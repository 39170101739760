import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import { NewsForm } from '@/interfaces/components/news/NewsForm';

export default class NewsAPI {
    public static async getById(id: string) {
        let newsReport;

        try {
            newsReport = (await ApiController.get(ApiRoutes.news.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReport);
    }

    public static async getAll() {
        let newsReports;

        try {
            newsReports = (await ApiController.get(ApiRoutes.news.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReports);
    }

    public static async getAllArchived() {
        let newsReports;

        try {
            newsReports = (await ApiController.get(ApiRoutes.news.getArchived)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReports);
    }

    public static async create(newsData: NewsForm) {
        let newsReport;

        const payload = {
            data: {
                type: 'news',
                attributes: {
                    title: newsData.title,
                    content: newsData.content,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: newsData.locale.id,
                        },
                    },
                },
            },
        };

        try {
            newsReport = (await ApiController.post(ApiRoutes.news.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReport);
    }

    public static async update(id: string, newsData: NewsForm) {
        let newsReport;

        const payload = {
            data: {
                type: 'news',
                id,
                attributes: {
                    title: newsData.title,
                    content: newsData.content,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: newsData.locale.id,
                        },
                    },
                },
            },
        };

        try {
            newsReport = (await ApiController.patch(ApiRoutes.news.update(id), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReport);
    }

    public static async delete(id: string) {
        try {
            (await ApiController.delete(ApiRoutes.news.delete(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async archiveOrActivate(id: string, shouldArchive: boolean) {
        let newsReport;
        const payload = {};

        try {
            newsReport = (await ApiController.post(
                ApiRoutes.news.archiveOrActivate(id, String(shouldArchive)),
                payload,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newsReport);
    }
}
