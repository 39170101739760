export enum FieldTypes {
    Files = 'files',
    Gallery = 'gallery',
    Input = 'input',
    InputWithSuffix = 'inputWithSuffix',
    InputNumber = 'inputNumber',
    Image = 'image',
    Switch = 'switch',
    Dropdown = 'dropdown',
    ExtendedFiles = 'extendedFiles',
    Configurator = 'configurator',
    Date = 'date',
    AutocompleteGroup= 'autocompleteGroup',
    ColorPicker= 'colorPicker',
    NumberRangeGroup = 'numberRangeGroup',
    UserGroups = 'userGroups',
    Checkbox = 'checkbox',
    RadioGroup = 'radioGroup',
    StaticMultiselect = 'staticMultiselect',
    RichTextEditor = 'richTextEditor',
    MultipleImages = 'multipleImages',
    MultipleTextareas = 'multipleTextareas',
    SalesProcessForms = 'salesProcessForms',
    UserAliases = 'userAliases',
}
