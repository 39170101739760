
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';

@Component({
    name: 'PopupNotification',
})
export default class PopupNotification extends Vue {
    @Prop({ required: true }) private options!: PopupNotificationOptions;

    private get icon(): string {
        return this.options.icon || (this.options.errorType === 'error' ? 'close-circle' : 'exclamation-circle');
    }

    private get color(): string {
        return 'color:' + (this.options.errorType === 'error' ? '#f5222d' : '#ffbf00');
    }
}
