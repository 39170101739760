import { OfferPriceDetail } from '@/interfaces/general/OfferPriceDetail';
import Offer from '@/models/Offer';
import { PriceItem } from '@/interfaces/general/PriceItem';
import i18n from '@/i18n';
import OfferConfiguration from '@/models/OfferConfiguration';
import { AdditionalPDFOptionChoice } from '@/interfaces/general/AdditionalPDFOptionChoice';
import { LocalStorageService } from '@/services/LocalStorageService';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import Project from '@/models/Project';
import { sortArrayByGivenPropertyName } from '@/helpers/TableHelper';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { OfferStates } from '@/enums/global/OfferStates';
import { Route } from 'vue-router/types/router';
import store from '@/store';
import { JWTDataKeys } from '@/enums/global/JWTDataKeys';

export function generatePricelist(
    selectedOffer: Offer,
    currencySymbol: string,
    isSellingPrice = true,
    userGroupId: string
) {
    const offerPrice = isSellingPrice ? selectedOffer.sellingOfferPrice : selectedOffer.purchaseOfferPrice;
    if (offerPrice == null) {
        return [];
    }

    const prices: PriceItem[] = offerPrice.priceDetails
        .filter((priceDetail) => !(priceDetail.manipulation && priceDetail.categoryId))
        .map(
            ({
                priceDetailName,
                priceDetailAmount,
                priceDetailAmountOriginal,
                categoryId,
                manipulation,
            }: OfferPriceDetail) => {
                let productPriceDetails = [];
                if (categoryId && manipulation == null) {
                    productPriceDetails = offerPrice.priceDetails.filter((priceDetail) => {
                        return priceDetail.categoryId === categoryId && priceDetail.manipulation;
                    });
                }
                return {
                    label: priceDetailName,
                    priceValue: priceDetailAmountOriginal != null ? priceDetailAmountOriginal : priceDetailAmount,
                    productPriceDetail: productPriceDetails.length > 0 ? productPriceDetails : null,
                };
            }
        );

    if (isSellingPrice) {
        if (selectedOffer.offerTax != null && offerPrice.priceWithTax) {
            prices.push({
                label: `${i18n.t('PDV')} (${selectedOffer.offerTax}%)`,
                priceValue: offerPrice.priceWithTax - offerPrice.price,
            });
            prices.push({
                label: `${i18n.t(
                    isSellingPrice ? 'Konačna prodajna cijena' : 'Konačna kupovna cijena'
                )} (${currencySymbol})`,
                priceValue: offerPrice.priceWithTax,
            });
        }
    } else {
        if (selectedOffer.offerTaxPurchase != null && offerPrice.priceWithTax) {
            prices.push({
                label: `${i18n.t('PDV')} (${selectedOffer.offerTaxPurchase}%)`,
                priceValue: offerPrice.priceWithTax - offerPrice.price,
            });
            prices.push({
                label: `${i18n.t(
                    isSellingPrice ? 'Konačna prodajna cijena' : 'Konačna kupovna cijena'
                )} (${currencySymbol})`,
                priceValue: offerPrice.priceWithTax,
            });
        }
    }

    return prices;
}

export function extractAdditionalPDFOptionsFromOfferConfiguration(
    offerConfiguration: OfferConfiguration,
    productOptions: AdditionalPDFOptionChoice[]
) {
    const checkedProductOptions: string[] = [];

    productOptions.forEach((option) => {
        if (offerConfiguration && offerConfiguration[option.value as keyof OfferConfiguration]) {
            checkedProductOptions.push(option.value);
        }
    });

    return {
        checkedProductOptions,
        discountOption: offerConfiguration.showDiscounts,
        priceOption: offerConfiguration.showPrice,
        printPrice: offerConfiguration.printPrice,
    };
}

export function getLastSelectedTab() {
    return LocalStorageService.has('lastSelectedTab') ? LocalStorageService.get('lastSelectedTab') : ProjectTypes.Lead;
}

/**
 * Generates an ID based on the state of the project
 * Project ID if 'lead'
 * OrderNumber or name of first ordered offer if 'order'
 * Name of newest offer if 'offer'
 * Name of newest offer (if exists), else project ID if 'request'
 * If an offer is given as an argument, it returns its name
 * @param project - The project that needs an ID to be generated
 * @param selectedOffer - The  offer that was selected in the offer list dropdown
 * @return The generated ID based on the state of the project
 */
export function generateStateSpecificId(project: Project | null, selectedOffer?: Offer) {
    let stateSpecificId;

    if (project == null) {
        return '';
    }

    if (selectedOffer) {
        return selectedOffer.state !== OfferStates.DRAFT
            ? selectedOffer.name === '-' || !selectedOffer.name
                ? project.id
                : selectedOffer.name
            : project.id;
    }

    switch (project.state) {
        case ProjectStates.REQUEST:
            if (determineWhetherProjectContainsLockedOrImportedOffers(project)) {
                stateSpecificId = extractIdOfNewestOffer(project);
            } else {
                stateSpecificId = project.id;
            }
            break;
        case ProjectStates.OFFER:
            stateSpecificId = extractIdOfNewestOffer(project);
            break;
        case ProjectStates.ORDER:
            stateSpecificId = extractOrderNumberId(project);
            break;
        default:
            stateSpecificId = project.id;
    }

    return stateSpecificId;
}

/**
 * Gets all the offers in a projects, sorts them by 'createdAt' and returns the name of the newest one
 * @param project - The project that contains the offers
 * @return The offer name of the newest offer
 */
export function extractIdOfNewestOffer(project: Project) {
    if (!project?.offerRevisions) {
        return '';
    }
    const sortedOffers = sortArrayByGivenPropertyName(project.offerRevisions.slice(), 'createdAt');
    const finalIndex = sortedOffers.length - 1;
    if (sortedOffers[finalIndex]?.name === '-') {
        for (let index = finalIndex; index >= 0; index -= 1) {
            if (sortedOffers[index]?.name && sortedOffers[index].name !== '-') {
                return sortedOffers[index].name;
            }
        }
    }
    return sortedOffers.splice(-1)[0]?.name ?? '';
}

/**
 * Extracts the order number from a project if it exists, else it returns the name of the first ordered offer
 * @param project - The project that contains the offers
 * @return The extracted order number or offer name
 */
export function extractOrderNumberId(project: Project) {
    const orderedOffer = project.offerRevisions.filter((offer: Offer) => offer.state === OfferStates.ORDERED);

    return project.orderNumber != null ? project.orderNumber : orderedOffer[0]?.name;
}

/**
 * Checks if the project contains any locked or imported offers
 * @param project - The project that contains the offers
 * @return A boolean whether the project contains any locked of imported offers
 */
export function determineWhetherProjectContainsLockedOrImportedOffers(project: Project) {
    return project.offerRevisions.some(
        (offer) => offer.state === OfferStates.LOCKED || offer.state === OfferStates.IMPORTED
    );
}

/**
 * Checks if the route between two project routes differs only in their initial tab queries
 * This is necessary because of the project navigation buttons
 * @param to - The vue router route the user is going to
 * @param from - The vue router route the user is coming from
 * @return - A boolean deciding whether it is a new route or just the same project with different tab queries
 */
export function checkIfTheProjectDataNeedsToBeReset(to: Route, from: Route) {
    return !(to.name === from.name && to.params.id === from.params.id && to.query.initialTab !== from.query.initialTab);
}

/**
 * Gets the 'ug' query param from the route and compares it to the current user group
 * If there's a difference, it will update the user group and clear related local storage entries
 * @param route - The current Vue Router route
 * @return - An empty promise
 */
export async function checkAndUpdateUserGroupBasedOnProjectURL(route: Route) {
    const currentUserGroup = store.getters[JWTDataKeys.CURRENT_USER_GROUP];
    const projectUserGroupId = route.query.ug || currentUserGroup;

    if (currentUserGroup && currentUserGroup.id === projectUserGroupId) {
        return Promise.resolve();
    }
    LocalStorageService.remove(ProjectTypes.Lead as string);
    LocalStorageService.remove(ProjectTypes.Offer as string);
    LocalStorageService.remove(ProjectTypes.Order as string);
    LocalStorageService.remove(ProjectTypes.Admin as string);

    await store.dispatch(JWTDataKeys.UPDATE_USER_GROUP, projectUserGroupId);

    return Promise.resolve();
}
