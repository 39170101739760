import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { WorkflowPayload } from '@/interfaces/components/Workflow/WorkflowPayload';

export default class WorkflowAPI {
    public static async getAll() {
        let workflows;

        try {
            workflows = await ApiController.get(ApiRoutes.workflow.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflows);
    }

    public static async getById(workflowId: string) {
        let workflow;

        try {
            workflow = await ApiController.get(ApiRoutes.workflow.getById(workflowId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflow);
    }

    public static async delete(workflowId: string) {

        try {
            await ApiController.delete(ApiRoutes.workflow.delete(workflowId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(workflowId: string, workflowPayload: WorkflowPayload) {
        let workflow;
        const request = {
            data: {
                type: 'workflows',
                id: workflowId,
                attributes: {
                    ...workflowPayload,
                },
            },
        };

        try {
            workflow =
                await ApiController.patch(
                    ApiRoutes.workflow.update(workflowId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflow);
    }

    public static async create(workflowPayload: WorkflowPayload) {
        let workflow;
        const request = {
            data: {
                type: 'workflows',
                attributes: {
                    name: 'Workflow',
                    ...workflowPayload,
                },
            },
        };

        try {
            workflow =
                await ApiController.post(
                    ApiRoutes.workflow.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflow);
    }
}
