import NextStepType from '@/models/NextStepType';

export default class NextStepTypeRepository {
    public static getAll() {
        return NextStepType.all();
    }

    public static getById(id: string) {
        return NextStepType.query().whereId(id).withAll().first();
    }
    public static delete(id: string) {
        return NextStepType.delete(id);
    }

    public static getByName(name: string) {
        return NextStepType.query().where('name', name).withAll().first();
    }
}
