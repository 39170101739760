
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChatRichTextEditor from '@/components/views/ChatView/ChatRichTextEditor.vue';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ReminderOptionRepository from '@/repositories/ReminderOptionRepository';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import { Moment } from 'moment';
import ReminderOption from '@/models/ReminderOption';
import NextStepType from '@/models/NextStepType';
import moment from 'moment';

export interface ActivityFormData {
    activityType: string;
    name: string;
    dateFrom: string | Moment | Date;
    dateTo: string | Moment | Date;
    reminderOption: string;
    assignedUser: string;
    note: string;
}
@Component({
    name: 'ActivityModalForm',
    components: {
        ChatRichTextEditor,
    },
})
export default class ActivityModalForm extends Vue {
    @Prop({ default: null }) private activity!: UpgradedNextStep | null;
    private localActivityType = '';
    private localName = '';
    private localDateFrom: string | Moment = '';
    private localDateTo: string | Moment = '';
    private localReminderOption = '';
    private localAssignedUser = '';
    private localNote = '';
    private refreshKey = 0;

    private get locale() {
        return getDatepickerLocale();
    }
    private activeErrors: string[] = [];
    private get activityTypes() {
        return NextStepTypeRepository.getAll();
    }
    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }
    private get reminderOptions() {
        return ReminderOptionRepository.getAll();
    }
    @Watch('activity', {
        immediate: true,
        deep: true,
    })
    private onActivityChange(activity: UpgradedNextStep) {
        if (this.activity) {
            this.localActivityType = this.activity?.nextStepType ?? this.activity?.activityType ?? '';
            this.localName = this.activity?.name ?? '';
            this.localDateFrom = moment(this.activity?.dateFrom) ?? '';
            this.localDateTo = moment(this.activity?.dateTo) ?? '';
            this.localReminderOption = this.activity?.reminderOption ?? '';
            this.localAssignedUser = this.activity?.assignedUser ?? '';
            this.localNote = this.activity?.note ?? '';
        }
    }
    private onUpdateContent(content: string) {
        this.localNote = content;
        this.onInput();
    }
    private onInput() {
        this.$emit('change', {
            activityType: this.localActivityType,
            name: this.localName,
            dateFrom: this.localDateFrom,
            dateTo: this.localDateTo,
            reminderOption: this.localReminderOption,
            assignedUser: this.localAssignedUser,
            note: this.localNote,
        });
    }
    @Watch('localNote')
    private refreshTextEditor() {
        if (this.refreshKey < 1) {
            this.refreshKey++;
        }
    }

    private async created() {
        try {
            await Promise.allSettled([NextStepType.getAll(), ReminderOption.getAll()]);
        } catch (e) {
            Promise.reject(e);
        }
    }
}
