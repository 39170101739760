import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
// @ts-ignore
import jsonLogic from 'json-logic-js/logic.js';
import { ChecklistUpdateFunction } from '@/interfaces/components/configurator/ChecklistUpdateFunction';
import { ChecklistVisibleFunction } from '@/interfaces/components/configurator/ChecklistVisibleFunction';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { FieldTypes } from '@/enums/components/FieldTypes';
import { ChecklistDropdownOption } from '@/interfaces/components/configurator/ChecklistDropdownOption';
import { Select } from 'ant-design-vue';
import Vue from 'vue';
import store from '@/store';

export function extractUpdateFunctionValues(
    updateFunctions: ChecklistUpdateFunction[],
    checkListType: string,
    values: ActiveProductFormValueObject
): ConfiguratorValue[] {
    const updatedValues: ConfiguratorValue[] = [];
    for (const updateFunction of updateFunctions) {
        const jsonLogicData: ActiveProductFormValueObject = {};

        for (const pId of updateFunction.conditionData) {
            const currentValue = values[pId];
            jsonLogicData[pId] = currentValue == null ? null : currentValue;

            if (jsonLogicData[pId] === true) {
                jsonLogicData[pId] = '1';
            } else if (jsonLogicData[pId] === false) {
                jsonLogicData[pId] = '0';
            }
        }

        const shouldUpdateValue = jsonLogic.apply(updateFunction.conditionJson, jsonLogicData);

        if (shouldUpdateValue) {
            const updatedValue: null | ConfiguratorValue = getAppropriateUpdateValue(
                checkListType,
                updateFunction,
                values
            );
            if (updatedValue != null) {
                updatedValues.push(updatedValue);
            }
        }
    }
    return updatedValues;
}

export function determineFieldVisibility(
    visibilityFunction: ChecklistVisibleFunction | null,
    values: ActiveProductFormValueObject
) {
    const jsonLogicData: ActiveProductFormValueObject = {};
    if (visibilityFunction == null) {
        return true;
    }

    for (const pId of visibilityFunction.conditionData) {
        const currentValue = values[pId];
        jsonLogicData[pId] = currentValue == null ? null : currentValue;

        if (jsonLogicData[pId] === true) {
            jsonLogicData[pId] = '1';
        } else if (jsonLogicData[pId] === false) {
            jsonLogicData[pId] = '0';
        }
    }

    return jsonLogic.apply(visibilityFunction.conditionJson, jsonLogicData);
}

export function extractAndRunOptionsFunction(
    optionsFunction: any,
    values: ActiveProductFormValueObject,
    optionsFunctionData: any
) {
    const jsonLogicData: ActiveProductFormValueObject = {};

    for (const pId of optionsFunctionData) {
        const currentValue = values[pId];
        jsonLogicData[pId] = currentValue == null ? null : currentValue;

        if (jsonLogicData[pId] === true) {
            jsonLogicData[pId] = '1';
        } else if (jsonLogicData[pId] === false) {
            jsonLogicData[pId] = '0';
        }
    }

    return jsonLogic.apply(optionsFunction, jsonLogicData);
}

function normalizeStringByLocale(input: string) {
    if (!input || typeof input !== 'string') {
        return '';
    }

    const normalizedText = input
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase();

    return normalizedText;
}

function mapChecklistDropdownOptionsToObject(checklistDropdownOptions: ChecklistDropdownOption[]) {
    return checklistDropdownOptions.reduce((acc, cur) => {
        return { ...acc, [normalizeStringByLocale(cur.vId)]: cur };
    }, {});
}

export function mapVisibleOptionVIDsToChecklistDropdownOptions(
    visibleOptionVIDs: string[],
    checklistDropdownOptions: ChecklistDropdownOption[]
) {
    const serializedChecklistDropdownOptions = mapChecklistDropdownOptionsToObject(checklistDropdownOptions);
    return visibleOptionVIDs
        .map((visibleOptionVID: string) => {
            const visibleOption = normalizeStringByLocale(visibleOptionVID);
            // @ts-ignore
            return serializedChecklistDropdownOptions[visibleOption];
        })
        .filter((visibleOption: ChecklistDropdownOption) => {
            return !!visibleOption?.vId && !!visibleOption?.value;
        });
}

export function extractInvalidDefaultValues(
    flattenedFields: any,
    productFormValues: any,
    productFormId: number | null
) {
    const warnings: Array<{}> = [];

    flattenedFields.forEach(async (field: any) => {
        const currentVal = productFormValues![field.pId];

        if (field.fieldType !== 'dropdown') {
            return;
        }

        if (currentVal === '' || currentVal === undefined || currentVal === null || currentVal === '#') {
            return;
        }

        const isValueAvailable = field.checklistDropdownField.checklistDropdownOptions.some(
            (dropdownOption: ChecklistDropdownOption) =>
                String(dropdownOption.vId).toUpperCase() === String(currentVal).toUpperCase()
        );

        if (!isValueAvailable) {
            const newValue = field.checklistDropdownField.checklistDropdownOptions[0].value;

            const warning = {
                pId: field.pId,
                value: currentVal,
                name: field.name,
                newValue,
            };

            warnings.push(warning);

            // Change active product form value
            await store.dispatch('configurator/updateActiveProductFormValue', {
                pId: field.pId,
                value: newValue,
                productFormId,
            });
        }
    });

    return warnings;
}

function getAppropriateUpdateValue(
    fieldType: string,
    updateFunction: ChecklistUpdateFunction,
    values: ActiveProductFormValueObject
) {
    const jsonLogicData: ActiveProductFormValueObject = {};

    for (const pId of updateFunction.resultData) {
        const currentValue = values[pId];
        jsonLogicData[pId] = currentValue == null ? null : currentValue;

        if (jsonLogicData[pId] === true) {
            jsonLogicData[pId] = '1';
        } else if (jsonLogicData[pId] === false) {
            jsonLogicData[pId] = '0';
        }
    }

    let updatedValue = jsonLogic.apply(updateFunction.resultJson, jsonLogicData);
    if (fieldType === FieldTypes.boolean) {
        // tslint:disable-next-line:triple-equals
        updatedValue = updatedValue == '1';
    } else if (['float', 'integer'].includes(fieldType)) {
        const value = parseFloat(updatedValue);
        updatedValue = Number.isFinite(value) ? value : updatedValue;
    }
    return updatedValue;
}

/**
 * Focuses the input element so the user is able to choose an option immediately
 * Done because antds( .focus does not work properly
 * @param event - The InputEvent that triggered the function call
 * @param dropdownRef - The reference to the select component
 */
export async function focusDropdownElement(event: InputEvent, dropdownRef: Select) {
    await Vue.nextTick();

    const inputElement = dropdownRef.$el.querySelector('input');

    if (inputElement) {
        inputElement.focus();
    }
}
