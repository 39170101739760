import { Model } from '@vuex-orm/core';
import ReminderOptionAPI from '@/api/ReminderOptionAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
export default class ReminderOption extends Model {
    public static entity = 'reminderOption';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string('null'),
            timeSeconds: this.attr(null),
        };
    }

    public static async getAll() {
        let reminderOptions;

        if (ss.has('reminderOptions')) {
            reminderOptions = ss.getAndParse('reminderOptions');
        } else {
            try {
                const fetchedOptions = await ReminderOptionAPI.getAll();
                reminderOptions = fetchedOptions.data;

                ss.stringifyAndSave('reminderOptions', reminderOptions);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: reminderOptions,
        });

        return Promise.resolve();
    }

    public static async getById(reminderOptionId: string) {
        let reminderOption;
        try {
            reminderOption = await ReminderOptionAPI.getById(reminderOptionId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: reminderOption.data,
        });
        return Promise.resolve(reminderOption);
    }

    public static async updateExisting(reminderOptionId: string, reminderInfo: any) {
        let reminderOption;
        try {
            reminderOption = await ReminderOptionAPI.update(reminderOptionId, reminderInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: reminderOption.data,
        });
        return Promise.resolve(reminderOption);
    }

    public static async createNew(reminderInfo: any) {
        let reminderOption;
        try {
            reminderOption = await ReminderOptionAPI.create(reminderInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: reminderOption.data,
        });
        return Promise.resolve(reminderOption);
    }

    public static async deleteExisting(reminderOptionId: string) {
        try {
            await ReminderOptionAPI.delete(reminderOptionId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ReminderOption.delete(reminderOptionId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
}
