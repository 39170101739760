import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class AutomaticPriceManipulationAPI {
    public static async getAll() {
        let priceManipulations;

        try {
            priceManipulations = await ApiController.get(ApiRoutes.priceManipulation.automatic.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulations);
    }

    public static async getById(priceManipulationId: string) {
        let priceManipulation;

        try {
            priceManipulation = await
                ApiController.get(ApiRoutes.priceManipulation.automatic.getById(priceManipulationId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async update(priceManipulationId: string, priceManipulationInfo: AvailableLabelFields) {
        let priceManipulation;

        const request = {
            data: {
                type: 'automatic_price_manipulations',
                id: priceManipulationId,
                attributes: {
                    name: priceManipulationInfo.name ? priceManipulationInfo.name : null,
                    value: priceManipulationInfo.value,
                },
                relationships: {
                    productCategory: {
                        data: null,
                    },
                },
            },
        };

        if (priceManipulationInfo.productCategory.id !== '0') {
            request.data.relationships.productCategory = {
                // @ts-ignore
                data: {
                    type: 'product_categories',
                    id: priceManipulationInfo.productCategory.id,
                },
            };
        }

        if (priceManipulationInfo.priceManipulationType) {
            // @ts-ignore
            request.data.relationships.priceManipulationType = {
                data: {
                    type: 'price_manipulation_types',
                    id: priceManipulationInfo.priceManipulationType.id,
                },
            };
        }

        try {
            priceManipulation =
                await ApiController.patch(
                    ApiRoutes.priceManipulation.automatic.update(priceManipulationId),
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async create(priceManipulationInfo: AvailableLabelFields) {
        let priceManipulation;

        const request = {
            data: {
                type: 'automatic_price_manipulations',
                attributes: {
                    name: priceManipulationInfo.name ? priceManipulationInfo.name : null,
                    value: priceManipulationInfo.value,
                },
                relationships: {
                    productCategory: {
                        data: null,
                    },
                },
            },
        };

        if (priceManipulationInfo.priceManipulationType) {
            // @ts-ignore
            request.data.relationships.priceManipulationType = {
                data: {
                    type: 'price_manipulation_types',
                    id: priceManipulationInfo.priceManipulationType.id,
                },
            };
        }

        if (priceManipulationInfo.productCategory.id !== '0') {
            request.data.relationships.productCategory = {
                // @ts-ignore
                data: {
                    type: 'product_categories',
                    id: priceManipulationInfo.productCategory.id,
                },
            };
        }

        try {
            priceManipulation =
                await ApiController.post(
                    ApiRoutes.priceManipulation.automatic.create,
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async delete(priceManipulationId: string) {

        try {
            await ApiController.delete(ApiRoutes.priceManipulation.automatic.delete(priceManipulationId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
