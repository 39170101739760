import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';
import { CMSUserInterface } from '@/models/interfaces/CMSUser';
import UserGroupConnection from '@/models/UserGroupConnection';


const actions: ActionTree<ILocalState, {}> = {
    async updateCmsUser({commit}, cmsUser: CMSUserInterface) {
        commit('setCmsUser', cmsUser);

        return Promise.resolve();
    },

    async updateUserGroupConnection({commit, state}, userGroupObject: any) {
        if (state.cmsUser === null) {
            return Promise.reject();
        }
        commit('updateExistingUserGroupConnection', userGroupObject);

        return Promise.resolve();
    },

    async addUserGroupConnection({commit, state}, userGroupObject: any) {
        if (state.cmsUser === null) {
            return Promise.reject();
        }

        // @ts-ignore
        if (state.cmsUser.userGroupConnections
            .map((userGroupConnection: UserGroupConnection) => userGroupConnection.userGroup.id)
            .includes(userGroupObject.userGroupConnection.userGroup.id)) {
            return Promise.reject();
        }

        commit('addUserGroupConnection', userGroupObject);

        return Promise.resolve();
    },

    async deleteUserGroupConnection({commit, state}, userGroupConnectionKey: any) {
        if (state.cmsUser === null) {
            return Promise.reject();
        }

        commit('deleteUserGroupConnection', userGroupConnectionKey);

        return Promise.resolve();
    },

    async setCmsEntitiesUserGroup({commit, rootGetters}, cmsEntity: string) {
        commit('setEntityUserGroup', {cmsEntity, currentUserGroup: rootGetters['jwtData/currentUserGroup']});
    },
};

export default actions;
