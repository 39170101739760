import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class FlaggedPdfValueAPI {
    public static async getAll() {
        let flaggedPdfValues;

        try {
            flaggedPdfValues = (await ApiController.get(ApiRoutes.flaggedPdfValue.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(flaggedPdfValues);
    }

    public static async getById(flaggedPdfValueId: string) {
        let flaggedPdfValue;

        try {
            flaggedPdfValue = (await ApiController.get(
                ApiRoutes.flaggedPdfValue.getById(flaggedPdfValueId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(flaggedPdfValue);
    }

    public static async delete(flaggedPdfValueId: string) {
        try {
            await ApiController.delete(ApiRoutes.flaggedPdfValue.delete(flaggedPdfValueId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async create(value: string) {
        let flaggedPdfValue;
        const request = {
            data: {
                type: 'flagged_pdf_values',
                attributes: {
                    value,
                },
            },
        };

        try {
            flaggedPdfValue = (await ApiController.post(ApiRoutes.flaggedPdfValue.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(flaggedPdfValue);
    }

    public static async update(flaggedPdfValueId: string, value: string) {
        let flaggedPdfValue;
        const request = {
            data: {
                type: 'flagged_pdf_values',
                id: flaggedPdfValueId,
                attributes: {
                    value,
                },
            },
        };

        try {
            flaggedPdfValue = await ApiController.patch(ApiRoutes.flaggedPdfValue.update(flaggedPdfValueId), request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(flaggedPdfValue);
    }
}
