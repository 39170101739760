import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import AddressConfig from '@/models/interfaces/Address';

export default class AddressAPI {
    public static async getAll() {
        let addresses;

        try {
            addresses = await ApiController.get(ApiRoutes.address.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(addresses);
    }

    public static async getById(addressId: string) {
        let address;

        try {
            address = await ApiController.get(ApiRoutes.address.getById(addressId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(address);
    }

    public static async update(addressId: string, addressInfo: AddressConfig, withRelationships = false) {
        let address;
        const request = {
            data: {
                type: 'addresses',
                id: addressId,
                attributes: {
                    name: addressInfo.name,
                    streetNameAndNumber: addressInfo.streetNameAndNumber,
                    poNumber: addressInfo.poNumber,
                    city: addressInfo.city,
                    building: addressInfo.building,
                    contactPerson: addressInfo.contactPerson,
                    note: addressInfo.note,
                    phone: addressInfo.phone,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: addressInfo.country,
                        },
                    },
                },
            },
        };

        if (addressInfo.parentRelationshipName) {
            request.data.relationships = {
                ...request.data.relationships,
                [addressInfo.parentRelationshipName]: addressInfo.parentRelationshipPayload,

            };
        }
        try {
            address =
                await ApiController.patch(
                    ApiRoutes.address.update(addressId, withRelationships),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(address);
    }

    public static async delete(addressId: string) {
        let address;

        try {
            address =
                await ApiController.delete(
                    ApiRoutes.address.update(addressId, false),
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(address);
    }

    public static async create(addressInfo: AddressConfig, withRelationships = false) {
        let address;
        const request = {
            data: {
                type: 'addresses',
                attributes: {
                    name: addressInfo.name,
                    streetNameAndNumber: addressInfo.streetNameAndNumber,
                    poNumber: addressInfo.poNumber,
                    city: addressInfo.city,
                    building: addressInfo.building,
                    contactPerson: addressInfo.contactPerson,
                    note: addressInfo.note,
                    phone: addressInfo.phone,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: addressInfo.country,
                        },
                    },
                },
            },
        };

        if (addressInfo.parentRelationshipName) {
            request.data.relationships = {
                ...request.data.relationships,
                [addressInfo.parentRelationshipName]: addressInfo.parentRelationshipPayload,

            };
        }

        try {
            address =
                await ApiController.post(
                    ApiRoutes.address.create(withRelationships),
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(address);
    }
}
