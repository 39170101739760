import IntroScreenItem from '@/models/IntroScreenItem';

export default class IntroScreenItemRepository {
    public static getAll() {
        return IntroScreenItem.all();
    }

    public static getById(id: string) {
        return IntroScreenItem.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return IntroScreenItem.delete(id);
    }
}
