
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { Action, Getter } from 'vuex-class';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';
import ChecklistFieldModule from '@/components/views/productView/ChecklistFieldModule.vue';
import ChecklistBoolFieldModule from '@/components/views/productView/ChecklistBoolFieldModule.vue';
import ChecklistDropdownFieldModule from '@/components/views/productView/ChecklistDropdownFieldModule.vue';
import ChecklistFloatFieldModule from '@/components/views/productView/ChecklistFloatFieldModule.vue';
import ChecklistIntegerFieldModule from '@/components/views/productView/ChecklistIntegerFieldModule.vue';
import ChecklistStringFieldModule from '@/components/views/productView/ChecklistStringFieldModule.vue';
import { determineFieldVisibility } from '@/helpers/FieldFunctionHelper';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import Offer from '@/models/Offer';
import { filterProductsFromOfferItems } from '@/helpers/OfferItemTableHelper';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
// @ts-ignore
import is from 'is_js';
import { Tooltip } from 'ant-design-vue';

enum fieldType {
    float = 'ChecklistFloatFieldModule',
    string = 'ChecklistStringFieldModule',
    boolean = 'ChecklistBoolFieldModule',
    integer = 'ChecklistIntegerFieldModule',
    dropdown = 'ChecklistDropdownFieldModule',
}

@Component({
    name: 'MultipositionHeader',
    components: {
        ChecklistFieldModule,
        ChecklistBoolFieldModule,
        ChecklistDropdownFieldModule,
        ChecklistFloatFieldModule,
        ChecklistIntegerFieldModule,
        ChecklistStringFieldModule,
        Tooltip,
    },
})
export default class MultipositionHeader extends Vue {
    @Prop({ required: true }) private productData!: ProductRowItem;
    @Prop({ required: true }) private activeItemRows!: ProductRowItem[];
    @Prop({ required: true }) private hiddenIndices!: boolean[];
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ default: false }) private isCustomProduct!: boolean;
    @Prop({ default: null }) private offer!: Offer;
    @Prop() private level!: number;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;

    private isModalVisible = false;
    private modalChecklistField: null | ChecklistField = null;

    private activeFieldModule(checklistFieldType: keyof typeof fieldType) {
        return fieldType[checklistFieldType];
    }

    private toggleInfoDetailsModal(checklistField: ChecklistField | null) {
        if (checklistField && checklistField.description == null) {
            return;
        }
        this.isModalVisible = !this.isModalVisible;
        this.modalChecklistField = this.isModalVisible ? checklistField : null;

        if (this.isModalVisible && is.safari()) {
            this.$nextTick(() => {
                const imageWrapperElement = this.$refs.imageWrapper as HTMLElement;
                const text = this.$refs.text as HTMLElement;
                const modalInner = document.querySelector('.ant-modal-body') as HTMLElement;
                const textHeight = text.getBoundingClientRect().height;
                const modalInnerHeight = modalInner.getBoundingClientRect().height;

                imageWrapperElement.style.height = modalInnerHeight - textHeight - 110 + 'px';
            });
        }
    }

    private filterProductsFromOfferItems(offerItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>) {
        return filterProductsFromOfferItems(offerItems, false);
    }

    private onUpdateStoreFieldValues({ pId, value }: { pId: string; value: ConfiguratorValue }) {
        this.$nextTick(() => {
            this.$notification.destroy();
        });
        for (const activeItemRow of this.activeItemRows) {
            const productFormValues = this.getProductFormValues(activeItemRow.activeProductFormId);
            const editableField = activeItemRow.editableFields.find((fieldToBeEdited: ChecklistField) => {
                return fieldToBeEdited.pId === pId;
            });
            if (productFormValues == null || editableField == null) {
                continue;
            }
            if (!determineFieldVisibility(editableField.checklistVisibleFunction, productFormValues)) {
                continue;
            }
            this.updateActiveProductFormValue({
                pId,
                value,
                productFormId: activeItemRow.activeProductFormId,
            });
        }
    }

    private checklistFieldHasVisibilityFunction(checklistField: ChecklistField) {
        return checklistField.checklistVisibleFunction != null;
    }
}
