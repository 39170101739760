import { Model } from '@vuex-orm/core';
import CountryAPI from '@/api/CountryAPI';
import { AxiosResponse } from 'axios';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class Country extends Model {
    public static entity = 'country';

    public get getName() {
        // @ts-ignore
        return this.name;
    }

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            code: this.string(''),
            ingeniousId: this.string(''),
        };
    }

    public static async getAll() {
        const countriesExist = Country.all().length > 150;
        // consider that the countries are loaded in if there are more than 149
        // prevent refetching if that is true
        if (countriesExist) {
            return Promise.resolve();
        }

        let countries;

        if (ss.has('countries')) {
            countries = ss.getAndParse('countries');
        } else {
            try {
                const fetchedCountries = (await CountryAPI.getAll()) as AxiosResponse;
                countries = fetchedCountries.data;

                ss.stringifyAndSave('countries', countries);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: countries,
        });

        return Promise.resolve(countries);
    }

    public id!: string;
    public code!: string;
    public name!: string;
}
