import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import PostCodeConnectionConfig from '@/models/interfaces/PostCodeConnection';

export default class PostCodeConnectionAPI {
    public static async getAll() {
        let postCodeConnections;

        try {
            postCodeConnections = await ApiController.get(ApiRoutes.postCodeConnection.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(postCodeConnections);
    }

    public static async getById(postCodeConnectionId: string) {
        let postCodeConnection;

        try {
            postCodeConnection =
                await ApiController.get(ApiRoutes.postCodeConnection.getById(postCodeConnectionId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(postCodeConnection);
    }

    public static async update(postCodeConnectionId: string, postCodeConnectionInfo: PostCodeConnectionConfig) {
        let postCodeConnection;
        const request = {
            data: {
                type: 'post_code_connections',
                id: postCodeConnectionId,
                attributes: {
                    toNumber: postCodeConnectionInfo.toNumber,
                    fromNumber: postCodeConnectionInfo.fromNumber,
                },
                relationships: {
                    user: {
                        data: {
                            type: 'users',
                            id: postCodeConnectionInfo.user,
                        },
                    },
                    country: {
                        data: {
                            type: 'countries',
                            id: postCodeConnectionInfo.country,
                        },
                    },
                },
            },
        };

        try {
            postCodeConnection =
                await ApiController.patch(
                    ApiRoutes.postCodeConnection.update(postCodeConnectionId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(postCodeConnection);
    }

    public static async create(postCodeConnectionInfo: PostCodeConnectionConfig) {
        let postCodeConnection;
        const request = {
            data: {
                type: 'post_code_connections',
                attributes: {
                    toNumber: postCodeConnectionInfo.toNumber,
                    fromNumber: postCodeConnectionInfo.fromNumber,
                },
                relationships: {
                    user: {
                        data: {
                            type: 'users',
                            id: postCodeConnectionInfo.user,
                        },
                    },
                    country: {
                        data: {
                            type: 'countries',
                            id: postCodeConnectionInfo.country,
                        },
                    },
                },
            },
        };

        try {
            postCodeConnection =
                await ApiController.post(
                    ApiRoutes.postCodeConnection.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(postCodeConnection);
    }

    public static async delete(postCodeConnectionId: string) {

        try {
            await ApiController.delete(ApiRoutes.postCodeConnection.delete(postCodeConnectionId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
