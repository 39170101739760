
import { Vue, Component, Model, Prop } from 'vue-property-decorator';

export interface ButtonSwitchConfiguration {
    firstOptionLabel: string;
    secondOptionLabel: string;
    firstOptionValue: any;
    secondOptionValue: any;
}

@Component({
    name: 'ButtonSwitch',
})
export default class ButtonSwitch extends Vue {
    @Model('input') private value!: boolean;
    @Prop({ type: Object as () => ButtonSwitchConfiguration }) private configuration!: ButtonSwitchConfiguration;
}
