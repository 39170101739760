import { AxiosResponse } from 'axios';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';

export default class UserAPI {
    public static async login(data: { username: string, password: string }) {
        let loginResponse;
        const request = {
            data: {
                attributes: {
                    username: data.username,
                    password: data.password,
                },
            },
        };
        try {
            loginResponse = await ApiController.post(ApiRoutes.login, request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(loginResponse);
    }

    public static async get(userId: string) {
        let user;
        try {
            user = await ApiController.get(ApiRoutes.cmsUser.getSingle(userId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(user);
    }

    public static async updateExisting(userData: any) {
        let user;
        const request = {
            data: {
                type: 'users',
                id: userData.id,
                attributes: {},
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: userData.localeId,
                        },
                    },
                },
            },
        };
        try {
            user = await ApiController.patch(ApiRoutes.cmsUser.updateExisting(userData.id), request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(user);
    }

    public static async resetPassword(password: string, passwordRepeat: string, token: string) {
        let resetPassword;
        const request = {
            data: {
                type: 'password_resets',
                attributes: {
                    password,
                    passwordRepeat,
                    token,
                },
            },
        };
        try {
            resetPassword = await ApiController.post(ApiRoutes.cmsUser.resetPassword, request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(resetPassword);
    }

    public static async requestPassword(email: string) {
        let response;
        const request = {
            data: {
                type: 'email_requests',
                attributes: {
                    email,
                },
            },
        };
        try {
            response = await ApiController.post(ApiRoutes.cmsUser.requestPassword, request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(response);
    }
}
