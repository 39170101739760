import { NavigationGuardNext, Route, VueRouter } from 'vue-router/types/router';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import User from '@/models/User';
import Token from '@/models/Token';
import { AxiosResponse } from 'axios';
// @ts-ignore
import jwt_decode from 'jwt-decode';
import { UserRepository } from '@/repositories/UserRepository';

export class RouteGuardService {
    constructor(private router: VueRouter) {
        this.guard();
    }

    private get token(): string | null {
        return LocalStorageService.get(LocalStorageKeyNames.token);
    }

    private guard() {
        this.router.beforeEach(async (to, from, next) => {
            try {
                await this.manageTokenExistence(to, next);
            } catch (e) {
                return;
            }
            const currentUser = UserRepository.getCurrentUser();

            if (currentUser != null || this.token == null) {
                next();
                return;
            }

            this.runSuccessfulActionsOrRedirectToLogin(next, to);
        });
    }

    private async runSuccessfulActionsOrRedirectToLogin(next: NavigationGuardNext, route: Route) {
        const decodedJWT = jwt_decode(this.token);

        try {
            await User.runInitialActionsAfterRouteChange(true, decodedJWT.locale, decodedJWT.id);
        } catch (e) {
            this.redirectToLogin(next, route.fullPath);
            return;
        }

        next();
    }

    private async manageTokenExistence(route: Route, next: any) {
        if (!route.meta?.authRequired) {
            next();
            return Promise.reject();
        }

        if (this.token === null || this.token.length <= 0) {
            this.redirectToLogin(next, route.fullPath);
            return Promise.reject();
        } else {
            await this.checkTokenValidation(next, route.fullPath);
            return Promise.resolve();
        }
    }

    private async checkTokenValidation(next: any, redirectedFrom: string | null | undefined) {
        let tokenRefreshed;
        const decoded = jwt_decode(this.token);

        if (this.isTodaysDateAfterGivenDate(decoded.exp)) {
            User.setToken({
                data: {
                    access_token: this.token,
                },
            });
            return Promise.resolve();
        }

        try {
            tokenRefreshed = (await Token.refreshToken()) as AxiosResponse;
        } catch (e) {
            await this.redirectToLogin(next, redirectedFrom);
            return;
        }
        User.setToken(tokenRefreshed);

        return Promise.resolve();
    }

    private async redirectToLogin(next: any, redirectedFrom: string | null | undefined) {
        await User.logout().then(() => {
            next({ name: 'login', query: { redirectTo: redirectedFrom } });
        });
    }

    private isTodaysDateAfterGivenDate(timestamp: number) {
        const currentDateWithOneHourIncrement = new Date();

        currentDateWithOneHourIncrement.setTime(currentDateWithOneHourIncrement.getTime() + 3600000);
        return currentDateWithOneHourIncrement < new Date(timestamp * 1000);
    }
}
