import ProjectSearch from '@/models/ProjectSearch';

export default class ProjectSearchRepository {
    public static getAll() {
        return ProjectSearch.query()
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.createdAt).getTime(),
                'desc'
            )
            .withAll()
            .get();
    }

    public static getById(id: string) {
        return ProjectSearch.query().whereId(id).withAll().first();
    }
}
