import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductMaterialAPI {
    public static async getAll() {
        let ProductMaterial;

        try {
            ProductMaterial = (await ApiController.get(ApiRoutes.productVersion.materials)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(ProductMaterial);
    }
}
