import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import Vue from 'vue';

const mutations: MutationTree<ILocalState> = {
    updateProjects(state, payload) {
        state.projects = payload;
    },
    updateProject(state, payload) {
        const projectIndex = state.projects.findIndex((p) => p.id === payload.id);
        if (projectIndex >= 0){
            state.projects[projectIndex] = payload;
        }
    },
    updateNextSteps(state, payload) {
        state.nextSteps = payload;
    },
    updateNextStep(state, payload) {
        const index = state.nextSteps.findIndex((item) => item.id === payload.id);

        if (index !== -1) {
            Vue.set(state.nextSteps, index, payload);
        }
    },
    removeNextStep(state, payload) {
        const nextStepIndex = state.nextSteps.findIndex((ns) => ns.id === payload);
        if (nextStepIndex >= 0) {
            state.nextSteps.splice(nextStepIndex, 1);
        }
    },
    updateClients(state, payload) {
        state.clients = payload;
    },

    clearProjectNavigationSettings(state) {
        state.projectNavigationSettings.sortedProjectsObject = {};
        state.projectNavigationSettings.userId = '';
        state.projectNavigationSettings.entityType = '';
        state.projectNavigationSettings.isLastPageFetched = false;
        state.projectNavigationSettings.isFirstPageFetched = false;
    },
    updateProjectNavigationSettings(state, settings) {
        const {
            projectIds,
            page,
            hasNext,
            hasPrev,
            userId,
            entityType,
        } = settings;

        if(projectIds) {
            state.projectNavigationSettings.sortedProjectsObject[page] = projectIds;
        }
        if(userId) {
            state.projectNavigationSettings.userId = userId;
        }
        if(entityType) {
            state.projectNavigationSettings.entityType = entityType;
        }

        if (!state.projectNavigationSettings.isFirstPageFetched && hasPrev !== undefined) {
            state.projectNavigationSettings.isFirstPageFetched = !hasPrev;
        }

        if (!state.projectNavigationSettings.isLastPageFetched && hasNext !== undefined) {
            state.projectNavigationSettings.isLastPageFetched = !hasNext;
        }
        // LocalStorageService.save('projectNavigationSettings', JSON.stringify(settings));
    },
};

export default mutations;
