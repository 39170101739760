
import { Component, Prop, Vue } from 'vue-property-decorator';
import PopupNotification from '@/components/global/popup/PopupNotification.vue';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
    name: 'PopupNotifications',
    components: {
        PopupNotification,
    },
})
export default class PopupNotifications extends Vue {
    @Prop({ required: true }) private notifications!: PopupNotificationOptions[];
    private rightMargin: number = 40;
    private topMargin: number = 40;
    private position: string = 'fixed';

    private notificationsAreVisible = true;

    private created() {
        if (this.$route.name === RouteNames.calculatorProduct) {
            this.rightMargin = -50;
            this.topMargin = 60;
            this.position = 'absolute';
        } else {
            this.rightMargin = 40;
            this.topMargin = 40;
            this.position = 'fixed';
        }
    }

    private get notificationIconInfo() {
        const obj = {
            icon: '',
            color: '',
        };

        if (this.notifications.length) {
            obj.icon =
                this.notifications[0].icon ||
                (this.notifications[0].errorType === 'error' ? 'close-circle' : 'exclamation-circle');
            obj.color = 'color:' + (this.notifications[0].errorType === 'error' ? '#f5222d' : '#ffbf00');
        } else {
            obj.icon = 'check-circle';
            obj.color = 'color: #52c41a';
        }

        return obj;
    }
}
