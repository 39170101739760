import Lead from '@/models/Lead';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { notification } from 'ant-design-vue';
import i18n from '@/i18n';
import { UploadFile } from 'ant-design-vue/types/upload';
import Attachment from '@/models/Attachment';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import Image from '@/models/Image';
import { AxiosError } from 'axios';
// @ts-ignore
import downloadFile from 'downloadjs';

/**
 * Updates the attachments in the model based on the given attachments.
 * Emits an update project event and notification when done
 * @param leadId - The id of the lead that is being updated
 * @param attachments - An array of attachments that need to be updated
 * @return - An empty promise
 */
export async function updateProjectAttachments(leadId: string | null, attachments: UploadFile[]) {
    if (leadId == null) {
        return Promise.reject(new Error('No lead data was given'));
    }

    await Lead.updateAttachments(attachments, leadId);

    notification.success({
        message: i18n.t('Promjene uspješne!') as string,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Uploads a file as an attachment and connects it to the lead entity
 * @param leadId - The lead id that the file is being connected to
 * @param file - The file that needs to be uploaded
 * @param onError - Antds function that needs to be called if an error occurs
 * @return - A promise with the form data if successful, otherwise undefined
 */
export async function uploadAttachmentWithRelationshipToLead(leadId: string | null, file: Blob, onError: () => void) {
    if (leadId == null) {
        throw new Error('Lead id was not passed.');
    }

    const formData = new FormData();

    formData.append('attachment', file);
    formData.append('lead_id', leadId);
    try {
        await Attachment.createNew(formData);
    } catch (e) {
        onError();
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (e as Error).message,
        });
        return Promise.reject();
    }

    return Promise.resolve(formData);
}

/**
 * Uploads an image as an attachment
 * @param file - The file that needs to be uploaded
 * @return - A promise with the URL to the image, otherwise error
 */
export async function uploadImage(file: File) {
    const formData = new FormData();
    let uploadedFile;

    formData.append('attachment', file);

    try {
        uploadedFile = await Attachment.createNew(formData, true);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }

    return Promise.resolve(uploadedFile.data.attachment);
}

/**
 * Convert the given file to base64
 * @param file - The file that needs to be uploaded
 * @return - A promise with the result, otherwise error
 */
export async function toBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export async function customHandlerForFileUpload(customRequestParameters: CustomRequestParameters, leadId: string) {
    let formData;
    try {
        formData = await uploadAttachmentWithRelationshipToLead(
            leadId,
            customRequestParameters.file,
            customRequestParameters.onError
        );
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }
    customRequestParameters.onSuccess(formData);

    notification.success({
        message: i18n.t('Promjene uspješne!'),
        description: '',
    });

    return Promise.resolve();
}

export async function downloadGivenFileOrImage(file: UploadFile & Image) {
    let blob;
    try {
        blob = await Attachment.getBlob(file.url as string);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }

    downloadFile(blob.data, file.name);
    return Promise.resolve();
}
