
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProjectTypes } from '@/enums/models/ProjectTypes';

@Component({
    name: 'FloatingDeleteActions',
})
export default class FloatingDeleteActions extends Vue {
    @Prop({ required: true }) private selectedEntities!: number;
    @Prop({ required: true }) private entityType!: string;

    private deleteMessage: string = '';
    private deleteType: string = '';

    private setDeleteMessages() {
        switch (this.entityType) {
            case 'Clients':
                this.deleteMessage = this.$t('Are you sure you want to delete these clients?');
                this.deleteType = this.$t('selected clients');
                break;
            default:
                this.deleteMessage = this.$t('Jeste li sigurni da želite obrisati ove projekte?');
                this.deleteType = this.$t('označena projekta');
        }
    }

    private emitDeletingEvent() {
        if (this.entityType === 'Clients') {
            this.$emit('deleteClients');
        } else {
            EventBus.$emit(EventBusEvents.deleteSelectedEntities);
        }
    }

    private onDelete() {
        const componentRef = this;

        this.$confirm({
            title: this.deleteMessage,
            content: '',
            onOk() {
                componentRef.emitDeletingEvent();
            },
        });
    }

    private mounted() {
        this.setDeleteMessages();
    }
}
