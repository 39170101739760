import { Model } from '@vuex-orm/core';
import Product from '@/models/Product';
import ProductSystemAPI from '@/api/ProductSystemAPI';
import ProductSystemDetails from '@/models/ProductSystemDetails';

export default class ProductSystem extends Model {

    public static entity = 'productSystem';

    public static fields() {
        return {
            id: this.attr(''),
            name: this.attr(''),
            code: this.attr(''),
            isArchived: this.boolean(false),
            product_id: this.attr(null),
            product: this.belongsTo(Product, 'product_id'),
            productSystemDetails_id: this.attr(''),
            productSystemDetails: this.belongsTo(ProductSystemDetails, 'productSystemDetails_id'),
        };
    }

    public static async getAll() {
        let productSystems;

        try {
            productSystems = await ProductSystemAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: productSystems.data,
        });

        return Promise.resolve();
    }

    public static async getById(productSystemId: string, locale?: string | null) {
        let productSystem;

        try {
            productSystem = await ProductSystemAPI.getById(productSystemId, locale);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: productSystem.data,
        });

        return Promise.resolve(productSystem);
    }

    public id!: string;
    public name!: string;
    public code!: string;
    public isArchived!: boolean;
    public product!: Product;
    public productSystemDetails!: ProductSystemDetails;
}
