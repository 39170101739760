import { Model } from '@vuex-orm/core';
import PdfAPI from '@/api/PdfAPI';
import DocumentName from '@/models/DocumentName';
import OfferConfiguration from '@/models/OfferConfiguration';
import { CalendarPDF } from '@/interfaces/components/calendar/CalendarPDF';
export default class Pdf extends Model {
    public static entity = 'pdf';

    public static fields() {
        return {
            id: this.attr(''),
            path: this.string(''),
            filename: this.string(''),
            errors: this.attr([]),
            documentName_id: this.attr(null),
            offerConfiguration_id: this.attr(null),
            documentName: this.belongsTo(DocumentName, 'documentName_id'),
            offerConfiguration: this.belongsTo(OfferConfiguration, 'offerConfiguration_id'),
        };
    }

    public static async addToOffer(offerId: string, documentTypeId: string, pdfTypeId: string) {
        let pdf;
        try {
            pdf = await PdfAPI.addPDF(offerId, documentTypeId, pdfTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: pdf.data,
        });
        return Promise.resolve();
    }

    public static async updateExisting(offerId: string, documentTypeId: string, pdfTypeId: string, pdfId: string) {
        let pdf;
        try {
            pdf = await PdfAPI.updateExisting(offerId, documentTypeId, pdfTypeId, pdfId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: pdf.data,
        });
        return Promise.resolve();
    }

    public static async previewOfferPDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
        return await PdfAPI.previewPDF(offerId, documentTypeId, pdfTypeId);
    }

    public static async downloadOfferPDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
        return await PdfAPI.downloadPDF(offerId, documentTypeId, pdfTypeId);
    }

    public static async downloadCalendarPDF(payload: CalendarPDF[]) {
        return await PdfAPI.downloadCalendarPDF(payload);
    }

    public id!: string;
    public path!: string;
    public filename!: string;
    public errors!: any[];
    public documentName!: DocumentName;
    public offerConfiguration!: OfferConfiguration;
}
