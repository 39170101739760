import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class LeadOriginAPI {
    public static async getAll() {
        let leadOrigins;

        try {
            leadOrigins = await ApiController.get(ApiRoutes.leadOrigin.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadOrigins);
    }
    public static async getById(leadOriginId: string) {
        let leadOrigin;

        try {
            leadOrigin = await ApiController.get(ApiRoutes.leadOrigin.getById(leadOriginId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadOrigin);
    }

    public static async delete(leadOriginId: string) {

        try {
            await ApiController.delete(ApiRoutes.leadOrigin.delete(leadOriginId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(leadOriginId: string, leadOriginInfo: AvailableLabelFields) {
        let leadOrigin;
        const request = {
            data: {
                type: 'lead_origins',
                id: leadOriginId,
                attributes: {
                    name: leadOriginInfo.name,
                },
            },
        };

        try {
            leadOrigin =
                await ApiController.patch(
                    ApiRoutes.leadOrigin.update(leadOriginId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadOrigin);
    }

    public static async create(leadOriginInfo: AvailableLabelFields) {
        let leadOrigin;
        const request = {
            data: {
                type: 'lead_origins',
                attributes: {
                    name: leadOriginInfo.name,
                },
            },
        };

        try {
            leadOrigin =
                await ApiController.post(
                    ApiRoutes.leadOrigin.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadOrigin);
    }
}
