import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import UploadedPdfAPI from '@/api/UploadedPdfAPI';

export default class UploadedPdf extends Model {

    public static entity = 'uploadedPdf';

    public static fields() {
        return {
            id: this.attr(''),
            document: this.string(''),
            uuid: this.string(''),
            title: this.string(''),
            type: this.string(''),
            locale: this.string(''),
        };
    }

    public static async createNew(formData: FormData) {
        let uploadedPdf;
        try {
            uploadedPdf = await UploadedPdfAPI.createNew(formData) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: uploadedPdf.data,
        });

        return Promise.resolve(uploadedPdf);
    }

    public static async updateExisting(file: any) {
        let uploadedPdf;
        try {
            uploadedPdf = await UploadedPdfAPI.updateExisting(file) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: uploadedPdf.data,
        });

        return Promise.resolve(uploadedPdf);
    }

    public id!: string;
    public document!: string;
    public uuid!: string;
    public title!: string;
    public type!: string;
    public locale!: string;
}
