import ContactPerson from '@/models/ContactPerson';

export default class ContactPersonRepository {
    public static getAll() {
        return ContactPerson.all();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return ContactPerson.query().whereId(id).withAllRecursive().first();
    }
}
