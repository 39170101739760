
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import {
    checkIfLocalStorageParametersExist,
    handleAndCleanUpSearchingForEntity,
    setCorrectPaginationAndSorters,
} from '@/helpers/SearchHelper';
import User from '@/models/User';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import CountriesDropdown from '@/components/global/CountriesDropdown.vue';
import { FilterUrl } from '@/interfaces/general/FilterUrl';
import { LocalStorageService } from '@/services/LocalStorageService';
import MultipleSelect from '@/components/global/MultipleSelect.vue';
import { getAppropriateFilterOptions } from '@/helpers/NewProjectSearchBarHelper';
import { ElementFilterType } from '@/enums/global/ElementFilterType';
import LabelReporistory from '@/repositories/LabelReporistory';
import CountryRepository from '@/repositories/CountryRepository';
import moment from 'moment';

@Component({
    name: 'TableSearchBar',
    components: { CountriesDropdown, AutocompleteGroupInput, MultipleSelect },
})
export default class TableSearchBar extends Vue {
    @Prop({ required: true }) private entityType!: string;
    @Prop({ required: true }) private search!: { isActive: boolean };
    @Prop({ required: true }) private currentUser!: User;

    @Prop({ default: null }) private externalFilterOptions!: SearchEntityFilter[] | null; // filter always active
    @Prop({ default: true }) private shrink!: boolean; // should searchbar shrink when filters are opened
    @Prop({ default: false }) private ignoreLocalFilters!: boolean; // ignore locally saved filters
    @Prop({ default: false }) private isLoading!: boolean;

    private searchQuery = '';
    private availableFilters: SearchEntityFilter[] = [];
    private elementFilterType = ElementFilterType;
    private visible = false;
    private allChecked = false;
    private localStorageParameters: any = null;
    private areFiltersVisible = false;
    private activeFilters: SearchEntityFilter[] = [];
    private fromFilter: string = 'all';

    private get sidebarCollapsed() {
        return this.$store.getters['generic/sidebarCollapsed'];
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private get placeholder() {
        if (this.entityType === 'clients') {
            return this.$t('Pretražite klijente po odabranim poljima');
        }

        return this.$t('Pretražite projekte po odabranim poljima');
    }

    private get defaultSortField() {
        if (this.entityType === 'clients') {
            return 'name';
        }

        return 'sortDate';
    }

    private get defaultSortOrder() {
        return this.entityType === 'clients' ? 'ascend' : 'descend';
    }
    private get showSourceTabs() {
        const groupId = String(this.$store.getters['jwtData/currentUserGroup']);
        return (
            (this.entityType === 'Vsi projekti' || this.entityType === 'Ponudbe' || this.entityType === 'Naročila') &&
            groupId !== '1'
        );
    }

    private toggleAllChecked() {
        if (this.allChecked) {
            this.availableFilters
                ?.filter((f) => f.type === this.elementFilterType.Switch)
                ?.forEach((f) => {
                    f.value = true;
                });
        } else {
            this.availableFilters
                ?.filter((f) => f.type === this.elementFilterType.Switch)
                ?.forEach((f) => {
                    f.value = false;
                });
        }
    }
    private onTabChange(e: string) {
        const searchParameters = {
            isSearchActive: this.localStorageParameters.isSearchActive ?? true,
            entityType: this.entityType ?? this.localStorageParameters.entityType,
            availableFilters: this.availableFilters,
            page: this.localStorageParameters?.page ?? 1,
        } as FilterUrl;
        this.onSearchWithParameters(searchParameters);
    }
    @Watch('availableFilters', { immediate: true, deep: true })
    private shouldToggleAll() {
        if (
            this.availableFilters?.filter((f) => f.type === this.elementFilterType.Switch && f.value === false)
                ?.length === 0
        ) {
            this.allChecked = true;
        } else {
            this.allChecked = false;
        }
    }
    private getFilters() {
        this.availableFilters = getAppropriateFilterOptions({
            entityType: this.entityType,
            currentUser: this.currentUser,
            shouldSetDefaultUser: true,
        });
        if (checkIfLocalStorageParametersExist(this.currentUser, this.entityType) && !this.ignoreLocalFilters) {
            this.localStorageParameters = JSON.parse(
                LocalStorageService.get(
                    // @ts-ignore
                    `${this.entityType}-${this.currentUser.id}`
                ) as string
            );
            // this.availableFilters = this.localStorageParameters?.availableFilters;
            this.localStorageParameters?.availableFilters.forEach((filter: SearchEntityFilter) => {
                this.setFilterInUI(filter);
            });
        }
        this.activeFilters = this.availableFilters.filter((f: SearchEntityFilter) => {
            if (f.value && f.value !== null && f.type !== this.elementFilterType.Switch) {
                if (!Array.isArray(f.value) && f.value !== '') {
                    return true;
                }
                if (Array.isArray(f.value) && f.value.length > 0) {
                    return true;
                }
            }
            return false;
        });
    }
    private async onSearch({
        results,
        page,
        sortField,
        sortOrder,
        shouldSetDefaultUser = true,
        shouldOverrideSortParameters = true,
    }: any = {}) {
        if (!checkIfLocalStorageParametersExist(this.currentUser, this.entityType)) {
            this.getFilters();
        }
        const newSearchParameters = {
            isSearchActive: this.search.isActive,
            searchQuery: this.searchQuery,
            entityType: this.entityType,
            availableFilters:
                this.externalFilterOptions && this.externalFilterOptions?.length > 0
                    ? [...this.availableFilters, ...this.externalFilterOptions]
                    : [...this.availableFilters],
            sortField,
            sortOrder,
            results,
            page,
        };
        this.startLoading();
        setCorrectPaginationAndSorters(
            newSearchParameters,
            this.localStorageParameters,
            this.defaultSortField,
            this.defaultSortOrder,
            shouldOverrideSortParameters
        );
        try {
            await handleAndCleanUpSearchingForEntity(
                newSearchParameters,
                this.currentUser.id,
                true,
                false,
                this.showSourceTabs ? this.fromFilter : undefined
            );
        } catch (e) {
            EventBus.$emit(EventBusEvents.searchFinished);

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            return;
        }
        this.activeFilters = this.availableFilters.filter((f: SearchEntityFilter) => {
            if (f.value && f.value !== null && f.type !== this.elementFilterType.Switch) {
                if (!Array.isArray(f.value) && f.value !== '') {
                    return true;
                }
                if (Array.isArray(f.value) && f.value.length > 0) {
                    return true;
                }
            }
            return false;
        });
    }

    private async onSearchWithParameters(searchParameters: FilterUrl) {
        let localStorageParameters = null;
        this.availableFilters = searchParameters.availableFilters; // , ...this.externalFilterOptions
        // ignore search requests that don't match current entity type
        if (searchParameters.entityType !== this.entityType) {
            return;
        }

        this.startLoading();
        this.setOrUpdateSearchQuery(searchParameters);

        if (checkIfLocalStorageParametersExist(this.currentUser, this.entityType) && !this.ignoreLocalFilters) {
            localStorageParameters = JSON.parse(
                LocalStorageService.get(
                    // @ts-ignore
                    `${this.entityType}-${this.currentUser.id}`
                ) as string
            );
        }

        setCorrectPaginationAndSorters(
            searchParameters,
            localStorageParameters,
            this.defaultSortField,
            this.defaultSortOrder,
            true
        );

        if (this.externalFilterOptions && this.externalFilterOptions?.length > 0) {
            searchParameters = {
                ...searchParameters,
                availableFilters: [...searchParameters.availableFilters, ...this.externalFilterOptions],
            };
        }

        try {
            await handleAndCleanUpSearchingForEntity(
                searchParameters,
                this.currentUser.id,
                true,
                false,
                this.showSourceTabs ? this.fromFilter : this.entityType === 'Povpraševanja' ? 'all' : undefined
            );
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });

            EventBus.$emit(EventBusEvents.searchFinished);
            return;
        }
        this.activeFilters = this.availableFilters.filter((f: SearchEntityFilter) => {
            if (f.value && f.value !== null && f.type !== this.elementFilterType.Switch) {
                if (!Array.isArray(f.value) && f.value !== '') {
                    return true;
                }
                if (Array.isArray(f.value) && f.value.length > 0) {
                    return true;
                }
            }
            return false;
        });
    }

    private setOrUpdateSearchQuery(searchParameters: FilterUrl) {
        if (searchParameters.searchQuery != null) {
            this.searchQuery = searchParameters.searchQuery;
        } else {
            searchParameters.searchQuery = this.searchQuery;
        }
    }

    private removeFilter(filter: SearchEntityFilter) {
        // this.activeFilters = this.activeFilters.filter((f) => f.label !== filter.label);
        if (this.isLoading) {
            return;
        }
        const filterIndex = this.availableFilters.findIndex((f) => f.label === filter.label);
        if (filterIndex >= 0) {
            this.availableFilters[filterIndex].value = filter.defaultValue;
            if (this.availableFilters[filterIndex].filter === 'assignedUser.id') {
                this.availableFilters[filterIndex].value = '';
            }
        }
        EventBus.$emit(EventBusEvents.runEntitySearchWithParameters, {
            isSearchActive: true,
            entityType: this.entityType,
            availableFilters: this.availableFilters,
            page: 1,
        });
    }

    private formatFilter(filter: SearchEntityFilter) {
        let resultString = filter.label + ': ';
        if (Array.isArray(filter.value) && filter.type === this.elementFilterType.DateRange) {
            for (let i = 0; i < filter.value.length; i++) {
                const date = new Date(filter.value[i]);
                if (date.toString() !== 'Invalid Date') {
                    resultString += moment(date.toISOString()).format(this.locale.lang.dateFormat);
                    resultString += i < filter.value.length - 1 ? ' - ' : '';
                }
            }
            return resultString;
        } else if (Array.isArray(filter.value) && filter.label?.toLowerCase()?.includes('status')) {
            for (let i = 0; i < filter.value.length; i++) {
                resultString += this.getLabelName(filter.value[i]);
                resultString += i < filter.value.length - 1 ? ', ' : '';
            }
            return resultString;
        } else if (filter.type === this.elementFilterType.CountryPicker) {
            resultString += CountryRepository.getById(filter.value as string)?.name ?? '';
            return resultString;
        } else if (Array.isArray(filter.value) && filter.type === this.elementFilterType.MultipleSelect) {
            if (typeof (filter as any).dropdownSource === 'function') {
                const dropdownOptions = (filter as any).dropdownSource();
                if (!dropdownOptions) {
                    return resultString;
                }
                let id = '';
                for (let i = 0; i < filter.value.length; i++) {
                    id = filter.value[i] as string;
                    resultString += dropdownOptions.find((o: any) => o.id === id)?.name ?? '';
                    resultString += i < filter.value.length - 1 ? ', ' : '';
                }
            }
            return resultString;
        } else if (filter.type === this.elementFilterType.Dropdown) {
            if (typeof (filter as any).dropdownSource === 'function') {
                const dropdownOptions = (filter as any).dropdownSource();
                if (!dropdownOptions) {
                    return resultString;
                }
                resultString += dropdownOptions.find((o: any) => o.id === filter.value)?.name ?? '';
            }
            return resultString;
        } else if (typeof filter.value === 'string') {
            resultString += ': ' + filter.value;
            return resultString;
        }

        return filter.label;
    }
    private getLabelName(labelId: string) {
        let id = null;
        const index = labelId.indexOf('-') + 1;
        if (index >= 0) {
            id = labelId.slice(index);
        }
        const label = LabelReporistory.getById(id);
        return label ? label.name : '';
    }
    private setFilterInUI(availableFilter: SearchEntityFilter) {
        const activeOptions = this.availableFilters;

        const selectedFilterOption = activeOptions.find((filterOption: SearchEntityFilter) => {
            return filterOption.filter === availableFilter.filter;
        });

        // @ts-ignore
        if (selectedFilterOption == null) {
            return;
        }

        selectedFilterOption.value = availableFilter.value;
    }
    private onClearFilters() {
        this.activeFilters.forEach((f) => this.removeFilter(f));
    }
    private toggleFilters() {
        this.areFiltersVisible = !this.areFiltersVisible;
    }

    private fireToggleFilters() {
        EventBus.$emit(EventBusEvents.toggleFilters);
    }

    private startLoading() {
        this.$emit('update:isLoading', true);
    }

    private created() {
        setTimeout(() => {
            // Wait 500 ms to prevent getting wrong entity type before its updated
            this.getFilters();
        }, 500);
        EventBus.$on(EventBusEvents.runEntitySearch, this.onSearch);
        EventBus.$on(EventBusEvents.runEntitySearchWithParameters, this.onSearchWithParameters);
        EventBus.$on(EventBusEvents.toggleFilters, this.toggleFilters);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.runEntitySearch, this.onSearch);
        EventBus.$off(EventBusEvents.runEntitySearchWithParameters, this.onSearchWithParameters);
        EventBus.$off(EventBusEvents.toggleFilters, this.toggleFilters);
    }

    @Watch('fromFilter', { immediate: true })
    private onFromFilterChange() {
        EventBus.$emit(EventBusEvents.projectsFromFilterChanged, this.fromFilter);
    }
}
