import { AxiosError } from 'axios';
import { notification } from 'ant-design-vue';
import i18n from '@/i18n';

/**
 * Show error notifications from given error
 * @param error - The error that occurred
 */
export function showErrorNotifications(error: AxiosError) {
    if (error.response == null) {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: (error as Error).message,
        });
        return;
    }

    error.response.data.errors.forEach(({ detail }: { detail: string }) => {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: detail,
        });
    });
}

/**
 * Show the successful change notification
 */
export function showChangeSuccessNotification() {
    notification.success({
        message: i18n.t('Promjena uspješna!') as string,
        description: '',
    });
}
