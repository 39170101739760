import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import UserGroupConnectionConfig from '@/models/interfaces/UserGroupConnection';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { DynamicObject } from '@/interfaces/general/DynamicObject';

export default class UserGroupConnectionAPI {
    public static async getAll() {
        let userGroupConnections;

        try {
            userGroupConnections = (await ApiController.get(ApiRoutes.userGroupConnection.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroupConnections);
    }

    public static async getById(userGroupConnectionId: string) {
        let userGroupConnection;

        try {
            userGroupConnection = (await ApiController.get(
                ApiRoutes.userGroupConnection.getById(userGroupConnectionId)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroupConnection);
    }

    public static async update(userGroupConnectionId: string, userGroupConnectionInfo: UserGroupConnectionConfig) {
        let userGroupConnection;
        const userRightsObject: any = {};
        if (userGroupConnectionInfo.userRights) {
            userGroupConnectionInfo.userRights
                .filter((userRight: string) => Object.values(UserRightsEnum).includes(userRight as UserRightsEnum))
                .forEach((userRight: string) => {
                    this.updateUserRightMap(userRightsObject, userRight);

                    this.updateUserRightsMapBasedOnCurrentSelection(userRight, userRightsObject);
                });
        }

        const request = {
            data: {
                type: 'user_group_connections',
                id: userGroupConnectionId,
                attributes: {
                    userRights: userRightsObject,
                },
                relationships: {
                    user: {
                        data: {
                            type: 'users',
                            id: userGroupConnectionInfo.user.id,
                        },
                    },
                    userGroup: {
                        data: {
                            type: 'user_groups',
                            id: userGroupConnectionInfo.userGroup.id,
                        },
                    },
                },
            },
        };

        try {
            userGroupConnection = (await ApiController.patch(
                ApiRoutes.userGroupConnection.update(userGroupConnectionId),
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroupConnection);
    }

    public static async updateUserRights(userGroupConnectionId: string, userRights: DynamicObject) {
        const request = {
            data: {
                type: 'user_group_connections',
                id: userGroupConnectionId,
                attributes: {
                    userRights,
                },
            },
        };

        try {
            await ApiController.patch(ApiRoutes.userGroupConnection.update(userGroupConnectionId), request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async create(userGroupConnectionInfo: UserGroupConnectionConfig) {
        let userGroupConnection;
        const userRightsObject: any = {};
        if (userGroupConnectionInfo.userRights) {
            userGroupConnectionInfo.userRights
                .filter((userRight: string) => Object.values(UserRightsEnum).includes(userRight as UserRightsEnum))
                .forEach((userRight: string) => {
                    this.updateUserRightMap(userRightsObject, userRight);

                    this.updateUserRightsMapBasedOnCurrentSelection(userRight, userRightsObject);
                });
        }
        const request = {
            data: {
                type: 'user_group_connections',
                attributes: {
                    userRights: userRightsObject,
                },
                relationships: {
                    user: {
                        data: {
                            type: 'users',
                            id: userGroupConnectionInfo.user.id,
                        },
                    },
                    userGroup: {
                        data: {
                            type: 'user_groups',
                            id: userGroupConnectionInfo.userGroup.id,
                        },
                    },
                },
            },
        };
        try {
            userGroupConnection = (await ApiController.post(
                ApiRoutes.userGroupConnection.create,
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroupConnection);
    }

    public static async delete(userGroupConnectionId: string) {
        try {
            await ApiController.delete(ApiRoutes.userGroupConnection.delete(userGroupConnectionId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private static isEditClientDetailedRule(rule: string) {
        return rule === UserRightsEnum.EDIT_CLIENT_DETAILED;
    }

    private static isViewPurchasePriceManipulationsRule(rule: string) {
        return rule === UserRightsEnum.VIEW_PURCHASE_PRICE_MANIPULATIONS;
    }

    private static isEditPurchasePriceManipulationsRule(rule: string) {
        return rule === UserRightsEnum.EDIT_PURCHASE_PRICE_MANIPULATIONS;
    }

    private static isViewChatRule(rule: string) {
        return rule === UserRightsEnum.VIEW_CHAT;
    }

    private static updateUserRightMap(userRightMap: any, userRight: string) {
        userRightMap[userRight] = userRight;
        return {
            ...userRightMap,
        };
    }

    private static updateUserRightsMapBasedOnCurrentSelection(userRight: string, userRightsObject: DynamicObject) {
        if (this.isEditClientDetailedRule(userRight)) {
            this.updateUserRightMap(userRightsObject, UserRightsEnum.EDIT_CLIENT);
        }

        if (this.isViewChatRule(userRight)) {
            this.updateUserRightMap(userRightsObject, UserRightsEnum.VIEW_ALL_NEXT_STEPS);
        }
    }
}
