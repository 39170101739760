import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';


const actions: ActionTree<ILocalState, {}> = {
    async updateAreOffersLoading({commit}, newState: boolean) {
        commit('setAreOffersLoading', newState);
    },
    async updateIsProjectLoading({commit}, newState: boolean) {
        commit('setIsProjectLoading', newState);
    },
    async updateIsLeadLoading({commit}, newState: boolean) {
        commit('setIsLeadLoading', newState);
    },
    async updateAreNextStepsLoading({commit}, newState: boolean) {
        commit('setAreNextStepsLoading', newState);
    },
    async setAllLoadingState({commit}, newState: boolean) {
        commit('setAllLoadingState', newState);
    },
};

export default actions;
