import { Model } from '@vuex-orm/core';
import SystemWorkflowNode from '@/models/SystemWorkflowNode';
import WorkflowNodeConnection from '@/models/WorkflowNodeConnection';
import { WorkflowRequestResource } from '@/interfaces/components/Workflow/WorkflowRequestResource';
import ProjectLabelTemplateWorkflowNode from '@/models/ProjectLabelTemplateWorkflowNode';
import WorkflowTemplateAPI from '@/api/WorkflowTemplateAPI';
import Locale from '@/models/Locale';

export default class WorkflowTemplate extends Model {
    public static entity = 'workflowTemplate';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            systemNodes_ids: this.attr([]),
            systemNodes: this.hasManyBy(SystemWorkflowNode, 'systemNodes_ids'),
            connections_ids: this.attr([]),
            connections: this.hasManyBy(WorkflowNodeConnection, 'connections_ids'),
            projectLabelTemplateNodes_ids: this.attr([]),
            projectLabelTemplateNodes:
                this.hasManyBy(ProjectLabelTemplateWorkflowNode, 'projectLabelTemplateNodes_ids'),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
        };
    }

    public static async getAll() {
        let workflowTemplates;
        try {
            workflowTemplates = await WorkflowTemplateAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: workflowTemplates.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelTemplateNodes',
                'projectLabelTemplate',
            ],
        });
        return Promise.resolve();
    }

    public static async getById(workflowTemplateId: string) {
        let workflowTemplate;
        try {
            workflowTemplate = await WorkflowTemplateAPI.getById(workflowTemplateId);
        } catch (e) {
            return Promise.reject(e);
        }
        await WorkflowTemplate.deleteAll();
        await this.insertOrUpdate({
            data: workflowTemplate.data,
            insertOrUpdate: [],
        });
        return Promise.resolve(workflowTemplate);
    }

    public static async getByLocale(localeId: string) {
        let workflowTemplate;
        try {
            workflowTemplate = await WorkflowTemplateAPI.getByLocale(localeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await WorkflowTemplate.deleteAll();
        await this.insertOrUpdate({
            data: workflowTemplate.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelTemplateNodes',
                'projectLabelTemplate',
                'locale',
            ],
        });
        return Promise.resolve(workflowTemplate);
    }

    public static async updateExisting(workflowTemplateRequestResource: WorkflowRequestResource) {
        let workflowTemplate;
        try {
            workflowTemplate = await WorkflowTemplateAPI.update(
                workflowTemplateRequestResource.workflowId as string,
                workflowTemplateRequestResource.workflowPayload,
            );
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: workflowTemplate.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelTemplateNodes',
                'projectLabelTemplate',
                'locale',
            ],
        });
        return Promise.resolve(workflowTemplate);
    }

    public static async createNew(workflowTemplateRequestResource: WorkflowRequestResource) {
        let workflowTemplate;
        try {
            workflowTemplate = await WorkflowTemplateAPI.create(
                workflowTemplateRequestResource.workflowPayload,
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: workflowTemplate.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelTemplateNodes',
                'projectLabelTemplate',
                'locale',
            ],
        });
        
        return Promise.resolve(workflowTemplate);
    }

    public static async deleteExisting(workflowTemplateId: string) {
        try {
            await WorkflowTemplateAPI.delete(workflowTemplateId);
        } catch (e) {
            return Promise.reject(e);
        }
        await WorkflowTemplate.delete(workflowTemplateId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string;
    public projectLabelTemplateNodes!: ProjectLabelTemplateWorkflowNode[];
    public systemNodes!: SystemWorkflowNode[];
    public connections!: WorkflowNodeConnection[];
    public locale!: Locale;
}
