export function generateGroupedList(list: any) {
    let groupedClientLists = list.reduce((accumulator: any, element: any) => {
        // @ts-ignore
        const group = element.name[0].toUpperCase();
        // @ts-ignore
        if (!accumulator[group]) {
            // @ts-ignore
            accumulator[group] = {group, children: [element]};
        } else {
            // @ts-ignore
            accumulator[group].children.push(element);
        }
        return accumulator;
    }, {});
    groupedClientLists = Object.keys(groupedClientLists).sort().map((key) => {
        // @ts-ignore
        return groupedClientLists[key];
    });
    return groupedClientLists;
}

export function generateDynamicGroupedList(list: any, groupBy: string) {
    const groupedLists = list.reduce((accumulator: any, element: any) => {
        const properties = groupBy.split('.');
        let evaluatedElement = Object.assign({}, element);

        for (const property of properties) {
            const candidate = evaluatedElement[property];
            if (candidate !== undefined) {
                evaluatedElement = candidate;
            } else {
                return;
            }
        }
        // @ts-ignore
        const group = evaluatedElement;
        // @ts-ignore
        if (!accumulator[group]) {
            // @ts-ignore
            accumulator[group] = {group, children: [element]};
        } else {
            // @ts-ignore
            accumulator[group].children.push(element);
        }
        return accumulator;
    }, {});

    return groupedLists;
}
