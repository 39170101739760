import { Model } from '@vuex-orm/core';
import ProductGroupAPI from '@/api/ProductGroupAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class ProductGroup extends Model {
    public static entity = 'productGroup';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
        };
    }

    public static async getAll() {
        let productGroups;

        if (ss.has('productGroups')) {
            productGroups = ss.getAndParse('productGroups');
        } else {
            try {
                const fetchedProductGroups = await ProductGroupAPI.getAll();
                productGroups = fetchedProductGroups.data;

                ss.stringifyAndSave('productGroups', productGroups);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: productGroups,
        });

        return Promise.resolve();
    }

    public static async getById(productGroupId: string) {
        let productGroup;
        try {
            productGroup = await ProductGroupAPI.getById(productGroupId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: productGroup.data,
        });
        return Promise.resolve(productGroup);
    }

    public static async updateExisting(productGroupId: string, productGroupInfo: any) {
        let productGroup;
        try {
            productGroup = await ProductGroupAPI.update(productGroupId, productGroupInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: productGroup.data,
        });
        return Promise.resolve(productGroup);
    }

    public static async createNew(productGroupInfo: any) {
        let productGroup;
        try {
            productGroup = await ProductGroupAPI.create(productGroupInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productGroup.data,
        });
        return Promise.resolve(productGroup);
    }

    public static async deleteExisting(productGroupId: string) {
        try {
            await ProductGroupAPI.delete(productGroupId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ProductGroup.delete(productGroupId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string;
}
