import { Model } from '@vuex-orm/core';
import OrderConfigurationConfig from '@/models/interfaces/OrderConfiguration';
import OrderConfigurationAPI from '@/api/OrderConfigurationAPI';
import OrderPdfProperties from '@/models/OrderPdfProperties';
import BankAccount from '@/models/BankAccount';
import Currency from '@/models/Currency';
import { OrderPriceType } from '@/enums/global/OrderPriceType';

export default class OrderConfiguration extends Model {

    public static entity = 'orderConfiguration';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            isArchived: this.boolean(false),
            showName: this.boolean(false),
            showProductDetails: this.boolean(false),
            showPricelists: this.boolean(false),
            showImages: this.boolean(false),
            showDifferences: this.boolean(false),
            showProductImageOnRight: this.boolean(false),
            showImageOnce: this.boolean(false),
            showUpn: this.boolean(false),
            showDiscounts: this.string(''),
            showPrice: this.string(''),
            orderPdfProperties_id: this.attr(null),
            orderPdfProperties: this.belongsTo(OrderPdfProperties, 'orderPdfProperties_id'),
            bankAccount: this.belongsTo(BankAccount, 'bankAccount_id'),
            bankAccount_id: this.attr(null),
            currency: this.belongsTo(Currency, 'currency_id'),
            currency_id: this.attr(null),
            showPurchasePrice: this.boolean(false),
            showRecommendedPrice: this.boolean(false),
        };
    }

    public static async getAll() {
        let orderConfigurations;
        try {
            orderConfigurations = await OrderConfigurationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: orderConfigurations.data,
            insertOrUpdate: ['currency'],
        });
        return Promise.resolve();
    }

    public static async getById(orderConfigurationId: string) {
        let orderConfiguration;
        try {
            orderConfiguration = await OrderConfigurationAPI.getById(orderConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: orderConfiguration.data,
        });
        return Promise.resolve(orderConfiguration);
    }

    public static async updateExisting(orderConfigurationId: string, orderConfigurationInfo: OrderConfigurationConfig) {
        let orderConfiguration;
        try {
            orderConfiguration = await OrderConfigurationAPI.update(orderConfigurationId, orderConfigurationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(orderConfiguration);
    }

    public static determineDefaultPrintPrice(showPurchasePrice: boolean, showRecommendedPrice: boolean) {
        if (showPurchasePrice) {
            return OrderPriceType.PURCHASE_PRICE;
        }

        if (showRecommendedPrice) {
            return OrderPriceType.RECOMMENDED_PRICE;
        }

        return OrderPriceType.SELLING_PRICE;
    }
    public id!: string;
    public name!: string;
    public showName!: boolean;
    public showProductDetails!: boolean;
    public showPricelists!: boolean;
    public showImages!: boolean;
    public showDifferences!: boolean;
    public showProductImageOnRight!: boolean;
    public showImageOnce!: boolean;
    public isArchived!: boolean;
    public showUpn!: boolean;
    public showDiscounts!: string;
    public showPrice!: string;
    public currency!: Currency;
    public showPurchasePrice!: boolean;
    public showRecommendedPrice!: boolean;
    public printPrice = OrderConfiguration.determineDefaultPrintPrice(
        this.showPurchasePrice,
        this.showRecommendedPrice,
    );
}
