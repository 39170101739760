
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { determineFieldVisibility, extractUpdateFunctionValues } from '@/helpers/FieldFunctionHelper';
import { Action, Getter } from 'vuex-class';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';
import ChecklistFieldModule from '@/components/views/productView/ChecklistFieldModule.vue';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import { debounce } from 'vue-debounce';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { PriceInputObject } from '@/interfaces/components/configurator/PriceInputObject';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { MultipositionDeleteMap } from '@/interfaces/general/MultipositionDeleteMap';
import { parseAndTransformStringWithDynamicValues } from '@/helpers/JsonLogicHelper';
import { UserRepository } from '@/repositories/UserRepository';
import ChecklistDropdownFieldModule from '@/components/views/productView/ChecklistDropdownFieldModule.vue';
import { areTwoProductFormValuesSame } from '@/helpers/FormDataHelper';
import { checklistFieldsWithErrors } from '@/helpers/OfferHelper';
import Offer from '@/models/Offer';
import { filterProductsFromOfferItems } from '@/helpers/OfferItemTableHelper';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import { defaultValueWarnings as defaultValueWarningsConfig } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import { C } from '@fullcalendar/core/internal-common';

@Component({
    name: 'ProductSlotRow',
    components: { ChecklistFieldModule },
})
export default class ProductSlotRow extends Vue {
    public hasPriceListError = false;
    public isDisabledFieldArray: boolean[] = [];
    @Prop({ default: null }) private addNewRow!: () => void | null;
    @Prop({ default: null }) private updateItemRowsIsUpdatingState!: (itemRowIndex: number, newState: boolean) => void;
    @Prop({ required: true }) private itemRowIndex!: number;
    private watchFunctionInstance: null | (() => void) = null;
    @Prop({ required: true }) private productFormId!: number;
    @Prop({ required: true }) private isFirst!: boolean;
    @Prop({ required: true }) private selectedSegment!: string;
    @Prop({ required: true }) private isUpdating!: boolean;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ required: true }) private hiddenIndices!: boolean[];
    @Prop({ default: null }) private offer!: Offer;
    @Prop({ default: false }) private isCustomProduct!: boolean;
    @Prop({ required: true }) private isEditMode!: boolean;
    @Prop({ required: true }) private productData!: ProductRowItem;
    @Prop({ required: true }) private deleteExistingRow!: (multipositionDeleteMap: MultipositionDeleteMap) => void;
    @Prop({ required: true }) private level!: number;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    @Action('configurator/calculatePrice') private calculatePrice!: ({
        activeProductFormValues,
        productFormId,
    }: PriceInputObject) => Promise<PriceItem[]>;
    private priceListError = '';
    private isActive = false;
    private pIdFieldErrors: string[] = [];
    private oldProductFormValues: null | ActiveProductFormValueObject = null;
    private debouncedUpdateDisabledStates: any = debounce(() => {
        this.updateDisabledStates();
    }, 200);
    private debouncedrunUpdateFunctions: any = debounce(
        (
            updateFunctions: UpdateFunctionEntry[],
            activeProductFormId: number,
            productFormValues: ActiveProductFormValueObject
        ) => {
            this.runUpdateFunctions(updateFunctions, activeProductFormId, productFormValues);
        },
        100
    );

    private debouncedUpdateItemRowsIsUpdatingState = debounce((itemRowIndex: number, newState: boolean) => {
        this.updateItemRowsIsUpdatingState(itemRowIndex, newState);
    }, 150);
    private debouncedRunOptionsFunctions = debounce(() => {
        this.runOptionsFunctions();
    }, 100);

    private debounceToggleIsActive = debounce((state) => {
        this.toggleIsActive(state);
    }, 250);

    private get shouldShowErrorClass() {
        return checklistFieldsWithErrors(this.productData).length > 0;
    }

    private get shouldShowWarningClass() {
        return !this.shouldShowErrorClass && checklistFieldsWithErrors(this.productData, true).length > 0;
    }

    private get productFormValues() {
        return this.getProductFormValues(this.productData.activeProductFormId);
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get showConnectionColor() {
        return (
            this.productData.connection !== null &&
            !this.shouldShowWarningClass &&
            !this.shouldShowErrorClass &&
            !this.isActive
        );
    }

    private toggleIsActive(state: boolean) {
        this.isActive = state;
    }

    private filterProductsFromOfferItems(offerItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>) {
        return filterProductsFromOfferItems(offerItems, false);
    }

    private async runUpdateFunctions(
        updateFunctions: UpdateFunctionEntry[],
        activeProductFormId: number,
        productFormValues: ActiveProductFormValueObject
    ) {
        updateFunctions.forEach((entry: UpdateFunctionEntry) => {
            if (activeProductFormId == null) {
                return Promise.reject();
            }

            const updatedValues: ConfiguratorValue[] = extractUpdateFunctionValues(
                entry.updateFunctions,
                entry.type,
                productFormValues
            );

            if (updatedValues.length > 0) {
                if (!this.checkIfSelectedValueIsValid(entry.id, updatedValues[0])) {
                    return;
                }

                this.updateActiveProductFormValue({
                    pId: entry.id,
                    value: updatedValues[0],
                    productFormId: activeProductFormId,
                });
            }
        });

        return Promise.resolve();
    }

    private checkIfSelectedValueIsValid(id: string, value: ConfiguratorValue) {
        // We return false only if selected value was not in dropdown options at start and is missing right now
        if (!this.defaultValueWarnings) {
            return true;
        }

        const field = this.productData.editableFields.find((field) => field.pId === id);

        if (!field) {
            return true;
        }

        if (field.fieldType !== 'dropdown') {
            return true;
        }

        const isValueAvailable = field.checklistDropdownField?.checklistDropdownOptions.some((dropdownOption: any) => {
            return dropdownOption.vId === value;
        });

        if (isValueAvailable) {
            return true;
        }

        const invalidValueWasDetectedAtMount = this.defaultValueWarnings.warnings.some(
            (warning: any) => warning.pId === id
        );

        return !invalidValueWasDetectedAtMount;
    }

    private get defaultValueWarnings() {
        const warnings = this.$store.getters['configurator/defaultValueWarnings'];

        if (warnings.length) {
            return warnings.find(
                (warning: defaultValueWarningsConfig) => warning.productFormId === this.productData.activeProductFormId
            );
        }

        return null;
    }

    private get productCombinationStyle() {
        if (this.showConnectionColor) {
            return { 'background-color': this.productData.color };
        }

        return '';
    }

    private updateDefaultProductFieldValues(defaultProductFields: ChecklistFieldEntry[]) {
        defaultProductFields.forEach((entry: ChecklistFieldEntry) => {
            if (this.productData.activeProductFormId == null) {
                return;
            }
            this.updateActiveProductFormValue({
                pId: entry.id,
                value: entry.value,
                productFormId: this.productData.activeProductFormId,
            });
        });
    }

    private async updateDisabledStates() {
        const productFormValues = this.getProductFormValues(this.productData.activeProductFormId);
        if (productFormValues == null) {
            return;
        }

        this.isDisabledFieldArray = this.productData.editableFields.map((editableField: ChecklistField) => {
            if (editableField.isSegmentLocked) {
                return true;
            }

            if (!this.checklistFieldHasVisibilityFunction(editableField)) {
                return false;
            }

            return !determineFieldVisibility(editableField.checklistVisibleFunction, productFormValues);
        });
        this.$emit('updateHiddenIndices');
        this.onChecklistError();
    }

    private checklistFieldHasVisibilityFunction(checklistField: ChecklistField) {
        return checklistField.checklistVisibleFunction != null;
    }

    private onUpdatePriceListError(newValue: boolean) {
        this.hasPriceListError = newValue;
    }

    private onOpenContactPopup() {
        const popupData: PopupEventData = {
            popupEvent: PopupEvents.contact,
            data: {
                source: 'product-slot-row',
            },
        };

        EventBus.$emit(EventBusEvents.openContactPopup, popupData);
    }

    private openPriceListOutOfRangePopup(data: any) {
        const popupData: PopupEventData = {
            popupEvent: PopupEvents.priceListOutOfRange,
            data,
        };

        EventBus.$emit(EventBusEvents.openPriceListOutOfRangePopup, popupData);
    }

    private onDelete() {
        this.deleteExistingRow({
            activeProductFormId: this.productData.activeProductFormId,
            offerItemId: this.productData.offerItem ? this.productData.offerItem.offerItemId : null,
        });
    }

    private async mounted() {
        this.watchFunctionInstance = this.$watch('productFormValues', this.onProductFormValueChange, {
            deep: true,
            immediate: true,
        });
        // @ts-ignore
        this.debouncedUpdateItemRowsIsUpdatingState(this.productData.activeProductFormId, false);
    }

    private runOptionsFunctions() {
        if (this.productFormValues == null) {
            return;
        }
        if (this.$refs.dropdownModules) {
            (this.$refs.dropdownModules as Vue[]).forEach((dropdownModule) => {
                (dropdownModule.$refs.field as ChecklistDropdownFieldModule).runVisibleOptionVIDs({
                    activeProductFormValues: this.productFormValues as ActiveProductFormValueObject,
                    activeProductFormId: this.productData.activeProductFormId,
                });
            });
        }

        // @ts-ignore
        this.debouncedUpdateItemRowsIsUpdatingState(this.productData.activeProductFormId, false);
    }

    private async onProductFormValueChange(
        productFormValues: ActiveProductFormValueObject = this.productFormValues as any
    ) {
        if (this.oldProductFormValues && areTwoProductFormValuesSame(productFormValues, this.oldProductFormValues)) {
            return;
        }

        const productFormValuesCopy = Object.assign({}, productFormValues);
        this.oldProductFormValues = Object.freeze(productFormValuesCopy);

        this.updateItemRowsIsUpdatingState(this.productData.activeProductFormId, true);
        this.$nextTick(async () => {
            this.debouncedrunUpdateFunctions(
                this.productData.updateFunctions,
                this.productData.activeProductFormId,
                productFormValues
            );
            this.debouncedUpdateDisabledStates();
            this.debouncedRunOptionsFunctions();
        });
    }

    private onChecklistError() {
        let numberOfNotifications = document.getElementsByClassName(
            `active-product-form-notification-${this.productData.activeProductFormId}`
        ).length;
        while (numberOfNotifications > 0) {
            this.$notification.close(`${this.productData.activeProductFormId}${numberOfNotifications - 1}`);
            numberOfNotifications--;
        }
        EventBus.$emit(EventBusEvents.clearNotifications, this.itemRowIndex);
        let index = 0;

        const checklistErrors = checklistFieldsWithErrors(this.productData, true);
        for (const checklistField of checklistErrors) {
            window.setTimeout(() => {
                if (checklistField.checklistStringField) {
                    // && checklistField.checklistStringField.defaultValue
                    switch (checklistField.fieldType) {
                        case 'abort':
                            EventBus.$emit(EventBusEvents.showNotification, {
                                index: this.itemRowIndex,
                                title: this.$t('Dogodila se greška') as string,
                                description: parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue ?? '',
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                type: 'error',
                            });
                            break;
                        case 'warning':
                            EventBus.$emit(EventBusEvents.showNotification, {
                                index: this.itemRowIndex,
                                title: this.$t('Upozorenje') as string,
                                description: parseAndTransformStringWithDynamicValues(
                                    checklistField.checklistStringField.defaultValue ?? '',
                                    checklistField.checklistStringField.functions,
                                    this.productFormValues,
                                    'function'
                                ),
                                type: 'warning',
                            });
                            break;
                    }
                    index++;
                }
            }, 50);
        }
    }

    private beforeDestroy() {
        if (this.watchFunctionInstance == null) {
            return;
        }

        this.watchFunctionInstance();
    }
}
