import AutomaticPriceManipulation from '@/models/AutomaticPriceManipulation';

export default class AutomaticPriceManipulationRepository {
    public static getAll() {
        return AutomaticPriceManipulation.query().withAll().get();
    }

    public static getById(id: string) {
        return AutomaticPriceManipulation.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return AutomaticPriceManipulation.delete(id);
    }
}
