import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductCategoryAPI {
    public static async getAll() {
        return await ApiController.get(ApiRoutes.getProductCategories) as AxiosResponse;
    }

    public static async getAllWithoutIcons() {
        return await ApiController.get(ApiRoutes.getProductCategoriesWithoutIcons) as AxiosResponse;
    }

    public static async getBasic() {
        return await ApiController.get(ApiRoutes.getBasicProductCategories) as AxiosResponse;
    }
}
