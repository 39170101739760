import UserGroup from '@/models/UserGroup';

export default class UserGroupRepository {
    public static getAll() {
        return UserGroup.query().withAll().get();
    }

    public static getById(id: string | number | undefined | null) {
        if (id == null) {
            return null;
        }
        return UserGroup.query().whereId(String(id)).withAll().first();
    }
}
