import { Model } from '@vuex-orm/core';
import OfferItemPriceManipulationAPI from '@/api/OfferItemPriceManipulationAPI';
import PriceManipulationType from '@/models/PriceManipulationType';
import OfferItem from '@/models/OfferItem';
import OfferItemPriceType from '@/models/OfferItemPriceType';
import CMSUser from './CMSUser';

export default class OfferItemPriceManipulation extends Model {
    public static entity = 'offerItemPriceManipulation';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            value: this.number(0),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            updatedBy_id: this.attr(null),
            updatedBy: this.belongsTo(CMSUser, 'updatedBy_id'),
            priceManipulationType: this.belongsTo(PriceManipulationType, 'priceManipulationType_id'),
            priceManipulationType_id: this.attr(null),
            offerItem: this.belongsTo(OfferItem, 'offerItem_id'),
            offerItem_id: this.attr(null),
            offerItemPriceType: this.belongsTo(OfferItemPriceType, 'offerItemPriceType_id'),
            offerItemPriceType_id: this.attr(null),
        };
    }

    public static async getAll() {
        let offerItemPriceManipulations;
        try {
            offerItemPriceManipulations = await OfferItemPriceManipulationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerItemPriceManipulations.data,
            insertOrUpdate: ['priceManipulationType', 'offerItemPriceType'],
        });
        return Promise.resolve();
    }

    public static async getById(offerItemPriceManipulationId: string) {
        let offerItemPriceManipulation;
        try {
            offerItemPriceManipulation = await OfferItemPriceManipulationAPI.getById(offerItemPriceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: offerItemPriceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'offerItemPriceType'],
        });
        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async updateExisting(offerItemPriceManipulationId: string, offerItemPriceManipulationInfo: any) {
        let offerItemPriceManipulation;
        try {
            offerItemPriceManipulation = await OfferItemPriceManipulationAPI.update(
                offerItemPriceManipulationId,
                offerItemPriceManipulationInfo
            );
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: offerItemPriceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'offerItemPriceType'],
        });
        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async createNew(offerItemPriceManipulationInfo: any) {
        let offerItemPriceManipulation;
        try {
            offerItemPriceManipulation = await OfferItemPriceManipulationAPI.create(offerItemPriceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insert({
            data: offerItemPriceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'offerItemPriceType'],
        });
        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async deleteExisting(offerItemPriceManipulationId: string) {
        try {
            await OfferItemPriceManipulationAPI.delete(offerItemPriceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OfferItemPriceManipulation.delete(offerItemPriceManipulationId);
        return Promise.resolve();
    }

    public static async deleteAllExisting(offerItemId: string) {
        try {
            await OfferItemPriceManipulationAPI.deleteAll(offerItemId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OfferItemPriceManipulation.deleteAll();
        return Promise.resolve();
    }

    public id!: string;
    public name!: string | null;
    public value!: number;
    public priceManipulationType!: PriceManipulationType;
    public offerItem!: OfferItem | null;
    public offerItemPriceType!: OfferItemPriceType;
    public updatedBy!: CMSUser | null;
    public createdAt!: string;
    public updatedAt!: string;
}
