import TaxDetails from '@/models/TaxDetails';

export default class TaxDetailsRepository {
    public static getAll() {
        return TaxDetails.all();
    }

    public static getById(id: string) {
        return TaxDetails.query().whereId(id).withAll().first();
    }
}
