import Project from '@/models/Project';
import { TabItemInterface } from '@/interfaces/components/chat/TabItemInterface';
import { convertProjectsToProjectInfo } from '@/helpers/chat/ChatHelper';

export default class ProjectRepository {
    public static getProjectById(projectId: number | string): Project | null {
        return Project.query()
            .whereId(projectId)
            .withAllRecursive()
            .first();
    }

    public static getLeadProjects(): Project[] {
        return Project.query()
            .where('state', 'lead')
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAllRecursive()
            .get();
    }

    public static getOfferProjects(): Project[] {
        return Project.query()
            .where('state', 'offer')
            .withAll()
            .get();
    }

    public static getOrderProjects(): Project[] {
        return Project.query()
            .where('state', 'order')
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAll()
            .get();
    }

    public static getActivitiesProjects(): Project[] {
        // todo - next step
        // get all projects like below
        // for each through each project and their next steps
        // the route for fetching these project might change - maybe we will
        // have to remove the name to be null constraint
        // push the project into a new array and add a new property with the "active" next step
        return Project.query()
            .where(
                (project: Project) =>
                    project.projectState === 'offer' ||
                    project.projectState === 'lead' ||
                    project.projectState === 'order' ||
                    project.projectState === 'request',
            )
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAllRecursive()
            .get();
    }

    public static getAdminProjects(): Project[] {
        return Project.query()
            .where('state', 'lead')
            .where((project: Project) => project.projectAssignedUserId === null)
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAllRecursive()
            .get();
    }

    public static getAllProjects(): Project[] {
        return Project.query()
            .where(
                (project: Project) =>
                    project.projectState === 'offer' ||
                    project.projectState === 'lead' ||
                    project.projectState === 'order' ||
                    project.projectState === 'request',
            )
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAllRecursive()
            .get();
    }

    public static filterProjectsByClient(clientId: string): Project[] {
        return Project.query()
            .where((project: Project) => project.client_id === clientId)
            .get();
    }

    public static getChatProjects(): TabItemInterface[] {
        const allProjects = Project.query()
            .where(
                (project: Project) =>
                    project.projectState === 'offer' ||
                    project.projectState === 'lead' ||
                    project.projectState === 'order' ||
                    project.projectState === 'request',
            )
            .orderBy(
                // @ts-ignore
                (project) => new Date(project.updatedAt).getTime(),
                'desc',
            )
            .withAllRecursive()
            .get();

        return convertProjectsToProjectInfo(allProjects) as TabItemInterface[];
    }
}
