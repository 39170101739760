import Vue from 'vue';
import moment from 'moment';

Vue.filter('dateFormat', (rawDate: string) => {
    if (rawDate === '-') {
        return '-';
    }
    return moment(rawDate).format('DD.MM.YYYY');
});

Vue.filter('dateTimeFormat', (rawDate: string) => {
    if (rawDate === '-') {
        return '-';
    }
    return moment(rawDate).format('DD.MM.YYYY HH:mm');
});
