import { Model } from '@vuex-orm/core';
import NewsAPI from '@/api/NewsAPI';
import { AxiosError, AxiosResponse } from 'axios';
import { NewsForm } from '@/interfaces/components/news/NewsForm';

export default class News extends Model {
    public static entity = 'news';

    public static fields() {
        return {
            id: this.attr(null),
            title: this.string(''),
            content: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            archivedAt: this.string(''),
            locale: this.attr(null),
            locale_id: this.attr(null),
        };
    }

    public static async getById(reportId: string): Promise<any> {
        let newsReport;

        try {
            newsReport = await NewsAPI.getById(reportId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: newsReport.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve(newsReport);
    }

    public static async getAll(): Promise<any> {
        let newsReports;

        try {
            newsReports = await NewsAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: newsReports.data,
        });
        return Promise.resolve(newsReports);
    }

    public static async getAllArchived(): Promise<AxiosResponse | AxiosError> {
        let newsReports;

        try {
            newsReports = await NewsAPI.getAllArchived();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: newsReports.data,
        });
        return Promise.resolve(newsReports);
    }

    public static async createNew(newsData: NewsForm): Promise<any> {
        let newsReport;

        try {
            newsReport = await NewsAPI.create(newsData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: newsReport.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve(newsReport);
    }

    public static async updateExisting(
        reportId: string,
        newsData: NewsForm,
    ): Promise<AxiosResponse | AxiosError> {
        let newsReport;

        try {
            newsReport = await NewsAPI.update(reportId, newsData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: newsReport.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve(newsReport);
    }

    public static async deleteExisting(reportId: string): Promise<void | AxiosError> {
        try {
            await NewsAPI.delete(reportId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.delete(reportId);

        return Promise.resolve();
    }

    public static async archiveOrActivateExisting(
        reportId: string,
        shouldArchive: boolean,
    ): Promise<void | AxiosError> {
        try {
            await NewsAPI.archiveOrActivate(reportId, shouldArchive);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.delete(reportId);

        return Promise.resolve();
    }

    public id!: string;
    public title!: string;
    public content!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public locale!: {
        id: string;
        name: string;
        code: string;
        type: string;
    };
}
