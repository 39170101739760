import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import ILocalState from './stateInterface';

const initState: ILocalState = {
    jwtData: {
        iat: 0,
        exp: 0,
        roles: [],
        username: '',
        id: '',
        locale: '',
        userGroups: [],
        userRights: [],
    },
    currentUserGroup: null,
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
