import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import { ReportTimeFrame } from '@/interfaces/components/Statistics/ReportTimeFrame';

export default class DashboardAPI {
    public static async createReport(reportData: any) {
        let report;

        const request = {
            data: {
                type: 'dashboard_reports',
                attributes: {
                    size: reportData.size,
                    position: reportData.position,
                },
                relationships: {
                    statisticReport: {
                        data: {
                            type: 'statistic_reports',
                            id: reportData.report.id,
                        },
                    },
                    dashboard: {
                        data: {
                            type: 'dashboards',
                            id: reportData.dashboardId,
                        },
                    },
                },
            },
        };

        try {
            report = (await ApiController.post(ApiRoutes.dashboard.createReport, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(report);
    }

    public static async updateReport(reportData: any, dashboardId: string): Promise<void | AxiosError> {
        const request = {
            data: {
                id: reportData.id,
                type: reportData.type,
                attributes: {
                    size: reportData.size,
                    position: reportData.position,
                },
                relationships: {
                    statisticReport: {
                        data: {
                            type: 'statistic_reports',
                            id: reportData.statisticReport.id,
                        },
                    },
                    dashboard: {
                        data: {
                            type: 'dashboards',
                            id: dashboardId,
                        },
                    },
                },
            },
        };

        try {
            (await ApiController.patch(
                ApiRoutes.dashboard.updateReport(reportData.id),
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getReport(reportId: string, timeFrame: ReportTimeFrame, userId: string) {
        let report;

        try {
            report = (await ApiController.get(
                ApiRoutes.dashboard.getReport(reportId, timeFrame, userId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(report);
    }

    public static async deleteReport(id: string) {
        let report;

        try {
            report = (await ApiController.delete(ApiRoutes.dashboard.deleteReport(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
