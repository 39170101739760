import PaymentType from '@/models/PaymentType';

export default class PaymentTypeRepository {
    public static getAll() {
        return PaymentType.query().where('isArchived', false).withAll().get();
    }

    public static getAllWithArchived() {
        return PaymentType.query().withAll().get();
    }

    public static getById(id: string | null | undefined) {
        if (id == null) {
            return null;
        }
        return PaymentType.query().whereId(id).withAll().first();
    }

    public static getDefault() {
        return PaymentType.query().where('isDefault', true).withAll().first();
    }
}
