import { Model } from '@vuex-orm/core';
import ReportAPI from '@/api/ReportAPI';
import CMSUser from '@/models/CMSUser';
import { AxiosError, AxiosResponse } from 'axios';
import { ReportData } from '@/interfaces/components/Statistics/CMSReport';

export default class Report extends Model {
    public static entity = 'report';

    public static fields() {
        return {
            id: this.string(null),
            createdAt: this.string(null),
            updatedAt: this.string(null),
            isDefault: this.boolean(false),
            name: this.string(null),
            type: this.string(null),
            users: this.attr(null),
        };
    }

    public static async getAll(): Promise<AxiosResponse | AxiosError> {
        let reports;

        try {
            reports = await ReportAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: reports.data,
        });

        return Promise.resolve(reports);
    }

    public static async getById(reportId: string): Promise<AxiosResponse | AxiosError> {
        let report;

        try {
            report = await ReportAPI.getById(reportId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: report.data,
        });
        return Promise.resolve(report);
    }

    public static async createNew(reportData: ReportData): Promise<void | AxiosError> {
        let report;

        try {
            report = await ReportAPI.create(reportData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: report.data,
        });

        return Promise.resolve();
    }

    public static async updateExisting(reportId: string, reportData: ReportData): Promise<void | AxiosError> {
        let report;

        try {
            report = await ReportAPI.update(reportId, reportData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: report.data,
        });
        return Promise.resolve();
    }

    public static async deleteExisting(reportId: string): Promise<void | AxiosError> {
        try {
            await ReportAPI.delete(reportId);
        } catch (e) {
            return Promise.reject(e);
        }

        await Report.delete(reportId);

        return Promise.resolve();
    }

    public static formatNumber(num: string | number, decimal: number = 2) {
        if (!num) {
            return 0;
        }
        if (typeof num === 'string') {
            return num;
        }

        return parseFloat(num.toFixed(decimal)).toLocaleString();
    }

    public id!: string;
    public createdAt!: string;
    public isDefault!: boolean;
    public name!: string;
    public type!: string;
    public updatedAt!: string;
}
