import Product from '@/models/Product';

export class ProductRepository {
    public static getAll() {
        return Product.query().withAll().get();
    }

    public static getById(productId: string) {
        return Product.query().withAllRecursive().whereId(productId).first();
    }
}
