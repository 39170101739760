import { Model } from '@vuex-orm/core';
import ProductSystemWizardAPI from '@/api/ProductSystemWizardAPI';
import { AxiosResponse, AxiosError } from 'axios';
import ProductSystem from '@/models/ProductSystem';
import ProductSystemWizardTranslation from './ProductSystemWizardTranslation';

export default class ProductSystemWizard extends Model {

    public static entity = 'productSystemWizard';

    public static fields() {
        return {
            id: this.attr(null),
            productSystem_id: this.attr(null),
            productSystem: this.belongsTo(ProductSystem, 'productSystem_id'),
            translations_ids: this.attr([]),
            translations: this.hasManyBy(ProductSystemWizardTranslation, 'translations_ids'),
        };
    }

    public static async getAll() {
        let productSystemWizardData;

        try {
            productSystemWizardData = await ProductSystemWizardAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: productSystemWizardData.data,
            insertOrUpdate: ['productSystem', 'productSystemWizardData','image'],
        });

        return Promise.resolve(productSystemWizardData);
    }

    public static async getById(id: string) { 
        let productSystemWizardData;
        try {
            productSystemWizardData = await ProductSystemWizardAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productSystemWizardData.data,
            insertOrUpdate: ['image','productSystem','productSystemWizardData'],
        });

        return Promise.resolve(productSystemWizardData);
    }

    public static async getByProductSystemId(id: string) { 
        let productSystemWizardData;
        try {
            productSystemWizardData = await ProductSystemWizardAPI.getByProductSystemId(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productSystemWizardData.data,
            insertOrUpdate: ['image','productSystem','productSystemWizardData'],
        });

        return Promise.resolve(productSystemWizardData);
    }

    public static async createNew(data?: ProductSystemWizard): Promise<AxiosResponse | AxiosError> {
        let productSystemWizardData;
        try {
            productSystemWizardData = await ProductSystemWizardAPI.createNew(data);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: productSystemWizardData.data,
            insertOrUpdate: ['image','productSystem','productSystemWizardData'],
        });

        return Promise.resolve(productSystemWizardData);
    }

    public static async createImage(data?: any): Promise<AxiosResponse> {
        let productSystemWizardData;
        try {
            productSystemWizardData = await ProductSystemWizardAPI.createNewImage(data) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(productSystemWizardData);
    }

    public static async updateExisting(id: string, data: ProductSystemWizard) {
        let updatedProductSystemWizard;
        try {
            updatedProductSystemWizard = await ProductSystemWizardAPI.updateExisting(id, data);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: updatedProductSystemWizard.data,
            insertOrUpdate: ['image','productSystem','productSystemWizardData'],
        });

        return Promise.resolve(updatedProductSystemWizard);
    }

    public static async deleteExisting(id: string) {
        try {
            await ProductSystemWizardAPI.deleteExisting(id);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public id!: string;
    public translations!: ProductSystemWizardTranslation[];
    public productSystem!: ProductSystem | null;
}
