import { constructUrlParams } from '@/helpers/SearchHelper';
import { LocalStorageService } from '@/services/LocalStorageService';
import store from '@/store';
import Project from '@/models/Project';
import { ProjectNavigationDirection } from '@/enums/components/Project/ProjectNavigationDirection';

export function determineNextAndPreviousProjectInformation(
    currentProjectId: string,
    direction: ProjectNavigationDirection
) {
    const sortedProjectsObject = store.getters['tableData/sortedProjectsObject'];
    const sortedProjectsIds = Object.values(sortedProjectsObject).flat();
    const currentProjectIdIndex = sortedProjectsIds.indexOf(currentProjectId);
    const nextProjectId = sortedProjectsIds[currentProjectIdIndex + 1];
    const previousProjectId = sortedProjectsIds[currentProjectIdIndex - 1];
    let currentProjectPage = 0;
    let nextProjectPage = 0;
    let previousProjectPage = 0;

    Object.entries(sortedProjectsObject).forEach(([page, projects]) => {
        if ((projects as string[]).includes(currentProjectId)) {
            currentProjectPage = Number(page);
        }
        if ((projects as Array<string | unknown>).includes(nextProjectId)) {
            nextProjectPage = Number(page);
        }
        if ((projects as Array<string | unknown>).includes(previousProjectId)) {
            previousProjectPage = Number(page);
        }
    });

    const correspondingProjectPage =
        direction === ProjectNavigationDirection.NEXT ? nextProjectPage : previousProjectPage;
    const correspondingProjectId = direction === ProjectNavigationDirection.NEXT ? nextProjectId : previousProjectId;
    const correspondingPageToBeFetched = direction === ProjectNavigationDirection.NEXT ? 1 : -1;
    const isCorrespondingPageFetched =
        direction === ProjectNavigationDirection.NEXT
            ? store.getters['tableData/isLastPageFetched']
            : store.getters['tableData/isFirstPageFetched'];

    return {
        correspondingProjectPage,
        correspondingProjectId,
        correspondingPageToBeFetched,
        isCorrespondingPageFetched,
        currentProjectPage,
    };
}

export async function fetchCorrespondingPageAndUpdateStore(correspondingPageToBeFetched: number) {
    const entityType = store.getters['tableData/entityType'];
    const userId = store.getters['tableData/userId'];
    const localStorageParameters = JSON.parse(
        LocalStorageService.get(
            // @ts-ignore
            `${entityType}-${userId}`
        ) as string
    );

    localStorageParameters.page += correspondingPageToBeFetched;

    const query = constructUrlParams(localStorageParameters);
    let payload;
    try {
        payload = await Project.fetchFilteredProjectsForNavigation(query);
    } catch (e) {
        throw e;
    }

    await store.dispatch('tableData/updateProjectNavigationSettings', {
        ...payload,
        page: localStorageParameters.page,
        userId,
        entityType,
    });

    return Promise.resolve();
}
