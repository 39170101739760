import Notification from '@/models/Notification';

export default class NotificationRepository {
    public static getAll() {
        return Notification.all().reverse();
    }

    // public static getById(id: string | null) {
    //     if (id == null) {
    //         return null;
    //     }
    //     return Notification.query().whereId(id).withAll().first();
    // }
}
