import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import ClientAttachmentAPI from '@/api/ClientAttachmentAPI';

export default class ClientAttachment extends Model {
    public static entity = 'clientAttachment';

    public static fields() {
        return {
            id: this.string(null),
            attachment: this.string(''),
            title: this.string(''),
            fileType: this.string(''),
            fileSize: this.string(''),
            displayName: this.string(''),
        };
    }

    public static async createNew(formData: FormData) {
        let attachment;
        try {
            attachment = (await ClientAttachmentAPI.createNew(formData)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(attachment);
    }

    public static async getBlob(resourceUrl: string) {
        return await ClientAttachmentAPI.getBlob(resourceUrl);
    }

    public static async getClientAttachmentByClientId(clientId: string) {
        let attachments;
        try {
            attachments = (await ClientAttachmentAPI.getAttachmentsByClientId(clientId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: attachments.data,
        });

        return Promise.resolve(attachments);
    }

    public static async getById(attachmentId: string) {
        let attachment;
        try {
            attachment = (await ClientAttachmentAPI.getById(attachmentId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: attachment.data,
        });

        return Promise.resolve(attachment);
    }

    public static async deleteExisting(attachmentId: string) {
        try {
            await ClientAttachmentAPI.delete(attachmentId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ClientAttachment.delete(attachmentId);
        return Promise.resolve();
    }
    public id!: string;
    public attachment!: string;
    public title!: string;
    public type!: string;
    public displayName!: string;
}
