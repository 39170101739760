import { Model } from '@vuex-orm/core';
import Locale from '@/models/Locale';
import FlaggedPdfValueAPI from '@/api/FlaggedPdfValueAPI';
import DropdownOption from './DropdownOption';

export default class FlaggedPdfValue extends Model {
    public static entity = 'flaggedPdfValue';

    public static fields() {
        return {
            id: this.attr(null),
            value: this.string(''),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
        };
    }

    public static async getAll() {
        let flaggedPdfValues;
        try {
            flaggedPdfValues = await FlaggedPdfValueAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: flaggedPdfValues.data,
        });
        return Promise.resolve();
    }

    public static async getById(id: string) {
        let flaggedPdfValue;
        try {
            flaggedPdfValue = await FlaggedPdfValueAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: flaggedPdfValue.data,
        });
        return Promise.resolve(flaggedPdfValue);
    }

    public static async createNew(data: any) {
        let flaggedPdfValue;
        try {
            flaggedPdfValue = await FlaggedPdfValueAPI.create(data.value.name);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: flaggedPdfValue.data,
        });

        return Promise.resolve(flaggedPdfValue);
    }

    public static async updateExisting(flaggedPdfValueId: string, data: any) {
        let flaggedPdfValue;
        try {
            flaggedPdfValue = await FlaggedPdfValueAPI.update(flaggedPdfValueId, data.value.name);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: flaggedPdfValue.data,
        });
        return Promise.resolve(flaggedPdfValue);
    }

    public static async deleteExisting(flaggedPdfValueId: string) {
        try {
            await FlaggedPdfValueAPI.delete(flaggedPdfValueId);
        } catch (e) {
            return Promise.reject(e);
        }
        await FlaggedPdfValue.delete(flaggedPdfValueId);
        return Promise.resolve();
    }

    public id!: string;
    public value!: string;
    public locale!: Locale;
}
