
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ButtonSwitch, { ButtonSwitchConfiguration } from '@/components/global/ButtonSwitch.vue';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import BankAccountRepository from '@/repositories/BankAccountRepository';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import CountryRepository from '@/repositories/CountryRepository';
import ContactEmailPhoneForm from '@/components/global/ContactEmailPhoneForm.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ClientInformation from '@/models/ClientInformation';
import ClientInformationPopup from '@/components/global/client/ClientInformationPopup.vue';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ClientLogoAttachments from '@/components/global/client/ClientLogoAttachments.vue';
import ClientAddressForm from '@/components/views/ClientView/ClientAddressForm.vue';

@Component({
    name: 'CustomerInformation',
    components: {
        ButtonSwitch,
        ContactEmailPhoneForm,
        ClientInformationPopup,
        ClientLogoAttachments,
        ClientAddressForm,
    },
})
export default class CustomerInformation extends Vue {
    @Prop({ required: true }) private value!: any;
    @Prop({ default: false }) private emailValidationOverride!: boolean;
    @Prop({ default: false }) private emailValidationOverrideValue!: boolean;
    @Prop({ default: false }) private isNewClient!: boolean;
    @Prop({ default: null }) private clientId!: string;

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private showClientInformationPopup = false;
    private clientInformation: ClientInformation | null = null;

    private customerTypeSwitch: ButtonSwitchConfiguration = {
        firstOptionLabel: 'Natural person',
        firstOptionValue: false,
        secondOptionLabel: 'Company',
        secondOptionValue: true,
    };

    private selectedBankAccount = '';
    private selectedIsTaxPayerOption = 1;

    private contactEmailAndPhoneForm = {
        emails: [''],
        phones: [''],
        primaryIndex: 0,
    };

    private selectedCountry = this.defaultCountry ? this.defaultCountry.id + '|' + this.defaultCountry.code : '';

    private get defaultCountry() {
        if (!this.value) {
            return;
        }

        if (this.value.addressForm.country) {
            return CountryRepository.getById(this.value.addressForm.country);
        }

        return '';
    }

    private get booleanOptions() {
        return [
            {
                id: 0,
                value: false,
                name: this.$t('No'),
            },
            {
                id: 1,
                value: true,
                name: this.$t('Yes'),
            },
        ];
    }

    private get isRoltek(): boolean {
        const userGroup = String(this.$store.getters['jwtData/currentUserGroup']);

        if (userGroup === '1' || userGroup === '2') {
            return true;
        }

        return false;
    }

    private onSelectedBankAccountChange() {
        this.value.bankCode = this.bankAccounts.find((bank) => bank.id === this.selectedBankAccount)?.accountNumber;
    }

    private onSelectedIsTaxPayerOptionChange() {
        if (this.selectedIsTaxPayerOption === 0) {
            this.value.isTaxPayer = false;
        }
        if (this.selectedIsTaxPayerOption === 1) {
            this.value.isTaxPayer = true;
        }
    }

    private get currencies() {
        return CurrencyRepository.getAll();
    }

    private get paymentTypes() {
        return PaymentTypeRepository.getAllWithArchived();
    }

    private get bankAccounts() {
        return BankAccountRepository.getAllWithFormattedName();
    }

    private async getClientInformation() {
        if (!this.value.taxId) {
            this.$notification.destroy();
            this.$notification.error({
                message: 'Error',
                description: 'Please enter tax ID.',
            });
            return;
        }

        let clientInformation;
        this.loadingOverlay.start();

        const formattedTaxId = this.formatTaxId(this.value.taxId);

        try {
            clientInformation = await ClientInformation.getById(formattedTaxId);
        } catch (e) {
            this.showErrorAndResetClientInformationForm(
                clientInformation?.data?.message ?? this.$t('Getting client information failed.')
            );
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        if (!clientInformation) {
            this.showErrorAndResetClientInformationForm(this.$t('Getting client information failed.'));
            return;
        }

        if (clientInformation?.data?.message) {
            this.showErrorAndResetClientInformationForm(
                clientInformation?.data?.message ?? this.$t('Getting client information failed.')
            );
            return;
        }

        this.clientInformation = clientInformation.data;
        this.showClientInformationPopup = true;
    }

    private formatTaxId(taxId: string) {
        const prefix = parseInt(taxId.substring(0, 2), 10);
        if (isNaN(prefix)) {
            return taxId.substring(2);
        }

        return taxId;
    }

    private onUseDifferentDeliveryAddressChange(useDifferentAddressForDelivery: boolean) {
        if (useDifferentAddressForDelivery) {
            let id: string;

            if (this.value.deliveryAddressForm?.id === this.value.addressForm?.id) {
                id = '';
            } else {
                id = this.value.deliveryAddressForm?.id;
            }

            this.value.deliveryAddressForm = {
                id,
                streetNameAndNumber: this.value.addressForm.streetNameAndNumber,
                poNumber: this.value.addressForm.poNumber,
                city: this.value.addressForm.city,
                building: this.value.addressForm.building,
                country: this.value.addressForm.country,
            };
            this.value.deliveryAddress = '';
        } else {
            this.value.deliveryAddress = this.value.addressForm.id;
        }
    }

    private onUseDifferentInvoiceAddressChange(useDifferentAddressForInvoice: boolean) {
        if (useDifferentAddressForInvoice) {
            let id: string;

            if (this.value.invoiceAddressForm?.id === this.value.addressForm?.id) {
                id = '';
            } else {
                id = this.value.invoiceAddressForm?.id;
            }

            this.value.invoiceAddressForm = {
                id,
                streetNameAndNumber: this.value.addressForm.streetNameAndNumber,
                poNumber: this.value.addressForm.poNumber,
                city: this.value.addressForm.city,
                building: this.value.addressForm.building,
                country: this.value.addressForm.country,
            };
            this.value.invoiceAddress = '';
        } else {
            this.value.invoiceAddress = this.value.addressForm.id;
        }
    }

    private showErrorAndResetClientInformationForm(message: string) {
        this.$notification.error({
            message: this.$t('Error'),
            description: message,
        });
        this.clientInformation = null;
    }

    private handleClientInformationConfirmation() {
        EventBus.$emit(EventBusEvents.clientInformationConfirmed);
        this.showClientInformationPopup = false;
    }

    private handleClientInformationCancelation() {
        EventBus.$emit(EventBusEvents.clientInformationCanceled);
        this.showClientInformationPopup = false;
    }

    private created() {
        EventBus.$on(EventBusEvents.getClientInformation, this.getClientInformation);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.getClientInformation, this.getClientInformation);
    }

    @Watch('value.bankCode', {
        immediate: true,
    })
    private changeBankAccount() {
        this.selectedBankAccount = this.value.bankCode;
    }

    @Watch('value.isTaxPayer', {
        immediate: true,
    })
    private updateTaxPayer() {
        this.selectedIsTaxPayerOption = this.value.isTaxPayer ? 1 : 0;
    }

    @Watch('value', {
        immediate: true,
        deep: true,
    })
    private onValueChange() {
        this.$emit('input', this.value);
    }
}
