var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.determineIsFieldVisible && _vm.checkVisibilityLevel(_vm.checklistField.level)),expression:"determineIsFieldVisible && checkVisibilityLevel(checklistField.level)"}],class:_vm.className},[_c('label',{class:['c-checklist__row-label', 'level-' + _vm.checklistField.level, _vm.labelClasses],on:{"click":function($event){_vm.toggleInfoDetailsModal();
            _vm.selectedChecklist = _vm.checklistField;}}},[_vm._v(" "+_vm._s(_vm.checklistField.name)+" "),(_vm.checklistField.description !== null)?_c('a-icon',{staticClass:"c-checklist__label-details",attrs:{"type":"info-circle"}}):_vm._e()],1),(_vm.selectedChecklist !== null && _vm.selectedChecklist.description !== null)?_c('a-modal',{attrs:{"dialog-style":{
            top: '20px',
            height: '90%',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
        },"dialogClass":{ 'has-two-items': _vm.selectedChecklist.image !== null },"title":_vm.selectedChecklist.name},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}},[_c('div',{ref:"modalInner",staticClass:"c-checklist__modal-inner",class:{ 'c-checklist__modal-inner--two-items': _vm.selectedChecklist.image !== null }},[(_vm.selectedChecklist.image !== null)?_c('div',{ref:"imageWrapper",staticClass:"c-checklist__image-wrapper",style:({ backgroundImage: `url(${_vm.selectedChecklist.image.image})` })},[_c('a-icon',{staticClass:"c-checklist__modal-inner__resize-icon",attrs:{"type":"fullscreen"},on:{"click":_vm.toggleImageFullScreen}})],1):_vm._e(),(_vm.selectedChecklist.image !== null && _vm.showFullScreenImage)?_c('div',{staticClass:"c-checklist__image--full-screen",style:({ backgroundImage: `url(${_vm.selectedChecklist.image.image})` })},[_c('a-icon',{attrs:{"type":"close"},on:{"click":_vm.toggleImageFullScreen}})],1):_vm._e(),_c('RichTextEditor',{ref:"text",attrs:{"content":_vm.checklistField.description,"editablePropertyName":"description","shouldShowLegend":false,"shouldShowMenuBar":false,"isReadOnly":true,"title":_vm.checklistField.name}})],1),_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":_vm.toggleInfoDetailsModal}},[_vm._v(" OK ")])],1)],2):_vm._e(),_c(_vm.activeFieldModule,{ref:"field",tag:"component",staticClass:"c-checklist__row-value u-b1",class:{
            'is-disabled': _vm.isDisabled && _vm.isAlternativeStyle,
            'has-errors': _vm.pIdFieldErrors.includes(_vm.checklistField.pId),
            warning: _vm.hasError,
        },attrs:{"activeProductFormId":_vm.activeProductFormId,"checklistBoolField":_vm.checklistField.checklistBoolField,"checklistDropdownField":_vm.checklistField.checklistDropdownField,"checklistFloatField":_vm.checklistField.checklistFloatField,"checklistIntegerField":_vm.checklistField.checklistIntegerField,"checklistStringField":_vm.checklistField.checklistStringField,"data-test":_vm.checklistField.name,"data-test-id":_vm.checklistField.pId,"debounceToggleIsActive":_vm.debounceToggleIsActive,"image":_vm.checklistField.image,"isDisabled":_vm.isDisabled,"itemRowIndex":_vm.itemRowIndex,"pId":_vm.checklistField.pId,"isConfigurator":_vm.isConfigurator},on:{"updateStoreFieldValue":_vm.onUpdateStoreFieldValue},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onPressEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onPressDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onPressUp.apply(null, arguments)}]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }