import { Model } from '@vuex-orm/core';
import PlainMessageAPI from '@/api/PlainMessageAPI';
import Attachment from '@/models/Attachment';

export default class PlainMessage extends Model {
    public static entity = 'plainMessage';

    public static fields() {
        return {
            id: this.string(null),
            text: this.string(''),
            attachments_ids: this.attr([]),
            attachments: this.hasManyBy(Attachment, 'attachments_ids'),
        };
    }

    public static async getAll() {
        let plainMessages;
        try {
            plainMessages = await PlainMessageAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: plainMessages.data,
        });
        return Promise.resolve();
    }

    public static async getById(plainMessageId: string) {
        let plainMessage;
        try {
            plainMessage = await PlainMessageAPI.getById(plainMessageId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: plainMessage.data,
        });
        return Promise.resolve(plainMessage);
    }

    public static async updateExisting(plainMessageId: string, plainMessageInfo: any) {
        let plainMessage;
        try {
            plainMessage = await PlainMessageAPI.update(plainMessageId, plainMessageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: plainMessage.data,
        });
        return Promise.resolve(plainMessage);
    }

    public static async createNew(plainMessageInfo: any) {
        let plainMessage;
        try {
            plainMessage = await PlainMessageAPI.create(plainMessageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: plainMessage.data,
        });
        return Promise.resolve(plainMessage);
    }

    public static async deleteExisting(plainMessageId: string) {
        try {
            await PlainMessageAPI.delete(plainMessageId);
        } catch (e) {
            return Promise.reject(e);
        }
        await PlainMessage.delete(plainMessageId);
        return Promise.resolve();
    }

    public id!: string;
    public text!: string;
    public attachments!: Attachment[];
}
