import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import OfferItemPriceTypeAPI from '@/api/OfferItemPriceTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
export default class OfferItemPriceType extends Model {
    public static entity = 'offerItemPriceType';
    public static roltekID = '1';
    public static purchaseID = '2';
    public static sellingID = '3';
    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
        };
    }

    public static async getAll() {
        let offerItemPriceTypes;

        if (ss.has('offerItemPriceType')) {
            offerItemPriceTypes = ss.getAndParse('offerItemPriceType');
        } else {
            try {
                const fetchedPriceTypes = (await OfferItemPriceTypeAPI.getAll()) as AxiosResponse;
                offerItemPriceTypes = fetchedPriceTypes.data;

                ss.stringifyAndSave('offerItemPriceType', offerItemPriceTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: offerItemPriceTypes,
        });

        return Promise.resolve(offerItemPriceTypes);
    }

    public static async getById(offerItemPriceTypeId: string) {
        let offerItemPriceTypes;
        try {
            offerItemPriceTypes = await OfferItemPriceTypeAPI.getById(offerItemPriceTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerItemPriceTypes.data,
        });
        return Promise.resolve(offerItemPriceTypes);
    }

    public static async getRoltekPriceType() {
        let offerItemPriceTypes;
        try {
            offerItemPriceTypes = await OfferItemPriceTypeAPI.getById(this.roltekID);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerItemPriceTypes.data,
        });
        return Promise.resolve(offerItemPriceTypes);
    }

    public static async getPurchasePriceType() {
        let offerItemPriceTypes;
        try {
            offerItemPriceTypes = await OfferItemPriceTypeAPI.getById(this.purchaseID);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerItemPriceTypes.data,
        });
        return Promise.resolve(offerItemPriceTypes);
    }

    public static async getSellingPriceType() {
        let offerItemPriceTypes;
        try {
            offerItemPriceTypes = await OfferItemPriceTypeAPI.getById(this.sellingID);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerItemPriceTypes.data,
        });
        return Promise.resolve(offerItemPriceTypes);
    }

    public id!: string;
    public name!: string;
}
