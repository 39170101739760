import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';

export default class DashboardAPI {
    public static async getById(id: string) {
        let dashboard;

        try {
            dashboard = (await ApiController.get(ApiRoutes.dashboard.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboard);
    }

    public static async getAll() {
        let dashboards;

        try {
            dashboards = (await ApiController.get(ApiRoutes.dashboard.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboards);
    }

    public static async getCurrent() {
        let dashboard;

        try {
            dashboard = (await ApiController.get(ApiRoutes.dashboard.getCurrent())) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboard);
    }

    public static async getByUserId(userId: string, dashboardId: string | null) {
        let dashboard;

        // Add userId param to the get by user route or current user route
        try {
            if (dashboardId) {
                dashboard = (await ApiController.get(
                    ApiRoutes.dashboard.getById(dashboardId, userId),
                )) as AxiosResponse;
            } else {
                dashboard = (await ApiController.get(
                    ApiRoutes.dashboard.getCurrent(userId),
                )) as AxiosResponse;
            }
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboard);
    }

    public static async delete(id: string) {
        try {
            (await ApiController.delete(ApiRoutes.dashboard.delete(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
