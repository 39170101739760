import JsFunction from '@/models/JsFunction';
// @ts-ignore
import jsonLogic from 'json-logic-js/logic.js';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { StringFunction } from '@/interfaces/components/configurator/StringFunction';
import JsFunctionRepository from '@/repositories/JsFunctionRepository';

export function registerJsonLogicFunctions(functions: JsFunction[]) {
    functions.forEach((jsFunction: JsFunction) => {
        const parsedFunction = new Function(`return ${jsFunction.code.trim()}`)();
        jsonLogic.add_operation(jsFunction.name, parsedFunction);
    });
}

/**
 * Fetches and registers the json logic functions only if there are no js functions in the ORM
 * @return - An empty promise
 */
export async function fetchAndRegisterJsonLogicFunctionsIfNecessary() {
    const repositoryJsFunctions = JsFunctionRepository.getAll();
    if (repositoryJsFunctions.length > 0) {
        return Promise.resolve();
    }

    const fetchedFunctions = await JsFunction.getAll();
    registerJsonLogicFunctions(fetchedFunctions);

    return Promise.resolve();
}

export function parseAndTransformStringWithDynamicValues(
    stringToBeParsed: string,
    stringFunctions: StringFunction[],
    values: ActiveProductFormValueObject | null,
    keyword: string,
) {
    if (values == null) {
        return stringToBeParsed;
    }

    let transformedString = removeUnnecessaryCharacters(stringToBeParsed);
    transformedString = transformStringWithJsonLogic(transformedString, stringFunctions, values, keyword);
    transformedString = replacePlaceholdersWithCorrectValues(transformedString, values);

    return transformedString;
}

function removeUnnecessaryCharacters(stringToBeParsed: string) {
    let transformedString = stringToBeParsed;
    transformedString = transformedString
        .trim()
        .replace(/\+/g, '')
        .replace(/"/g, '');

    return transformedString;
}

function transformStringWithJsonLogic(
    stringWithJsonLogic: string,
    stringFunctions: StringFunction[],
    values: ActiveProductFormValueObject,
    keyword: string,
) {
    const functionResults = stringFunctions.map((stringFunction) => {
        return handleJsonLogicExpression(values, stringFunction.conditionJson, stringFunction.conditionData);
    });

    return replaceKeywordsWithCorrectValues(stringWithJsonLogic, keyword, functionResults);
}

function handleJsonLogicExpression(
    values: ActiveProductFormValueObject,
    conditionJson: any,
    conditionData: any,
) {
    const jsonLogicData: ActiveProductFormValueObject = generatePayloadForJsonLogic(values, conditionData);

    return calculateJsonLogicExpression(conditionJson, jsonLogicData);
}

function generatePayloadForJsonLogic(values: ActiveProductFormValueObject, conditionData: any) {
    const jsonLogicData: ActiveProductFormValueObject = {};

    for (const pId of conditionData) {
        jsonLogicData[pId] = getCorrectValueFromActiveProductForm(values, pId);
    }

    return jsonLogicData;
}

function calculateJsonLogicExpression(conditionJson: any, jsonLogicData: any) {
    return jsonLogic.apply(conditionJson, jsonLogicData);
}

function getCorrectValueFromActiveProductForm(values: ActiveProductFormValueObject, pId: string) {
    const currentValue = values[pId];
    return currentValue == null ? null : currentValue;
}

function replacePlaceholdersWithCorrectValues(
    stringToBeReplaced: string,
    values: ActiveProductFormValueObject,
) {
    return stringToBeReplaced.replace(/p\d+/g, (match) => {
        return getCorrectValueFromActiveProductForm(values, match);
    });
}

function replaceKeywordsWithCorrectValues(
    stringWithJsonLogic: string,
    keyword: string,
    functionResults: string[],
) {
    let transformedString = stringWithJsonLogic;

    functionResults.forEach((functionResult) => {
        transformedString = transformedString.replace(keyword, functionResult);
    });

    return transformedString;
}
