import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import StatusEventConfiguration from '@/models/interfaces/StatusEventConfiguration';

export default class StatusEventAPI {
    public static async getById(id: string) {
        let statusEvent;

        try {
            statusEvent = (await ApiController.get(ApiRoutes.automaticEvents.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(statusEvent);
    }

    public static async getAll() {
        let statusEvents;

        try {
            statusEvents = (await ApiController.get(ApiRoutes.automaticEvents.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(statusEvents);
    }

    public static async create(eventData: StatusEventConfiguration) {
        let statusEvent;
        let request;

        request = {
            data: {
                type: 'status_event_activity_config',
                attributes: {
                    name: eventData.name,
                    status: eventData.status,
                    statusChange: eventData.statusChange,
                    type: eventData.type,
                    conditions: { ...eventData.conditions },
                    receptionUsers: eventData.receptionUsers,
                },
                relationships: {
                    projectLabel: {
                        data: {
                            type: 'project_label',
                            id: eventData.labelId,
                        },
                    },
                },
            },
        };

        // Remove users type and email attachment info if event is of type popup
        if (eventData.type === 'POPUP') {
            // @ts-ignore
            request.data.attributes.conditions.email = null;
            request.data.attributes.receptionUsers = [];
        }

        try {
            statusEvent = (await ApiController.post(
                ApiRoutes.automaticEvents.create,
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(statusEvent);
    }

    public static async update(eventId: string, eventData: StatusEventConfiguration) {
        let statusEvent;
        let request;

        request = {
            data: {
                type: 'status_event_activity_config',
                id: eventId,
                attributes: {
                    name: eventData.name,
                    status: eventData.status,
                    statusChange: eventData.statusChange,
                    type: eventData.type,
                    conditions: { ...eventData.conditions },
                    receptionUsers: eventData.receptionUsers,
                },
                relationships: {
                    projectLabel: {
                        data: {
                            type: 'project_label',
                            id: eventData.labelId,
                        },
                    },
                },
            },
        };

        // Remove users type and email attachment info if event is of type popup
        if (eventData.type === 'POPUP') {
            // @ts-ignore
            request.data.attributes.conditions.email = null;
            request.data.attributes.receptionUsers = [];
        }

        try {
            statusEvent = (await ApiController.patch(
                ApiRoutes.automaticEvents.update(eventId),
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(statusEvent);
    }

    public static async delete(id: string) {
        try {
            (await ApiController.delete(ApiRoutes.automaticEvents.delete(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
