import { Model } from '@vuex-orm/core';
import Image from '@/models/Image';
import ProductSystemDetailsAPI from '@/api/ProductSystemDetailsAPI';
import { AvailableProductSystemDetailsFields } from '@/interfaces/components/productSystemDetails/AvailableProductSystemDetailsFields';
import UploadedPdf from '@/models/UploadedPdf';
import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';
import ProductSystem from '@/models/ProductSystem';

export default class ProductSystemDetails extends Model {

    public static entity = 'productSystemDetails';

    public static fields() {
        return {
            id: this.attr(null),
            title: this.attr(''),
            description: this.attr(''),
            productSystem_id: this.attr(null),
            productSystem: this.belongsTo(ProductSystem, 'productSystem_id'),
            catalogueImage_id: this.attr(null),
            catalogueImage: this.belongsTo(Image, 'catalogueImage_id'),
            pdfOfferImage_id: this.attr(null),
            pdfOfferImage: this.belongsTo(Image, 'pdfOfferImage_id'),
            icon_id: this.attr(null),
            icon: this.belongsTo(Image, 'icon_id'),
            document_ids: this.attr([]),
            documents: this.hasManyBy(UploadedPdf, 'document_ids'),
            gallery_ids: this.attr([]),
            gallery: this.hasManyBy(Image, 'gallery_ids'),
            configuration_ids: this.attr([]),
            configurations:
                this.hasManyBy(ProductSystemDetailsConfiguration, 'configuration_ids'),
        };
    }

    public static async getAll() {
        let productSystemDetails;
        try {
            productSystemDetails = await ProductSystemDetailsAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: productSystemDetails.data,
            insertOrUpdate: ['icon', 'catalogueImage', 'pdfOfferImage', 'productSystem', 'uploadedPdf', 'productSystemDetailsConfiguration'],
        });
        return Promise.resolve();
    }

    public static async getById(productSystemDetailsId: string, locale?: string | null) {
        let productSystemDetails;
        try {
            productSystemDetails = await ProductSystemDetailsAPI.getById(productSystemDetailsId, locale);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productSystemDetails.data,
            insertOrUpdate: ['icon', 'catalogueImage', 'pdfOfferImage', 'productSystem', 'uploadedPdf', 'productSystemDetailsConfiguration'],
        });
        return Promise.resolve(productSystemDetails);
    }

    public static async updateExisting(
        productSystemDetailsId: string,
        productSystemDetailsInfo: AvailableProductSystemDetailsFields,
        productSystemId?: string,
        locale?: string | null) {
        if (productSystemId == null) {
            return Promise.reject(new Error('You must set a product system ID (additionalEntity)'));
        }

        let productDetails;
        try {
            productDetails = await ProductSystemDetailsAPI.updateExisting(
                productSystemDetailsId,
                productSystemDetailsInfo,
                productSystemId,
                locale,
            );
        } catch (e) {
            return Promise.reject(e);
        }
        await ProductSystemDetails.delete(productSystemDetailsId);
        this.insertOrUpdate({
            data: productDetails.data,
            insertOrUpdate: ['icon', 'catalogueImage', 'pdfOfferImage', 'productSystem', 'uploadedPdf', 'productSystemDetailsConfiguration'],
        });
        return Promise.resolve();
    }

    public static async createNew(
        productSystemDetailsInfo: AvailableProductSystemDetailsFields,
        productSystemId?: string,
        locale?: string | null) {
        if (productSystemId == null) {
            return Promise.reject(new Error('You must set a product ID (additionalEntity)'));
        }

        let newProductDetails;
        try {
            newProductDetails =
                await ProductSystemDetailsAPI.createNew(productSystemDetailsInfo, productSystemId, locale);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: newProductDetails.data,
            insertOrUpdate: ['icon', 'catalogueImage', 'pdfOfferImage', 'productSystem', 'uploadedPdf', 'productSystemDetailsConfiguration'],
        });
        return Promise.resolve(newProductDetails);
    }

    public id!: string;
    public title!: string;
    public description!: string;
    public productSystem!: ProductSystem;
    public catalogueImage!: Image | null;
    public pdfOfferImage!: Image | null;
    public icon!: Image | null;

    [key: string]: any;
}
