import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import Attachment from '@/models/Attachment';

export default class PlainMessageAPI {
    public static async getAll() {
        let plainMessages;

        try {
            plainMessages = await ApiController.get(ApiRoutes.plainMessage.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(plainMessages);
    }
    public static async getById(plainMessageId: string) {
        let plainMessage;

        try {
            plainMessage = await ApiController.get(ApiRoutes.plainMessage.getById(plainMessageId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(plainMessage);
    }

    public static async delete(plainMessageId: string) {

        try {
            await ApiController.delete(ApiRoutes.plainMessage.delete(plainMessageId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(plainMessageId: string, plainMessageInfo: AvailableLabelFields) {
        let plainMessage;
        const request = {
            data: {
                type: 'lead_origins',
                id: plainMessageId,
                attributes: {
                    name: plainMessageInfo.name,
                },
            },
        };

        try {
            plainMessage =
                await ApiController.patch(
                    ApiRoutes.plainMessage.update(plainMessageId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(plainMessage);
    }

    public static async create(plainMessageInfo: AvailableLabelFields) {
        let plainMessage;
        const request = {
            data: {
                type: 'plain_messages',
                attributes: {
                    text: plainMessageInfo.text,
                },
                relationships: {
                    chatMessage: {
                        data: {
                            type: 'chat_messages',
                            id: plainMessageInfo.chatMessageId,
                        },
                    },
                },
            },
        };

        if (plainMessageInfo.attachments) {
            const attachmentData = plainMessageInfo.attachments.map((attachment: Attachment) => {
                return {
                    type: 'attachments',
                    id: attachment.id,
                };
            });
            // @ts-ignore
            request.data.relationships.attachments = {
                data: attachmentData,
            };
        }

        try {
            plainMessage =
                await ApiController.post(
                    ApiRoutes.plainMessage.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(plainMessage);
    }
}
