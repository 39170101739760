import { Model } from '@vuex-orm/core';
import ProjectStateAPI from '@/api/ProjectStateAPI';

export default class ProjectState extends Model {

    public static entity = 'projectState';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            type: this.string(''),
        };
    }

    public static async getAll() {
        let projectStates;
        try {
            projectStates = await ProjectStateAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: projectStates.data,
        });
        return Promise.resolve(projectStates);
    }

    public id!: string;
    public name!: string;

}
