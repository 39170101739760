import { Model } from '@vuex-orm/core';
import NextStepType from '@/models/NextStepType';
import NextStepTemplateAPI from '@/api/NextStepTemplateAPI';
import { AxiosError, AxiosResponse } from 'axios';
import ActivityFormConfiguration from '@/models/interfaces/ActivityFormConfiguration';

export default class NextStepTemplate extends Model {
    public static entity = 'nextStepTemplate';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            type: this.attr(''),
            responsiblePersonType: this.string(''),
            dateFrom: this.attr(null),
            dateTo: this.attr(null),
            note: this.string(''),
            createdAt: this.attr(''),
            updatedAt: this.attr(''),
        };
    }

    public static async getAll(): Promise<AxiosResponse | AxiosError> {
        let nextSteps;
        try {
            nextSteps = await NextStepTemplateAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextSteps.data,
        });
        return Promise.resolve(nextSteps);
    }

    public static async getById(nextStepId: string): Promise<AxiosResponse | AxiosError> {
        let nextStep;
        try {
            nextStep = await NextStepTemplateAPI.getById(nextStepId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextStep.data,
        });
        return Promise.resolve(nextStep);
    }

    // Write static function to get by labelId
    public static async getByLabelId(labelId: string): Promise<AxiosResponse | AxiosError> {
        let nextStep;

        try {
            nextStep = await NextStepTemplateAPI.getByLabelId(labelId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async updateExisting(
        nextStepId: string,
        nextStepInfo: ActivityFormConfiguration,
    ): Promise<void | AxiosError> {
        let nextStep;
        try {
            nextStep = await NextStepTemplateAPI.update(nextStepId, nextStepInfo);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: nextStep.data,
        });
        return Promise.resolve();
    }

    public static async createNew(nextStepInfo: ActivityFormConfiguration): Promise<void | AxiosError> {
        let nextStep;
        try {
            nextStep = await NextStepTemplateAPI.create(nextStepInfo);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: nextStep.data,
        });
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public note!: string;
    public dateFrom!: string;
    public dateTo!: string;
    public responsiblePersonType!: string;
    public type!: NextStepType;
    public createdAt!: string;
    public updatedAt!: string;
}
