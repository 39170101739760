import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import ActivityFormConfiguration from '@/models/interfaces/ActivityFormConfiguration';

export default class NextStepTemplateAPI {
    public static async getAll() {
        let nextSteps;

        try {
            nextSteps = (await ApiController.get(ApiRoutes.nextStepTemplate.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextSteps);
    }

    public static async getById(nextStepId: string) {
        let nextStep;

        try {
            nextStep = (await ApiController.get(
                ApiRoutes.nextStepTemplate.getById(nextStepId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async getByLabelId(labelId: string) {
        let nextStep;

        try {
            nextStep = (await ApiController.get(
                ApiRoutes.nextStepTemplate.getByLabelId(labelId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async update(nextStepId: string, nextStepInfo: ActivityFormConfiguration) {
        let nextStep;
        const payload = {
            data: {
                type: 'next_steps_template',
                id: nextStepId,
                attributes: {
                    name: nextStepInfo.name,
                    responsiblePersonType: nextStepInfo.responsiblePersonType,
                    note: nextStepInfo.note,
                    type: nextStepInfo.activityType,
                },
                relationships: {},
            },
        };

        if (nextStepInfo.statusEventId) {
            // @ts-ignore
            payload.data.relationships.statusEventActivityConfig = {
                data: {
                    type: 'status_event_activity_config',
                    id: nextStepInfo.statusEventId,
                },
            };
        }

        if (nextStepInfo.activityType) {
            // @ts-ignore
            payload.data.relationships.type = {
                data: {
                    type: 'next_step_types',
                    id: nextStepInfo.activityType,
                },
            };
        }

        try {
            nextStep = (await ApiController.patch(
                ApiRoutes.nextStepTemplate.update(nextStepId),
                payload,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async create(nextStepInfo: ActivityFormConfiguration) {
        let nextStep;
        const payload = {
            data: {
                type: 'next_steps_template',
                attributes: {
                    name: nextStepInfo.name,
                    responsiblePersonType: nextStepInfo.responsiblePersonType,
                    note: nextStepInfo.note,
                    type: nextStepInfo.activityType,
                },
                relationships: {},
            },
        };

        if (nextStepInfo.statusEventId) {
            // @ts-ignore
            payload.data.relationships.statusEventActivityConfig = {
                data: {
                    type: 'status_event_activity_config',
                    id: nextStepInfo.statusEventId,
                },
            };
        }

        if (nextStepInfo.activityType) {
            // @ts-ignore
            payload.data.relationships.type = {
                data: {
                    type: 'next_step_types',
                    id: nextStepInfo.activityType,
                },
            };
        }

        try {
            nextStep = (await ApiController.post(
                ApiRoutes.nextStepTemplate.create,
                payload,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }
}
