import OfferTitle from '@/models/OfferTitle';
import OfferItem from '@/models/OfferItem';
import { OfferItemIdAndType } from '@/interfaces/components/ProductsInOffer/OfferItemIdAndType';
import ProductSystem from '@/models/ProductSystem';
import Product from '@/models/Product';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { MANDATORY_SIMPLE_PRODUCT_CATEGORIES } from '@/enums/global/GlobalOptions';
import ProductCategory from '@/models/ProductCategory';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { ProductTypes } from '@/enums/global/ProductTypes';

export function isOfferItemMaterial(offerItem: OfferItem | OfferTitle) {
    return (
        offerItem instanceof OfferItem &&
        offerItem.offerItemOfferItemType === ProductTypes.MATERIAL &&
        offerItem.offerItemMaterial !== null
    );
}

export function removeOfferItemFromArrayById(
    offerItemId: string,
    offerItemsForUpdatingRowNumbers: OfferItemIdAndType[]
) {
    return offerItemsForUpdatingRowNumbers.filter((offerItemAndTitleObject) => {
        return offerItemAndTitleObject.type === 'offerTitle' || offerItemAndTitleObject.id !== offerItemId;
    });
}

export function transformProductSystems(productSystems: ProductSystem[]) {
    return productSystems
        .filter((productSystem) => !productSystem.isArchived)
        .filter((productSystem) => productSystem.product && !productSystem.product.productForm.isArchived)
        .map((productSystem) => {
            const productSystemDetailsImage =
                productSystem.productSystemDetails &&
                productSystem.productSystemDetails.icon &&
                productSystem.productSystemDetails.icon.image
                    ? productSystem.productSystemDetails.icon.image
                    : null;
            const productImage =
                productSystem.product && productSystem.product.icon ? productSystem.product.icon.image : null;

            return {
                productSystemName: productSystem.name,
                productSystemCode: productSystem.code,
                productSystemId: productSystem.id,
                productName: productSystem.product ? productSystem.product.productForm.name : '',
                productFormId: productSystem.product ? productSystem.product.productForm.id : '',
                productId: productSystem.product ? productSystem.product.id : '',
                isProductCustom: productSystem.product
                    ? productSystem.product.defaultFields[0]?.value?.includes('.CUSTOM')
                    : false,
                image: productSystemDetailsImage || productImage,
                showWindowDimensionsWizard: productSystem.product.windowDimensionWizardEnabled,
            };
        });
}

export function transformProducts(products: Product[]) {
    return products
        .filter((product) => !product.productForm.isArchived)
        .map((product) => {
            return {
                productSystemName: null,
                productSystemId: null,
                productName: product.productForm.name,
                productFormId: product.productForm.id,
                productId: product.id,
                isProductCustom: product?.defaultFields[0]?.value?.includes('.CUSTOM'),
                image: product.icon
                    ? product.icon.image
                    : product.productForm.gallery?.length && product.productForm.gallery?.length > 0
                    ? product.productForm.gallery[0].image
                    : null,
                showWindowDimensionsWizard: product.windowDimensionWizardEnabled,
            };
        });
}

export function determineIfProductCategoryMustBeSimple(productCategoryId: string) {
    return !getMandatorySimpleProductCategories()
        .map((productCategory) => productCategory.id)
        .includes(productCategoryId);
}

function getMandatorySimpleProductCategories() {
    return MANDATORY_SIMPLE_PRODUCT_CATEGORIES.map((productCategoryCode) =>
        ProductCategoriesRepository.getByCode(productCategoryCode)
    ).filter((productCategory) => productCategory != null) as ProductCategory[];
}

export function generateProductCategoryOptions(productCategories: ProductCategory[], shouldShowMaterials: boolean) {
    if (productCategories.length <= 0) {
        return [];
    }

    if (!shouldShowMaterials) {
        const materialProductCategoryIndex = productCategories.findIndex(
            (productCategory) => productCategory.code === 'MATERIALS'
        );

        if (materialProductCategoryIndex !== -1) {
            productCategories.splice(materialProductCategoryIndex, 1);
        }
    }
    const filteredProductCategories: RadioButtonGroupOptions[] = [];
    productCategories.forEach((productCategory) => {
        const products = productCategory.products;

        if (products.length <= 0) {
            filteredProductCategories.push({
                label: productCategory.name,
                value: productCategory.id,
            });
        }

        const areAllProductFormsArchived = products
            .map((product) => product.productForm.isArchived)
            .every((isArchived) => isArchived);

        if (!areAllProductFormsArchived) {
            filteredProductCategories.push({
                label: productCategory.name,
                value: productCategory.id,
            });
        }
    });

    return filteredProductCategories;
}
