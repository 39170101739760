import ProductCatalogue from '@/models/ProductCatalogue';
import { DiscountTableEntry } from '@/interfaces/components/clients/DiscountTableEntry';
import i18n from '@/i18n';
import { DiscountFormFields } from '@/enums/components/EditClient/DiscountFormFields';
import Discount from '@/models/Discount';
import { CommonDiscountGroupNames } from '@/enums/components/Discount/CommonDiscountGroupNames';
import PriceTypeRepository from '@/repositories/PriceTypeRepository';
import PriceType from '@/models/PriceType';
import { ClientFieldEntry } from '@/interfaces/components/clients/ClientFieldEntry';
import ProductGroup from '@/models/ProductGroup';
import { CommonPriceTypes } from '@/enums/global/CommonPriceTypes';

export function discountTableColumns(selectedPriceTypeId: string) {
    const selectedPriceType = PriceTypeRepository.getById(selectedPriceTypeId) as PriceType;

    let columns = [
        {
            title: i18n.t('Kategorija'),
            dataIndex: DiscountFormFields.DISCOUNT_CATEGORY,
            key: DiscountFormFields.DISCOUNT_CATEGORY,
            scopedSlots: { customRender: DiscountFormFields.DISCOUNT_CATEGORY },
            isEditable: true,
            width: 200,
        },
        {
            title: i18n.t('Ime'),
            dataIndex: DiscountFormFields.NAME,
            key: DiscountFormFields.NAME,
            scopedSlots: { customRender: DiscountFormFields.NAME },
            isEditable: true,
            width: 1000,
        },
        {
            title: i18n.t('PC1'),
            dataIndex: DiscountFormFields.PC1,
            key: DiscountFormFields.PC1,
            scopedSlots: { customRender: DiscountFormFields.PC1 },
            isEditable: true,
        },
        {
            title: i18n.t('PC2'),
            dataIndex: DiscountFormFields.PC2,
            key: DiscountFormFields.PC2,
            scopedSlots: { customRender: DiscountFormFields.PC2 },
            isEditable: true,
        },
        {
            title: '%',
            dataIndex: DiscountFormFields.IS_PERCENTAGE,
            key: DiscountFormFields.IS_PERCENTAGE,
            slots: { title: DiscountFormFields.IS_PERCENTAGE },
            scopedSlots: { customRender: DiscountFormFields.IS_PERCENTAGE },
            align: 'center',
            width: 50,
            isEditable: true,
        },
        {
            title: '',
            dataIndex: DiscountFormFields.ACTIONS,
            key: DiscountFormFields.ACTIONS,
            slots: { title: DiscountFormFields.ACTIONS },
            scopedSlots: { customRender: DiscountFormFields.ACTIONS },
            align: 'center',
            width: 180,
            isEditable: false,
        },
    ];

    if (selectedPriceType.getName === CommonPriceTypes.VK1) {
        columns = columns.filter((column) => column.key !== DiscountFormFields.PC2);
    }

    if (selectedPriceType.getName === CommonPriceTypes.VK2) {
        columns = columns.filter((column) => column.key !== DiscountFormFields.PC1);
    }

    return columns;
}

/**
 * Fetches materials based on the given search text and transforms them to a type that can
 * be handled by Autocomplete component
 * @param searchText - The search text that the description will be filtered by
 * @return - The id/description of all the materials
 */
export async function fetchAndTransformMaterials(searchText: string) {
    const materials = await ProductCatalogue.search(searchText);

    return materials.map((productCatalogue: ProductCatalogue) => {
        return {
            value: productCatalogue.oid,
            text: productCatalogue.description,
        };
    });
}

/**
 * Sets the isEditing flag to true for the given ID of an item
 * @param discounts - All discounts that are active on a client
 * @param id - The ID of the editing discount
 * @return - The updated discounts
 */
export async function onEditFormItem(discounts: DiscountTableEntry[], id: string) {
    const newDiscounts = [...discounts];
    const target = newDiscounts.filter((item) => id === item.id)[0];

    if (!target) {
        return Promise.reject(new Error('Discount was not found in existing elements'));
    }

    target.isEditing = true;
    return Promise.resolve(newDiscounts);
}

/**
 * Cache edited discount in case of cancel
 * @param discounts - All discounts that are active on a client
 * @param id - The ID of the editing discount
 * @return - Copy of the cached discount
 */
export function cacheEditedDiscount(id: string, discounts: DiscountTableEntry[]) {
    const target = discounts.filter((item) => id === item.id)[0];

    return Object.assign({}, target);
}

/**
 * Updates or creates a new discount
 * Runs validation before saving
 * @param discounts - All discounts that are active on a client
 * @param id - The ID of the editing discount
 * @param clientId - The id of the client
 * @return - An empty promise
 */
export async function onSaveForm(discounts: DiscountTableEntry[], id: string, clientId: string) {
    const target = discounts.filter((item) => id === item.id)[0];

    if (!target) {
        return Promise.reject(new Error('Current discount was not found'));
    }

    try {
        validateTableEntry(target);
        await createOrUpdateDiscount(target, clientId);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Saves local state of discounts
 * @param id - Edited discount that should be saved
 * @param discounts - All discounts that are active on a client
 * @return - New discounts promise
 */
export async function onSaveLocally(id: string, discounts: DiscountTableEntry[]) {
    const newDiscounts: DiscountTableEntry[] = [...discounts];
    const target: DiscountTableEntry = newDiscounts.filter((discount) => discount.id === id)[0];

    if (!target) {
        return Promise.reject(new Error('Current discount was not found'));
    }

    validateTableEntry(target);
    target.isEditing = false;

    return Promise.resolve(newDiscounts);
}

/**
 * Deletes discounts from the database
 * @param deletedKeys - The keys of the items that should be deleted from backend
 * @return - An empty promise
 */
export async function deleteItems(deletedKeys: string[]) {
    try {
        for (const id of deletedKeys) {
            await onDeleteItem(id);
        }
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Deletes the given discount item
 * @param id - The ID of the discount
 * @return - An empty promise
 */
export async function onDeleteItem(id: string) {
    try {
        await Discount.deleteExisting(id);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Deletes the given discount item
 * @param tableEntry - The discount table entry that will be created or updated
 * @param clientId - The id of the client this discount is being added to
 */
export async function createOrUpdateDiscount(tableEntry: DiscountTableEntry, clientId: string) {
    try {
        if (tableEntry.id.includes('new')) {
            await Discount.createNew({ ...tableEntry, clientId });
        } else {
            await Discount.updateExisting(tableEntry.id, { ...tableEntry, clientId });
        }
    } catch (e) {
        throw e;
    }
}

/**
 * Validates that there are not empty values
 * @param tableEntry - The entry that needs to be checked
 * @return - True if validation has passed, otherwise throws error
 */
export function validateTableEntry(tableEntry: DiscountTableEntry) {
    if (
        tableEntry.name === '' ||
        tableEntry.name == null ||
        tableEntry.pc1 == null ||
        tableEntry.pc2 == null ||
        tableEntry.discountCategory == null
    ) {
        throw new Error(i18n.t('Sva polja su obavezna!') as string);
    }

    return true;
}

/**
 * Cancels editing and removes any changes
 * @param discounts - All discounts that are active on a client
 * @param cachedDiscount - Cached discount that is being edited
 * @param id - The ID of the editing discount
 * @return - The updated discounts
 */
export async function onCancel(discounts: DiscountTableEntry[], cachedDiscount: DiscountTableEntry | null, id: string) {
    let newDiscounts = [...discounts];
    // const newCachedDiscounts = [...cachedDiscounts];
    // const target = newDiscounts.filter((item) => id === item.id)[0];
    const targetCache = newDiscounts.filter((item) => id === item.id)[0];

    if (!targetCache) {
        return Promise.reject(new Error('Discount was not found in existing elements'));
    }

    if (cachedDiscount == null) {
        newDiscounts = newDiscounts.filter((item) => item.id !== id);
    } else {
        Object.assign(targetCache, cachedDiscount);
        targetCache.isEditing = false;
    }

    return Promise.resolve(newDiscounts);
}

/**
 * Retrieves discounts from the client and transforms them to fit the table data
 * @param discounts - All of the discounts on a client
 * @return - The transformed client discounts
 */
export function transformClientDiscountsToTableEntry(discounts: Discount[]) {
    return discounts.map((discount) => {
        return {
            id: discount.id,
            key: discount.id,
            discountedEntityOid: discount.discountedEntityOid,
            name: discount.discountedEntity.name,
            pc1: discount.price1[0],
            pc2: discount.price2[0],
            isPercentage: discount.isPercentage,
            isEditing: false,
            discountCategory: discount.discountType,
            productGroup: discount.productGroup ? discount.productGroup.id : null,
        };
    });
}

/**
 * Generates an empty table entry
 * @return - An empty table entry
 */
export function generateEmptyTableEntry() {
    return {
        id: 'new',
        key: 'new',
        category: '',
        name: '',
        pc1: 0,
        pc2: 0,
        isPercentage: false,
        isEditing: true,
        discountCategory: CommonDiscountGroupNames.PRODUCT_GROUP,
        discountedEntityOid: null,
        productGroup: null,
    };
}

/**
 * Resets the pc1 or pc2 value based on the currently selected price type
 * @param {fieldName, fieldValue} - The key/value pair of the changed form item
 * @param handleChange - The function for handling the local change
 * @param editingKey - The currently active key of the row
 */
export function resetFormValueAfterPriceTypeChange(
    { fieldName, fieldValue }: ClientFieldEntry,
    handleChange: (value: string | ProductGroup | number, id: string, column: string) => void,
    editingKey: string
) {
    const selectedPriceType = PriceTypeRepository.getById(fieldValue) as PriceType;

    if (selectedPriceType.getName === CommonPriceTypes.VK1) {
        handleChange(0, editingKey, DiscountFormFields.PC2);
    } else {
        handleChange(0, editingKey, DiscountFormFields.PC1);
    }
}
