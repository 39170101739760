
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RTETitleIcon from '@/icons/RTETitleIcon.vue';
import RTEAddImageIcon from '@/icons/RTEAddImageIcon.vue';
// @ts-ignore
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import {
    Blockquote,
    Bold,
    BulletList,
    Code,
    CodeBlock,
    HardBreak,
    Heading,
    History,
    HorizontalRule,
    Link,
    ListItem,
    OrderedList,
    Strike,
    TodoItem,
    TodoList,
    Underline,
    // @ts-ignore
} from 'tiptap-extensions';
import Image from '@/helpers/RichTextHelper';
import { uploadImage, toBase64 } from '@/helpers/attachments/AttachmentsHelper';

@Component({
    name: 'RichTextEditor',
    components: {
        EditorContent,
        EditorMenuBar,
        RTETitleIcon,
        RTEAddImageIcon,
        EditorMenuBubble,
    },
})
export default class RichTextEditor extends Vue {
    @Prop({ required: true }) public title!: string;
    @Prop({ required: true }) public content!: string;
    @Prop({ default: true }) public shouldShowLegend!: boolean;
    @Prop({ default: false }) public shouldUploadImagesAsAttachments!: boolean;
    @Prop({ required: true }) public editablePropertyName!: string;
    @Prop({ default: true }) public shouldShowMenuBar!: boolean;
    @Prop({ default: false }) public isReadOnly!: boolean;
    @Prop({ default: false }) public shouldUpdateDynamically!: boolean;
    private editor = new Editor({
        editable: true,
        extensions: [
            new Blockquote(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [2] }),
            new BulletList(),
            new OrderedList(),
            new ListItem(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Code(),
            new Link(),
            new Strike(),
            new Underline(),
            new History(),
            new HorizontalRule(),
            new Image(null, null, this.uploadImage),
        ],
        content: this.content,
        onUpdate: this.onUpdate,
    });

    private imageCommand: any = null;
    private linkUrl: any = null;
    private linkMenuIsActive = false;

    private onUpdate({ getHTML }: { getHTML: () => string }) {
        const html = this.fixEmptyNewlines(getHTML());
        this.$emit('updateContent', html);
    }

    private fixEmptyNewlines(html: string) {
        return html.replace(/<p><\/p>/gim, '<p>&nbsp;</p>');
    }

    private showImagePrompt(command: any) {
        const fileInput = this.$refs.file as HTMLInputElement;
        fileInput.click();
        this.imageCommand = command;
    }

    private async fileChange() {
        if (this.imageCommand == null) {
            return;
        }
        const fileInput = this.$refs.file as HTMLInputElement;
        const file = fileInput.files ? fileInput.files[0] : null;
        if (file == null) {
            return;
        }
        const image = this.shouldUploadImagesAsAttachments ? await uploadImage(file) : await toBase64(file);
        this.imageCommand({ src: image });
    }

    private async uploadImage(file: File) {
        return this.shouldUploadImagesAsAttachments ? await uploadImage(file) : await toBase64(file);
    }

    private showLinkMenu(attrs: any) {
        this.linkUrl = attrs.href;
        this.linkMenuIsActive = true;
        this.$nextTick(() => {
            (this.$refs.linkInput as HTMLElement).focus();
        });
    }

    private hideLinkMenu() {
        this.linkUrl = null;
        this.linkMenuIsActive = false;
    }

    private setLinkUrl(command: any, url: string) {
        command({ href: url });
        this.hideLinkMenu();
    }

    private beforeDestroy() {
        if (this.editor == null) {
            return;
        }
        this.editor.destroy();
    }

    @Watch('isReadOnly', { immediate: true })
    private editable() {
        this.editor.setOptions({
            editable: !this.isReadOnly,
        });
    }

    @Watch('content', { immediate: true })
    private setContent() {
        if (!this.shouldUpdateDynamically || !this.content) {
            return;
        }

        this.editor.setContent(this.content);
    }
}
