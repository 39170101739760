import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class OrderStatusAPI {
    public static async getAll() {
        let orderStatuses;

        try {
            orderStatuses = await ApiController.get(ApiRoutes.orderStatuses.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderStatuses);
    }

    public static async getById(orderStatusId: string) {
        let orderStatus;

        try {
            orderStatus = await ApiController.get(ApiRoutes.orderStatuses.getById(orderStatusId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderStatus);
    }

    public static async delete(orderStatusId: string) {

        try {
            await ApiController.delete(ApiRoutes.orderStatuses.delete(orderStatusId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(orderStatusId: string, orderStatusInfo: AvailableLabelFields) {
        let orderStatus;
        const request = {
            data: {
                type: 'order_statuses',
                id: orderStatusId,
                attributes: {
                    name: orderStatusInfo.name,
                    code: orderStatusInfo.code,
                },
            },
        };

        try {
            orderStatus =
                await ApiController.patch(
                    ApiRoutes.orderStatuses.update(orderStatusId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderStatus);
    }

    public static async create(orderStatusInfo: AvailableLabelFields) {
        let orderStatus;
        const code = orderStatusInfo.name.trim().toUpperCase();
        const request = {
            data: {
                type: 'order_statuses',
                attributes: {
                    name: orderStatusInfo.name,
                    code,
                },
            },
        };

        try {
            orderStatus =
                await ApiController.post(
                    ApiRoutes.orderStatuses.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderStatus);
    }
}
