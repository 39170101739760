
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CountryRepository from '@/repositories/CountryRepository';

@Component({
    name: 'ClientAddressForm',
})
export default class test extends Vue {
    @Prop({ default: null }) private value!: any;
    @Prop({ required: true }) private addressType!: string;

    private get defaultCountry() {
        if (!this.value) {
            return;
        }

        switch (this.addressType) {
            case 'delivery':
                if (this.value.deliveryAddressForm.country) {
                    return CountryRepository.getById(this.value.deliveryAddressForm.country);
                }
                break;
            case 'invoice':
                if (this.value.invoiceAddressForm.country) {
                    return CountryRepository.getById(this.value.invoiceAddressForm.country);
                }
                break;
            default:
                if (this.value.addressForm.country) {
                    return CountryRepository.getById(this.value.addressForm.country);
                }
        }

        return '';
    }

    private selectedCountry = this.defaultCountry ? this.defaultCountry.id + '|' + this.defaultCountry.code : '';

    private get addressForm() {
        switch (this.addressType) {
            case 'delivery':
                return 'deliveryAddressForm';
                break;
            case 'invoice':
                return 'invoiceAddressForm';
                break;
            default:
                return 'addressForm';
        }
    }

    private get flagUrl() {
        return `url(https://flagsapi.com/${this.selectedCountry.split('|')[1]}/flat/32.png)` ?? '';
    }

    private get countries() {
        return CountryRepository.getAll();
    }

    private onSelectedCountryChange() {
        const countryId = this.selectedCountry.split('|')[0];

        switch (this.addressType) {
            case 'delivery':
                this.value.deliveryAddressForm.country = countryId;
                break;
            case 'invoice':
                this.value.invoiceAddressForm.country = countryId;
                break;
            default:
                this.value.addressForm.country = countryId;
        }
    }

    @Watch('defaultCountry', {
        immediate: true,
    })
    private changeCurrentCountry() {
        this.selectedCountry = this.defaultCountry ? this.defaultCountry.id + '|' + this.defaultCountry.code : '';
    }
}
