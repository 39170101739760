import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductFunctionsAPI {
    public static async getAll() {
        let ProductFunctions;

        try {
            ProductFunctions = (await ApiController.get(ApiRoutes.productVersion.JSFunctions)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(ProductFunctions);
    }
}
