import { Model } from '@vuex-orm/core';
import Image from '@/models/Image';

export default class ProductSystemWizardTranslation extends Model {

    public static entity = 'productSystemWizardTranslation';

    public static fields() {
        return {
            id: this.attr(null),
            description: this.string(''),
            image_id: this.attr(null),
            image: this.belongsTo(Image, 'image_id', 'id'),
            locale: this.string(''),
        };
    }

    public id!: string;
    public description!: string;
    public image!: null | Image;
    public locale!: string | null;
}
