import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';

export default class ReportAPI {
    public static async getTypes() {
        let types;

        try {
            types = (await ApiController.get(ApiRoutes.reports.getTypes)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(types);
    }
}
