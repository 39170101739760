export function toggleClassBasedOnOverlapFromTargetElement(
    scrollElement: HTMLElement | undefined,
    targetElement: HTMLElement | undefined,
    className: string) {
    if (scrollElement == null || targetElement == null) {
        return;
    }


    if ((getRectTop(scrollElement) + document.body.scrollTop) + scrollElement.offsetHeight
        >= (getRectTop(targetElement) + document.body.scrollTop) - 10) {
        scrollElement.classList.add(className);
    }
    if (document.body.scrollTop + window.innerHeight < (getRectTop(targetElement) + document.body.scrollTop)) {
        scrollElement.classList.remove(className);
    }
}

function getRectTop(el: Element | undefined) {
    if (el == null) {
        return 0;
    }

    const rect = el.getBoundingClientRect();
    return rect.top;
}
