
import { Component, Prop, Vue } from 'vue-property-decorator';
import ActivityModalForm, { ActivityFormData } from '@/components/global/ActivityModalForm.vue';
import NextStep from '@/models/NextStep';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import { transformObjectToBeBackendCompliant } from '@/helpers/NextStepHelper';
import Lead from '@/models/interfaces/Lead';
import { createNewChatMessageAndChat } from '@/helpers/chat/ChatHelper';
import Project from '@/models/Project';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import router from '@/router/index';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { generateStateSpecificId } from '@/helpers/project/ProjectHelper';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import NextStepRepository from '@/repositories/NextStepRepository';
import { Mutation } from 'vuex-class';

@Component({
    name: 'ActivityUpdateModal',
    components: {
        ActivityModalForm,
    },
})
export default class ActivityUpdateModal extends Vue {
    @Mutation('tableData/updateNextStep') private updateNextStep!: (nextStep: NextStep) => void;
    @Prop({ required: true }) private lead!: Lead;
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private isActivityUpdateModalVisible!: boolean;
    @Prop({ required: true }) private activity!: NextStep | null;
    @Prop({ required: true }) private canActivityBeDeleted!: boolean;
    private formData: ActivityFormData | null = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private closeActivityUpdatePopup() {
        this.$emit('update:isActivityUpdateModalVisible', false);
    }

    private get upgradedActivity() {
        return {
            id: this.activity?.id,
            name: this.activity?.name,
            date: this.activity?.date,
            dateFrom: this.activity?.dateFrom,
            dateTo: this.activity?.dateTo,
            note: this.activity?.note,
            reminderDate: this.activity?.reminderDate,
            completed: this.activity?.completed,
            reminderOption: this.activity?.reminderOption ? this.activity?.reminderOption.id : null,
            nextStepType: this.activity?.type ? this.activity?.type.id : null,
            assignedUser: this.activity?.assignedUser ? this.activity?.assignedUser.id : '',
        };
    }

    private get projectSpecificId() {
        return generateStateSpecificId(this.project);
    }

    private async onDeleteActivity() {
        this.loadingOverlay.start();

        try {
            if (!this.activity) {
                throw new Error('Activity not set');
            }
            await NextStep.deleteExisting(this.activity.id);
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });
        } finally {
            this.loadingOverlay.stop();
        }

        this.closeActivityUpdatePopup();
        this.$notification.success({
            message: this.$t('Activity deleted successfully'),
            description: ``,
        });
        EventBus.$emit(EventBusEvents.updateProject);
    }

    private async onToggleCompleted() {
        if (this.activity?.completed !== undefined) {
            this.activity.completed = !this.activity?.completed;
        }
    }

    private updateFormData(data: ActivityFormData) {
        this.formData = {
            ...data,
        };
    }

    private get activityTypes() {
        return NextStepTypeRepository.getAll();
    }

    private async saveActivity() {
        this.loadingOverlay.start();

        try {
            if (!this.activity) { throw new Error('Activity not set'); }
            const data: UpgradedNextStep =
            this.formData ? {
                ...this.activity,
                ...this.formData,
                nextStepType: this.formData.activityType,
                leadId: this.project?.id,
            } : {
                ...this.activity,
                ...this.upgradedActivity,
                leadId: this.project?.id,
            }
            ;
            if (!this.formData?.name) {
                data.name = this.activityTypes.find(
                    (a) => a?.id + '' === this.formData?.activityType,
                )?.name;
            }

            const chatId = await createNewChatMessageAndChat(
                true,
                this.project?.id,
                this.project?.chat?.id,
                this.activity?.id,
            );
            const backendCompliantObject = transformObjectToBeBackendCompliant(
                data,
                chatId,
            );
            await NextStep.updateExisting(
                this.activity?.id,
                backendCompliantObject,
            );

            const nextStep = NextStepRepository.getById(this.activity?.id);

            if (nextStep !== null) {
              this.updateNextStep(nextStep);
            }
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });
            EventBus.$emit(EventBusEvents.updateProject);

        } finally {
            this.loadingOverlay.stop();
        }

        this.closeActivityUpdatePopup();
    }
    private navigateToProject() {
        router.push({
            name: RouteNames.project,
            params: {
                id: this.project.id,
            },
            query: {
                initialTab: `${ProjectTabValues.Basic}`,
            },
        });
    }
}
