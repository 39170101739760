import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import OfferPdfPropertiesConfiguration from '@/models/interfaces/OfferPdfPropertiesConfiguration';
import { constructOfferPdfPropertiesRequest } from '@/helpers/OfferPdfPropertiesHelper';

export default class OfferPdfPropertiesAPI {
    public static async getAll() {
        let offerPdfProperties;

        try {
            offerPdfProperties = await ApiController.get(ApiRoutes.offerPdfProperties.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerPdfProperties);
    }

    public static async getById(offerPdfPropertiesId: string) {
        let offerPdfProperties;

        try {
            offerPdfProperties =
                await ApiController.get(ApiRoutes.offerPdfProperties.getById(offerPdfPropertiesId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerPdfProperties);
    }

    public static async update(
        offerPdfPropertiesId: string,
        offerPdfPropertiesConfig: OfferPdfPropertiesConfiguration,
        offerConfigurationId?: string) {
        let offerPdfProperties;
        const request = constructOfferPdfPropertiesRequest(
            {
                offerPdfPropertiesId,
                offerPdfPropertiesConfig,
                offerConfigurationId,
            });

        try {
            offerPdfProperties =
                await ApiController.patch(
                    ApiRoutes.offerPdfProperties.update(offerPdfPropertiesId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerPdfProperties);
    }

    public static async create(
        offerPdfPropertiesConfig: OfferPdfPropertiesConfiguration,
        offerConfigurationId?: string) {
        let offerPdfProperties;
        const request = constructOfferPdfPropertiesRequest(
            {
                offerPdfPropertiesConfig,
                offerConfigurationId,
            });

        try {
            offerPdfProperties =
                await ApiController.post(
                    ApiRoutes.offerPdfProperties.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerPdfProperties);
    }

    public static async delete(offerPdfPropertiesId: string) {

        try {
            await ApiController.delete(ApiRoutes.offerPdfProperties.delete(offerPdfPropertiesId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
