import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import OrderPdfPropertiesConfiguration from '@/models/interfaces/OrderPdfPropertiesConfiguration';
import { constructOrderPdfPropertiesRequest } from '@/helpers/OrderPdfPropertiesHelper';

export default class OrderPdfPropertiesAPI {
    public static async getAll() {
        let orderPdfProperties;

        try {
            orderPdfProperties = await ApiController.get(ApiRoutes.orderPdfProperties.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderPdfProperties);
    }

    public static async getById(orderPdfPropertiesId: string) {
        let orderPdfProperties;
        try {
            orderPdfProperties =
                await ApiController.get(ApiRoutes.orderPdfProperties.getById(orderPdfPropertiesId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderPdfProperties);
    }

    public static async update(
        orderPdfPropertiesId: string,
        orderPdfPropertiesConfig: OrderPdfPropertiesConfiguration,
        orderConfigurationId?: string) {
        let orderPdfProperties;
        const request = constructOrderPdfPropertiesRequest(
            {
                orderPdfPropertiesId,
                orderPdfPropertiesConfig,
                orderConfigurationId,
            });

        try {
            orderPdfProperties =
                await ApiController.patch(
                    ApiRoutes.orderPdfProperties.update(orderPdfPropertiesId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderPdfProperties);
    }

    public static async create(
        orderPdfPropertiesConfig: OrderPdfPropertiesConfiguration,
        orderConfigurationId?: string) {
        let orderPdfProperties;
        const request = constructOrderPdfPropertiesRequest(
            {
                orderPdfPropertiesConfig,
                orderConfigurationId,
            });

        try {
            orderPdfProperties =
                await ApiController.post(
                    ApiRoutes.orderPdfProperties.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderPdfProperties);
    }

    public static async delete(orderPdfPropertiesId: string) {

        try {
            await ApiController.delete(ApiRoutes.orderPdfProperties.delete(orderPdfPropertiesId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
