import { Model } from '@vuex-orm/core';
import TaxDetailsAPI from '@/api/TaxDetailsAPI';

export default class TaxDetails extends Model {

    public static entity = 'taxDetails';

    public static fields() {
        return {
            id: this.string(null),
            amount: this.attr(null),
            description: this.attr(null),
        };
    }

    public static async getAll() {
        let taxDetails;
        try {
            taxDetails = await TaxDetailsAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: taxDetails.data,
        });
        return Promise.resolve();
    }

    public static async getById(taxDetailsId: string) {
        let taxDetails;
        try {
            taxDetails = await TaxDetailsAPI.getById(taxDetailsId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: taxDetails.data,
        });
        return Promise.resolve(taxDetails);
    }

    public static async updateExisting(taxDetailsId: string, taxDetailsInfo: any) {
        let taxDetails;
        try {
            taxDetails = await TaxDetailsAPI.update(taxDetailsId, taxDetailsInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: taxDetails.data,
        });
        return Promise.resolve(taxDetails);
    }

    public static async createNew(taxDetailsInfo: any) {
        let taxDetails;
        try {
            taxDetails = await TaxDetailsAPI.create(taxDetailsInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: taxDetails.data,
        });
        return Promise.resolve(taxDetails);
    }

    public static async deleteExisting(taxDetailsId: string) {
        try {
            await TaxDetailsAPI.delete(taxDetailsId);
        } catch (e) {
            return Promise.reject(e);
        }
        await TaxDetails.delete(taxDetailsId);
        return Promise.resolve();
    }

    public id!: string;
    public amount!: number;
    public description!: string;

}
