var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading || _vm.hiddenIndices.length > 0),expression:"!isLoading || hiddenIndices.length > 0"}],ref:"wrapper",staticClass:"c-add-product-row c-add-product-row__header"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hiddenIndices.length > 0),expression:"hiddenIndices.length > 0"}],staticClass:"c-checklist__row-number-label",class:{ 'c-checklist__row--padding': _vm.isCombinationSelectionActive }},[_c('label',{staticClass:"c-checklist__row-label u-b2--"},[_vm._v(" "+_vm._s(_vm.$t('Br'))+" ")])]),_vm._l((_vm.productData.editableFields),function(checklistField,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.hiddenIndices.length > 0 ? !_vm.hiddenIndices[index] : false) && checklistField.level <= _vm.level),expression:"(hiddenIndices.length > 0 ? !hiddenIndices[index] : false) && checklistField.level <= level"}],key:checklistField.id,staticClass:"c-checklist__row is-alternative is-multiposition",class:`c-checklist__row--${checklistField.fieldType}`},[_c('label',{staticClass:"c-checklist__row-label u-b2--",class:[checklistField.description != null ? 'is-clickable' : '', 'level-' + checklistField.level],on:{"click":() => {
                        _vm.toggleInfoDetailsModal(checklistField);
                    }}},[_c('Tooltip',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(checklistField.name)+" ")]},proxy:true}],null,true)},[(checklistField.description !== null)?_c('a-icon',{staticClass:"c-checklist__label-details",attrs:{"type":"info-circle"}}):_vm._e(),_vm._v(" "+_vm._s(checklistField.name)+" ")],1)],1)])}),(_vm.isCustomProduct)?_c('div',{staticClass:"c-add-material-slot__row"},[_c('div',{staticClass:"c-add-material-slot__wrapper dropdown"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Povezano s proizvodom')))]),_c('ASelect',{staticClass:"c-add-material-slot__select u-b1",attrs:{"filterOption":"","optionFilterProp":"children","show-search":""},on:{"change":function($event){return _vm.$emit('is-connected', $event)}}},_vm._l((_vm.filterProductsFromOfferItems(_vm.offer ? _vm.offer.offerItems : [])),function(offerItem){return _c('ASelectOption',{key:offerItem.rowNumber,staticClass:"c-language-picker__option u-b1",attrs:{"value":offerItem.displayRowNumber}},[_vm._v(" "+_vm._s(offerItem.displayRowNumber)+" - "+_vm._s(offerItem.name)+" ")])}),1)],1),_c('div',{staticClass:"c-add-material-slot__wrapper"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Uključeno u produkciju')))]),_c('ASwitch',{on:{"change":function($event){return _vm.$emit('is-production', $event)}}})],1)]):_vm._e(),(_vm.modalChecklistField != null)?_c('a-modal',{attrs:{"dialog-style":{
                top: '20px',
                height: '90%',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                justifyContent: 'center',
            },"dialogClass":{ 'has-two-items': _vm.modalChecklistField.image !== null },"title":_vm.modalChecklistField.name,"visible":_vm.isModalVisible},on:{"cancel":() => {
                    _vm.toggleInfoDetailsModal(null);
                }}},[_c('div',{ref:"modalInner",staticClass:"c-checklist__modal-inner",class:{ 'c-checklist__modal-inner--two-items': _vm.modalChecklistField.image !== null }},[(_vm.modalChecklistField.image !== null)?_c('div',{ref:"imageWrapper",staticClass:"c-checklist__image-wrapper",style:({ backgroundImage: `url(${_vm.modalChecklistField.image.image})` })}):_vm._e(),_c('p',{ref:"text"},[_vm._v(" "+_vm._s(_vm.modalChecklistField.description)+" ")])]),_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":() => {
                            _vm.toggleInfoDetailsModal(null);
                        }}},[_vm._v(" OK ")])],1)],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }