import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';

const getters: GetterTree<ILocalState, {}> = {
    projects(state) {
        return state.projects;
    },
    nextSteps(state) {
        return state.nextSteps;
    },
    clients(state) {
        return state.clients;
    },
    sortedProjectsObject(state) {
        return state.projectNavigationSettings.sortedProjectsObject;
    },
    isSortedProjectsObjectEmpty(state) {
        return Object.values(state.projectNavigationSettings.sortedProjectsObject).length <= 0;
    },
    isLastPageFetched(state) {
        return state.projectNavigationSettings.isLastPageFetched;
    },
    isFirstPageFetched(state) {
        return state.projectNavigationSettings.isFirstPageFetched;
    },
    entityType(state) {
        return state.projectNavigationSettings.entityType;
    },
    userId(state) {
        return state.projectNavigationSettings.userId;
    },
};

export default getters;
