import CampaignType from '@/models/CampaignType';

export default class CampaignTypeRepository {
    public static getAll() {
        return CampaignType.all();
    }

    public static getById(id: string) {
        return CampaignType.query().whereId(id).withAll().first();
    }
}
