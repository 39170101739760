import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import AttachmentAPI from '@/api/AttachmentAPI';
import Lead from '@/models/Lead';

export default class Attachment extends Model {

    public static entity = 'attachment';

    public static fields() {
        return {
            id: this.string(null),
            attachment: this.string(''),
            title: this.string(''),
            fileType: this.string(''),
            fileSize: this.string(''),
            displayName: this.string(''),
            lead_id: this.string(''),
            lead: this.belongsTo(Lead, 'lead_id'),
        };
    }

    public static async createNew(formData: FormData, shouldBePublic = false) {
        let attachment;
        try {
            attachment = await AttachmentAPI.createNew(formData, shouldBePublic) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: attachment.data,
            insertOrUpdate: ['lead'],
        });

        return Promise.resolve(attachment);
    }

    public static async getBlob(resourceUrl: string) {
        return await AttachmentAPI.getBlob(resourceUrl);
    }

    public static async getAttachmentsByProjectId(projectId: string) {
        let attachments;
        try {
            attachments = await AttachmentAPI.getAttachmentsByProjectId(projectId) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: attachments.data,
        });

        return Promise.resolve(attachments);
    }

    public static async deleteExisting(attachmentId: string) {
        try {
            await AttachmentAPI.delete(attachmentId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Attachment.delete(attachmentId);
        return Promise.resolve();
    }
    public id!: string;
    public attachment!: string;
    public uuid!: string;
    public title!: string;
    public type!: string;
}
