import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class IntroScreenAPI {
    public static async getAll() {
        let introScreens;

        try {
            introScreens = await ApiController.get(ApiRoutes.introScreen.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreens);
    }

    public static async getById(introScreenId: string) {
        let introScreen;

        try {
            introScreen = await ApiController.get(ApiRoutes.introScreen.getById(introScreenId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreen);
    }

    public static async update(introScreenId: string, introScreenInfo: AvailableLabelFields) {
        let introScreen;

        const payload = {
            data: {
                type: 'intro_screens',
                id: introScreenId,
                attributes: {
                    userGroupId: introScreenInfo.userGroupId,
                },
                relationships: {},
            },
        };

        try {
            introScreen =
                await ApiController.patch(
                    ApiRoutes.introScreen.update(introScreenId),
                    payload,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreen);
    }

    public static async create(introScreenInfo: AvailableLabelFields) {
        let introScreen;
        const payload = {
            data: {
                type: 'intro_screens',
                attributes: {
                    userGroupId: introScreenInfo.userGroupId,
                },
                relationships: {},
            },
        };

        try {
            introScreen =
                await ApiController.post(
                    ApiRoutes.introScreen.create,
                    payload,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreen);
    }

    public static async delete(introScreenId: string) {
        try {
            await ApiController.delete(ApiRoutes.introScreen.delete(introScreenId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
