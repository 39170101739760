import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import JsFunctionAPI from '@/api/JsFunctionAPI';

export default class JsFunction extends Model {

    public static entity = 'function';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            code: this.string(''),
        };
    }

    public static async getAll() {
        let functions;
        try {
            functions = await JsFunctionAPI.getAll() as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: functions.data,
        });
        return Promise.resolve(functions.data);
    }
    public name!: string;
    public code!: string;
    public id!: string;

}
