
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getLastSelectedTab } from '@/helpers/project/ProjectHelper';
import Project from '@/models/Project';
import ClientRepository from '@/repositories/ClientRepository';
import ContactConfig from '@/models/interfaces/Contact';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import 'vue2-datepicker/index.css';
import ProjectsTableWrapper from '@/components/global/table-wrappers/ProjectsTableWrapper.vue';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import { UserRepository } from '@/repositories/UserRepository';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import User from '@/models/User';

@Component({
    name: 'ProjectsModal',
    components: { ProjectsTableWrapper, TableSearchBar },
})
export default class CreateNewEventPopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private search = {
        isActive: true,
    };
    // private currentUser!: User | null;
    private entityType = '';
    private selectedProject = null;
    private currentUserId = '';
    private isLoaded = false;

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private created() {
        if (
            this.popupData.origin &&
            (this.popupData.origin === 'calendar' || this.popupData.origin === 'rootCalendar')
        ) {
            this.entityType = ProjectTypes.All;
            this.currentUserId = this.popupData.selectedUserId;
        }

        this.isLoaded = true;
    }

    private startTableLoading() {
        const wrapper = this.$refs.projectTableWrapper as any;
        if (wrapper && wrapper.startLoading) {
            wrapper.startLoading();
        }
    }

    private handleSelectedProject($event: any) {
        this.selectedProject = $event;

        if (
            this.popupData.origin &&
            (this.popupData.origin === 'calendar' || this.popupData.origin === 'rootCalendar')
        ) {
            this.onSave();
        }
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private onSave() {
        if (this.popupData && this.popupData.origin === 'rootCalendar') {
            EventBus.$emit('on-save-project', {
                project: this.selectedProject,
                rootCalendar: true,
            });
        } else {
            EventBus.$emit('on-save-project', this.selectedProject);
        }
        EventBus.$emit(EventBusEvents.closePopup);

        if (this.popupData.origin && this.popupData.origin === 'calendar') {
            EventBus.$emit(EventBusEvents.openCreateNewEventPopup, {
                popupEvent: EventBusEvents.openCreateNewEventPopup,
                data: {
                    prefill: false,
                    eventData: { ...this.popupData.originData, project: this.selectedProject },
                },
            });
        }
    }
}
