import { Model } from '@vuex-orm/core';
import Country from '@/models/Country';
import TaxAPI from '@/api/TaxAPI';
import TaxConfig from '@/models/interfaces/Tax';

export default class Tax extends Model {

    public static entity = 'tax';

    public static fields() {
        return {
            id: this.attr(null),
            montage: this.attr(null),
            noMontage: this.attr(null),
            montageNonTax: this.attr(null),
            noMontageNonTax: this.attr(null),
            company: this.attr(null),
            country: this.belongsTo(Country, 'country_id'),
            country_id: this.attr(null),
        };
    }

    public static async getAll() {
        let taxes;
        try {
            taxes = await TaxAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: taxes.data,
        });
        return Promise.resolve();
    }

    public static async getById(taxId: string) {
        let tax;
        try {
            tax = await TaxAPI.getById(taxId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: tax.data,
        });
        return Promise.resolve(tax);
    }

    public static async updateExisting(taxId: string, taxInfo: TaxConfig) {
        let tax;
        try {
            tax = await TaxAPI.update(taxId, taxInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(tax);
    }

    public static async createNew(taxInfo: TaxConfig) {
        let tax;
        try {
            tax = await TaxAPI.create(taxInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(tax);
    }

    public id!: string;
    public montage!: string | null;
    public noMontage!: string | null;
    public montageNonTax!: string | null;
    public noMontageNonTax!: string | null;
    public company!: string | null;
    public country!: Country;

}
