<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9332 13.375L8.43321 2.125C8.33633 1.95781 8.16915 1.875 8.0004 1.875C7.83165 1.875 7.6629 1.95781 7.56758 2.125L1.06758 13.375C0.875396 13.7094 1.11602 14.125 1.5004 14.125H14.5004C14.8848 14.125 15.1254 13.7094 14.9332 13.375ZM7.5004 6.5C7.5004 6.43125 7.55665 6.375 7.6254 6.375H8.3754C8.44415 6.375 8.5004 6.43125 8.5004 6.5V9.375C8.5004 9.44375 8.44415 9.5 8.3754 9.5H7.6254C7.55665 9.5 7.5004 9.44375 7.5004 9.375V6.5ZM8.0004 12C7.80413 11.996 7.61726 11.9152 7.47988 11.775C7.3425 11.6348 7.26555 11.4463 7.26555 11.25C7.26555 11.0537 7.3425 10.8652 7.47988 10.725C7.61726 10.5848 7.80413 10.504 8.0004 10.5C8.19666 10.504 8.38353 10.5848 8.52091 10.725C8.6583 10.8652 8.73524 11.0537 8.73524 11.25C8.73524 11.4463 8.6583 11.6348 8.52091 11.775C8.38353 11.9152 8.19666 11.996 8.0004 12Z" fill="#F5222D"/>
    </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'ExclamationMark',
})
export default class ExclamationMark extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
