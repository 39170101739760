import { Model } from '@vuex-orm/core';
import ChatAPI from '@/api/ChatAPI';
import ChatMessage from '@/models/ChatMessage';
import Project from '@/models/Project';

export default class Chat extends Model {
    public static entity = 'chat';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            created: this.string(''),
            createdBy: this.string(''),
            message_ids: this.attr([]),
            messages: this.hasManyBy(ChatMessage, 'message_ids'),
            project_id: this.attr(null),
            project: this.belongsTo(Project, 'project_id'),
        };
    }

    public static async getAll() {
        let chats;
        try {
            chats = await ChatAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: chats.data,
        });
        return Promise.resolve();
    }

    public static async getById(chatId: string) {
        let chat;
        try {
            chat = await ChatAPI.getById(chatId);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: chat.data,
            insertOrUpdate: [
                'project',
                'chatMessages',
                'cmsUser',
                'plainMessage',
                'nextStep',
                'attachment',
                'lead',
                'offer',
                'client',
            ],
        });
        return Promise.resolve(chat);
    }

    public static async updateExisting(chatId: string, chatInfo: any) {
        let chat;
        try {
            chat = await ChatAPI.update(chatId, chatInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: chat.data,
        });
        return Promise.resolve(chat);
    }

    public static async createNew(chatInfo: any) {
        let chat;
        try {
            chat = await ChatAPI.create(chatInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: chat.data,
            insertOrUpdate: [
                'project',
                'chatMessages',
                'cmsUser',
                'plainMessage',
                'nextStep',
                'attachment',
                'lead',
                'offer',
                'client',
            ],
        });

        await Project.update({
            where: chat.data.project,
            data: {
                chat_id: chat.data.id,
            },
        });
        return Promise.resolve(chat);
    }

    public static async deleteExisting(chatId: string) {
        try {
            await ChatAPI.delete(chatId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Chat.delete(chatId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public created!: string;
    public createdBy!: string;
    public messages!: ChatMessage[];
}
