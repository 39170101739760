import { Model } from '@vuex-orm/core';
import WorkflowAPI from '@/api/WorkflowAPI';
import SystemWorkflowNode from '@/models/SystemWorkflowNode';
import ProjectLabelWorkflowNode from '@/models/ProjectLabelWorkflowNode';
import WorkflowNodeConnection from '@/models/WorkflowNodeConnection';
import { WorkflowRequestResource } from '@/interfaces/components/Workflow/WorkflowRequestResource';

export default class Workflow extends Model {
    public static entity = 'workflow';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            systemNodes_ids: this.attr([]),
            systemNodes: this.hasManyBy(SystemWorkflowNode, 'systemNodes_ids'),
            connections_ids: this.attr([]),
            connections: this.hasManyBy(WorkflowNodeConnection, 'connections_ids'),
            projectLabelNodes_ids: this.attr([]),
            projectLabelNodes: this.hasManyBy(ProjectLabelWorkflowNode, 'projectLabelNodes_ids'),
        };
    }

    public static async getAll() {
        let workflows;
        try {
            workflows = await WorkflowAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: workflows.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelNodes',
                'label',
            ],
        });
        return Promise.resolve();
    }

    public static async getById(workflowId: string) {
        let workflow;
        try {
            workflow = await WorkflowAPI.getById(workflowId);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: workflow.data,
            insertOrUpdate: [],
        });
        return Promise.resolve(workflow);
    }

    public static async updateExisting(workflowRequestResource: WorkflowRequestResource) {
        let workflow;
        try {
            workflow = await WorkflowAPI.update(
                workflowRequestResource.workflowId as string,
                workflowRequestResource.workflowPayload,
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: workflow.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelNodes',
                'label',
            ],
        });
        return Promise.resolve(workflow);
    }

    public static async createNew(workflowRequestResource: WorkflowRequestResource) {
        let workflow;
        try {
            workflow = await WorkflowAPI.create(workflowRequestResource.workflowPayload);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: workflow.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelNodes',
                'label',
            ],
        });
        return Promise.resolve(workflow);
    }

    public static async deleteExisting(workflowId: string) {
        try {
            await WorkflowAPI.delete(workflowId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Workflow.delete(workflowId);
        return Promise.resolve();
    }

    public static async resetToDefault(workflowId: string, deletedNodesIds: any) {
        let workflow;

        // USED ONLY FOR E2E TESTS
        const workflowPayload = {
            connections: [
                {
                    sourcePosition: 'bottom',
                    destinationPosition: 'top',
                    sourceNode: '2',
                    destinationNode: '1',
                },
                {
                    sourcePosition: 'bottom',
                    destinationPosition: 'top',
                    sourceNode: '1',
                    destinationNode: '3',
                },
                {
                    id: 1645094816774,
                    sourcePosition: 'right',
                    destinationPosition: 'left',
                    sourceNode: '4',
                    destinationNode: 'new-3',
                },
            ],
            projectLabelNodes: [
                {id: '1', x: 450, y: 300, projectLabelId: '18'},
                {
                    id: 'new-3',
                    x: 750,
                    y: 510,
                    projectLabelId: '4',
                },
            ],
            systemNodes: [
                {id: '2', x: 450, y: 20, type: 'start', name: 'Start'},
                {
                    id: '3',
                    x: 450,
                    y: 520,
                    type: 'end',
                    name: 'End',
                },
                {
                    id: '4',
                    x: 750,
                    y: 400,
                    type: 'roltek_production',
                    name: 'Roltek Production',
                },
            ],
            projectLabelTemplateNodes: [],
            deletedNodes: deletedNodesIds,
            deletedConnections: [],
            locale: null,
        };
        try {
            workflow = await WorkflowAPI.update(
                workflowId,
                workflowPayload,
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: workflow.data,
            insertOrUpdate: [
                'systemNodes',
                'connections',
                'projectLabelNodes',
                'label',
            ],
        });
        return Promise.resolve(workflow);
    }
    public id!: string;
    public name!: string;
    public projectLabelNodes!: ProjectLabelWorkflowNode[];
    public systemNodes!: SystemWorkflowNode[];
    public connections!: WorkflowNodeConnection[];
}
