
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import ClientRepository from '@/repositories/ClientRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ProjectModel from '@/models/Project';
import { AxiosResponse } from 'axios';
import ProjectRepository from '@/repositories/ProjectRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import CMSUser from '@/models/CMSUser';
import { UserRepository } from '@/repositories/UserRepository';
import Client from '@/models/Client';

@Component({
    name: 'NewProjectModal',
    components: { AutocompleteGroupInput },
})
export default class NewProjectModal extends Vue {
    @Prop({ default: false }) private isNewProjectModalVisible!: boolean;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private isContactPersonShown = false;
    private selectedClientId: string = '';
    private selectedContactPersonId: string = '';
    private localAssignedUser: string = '';
    private localMadeByUser: string = '';
    private localProjectConnection: string = '';
    private defaultClientId: string = '';
    private disabledCreateProjectButton: boolean = false;
    private createWithClient: boolean =
        String(this.$store.getters['jwtData/currentUserGroup']) == '1' ||
        String(this.$store.getters['jwtData/currentUserGroup']) == '2' ||
        String(this.$store.getters['jwtData/currentUserGroup']) == '3';

    private get contactPersons() {
        if (this.selectedClientId == null) {
            return [];
        }

        return ClientRepository.contactPersons(this.selectedClientId);
    }

    private get client() {
        if (!this.selectedClientId) {
            return;
        }

        return ClientRepository.getById(this.selectedClientId);
    }

    private get isProjectClientCreation() {
        return this.$store.getters['generic/projectClientCreation'];
    }

    private get isCalendarPopup() {
        return this.$store.getters['generic/calendarPopup'];
    }

    private get createdClient() {
        return this.$store.getters['generic/createdClient'];
    }

    private get createdContacts() {
        return this.$store.getters['generic/createdContacts'];
    }

    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }

    private get activeUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private onCreateWithClientChange(createWithClient: boolean) {
        this.createWithClient = createWithClient;

        if (!createWithClient) {
            this.selectedClientId = '';
            this.selectedContactPersonId = '';
        }
    }

    private async onClientSelect(clientId: string) {
        this.selectedClientId = clientId;

        this.loadingOverlay.start();

        await Client.getById(this.selectedClientId);
        await this.$nextTick();

        if (this.contactPersons.length) {
            this.selectedContactPersonId = this.contactPersons[0].id;
            this.isContactPersonShown = true;
        }

        if (this.client?.clientAssignedUser) {
            this.localAssignedUser = this.client.clientAssignedUser.id;
        }

        this.loadingOverlay.stop();
    }

    private showNewClientPopup() {
        this.$store.commit('generic/setProjectClientCreation', true);
        EventBus.$emit(EventBusEvents.toggleNewProjectModal, false);
        EventBus.$emit(EventBusEvents.toggleNewClientModal, true);
    }

    private async okClicked() {
        if (this.disabledCreateProjectButton) {
            return;
        }

        this.disabledCreateProjectButton = true;

        try {
            await this.createNewProject();
        } catch (e) {
            this.disabledCreateProjectButton = false;
            throw e;
        }
    }

    private async createNewProject() {
        if (
            !this.localAssignedUser ||
            !this.localMadeByUser ||
            (this.createWithClient && (!this.selectedClientId || !this.selectedContactPersonId))
        ) {
            this.$notification.error({
                message: this.$t('Nedostaju podaci'),
                description: '',
            });
            this.disabledCreateProjectButton = false;
            return;
        }

        this.loadingOverlay.start();
        const response = (await ProjectModel.createNew(this.selectedClientId)) as AxiosResponse;
        const project = ProjectRepository.getProjectById(response.data.id);
        if (!project) {
            this.loadingOverlay.stop();
            throw new Error('There was an error while creation a new project.');
        }
        await ProjectModel.updateExisting({
            projectId: project.id,
            assignedUserId: this.localAssignedUser,
            madeByUserId: this.localMadeByUser,
            connection: this.localProjectConnection,
        });

        if (!this.isCalendarPopup) {
            await this.$router.push({
                name: RouteNames.project,
                params: {
                    id: project.id,
                },
                query: {
                    initialTab: `${ProjectTabValues.Basic}`,
                },
            });
        } else {
            EventBus.$emit(EventBusEvents.setNewProjectInCalendar, response.data.id);
            EventBus.$emit('on-save-project', {
                project,
                rootCalendar: true,
            });
        }

        this.loadingOverlay.stop();
        this.closePopup();
    }

    private closePopup() {
        this.$emit('update:isNewProjectModalVisible', false);
        this.$store.commit('generic/setCalendarPopup', false);
    }

    private setDefaultCommercialist() {
        if (this.createdClient?.assignedUser) {
            this.localAssignedUser = this.createdClient.assignedUser.id;
        } else if (this.currentUser?.isSalesman) {
            this.localAssignedUser = this.currentUser.id;
        }
    }

    private beforeDestroy() {
        this.$store.commit('generic/setProjectClientCreation', false);
    }

    private async mounted() {
        await CMSUser.getAllOnlyUsers();

        if (this.currentUser) {
            this.localMadeByUser = this.currentUser.id;
        }

        if (this.isProjectClientCreation && this.createdClient) {
            this.setDefaultCommercialist();

            this.defaultClientId = this.createdClient.id;
            this.selectedClientId = this.createdClient.id;

            if (this.createdContacts.length) {
                this.selectedContactPersonId = this.createdContacts[0].id;
                this.isContactPersonShown = true;
            }
        } else {
            if (this.currentUser?.isSalesman) {
                this.localAssignedUser = this.currentUser.id;
            }
        }
    }

    @Watch('localAssignedUser', { immediate: true })
    private validateAssignedUser(assignedUser: any) {
        if (typeof assignedUser !== 'string') {
            this.localAssignedUser = String(assignedUser);
        }
    }
}
