import { Model } from '@vuex-orm/core';
import ProductFunctionsAPI from '@/api/ProductFunctionsAPI';

export default class ProductFunctions extends Model {
    public static entity = 'productFunctions';

    public static fields() {
        return {
            name: this.string(''),
            hasError: this.boolean(false),
            updatedAt: this.string(''),
        };
    }

    public static async getAll() {
        let allProductFunctions;

        try {
            allProductFunctions = await ProductFunctionsAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({ data: allProductFunctions.data });

        return Promise.resolve();
    }

    public name!: string;
    public hasError!: boolean;
    public updatedAt!: string;
}
