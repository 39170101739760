import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import PriceListRegionAPI from '@/api/PriceListRegionAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class PriceListRegion extends Model {
    public static entity = 'priceListRegion';

    public get getName() {
        // @ts-ignore
        return this.name;
    }

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            ingeniousId: this.string(''),
        };
    }

    public static async getAll() {
        let priceListRegion;

        if (ss.has('priceListRegion')) {
            priceListRegion = ss.getAndParse('priceListRegion');
        } else {
            try {
                const fetchedPriceListRegion = (await PriceListRegionAPI.getAll()) as AxiosResponse;
                priceListRegion = fetchedPriceListRegion.data;

                ss.stringifyAndSave('priceListRegion', priceListRegion);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: priceListRegion,
        });

        return Promise.resolve(priceListRegion);
    }
}
