import { AxiosError, AxiosResponse } from 'axios';
import { Model } from '@vuex-orm/core';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import UserAPI from '@/api/UserAPI';
import Token from '@/models/Token';
import Locale from '@/models/Locale';
import Currency from '@/models/Currency';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import UserGroupConnection from '@/models/UserGroupConnection';
import { UserRepository } from '@/repositories/UserRepository';
import i18n from '@/i18n';
import moment from 'moment';
import { JWTDataKeys } from '@/enums/global/JWTDataKeys';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class User extends Model {
    public static entity = 'user';

    public static fields() {
        return {
            id: this.attr(null),
            email: this.string(''),
            roles: this.attr([]),
            name: this.attr(''),
            phoneNumber: this.attr(''),
            locale_id: this.attr(null),
            locale: this.belongsTo(Locale, 'locale_id'),
            isSalesman: this.boolean(false),
            currency_id: this.attr(null),
            currency: this.belongsTo(Currency, 'currency_id'),
            userGroupConnections: this.hasManyBy(UserGroupConnection, 'userGroupConnection_ids'),
            userGroupConnection_ids: this.attr([]),
        };
    }

    public static async login(data: { username: string; password: string }): Promise<AxiosResponse | AxiosError> {
        let loginResponse;
        try {
            loginResponse = await UserAPI.login(data);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.setToken(loginResponse);
        return Promise.resolve(loginResponse);
    }

    public static async setToken(loginResponse: any) {
        const payload = loginResponse.data.access_token.split('.')[1];
        const storeInstance = this.store();
        const jwtObject = JSON.parse(atob(payload));

        LocalStorageService.save(LocalStorageKeyNames.token, loginResponse.data.access_token);
        await storeInstance.dispatch(JWTDataKeys.UPDATE_JWT_DATA, jwtObject);

        const defaultUserGroup = LocalStorageService.has(LocalStorageKeyNames.userGroup)
            ? LocalStorageService.get(LocalStorageKeyNames.userGroup)
            : jwtObject.userGroups[0];

        if (storeInstance.getters[JWTDataKeys.CURRENT_USER_GROUP] == null) {
            await storeInstance.dispatch(JWTDataKeys.UPDATE_USER_GROUP, defaultUserGroup);
        }

        await Token.setToken(loginResponse.data);
    }

    public static async runInitialActionsAfterRouteChange(shouldUpdateLocale = true, locale?: string, userId?: string) {
        let currentUser = UserRepository.getCurrentUser();

        if (userId == null && currentUser == null) {
            return Promise.reject(new Error('Current user could not be fetched'));
        }
        const userIdWithFallback = userId ? userId : (currentUser as User).id;
        const localeWithFallback = locale ? locale : (currentUser as User).locale.code;

        await User.get(userIdWithFallback);
        currentUser = UserRepository.getCurrentUser();

        this.store().dispatch(JWTDataKeys.UPDATE_USER_RIGHTS, currentUser);

        if (shouldUpdateLocale) {
            i18n.locale = localeWithFallback;
            moment.locale(i18n.locale);
        }

        return Promise.resolve();
    }

    public static async fetchAndSetToken() {
        let tokenRefreshed;

        try {
            tokenRefreshed = (await Token.refreshToken()) as AxiosResponse;
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject();
        }

        await User.setToken(tokenRefreshed);
        return Promise.resolve();
    }

    public static async get(userId: string) {
        let user;

        try {
            user = await UserAPI.get(userId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: user.data,
            insertOrUpdate: ['locale', 'currency'],
        });

        return Promise.resolve(user);
    }

    public static async updateCurrent(userData: any) {
        let user;
        try {
            user = await UserAPI.updateExisting(userData);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: user.data,
            insertOrUpdate: ['locale', 'currency'],
        });

        return Promise.resolve(user);
    }

    public static async resetPassword(password: string, passwordRepeat: string, token: string) {
        try {
            await UserAPI.resetPassword(password, passwordRepeat, token);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async requestPassword(email: string) {
        try {
            await UserAPI.requestPassword(email);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async logout() {
        const storeInstance = this.store();

        await this.deleteAll();
        LocalStorageService.remove(LocalStorageKeyNames.token);
        LocalStorageService.remove(LocalStorageKeyNames.userGroup);
        // Clear cache storage
        sessionStorage.clear();
        await storeInstance.dispatch(JWTDataKeys.UPDATE_USER_GROUP, null);
        return Promise.resolve();
    }

    public static async resetUserRights() {
        // ONLY USED FOR TESTING
        await UserGroupConnection.updateExisting('1', {
            $id: '1',
            id: '1',
            userRights: [
                'VIEW_LEAD_ASSIGN',
                'DELETE_PROJECTS',
                'VIEW_PURCHASE_PRICE_MANIPULATIONS',
                'VIEW_PURCHASE_PRICE',
                'EDIT_PURCHASE_PRICE_MANIPULATIONS',
                'VIEW_PAYMENT_RECORDS',
                'EDIT_PAYMENT_RECORDS',
                'SEND_ORDER',
                'ALLOW_OFFER_ERRORS',
                'B2B_CONTROL_PANEL',
                'VIEW_ALL_NEXT_STEPS',
                'VIEW_CHAT',
                'CREATE_CLIENT',
                'EDIT_CLIENT',
                'DELETE_CLIENT',
                'EDIT_CLIENT_DETAILED',
                'VIEW_USER',
                'CREATE_USER',
                'EDIT_USER',
                'EDIT_USER_RIGHTS',
                'CMS_TRANSLATIONS',
                'CMS_CONTENT',
                'CMS_INFO_BUTTONS',
                'CMS_STATUSES',
                'CMS_CAMPAIGNS',
                'CMS_CAMPAIGN_TYPES',
                'CMS_NEXT_STEP_TYPES',
                'CMS_CLIENT_TYPES',
                'CMS_POST_CODE_CONNECTIONS',
                'CMS_DOCUMENT_NAMES',
                'CMS_OFFER_CONFIGURATIONS',
                'CMS_TAXES',
                'CMS_LEAD_TYPES',
                'CMS_LEAD_ORIGINS',
                'CMS_ORDER_STATUSES',
                'CMS_CATALOG',
                'CMS_CURRENCIES',
                'CMS_EMAIL_TEMPLATE_PROPERTIES',
                'CMS_PRODUCT_GROUPS',
            ],
            // @ts-ignore
            user: {
                // @ts-ignore
                $id: this.id,
                // @ts-ignore
                id: this.id,
            },
            // @ts-ignore
            userGroup: {
                $id: '1',
                id: '1',
            },
            // @ts-ignore
            user_id: this.id,
            userGroup_id: '1',
            key: '0',
        });

        await UserGroupConnection.updateExisting('4', {
            $id: '4',
            id: '4',
            userRights: [],
            // @ts-ignore
            user: {
                // @ts-ignore
                $id: this.id,
                // @ts-ignore
                id: this.id,
            },
            // @ts-ignore
            userGroup: {
                $id: '2',
                id: '2',
            },
            // @ts-ignore
            user_id: this.id,
            userGroup_id: '2',
            key: '1',
        });
    }
    public email!: string;
    public roles!: string[];
    public locale!: Locale;
    public id!: string;
    public name!: string;
    public isSalesman!: boolean;
    public currency!: Currency;
    public userGroupConnections!: UserGroupConnection[];
}
