import ProductGroup from '@/models/ProductGroup';

export default class ProductGroupRepository {
    public static getAll() {
        return ProductGroup.query().withAll().get();
    }

    public static getById(id: string) {
        return ProductGroup.query().whereId(id).withAll().first();
    }
}
