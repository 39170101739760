import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class NewsAttachmentAPI {
    public static async createNew(formData: FormData) {
        const apiRoute = ApiRoutes.newsAttachment.create;
        let image;

        try {
            image = (await ApiController.post(apiRoute, formData)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(image);
    }

    public static async getBlob(resourceUrl: string) {
        return (await ApiController.get(resourceUrl, null, true)) as AxiosResponse;
    }

    public static async getAttachmentsByNewsId(newsId: string) {
        let attachments;

        try {
            attachments = (await ApiController.get(
                ApiRoutes.newsAttachment.getAttachmentsByNewsId(newsId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(attachments);
    }

    public static async delete(attachmentId: string) {
        try {
            await ApiController.delete(ApiRoutes.newsAttachment.delete(attachmentId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
