
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'ChecklistBoolFieldModule',
})
export default class ChecklistBoolFieldModule extends Vue {
    @Prop({required: true}) private pId!: string;
    @Prop({default: false}) private isDisabled!: boolean;
    @Prop() private activeProductFormId!: number | null;
    @Prop({default: null}) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Getter('configurator/activeProductFormValue') private productFormValue!:
        (pId: string, productFormId: number) => boolean | null;

    private value = false;

    private get fieldValue() {
        if (this.activeProductFormId == null) {
            return false;
        }

        return this.productFormValue(this.pId, this.activeProductFormId);
    }

    private onChange(checked: boolean) {
        if (this.activeProductFormId == null) {
            this.value = checked;
        }

        EventBus.$emit(EventBusEvents.changesInDataMade, {state: true});

        this.$emit(EventBusEvents.updateStoreFieldValue, {
            pId: this.pId,
            value: checked,
            productFormId: this.activeProductFormId,
        });
    }

    private onBlur() {
        this.debounceToggleIsActive && this.debounceToggleIsActive(false);
    }

    private onFocus() {
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    @Watch(`fieldValue`, {immediate: true})
    private onFieldValueChange(newValue: boolean | null) {
        if (newValue == null) {
            this.value = false;
            return;
        }
        this.value = newValue;
    }
}
