import { Model } from '@vuex-orm/core';
import ClientAPI from '@/api/ClientAPI';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import Project from '@/models/Project';
import Country from '@/models/Country';
import PriceListRegion from '@/models/PriceListRegion';
import PriceType from '@/models/PriceType';
import PaymentType from '@/models/PaymentType';
import ClientType from '@/models/ClientType';
import Address from '@/models/Address';
import Currency from '@/models/Currency';
import ContactPerson from '@/models/ContactPerson';
import { formatDate } from '@/helpers/CmsIndexHelper';
import CMSUser from '@/models/CMSUser';
import Discount from '@/models/Discount';
import ClientRepository from '@/repositories/ClientRepository';
import Tag from '@/interfaces/general/Tag';
import LeadOrigin from '@/models/LeadOrigin';

export default class Client extends Model {
    public get clientId() {
        // @ts-ignore
        return this.id;
    }

    public get clientName() {
        // @ts-ignore
        return this.name;
    }

    public get company() {
        // @ts-ignore
        return this.isCompany;
    }

    public get clientFax() {
        // @ts-ignore
        return this.fax;
    }

    public get clientPhoneNumber() {
        // @ts-ignore
        return this.phone;
    }

    public get clientAdditionalNotes() {
        // @ts-ignore
        return this.additionalNotes;
    }

    public get clientEmail() {
        // @ts-ignore
        return this.email;
    }

    public get clientAssignedUser() {
        // @ts-ignore
        return this.assignedUser;
    }

    public get clientPriceListRegion() {
        // @ts-ignore
        return this.priceListRegion;
    }
    public get clientPriceType() {
        // @ts-ignore
        return this.priceType;
    }

    public get isClientTaxPayer() {
        if (this.isTaxPayer === null) {
            return i18n.t('Informacija nije dostupna');
        }
        return this.isTaxPayer ? i18n.t('Da') : i18n.t('Ne');
    }

    public static entity = 'client';

    public static fields() {
        return {
            id: this.attr(null),
            oid: this.attr(''),
            name: this.attr(''),
            matchcode: this.attr(''),
            visibleId: this.attr(''),
            taxId: this.attr(''),
            registerId: this.attr(''),
            accountingProgram: this.attr(''),
            additionalNotes: this.attr(''),
            fax: this.attr(''),
            bankCode: this.attr(''),
            isTaxPayer: this.attr(null),
            createdAt: this.attr(''),
            updatedAt: this.attr(''),
            project_ids: this.attr([]),
            contactPerson_ids: this.attr([]),
            assignedUser_id: this.attr(''),
            country_id: this.attr(''),
            priceListRegion_id: this.attr(''),
            priceType_id: this.attr(''),
            paymentType_id: this.attr(''),
            leadOrigin_id: this.attr(''),
            clientType_ids: this.attr([]),
            isCompany: this.attr(false),
            discount_ids: this.attr([]),
            address_id: this.attr(''),
            deliveryAddress_id: this.attr(''),
            invoiceAddress_id: this.attr(''),
            currency_id: this.attr(''),
            tags: this.attr([]),
            clientLogs: this.attr([]),
            contactPersons: this.hasManyBy(ContactPerson, 'contactPerson_ids'),
            projects: this.hasManyBy(Project, 'project_ids'),
            assignedUser: this.belongsTo(CMSUser, 'assignedUser_id'),
            country: this.belongsTo(Country, 'country_id'),
            priceListRegion: this.belongsTo(PriceListRegion, 'priceListRegion_id'),
            priceType: this.belongsTo(PriceType, 'priceType_id'),
            paymentType: this.belongsTo(PaymentType, 'paymentType_id'),
            clientTypes: this.hasManyBy(ClientType, 'clientType_ids'),
            discounts: this.hasManyBy(Discount, 'discount_ids'),
            address: this.belongsTo(Address, 'address_id'),
            deliveryAddress: this.belongsTo(Address, 'deliveryAddress_id'),
            invoiceAddress: this.belongsTo(Address, 'invoiceAddress_id'),
            currency: this.belongsTo(Currency, 'currency_id'),
            isSearchResult: this.attr(false),
            roltekAppPartner: this.attr(false),
            squareLogo: this.attr(null),
            bigLogo: this.attr(null),
            webpage: this.attr(null),
            backofficeUser: this.attr(null),
            origin: this.belongsTo(LeadOrigin, 'leadOrigin_id'),
        };
    }

    public static async getAll() {
        let clients;
        try {
            clients = await ClientAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: clients.data,
            insertOrUpdate: ['address', 'priceType', 'priceListRegion', 'clientType', 'country', 'cmsUser', 'currency'],
        });
        return Promise.resolve();
    }

    public static async getById(clientId: string) {
        let client;
        try {
            client = await ClientAPI.getById(clientId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Discount.deleteAll();

        this.insertOrUpdate({
            data: client.data,
            insertOrUpdate: [
                'priceListRegion',
                'priceType',
                'paymentType',
                'cmsUser',
                'clientType',
                'isCompany',
                'address',
                'project',
                'contactPersons.address',
                'currency',
                'backofficeUser',
                'leadOrigin',
            ],
        });
        return Promise.resolve(client);
    }

    public static async getByIdForConfigurator(clientId: string) {
        let client;
        try {
            client = await ClientAPI.getByIdForConfigurator(clientId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: client.data,
            insertOrUpdate: ['priceListRegion', 'priceType', 'paymentType'],
        });

        return Promise.resolve(client);
    }

    public static async getSingleForClientScreen(clientId: string) {
        let client;
        try {
            client = await ClientAPI.getSingleForClientScreen(clientId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: client.data,
            insertOrUpdate: ['cmsUser', 'clientType', 'isCompany', 'address', 'project'],
        });

        return Promise.resolve(client);
    }

    public static async deleteExisting(clientId: string) {
        try {
            await ClientAPI.delete(clientId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.delete(clientId);

        return Promise.resolve();
    }

    public static async updateExisting(clientId: string, clientInfo: AvailableClientFields) {
        let client;
        try {
            client = await ClientAPI.updateExisting(clientId, clientInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: client.data,
            insertOrUpdate: ['priceListRegion', 'priceType', 'paymentType'],
        });
        return Promise.resolve();
    }

    public static async createNew(clientInfo: AvailableClientFields) {
        let client;
        try {
            client = await ClientAPI.createNew(clientInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: client.data,
            insertOrUpdate: ['paymentType'],
        });
        return Promise.resolve(client);
    }

    public static async search(query: string) {
        let clients;
        const encodedQuery = encodeURIComponent(`%${query}%`);
        const routeQuery = `&q=(name:${encodedQuery}|address.streetNameAndNumber:${encodedQuery}|address.city:${encodedQuery}|address.poNumber:${encodedQuery}|contactPersons.phone:${encodedQuery})`;

        try {
            clients = (await ClientAPI.search(routeQuery)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: clients.data,
            insertOrUpdate: ['image', 'country', 'paymentType'],
        });
        return Promise.resolve(clients.data);
    }

    public static async removeAllCurrentSearchResults() {
        const selectedOffers = ClientRepository.getAllSearchResults();

        return Promise.all(
            selectedOffers.map(async (client) => {
                await Client.update({
                    data: {
                        id: client.id,
                        isSearchResult: false,
                    },
                });

                return Promise.resolve();
            })
        );
    }

    public static async searchNew(query: string) {
        let clients;
        const encodedQuery = encodeURI(`%${query}%`);
        const routeQuery = `&q=(name:${encodedQuery}|address.streetNameAndNumber:${encodedQuery}|address.city:${encodedQuery}|address.poNumber:${encodedQuery}|contactPersons.phone:${encodedQuery})`;

        try {
            clients = (await ClientAPI.searchNew(routeQuery)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        await this.removeAllCurrentSearchResults();

        await this.insertOrUpdate({
            data: clients.data.map((clientData: any) => {
                return {
                    ...clientData,
                    isSearchResult: true,
                };
            }),
            insertOrUpdate: ['address', 'country'],
        });

        return Promise.resolve(clients.data);
    }

    public static async validateEmail(input: string) {
        let clients;
        try {
            clients = (await ClientAPI.validateEmail(input)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clients);
    }

    public static async validateClient(
        inputEmail: string,
        inputPhone: string,
        inputName: string,
        inputAddress: string,
        inputTaxId: string
    ) {
        let clients;
        try {
            clients = await ClientAPI.validateClient(inputEmail, inputPhone, inputName, inputAddress, inputTaxId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async updatePaymentType(clientId: string, clientInfo: AvailableClientFields) {
        let client;
        try {
            client = await ClientAPI.updatePaymentType(clientId, clientInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: client.data,
        });
        return Promise.resolve();
    }

    public static async fetchByPaymentType(paymentTypeId: string) {
        let clients;
        try {
            clients = await ClientAPI.fetchByPaymentType(paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clients.data);
    }

    public static async filterClients(query: string) {
        let clients;
        try {
            clients = await ClientAPI.filterClients(query);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: clients.data,
            insertOrUpdate: [
                'address',
                'priceType',
                'priceListRegion',
                'clientType',
                'country',
                'paymentType',
                'cmsUser',
                'currency',
            ],
        });

        await this.store().dispatch('tableData/updateClients', {
            sortedClientIds: clients.data.map((client: Client) => client.id),
            clients: ClientRepository.getAll(),
        });

        return Promise.resolve();
    }

    public static async sendToIngenious(clientId: string) {
        try {
            await ClientAPI.sendToIngenious(clientId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }
    public id!: string;
    public visibleId!: string;
    public address!: Address | null;
    public deliveryAddress!: Address | null;
    public invoiceAddress!: Address | null;
    public updatedAt!: string;
    public name!: string;
    public currency!: Currency;
    public clientTypes!: ClientType[];
    public contactPersons!: ContactPerson[];
    public paymentType!: PaymentType;
    public isTaxPayer!: boolean | null;
    public discounts!: Discount[];
    public priceType!: PriceType;
    public isSearchResult!: boolean;
    public tags!: Tag[];
    public isCompany!: boolean;
    public clientType_ids!: string[];
    public roltekAppPartner!: boolean;
    public squareLogo!: string;
    public bigLogo!: string;
    public webpage!: string;
    public backofficeUser!: string;
    public leadOrigin!: string;

    public setTag(tag: Tag): void {
        const index = this.tags.findIndex((item: Tag) => item.name === tag.name);

        if (index !== -1) {
            this.tags.splice(index, 1);
        }

        this.tags.push(tag);
    }
}
