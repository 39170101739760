import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import { CommonDiscountGroupNames } from '@/enums/components/Discount/CommonDiscountGroupNames';

export default class DiscountAPI {
    public static async getAll() {
        let discounts;

        try {
            discounts = await ApiController.get(ApiRoutes.discount.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(discounts);
    }

    public static async getById(discountId: string) {
        let discount;

        try {
            discount = await
                ApiController.get(ApiRoutes.discount.getById(discountId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(discount);
    }

    public static async update(discountId: string, discountInfo: AvailableLabelFields) {
        let discount;

        const request = {
            data: {
                type: 'discounts',
                id: discountId,
                attributes: {
                    discountedEntityOid: discountInfo.discountCategory === CommonDiscountGroupNames.PRODUCT
                        ? discountInfo.discountedEntityOid : null,
                    discountType: discountInfo.discountCategory,
                    price1: [discountInfo.pc1, 0, 0],
                    price2: [discountInfo.pc2, 0, 0],
                    isPercentage: discountInfo.isPercentage,
                },
                relationships: {
                    productGroup: {
                        data: null,
                    },
                },
            },
        };

        if (discountInfo.discountCategory === CommonDiscountGroupNames.PRODUCT_GROUP) {
            request.data.relationships.productGroup = {
                // @ts-ignore
                data: {
                    type: 'product_groups',
                    id: discountInfo.productGroup,
                },
            };
        }

        try {
            discount =
                await ApiController.patch(
                    ApiRoutes.discount.update(discountId),
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(discount);
    }

    public static async create(discountInfo: AvailableLabelFields) {
        let discount;

        const request = {
            data: {
                type: 'discounts',
                attributes: {
                    oid: '',
                    discountedEntityOid: discountInfo.discountCategory === CommonDiscountGroupNames.PRODUCT ?
                        discountInfo.discountedEntityOid : null,
                    discountType: discountInfo.discountCategory,
                    price1: [discountInfo.pc1, 0, 0],
                    price2: [discountInfo.pc2, 0, 0],
                    priceThresholds: [0, 0, 0],
                    isPercentage: discountInfo.isPercentage,
                },
                relationships: {
                    productGroup: {
                        data: null,
                    },
                    client: {
                        data: {
                            type: 'clients',
                            id: discountInfo.clientId,
                        },
                    },
                },
            },
        };

        if (discountInfo.discountCategory === CommonDiscountGroupNames.PRODUCT_GROUP) {
            request.data.relationships.productGroup = {
                // @ts-ignore
                data: {
                    type: 'product_groups',
                    id: discountInfo.productGroup,
                },
            };
        }

        try {
            discount =
                await ApiController.post(
                    ApiRoutes.discount.create,
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(discount);
    }

    public static async delete(discountId: string) {

        try {
            await ApiController.delete(ApiRoutes.discount.delete(discountId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
