import Currency from '@/models/Currency';

export default class CurrencyRepository {
    public static getAll() {
        return Currency.all();
    }

    public static getById(id: string) {
        return Currency.query().whereId(id).withAll().first();
    }

    public static getBySymbol(symbol: string) {
        return Currency.query().where('symbol', symbol).withAll().first();
    }
}
