
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import downloadFile from 'downloadjs';
import { Upload } from 'ant-design-vue';
import ClientAttachmentRepository from '@/repositories/ClientAttachmentRepository';
import ClientAttachment from '@/models/ClientAttachment';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import { customHandlerForFileUpload } from '@/helpers/ClientAttachment/ClientAttachmentHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ClientLogoAttachment',
    components: { UploadDragger: Upload.Dragger },
})
export default class ClientLogoAttachment extends Vue {
    @Prop({ default: null }) private attachmentId!: string;
    @Prop({ default: null }) private clientId!: string;
    private loading: string | null = null;
    private showDropzone = false;
    private attachment: ClientAttachment | null = null;
    private blob: any = null;
    // hidding of the dropzone is delayed, to prevent flickering effect when draging over different child components
    private toggleDropzineTimer: NodeJS.Timeout | undefined;

    private async setAttachments() {
        await this.initializeClientAttachment();

        this.setBlob();
    }

    private async setBlob() {
        if (!this.attachment) {
            return;
        }

        const blob = await ClientAttachment.getBlob(this.attachment?.attachment as string);

        this.blob = URL.createObjectURL(blob.data);
    }

    private toggleDropzone(show: boolean) {
        if (this.attachment) {
            return;
        }

        if (show) {
            this.showDropzone = true;
            if (this.toggleDropzineTimer) {
                clearTimeout(this.toggleDropzineTimer);
            }
        } else {
            this.toggleDropzineTimer = setTimeout(() => {
                this.showDropzone = false;
            }, 500);
        }
    }

    private async initializeClientAttachment() {
        await this.$nextTick();

        if (!this.attachmentId) {
            return;
        }

        let response;

        try {
            response = await ClientAttachment.getById(this.attachmentId);
        } catch {
            this.$notification.error({
                message: this.$t('Error fetching logos') as string,
                description: '',
            });
            return;
        }

        this.attachment = response.data;
    }

    private manualUpload(file: File) {
        this.uploadAttachment(file);
    }

    private async uploadAttachment(file: File) {
        this.attachment;

        await this.customRequest({
            file,
            onSuccess: (formData: FormData) => {
                this.$emit('updateAttachment', formData.get('attachmentId'));
                return;
            },
            onError: () => {
                return;
            },
        });

        return false;
    }

    private async customRequest(customRequestParameters: CustomRequestParameters) {
        await customHandlerForFileUpload(customRequestParameters, String(this.clientId));

        await this.setAttachments();
    }

    private startLoading(clientAttachmentId: string) {
        this.loading = clientAttachmentId;
    }

    private endLoading() {
        this.loading = null;
    }

    private onOpenRemoveModal(clientAttachmentForDelete: ClientAttachment) {
        return new Promise((resolve) => {
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
                content: '',
                okText: this.$t('Da') as string,
                okType: 'danger',
                cancelText: this.$t('Ne') as string,
                class: 'c-delete-modal',
                onOk: () => {
                    this.onRemove(clientAttachmentForDelete);
                    resolve(null);
                },
                onCancel: () => {
                    resolve(null);
                },
            });
        });
    }

    private async onRemove(clientAttachmentForDelete: ClientAttachment) {
        this.startLoading(clientAttachmentForDelete.id);

        if (clientAttachmentForDelete.id) {
            await ClientAttachment.deleteExisting(clientAttachmentForDelete.id);
        }
        this.attachment = null;
        this.blob = null;
        this.$emit('updateAttachment', null);
        this.endLoading();
    }

    private async onPreview(clientAttachment: ClientAttachment) {
        let blob;
        this.startLoading(clientAttachment.id);
        try {
            blob = await ClientAttachment.getBlob(clientAttachment.attachment as string);
        } catch (e) {
            let error;
            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }

        downloadFile(blob.data, clientAttachment.displayName);
        this.endLoading();
    }

    public async mounted() {
        this.setAttachments();

        EventBus.$on(EventBusEvents.initializeClientAttachments, this.initializeClientAttachment);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.initializeClientAttachments, this.initializeClientAttachment);
    }
}
