
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';

@Component({
    name: 'PriceListOutOfRangeSlot',
})

export default class PriceListOutOfRangeSlot extends Vue {
    @Prop({required: true}) private popupData!: any;

    private onClose() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private openContactPopup() {
        const popupData: PopupEventData = {
            popupEvent: PopupEvents.contact,
            data: {
                source: 'pricelist-out-of-range',
            },
        };

        EventBus.$emit(EventBusEvents.openContactPopup, popupData);

        this.onClose();
    }
}
