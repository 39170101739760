import { Model } from '@vuex-orm/core';
import IntroScreenItemAPI from '@/api/IntroScreenItemAPI';
import IntroScreenItemRepository from '@/repositories/IntroScreenItemRepository';
import Label from '@/models/Label';
import OrderStatus from '@/models/OrderStatus';
import IntroScreen from '@/models/IntroScreen';

export default class IntroScreenItem extends Model {

    public static entity = 'IntroScreenItem';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            color: this.attr(null),
            filteredProjects: this.attr(null),
            state: this.attr(null),
            rowNumber: this.attr(null),
            projectLabel_id: this.attr(null),
            projectLabel: this.belongsTo(Label, 'projectLabel_id'),
            orderStatus_id: this.attr(null),
            orderStatus: this.belongsTo(OrderStatus, 'orderStatus_id'),
            introScreen_id: this.attr(null),
            introScreen: this.belongsTo(IntroScreen, 'introScreen_id'),
        };
    }

    public static async getAll() {
        let introScreenItems;
        try {
            introScreenItems = await IntroScreenItemAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: introScreenItems.data,
        });
        return Promise.resolve();
    }

    public static async getById(introScreenItemId: string) {
        let introScreenItem;
        try {
            introScreenItem = await IntroScreenItemAPI.getById(introScreenItemId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: introScreenItem.data,
        });
        return Promise.resolve(introScreenItem);
    }

    public static async updateExisting(introScreenItemId: string, introScreenItemInfo: any) {
        let introScreenItem;
        try {
            introScreenItem = await IntroScreenItemAPI.update(introScreenItemId, introScreenItemInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: introScreenItem.data,
        });
        return Promise.resolve(introScreenItem);
    }

    public static async createNew(introScreenItemInfo: any) {
        let introScreenItem;
        try {
            introScreenItem = await IntroScreenItemAPI.create(introScreenItemInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insert({
            data: introScreenItem.data,
        });
        return Promise.resolve(introScreenItem);
    }

    public static async deleteExisting(introScreenItemId: string) {
        try {
            await IntroScreenItemAPI.delete(introScreenItemId);
        } catch (e) {
            return Promise.reject(e);
        }
        await IntroScreenItemRepository.delete(introScreenItemId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public color!: string;
    public filteredProjects!: string;
    public state!: string;
    public rowNumber!: number;
    public projectLabel!: Label;
    public orderStatus!: OrderStatus;
    public introScreen!: IntroScreen;

}
