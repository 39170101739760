import { Model } from '@vuex-orm/core';
import CMSUser from '@/models/CMSUser';
import UserGroup from '@/models/UserGroup';
import UserGroupConnectionConfig from '@/models/interfaces/UserGroupConnection';
import UserGroupConnectionAPI from '@/api/UserGroupConnectionAPI';
import { DynamicObject } from '@/interfaces/general/DynamicObject';

export default class UserGroupConnection extends Model {

    public static entity = 'userGroupConnection';

    public static fields() {
        return {
            id: this.attr(null),
            userRights: this.attr([]),
            user: this.belongsTo(CMSUser, 'user_id'),
            user_id: this.attr(null),
            userGroup: this.belongsTo(UserGroup, 'userGroup_id'),
            userGroup_id: this.attr(null),
        };
    }

    public static async getAll() {
        let userGroupConnections;
        try {
            userGroupConnections = await UserGroupConnectionAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: userGroupConnections.data,
        });
        return Promise.resolve();
    }

    public static async getById(userGroupConnectionId: string) {
        let userGroupConnection;
        try {
            userGroupConnection = await UserGroupConnectionAPI.getById(userGroupConnectionId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: userGroupConnection.data,
        });
        return Promise.resolve(userGroupConnection);
    }

    public static async updateExisting(userGroupConnectionId: string,
                                       userGroupConnectionInfo: UserGroupConnectionConfig) {
        let userGroupConnection;
        try {
            userGroupConnection = await UserGroupConnectionAPI.update(userGroupConnectionId, userGroupConnectionInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(userGroupConnection);
    }

    public static async updateUserRights(
        userGroupConnectionId: string,
        userRights: DynamicObject) {
        try {
            await UserGroupConnectionAPI.updateUserRights(userGroupConnectionId, userRights);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }

    public static async createNew(userGroupConnectionInfo: UserGroupConnectionConfig) {
        let userGroupConnection;
        try {
            userGroupConnection = await UserGroupConnectionAPI.create(userGroupConnectionInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(userGroupConnection);
    }

    public static async deleteExisting(userGroupConnectionId: string) {
        try {
            await UserGroupConnectionAPI.delete(userGroupConnectionId);
        } catch (e) {
            return Promise.reject(e);
        }
        await UserGroupConnection.delete(userGroupConnectionId);
        return Promise.resolve();
    }
    public id!: string;
    public userRights!: string[];
    public userGroup!: UserGroup;
    public user!: CMSUser;
    public key!: string;

}
