import Offer from '@/models/Offer';
import store from '@/store';
import i18n from '@/i18n';
import { notification } from 'ant-design-vue';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import { determineFieldVisibility } from '@/helpers/FieldFunctionHelper';
import ChecklistField from '@/models/ChecklistField';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import OfferTitle from '@/models/OfferTitle';
import Client from '@/models/Client';
import { AxiosResponse } from 'axios';
import Router from '@/router';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
// @ts-ignore
import downloadFile from 'downloadjs';
import { checkOfferItemsAndOpenRecalculationPopup, validateOffer } from '@/helpers/project/ProductFormVersionHelper';
import OfferRepository from '@/repositories/OfferRepository';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { SendPdfOptions } from '@/interfaces/components/offerHistory/SendPdfOptions';
import Pdf from '@/models/Pdf';
import CMSUser from '@/models/CMSUser';
import { GenerateOfferPDFParameters } from '@/interfaces/components/offerHistory/GenerateOfferPDFParameters';
import { ConfigurationOverride } from '@/interfaces/general/ConfigurationOverride';
import ProjectRepository from '@/repositories/ProjectRepository';
import { InstallationAndDeliveryFormFields } from '@/enums/components/ProjectNew/InstallationAndDeliveryFormFields';
import Montage from '@/models/Montage';
import { MontageSubmitOptions } from '@/interfaces/components/projectNew/MontageSubmitOptions';
import { MontageDetailsOptions } from '@/interfaces/components/projectNew/MontageDetailsOptions';
import { OrderNotesSubmitOptions } from '@/interfaces/components/projectNew/OrderNotesSubmitOptions';
import { OrderNotesDetailsOptions } from '@/interfaces/components/projectNew/OrderNotesDetailsOptions';
import { OrderNotesFormFields } from '@/enums/components/ProjectNew/OrderNotesFormFields';
import { PaymentDetailsFormFields } from '@/enums/components/ProjectNew/PaymentDetailsFormFields';
import Project from '@/models/Project';
import BankAccount from '@/models/BankAccount';
import PaymentType from '@/models/PaymentType';
import { PaymentDetailsSubmitOptions } from '@/interfaces/components/projectNew/PaymentDetailsSubmitOptions';
import { PaymentDetailsOptions } from '@/interfaces/components/projectNew/PaymentDetailsOptions';
import moment from 'moment';
import UserGroup from '@/models/UserGroup';
import { AxiosError } from 'axios';
import OfferItem from '@/models/OfferItem';

/**
 * Sends the selected offer to backend for its XML to be used
 * @param selectedOffer - The  offer that was selected in the offer list dropdown
 */
export async function sendOfferXML(selectedOffer: Offer | null) {
    await store.dispatch('projectLoadingState/updateAreOffersLoading', true);

    try {
        checkIfSelectedOfferExists(selectedOffer);
        await Offer.send((selectedOffer as Offer).offerId);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        await store.dispatch('projectLoadingState/updateAreOffersLoading', false);
        return Promise.reject();
    }

    notification.success({
        message: `${i18n.t('XML ponude')} ${(selectedOffer as Offer).offerName} `,
        description: i18n.t('je uspješno poslan u Roltek') as string,
        class: 'send-offer-xml',
    });

    await store.dispatch('projectLoadingState/updateAreOffersLoading', false);
    return Promise.resolve();
}

/**
 * Verified that the selected offer is not null or undefined
 * @param selectedOffer - The  offer that was selected in the offer list dropdown
 */
export function checkIfSelectedOfferExists(selectedOffer: Offer | null) {
    if (selectedOffer == null) {
        throw new Error(i18n.t('Odaberite ponudu čiji XML želite poslati') as string);
    }

    return true;
}

/**
 * Fetches the active product form and determines if any aborts are currently visible
 * @param activeItemRow - A single multiposition row
 * @param includeWarnings - A flag for including warnings in the check
 * @return Returns all of the checklist fields that are visible and of error type
 */
export function checklistFieldsWithErrors(activeItemRow: ProductRowItem, includeWarnings = false) {
    const allowedFieldTypes = ['abort'];
    const activeProductForm = store.getters['configurator/productForm'](String(activeItemRow.productFormId));

    if (includeWarnings) {
        allowedFieldTypes.push('warning');
    }

    if (activeProductForm == null || !activeProductForm?.checklists?.checklistSegments?.length) {
        return [];
    }

    return activeProductForm.checklists.checklistSegments[0].checklistFields
        .filter((checklistField: ChecklistField) => allowedFieldTypes.includes(checklistField.fieldType))
        .filter((checklistField: ChecklistField) => {
            const activeProductFormValues = store.getters['configurator/activeProductForm'](
                activeItemRow.activeProductFormId
            );
            return (
                activeProductFormValues &&
                determineFieldVisibility(checklistField.checklistVisibleFunction, activeProductFormValues)
            );
        });
}

/**
 * Sends the selected project and offer to be checked by Roltek
 * Sets the required assistance flag to true on an offer
 * @param offer - The  offer that was selected in the offer list dropdown
 */
export async function requestRoltekControl(offer: Offer) {
    try {
        await Offer.requestAssistance(offer.offerId);
    } catch (e) {
        return Promise.reject();
    }

    notification.success({
        message: `${i18n.t('Ponuda je uspješno poslana u Roltek kontrolu')}`,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Finished Roltek control state
 * Sets the required assistance flag to false on an offer
 * @param offer - The  offer that was selected in the offer list dropdown
 */
export async function finishRoltekControl(offer: Offer) {
    try {
        await Offer.finishAssistance(offer.offerId);
    } catch (e) {
        return Promise.reject();
    }

    notification.success({
        message: `${i18n.t('Ponuda je uspješno poslana u Roltek kontrolu')}`,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Sends the selected project and offer to be checked by Roltek
 * Sets the required assistance flag to true on an offer
 * @param offer - The  offer that was selected in the offer list dropdown
 */
export async function cancelRoltekControl(offer: Offer) {
    try {
        await Offer.cancelAssistance(offer.offerId);
    } catch (e) {
        return Promise.reject();
    }

    notification.success({
        message: `${i18n.t('Ponuda je uspješno maknuta iz Roltek kontrole')}`,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Extracts descriptions from offer titles
 * @param offerTitles - All offer titles on an offer
 * @return Offer title descriptions as a string
 */
export function extractOfferTitlesAsString(offerTitles: OfferTitle[]) {
    return offerTitles
        .slice()
        .sort((a, b) => a.rowNumber - b.rowNumber)
        .map((offerTitle) => offerTitle.description)
        .join(', ');
}

/**
 * Download the PDF of the given offer, if it exists
 * @param offer - The offer from which the PDF should be downloaded
 * @param client - The project client whose name will be used in the pdf name, if it exists
 */
export async function onDownloadPDF(offer: Offer | null, client: Client | null) {
    if (offer == null || offer.pdf == null) {
        throw new Error('Offer does not exist');
    }

    const clientName = client ? client?.name : offer?.project?.client ? offer?.project?.client?.name : '';
    let pdf;

    try {
        pdf = await Offer.getOfferPDF(offer.pdf.path);
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    downloadFile(pdf.data, `${offer.offerName} ${clientName}.pdf`, pdf.data.type);

    return Promise.resolve();
}

/**
 * Copy the given offer to a new project and redirect to it
 * @param offer - The offer which should be copied
 * @return An empty promise
 */
export async function copyOfferToNewProject(offer: Offer) {
    let response;

    try {
        response = await Offer.copyExistingToNewProject(offer.id);
        await removeAllowErrorFlagsFromNewOfferItems(response.data.id);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }
    notification.success({
        message: `${i18n.t('Ponuda')} ${offer.name} `,
        description: i18n.t('je uspješno kopirana') as string,
    });

    redirectToProject(response);

    return Promise.resolve();
}

/**
 * Remove allow error flag from new copies offer items
 * @param newProjectId - The id of the project whos offer items should be updated
 * @return An empty promise
 */
async function removeAllowErrorFlagsFromNewOfferItems(newProjectId: string) {
    if (!newProjectId) {
        return;
    }

    const project = await Project.getById(newProjectId);
    const offerId = project.data.offerRevisions[0];
    const offer = await Offer.getById(offerId);
    const offerItems = offer.data.offerItems;

    const promises: Array<Promise<any>> = [];

    offerItems.forEach((offerItemId: string, productFormId: number) => {
        promises.push(OfferItem.updateAllowErrorState(offerItemId, false, productFormId));
    });

    try {
        await Promise.all(promises);
    } catch (e) {
        Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Copy the given offer to the same project and redirect to the products tab
 * @param offer - The offer which should be copied
 * @return An empty promise
 */
export async function copyOfferInCurrentProject(offer: Offer) {
    let response;

    try {
        response = await Offer.copyExisting(offer.offerId);
    } catch (e) {
        return Promise.reject();
    }
    notification.success({
        message: `${i18n.t('Ponuda')} ${offer.name} `,
        description: i18n.t('je uspješno kopirana') as string,
        class: 'offer-copy-success',
    });

    EventBus.$emit(EventBusEvents.updateProject, {
        newOfferId: response.data.id,
        redirectTo: ProjectTabValues.Products,
    });

    return Promise.resolve();
}

/**
 * Redirect to a project from the given response
 * @param response - The response when a new project is created
 */
export function redirectToProject(response: AxiosResponse) {
    Router.push({
        name: RouteNames.project,
        params: {
            id: response.data.id,
        },
        query: {
            initialTab: `${ProjectTabValues.Basic}`,
        },
    });
}

/**
 * Validate and try to recalculate the offer items on the given offer
 * @param offerId - The offer id that needs to be recalculated
 * @param client - The client of the project, if it exists
 */
export async function validateAndRecalculateOffer(
    offerId: string,
    client: Client | null,
    isOfferActionsPopup: boolean = false
) {
    const offer = OfferRepository.getOfferById(offerId);

    if (offer == null) {
        return;
    }

    await checkOfferItemsAndOpenRecalculationPopup(offer, client ? client.id : null);

    return Promise.resolve();
}

/**
 * Send an event to open the document and pdf type popup based on the given offer
 * @param projectId - The current project id
 * @param selectedOffer - The offer on which the popup will be opened
 * @param shouldPreviewPDF - Whether the offer should preview or generate the PDF
 */
export function openDocumentAndPdfTypePopup(
    projectId: string,
    selectedOffer: Offer,
    shouldPreviewPDF = false,
    isAdminPDF = false,
    isProjectLocked = false
) {
    const originalOffer = retrieveOriginalOffer(selectedOffer);
    const project = ProjectRepository.getProjectById(projectId);

    if (project == null) {
        return;
    }

    const popupData: PopupEventData = {
        popupEvent: PopupEvents.openDocumentAndPdfType,
        data: {
            projectId,
            originalOffer,
            selectedOffer,
            assignedUser: project.projectAssignedUser,
            client: project.projectClient,
            shouldPreviewPDF,
            isAdminPDF,
            isProjectLocked,
        },
    };

    if (selectedOffer.pdf != null) {
        if (selectedOffer.pdf.offerConfiguration !== null && !selectedOffer.pdf.offerConfiguration.isArchived) {
            popupData.data.selectedOfferPdfTypeId = selectedOffer.pdf.offerConfiguration.id;
        }
        popupData.data.selectedOfferDocumentNameId = selectedOffer.pdf.documentName.id;
    }

    EventBus.$emit(EventBusEvents.openDocumentAndPdfTypeSlot, popupData);
}

/**
 * Gets the original offer from the selected offer
 * @param offer - The offer from which the original offer id will be fetched
 * @return The original offer if exists, otherwise null
 */
export function retrieveOriginalOffer(offer: Offer) {
    if (!Offer.hasOriginalOffer(offer)) {
        return null;
    }
    return OfferRepository.getOfferById(String((offer as Offer).originalOfferId));
}

/**
 * Validate and try to order the given offer
 * @param offerId - The offer id that needs to be ordered
 * @param client - The client of the project, if it exists
 */
export async function validateAndOrderOffer(offerId: string | null, client: Client | null) {
    const offer = OfferRepository.getOfferById(offerId);

    if (offer == null) {
        return Promise.reject();
    }

    if (
        !(await validateOffer(
            offer,
            client ? client.id : null,
            () => {
                validateAndOrderOffer(offerId, client);
            },
            i18n.t('Rekalkuliraj i stvori narudžbu')
        ))
    ) {
        return Promise.reject();
    }

    try {
        await orderOffer(offer);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Order the given offer
 * @param offer - The offer that needs to be ordered
 * @return - An empty promise
 */
export async function orderOffer(offer: Offer) {
    try {
        await Offer.order(offer.offerId);
    } catch (e) {
        let error;

        if (e instanceof Error) {
            error = e.message;
        } else {
            error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
        }

        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: error,
        });
        return Promise.reject(e);
    }
    await Offer.getForOffersTab(offer.id);

    notification.success({
        message: `${i18n.t('Ponuda')} ${offer.offerName} `,
        description: i18n.t('je uspješno naručena') as string,
        class: 'order-success',
    });

    return Promise.resolve();
}

/**
 * Open the mail client with prefilled project information and download the given offer PDF
 * It will also set the offers sentToClient property to true
 * @param options - All data necessary for generating an email and downloading the offer PDF including:
 * offer, contactPerson, currentUserGroup, projectConnection, client
 */
export async function onSendPDF(options: SendPdfOptions) {
    const generatedMailtoString = generateMailStringForProject(options);

    if (generatedMailtoString === '') {
        return Promise.reject();
    }

    window.open(generatedMailtoString, '_blank');
    await onDownloadPDF(options.offer, options.client);

    if (!options.offer.sentToClient) {
        await updateSentToClient(options.offer.id, true);
        EventBus.$emit(EventBusEvents.updateOfferData);
    }

    return Promise.resolve();
}

/**
 * Generates the mailto: string with project information
 * @param options - All data necessary for generating an email
 * @return The generated string if possible, otherwise an empty string
 */
export function generateMailStringForProject(options: SendPdfOptions) {
    if (options.currentUserGroup == null || options.offer == null) {
        return '';
    }

    if (options.contactPerson == null) {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: i18n.t('Morate dodijeliti kontakt osobu') as string,
        });
        return '';
    }

    const userGroupName = options.currentUserGroup.name.toUpperCase();
    const offerName = options.offer.name;
    const projectConnection = options.projectConnection || '';
    const toEmail = options.contactPerson.email;

    return `mailto:${toEmail}?subject=${userGroupName} - ${offerName} ${projectConnection}`;
}

/**
 * Update the sentToClient property to given state
 * @param offerId - The offer id to be updated
 * @param state - The new state of sentToClient
 * @return - An empty promise
 */
export async function updateSentToClient(offerId: string, state: boolean) {
    try {
        await Offer.updateSentToClient(offerId, state);
    } catch (e) {
        let error;

        if (e instanceof Error) {
            error = e.message;
        } else {
            error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
        }

        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: error,
        });
        return Promise.reject(e);
    }

    notification.success({
        message: `${i18n.t('Promjena uspješna!')}`,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Validate and try to generate the PDF on the given offer
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and generating PDF
 * @return An empty promise
 */
export async function validateOfferAndGeneratePDF(generateOfferPDFParameters: GenerateOfferPDFParameters) {
    const offer = OfferRepository.getOfferById(generateOfferPDFParameters.offerId);

    if (offer == null) {
        return Promise.reject();
    }

    if (
        !(await validateOffer(
            offer,
            generateOfferPDFParameters.client ? generateOfferPDFParameters.client.id : null,
            () => {
                validateOfferAndGeneratePDF(generateOfferPDFParameters);
            },
            i18n.t('Rekalkuliraj i stvori ponudu')
        ))
    ) {
        return Promise.reject();
    }

    await previewOfferPDF(generateOfferPDFParameters, true);
    return Promise.resolve();
}

async function sendPDF(selectedOfferId: string, userGroup: UserGroup | null) {
    const offer = OfferRepository.getOfferById(selectedOfferId);
    if (offer == null) {
        return;
    }
    await onSendPDF({
        offer,
        contactPerson: offer.project.contactPerson,
        currentUserGroup: userGroup,
        client: offer.project.client,
        projectConnection: offer.project.projectConnection,
    });
}

/**
 * Validate the given data for creating the PDF, update offer comment and configuration, and update/create the PDF
 * It will also update the project and show notifications on success
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and generating PDF
 * @param sendEmailPdf - if true after creating pdf email will be sent
 * @return An empty promise
 */
export async function generateOfferPDF(generateOfferPDFParameters: GenerateOfferPDFParameters, sendEmailPdf: boolean) {
    try {
        await validateOfferDataForCreatingPDF(
            generateOfferPDFParameters.assignedUser,
            generateOfferPDFParameters.client
        );
        await updateConfigurationOverrideCommentAndExpirationDate(
            generateOfferPDFParameters.offerId,
            generateOfferPDFParameters.comment,
            generateOfferPDFParameters.expirationDate,
            generateOfferPDFParameters.configurationOverride
        );
        await updateOrCreatePDF(
            generateOfferPDFParameters.offerId,
            generateOfferPDFParameters.documentTypeId,
            generateOfferPDFParameters.pdfTypeId
        );
        setTimeout(async () => {
            if (sendEmailPdf) {
                const element = document.querySelector('[data-test="offer-download"]') as HTMLElement;
                element && element?.click();
                //  await sendPDF(generateOfferPDFParameters.offerId, userGroup)
            }
        }, 2000);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }

    runEffectsAfterSuccess(generateOfferPDFParameters.offerId);
}

/**
 * Validate data related to generating the PDF
 * @param assignedUser - The assigned user on the project
 * @param client - The client on the project
 * @return An empty promise
 */
export async function validateOfferDataForCreatingPDF(assignedUser: CMSUser | null, client: Client | null) {
    if (assignedUser == null) {
        notification.error({
            message: i18n.t('Dogodila se greška') as string,
            description: i18n.t('Morate imati dodijeljenog korisnika kako biste generirali ponudu') as string,
        });
        return Promise.reject();
    }
    return Promise.resolve();
}

/**
 * Update the comment and configuration override on the offer
 * @param offerId - The offer id that wil be updated
 * @param comment - The offer comment
 * @param configurationOverride -The updated offer configuration override
 * @return An empty promise
 */
export async function updateOfferCommentAndConfiguration(
    offerId: string,
    comment: string,
    configurationOverride: ConfigurationOverride
) {
    try {
        await Offer.updateConfigurationOverrideAndComment(offerId, comment, configurationOverride);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Update the comment, configuration override and expiration date on the offer
 * @param offerId - The offer id that wil be updated
 * @param comment - The offer comment
 * @param expirationDate -The offer expirationDate
 * @param configurationOverride -The updated offer configuration override
 * @return An empty promise
 */
export async function updateConfigurationOverrideCommentAndExpirationDate(
    offerId: string,
    comment: string,
    expirationDate: string | null,
    configurationOverride: ConfigurationOverride
) {
    try {
        await Offer.updateConfigurationOverrideCommentAndExpirationDate(
            offerId,
            comment,
            expirationDate,
            configurationOverride
        );
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Update or create the PDF on the offer
 * @param offerId - The offer id that wil be updated
 * @param documentTypeId - The selected document type id to determine the new name of the offer
 * @param pdfTypeId - The selected pdf type id used for generating the PDF
 * @return An empty promise
 */
export async function updateOrCreatePDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
    const offer = OfferRepository.getOfferById(offerId);

    if (offer == null) {
        return Promise.reject();
    }

    const doesPDFExist = offer.pdf != null;

    try {
        if (doesPDFExist) {
            await Pdf.updateExisting(offerId, documentTypeId, pdfTypeId, (offer.pdf as Pdf).id);
        } else {
            await Pdf.addToOffer(offerId, documentTypeId, pdfTypeId);
        }
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Runs finishing actions after a PDF has been generated
 * @param offerId - The offer id that wil be updated
 */
export async function runEffectsAfterSuccess(offerId: string) {
    const successCreateOfferDescription = i18n.t('je uspješno naručena') as string;
    const successUpdatedOfferDescription = i18n.t('je uspješno promijenjena') as string;
    const offer = OfferRepository.getOfferById(offerId);

    if (offer == null) {
        return Promise.reject();
    }

    await Offer.getForOffersTab(offerId);

    notification.success({
        message: `${i18n.t('Ponuda')}`,
        description: offer.pdf != null ? successUpdatedOfferDescription : successCreateOfferDescription,
        class: 'offer-create-success',
    });
    EventBus.$emit(EventBusEvents.closePopup);
    EventBus.$emit(EventBusEvents.updateProject);
    EventBus.$emit(EventBusEvents.updateProjectTabActiveKey, ProjectTabValues.Products);
    window.scrollTo(0, 0);
}

/**
 * Validate and try to preview the PDF on the given offer
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and previewing PDF
 * @return An empty promise
 */
export async function validateOfferAndPreviewPDF(generateOfferPDFParameters: GenerateOfferPDFParameters) {
    const offer = OfferRepository.getOfferById(generateOfferPDFParameters.offerId);

    if (offer == null) {
        return Promise.reject();
    }

    if (
        !(await validateOffer(
            offer,
            generateOfferPDFParameters.client ? generateOfferPDFParameters.client.id : null,
            () => {
                validateOfferAndPreviewPDF(generateOfferPDFParameters);
            },
            i18n.t('Rekalkuliraj i pregledaj ponudu')
        ))
    ) {
        return Promise.reject();
    }
    await previewOfferPDF(generateOfferPDFParameters, false);
    return Promise.resolve();
}

/**
 * Validate and try to preview the PDF on the given offer
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and previewing PDF
 * @return An empty promise
 */
export async function generateAdminPDFPreview(generateOfferPDFParameters: GenerateOfferPDFParameters) {
    const offer = OfferRepository.getOfferById(generateOfferPDFParameters.offerId);

    if (offer == null) {
        return Promise.reject();
    }

    await downloadOfferPDF(generateOfferPDFParameters, false);
    return Promise.resolve();
}

/**
 * Validate the given data for creating the PDF and download the PDF preview
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and previewing PDF
 * @param generate - option for generating pdf or just previewing
 * @return An empty promise
 */
export async function downloadOfferPDF(generateOfferPDFParameters: GenerateOfferPDFParameters, generate: boolean) {
    try {
        if (!generateOfferPDFParameters?.isProjectLocked) {
            await updateOfferCommentAndConfiguration(
                generateOfferPDFParameters.offerId,
                generateOfferPDFParameters.comment,
                generateOfferPDFParameters.configurationOverride
            );
        }

        const pdfData = await downloadPDFBasedOnSpecifications(
            generateOfferPDFParameters.client,
            generateOfferPDFParameters.offerId,
            generateOfferPDFParameters.documentTypeId,
            generateOfferPDFParameters.pdfTypeId
        );

        const pdfName = `${generateOfferPDFParameters?.client?.name}.pdf`;
        const cta = async (email: boolean) => generate && (await generateOfferPDF(generateOfferPDFParameters, email));
        EventBus.$emit(EventBusEvents.closePopup);
        setTimeout(() => {
            EventBus.$emit(EventBusEvents.onOpenPdfPreviewOfferPopup, {
                popupEvent: PopupEvents.openPdfPreviewOfferPopup,
                data: {
                    pdfData,
                    pdfName,
                    generatePdf: generate,
                    createOrderAction: cta,
                    isAdminPDF: generateOfferPDFParameters.isAdminPDF,
                },
            });
        }, 100);
    } catch (e) {
        EventBus.$emit(EventBusEvents.closePopup);
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }
}

/**
 * Validate the given data for creating the PDF and download the PDF preview
 * @param generateOfferPDFParameters - All parameters that are needed for validating the offer and previewing PDF
 * @param generate - option for generating pdf or just previewing
 * @return An empty promise
 */
export async function previewOfferPDF(generateOfferPDFParameters: GenerateOfferPDFParameters, generate: boolean) {
    try {
        await validateOfferDataForCreatingPDF(
            generateOfferPDFParameters.assignedUser,
            generateOfferPDFParameters.client
        );

        if (!generateOfferPDFParameters?.isProjectLocked) {
            await updateOfferCommentAndConfiguration(
                generateOfferPDFParameters.offerId,
                generateOfferPDFParameters.comment,
                generateOfferPDFParameters.configurationOverride
            );
        }

        const pdfData = await preparedPDFForPreviewBasedOnGivenOptions(
            generateOfferPDFParameters.client,
            generateOfferPDFParameters.offerId,
            generateOfferPDFParameters.documentTypeId,
            generateOfferPDFParameters.pdfTypeId
        );
        const pdfName = `${generateOfferPDFParameters?.client?.name}.pdf`;
        const cta = async (email: boolean) => generate && (await generateOfferPDF(generateOfferPDFParameters, email));
        EventBus.$emit(EventBusEvents.closePopup);
        setTimeout(() => {
            EventBus.$emit(EventBusEvents.onOpenPdfPreviewOfferPopup, {
                popupEvent: PopupEvents.openPdfPreviewOfferPopup,
                data: {
                    pdfData,
                    pdfName,
                    generatePdf: generate,
                    createOrderAction: cta,
                },
            });
        }, 100);
    } catch (e) {
        EventBus.$emit(EventBusEvents.closePopup);
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }
}

/**
 * Download the PDF based on the given options
 * @param client - The project client whose name will be used in the pdf name, if it exists
 * @param offerId - The offer id that wil be updated
 * @param documentTypeId - The selected document type id to determine the new name of the offer
 * @param pdfTypeId - The selected pdf type id used for generating the PDF
 * @return An empty promise
 */
export async function downloadPDFBasedOnGivenOptions(
    client: Client | null,
    offerId: string,
    documentTypeId: string,
    pdfTypeId: string
) {
    if (client == null) {
        throw new Error('Client does not exist');
    }
    let pdf;
    let file;

    try {
        pdf = await Pdf.previewOfferPDF(offerId, documentTypeId, pdfTypeId);
        file = await Offer.getOfferPDF(pdf.data.path);
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    downloadFile(file.data, `- ${client.name}.pdf`, pdf.data.type);

    return Promise.resolve();
}

/**
 * Preapre pdf for previewing the PDF based on the given options
 * @param client - The project client whose name will be used in the pdf name, if it exists
 * @param offerId - The offer id that wil be updated
 * @param documentTypeId - The selected document type id to determine the new name of the offer
 * @param pdfTypeId - The selected pdf type id used for generating the PDF
 * @return An empty promise
 */
export async function preparedPDFForPreviewBasedOnGivenOptions(
    client: Client | null,
    offerId: string,
    documentTypeId: string,
    pdfTypeId: string
) {
    let pdf;
    let file;
    let data = null;

    try {
        pdf = await Pdf.previewOfferPDF(offerId, documentTypeId, pdfTypeId);
        file = await Offer.getOfferPDF(pdf.data.path);

        data = {
            file,
            pdf,
        };
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    return Promise.resolve(data);
}

/**
 * Preapre pdf for previewing the PDF based on the given options
 * @param client - The project client whose name will be used in the pdf name, if it exists
 * @param offerId - The offer id that wil be updated
 * @param documentTypeId - The selected document type id to determine the new name of the offer
 * @param pdfTypeId - The selected pdf type id used for generating the PDF
 * @return An empty promise
 */
export async function downloadPDFBasedOnSpecifications(
    client: Client | null,
    offerId: string,
    documentTypeId: string,
    pdfTypeId: string
) {
    let pdf;
    let file;
    let data = null;

    try {
        pdf = await Pdf.downloadOfferPDF(offerId, documentTypeId, pdfTypeId);
        file = await Offer.getOfferPDF(pdf.data.path);

        data = {
            file,
            pdf,
        };
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    return Promise.resolve(data);
}

/**
 * Delete the given offer and set the latest offer as the selected offer if the selected one is deleted
 * @param projectId - The active project id
 * @param offerId - The offer id that needs to be deleted
 * @param selectedOfferId - Id of the currently selected offer
 * @return An empty promise
 */
export async function deleteOfferAndSetSelectedOfferToLatest(
    projectId: string,
    offerId: string,
    selectedOfferId: string | null
) {
    await store.dispatch('projectLoadingState/updateAreOffersLoading', true);

    await Offer.deleteExisting(offerId);
    const latestOffer = OfferRepository.getProjectLatestOffer(projectId);

    if (latestOffer != null && selectedOfferId === offerId) {
        EventBus.$emit(EventBusEvents.updateProject, {
            newOfferId: latestOffer.id,
            redirectTo: ProjectTabValues.Products,
        });
    } else {
        EventBus.$emit(EventBusEvents.updateProject);
    }

    notification.success({
        message: `${i18n.t('Promjene uspješne!')}`,
        description: '',
    });

    return Promise.resolve();
}

/**
 * Generates the decorator rules for the installation and delivery form
 * @param montage - The montage entity that is being edited
 * @return An object of decorator rules for each field in the form
 */
export function getInstallationAndDeliveryFormDecoratorRules(montage?: Montage | null) {
    return {
        [InstallationAndDeliveryFormFields.DELIVERY]: [
            InstallationAndDeliveryFormFields.DELIVERY,
            {
                initialValue: montage ? montage.delivery : false,
                valuePropName: 'checked',
            },
        ],
        [InstallationAndDeliveryFormFields.ASSEMBLY]: [
            InstallationAndDeliveryFormFields.ASSEMBLY,
            {
                initialValue: montage ? montage.assembly : false,
                valuePropName: 'checked',
            },
        ],
        [InstallationAndDeliveryFormFields.PRICE]: [
            InstallationAndDeliveryFormFields.PRICE,
            {
                initialValue: montage ? montage.getPrice : 0,
            },
        ],
        [InstallationAndDeliveryFormFields.NUMBER_OF_WORKERS]: [
            InstallationAndDeliveryFormFields.NUMBER_OF_WORKERS,
            {
                initialValue: montage ? montage.getNumberOfWorkers : 0,
            },
        ],
        [InstallationAndDeliveryFormFields.EXPECTED_TIME]: [
            InstallationAndDeliveryFormFields.EXPECTED_TIME,
            {
                initialValue: montage ? montage.getExpectedTime : 0,
            },
        ],
        [InstallationAndDeliveryFormFields.ELECTRICIAN]: [
            InstallationAndDeliveryFormFields.ELECTRICIAN,
            {
                initialValue: montage ? montage.getElectrician : false,
                valuePropName: 'checked',
            },
        ],
        [InstallationAndDeliveryFormFields.DECOUNSTRUCTION]: [
            InstallationAndDeliveryFormFields.DECOUNSTRUCTION,
            {
                initialValue: montage ? montage.deconstruction : false,
                valuePropName: 'checked',
            },
        ],
        [InstallationAndDeliveryFormFields.REMOVAL]: [
            InstallationAndDeliveryFormFields.REMOVAL,
            {
                initialValue: montage ? montage.removal : false,
                valuePropName: 'checked',
            },
        ],
    };
}

/**
 * Runs the validation on the given form entity from Antd design
 * Submits an API request if everything is fine
 * @param options - All options necessary to create or update the montage entity
 * @return A promise
 */
export async function validateAndSubmitMontage(options: MontageSubmitOptions) {
    return new Promise<void>((resolve, reject) => {
        options.form.validateFieldsAndScroll(async (err: Error[], values: MontageDetailsOptions) => {
            try {
                validateInstallationAndDeliveryForm(err, values);
                if (options.montageId) {
                    await Montage.updateExistingAndSaveLocally(values, options.montageId);
                } else {
                    await Montage.createNewAndSaveLocally(values, options.offerId);
                }
                EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            } catch (e) {
                return reject(e);
            }

            return resolve();
        });
    });
}

/**
 * Throws an error if there are errors in the form
 * @param errors - An array of errors from the form
 * @param values - Values in the form
 * @return A promise
 */
export function validateInstallationAndDeliveryForm(errors: Error[], values: MontageDetailsOptions) {
    if (!values.delivery && !values.assembly) {
        throw new Error('Molimo odaberite dostavu, montažu ili oboje');
    }
    if (errors) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}

/**
 * Runs the validation on the given form entity from Antd design
 * Submits an API request if everything is fine
 * @param options - All options necessary to update the offer entity
 * @return A promise
 */
export async function validateAndSubmitOrderNotes(options: OrderNotesSubmitOptions) {
    return new Promise<void>((resolve, reject) => {
        options.form.validateFieldsAndScroll(async (err: Error[], values: OrderNotesDetailsOptions) => {
            try {
                validateOrderNotesForm(err);
                await Offer.updateOrderNotes(options.offerId, values.orderNotes);
                EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            } catch (e) {
                return reject(e);
            }

            return resolve();
        });
    });
}

/**
 * Throws an error if there are errors in the form
 * @param errors - An array of errors from the form
 * @return A promise
 */
export function validateOrderNotesForm(errors: Error[]) {
    if (errors) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}

/**
 * Generates the decorator rules for the order notes form
 * @param offer - The offer entity that is being edited
 * @return An object of decorator rules for each field in the form
 */
export function getOrderNotesFormDecoratorRules(offer: Offer | null) {
    if (offer == null) {
        return {};
    }

    return {
        [OrderNotesFormFields.ORDER_NOTES]: [
            OrderNotesFormFields.ORDER_NOTES,
            {
                initialValue: offer.orderNotes ? offer.orderNotes : '',
            },
        ],
    };
}

/**
 * Generates the decorator rules for the installation and delivery form
 * @param project - The active project
 * @param offer - The active offer
 * @return An object of decorator rules for each field in the form
 */
export function getPaymentDetailsDecoratorRules(project: Project, offer: Offer | null) {
    return {
        [PaymentDetailsFormFields.BANK_ACCOUNT]: [
            PaymentDetailsFormFields.BANK_ACCOUNT,
            {
                initialValue: project?.bankAccount ? project.bankAccount.id : null,
            },
        ],
        [PaymentDetailsFormFields.TAX_RATE]: [
            PaymentDetailsFormFields.TAX_RATE,
            {
                initialValue: offer ? offer.tax : 0,
            },
        ],
        [PaymentDetailsFormFields.EXPIRATION_DATE]: [
            PaymentDetailsFormFields.EXPIRATION_DATE,
            {
                initialValue: offer && offer.expirationDate ? moment(offer.expirationDate) : null,
            },
        ],
        [PaymentDetailsFormFields.PAYMENT_TYPE]: [
            PaymentDetailsFormFields.PAYMENT_TYPE,
            {
                initialValue: offer && offer.paymentType ? offer.paymentType.id : null,
            },
        ],
    };
}

/**
 * Fetches all dropdown sources for the payment details form
 * @return A promise
 */
export async function fetchPaymentDetailsDropdownOptions() {
    return await Promise.all([BankAccount.getAll(), PaymentType.getAll()]);
}

/**
 * Runs the validation on the given form entity from Antd design
 * Submits an API request if everything is fine
 * @param options - All options necessary to update the offer and project entities
 * @return A promise
 */
export async function validateAndSubmitPaymentDetails(options: PaymentDetailsSubmitOptions) {
    return new Promise<void>((resolve, reject) => {
        options.form.validateFieldsAndScroll(async (err: Error[], values: PaymentDetailsOptions) => {
            try {
                validatePaymentDetails(err);
                await Offer.updatePaymentDetails(options.offerId, values);
                await Project.updateBankAccount({
                    projectId: options.projectId,
                    bankAccountId: values.bankAccount,
                });
                EventBus.$emit(EventBusEvents.fetchProjectFromRepository);
                EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            } catch (e) {
                return reject(e);
            }

            return resolve();
        });
    });
}

/**
 * Throws an error if there are errors in the form
 * @param errors - An array of errors from the form
 * @return A promise
 */
export function validatePaymentDetails(errors: Error[]) {
    if (errors) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}
