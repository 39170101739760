import ProductCatalogue from '@/models/ProductCatalogue';

export default class ProductCatalogueRepository {
    public static getAll() {
        return ProductCatalogue.query().withAll().get();
    }

    public static getById(productCatalogueId: string) {
        return ProductCatalogue.query().whereId(productCatalogueId).withAll().first();
    }

    public static filterByDescription(materialName: string) {
        return ProductCatalogue.query().where((material: ProductCatalogue) => {
            const productName = material.description.toUpperCase();
            return productName.includes(materialName.toLocaleUpperCase());
        }).withAll().get();
    }
}
