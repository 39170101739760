var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-add-offer-item-price-manipulation"},[_c('AForm',{class:{ isLoading: _vm.isLoading },attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('AFormItem',{staticClass:"c-add-offer-item-price-manipulation__form-item u-b1",attrs:{"label":_vm.$t('Tip')}},[_c('ASelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `priceManipulationType`,
                    {
                        initialValue: _vm.initialPriceType,
                    },
                ]),expression:"[\n                    `priceManipulationType`,\n                    {\n                        initialValue: initialPriceType,\n                    },\n                ]"}],attrs:{"placeholder":_vm.$t('Odaberite tip'),"data-test":"price-manipulation-type","filterOption":"","optionFilterProp":"children","show-search":""}},_vm._l((_vm.priceManipulationTypes),function(priceManipulationType){return _c('ASelectOption',{key:priceManipulationType.id,staticClass:"u-b1",attrs:{"value":priceManipulationType.id}},[_vm._v(" "+_vm._s(priceManipulationType.name)+" ")])}),1)],1),_c('AFormItem',{staticClass:"c-add-offer-item-price-manipulation__form-item u-b1",attrs:{"label":_vm.$t('Iznos')}},[_c('AInputNumber',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `value`,
                    {
                        initialValue: _vm.offerItemPriceManipulationItem ? _vm.offerItemPriceManipulationItem.value : 0,
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                pattern: /\d+(\.\d{1,2})?/,
                                message: _vm.$t('Iznos mora biti brojčane vrijednosti'),
                            },
                        ],
                    },
                ]),expression:"[\n                    `value`,\n                    {\n                        initialValue: offerItemPriceManipulationItem ? offerItemPriceManipulationItem.value : 0,\n                        rules: [\n                            {\n                                required: true,\n                                whitespace: true,\n                                pattern: /\\d+(\\.\\d{1,2})?/,\n                                message: $t('Iznos mora biti brojčane vrijednosti'),\n                            },\n                        ],\n                    },\n                ]"}],staticClass:"c-add-offer-item-price-manipulation__input",attrs:{"data-test":"price-manipulation-value","decimalSeparator":","}})],1),_c('AFormItem',{staticClass:"c-add-offer-item-price-manipulation__form-item u-b1",attrs:{"label":_vm.$t('Naziv')}},[_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `name`,
                    {
                        initialValue: _vm.offerItemPriceManipulationItem ? _vm.offerItemPriceManipulationItem.name : '',
                    },
                ]),expression:"[\n                    `name`,\n                    {\n                        initialValue: offerItemPriceManipulationItem ? offerItemPriceManipulationItem.name : '',\n                    },\n                ]"}],attrs:{"data-test":"price-manipulation-name"}})],1),_c('AButton',{staticClass:"c-add-offer-item-price-manipulation__submit u-b1",attrs:{"data-test":"price-manipulation-submit","html-type":"submit","type":"primary"}},[_vm._v(" "+_vm._s(_vm.popupData.offerItemPriceManipulationId == null ? _vm.$t('Dodaj promjenu cijene') : _vm.$t('Spremi promjene'))+" ")])],1),(_vm.isLoading)?_c('a-spin',{staticClass:"loading-wrapper"},[_c('a-icon',{attrs:{"slot":"indicator","type":"loading"},slot:"indicator"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }