import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class NotificationAPI {
    public static async getAll() {
        let notifications;

        try {
            notifications = (await ApiController.get(ApiRoutes.notification.getAll)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notifications);
    }

    public static async getUnread() {
        let notifications;

        try {
            notifications = (await ApiController.get(ApiRoutes.notification.getUnread)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notifications);
    }

    public static async getUnreadCount() {
        let notificationsCount;

        try {
            notificationsCount = (await ApiController.get(ApiRoutes.notification.getUnreadCount)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notificationsCount);
    }

    public static async updateAllAsRead() {
        let notifications;

        try {
            notifications = (await ApiController.get(ApiRoutes.notification.updateAsRead)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(notifications);
    }
}
