
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import VuePdfApp from 'vue-pdf-app';
import {PdfViewConfig} from '@/enums/global/PdfViewConfig';
// import this to use default icons for buttons
import 'vue-pdf-app/dist/icons/main.css';

@Component({
    name: 'PreviewFilePopup',
    components: {
        VuePdfApp,
        PdfViewConfig,
    },
})
export default class PreviewFilePopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private isPendingRequest = false;
    private src: string = '';
    private fileName: string = '';
    private pdfConfig = PdfViewConfig;

    protected data() {
        const data = this.popupData?.data;

        if (data.fileType === 'pdf') {
            const file = new Blob([data.blob], { type: 'application/pdf' });
            return {
                fileName: data.name,
                src: URL.createObjectURL(file),
            };
        } else {
            const file = new Blob([data.blob]);
            return {
                fileName: data.name,
                src: URL.createObjectURL(file),
            };
        }
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }
}
