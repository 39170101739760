import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class EmailTemplatePropertyAPI {
    public static async getAll() {
        let emailTemplateProperties;

        try {
            emailTemplateProperties = await ApiController.get(ApiRoutes.emailTemplateProperty.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(emailTemplateProperties);
    }

    public static async getById(emailTemplatePropertyId: string) {
        let emailTemplateProperty;

        try {
            emailTemplateProperty =
                await ApiController.get(ApiRoutes.emailTemplateProperty.getById(emailTemplatePropertyId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(emailTemplateProperty);
    }

    public static async delete(emailTemplatePropertyId: string) {

        try {
            await ApiController.delete(ApiRoutes.emailTemplateProperty.delete(emailTemplatePropertyId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(emailTemplatePropertyId: string, emailTemplatePropertyInfo: AvailableLabelFields) {
        let emailTemplateProperty;
        const request = {
            data: {
                type: 'email_template_properties',
                id: emailTemplatePropertyId,
                attributes: {
                    headerRichtext: emailTemplatePropertyInfo.headerRichtext,
                    footerRichtext: emailTemplatePropertyInfo.footerRichtext,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: emailTemplatePropertyInfo.locale.id,
                        },
                    },
                },
            },
        };

        try {
            emailTemplateProperty =
                await ApiController.patch(
                    ApiRoutes.emailTemplateProperty.update(emailTemplatePropertyId),
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(emailTemplateProperty);
    }

    public static async create(emailTemplatePropertyInfo: AvailableLabelFields) {
        let emailTemplateProperty;
        const request = {
            data: {
                type: 'email_template_properties',
                attributes: {
                    headerRichtext: emailTemplatePropertyInfo.headerRichtext,
                    footerRichtext: emailTemplatePropertyInfo.footerRichtext,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: emailTemplatePropertyInfo.locale.id,
                        },
                    },
                },
            },
        };

        try {
            emailTemplateProperty =
                await ApiController.post(
                    ApiRoutes.emailTemplateProperty.create,
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(emailTemplateProperty);
    }
}
