import { Model } from '@vuex-orm/core';
import ClientInformationAPI from '@/api/ClientInformationAPI';

export default class ClientInformation extends Model {
    public static async getById(taxID: string) {
        let clientInformation;

        try {
            clientInformation = await ClientInformationAPI.getById(taxID);
        } catch (e) {
            return Promise.reject(e);
        }

        // this.create({
        //     data: clientInformation.data,
        // });
        return Promise.resolve(clientInformation);
    }

    public city!: string;
    public country!: number;
    public fullName!: string;
    public postalCode!: string;
    public registerID!: string;
    public shortName!: string;
    public street!: string;
    public taxNumber!: string;
    public taxPayer!: number;
}
