
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserGroup from '@/models/UserGroup';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import RLogoIcon from '@/icons/RLogoIcon.vue';
import RLogoIconSLO from '@/icons/RLogoIconSLO.vue';
import RLogoIconAT from '@/icons/RLogoIconAT.vue';
import RLogoIconHR from '@/icons/RLogoIconHR.vue';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
import { SessionStorageKeyNames } from '@/enums/global/SessionStorageCommonEndpoints';

@Component({
    name: 'UserGroupSelect',
    components: {
        RLogoIcon,
        RLogoIconSLO,
        RLogoIconAT,
        RLogoIconHR,
    },
})
export default class UserGroupSelect extends Vue {
    @Prop({ required: true }) private collapsed!: boolean;

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private searchString: string = '';
    private highlitedItemIndex: number = 0;
    private isDropdownVisible: boolean = false;

    private allUserGroups: any[] = [];
    private selectedKeys: string[] | number[] = [0];

    private get userGroups() {
        if (this.allUserGroups.length === 0) {
            return [];
        }

        const allowedUserGroupIds = this.$store.getters['jwtData/allowedUserGroups'].map((id: number) => String(id));

        return this.allUserGroups.filter((userGroup: UserGroup) => {
            return allowedUserGroupIds.includes(userGroup.id);
        });
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get filteredUserGroups() {
        if (this.userGroups.length > 2 && this.searchString === '') {
            return [];
        }
        if (this.searchString && this.searchString !== '') {
            if (this.searchString.length < 3) {
                return [];
            }
            return this.userGroups.filter((ug) =>
                (ug.name as string).toLowerCase().includes(this.searchString.toLowerCase())
            );
        }
        return this.userGroups;
    }

    private triggerDropdownVisibility() {
        this.isDropdownVisible = true;
    }

    private onChangeUserGroup(value: string) {
        this.loadingOverlay.start();

        LocalStorageService.remove(ProjectTypes.Lead as string);
        LocalStorageService.remove(ProjectTypes.Offer as string);
        LocalStorageService.remove(ProjectTypes.Order as string);
        LocalStorageService.remove(ProjectTypes.Admin as string);

        this.clearSessionStorageCache();

        this.$store.dispatch('jwtData/updateUserGroup', value);
        this.isDropdownVisible = false;

        if (this.$route.name !== RouteNames.home) {
            this.$router.push({ name: RouteNames.home }, () => {
                EventBus.$emit(EventBusEvents.refetchHome);
            });
        } else {
            EventBus.$emit(EventBusEvents.refetchHome);
        }

        this.loadingOverlay.stop();
    }

    private clearSessionStorageCache() {
        // Clear user group tied cache data
        Object.keys(sessionStorage).forEach((key: any) => {
            if (!Object.values(SessionStorageKeyNames).includes(key as SessionStorageKeyNames)) {
                ss.remove(key);
            }
        });
    }

    private async fetchUserGroups() {
        try {
            if (ss.has('userGroups')) {
                this.allUserGroups = ss.getAndParse('userGroups');
            } else {
                await UserGroup.getAll();
                const allUserGroups = UserGroupRepository.getAll();

                this.allUserGroups = allUserGroups;
                ss.stringifyAndSave('userGroups', allUserGroups);
            }
        } catch (e) {
            return;
        }
    }

    private async dropdownVisibleChange(visible: boolean) {
        if (visible && this.userGroups.length > 2) {
            await this.$nextTick();

            const search = this.$refs.userGroupSelectSearch as HTMLElement;

            if (search) {
                search.focus();
            }
        }
    }

    private changeUserGroup() {
        const currentUserGroupId = this.filteredUserGroups[this.selectedKeys[0] as number].id;
        this.onChangeUserGroup(currentUserGroupId);

        this.isDropdownVisible = false;
    }

    private scrollDown() {
        if (this.highlitedItemIndex < this.filteredUserGroups.length - 1) {
            this.highlitedItemIndex++;
        }

        this.selectedKeys = [this.highlitedItemIndex];
    }
    private scrollUp() {
        if (this.highlitedItemIndex > 0) {
            this.highlitedItemIndex--;
        }

        this.selectedKeys = [this.highlitedItemIndex];
    }

    private onItemHover() {
        this.highlitedItemIndex = 0;
    }

    private mounted() {
        this.fetchUserGroups();
    }

    @Watch('collapsed')
    private onCollapsedChange() {
        if (!this.isDropdownVisible) {
            return;
        }

        if (this.collapsed) {
            this.isDropdownVisible = false;
        }
    }
}
