
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClientInformation from '@/models/ClientInformation';
import CountryRepository from '@/repositories/CountryRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ClientInformationPopup',
})
export default class ClientInformationPopup extends Vue {
    @Prop({ required: false }) private clientData!: ClientInformation;

    private nameOption = 'fullName';
    private checkedFields: string[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private checked(key: string) {
        return this.checkedFields.includes(key);
    }

    private get countries() {
        return CountryRepository.getAll();
    }

    private get clientsCountry() {
        if (this.clientData.country) {
            return this.countries.find((country) => country.id === String(this.clientData.country));
        }
    }

    private get filteredData() {
        const data = [];
        const labels = [this.$t('Country'), this.$t('City'), this.$t('Postal Code'), this.$t('Register ID'), this.$t('Street'), this.$t('Tax ID'), this.$t('Taxpayer')];
        const keys = ['country', 'city', 'postalCode', 'registerID', 'street', 'taxNumber', 'taxPayer'];
        for (let i = 0; i < labels.length; i++) {
            if (labels[i] === 'Taxpayer') {
                data.push({
                    label: labels[i],
                    key: keys[i],
                    // @ts-ignore
                    value: this.clientData[keys[i]] ? 'Yes' : 'No',
                });
            } else {
                data.push({
                    label: labels[i],
                    key: keys[i],
                    // @ts-ignore
                    value: this.clientData[keys[i]],
                });
            }
        }
        return data;
    }

    private onCheck(key: string) {
        if (this.checkedFields.includes(key)) {
            this.checkedFields = this.checkedFields.filter((field) => field !== key);
        } else {
            this.checkedFields.push(key);
        }
    }

    private onClientConfirmation() {
        if (this.nameOption === 'None' && !this.checkedFields.length) {
            return;
        }

        if (this.nameOption !== 'None') {
            this.checkedFields.push(this.nameOption);
        }

        const data = {
            fields: this.checkedFields,
            values: this.clientData,
        };

        this.loadingOverlay.start();
        setTimeout(() => {
            this.loadingOverlay.stop();
        }, 500);

        EventBus.$emit(EventBusEvents.updateClientData, data);
        this.resetForm();
    }

    private resetForm() {
        this.nameOption = 'fullName';
        this.checkedFields = [];
    }

    private created() {
        EventBus.$on(EventBusEvents.clientInformationConfirmed, this.onClientConfirmation);
        EventBus.$on(EventBusEvents.clientInformationCanceled, this.resetForm);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.clientInformationConfirmed, this.onClientConfirmation);
        EventBus.$off(EventBusEvents.clientInformationCanceled, this.resetForm);
    }
}
