
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
// import this to use default icons for buttons
import { getOrderPdfForPreview, updateOrCreateOrderPDF, getOrderConfigurationIdByLocale } from '@/helpers/OrderHelper';
import i18n from '@/i18n';
import { validateAndOrderOffer } from '@/helpers/OfferHelper';
import ExclamationMark from '@/icons/ExclamationMark.vue';
import VuePdfApp from 'vue-pdf-app';
import { PdfViewConfig } from '@/enums/global/PdfViewConfig';
// import this to use default icons for buttons
import 'vue-pdf-app/dist/icons/main.css';

@Component({
    name: 'PdfPreviewOrderPopup',
    components: {
        ExclamationMark,
        VuePdfApp,
        PdfViewConfig,
    },
})
export default class PdfPreviewOrderPopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private isPendingRequest = true;
    private isPendingRequestOfConfirmation = false;
    private src: string = '';
    private filename: string = '';
    private showAllErrors: boolean = false;
    private errorList = [];
    private confirmWord: string = '';
    private pdfConfig = PdfViewConfig;

    public async mounted() {
        const pdfData = await getOrderPdfForPreview(this.popupData.selectedOfferId, i18n.locale);
        const file = new Blob([pdfData.file.data], { type: 'application/pdf' });
        this.filename = this.popupData?.pdfName;
        this.errorList = pdfData.pdf.data.errors;
        this.src = URL.createObjectURL(file);
        this.isPendingRequest = false;
    }
    private get hasErrors() {
        return this.errorList.length > 0;
    }

    private async confirmOrder() {
        if (this.confirmWord.toLowerCase() !== i18n.t('Narudžba').toLowerCase()) {
            this.$notification.error({
                message: this.$t('Greška') as string,
                description: this.$t('Please type the correct confirm word to create the offer.') as string,
            });
            return;
        }
        this.isPendingRequestOfConfirmation = true;
        let isSuccess = false;
        const orderConfigurationId = getOrderConfigurationIdByLocale(i18n.locale);
        await updateOrCreateOrderPDF(this.popupData.selectedOfferId, orderConfigurationId);

        try {
            await validateAndOrderOffer(this.popupData.selectedOfferId, this.popupData.client);
            isSuccess = true;
        } catch (e) {
            if ((e as any).code === 'ECONNABORTED') {
                this.$notification.error({
                    message: this.$t('Greška') as string,
                    description: this.$t(
                        'Poslužitelj trenutno nije dostupan: Potvrda narudžbe trenutno nije moguća. Molimo pokušajte ponovo kasnije.'
                    ) as string,
                });
            }
        } finally {
            this.isPendingRequestOfConfirmation = false;

            const showCelebrationToUG =
                String(this.$store.getters['jwtData/currentUserGroup']) != '1' &&
                String(this.$store.getters['jwtData/currentUserGroup']) != '2' &&
                String(this.$store.getters['jwtData/currentUserGroup']) != '3';

            EventBus.$emit(EventBusEvents.updateProject, {
                shouldShowPopup: isSuccess && showCelebrationToUG,
                popupType: 'celebration',
            });
            EventBus.$emit(EventBusEvents.closePopup);
        }
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private editOffer() {
        EventBus.$emit(EventBusEvents.closePopup);
        const element = document.querySelector('[data-test="offer-copy"]') as HTMLElement;
        element && element?.click();
    }
}
