import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class CountryAPI {
    public static async getAll() {
        let countries;
        try {
            countries = await ApiController.get(ApiRoutes.country.getAll) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return countries;
    }
}
