var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-link__wrapper"},[(_vm.nav.entity === _vm.globalEntities.projects)?_c('router-link',{staticClass:"nav-link__link",attrs:{"to":{
            name: _vm.routeNames.project,
            params: {
                id: _vm.nav.id,
            },
            query: {
                initialTab: _vm.navTab,
            },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.navigationText)+" ")]):(_vm.nav.entity === _vm.globalEntities.clients)?_c('router-link',{staticClass:"nav-link__link",attrs:{"to":{
            name: _vm.routeNames.editClient,
            params: {
                clientId: _vm.nav.id,
            },
            query: {
                initialTab: _vm.navTab,
            },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.navigationText)+" ")]):_c('router-link',{staticClass:"nav-link__link",attrs:{"to":{
            name: _vm.nav.entity,
        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.navigationText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }