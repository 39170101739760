import OfferItem from '@/models/OfferItem';
import Offer from '@/models/Offer';
import {
    extractDefaultValues,
    extractOptionsFunctions,
    extractUpdateFunctions,
    extractWarningAndAbortFields,
    formatFormData,
} from '@/helpers/FormDataHelper';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import store from '@/store';
import { OfferValidationProductFormConfig } from '@/interfaces/general/OfferValidationProductFormConfig';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import {
    determineFieldVisibility,
    extractAndRunOptionsFunction,
    extractUpdateFunctionValues,
} from '@/helpers/FieldFunctionHelper';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import { OptionFunctionEntry } from '@/interfaces/components/configurator/OptionFunctionEntry';
import { ProductAndProductFormId } from '@/interfaces/general/ProductAndProductFormId';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import { parseAndTransformStringWithDynamicValues } from '@/helpers/JsonLogicHelper';
import { ChecklistStringField } from '@/interfaces/components/configurator/ChecklistStringField';
import Vue from 'vue';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import JsFunctionRepository from '@/repositories/JsFunctionRepository';
import JsFunction from '@/models/JsFunction';
import { OfferRecalculationErrorItem } from '@/interfaces/components/offerHistory/OfferRecalculationErrorItem';
import { ProductTypes } from '@/enums/global/ProductTypes';
import i18n from '@/i18n';
import ConfiguratorService from '@/services/ConfiguratorService';
import { ErrorSeverities } from '@/enums/global/ErrorSeverities';
import { RecalculationError } from '@/interfaces/components/offerHistory/RecalculationError';
import { registerJsonLogicFunctions } from '@/helpers/JsonLogicHelper';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import { ErrorOptions } from '@/interfaces/ErrorOptions';
import { extractInvalidDefaultValues } from '@/helpers/FieldFunctionHelper';
import { defaultValueWarnings as defaultValueWarningsConfig } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import { MultipositionProduct } from '@/interfaces/components/projectNew/MultipositionProduct';
import { checklistFieldsWithErrors } from '@/helpers/OfferHelper';
import OfferItemRepository from '@/repositories/OfferItemRepository';

/**
 * Compares the products on a given offer, sets the callback and recalculates all offer items
 * If all offer items have the same checklist and pricelist versions, it will not recalculate
 * @param offer - The offer that needs to be validated
 * @param clientId - The id of the client assigned to the project
 * @param callbackAfterRecalculation - The function that needs to be called after the recalculation
 * @param recalculationAndCallbackLabel - The label for the button in the recalculation popup
 * @return - A promise with a boolean determining whether the offer is valid or not
 */
export async function validateOffer(
    offer: Offer,
    clientId: null | string,
    callbackAfterRecalculation: () => void,
    recalculationAndCallbackLabel = ''
) {
    const productOfferItems = offer.offerItems.filter(
        (offerItem) => offerItem.offerItemOfferItemType === ProductTypes.PRODUCT
    );

    store.commit('configurator/clearOfferItems');
    await store.dispatch('configurator/addInitialOfferItems', productOfferItems);
    store.commit('configurator/setCallbackAfterCalculation', callbackAfterRecalculation);
    store.commit('configurator/setRecalculationAndCallbackLabel', recalculationAndCallbackLabel);

    if (compareProductFormVersions(productOfferItems)) {
        EventBus.$emit(EventBusEvents.closeRecalculationPopup);
        return Promise.resolve(true);
    }

    let jsFunctions = JsFunctionRepository.getAll();

    if (jsFunctions.length <= 0) {
        await JsFunction.getAll();
        jsFunctions = JsFunctionRepository.getAll();
    }
    registerJsonLogicFunctions(jsFunctions);
    EventBus.$emit(EventBusEvents.openRecalculationPopup);
    try {
        await extractAndFetchProductForms(productOfferItems, clientId);
    } catch (e) {
        return Promise.resolve(false);
    }

    determineDefaultValueAvailability();

    return Promise.resolve(false);
}

/**
 * Opens the recalculation popup and recalculates all the products in the offer
 * @param offer - The offer that needs to be validated
 * @param clientId - The id of the client assigned to the project
 * @return - An empty promise
 */
export async function checkOfferItemsAndOpenRecalculationPopup(
    offer: Offer,
    clientId: null | string,
    isOfferActionsPopup: boolean = false
) {
    const productOfferItems = offer.offerItems;
    // .filter((offerItem) => offerItem.offerItemOfferItemType === ProductTypes.PRODUCT);
    store.commit('configurator/clearOfferItems');

    await store.dispatch('configurator/addInitialOfferItems', productOfferItems);
    // tslint:disable-next-line:no-empty
    store.commit('configurator/setCallbackAfterCalculation', () => {});

    let jsFunctions = JsFunctionRepository.getAll();

    if (jsFunctions.length <= 0) {
        await JsFunction.getAll();
        jsFunctions = JsFunctionRepository.getAll();
    }
    registerJsonLogicFunctions(jsFunctions);
    EventBus.$emit(EventBusEvents.openRecalculationPopup, isOfferActionsPopup);
    try {
        await extractAndFetchProductForms(
            productOfferItems.filter((offerItem) => offerItem.offerItemOfferItemType === ProductTypes.PRODUCT),
            clientId
        );
    } catch (e) {
        return Promise.reject();
    }

    try {
        await updateMaterialOfferItem(
            productOfferItems.filter((offerItem) => offerItem.offerItemOfferItemType === ProductTypes.MATERIAL),
            clientId
        );
        await determineDefaultValueAvailability();
    } catch (e) {
        return Promise.reject();
    }

    return Promise.resolve();
}

/**
 * We check if default values for each product field is available in current offer item
 * and if not, we update recalculation errors in vuex
 * @return - empty promise
 */
export async function determineDefaultValueAvailability() {
    const recalculationErrors = store.getters['configurator/offerItemRecalculationErrors'];

    if (!recalculationErrors.length) {
        return;
    }

    const productForms = store.getters['configurator/productForms'];
    const activeProductForms = store.getters['configurator/activeProductForms'];

    const invalidDefaultValues: defaultValueWarningsConfig[] = [];

    let currentProductFormValues: { id: number; activeProductForm: any };
    let currentProductForm: ProductForm;

    recalculationErrors.forEach((recalculationItem: any) => {
        if (!currentProductForm || currentProductForm.id !== recalculationItem.productId) {
            const productForm = productForms.find(
                (productForm: ProductForm) => productForm.id === recalculationItem.productId
            );

            currentProductForm = productForm.checklists.checklistSegments
                .map((segment: any) => segment.checklistFields)
                .flat();
        }

        if (!currentProductFormValues || currentProductFormValues.id !== recalculationItem.activeProductFormId) {
            const formValues = activeProductForms.find(
                (activeProductForm: any) => activeProductForm.id === recalculationItem.activeProductFormId
            );
            currentProductFormValues = formValues;
        }

        const defaultValueWarnings: Array<any> = extractInvalidDefaultValues(
            currentProductForm,
            currentProductFormValues.activeProductForm,
            recalculationItem.activeProductFormId
        );

        invalidDefaultValues.push({
            id: recalculationItem.rowNumber,
            productFormId: recalculationItem.activeProductFormId,
            warnings: defaultValueWarnings,
            productId: recalculationItem.productId,
            productName: recalculationItem.name,
        });
    });

    await store.dispatch('configurator/setDefaultValueWarnings', invalidDefaultValues);
    await store.dispatch('configurator/updateRecalculationErrorsWithDefaultValueWarnings');

    return Promise.resolve();
}

export function compareMajorVersions(v1: string, v2: string) {
    const version1 = v1.split('_');
    const version2 = v2.split('_');
    if (version1.length < 2 || version2.length < 2) {
        return version1[0] === version2[0];
    }
    return version1[0] === version2[0] && version1[1] === version2[1];
}

export function compareProductFormVersions(offerItems: OfferItem[]) {
    return offerItems.every((offerItem) => {
        /*return offerItem.priceListVersion === offerItem.productSystem.product.productForm.priceListVersion &&
            offerItem.checklistVersion === offerItem.productSystem.product.productForm.checklistVersion;*/
        return (
            compareMajorVersions(
                offerItem.priceListVersion ?? '',
                offerItem.productSystem.product.productForm.priceListVersion
            ) &&
            compareMajorVersions(
                offerItem.checklistVersion ?? '',
                offerItem.productSystem.product.productForm.checklistVersion
            )
        );
    });
}

export function filterValidOfferItems(offerItems: OfferItem[]) {
    // unused
    return offerItems.filter((offerItem) => {
        /*return offerItem.priceListVersion !== offerItem.productSystem.product.productForm.priceListVersion ||
            offerItem.checklistVersion !== offerItem.productSystem.product.productForm.checklistVersion;*/
        return (
            !compareMajorVersions(
                offerItem.priceListVersion ?? '',
                offerItem.productSystem.product.productForm.priceListVersion
            ) ||
            !compareMajorVersions(
                offerItem.checklistVersion ?? '',
                offerItem.productSystem.product.productForm.checklistVersion
            )
        );
    });
}

/*export function filterInvalidOfferItems(offerItems: OfferItem[]) { // unused
    return offerItems.filter((offerItem) => {
        return offerItem.priceListVersion === offerItem.productSystem.product.productForm.priceListVersion &&
            offerItem.checklistVersion === offerItem.productSystem.product.productForm.checklistVersion;
    });
}*/
export async function updateMaterialOfferItem(offerItems: OfferItem[], clientId: string | null) {
    for await (const offerItem of offerItems) {
        try {
            // get priceList here and pass it to updateOfferItem
            const response = await createNewMaterialForm(offerItem, clientId);
            await updateOfferItem(
                offerItem.id,
                [],
                [],
                response.priceList,
                response.activeProductFormId,
                offerItem.offerItemOfferItemType,
                null
            );
        } catch (e) {
            const errorObj = e as { type?: string; errors?: [] }; // Type assertion

            const backendErrors = errorObj.type === 'backend' ? errorObj.errors : [];
            const frontendErrors = errorObj.type == null || errorObj.type === 'frontend' ? errorObj.errors : [];

            await updateOfferItem(
                offerItem.id,
                backendErrors,
                frontendErrors,
                undefined,
                null,
                offerItem.offerItemOfferItemType,
                null
            );
        }
        await Vue.nextTick();
    }
}
export async function extractAndFetchProductForms(offerItems: OfferItem[], clientId: string | null) {
    const productFormAndProductIds = extractProductFormAndProductIds(offerItems);
    let activeProductForms = null;

    try {
        activeProductForms = await generateProductFormsDefaultSettings(productFormAndProductIds);
    } catch (e) {
        throw e;
    }

    if (activeProductForms == null) {
        throw new Error('An error occurred when generating active product forms');
    }

    for await (const offerItem of offerItems) {
        try {
            const response = await createNewProductForm(
                offerItem,
                activeProductForms as OfferValidationProductFormConfig[],
                clientId
            );
            await updateOfferItem(
                offerItem.id,
                [],
                [],
                response.priceList,
                response.activeProductFormId,
                offerItem.offerItemOfferItemType,
                parseInt(offerItem.productSystem.product.id)
            );
        } catch (e) {
            const errorObj = e as ErrorOptions;

            const backendErrors = errorObj.type === 'backend' ? errorObj.errors : [];
            const frontendErrors = errorObj.type == null || errorObj.type === 'frontend' ? errorObj.errors : [];

            await updateOfferItem(
                offerItem.id,
                backendErrors,
                frontendErrors,
                errorObj.priceList,
                errorObj.activeProductFormId,
                offerItem.offerItemOfferItemType,
                parseInt(offerItem.productSystem.product.id)
            );
        }
        await Vue.nextTick();
    }
}

export async function getDefaultProductFormSettings(productIdAndProductFormId: ProductAndProductFormId) {
    let activeProductForm;
    try {
        activeProductForm = await getCurrentProductForm(productIdAndProductFormId.productFormId);
    } catch (e) {
        throw e;
    }
    if (activeProductForm == null) {
        return Promise.reject(new Error('Greška pri dohvaćanju proizvoda'));
    }

    const defaultUpdateFunctions = extractUpdateFunctions(activeProductForm);
    const defaultOptionsFunctions = extractOptionsFunctions(activeProductForm);
    const defaultProductFormValues = await extractDefaultValues({
        productForm: activeProductForm,
        productId: productIdAndProductFormId.productId,
        clientId: null,
        productSystemName: null,
    });
    const warningAndAbortFields = extractWarningAndAbortFields(activeProductForm);

    return Promise.resolve({
        activeProductForm,
        defaultUpdateFunctions,
        defaultOptionsFunctions,
        defaultProductFormValues,
        warningAndAbortFields,
        productFormId: productIdAndProductFormId.productFormId,
    });
}

export async function getCurrentProductForm(productFormId: string): Promise<ProductForm | null> {
    try {
        await store.dispatch('configurator/getSingleProductForm', productFormId);
    } catch (e) {
        throw e;
    }

    return store.getters['configurator/productForm'](productFormId);
}

export async function createNewProductForm(
    offerItem: OfferItem,
    activeProductFormConfigs: OfferValidationProductFormConfig[],
    clientId: string | null
) {
    const currentActiveProductFormConfig = activeProductFormConfigs.find((activeProductFormConfig) => {
        const activeProductFormId = activeProductFormConfig.productFormId;
        const offerItemProductFormId = offerItem.offerItemProductSystem.product.productForm.id;

        return activeProductFormId === offerItemProductFormId;
    });
    if (currentActiveProductFormConfig === undefined) {
        return Promise.reject(new Error('Active product form not found'));
    }

    let response = null;

    try {
        response = await validateOfferItem(
            offerItem.offerItemFormData,
            currentActiveProductFormConfig,
            clientId,
            offerItem.id
        );
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve(response);
}

export async function createNewMaterialForm(offerItem: OfferItem, clientId: string | null) {
    const activeProductFormId = await store.dispatch('configurator/addActiveProductForm', {});
    let priceList = [];

    try {
        for await (const formDataEntry of offerItem.offerItemFormData) {
            await store.dispatch('configurator/updateActiveProductFormValue', {
                pId: formDataEntry.id,
                value: formDataEntry.value,
                productFormId: activeProductFormId,
            });
        }

        /* const frontendValidationErrors = getErrorFieldsMessages(settings.warningAndAbortFields, activeProductFormId);
        const abortTypeErrors = getOnlyAbortErrors(frontendValidationErrors);

        if (abortTypeErrors.length === 0) {
            priceList = await calculateConfiguratorPrice(
                settings.productFormId,
                activeProductFormId,
                clientId,
                offerItemId,
            );
        }*/
        priceList = await calculateMaterialPrice(clientId, offerItem.id);
        /*if (frontendValidationErrors.length > 0) {
            return Promise.reject({
                type: 'frontend',
                errors: frontendValidationErrors,
                priceList,
                activeProductFormId,
            });
        }*/
    } catch (e) {
        const errorObj = e as ErrorOptions;
        const errors =
            errorObj.error != null
                ? [e]
                : [
                      {
                          error: [(e as Error).message],
                          severity: 'abort',
                      },
                  ];

        if (errorObj.message === "Cannot read properties of null (reading 'match')") {
            const erroredFunction = (errorObj as any).stack.split('    at ')[1].replace('Object.', '').split(' ')[0];
            (errors as any)[0].error = `${i18n.t('Greška u funkciji')}: ${erroredFunction}`;
        }
        return Promise.reject({
            errors,
            type: 'frontend',
            priceList,
            activeProductFormId,
        });
    }

    return Promise.resolve({
        priceList,
        activeProductFormId,
    });
}

export async function validateOfferItem(
    offerItemFormData: ChecklistFieldEntry[],
    settings: OfferValidationProductFormConfig,
    clientId: string | null,
    offerItemId: string
) {
    const activeProductFormId = await store.dispatch(
        'configurator/addActiveProductForm',
        Object.assign({}, settings.defaultProductFormValues)
    );
    let priceList = [];

    try {
        let shouldRunFunctions = true;
        await mapOfferItemsFormData(offerItemFormData, activeProductFormId, clientId);

        while (shouldRunFunctions) {
            let numberOfUpdatedValues = await runUpdateFunctions(settings.defaultUpdateFunctions, activeProductFormId);
            numberOfUpdatedValues += await runOptionsFunctions(settings.defaultOptionsFunctions, activeProductFormId);
            if (numberOfUpdatedValues <= 0) {
                shouldRunFunctions = false;
            }
        }

        const frontendValidationErrors = getErrorFieldsMessages(settings.warningAndAbortFields, activeProductFormId);
        const abortTypeErrors = getOnlyAbortErrors(frontendValidationErrors);

        if (abortTypeErrors.length === 0) {
            priceList = await calculateConfiguratorPrice(
                settings.productFormId,
                activeProductFormId,
                clientId,
                offerItemId
            );
        }

        if (frontendValidationErrors.length > 0) {
            return Promise.reject({
                type: 'frontend',
                errors: frontendValidationErrors,
                priceList,
                activeProductFormId,
            });
        }
    } catch (e) {
        const errorObj = e as Error | any;

        const errors =
            errorObj.error != null
                ? [e]
                : [
                      {
                          error: [errorObj.message],
                          severity: 'abort',
                      },
                  ];

        if (errorObj.message === "Cannot read properties of null (reading 'match')") {
            const erroredFunction = errorObj.stack.split('    at ')[1].replace('Object.', '').split(' ')[0];
            (errors as any)[0].error = `${i18n.t('Greška u funkciji')}: ${erroredFunction}`;
        }
        return Promise.reject({
            errors,
            type: 'frontend',
            priceList,
            activeProductFormId,
        });
    }

    return Promise.resolve({
        priceList,
        activeProductFormId,
    });
}

export async function calculateConfiguratorPrice(
    productFormId: string,
    activeProductFormId: string,
    clientId: string | null,
    offerItemId: string
) {
    const activeProductFormValues = store.getters['configurator/activeProductForm'](activeProductFormId);
    let priceList = [];
    try {
        priceList = await store.dispatch('configurator/calculatePrice', {
            activeProductFormValues,
            productFormId,
            clientId,
            currencySymbol: '',
            offerItemId,
        });
    } catch (e) {
        const errorObj = e as ErrorOptions;

        if (errorObj.response && errorObj.response.status === 422) {
            if (
                errorObj.response.data.errors &&
                errorObj.response.data.errors[0].code &&
                errorObj.response.data.errors[0].code === 'PRODUCT_LIST_NOT_FOUND'
            ) {
                return Promise.reject({
                    error: errorObj.response?.data?.errors[0]?.title,
                    severity: 'abort',
                });
            }
            return Promise.reject({
                error: errorObj?.response?.data?.errors?.[0]?.title,
                severity: 'abort',
            });
        }
        return Promise.reject({
            error: errorObj.message ? errorObj.message : errorObj,
            severity: 'abort',
        });
    }

    return Promise.resolve(priceList);
}

export function extractProductFormAndProductIds(offerItems: OfferItem[]) {
    return offerItems
        .map((offerItem) => {
            return {
                productFormId: offerItem?.productSystem?.product?.productForm?.id,
                productId: offerItem?.productSystem?.product?.id,
            };
            // used to filter out duplicates of product form ids
        })
        .filter((value, index, self) => self.findIndex((t) => t.productFormId === value.productFormId) === index);
}

export async function generateProductFormsDefaultSettings(productFormAndProductIds: ProductAndProductFormId[]) {
    try {
        return await Promise.all(
            productFormAndProductIds.map((productIdAndProductFormId) => {
                return getDefaultProductFormSettings(productIdAndProductFormId);
            })
        );
    } catch (e) {
        throw e;
    }
}

export async function mapOfferItemsFormData(
    offerItemFormData: ChecklistFieldEntry[],
    activeProductFormId: number,
    clientId?: string | null
) {
    for await (const formDataEntry of offerItemFormData) {
        if (clientId != null && formDataEntry.id === GlobalOptions.PRICELIST_PID) {
            await ConfiguratorService.updatePricelistValueToClientPricelistValue(
                clientId,
                formDataEntry.id,
                activeProductFormId
            );
            continue;
        }
        if (compareCurrentAndGivenValue(formDataEntry.id, activeProductFormId, formDataEntry.value)) {
            continue;
        }

        await store.dispatch('configurator/updateActiveProductFormValue', {
            pId: formDataEntry.id,
            value: formDataEntry.value,
            productFormId: activeProductFormId,
        });
    }

    return Promise.resolve();
}

export async function runUpdateFunctions(updateFunctions: UpdateFunctionEntry[], activeProductFormId: number) {
    let numberOfUpdatedValues = 0;

    for await (const entry of updateFunctions) {
        const activeProductFormValues = store.getters['configurator/activeProductForm'](activeProductFormId);
        const updatedValues: ConfiguratorValue[] = extractUpdateFunctionValues(
            entry.updateFunctions,
            entry.type,
            activeProductFormValues
        );

        if (updatedValues.length <= 0 || compareCurrentAndGivenValue(entry.id, activeProductFormId, updatedValues[0])) {
            continue;
        }

        numberOfUpdatedValues++;

        await store.dispatch('configurator/updateActiveProductFormValue', {
            pId: entry.id,
            value: updatedValues[0],
            productFormId: activeProductFormId,
        });
    }

    return Promise.resolve(numberOfUpdatedValues);
}

export async function runOptionsFunctions(optionsFunctions: OptionFunctionEntry[], activeProductFormId: number) {
    const activeProductFormValues = store.getters['configurator/activeProductForm'](activeProductFormId);
    let numberOfUpdatedValues = 0;

    for await (const entry of optionsFunctions) {
        const visibleOptionVIDs = extractAndRunOptionsFunction(
            entry.optionsFunction,
            activeProductFormValues,
            entry.optionsFunctionData
        ).split(';');

        const currentValue = store.getters['configurator/activeProductFormValue'](entry.id, activeProductFormId);

        if (
            currentValue == null ||
            visibleOptionVIDs.includes(currentValue) ||
            compareCurrentAndGivenValue(entry.id, activeProductFormId, visibleOptionVIDs[0])
        ) {
            continue;
        }
        numberOfUpdatedValues++;

        await store.dispatch('configurator/updateActiveProductFormValue', {
            pId: entry.id,
            value: visibleOptionVIDs[0],
            productFormId: activeProductFormId,
        });
    }

    return Promise.resolve(numberOfUpdatedValues);
}

export function getErrorFieldsMessages(errorFields: ChecklistField[], activeProductFormId: number) {
    const activeProductFormValues = store.getters['configurator/activeProductForm'](activeProductFormId);

    return errorFields
        .filter((errorField) => determineFieldVisibility(errorField.checklistVisibleFunction, activeProductFormValues))
        .filter(
            (errorField) =>
                errorField.checklistStringField !== null && errorField.checklistStringField.defaultValue !== null
        )
        .map((errorField) => {
            return {
                error: parseAndTransformStringWithDynamicValues(
                    (errorField.checklistStringField as ChecklistStringField).defaultValue as string,
                    (errorField.checklistStringField as ChecklistStringField).functions,
                    activeProductFormValues,
                    'function'
                ),
                severity: errorField.fieldType,
            };
        });
}

export function compareCurrentAndGivenValue(
    pId: string,
    activeProductFormId: number,
    newValue: string | number | null | boolean
) {
    const currentValue = store.getters['configurator/activeProductFormValue'](pId, activeProductFormId);

    return currentValue === newValue;
}

/*async function updateValidOfferItemsToSuccess(offerItems: OfferItem[]) {
    filterInvalidOfferItems(offerItems).forEach((offerItem) => {
        updateOfferItem(offerItem.id);
    });

    await Vue.nextTick();

    return Promise.resolve();
}*/

async function updateOfferItem(
    offerItemId: string,
    backendErrors = [],
    frontendErrors = [],
    priceList: PriceItem[] = [],
    activeProductFormId: string | null = null,
    type: string | null = null,
    productFormId: number | null
) {
    const newPrice = priceList.length > 0 ? priceList.splice(-1)[0].priceValue : null;
    await store.dispatch('configurator/updateOfferItem', {
        offerItemId,
        backendErrors,
        frontendErrors,
        newPrice,
        activeProductFormId,
        type,
        productFormId,
    });

    return Promise.resolve();
}

export async function calculateMaterialPrice(clientId: string | null, offerItemId: string) {
    // const activeProductFormValues = store.getters['configurator/activeProductForm'](activeProductFormId);
    let priceList = [];
    try {
        priceList = await store.dispatch('configurator/calculateMaterialPrice', {
            clientId,
            currencySymbol: '',
            offerItemId,
        });
    } catch (e) {
        const errorObj = e as ErrorOptions;

        if (errorObj.response && errorObj.response.status === 422) {
            if (errorObj.response.data.errors && errorObj.response.data.errors[0].code) {
                return Promise.reject({
                    error: errorObj.response?.data?.errors[0]?.title,
                    severity: 'abort',
                });
            }
            return Promise.reject({
                error: errorObj?.response?.data?.errors?.[0].title,
                severity: 'abort',
            });
        }
        return Promise.reject({
            error: errorObj.message ? errorObj.message : errorObj,
            severity: 'abort',
        });
    }

    return Promise.resolve(priceList);
}

export async function recalculateOfferItems(offerId: string) {
    const offerItems: OfferRecalculationErrorItem[] = store.getters['configurator/offerItemRecalculationErrors'];

    const payloads: MultipositionProduct[] = [];
    const promises: any = [];

    // Get payloads for all products we gonna add
    offerItems
        .filter((offerItem) => offerItem.activeProductFormId != null)
        .forEach(async (offerItem) => {
            // Send call for payload preparation and update payloads
            const promise = preparePayloadForOfferItems(offerItem);
            promises.push(
                promise.then((payload: any) => {
                    payloads.push(payload);
                })
            );
        });

    try {
        if (promises.length) {
            await Promise.all(promises);
        }

        await onUpdateItemsInOffer(
            payloads.filter((payload: MultipositionProduct) => payload.id),
            offerId
        );
    } catch (e) {
        return Promise.reject(e);
    }

    EventBus.$emit(EventBusEvents.updateProject);
    // to update selected offer in products
    EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);

    return Promise.resolve();
}

async function preparePayloadForOfferItems(offerItem: any) {
    const abortOfferItemErrors =
        offerItem.backendErrors.length > 0 || getOnlyAbortErrors(offerItem.frontendErrors).length > 0;

    const productFormValues: ChecklistFieldEntry[] = formatFormData(
        store.getters['configurator/activeProductForm'](offerItem.activeProductFormId) as ActiveProductFormValueObject
    );

    if (productFormValues == null) {
        return Promise.reject();
    }

    const checklistErrors = checklistFieldsWithErrors(offerItem);
    const errorsHaveValidWarranty = checkIferrorsHaveValidWarranty(checklistErrors);
    const offerItemId = offerItem != null ? offerItem.offerItemId : null;
    const offerItemEntity = OfferItemRepository.getById(offerItemId);

    const payload: any = {
        formData: productFormValues,
        hasErrors: abortOfferItemErrors,
        production: offerItemEntity?.production ?? null,
        offerItemType: offerItemEntity?.offerItemType ?? 'product',
        validWarranty: errorsHaveValidWarranty,
        activeProductFormId: offerItem.productFormId,
        connection: offerItemEntity?.connection ?? null,
    };

    if (offerItemId) {
        payload.id = offerItemId;
    }

    return Promise.resolve(payload);
}

function checkIferrorsHaveValidWarranty(checklistErrors: ChecklistField[]) {
    if (!checklistErrors?.length) {
        return true;
    }

    const invalidWarranty = checklistErrors.some((checklist: ChecklistField) => {
        if (checklist.checklistStringField) {
            return !checklist.checklistStringField.validWarranty;
        }
    });

    return !invalidWarranty;
}

async function onUpdateItemsInOffer(payloads: MultipositionProduct[], offerId: string) {
    try {
        await ConfiguratorService.updateBulkItemsInOffer(payloads, offerId);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

/**
 * Filters out warning errors from an array of frontend errors
 * @param frontendErrors - All errors on an offer item
 * @return An array with only abort errors
 */
export function getOnlyAbortErrors(frontendErrors: RecalculationError[]) {
    return frontendErrors.filter((error) => error.severity !== ErrorSeverities.WARNING);
}
