import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class PriceListRegionAPI {
    public static async getAll() {
        let priceListRegions;
        try {
            priceListRegions = await ApiController.get(ApiRoutes.priceListRegion.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return priceListRegions;
    }
}
