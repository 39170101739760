import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { ApiController } from '@/services/ApiController';
import Resource from '@/models/Resource';

export default class ResourceAPI {
    public static async getAll() {
        let getAllResources;

        try {
            getAllResources = (await ApiController.get(ApiRoutes.resource.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(getAllResources);
    }

    public static async getById(id: string) {
        let resource;

        try {
            resource = (await ApiController.get(ApiRoutes.resource.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(resource);
    }

    public static async create(resource?: Resource) {
        let newResource;

        const payload = {
            data: {
                type: 'resource',
                attributes: {
                    ...resource,
                },
            },
        };

        try {
            newResource = (await ApiController.post(ApiRoutes.resource.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newResource);
    }

    public static async update(resourceId: string, data: Resource) {
        let updatedResource;

        const payload = {
            data: {
                type: 'resource',
                attributes: {
                    description: data.description,
                    notes: data.notes,
                    title: data.title,
                },
                id: resourceId,
            },
        };

        try {
            updatedResource = (await ApiController.patch(
                ApiRoutes.resource.update(resourceId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedResource);
    }

    public static async delete(resourceId: string) {
        try {
            await ApiController.delete(ApiRoutes.resource.delete(resourceId));
        } catch (e) {
            return Promise.reject(e);
        }

        await Resource.delete(resourceId);

        return Promise.resolve();
    }
}
