import { Model } from '@vuex-orm/core';
import BankAccountAPI from '@/api/BankAccountAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class BankAccount extends Model {
    public static entity = 'bankAccount';

    public static fields() {
        return {
            id: this.string(null),
            accountNumber: this.string(''),
            bankName: this.string(''),
        };
    }

    public static async getById(labelId: string) {
        let bankAccount;
        try {
            bankAccount = await BankAccountAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: bankAccount.data,
        });
        return Promise.resolve(bankAccount);
    }

    public static async updateExisting(bankAccountId: string, bankAccountInfo: any) {
        let bankAccount;
        try {
            bankAccount = await BankAccountAPI.update(bankAccountId, bankAccountInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: bankAccount.data,
        });
        return Promise.resolve(bankAccount);
    }

    public static async createNew(bankAccountInfo: any) {
        let bankAccount;
        try {
            bankAccount = await BankAccountAPI.create(bankAccountInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: bankAccount.data,
        });
        return Promise.resolve(bankAccount);
    }

    public static async deleteExisting(bankAccountId: string) {
        try {
            await BankAccountAPI.delete(bankAccountId);
        } catch (e) {
            return Promise.reject(e);
        }
        await BankAccount.delete(bankAccountId);
        return Promise.resolve();
    }

    public static async getAll() {
        let bankAccounts;

        if (ss.has('bankAccounts')) {
            bankAccounts = ss.getAndParse('bankAccounts');
        } else {
            try {
                const fetchedBankAccounts = await BankAccountAPI.getAll();
                bankAccounts = fetchedBankAccounts.data;

                ss.stringifyAndSave('bankAccounts', bankAccounts);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: bankAccounts,
        });

        return Promise.resolve(bankAccounts);
    }

    public id!: string;
    public accountNumber!: string;
    public bankName!: string;
}
