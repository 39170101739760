import { Model } from '@vuex-orm/core';
import ProjectSearchAPI from '@/api/ProjectSearchAPI';
import ProjectSearchRepository from '@/repositories/ProjectSearchRepository';

export default class ProjectSearch extends Model {
    public static entity = 'ProjectSearch';

    public static fields() {
        return {
            id: this.string(''),
            typeSpecificId: this.attr(null),
            clientName: this.attr(null),
            clientId: this.string(''),
            orderNumber: this.attr(null),
            createdAt: this.attr(null),
            connection: this.attr(null),
            clientPhone: this.attr(null),
            clientStreetNameAndNumber: this.attr(null),
            offerName: this.attr(null),
            state: this.attr(null),
            assignedUserName: this.attr(null),
            contactPersonPhone: this.attr(null),
            price: this.attr(null),
            assignedUserId: this.string(''),
            clientCity: this.attr(null),
            manufactureDate: this.attr(null),
            projectLabelColor: this.attr(null),
            projectLabelName: this.attr(null),
            leadNumberOfProducts: this.attr(null),
            clientPoNumber: this.attr(null),
            orderStatusName: this.attr(null),
            orderStatusId: this.string(''),
            projectInternalOrders: this.attr(null),
            productsInfo: this.attr(null),
        };
    }

    public static async getAll(query: string = '') {
        let Projects;

        try {
            Projects = await ProjectSearchAPI.getAll(query);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: Projects.data,
            insertOrUpdate: ['projectInternalOrders'],
        });

        const sortedProjectIds = Projects.data.map((project: any) => String(project.id));

        await this.store().dispatch('tableData/updateProjects', {
            sortedProjectIds,
            projects: ProjectSearchRepository.getAll(),
        });

        return Promise.resolve();
    }

    public id!: string;
    public typeSpecificId!: string;
    public clientName!: string;
    public clientId!: number;
    public orderNumber!: string;
    public createdAt!: string;
    public connection!: string;
    public clientPhone!: string;
    public clientStreetNameAndNumber!: string;
    public offerName!: string;
    public state!: string;
    public assignedUserName!: string;
    public contactPersonPhone!: string;
    public price!: number;
    public assignedUserId!: number;
    public clientCity!: string;
    public manufactureDate!: string;
    public projectLabelColor!: string;
    public projectLabelName!: string;
    public leadNumberOfProducts!: number;
    public clientPoNumber!: string;
    public orderStatusName!: string;
    public orderStatusId!: string;
    public projectInternalOrders!: any[];
    public productsInfo!: any[];
}
