import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import PriceTypeAPI from '@/api/PriceTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class PriceType extends Model {
    public get getName() {
        // @ts-ignore
        return this.name;
    }

    public static entity = 'priceType';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            ingeniousId: this.string(''),
        };
    }

    public static async getAll() {
        let priceTypes;

        if (ss.has('priceTypes')) {
            priceTypes = ss.getAndParse('priceTypes');
        } else {
            try {
                const fetchedPriceTypes = (await PriceTypeAPI.getAll()) as AxiosResponse;
                priceTypes = fetchedPriceTypes.data;

                ss.stringifyAndSave('priceTypes', priceTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: priceTypes,
        });

        return Promise.resolve(priceTypes);
    }
    public id!: string;
}
