
import { CommonDiscountGroupNames } from '@/enums/components/Discount/CommonDiscountGroupNames';
import { fetchAndTransformMaterials } from '@/helpers/Discount/DiscountHelper';
import ProductGroupRepository from '@/repositories/ProductGroupRepository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

@Component({
    name: 'DiscountInformationRow',
})
export default class DiscountInformationRow extends Vue {
    @Prop({ required: true }) private value!: any;
    private cachedValue: any = {};
    private debounceTimer: any = null;

    private materialDataSource: null | any[] = null;

    private get discountCategories() {
        return [
            {
                value: CommonDiscountGroupNames.PRODUCT,
                text: this.$t(CommonDiscountGroupNames.PRODUCT),
            },
            {
                value: CommonDiscountGroupNames.PRODUCT_GROUP,
                text: this.$t(CommonDiscountGroupNames.PRODUCT_GROUP),
            },
        ];
    }

    private commonDiscountGroupNames = CommonDiscountGroupNames;

    private get discountCategoryText() {
        if (!this.value.discountCategory) {
            return '';
        }
        return this.discountCategories.find((i) => i.value === this.value.discountCategory)?.text || '';
    }

    private get entityOidText() {
        if (this.value.productGroup) {
            return this.productGroups.find((i) => i.id === this.value.productGroup)?.name || '';
        }
        if (this.value.discountedEntityOid && this.materialDataSource) {
            return this.materialDataSource.find((i) => i.value === this.value.discountedEntityOid)?.text || '';
        }
        return '';
    }

    private get productGroups() {
        return ProductGroupRepository.getAll();
    }

    private onEdit() {
        this.value.isEditing = true;
        this.isEditingChanged();

        this.cachedValue = { ...this.value };
    }

    private confirmChanges() {
        this.value.isEditing = false;
        this.isEditingChanged();
        this.$emit('input', this.value);
        this.$emit('update');
    }

    private cancelChanges() {
        this.cachedValue.isEditing = false;
        this.value.isEditing = false;
        this.isEditingChanged();
        this.$emit('input', this.cachedValue);
        this.$emit('cancel');
    }

    private isEditingChanged() {
        this.$emit('isEditingChanged');
    }

    private debouncedUpdateMaterials(value: string) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            this.updateLocallyAndCacheMaterials(value);
        }, 300);
    }

    private handleSelect(value: string) {
        this.value.discountedEntityOid = value;
    }

    private async fetchMaterials() {
        if (this.value.discountCategory === 'PRODUCT_GROUP' || !ss.has('materials')) {
            return;
        }

        this.materialDataSource = ss.getAndParse('materials');

        // Check if cached values contain initial value and fetch it if not
        const isInitialValueCached = this.materialDataSource?.some((currentObj) => {
            return this.value.discountedEntityOid === currentObj.value;
        });

        if (!isInitialValueCached) {
            this.updateLocallyAndCacheMaterials(this.value.name);
        }
    }

    private async updateLocallyAndCacheMaterials(name = '') {
        const materials = await fetchAndTransformMaterials(name);

        this.materialDataSource = [...materials];
        ss.updateUniqueArray('materials', materials);
    }

    private mounted() {
        this.fetchMaterials();
    }

    @Watch('value.discountCategory')
    private handleDiscountCategoryChange(category: string | null) {
        if (category) {
            if (category === this.commonDiscountGroupNames.PRODUCT) {
                this.value.productGroup = null;
            }

            if (category === this.commonDiscountGroupNames.PRODUCT_GROUP) {
                this.value.discountedEntityOid = null;
            }
        }
    }
}
