
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductCatalogueRepository from '@/repositories/ProductCatalogueRepository';
import { MaterialTypes } from '@/enums/global/MaterialTypes';
import OfferItem from '@/models/OfferItem';
import { MaterialProperties } from '@/interfaces/general/MaterialProperties';
import ConfiguratorService from '@/services/ConfiguratorService';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import OfferItemRepository from '@/repositories/OfferItemRepository';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { formatFormData } from '@/helpers/FormDataHelper';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { capitalizeWord } from '@/helpers/TranslationHelper';
import { ProductTypes } from '@/enums/global/ProductTypes';

@Component({
    name: 'AddMaterialSlot',
})
export default class AddMaterialSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private materialTypes = MaterialTypes;
    private materialProperties: MaterialProperties = {
        q: null,
        x: null,
        y: null,
    };
    private offerItemDisplayRowNumber: null | number = null;
    private production = true;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get productCatalogue() {
        return ProductCatalogueRepository.getById(this.popupData.materialId);
    }

    private get materialType() {
        if (this.productCatalogue == null) {
            return null;
        }
        switch (this.productCatalogue.getProductType) {
            case 0:
                return MaterialTypes.UNIT;
            case 1:
            case 2:
                return MaterialTypes.METER;
            case 3:
            case 4:
                return MaterialTypes.METER_SQUARED;
            default:
                return null;
        }
    }

    private capitalize(word: string) {
        return capitalizeWord(word);
    }

    private checkAndShowErrors() {
        const errorMessages = [];
        if (this.materialProperties.q === null || this.materialProperties.q === '') {
            errorMessages.push(this.$t('Molimo popunite količinu') as string);
        }

        if (this.materialType === MaterialTypes.METER) {
            if (this.materialProperties.x === null || this.materialProperties.x === '') {
                errorMessages.push(this.$t('Molimo popunite x') as string);
            }
        }

        if (this.materialType === MaterialTypes.METER_SQUARED) {
            if (this.materialProperties.x === null || this.materialProperties.x === '') {
                errorMessages.push(this.$t('Molimo popunite x') as string);
            }
            if (this.materialProperties.y === null || this.materialProperties.y === '') {
                errorMessages.push(this.$t('Molimo popunite y') as string);
            }
        }
        if (errorMessages.length > 0) {
            errorMessages.forEach((errorMessage) => {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: errorMessage,
                });
            });
            return false;
        }
        return true;
    }

    private async onAddMaterialToOffer() {
        let validOfferId;

        if (!this.checkAndShowErrors()) {
            return;
        }

        this.loadingOverlay.start();

        try {
            validOfferId = await ConfiguratorService.getValidOfferId(
                this.popupData.projectId,
                this.popupData.offerId,
                this.popupData.clientsPaymentTypeId
            );
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.updateOrCreateMaterial(validOfferId);
    }

    private async updateOrCreateMaterial(offerId: string) {
        const formData = formatFormData(this.materialProperties as ActiveProductFormValueObject);
        try {
            if (this.popupData.offerItemId == null) {
                await OfferItem.createMaterial(
                    formData,
                    this.popupData.materialId,
                    offerId,
                    this.offerItemDisplayRowNumber,
                    this.production
                );
            } else {
                await OfferItem.updateExisting(
                    formData,
                    String(this.popupData.offerItemId),
                    ProductTypes.MATERIAL,
                    false,
                    null,
                    this.offerItemDisplayRowNumber,
                    this.production
                );
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();

            return;
        }
        this.onFinalizeMaterialAction();
    }

    private onFinalizeMaterialAction() {
        EventBus.$emit(EventBusEvents.updateProject);
        EventBus.$emit(EventBusEvents.closePopup);
        this.loadingOverlay.stop();
    }

    private mounted() {
        if (this.popupData.offerItemId == null) {
            return;
        }

        const offerItem = OfferItemRepository.getById(this.popupData.offerItemId);

        if (offerItem == null) {
            return;
        }

        const offerItemWidth = offerItem.offerItemFormData.find((entry: ChecklistFieldEntry) => entry.id === 'x').value;
        const offerItemHeight = offerItem.offerItemFormData.find((entry: ChecklistFieldEntry) => entry.id === 'y')
            .value;
        const offerItemQuantity = offerItem.offerItemFormData.find((entry: ChecklistFieldEntry) => entry.id === 'q')
            .value;

        this.materialProperties.q = offerItemQuantity;
        this.materialProperties.x = offerItemWidth;
        this.materialProperties.y = offerItemHeight;
        this.offerItemDisplayRowNumber = offerItem.connection;
        this.production = offerItem.production;
    }
}
