import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import ILocalState from './stateInterface';

const initState: ILocalState = {
    projects: [],
    nextSteps: [],
    clients: [],
    projectNavigationSettings: {
        sortedProjectsObject: {},
        isFirstPageFetched: false,
        isLastPageFetched: false,
        userId: '',
        entityType: '',
    },
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
