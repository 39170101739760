import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';
import AddressConfig from '@/models/interfaces/Address';
import { ReportData } from '@/interfaces/components/Statistics/CMSReport';

export default class ReportAPI {
    public static async getById(id: string) {
        let report;

        try {
            report = (await ApiController.get(ApiRoutes.reports.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(report);
    }

    public static async getAll() {
        let reports;

        try {
            reports = (await ApiController.get(ApiRoutes.reports.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(reports);
    }

    public static async create(reportData: ReportData) {
        let report;

        const request = {
            data: {
                type: 'statistic_reports',
                attributes: {
                    type: reportData.type,
                    name: reportData.name,
                    isDefault: reportData.isDefault,
                },
                relationships: {
                    users: {
                        data: [
                            ...reportData.usersData[0].users.map((userId: string) => {
                                return {
                                    type: 'users',
                                    id: userId,
                                };
                            }),
                        ],
                    },
                },
            },
        };

        try {
            report = (await ApiController.post(ApiRoutes.reports.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(report);
    }

    public static async update(reportId: string, reportData: ReportData) {
        let report;

        const request = {
            data: {
                type: 'statistic_reports',
                id: reportId,
                attributes: {
                    type: reportData.type,
                    name: reportData.name,
                    isDefault: reportData.isDefault,
                },
                relationships: {
                    users: {
                        data: [
                            ...reportData.usersData[0].users.map((userId: string) => {
                                return {
                                    type: 'users',
                                    id: userId,
                                };
                            }),
                        ],
                    },
                },
            },
        };

        try {
            report = (await ApiController.patch(
                ApiRoutes.reports.update(reportId),
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(report);
    }

    public static async delete(id: string) {
        let report;

        try {
            report = (await ApiController.delete(ApiRoutes.reports.delete(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
