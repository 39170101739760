import { Model } from '@vuex-orm/core';
import offerTitleAPI from '@/api/OfferTitleAPI';
import OfferTitleAPI from '@/api/OfferTitleAPI';
import { OfferItemRowNumber } from '@/interfaces/components/ProductsInOffer/OfferItemRowNumber';
import Offer from '@/models/Offer';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

export default class OfferTitle extends Model {
    public static entity = 'offerTitle';

    public static fields() {
        return {
            id: this.string(null),
            description: this.string(''),
            rowNumber: this.number(0),
            offer_id: this.attr(null),
            offer: this.belongsTo(Offer, 'offer_id'),
        };
    }

    public static async getAll() {
        let offerTitles;
        try {
            offerTitles = await offerTitleAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerTitles.data,
        });
        return Promise.resolve(offerTitles);
    }

    public static async getById(offerTitleId: string) {
        let offerTitle;
        try {
            offerTitle = await offerTitleAPI.getById(offerTitleId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerTitle.data,
        });
        return Promise.resolve(offerTitle);
    }

    public static async updateExisting(offerTitleId: string, offerTitleInfo: any) {
        let offerTitle;
        try {
            offerTitle = await offerTitleAPI.update(offerTitleId, offerTitleInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerTitle.data,
        });
        return Promise.resolve(offerTitle);
    }

    public static async createNew(offerTitleInfo: any) {
        let offerTitle;
        try {
            offerTitle = await offerTitleAPI.create(offerTitleInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: offerTitle.data,
            insertOrUpdate: ['offer', 'offerTitles'],
        });

        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
        return Promise.resolve(offerTitle);
    }

    public static async deleteExisting(offerTitleId: string) {
        try {
            await offerTitleAPI.delete(offerTitleId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OfferTitle.delete(offerTitleId);
        return Promise.resolve();
    }

    public static async updateRowNumbers(offerItemRowNumbers: OfferItemRowNumber[]) {
        try {
            await OfferTitleAPI.updateRowNumbers(offerItemRowNumbers);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async duplicateExisting(offerTitleId: string) {
        let offerTitle;
        try {
            offerTitle = await OfferTitleAPI.duplicateExisting(offerTitleId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offerTitle.data,
        });

        return Promise.resolve();
    }
    public id!: string;
    public description!: string;
    public rowNumber!: number;
    public code!: string;
    public offer!: Offer;
}
