import { Model } from '@vuex-orm/core';
import { ProductFormAPI } from '@/api/ProductFormAPI';
import Checklist from '@/models/Checklist';
import Image from '@/models/Image';

export default class ProductForm extends Model {
    public static entity = 'productForm';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            itemNumber: this.string(''),
            disabled: this.string(''),
            commodityGroup: this.string(''),
            productGroup: this.string(''),
            isArchived: this.boolean(false),
            priceListVersion: this.string(''),
            checklistVersion: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            uuid: this.string(''),
            checklists_id: this.attr(null),
            checklists: this.belongsTo(Checklist, 'checklists_id'),
            isCustom: this.boolean(false),
            gallery_ids: this.attr([]),
            gallery: this.hasManyBy(Image, 'gallery_ids'),
        };
    }

    public static async getAll() {
        let productForms;
        try {
            productForms = await ProductFormAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: productForms.data,
            insertOrUpdate: ['checklistSegment', 'checklist', 'checklistField'],
        });
        return Promise.resolve();
    }

    public static async getById(productFormId: string) {
        let productForm;
        try {
            productForm = await ProductFormAPI.getById(Number(productFormId));
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productForm.data,
            insertOrUpdate: ['checklistSegment', 'checklist', 'checklistField'],
        });
        return Promise.resolve(productForm);
    }

    public static async getImageByFormId(productFormId: string) {
        let productForm;
        try {
            productForm = await ProductFormAPI.getImageByFormId(Number(productFormId));
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productForm.data,
            insertOrUpdate: ['gallery'],
        });
        return Promise.resolve(productForm);
    }

    public static async updateExisting(productFormId: string, productForm: any, locale?: string | null) {
        let updatetProduct;
        if (productFormId == null) {
            return Promise.reject(new Error('You must set a product system ID (additionalEntity)'));
        }

        try {
            updatetProduct = await ProductFormAPI.updateExisting(
                // productId,
                productFormId,
                productForm,
                locale
            );
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: updatetProduct.data,
            insertOrUpdate: ['checklistSegment', 'checklist', 'checklistField'],
        });
        return Promise.resolve(); // updatetProduct.data
    }

    public name!: string;
    public itemNumber!: string;
    public disabled!: boolean;
    public directory!: string;
    public commodityGroup!: string;
    public productGroup!: string;
    public priceListVersion!: string;
    public checklistVersion!: string;
    public checklists!: Checklist;
    public createdAt!: string;
    public updatedAt!: string;
    public uuid!: string;
    public id!: string;
    public isArchived!: boolean;
    public isCustom!: boolean;
    public gallery!: Image[] | null;
}
