import { Model } from '@vuex-orm/core';
import DashboardReportAPI from '@/api/DashboardReportAPI';
import { AxiosError, AxiosResponse } from 'axios';
import { ReportTimeFrame } from '@/interfaces/components/Statistics/ReportTimeFrame';
export default class DashboardReport extends Model {
    public static entity = 'dashboardReport';

    public static fields() {
        return {
            //
        };
    }

    public static async createNewReport(reportData: any) {
        let report;

        try {
            report = await DashboardReportAPI.createReport(reportData);
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: dashboard.data,
        //     insertOrUpdate: ['dashboard'],
        // });
        return Promise.resolve(report);
    }

    public static async updateExistingReport(
        reportData: any,
        dashboardId: string,
    ): Promise<void | AxiosError> {
        try {
            await DashboardReportAPI.updateReport(reportData, dashboardId);
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: dashboard.data,
        //     insertOrUpdate: ['dashboard'],
        // });
        return Promise.resolve();
    }

    public static async getReport(reportId: string, timeFrame: ReportTimeFrame, userId: string) {
        let report;

        try {
            report = await DashboardReportAPI.getReport(reportId, timeFrame, userId);
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: dashboard.data,
        //     insertOrUpdate: ['dashboard'],
        // });

        return Promise.resolve(report);
    }

    public static async deleteExistingReport(reportId: string): Promise<void | AxiosError> {
        try {
            await DashboardReportAPI.deleteReport(reportId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
}
