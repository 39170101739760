import Address from '@/models/Address';
import Lead from '@/models/Lead';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { notification } from 'ant-design-vue';
import i18n from '@/i18n';
import AddressConfig from '@/models/interfaces/Address';
import { AddressSubmitOptions } from '@/interfaces/components/projectNew/AddressSubmitOptions';
import { AddressParentRelationshipNames } from '@/enums/components/ProjectNew/AddressParentRelationshipNames';
import Offer from '@/models/Offer';
import { AxiosError } from 'axios';

/**
 * Formats the given address
 * @param address - The  offer that was selected in the offer list dropdown
 * @return The formatted address or '-' if empty
 */
export function formatAddress(address: Address | null) {
    if (address == null) {
        return '-';
    }

    const addressArray = [
        address?.streetNameAndNumber,
        address?.building,
        `${address?.poNumber} ${address?.city}`,
        address?.country?.name,
    ];

    return addressArray
        .map((part) => (part ? part.trim() : part))
        .filter((part) => part != null && part !== '')
        .join(', ');
}

/**
 * Removes the address relationship from the given parent relationship name and id
 * @param parentRelationshipName
 * @param parentRelationshipId
 * @return An empty promise
 */
export async function removeAddressFromAppropriateParent(parentRelationshipName: string, parentRelationshipId: string) {
    switch (parentRelationshipName) {
        case AddressParentRelationshipNames.OBJECT_LEADS:
            await Lead.deleteObjectAddress(parentRelationshipId);
            break;
        case AddressParentRelationshipNames.DELIVERY_OFFERS:
            await Offer.deleteDeliveryAddress(parentRelationshipId);
            break;
        case AddressParentRelationshipNames.BILLING_OFFERS:
            await Offer.deleteBillingAddress(parentRelationshipId);
            break;
    }

    return Promise.resolve();
}

/**
 * Deletes the address relationship from the parent and the address entity itself
 * It also shows the status notification
 * @param addressId - Address id to be deleted
 * @param parentRelationshipName
 * @param parentRelationshipId
 * @return An empty promise
 */
export async function deleteAddressEntity(
    addressId: string,
    parentRelationshipName: string,
    parentRelationshipId: string
) {
    try {
        await removeAddressFromAppropriateParent(parentRelationshipName, parentRelationshipId);
        await Address.deleteExisting(addressId);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }

    notification.success({
        message: i18n.t('Promjene uspješne!'),
        description: '',
    });

    return Promise.resolve();
}

/**
 * Runs the validation on the given form entity from Antd design
 * Submits an API request if everything is fine
 * @param options - All options necessary to create an address entity
 * @return A promise
 */
export async function validateAndSubmitAddress(options: AddressSubmitOptions) {
    return new Promise((resolve, reject) => {
        options.form.validateFieldsAndScroll(async (err: Error[], values: AddressConfig) => {
            let address;

            try {
                validateAddressForm(err, options.parentRelationshipPayload);
                address = await createOrUpdateAddress(values, options);
            } catch (e) {
                return reject(e);
            }

            return resolve(address.data.id);
        });
    });
}

/**
 * Throws an error if there are errors in the form or if the parent relationship payload is not defined
 * @param errors - An array of errors from the form
 * @param parentRelationshipPayload - The parent relationship payload object
 * @return A promise
 */
export function validateAddressForm(errors: Error[], parentRelationshipPayload: null | object) {
    if (errors || parentRelationshipPayload == null) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}

/**
 * Sends an API request with the correct payload for creating or updating the address entity
 * The creation or updating is based on the given address id in the options object
 * @param values - The current form values
 * @param options - All options necessary to create an address entity
 * @return A promise with the updated/created address
 */
export async function createOrUpdateAddress(values: AddressConfig, options: AddressSubmitOptions) {
    let address;

    const valuesWithParentRelationship = {
        ...values,
        parentRelationshipName: options.parentRelationshipName,
        parentRelationshipPayload: options.parentRelationshipPayload as object,
    };

    try {
        if (options.addressId == null) {
            address = await Address.createNewAndSaveLocally(valuesWithParentRelationship);
        } else {
            address = await Address.updateExistingAndSaveLocally(options.addressId, valuesWithParentRelationship);
        }
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve(address);
}
