export function sortStrings(a: string | null, b: string | null, isAscending: boolean) {
    a = typeof a === 'string' ? a.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() : a;
    b = typeof b === 'string' ? b.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() : b;
    // equal items sort equally
    if (a === b) {
        return 0;
    }
    // nulls sort after anything else
    else if (a === null) {
        return 1;
    } else if (b === null) {
        return -1;
    }
    // otherwise, if we're ascending, lowest sorts first

    else if (isAscending) {
        return a < b ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
        return a < b ? 1 : -1;
    }

}

export function sortNumbers(a: number, b: number) {
    if (a === b) {
        return 0;
    } else if (a === null || isNaN(a)) {
        return -1;
    } else if (b === null || isNaN(b)) {
        return 1;
    }
    return a - b;
}

export function sortDates(a: string, b: string, isAscending: boolean) {
    const key1 = new Date(a);
    const key2 = new Date(b);

    if (key1 === key2) {
        return 0;
    } else if (a === null) {
        return 1;
    } else if (b === null) {
        return -1;
    } else if (isAscending) {
        return key1 < key2 ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
        return key1 < key2 ? 1 : -1;
    }
}

export function compareArrays(a: any, b: any) {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; ++i) {
        if (!Object.is(a[i], b[i])) {
            return false;
        }
    }

    return true;
}
