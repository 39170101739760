import { Model } from '@vuex-orm/core';
import CustomerHistoryAPI from '@/api/CustomerHistoryAPI';

export default class CustomerHistory extends Model {
    public static entity = 'customerHistory';

    public static fields() {
        return {
            id: this.string(null),
            createdAt: this.string(null),
            message: this.string(null),
        };
    }

    public static async getAll() {
        let customerHistory;

        try {
            const fetchedLogs = await CustomerHistoryAPI.getAll();
            customerHistory = fetchedLogs.data;
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: customerHistory,
        });
        return Promise.resolve(customerHistory);
    }

    public id!: string;
    public createdAt!: string;
    public message!: string;
}
