
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { UploadFile } from 'ant-design-vue/types/upload';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ContactSlot',
})
export default class ContactSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;

    private description = '';
    private attachments: UploadFile[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private onClose() {
        EventBus.$emit(EventBusEvents.closeContactPopup);
    }

    private async beforeUpload(file: UploadFile) {
        this.attachments.push(file);
        return false;
    }

    private onRemove(file: UploadFile) {
        const index = this.attachments.indexOf(file);
        const newFileList = this.attachments.slice();
        newFileList.splice(index, 1);
        this.attachments = newFileList;
    }

    private async onSubmitForm() {
        this.loadingOverlay.start();

        const formData = new FormData();
        formData.append('message', this.description);

        this.attachments.forEach((file: any, index: number) => {
            formData.append('file${index}', file);
        });

        try {
            await ApiController.post(ApiRoutes.inquiry, formData);
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            this.loadingOverlay.stop();
            return;
        }

        EventBus.$emit(EventBusEvents.closeContactPopup);
        this.loadingOverlay.stop();
        this.$notification.success({
            message: this.$t('Upit je uspješno poslan') as string,
            description: '',
        });
    }
}
