import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import { JwtData } from '@/interfaces/general/JwtData';

const mutations: MutationTree<ILocalState> = {
    setJwtData(state, payload) {
        state.jwtData = {
            ...payload,
            userRights: state.jwtData.userRights,
        };
    },

    setCurrentUserGroup(state, payload) {
        state.currentUserGroup = payload;
    },

    setUserRights(state, payload) {
        (state.jwtData as JwtData).userRights = payload;
    },
};

export default mutations;
