import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import OfferConfigurationConfig from '@/models/interfaces/OfferConfiguration';
import i18n from '@/i18n';
import { OfferPriceType } from '@/enums/global/OfferPriceType';

export default class OfferConfigurationAPI {
    public static async getAll(getOnlyUnarchived: boolean) {
        let offerConfigurations;

        try {
            offerConfigurations = await ApiController.get(getOnlyUnarchived ?
                ApiRoutes.offerConfiguration.getUnarchived : ApiRoutes.offerConfiguration.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerConfigurations);
    }

    public static async getById(offerConfigurationId: string) {
        let offerConfiguration;

        try {
            offerConfiguration =
                await ApiController.get(ApiRoutes.offerConfiguration.getById(offerConfigurationId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerConfiguration);
    }

    public static async update(offerConfigurationId: string, offerConfigurationConfig: OfferConfigurationConfig) {
        let offerConfiguration;
        const request = {
            data: {
                type: 'offer_configurations',
                id: offerConfigurationId,
                attributes: {
                    name: offerConfigurationConfig.name, showName: offerConfigurationConfig.showName,
                    showProductDetails: offerConfigurationConfig.showProductDetails,
                    showPricelists: offerConfigurationConfig.showPricelists,
                    showImages: offerConfigurationConfig.showImages,
                    showDifferences: offerConfigurationConfig.showDifferences,
                    showDiscounts: offerConfigurationConfig.showDiscounts,
                    showProductImageOnRight: offerConfigurationConfig.showProductImageOnRight,
                    showImageOnce: offerConfigurationConfig.showImageOnce,
                    showPrice: offerConfigurationConfig.showPrice,
                    showUpn: offerConfigurationConfig.showUpn,
                    showPurchasePrice: offerConfigurationConfig.printPrice === OfferPriceType.PURCHASE_PRICE,
                    showRecommendedPrice: offerConfigurationConfig.printPrice === OfferPriceType.RECOMMENDED_PRICE,
                },
                relationships: {},
            },
        };

        try {
            this.checkForForbiddenConfigurations(offerConfigurationConfig);
        } catch (e) {
            throw e;
        }

        if (offerConfigurationConfig.bankAccount) {
            request.data.relationships = {
                bankAccount: {
                    data: {
                        type: 'bank_accounts',
                        id: offerConfigurationConfig.bankAccount.id,
                    },
                },
            };
        }


        if (offerConfigurationConfig.currency) {
            // @ts-ignore
            request.data.relationships.currency = {
                data: {
                    type: 'currencies',
                    id: offerConfigurationConfig.currency.id,
                },
            };
        }

        try {
            offerConfiguration =
                await ApiController.patch(
                    ApiRoutes.offerConfiguration.update(offerConfigurationId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerConfiguration);
    }

    public static async archive(offerConfigurationId: string) {
        let offerConfiguration;
        const request = {
            data: {
                type: 'offer_configurations',
                id: offerConfigurationId,
                attributes: {
                    isArchived: true,
                },
            },
        };

        try {
            offerConfiguration =
                await ApiController.patch(
                    ApiRoutes.offerConfiguration.update(offerConfigurationId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerConfiguration);
    }

    public static async create(offerConfigurationConfig: OfferConfigurationConfig) {
        let offerConfiguration;
        const request = {
            data: {
                type: 'offer_configurations',
                attributes: {
                    name: offerConfigurationConfig.name,
                    showName: offerConfigurationConfig.showName,
                    showProductDetails: offerConfigurationConfig.showProductDetails,
                    showPricelists: offerConfigurationConfig.showPricelists,
                    showImages: offerConfigurationConfig.showImages,
                    showDifferences: offerConfigurationConfig.showDifferences,
                    showProductImageOnRight: offerConfigurationConfig.showProductImageOnRight,
                    showImageOnce: offerConfigurationConfig.showImageOnce,
                    showDiscounts: offerConfigurationConfig.showDiscounts,
                    showPrice: offerConfigurationConfig.showPrice,
                    showUpn: offerConfigurationConfig.showUpn,
                    showPurchasePrice: offerConfigurationConfig.printPrice === OfferPriceType.PURCHASE_PRICE,
                    showRecommendedPrice: offerConfigurationConfig.printPrice === OfferPriceType.RECOMMENDED_PRICE,
                },
                relationships: {},
            },
        };

        try {
            this.checkForForbiddenConfigurations(offerConfigurationConfig);
        } catch (e) {
            throw e;
        }

        if (offerConfigurationConfig.bankAccount) {
            request.data.relationships = {
                bankAccount: {
                    data: {
                        type: 'bank_accounts',
                        id: offerConfigurationConfig.bankAccount.id,
                    },
                },
            };
        }

        if (offerConfigurationConfig.currency) {
            // @ts-ignore
            request.data.relationships.currency = {
                data: {
                    type: 'currencies',
                    id: offerConfigurationConfig.currency.id,
                },
            };
        }

        try {
            offerConfiguration =
                await ApiController.post(
                    ApiRoutes.offerConfiguration.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerConfiguration);
    }

    public static async duplicate(offerConfigurationId: string) {

        try {
            await ApiController.post(ApiRoutes.offerConfiguration.duplicate(offerConfigurationId), {});
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async delete(offerConfigurationId: string) {

        try {
            await ApiController.delete(ApiRoutes.offerConfiguration.delete(offerConfigurationId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private static checkForForbiddenConfigurations(offerConfigurationConfig: OfferConfigurationConfig) {
        if (offerConfigurationConfig.showImages && offerConfigurationConfig.showProductImageOnRight) {
            throw new Error(i18n.t('Morate odabrati samo jednu opciju za prikaz slike') as string);
        }

        if (offerConfigurationConfig.showUpn && offerConfigurationConfig.bankAccount == null) {
            throw new Error(i18n.t('Bankovni račun mora biti odabran') as string);
        }

    }
}
