
import { Vue, Component, Prop } from 'vue-property-decorator';
import ConfiguratorService from '@/services/ConfiguratorService';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { formatFormData } from '@/helpers/FormDataHelper';
import OfferItem from '@/models/OfferItem';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { JsonAPIError } from '@/interfaces/general/JsonAPIError';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ProductTypes } from '@/enums/global/ProductTypes';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';

@Component({
    name: 'AddOfferItem',
})
export default class AddOfferItem extends Vue {
    @Prop({ required: true })
    private productFormValues!: ActiveProductFormValueObject | null;
    @Prop({ required: true }) private isEditMode!: boolean;
    @Prop({ default: false }) private production!: boolean;
    @Prop({ default: null }) private connection!: number | null;
    @Prop({ required: true }) private checklistFieldErrorsExist!: boolean;
    @Prop({ required: true }) private formFieldErrorsExist!: boolean;
    @Prop({ required: true }) private offerItemId!: string | null;
    @Prop({ required: true }) private paymentTypeId!: string | null;
    @Prop({ required: true }) private offerItemHasErrors!: boolean;
    @Prop({ required: true }) private checklistErrors!: ChecklistField[];
    @Prop ({ required: true }) private productFormId!: number;
    
    private isAllowedToCreateOffer = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private eventFinishedCallback: (() => void) | null = null;
    private eventFinished: boolean = false;

    private get allowErrors() {
        return this.offerItemHasErrors ? false : null;
    }

    private get errorsHaveValidWarranty() {
        if (!this.checklistErrors?.length) {
            return true;
        }

        const invalidWarranty = this.checklistErrors.some((checklist: ChecklistField) => {
            if (checklist.checklistStringField) {
                return !checklist.checklistStringField.validWarranty;
            }
        });

        return !invalidWarranty;
    }

    private async addProductToOffer() {
        this.loadingOverlay.start();
        this.eventFinishedCallback = null;
        this.$emit(EventBusEvents.validateFormFields);

        await new Promise<void>((resolve) => {
            this.eventFinishedCallback = resolve;

            if (this.eventFinished) {
                this.eventFinished = false;
                resolve();
            }
        });

        if (this.formFieldErrorsExist) {
            this.loadingOverlay.stop();
            return;
        }

        const projectId = this.$route.query.projectId as string;
        const routeOfferId = this.$route.query.offerId as string;
        let validOfferId;
        try {
            validOfferId = await ConfiguratorService.getValidOfferId(projectId, routeOfferId, this.paymentTypeId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }

        const formData: Array<{ id: string; value: ConfiguratorValue }> = formatFormData(
            this.productFormValues as ActiveProductFormValueObject
        );

        try {
            if (!this.isEditMode) {
                // tslint:disable-next-line:max-line-length
                await OfferItem.createNew(
                    formData,
                    String(validOfferId),
                    this.checklistFieldErrorsExist,
                    this.connection,
                    this.production,
                    this.errorsHaveValidWarranty,
                    this.productFormId,
                );
            } else {
                await OfferItem.updateExisting(
                    formData,
                    String(this.offerItemId),
                    ProductTypes.PRODUCT,
                    this.checklistFieldErrorsExist,
                    this.productFormId,
                    this.connection,
                    this.production,
                    this.allowErrors,
                    this.errorsHaveValidWarranty,                    
                );
            }
        } catch (e) {
            (e as any).response.data.errors.forEach((error: JsonAPIError) => {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: error.title ? error.title : (e as any).message,
                });
            });
            this.loadingOverlay.stop();
            return;
        }

        EventBus.$emit(EventBusEvents.changesInDataMade, { state: false });

        this.$router.push({
            name: RouteNames.project,
            params: { id: projectId },
            query: {
                offerId: validOfferId,
                initialTab: `${ProjectTabValues.Products}`,
            },
        });
        this.loadingOverlay.stop();
    }

    private mounted() {
        if (this.$route.query.projectId != null) {
            this.isAllowedToCreateOffer = true;
        }
    }

    private continueExecution() {
        if (this.eventFinishedCallback) {
            this.eventFinishedCallback();
        } else {
            this.eventFinished = true;
        }

        this.eventFinishedCallback = null;
    }

    private created() {
        EventBus.$on(EventBusEvents.formValidationFinished, this.continueExecution);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.formValidationFinished, this.continueExecution);
    }
}
