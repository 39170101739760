<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.79167 10.375L8.08333 13.125L11.2917 9L15.4167 14.5H2.58333L5.79167 10.375ZM17.25 15.4167V2.58333C17.25 1.56583 16.425 0.75 15.4167 0.75H2.58333C2.0971 0.75 1.63079 0.943154 1.28697 1.28697C0.943154 1.63079 0.75 2.0971 0.75 2.58333V15.4167C0.75 15.9029 0.943154 16.3692 1.28697 16.713C1.63079 17.0568 2.0971 17.25 2.58333 17.25H15.4167C15.9029 17.25 16.3692 17.0568 16.713 16.713C17.0568 16.3692 17.25 15.9029 17.25 15.4167Z" fill="#8C8C8C"/>
    </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'RTEAddImageIcon',
})
export default class RTEAddImageIcon extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
