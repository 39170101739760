import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';

export default class OfferItemPriceManipulationRepository {
    public static getAll() {
        return OfferItemPriceManipulation.query().withAll().get();
    }

    public static getById(id: string) {
        return OfferItemPriceManipulation.query().whereId(id).withAllRecursive().first();
    }

    public static delete(id: string) {
        return OfferItemPriceManipulation.delete(id);
    }
}
