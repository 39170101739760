import axios from 'axios';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import router from '@/router/index';
import Jsona from 'jsona';
import { TJsonApiBody, TJsonApiData } from 'jsona/lib/JsonaTypes';
import { RouteNames } from '@/enums/routes/RouteNames';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import i18n from '@/i18n';
import { ApiRoutes } from '@/enums/api/ApiRoutes';

const dataFormatter = new Jsona();
const publicRoutes = [ApiRoutes.login, ApiRoutes.cmsUser.requestPassword, ApiRoutes.cmsUser.resetPassword];
let currentUserGroup: null | number = null;

export const appAxios = axios.create({
    headers: {
        Accept: 'application/vnd.api+json',
    },
    baseURL: process.env.VUE_APP_BASE_URL,
    responseType: 'json',
});

EventBus.$on(EventBusEvents.updateUserGroup, (userGroupId: number | null) => {
    currentUserGroup = userGroupId;
});

appAxios.interceptors.request.use((config) => {
    const userGroup = currentUserGroup || LocalStorageService.get(LocalStorageKeyNames.userGroup);

    if (LocalStorageService.has(LocalStorageKeyNames.token) && !publicRoutes.includes(config.url as string)) {
        config.headers.Authorization = `Bearer ${LocalStorageService.get(LocalStorageKeyNames.token)}`;
    }

    if (config.headers.common['Accept-Language'] == null) {
        config.headers.common['Accept-Language'] = i18n.locale;
    }

    if (userGroup == null && !publicRoutes.includes(config.url as string)) {
        router.push({ name: RouteNames.login });
        return config;
        // throw new Error('User group was not found.');
    }

    config.params = {
        ug: userGroup,
    };

    return config;
});

appAxios.interceptors.response.use(
    async (response) => {
        if (response.data != null && response.data.meta != null && response.data.meta.totalCount != null) {
            if (
                response.config.url &&
                (response.config.url.includes('/projects') ||
                    response.config.url.includes('project/search') ||
                    response.config.url.includes('/next/steps'))
            ) {
                EventBus.$emit(EventBusEvents.emitProjectPagination, {
                    totalNumber: response.data.meta.totalCount,
                });
            } else if (response.config.url && response.config.url.includes('/clients')) {
                EventBus.$emit(EventBusEvents.emitClientPagination, {
                    totalNumber: response.data.meta.totalCount,
                });
            } else if (response.config.url && response.config.url.includes('/translations')) {
                EventBus.$emit(EventBusEvents.emitTranslationPagination, {
                    totalNumber: response.data.meta.totalCount,
                });
            }
        }

        if (response.headers['content-type'] && response.headers['content-type'].includes('application/vnd.api+json')) {
            if (response.data == null) {
                throw new Error('No data was found in the request');
            }
            response.data = dataFormatter.deserialize(response.data);
        }
        return response;
    },
    (error) => {
        if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.errors.length > 0 &&
            error.response.data.errors[0].code ===
                'ACCESS_DENIED_BY_CONTROLLER_ANNOTATION_@ISGRANTED("EDIT_USER_RIGHTS")'
        ) {
            return Promise.reject(new Error(i18n.t('Nemate dovoljno prava za ovu akciju') as string));
        }

        if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.errors.length > 0 &&
            error.response.data.errors[0].code === 'USER_GROUP_FORBIDDEN'
        ) {
            currentUserGroup = null;
            router.push({ name: RouteNames.login });
            return Promise.reject(new Error('Korisnička grupa je neispravna.'));
        }

        if (error.response && error.response.status === 401 && !error.response.config.url.includes('refresh-token')) {
            if (router.currentRoute.name === RouteNames.login) {
                return;
            }
            router.push({ name: RouteNames.login });
            return Promise.reject(new Error('Vrijeme prijave je isteklo, molimo da se ponovo prijavite.'));
        }
        return Promise.reject(error);
    }
);

// function isProductFormResponse(response: TJsonApiBody) {
//     if (response.data == null) {
//         return false;
//     }
//     const responseData = response.data as TJsonApiData;
//     // handles response from product/response because of issue in json-api-response-converter library
//     // todo - fix if library fixes this
//     return responseData.links && responseData.links.self && responseData.links.self.includes('product/forms');
// }
