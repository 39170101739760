
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import ClientType from '@/models/ClientType';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';

@Component({
    name: 'DuplicateClientSlot',
})
export default class DuplicateClientSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;

    private get clientTypeNames() {
        return this.popupData.client.clientTypes.map((clientType: ClientType) => clientType.name).join(', ');
    }

    private onContinue() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private onSaveExisting() {
        this.$router.push(
            {
                name: RouteNames.editClient,
                params: { clientId: this.popupData.client.id },
                query: {
                    projectId: this.popupData.projectId,
                    initialTab: String(ClientTabs.CUSTOMER),
                },
            },
            () => {
                EventBus.$emit(EventBusEvents.duplicateValueDetected, false);
            }
        );
        EventBus.$emit(EventBusEvents.closePopup);
        EventBus.$emit(EventBusEvents.closeNewClientPopup);
    }

    private saveDuplicatedClient() {
        // Emit an event indicating the user has chosen to save the client with duplicate values
        EventBus.$emit(EventBusEvents.saveClientWithDuplicates);
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private parseEmptyValues(value: number | null | string | undefined | []) {
        if (value == null) {
            return '-';
        }
        if (value === '' || (Array.isArray(value) && value.length <= 0)) {
            return '-';
        }
        return value;
    }
}
