import { AxiosResponse } from 'axios';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { TJsonApiBody, TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';
import { UploadFile } from 'ant-design-vue/types/upload';
import store from '@/store';
import Product from '@/models/Product';

export class ProductFormAPI {
    public static async getAll() {
        let productForms;
        try {
            productForms = (await ApiController.get(ApiRoutes.cms.productForm.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(productForms);
    }

    public static async getById(productFormId: number, locale?: string | null) {
        let productForm;

        try {
            productForm = (await ApiController.get(ApiRoutes.getProductForm(productFormId), locale)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productForm);
    }

    public static async getFromProduct(productFormId: string) {
        let singleProductForm;

        try {
            singleProductForm = await ApiController.get(ApiRoutes.getProductFromFromProduct(productFormId));
        } catch (e) {
            return Promise.reject(e);
        }

        const data = singleProductForm.data;

        return Promise.resolve(data);
    }

    public static async getProductForms() {
        let productForm;

        try {
            productForm = (await ApiController.get(ApiRoutes.getProductForms)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productForm);
    }

    public static async getImageByFormId(productFormId: number, locale?: string | null) {
        let productForm;

        try {
            productForm = (await ApiController.get(
                ApiRoutes.getProductFormImage(productFormId),
                locale
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productForm);
    }

    public static async updateExisting(
        // productId: string,
        productFormId: string,
        productFormData: any,
        locale?: string | null
    ) {
        let productForm;

        const request: TJsonApiBody = {
            data: {
                type: 'product_forms',
                id: productFormId,
                attributes: {
                    // title: productSystemDetailsInfo.title,
                    // description: productSystemDetailsInfo.description,
                },
            },
        };
        const relationships: TJsonApiRelationships = {
            /*productSystem: {
                data: {
                    type: 'product',
                    id: productSystemId,
                },
            },*/
        };

        if (productFormData.gallery) {
            const galleryData = productFormData.gallery.map((image: UploadFile) => {
                return {
                    type: 'images',
                    id: image.uid,
                };
            });
            relationships.gallery = {
                data: galleryData,
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }
        try {
            productForm = (await ApiController.patch(
                ApiRoutes.getProductForm(Number(productFormId)),
                request,
                locale
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productForm);
    }
}
