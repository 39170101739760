import { Model } from '@vuex-orm/core';
import DiscountAPI from '@/api/DiscountAPI';
import ProductGroup from '@/models/ProductGroup';

export default class Discount extends Model {
    public static entity = 'discount';

    public static fields() {
        return {
            id: this.string(null),
            oid: this.string(''),
            discountedEntityOid: this.attr(''),
            discountType: this.attr(''),
            price1: this.attr([]),
            price2: this.attr([]),
            threshold: this.attr([]),
            isPercentage: this.boolean(false),
            productGroup: this.belongsTo(ProductGroup, 'productGroup_id'),
            productGroup_id: this.attr(null),
            discountedEntity: this.attr(null),
            discountCategory: this.attr(''),
        };
    }

    public static async getAll() {
        let discounts;
        try {
            discounts = await DiscountAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: discounts.data,
        });
        return Promise.resolve();
    }

    public static async getById(discountId: string) {
        let discount;
        try {
            discount = await DiscountAPI.getById(discountId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: discount.data,
        });
        return Promise.resolve(discount);
    }

    public static async updateExisting(discountId: string, discountInfo: any) {
        let discount;
        try {
            discount = await DiscountAPI.update(discountId, discountInfo);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: discount.data,
        });

        return Promise.resolve(discount);
    }

    public static async createNew(discountInfo: any) {
        let discount;
        try {
            discount = await DiscountAPI.create(discountInfo);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: discount.data,
        });
        return Promise.resolve(discount);
    }

    public static async deleteExisting(discountId: string) {
        try {
            await DiscountAPI.delete(discountId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Discount.delete(discountId);
        return Promise.resolve();
    }
    public id!: string;
    public oid!: string;
    public discountedEntityOid!: string;
    public discountType!: string;
    public price1!: number[];
    public price2!: number[];
    public threshold!: number[];
    public isPercentage!: boolean;
    public productGroup!: ProductGroup;
    public discountedEntity!: {
        name: string;
    };
    public discountCategory!: string;
}
