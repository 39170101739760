import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import moment from 'moment';
import { VisibleNextStepParameters } from '@/interfaces/components/nextStep/VisibleNextStepParameters';
import i18n from '@/i18n';

export function transformObjectToBeBackendCompliant(
    nextStepObject: UpgradedNextStep,
    chatMessageId: string | null | undefined) {
    const transformedStepObject = Object.assign({}, nextStepObject);
    if (transformedStepObject.name === '') {
        let nextStepName = i18n.t('Sljedeći korak') as string;
        if (transformedStepObject.nextStepType != null) {
            const nextStepType = NextStepTypeRepository.getById(transformedStepObject.nextStepType);
            nextStepName = nextStepType ? nextStepType.name : i18n.t('Sljedeći korak') as string;
        }
        transformedStepObject.name = nextStepName;
    }

    if (transformedStepObject.dateFrom) {
        transformedStepObject.dateFrom = moment.utc(transformedStepObject.dateFrom);
    }

    if (transformedStepObject.dateTo) {
        transformedStepObject.dateTo = moment.utc(transformedStepObject.dateTo);
    }

    if (nextStepObject.messageId) {
        transformedStepObject.chatMessage = nextStepObject.messageId;
    } else if (chatMessageId) {
        transformedStepObject.chatMessage = chatMessageId;
    }

    return transformedStepObject;
}


export function filterVisibleNextSteps(
    {
        upgradedNextSteps,
        shouldShowCompleted,
        shouldDisplayOnlyOneItem,
    }: VisibleNextStepParameters) {
    let filteredItems: UpgradedNextStep[] = upgradedNextSteps.slice();

    if (!shouldShowCompleted) {
        filteredItems = filteredItems.filter((singleNextStep: UpgradedNextStep) => {
            return !singleNextStep.completed;
        });
    }

    if (filteredItems.length <= 0) {
        return [];
    }

    if (shouldDisplayOnlyOneItem) {
        return [filteredItems[0]];
    }

    return filteredItems;
}
