import { Model } from '@vuex-orm/core';
import ClientTypeAPI from '@/api/ClientTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
export default class ClientType extends Model {
    public static entity = 'clientType';

    public static fields() {
        return {
            id: this.string(null),
            code: this.attr(null),
            name: this.attr(null),
            color: this.string(null),
            description: this.attr(null),
        };
    }

    public static async getAll() {
        let clientTypes;

        if (ss.has('clientTypes')) {
            clientTypes = ss.getAndParse('clientTypes');
        } else {
            try {
                clientTypes = await ClientTypeAPI.getAll();
                clientTypes = clientTypes.data;

                ss.stringifyAndSave('clientTypes', clientTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: clientTypes,
        });
        return Promise.resolve();
    }

    public static async getById(clientTypeId: string) {
        let clientType;
        try {
            clientType = await ClientTypeAPI.getById(clientTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: clientType.data,
        });
        return Promise.resolve(clientType);
    }

    public static async updateExisting(clientTypeId: string, clientTypeInfo: any) {
        let clientType;
        try {
            clientType = await ClientTypeAPI.update(clientTypeId, clientTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: clientType.data,
        });
        return Promise.resolve(clientType);
    }

    public static async createNew(clientTypeInfo: any) {
        let clientType;
        try {
            clientType = await ClientTypeAPI.create(clientTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: clientType.data,
        });
        return Promise.resolve(clientType);
    }

    public static async deleteExisting(clientTypeId: string) {
        try {
            await ClientTypeAPI.delete(clientTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ClientType.delete(clientTypeId);
        return Promise.resolve();
    }

    public id!: string;
    public code!: string | null;
    public name!: string;
    public color!: string;
    public description!: string | null;
}
