
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// import { EventBusEvents } from '@/enums/global/EventBusEvents';
// import { EventBus } from '@/helpers/EventBusHelper';
import SingleNotification from '@/components/global/Notification.vue';
import { NotificationField } from '@/interfaces/general/Notification';
import { TimeSplitNotifications } from '@/interfaces/general/TimeSplitNotification';
import Notification from '@/models/Notification';
import NotificationRepository from '@/repositories/NotificationRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'NotificationsPopup',
    components: { SingleNotification },
})
export default class NotificationsPopup extends Vue {
    @Getter('generic/unreadNotificationsCount') private unreadCount!: number;
    @Prop({ required: true }) private notificationsVisible!: boolean;

    private hideReadMessages = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get timeSplitNotifications(): TimeSplitNotifications {
        const arr: TimeSplitNotifications = {};

        this.notifications.forEach((notification: NotificationField) => {
            let creationTime = '';

            const notificationDate = new Date(notification.createdAt);

            switch (true) {
                case this.checkIfDateIsOlderByDay(0, notificationDate):
                    creationTime = 'Today';
                    break;
                case this.checkIfDateIsOlderByDay(1, notificationDate):
                    creationTime = 'Yesterday';
                    break;
                default:
                    creationTime = 'Older';
            }

            if (!notification.readAt || (notification.readAt && !this.hideReadMessages)) {
                if (arr[creationTime]) {
                    arr[creationTime].push({ ...notification });
                } else {
                    arr[creationTime] = [{ ...notification }];
                }
            }
        });

        return arr;
    }

    private get isAnyUnread() {
        return this.unreadCount > 0;
    }

    private async switchNotificationsByReadState() {
        this.loadingOverlay.start();

        try {
            if (this.hideReadMessages) {
                await Notification.getUnread();
            } else {
                await Notification.getAll();
            }
        } catch {
            this.$notification.error({
                message: this.$t('Error updating notifications') as string,
                description: '',
            });
        } finally {
            this.toggleResize();
            this.loadingOverlay.stop();
        }
    }

    private toggleResize() {
        setTimeout(() => {
            this.$emit('toggleNotifications');
        }, 0);
    }

    private updateReadStatus(id: string | number) {
        // const notification = this.notifications.find((notification: NotificationField) => {
        //     return notification.id === id;
        // });

        // if (notification) {
        //     notification.readAt = true;
        // }
        return;
    }

    private async markAllAsRead() {
        this.loadingOverlay.start();

        try {
            await Notification.updateAllAsRead();
        } catch {
            this.$notification.error({
                message: this.$t('Error updating notifications') as string,
                description: '',
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private checkIfDateIsOlderByDay(days: number, notificationDate: Date) {
        const currentDate = new Date();

        return (
            notificationDate.getDate() === currentDate.getDate() - days &&
            notificationDate.getMonth() === currentDate.getMonth() &&
            notificationDate.getFullYear() === currentDate.getFullYear()
        );
    }

    private get notifications(): NotificationField[] {
        return NotificationRepository.getAll();
    }

    private onClose() {
        this.$emit('closeNotifications');
    }

    @Watch('notificationsVisible', { immediate: true })
    private async updateNotifications(isVisible: boolean) {
        if (!isVisible) {
            return;
        }

        this.loadingOverlay.start();

        try {
            if (this.isAnyUnread) {
                await this.markAllAsRead();
                this.$emit('refreshUnreadCount');
            }

            await this.$nextTick();
            await Notification.getAll();
            this.notifications.forEach((notif: any) => {
                if (notif.navigation) {
                    console.log(notif);
                }
            });
        } catch {
            this.$notification.error({
                message: this.$t('Error updating notifications') as string,
                description: '',
            });
        }

        this.toggleResize();
        this.loadingOverlay.stop();
    }
}
