import { Model } from '@vuex-orm/core';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';

export default class ProjectLabelTemplateWorkflowNode extends Model {
    public static entity = 'projectLabelTemplateWorkflowNode';

    public static fields() {
        return {
            id: this.string(null),
            x: this.number(0),
            y: this.number(0),
            projectLabelTemplate: this.belongsTo(ProjectLabelTemplate, 'projectLabelTemplate_id'),
            projectLabelTemplate_id: this.attr(null),
        };
    }
    public id!: string;
    public x!: number;
    public y!: number;
    public projectLabelTemplate!: ProjectLabelTemplate;
}
