import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import OfferConfigurationConfig from '@/models/interfaces/OfferConfiguration';
import { OfferPriceType } from '@/enums/global/OfferPriceType';
import i18n from '@/i18n';

export default class OrderConfigurationAPI {
    public static async getAll() {
        let orderConfigurations;
        try {
            orderConfigurations = await ApiController.get(ApiRoutes.orderConfiguration.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderConfigurations);
    }

    // @ts-ignore
    public static async getById(id: string) {
        let orderConfiguration;
        try {
            orderConfiguration =  await ApiController.get(ApiRoutes.orderConfiguration.getById(id)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(orderConfiguration);
    }

    public static async update(orderConfigurationId: string, orderConfigurationConfig: OfferConfigurationConfig) {
        let orderConfiguration;
        const request = {
            data: {
                type: 'order_configurations',
                id: orderConfigurationId,
                attributes: {
                    name: orderConfigurationConfig.name, showName: orderConfigurationConfig.showName,
                    showProductDetails: orderConfigurationConfig.showProductDetails,
                    showPricelists: orderConfigurationConfig.showPricelists,
                    showImages: orderConfigurationConfig.showImages,
                    showDifferences: orderConfigurationConfig.showDifferences,
                    showDiscounts: orderConfigurationConfig.showDiscounts,
                    showProductImageOnRight: orderConfigurationConfig.showProductImageOnRight,
                    showImageOnce: orderConfigurationConfig.showImageOnce,
                    showPrice: orderConfigurationConfig.showPrice,
                    showUpn: orderConfigurationConfig.showUpn,
                    showPurchasePrice: orderConfigurationConfig.printPrice === OfferPriceType.PURCHASE_PRICE,
                    showRecommendedPrice: orderConfigurationConfig.printPrice === OfferPriceType.RECOMMENDED_PRICE,
                },
                relationships: {},
            },
        };

        try {
            this.checkForForbiddenConfigurations(orderConfigurationConfig);
        } catch (e) {
            throw e;
        }

        if (orderConfigurationConfig.bankAccount) {
            request.data.relationships = {
                bankAccount: {
                    data: {
                        type: 'bank_accounts',
                        id: orderConfigurationConfig.bankAccount.id,
                    },
                },
            };
        }


        if (orderConfigurationConfig.currency) {
            // @ts-ignore
            request.data.relationships.currency = {
                data: {
                    type: 'currencies',
                    id: orderConfigurationConfig.currency.id,
                },
            };
        }

        try {
            orderConfiguration =
                await ApiController.patch(
                    ApiRoutes.orderConfiguration.update(orderConfigurationId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(orderConfiguration);
    }

    private static checkForForbiddenConfigurations(orderConfigurationConfig: OfferConfigurationConfig) {
        if (orderConfigurationConfig.showImages && orderConfigurationConfig.showProductImageOnRight) {
            throw new Error(i18n.t('Morate odabrati samo jednu opciju za prikaz slike') as string);
        }

        if (orderConfigurationConfig.showUpn && orderConfigurationConfig.bankAccount == null) {
            throw new Error(i18n.t('Bankovni račun mora biti odabran') as string);
        }

    }
}
