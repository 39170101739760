export enum NavigationKeys {
    PROJECTS = 'projects',
    CLIENTS = 'clients',
    ACTIVITIES = 'activities',
    SETTINGS = 'settings',
    USERSETTINGS = 'usersettings',
    CHAT = 'chat',
    CALCULATOR = 'calculator',
    INTRO = 'intro',
    DOCUMENTS = 'technicaldocumentation',
    CALENDAR = 'calendar',
    NEWCLIENTINQUIRY = 'newClientInquiry',
    NOTIFICATIONS = 'notifications',
    USERGROUP = 'userGroup',
}
