
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import AddPopupProductSlot from '@/components/global/popup/AddProductSlot.vue';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { ModalWidth } from '@/enums/global/ModalWidth';
import AddMaterialSlot from '@/components/global/popup/AddMaterialSlot.vue';
import EditMultipositionSlot from '@/components/global/popup/EditMultipositionSlot.vue';
import DuplicateClientSlot from '@/components/global/popup/DuplicateClientSlot.vue';
import PriceListOutOfRangeSlot from '@/components/global/popup/PriceListOutOfRangeSlot.vue';
import DocumentAndPdfTypeSlot from '@/components/global/popup/DocumentAndPdfTypeSlot.vue';
import DeleteContactPerson from '@/components/global/popup/DeleteContactPerson.vue';
import DeleteClientPerson from '@/components/global/popup/DeleteClientPerson.vue';
import PdfPreviewOfferPopup from '@/components/global/popup/PdfPreviewOfferPopup.vue';
import ProjectsModal from '@/components/global/popup/ProjectsModal.vue';
import PdfPreviewOrderPopup from '@/components/global/popup/PdfPreviewOrderPopup.vue';
import CreateNewEventPopup from '@/components/global/popup/CreateNewEventPopup.vue';
import PreviewFilePopup from '@/components/global/popup/PreviewFilePopup.vue';
import OfferItemPriceManipulationForm from '@/components/global/popup/OfferItemPriceManipulationForm.vue';
import ActivitesProjectsModal from '@/components/global/popup/ActivitesProjectsModal.vue';
import DimensionsWizard from '@/components/global/popup/DimensionsWizard.vue';
import CombinationsWizard from '@/components/global/popup/CombinationsWizard.vue';

@Component({
    name: 'Popup',
    components: {
        ActivitesProjectsModal,
        OfferItemPriceManipulationForm,
        DocumentAndPdfTypeSlot,
        EditMultipositionSlot,
        AddMaterialSlot,
        AddPopupProductSlot,
        PriceListOutOfRangeSlot,
        DuplicateClientSlot,
        DeleteContactPerson,
        DeleteClientPerson,
        PdfPreviewOfferPopup,
        PdfPreviewOrderPopup,
        PreviewFilePopup,
        CreateNewEventPopup,
        ProjectsModal,
        DimensionsWizard,
        CombinationsWizard,
    },
})
export default class Popup extends Vue {
    public popupEvents: any = PopupEvents;
    public popupData: any = null;
    private visible: boolean = false;
    private popupEvent: string = '';
    private modalWidth = ModalWidth;
    private title: string = '';

    private get wrapClassName() {
        if (this.popupEvent === PopupEvents.openAddProducts || this.popupEvent === PopupEvents.openMultiposition) {
            const classes = ['multiposition'];
            if (this.popupEvent === PopupEvents.openMultiposition) {
                classes.push('multiposition--editing');
            }
            return classes.join(' ');
        }

        if (
            this.popupEvent === PopupEvents.openDimensionsWizard ||
            this.popupEvent === PopupEvents.openCombinationsWizard
        ) {
            const classes = ['dimensions-wizard-modal'];

            return classes.join(' ');
        }

        if (!this.parentHasPadding) {
            return 'no-padding';
        }

        return '';
    }

    private get narrowMode() {
        return (
            this.popupEvent === PopupEvents.openDocumentAndPdfType ||
            this.popupEvent === PopupEvents.openAddMaterials ||
            this.popupEvent === PopupEvents.priceListOutOfRange ||
            this.popupEvent === PopupEvents.openDuplicateClient ||
            this.popupEvent === PopupEvents.deleteContactPerson ||
            this.popupEvent === PopupEvents.deleteClientPerson ||
            this.popupEvent === PopupEvents.offerItemPriceManipulation
        );
    }

    private get parentHasPadding() {
        return (
            this.popupEvent === PopupEvents.openAddMaterials ||
            this.popupEvent === PopupEvents.openMultiposition ||
            this.popupEvent === PopupEvents.openAddProducts ||
            this.popupEvent === PopupEvents.openDuplicateClient ||
            this.popupEvent === PopupEvents.offerItemPriceManipulation
        );
    }

    private eventBusListeners() {
        EventBus.$on(EventBusEvents.openAddProductsPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openMaterialsPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openMultipositionPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openDuplicateClientPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openDocumentAndPdfTypeSlot, this.initializeModal);
        EventBus.$on(EventBusEvents.openPriceListOutOfRangePopup, this.initializeModal);
        EventBus.$on(EventBusEvents.onDeleteContactPerson, this.initializeModal);
        EventBus.$on(EventBusEvents.onDeleteClientPerson, this.initializeModal);
        EventBus.$on(EventBusEvents.onOfferItemPriceManipulation, this.initializeModal);
        EventBus.$on(EventBusEvents.onOpenPdfPreviewOfferPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openCreateNewEventPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.onOpenPdfPreviewOrderPopup, this.initializeModal);
        EventBus.$on(EventBusEvents.onOpenPreviewFilePopup, this.initializeModal);
        EventBus.$on(EventBusEvents.openProjectsModal, this.initializeModal);
        EventBus.$on(EventBusEvents.openDimensionsWizard, this.initializeModal);
        EventBus.$on(EventBusEvents.openCombinationsWizard, this.initializeModal);
        EventBus.$on(EventBusEvents.closePopup, this.closePopup);
    }

    private initializeModal(eventData: PopupEventData) {
        this.openPopup(eventData.popupEvent);

        if (eventData.data === undefined) {
            return;
        }

        this.popupData = eventData.data;

        if (this.popupData?.title) {
            this.title = this.popupData.title;
        }
    }

    private openPopup(popupEvent: string) {
        this.popupEvent = popupEvent;
        this.visible = true;
    }

    private closePopup(shouldConfirm: boolean = false) {
        const multipositionEvents = [this.popupEvents.openAddProducts, this.popupEvents.openMultiposition];

        if (multipositionEvents.includes(this.popupEvent) && shouldConfirm) {
            this.$confirm({
                title: `${this.$t('Are you sure you want to close multiposition?')}`,
                content: `${this.$t(`Your changes won't be saved.`)}`,
                okText: this.$t('Close') as string,
                okType: 'danger',
                cancelText: this.$t('Cancel') as string,
                onOk: () => {
                    this.closePopupAndResetEvent();
                },
                onCancel: () => {
                    return;
                },
            });

            return;
        }

        this.closePopupAndResetEvent();
    }

    private closePopupAndResetEvent() {
        EventBus.$emit(EventBusEvents.cancelDeletingClients);

        this.visible = false;
        this.popupEvent = '';
        this.title = '';
    }

    private removeEventListeners() {
        EventBus.$off(EventBusEvents.openAddProductsPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openMaterialsPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openMultipositionPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openDuplicateClientPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openDocumentAndPdfTypeSlot, this.initializeModal);
        EventBus.$off(EventBusEvents.openPriceListOutOfRangePopup, this.initializeModal);
        EventBus.$off(EventBusEvents.onDeleteContactPerson, this.initializeModal);
        EventBus.$off(EventBusEvents.onDeleteClientPerson, this.initializeModal);
        EventBus.$off(EventBusEvents.onOfferItemPriceManipulation, this.initializeModal);
        EventBus.$off(EventBusEvents.onOpenPdfPreviewOfferPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openCreateNewEventPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.onOpenPdfPreviewOrderPopup, this.initializeModal);
        EventBus.$off(EventBusEvents.onOpenPreviewFilePopup, this.initializeModal);
        EventBus.$off(EventBusEvents.openProjectsModal, this.initializeModal);
        EventBus.$off(EventBusEvents.openDimensionsWizard, this.initializeModal);
        EventBus.$off(EventBusEvents.openCombinationsWizard, this.initializeModal);
        EventBus.$off(EventBusEvents.closePopup, this.closePopup);
    }

    private mounted() {
        this.eventBusListeners();
    }

    private beforeDestroy() {
        this.removeEventListeners();
    }

    private get getModalWidth() {
        // tslint:disable-next-line:max-line-length
        if (
            [
                this.popupEvents.openPdfPreviewOfferPopup,
                this.popupEvents.openPdfPreviewOrderPopup,
                this.popupEvents.openCreateNewEventPopup,
                this.popupEvents.openProjectsModal,
            ].includes(this.popupEvent)
        ) {
            return this.modalWidth.SMALL_WIDE;
        } else {
            return this.narrowMode ? this.modalWidth.SMALL : '100%';
        }
    }
}
