import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductSystemAPI {
    public static async getAll() {
        let productSystems;

        try {
            productSystems = await ApiController.get(ApiRoutes.productSystem.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystems);
    }

    public static async getById(productSystemId: string, locale?: string | null) {
        let productSystem;

        try {
            productSystem =
                await ApiController.get(ApiRoutes.productSystem.getSingle(productSystemId), locale) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystem);
    }
}
