import { Model } from '@vuex-orm/core';
import ProjectLogAPI from '@/api/ProjectLogAPI';

export default class ProjectLog extends Model {

    public static entity = 'projectLog';

    public static fields() {
        return {
            id: this.attr(null),
            message: this.string(''),
            changeType: this.string(''),
            oldValue: this.string(''),
            newValue: this.string(''),
            createdAt: this.string(''),
        };
    }

    public static async getAll() {
        let projectLogs;
        try {
            projectLogs = await ProjectLogAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: projectLogs.data,
        });
        return Promise.resolve();
    }

    public static async getById(projectId: string | undefined) {
        if (projectId === undefined) {
            this.create({
                data: [],
            });
            return;
        }
        let projectLog;
        try {
            projectLog = await ProjectLogAPI.getById(projectId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: projectLog.data,
        });
        return Promise.resolve(projectLog);
    }

    public id!: string;
    public message!: string;
    public changeType!: string;
    public oldValue!: string;
    public newValue!: string;
    public createdAt!: string;

}
