import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class TranslationAPI {
    public static async getAll() {
        let translations;

        try {
            translations = await ApiController.get(ApiRoutes.translations.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(translations);
    }
}
