import ScheduleAPI from '@/api/ScheduleAPI';
import { Model } from '@vuex-orm/core';
import { AxiosError, AxiosResponse } from 'axios';

export default class Schedule extends Model {
    public static entity = 'schedule';

    public static fields() {
        return {
            id: this.string(''),
            userChanged: this.number(''),
            userInserted: this.attr(''),
            recurrenceExpression: this.string(''),
            title: this.string(''),
            dateTimeFrom: this.number(''),
            dateTimeTo: this.number(''),
            duration: this.string(''),
            userId: this.string(''),
            resourceId: this.string(''),
            ruleId: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
        };
    }

    public static async getAll() {
        let schedules;

        try {
            schedules = await ScheduleAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: schedules.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve(schedules);
    }

    public static async getById(id: string) {
        let schedule;
        try {
            schedule = await ScheduleAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: schedule.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve(schedule);
    }

    public static async getByUserId(id: string) {
        let schedules;

        try {
            schedules = await ScheduleAPI.getByUserId(id);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: schedules.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve(schedules);
    }

    public static async getByResourceId(scheduleId: string) {
        let schedule;
        try {
            schedule = await ScheduleAPI.getByResourceId(scheduleId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: schedule.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve(schedule);
    }

    public static async createNew(schedule: Schedule): Promise<AxiosResponse | AxiosError> {
        let newSchedule;

        try {
            newSchedule = await ScheduleAPI.create(schedule);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: newSchedule.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve(newSchedule);
    }

    public static async deleteExisting(scheduleId: string) {
        try {
            await ScheduleAPI.delete(scheduleId);
        } catch (e) {
            return Promise.reject(e);
        }

        await Schedule.delete(scheduleId);

        return Promise.resolve();
    }

    public static async updateExisting(scheduleId: string, scheduleData: Schedule) {
        let newSchedule;

        try {
            newSchedule = await ScheduleAPI.update(scheduleId, scheduleData);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: newSchedule.data,
            insertOrUpdate: ['schedules'],
        });

        return Promise.resolve();
    }

    public id!: string;
    public userChanged!: number;
    public userInserted!: any;
    public recurrenceExpression!: string;
    public title!: string;
    public dateTimeFrom!: number;
    public dateTimeTo!: number;
    public duration!: string;
    public userId!: string;
    public resourceId!: string;
    public ruleId!: string;
    public createdAt!: string;
    public updatedAt!: string;
}
