import { Model } from '@vuex-orm/core';

export default class WorkflowNode extends Model {
    public static entity = 'workflowNode';

    public static fields() {
        return {
            id: this.string(null),
            x: this.number(0),
            y: this.number(0),
        };
    }

    public id!: string;
    public x!: number;
    public y!: number;
}
