import { Model } from '@vuex-orm/core';
import Country from '@/models/Country';
import AddressAPI from '@/api/AddressAPI';
import AddressConfig from '@/models/interfaces/Address';
import Lead from '@/models/Lead';
import Offer from '@/models/Offer';

export default class Address extends Model {
    public static entity = 'address';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            streetNameAndNumber: this.attr(null),
            poNumber: this.attr(null),
            city: this.attr(null),
            building: this.attr(null),
            contactPerson: this.attr(''),
            phone: this.attr(''),
            note: this.attr(''),
            country: this.belongsTo(Country, 'country_id'),
            country_id: this.attr(null),
            objectLeads_ids: this.attr([]),
            offer_id: this.attr(null),
            objectLeads: this.hasManyBy(Lead, 'objectLeads_ids'),
            deliveryOffers_ids: this.attr([]),
            deliveryOffers: this.hasMany(Offer, 'deliveryAddress_id'),
            billingOffers_ids: this.attr([]),
            billingOffers: this.hasMany(Offer, 'billingAddress_id'),
        };
    }

    public static async getAll() {
        let addresses;
        try {
            addresses = await AddressAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: addresses.data,
        });
        return Promise.resolve();
    }

    public static async getById(addressId: string) {
        let address;
        try {
            address = await AddressAPI.getById(addressId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: address.data,
        });
        return Promise.resolve(address);
    }

    public static async updateExisting(addressId: string, addresssInfo: AddressConfig) {
        let address;
        try {
            address = await AddressAPI.update(addressId, addresssInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(address);
    }

    public static async updateExistingAndSaveLocally(addressId: string, addresssInfo: AddressConfig) {
        let address;
        try {
            address = await AddressAPI.update(addressId, addresssInfo, true);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: address.data,
        });
        return Promise.resolve(address);
    }

    public static async deleteExisting(addressId: string) {
        let address;
        try {
            address = await AddressAPI.delete(addressId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Address.delete(addressId);

        return Promise.resolve(address);
    }

    public static async createNew(addressInfo: AddressConfig) {
        let address;
        try {
            address = await AddressAPI.create(addressInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(address);
    }

    public static async createNewAndSaveLocally(addressInfo: AddressConfig) {
        let address;
        try {
            address = await AddressAPI.create(addressInfo, true);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: address.data,
            insertOrUpdate: ['lead'],
        });
        return Promise.resolve(address);
    }
    public id!: string;
    public name!: string;
    public streetNameAndNumber!: string;
    public poNumber!: string;
    public city!: string;
    public building!: string | null;
    public contactPerson!: string | null;
    public phone!: string | null;
    public note!: string | null;
    public country!: Country;
}
