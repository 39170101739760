import Client from '@/models/Client';

export default class ClientRepository {
    public static getAll() {
        return Client.query().withAllRecursive().get();
    }

    public static getById(clientId: string | null | undefined) {
        if (clientId == null) {
            return null;
        }
        return Client.query().whereId(clientId).withAllRecursive().first();
    }

    public static getByIdInArray(clientId: string | null | undefined) {
        if (clientId == null) {
            return null;
        }
        return Client.query().whereId(clientId).withAllRecursive().get();
    }

    public static filterByName(query: string) {
        return Client.query().where((client: Client) => {
            const clientName = client.clientName.toUpperCase();
            return clientName.includes(query.toLocaleUpperCase());
        }).withAll().get();
    }

    public static getNameById(id: string) {
        const client = Client.query().whereId(id).first();
        if (client == null) {
            return '';
        }
        return client.name;
    }

    public static contactPersons(clientId: string | null) {
        if (clientId == null) {
            return [];
        }
        const client = Client.query().whereId(clientId).withAllRecursive().first();
        if (client == null) {
            return [];
        }

        return client.contactPersons;
    }

    public static getAllSearchResults() {
        return Client.query().where('isSearchResult', true).withAllRecursive().get();
    }
}
