import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductCatalogueAPI {
    public static async search(query: string) {
        let product;

        try {
            product = await ApiController.get(ApiRoutes.productCatalogue.search(query)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(product);
    }
}
