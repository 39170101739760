import ProjectState from '@/models/ProjectState';
import { mapTranslationByKey } from '@/helpers/TranslationHelper';

export default class ProjectStateRepository {
    public static getAll() {
        return ProjectState.all().map((element) => {
            return {...element, label: mapTranslationByKey(element.name)};
        });
    }

    public static getById(id: string) {
        return ProjectState.query().whereId(id).withAll().first();
    }
}
