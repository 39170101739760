
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import PhoneNumberInput from '@/components/global/PhoneNumberInput.vue';

interface EmailPhoneFormType {
    emails: string[];
    phones: string[];
    primaryIndex: number;
}

@Component({
    name: 'ContactEmailPhoneForm',
    components: {
        PhoneNumberInput,
    },
})
export default class ContactEmailPhoneForm extends Vue {
    @Model('change', {
        type: Object as PropType<EmailPhoneFormType>,
    })
    private value!: EmailPhoneFormType;

    @Prop({ default: false }) private email!: string;
    @Prop({ default: false }) private phone!: string;
    @Prop({ default: false }) private multiple!: boolean;
    @Prop({ default: '' }) private selectedCountry!: string;
    @Prop({ required: false }) private isCompany!: boolean;
    @Prop({ required: false }) private shouldPreserveFormOnUserToggle!: boolean;

    @Prop({ default: false }) private emailValidationOverride!: boolean;
    @Prop({ default: false }) private emailValidationOverrideValue!: boolean;
    @Prop({ default: false }) private contactErrors!: any;

    // The format returned and format used for the phone input arent the same
    private originalPhoneInputs: string[] = [''];
    private isValidEmail = true;
    private mailFormat =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    private handlePrimary(index: number): void {
        this.value.primaryIndex = index;

        this.$emit('change', this.value);
    }

    private handleEmailChange(index: number, event: Event): void {
        const input = event.target as HTMLInputElement;

        const copy = this.value.emails.slice();
        copy[index] = input.value;

        this.value.emails = copy;

        this.$emit('change', this.value);
        this.$emit('update:email', this.value.emails[0]);
    }

    private handlePhoneChange(index: number, event: string): void {
        const copy = this.value.phones.slice();
        copy[index] = event;

        this.value.phones = copy;

        this.$emit('change', this.value);
        this.$emit('update:phone', this.value.phones[0]);
    }

    private resetForm() {
        this.value.emails = [''];
        this.value.phones = [''];
        this.originalPhoneInputs = [];

        this.$emit('change', this.value);
        this.$emit('update:email', this.value.emails[0]);
        this.$emit('update:phone', this.value.phones[0]);
    }

    private get validateEmail() {
        if (this.emailValidationOverride) {
            return this.emailValidationOverrideValue;
        }

        if (!this.email) {
            return true;
        }

        return this.email.match(this.mailFormat);
    }

    private addEmail(): void {
        this.value.emails.push('');

        this.$emit('change', this.value);
    }

    private addPhone(): void {
        this.value.phones.push('');

        this.$emit('change', this.value);
    }

    private removeEmail(index: number): void {
        this.value.emails.splice(index, 1);

        if (index === this.value.primaryIndex) {
            this.value.primaryIndex = 0;
        }

        this.$emit('change', this.value);
    }

    private removePhone(index: number): void {
        this.value.phones.splice(index, 1);
        this.originalPhoneInputs.splice(index, 1);

        this.$emit('change', this.value);
    }

    private created() {
        if (this.value.emails.length === 0) {
            this.value.emails.push('');
        }

        if (this.value.phones.length === 0) {
            this.value.phones.push('');
        }

        this.value.phones.forEach((phone) => {
            this.originalPhoneInputs.push(phone);
        });
    }

    @Watch('phone', {
        immediate: true,
    })
    private loadPhone(newVal: string) {
        if (newVal) {
            this.originalPhoneInputs[0] = newVal;
        }
    }

    @Watch('isCompany', {
        immediate: false,
    })
    private onCompanyChange(newVal: boolean) {
        if (!newVal) {
            // this.resetForm();
        }
    }
}
