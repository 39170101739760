import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class PurchasePriceManipulationAPI {
    public static async getAll() {
        let priceManipulations;

        try {
            priceManipulations = await ApiController.get(ApiRoutes.priceManipulation.purchase.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulations);
    }

    public static async getById(priceManipulationId: string) {
        let priceManipulation;

        try {
            priceManipulation = await
                ApiController.get(ApiRoutes.priceManipulation.purchase.getById(priceManipulationId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async update(priceManipulationId: string, priceManipulationInfo: AvailableLabelFields) {
        let priceManipulation;

        const request = {
            data: {
                type: 'offer_purchase_price_manipulations',
                id: priceManipulationId,
                attributes: {
                    name: priceManipulationInfo.name ? priceManipulationInfo.name : null,
                    value: priceManipulationInfo.value,
                },
                relationships: {
                    priceManipulationType: {
                        data: {
                            type: 'price_manipulation_types',
                            id: priceManipulationInfo.priceManipulationType,
                        },
                    },
                    productCategory: {
                        data: null,
                    },
                    offer: {
                        data: {
                            type: 'offers',
                            id: priceManipulationInfo.offer,
                        },
                    },
                },
            },
        };

        if (priceManipulationInfo.productCategory !== '0') {
            // @ts-ignore
            request.data.relationships.productCategory = {
                data: {
                    type: 'product_categories',
                    id: priceManipulationInfo.productCategory,
                } as any,
            };
        }

        try {
            priceManipulation =
                await ApiController.patch(
                    ApiRoutes.priceManipulation.purchase.update(priceManipulationId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async create(priceManipulationInfo: AvailableLabelFields) {
        let priceManipulation;

        const request = {
            data: {
                type: 'offer_purchase_price_manipulations',
                attributes: {
                    name: priceManipulationInfo.name ? priceManipulationInfo.name : null,
                    value: priceManipulationInfo.value,
                },
                relationships: {
                    priceManipulationType: {
                        data: {
                            type: 'price_manipulation_types',
                            id: priceManipulationInfo.priceManipulationType,
                        },
                    },
                    productCategory: null,
                    offer: {
                        data: {
                            type: 'offers',
                            id: priceManipulationInfo.offer,
                        },
                    },
                },
            },
        };

        if (priceManipulationInfo.productCategory !== '0') {
            // @ts-ignore
            request.data.relationships.productCategory = {
                data: {
                    type: 'product_categories',
                    id: priceManipulationInfo.productCategory,
                },
            };
        }

        try {
            priceManipulation =
                await ApiController.post(
                    ApiRoutes.priceManipulation.purchase.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulation);
    }

    public static async delete(priceManipulationId: string) {

        try {
            await ApiController.delete(ApiRoutes.priceManipulation.purchase.delete(priceManipulationId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
