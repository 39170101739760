import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class ProductGroupAPI {
    public static async getAll() {
        let productGroups;

        try {
            productGroups = await ApiController.get(ApiRoutes.productGroup.get);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productGroups);
    }

    public static async getById(productGroupId: string) {
        let productGroup;

        try {
            productGroup =
                await ApiController.get(ApiRoutes.productGroup.getById(productGroupId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productGroup);
    }

    public static async delete(productGroupId: string) {

        try {
            await ApiController.delete(ApiRoutes.productGroup.delete(productGroupId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(productGroupId: string, productGroupInfo: AvailableLabelFields) {
        let productGroup;
        const request = {
            data: {
                type: 'product_groups',
                id: productGroupId,
                attributes: {
                    name: productGroupInfo.name,
                },
            },
        };

        try {
            productGroup =
                await ApiController.patch(
                    ApiRoutes.productGroup.update(productGroupId),
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productGroup);
    }

    public static async create(productGroupInfo: AvailableLabelFields) {
        let productGroup;
        const request = {
            data: {
                type: 'product_groups',
                attributes: {
                    name: productGroupInfo.name,
                },
            },
        };

        try {
            productGroup =
                await ApiController.post(
                    ApiRoutes.productGroup.create,
                    request,
                );
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productGroup);
    }
}
