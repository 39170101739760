import Project from '@/models/Project';
import Offer from '@/models/Offer';
import { AxiosResponse } from 'axios';
import ProjectRepository from '@/repositories/ProjectRepository';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { ChecklistSegment } from '@/interfaces/components/configurator/ChecklistSegment';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { formatFormData } from '@/helpers/FormDataHelper';
import OfferItem from '@/models/OfferItem';
import { JsonAPIError } from '@/interfaces/general/JsonAPIError';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import i18n from '@/i18n';
import Product from '@/models/Product';
import { ProductRepository } from '@/repositories/ProductRepository';
import ClientRepository from '@/repositories/ClientRepository';
import { mapOfferItemsFormData } from '@/helpers/project/ProductFormVersionHelper';
import ProductSlotRow from '@/components/global/popup/ProductSlotRow.vue';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import store from '@/store';
import { COMMON_FIELD_PIDS, GlobalOptions } from '@/enums/global/GlobalOptions';
import { MultipositionProduct } from '@/interfaces/components/projectNew/MultipositionProduct';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

export default class ConfiguratorService {
    public static async getExistingOrNewOfferId(
        projectId: string,
        routeOfferId: any,
        paymentTypeId: string | null
    ): Promise<{ offerId: string; hasNewOfferBeenCreated: boolean }> {
        let offerId;
        let newOffer;
        let hasNewOfferBeenCreated = false;

        if (routeOfferId) {
            offerId = routeOfferId;
            return Promise.resolve({
                offerId,
                hasNewOfferBeenCreated,
            });
        }

        try {
            newOffer = (await Offer.createNew(String(projectId), paymentTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        hasNewOfferBeenCreated = true;
        offerId = newOffer.data.id;

        return Promise.resolve({
            offerId: String(offerId),
            hasNewOfferBeenCreated,
        });
    }

    public static async getActiveProject(projectId: string, shouldFetchProject = false): Promise<Project> {
        let activeProject: Project | null;

        if (shouldFetchProject) {
            try {
                await Project.getById(projectId);
            } catch (e) {
                return Promise.reject(new Error(`${i18n.t('Projekt s oznakom')} ${projectId} ${i18n.t('ne postoji')}`));
            }
        }

        activeProject = await ProjectRepository.getProjectById(projectId);
        if (activeProject == null) {
            return Promise.reject(new Error(`${i18n.t('Projekt s oznakom')} ${projectId} ${i18n.t('ne postoji')}`));
        }

        return Promise.resolve(activeProject);
    }

    /**
     * Retrieve the default product values from the product and client if it exists
     * @param productId - The product id of the chosen product
     * @param clientId - The id of the projects' client if it exists
     * @param productSystemName - The chosen product system name if it exists
     * @return - A promise containing an array of id/value pairs of default product fields
     */
    public static async getDefaultProductFields(
        productId: string,
        clientId: string | null | undefined,
        productSystemName: string | null
    ) {
        const defaultProductFields: ChecklistFieldEntry[] = [];
        let defaultPriceListRegionNameValue = GlobalOptions.DEFAULT_PRICELIST_VERSION;

        if (productId == null) {
            return Promise.reject(new Error('No product was selected'));
        }

        const product: Product | null = ProductRepository.getById(productId);

        if (product == null) {
            return Promise.reject(new Error('Can not find the selected product'));
        }
        Object.entries(product.defaultFields).forEach((entry: any) => {
            defaultProductFields.push({
                id: entry[1].id,
                value: entry[1].value,
            });
        });

        if (productSystemName != null) {
            defaultProductFields.filter((productField) => productField.id !== GlobalOptions.SISTEM_PID);
            defaultProductFields.push({
                id: String(GlobalOptions.SISTEM_PID),
                value: productSystemName,
            });
        }

        if (clientId != null) {
            defaultPriceListRegionNameValue = ConfiguratorService.determinePricelistValue(clientId);
        }

        defaultProductFields.push({
            id: String(GlobalOptions.PRICELIST_PID),
            value: defaultPriceListRegionNameValue,
        });

        return Promise.resolve(defaultProductFields);
    }

    public static async getValidOfferId(projectId: string, routeOfferId: string | null, paymentTypeId: string | null) {
        let activeProject: Project;
        let offerObject;

        try {
            offerObject = await ConfiguratorService.getExistingOrNewOfferId(projectId, routeOfferId, paymentTypeId);
        } catch (e) {
            return Promise.reject(new Error(i18n.t('Pogreška prilikom dohvaćanja ponude') as string));
        }

        try {
            const projectExists = ProjectRepository.getProjectById(projectId) != null;
            activeProject = await ConfiguratorService.getActiveProject(
                projectId,
                offerObject.hasNewOfferBeenCreated || !projectExists
            );
        } catch (e) {
            return Promise.reject(e);
        }

        if (activeProject) {
            const projectOfferIds = activeProject.projectOfferRevisions.map((offer: Offer) => {
                return String(offer.offerId);
            });

            if (!projectOfferIds.includes(offerObject.offerId)) {
                return Promise.reject(
                    new Error(i18n.t('Odabrana ponuda se ne nalazi unutar odabranog projekta') as string)
                );
            }
        }
        return Promise.resolve(offerObject.offerId);
    }

    public static extractEditableFields(activeProductForm: ProductForm | null) {
        if (activeProductForm == null) {
            return [];
        }

        const fields: ChecklistField[] = [];
        activeProductForm.checklists.checklistSegments.forEach((checklistSegment: ChecklistSegment) => {
            checklistSegment.checklistFields.forEach((checklistField: ChecklistField) => {
                if (checklistField.multiposition) {
                    fields.push({
                        ...checklistField,
                        checklistSegmentName: checklistSegment.name,
                        isSegmentLocked: checklistSegment.isLocked,
                    });
                }
            });
        });
        return fields;
    }

    public static async addItemToOffer(
        productFormValues: ActiveProductFormValueObject,
        offerId: string,
        hasChecklistErrors: boolean,
        connection: number | null = null,
        production: boolean = false,
        productFormId: number
    ) {
        let offerItem;
        const formData: Array<{ id: string; value: ConfiguratorValue }> = formatFormData(
            productFormValues as ActiveProductFormValueObject
        );

        try {
            offerItem = await OfferItem.createNew(
                formData,
                offerId,
                hasChecklistErrors,
                connection,
                production,
                null,
                productFormId
            );
        } catch (e) {
            const errorObj = e as ErrorOptions;
            const errorMessages: string[] = [];

            errorObj?.response?.data?.errors?.forEach((error: JsonAPIError) => {
                errorMessages.push(error.title ? error.title : errorObj.message);
            });

            return Promise.reject(errorMessages);
        }

        return Promise.resolve(offerItem);
    }

    public static async addBulkItemsToOffer(payload: MultipositionProduct[], offerId: string) {
        if (!payload.length) {
            return Promise.resolve();
        }

        try {
            await OfferItem.createNewBulk(payload, offerId);
        } catch (e) {
            const errorObj = e as ErrorOptions;
            const errorMessages: string[] = [];

            errorObj?.response?.data?.errors?.forEach((error: JsonAPIError) => {
                errorMessages.push(error.title ? error.title : errorObj.message);
            });

            return Promise.reject(errorMessages);
        }
    }

    public static async updateBulkItemsInOffer(payload: MultipositionProduct[], offerId: string) {
        if (!payload.length) {
            return Promise.resolve();
        }

        try {
            await OfferItem.updateNewBulk(payload, offerId);
        } catch (e) {
            const errorObj = e as ErrorOptions;
            const errorMessages: string[] = [];

            errorObj?.response?.data?.errors?.forEach((error: JsonAPIError) => {
                errorMessages.push(error.title ? error.title : errorObj.message);
            });

            return Promise.reject(errorMessages);
        }
    }

    public static async addNewRow(
        initialProductFormValue: ActiveProductFormValueObject | null,
        updateFunctions: UpdateFunctionEntry[] | null,
        editableFields: ChecklistField[],
        availableSegments: RadioButtonGroupOptions[],
        addActiveProductForm: (activeProductForm: ActiveProductFormValueObject) => Promise<number | null>,
        offerItem?: OfferItem,
        shouldSetOfferItem = true,
        n = 1,
        productFormId = null,
        existingProductValues?: ChecklistFieldEntry[],
        clientId: string | null = null,
        isCustom: boolean = false
    ) {
        if (initialProductFormValue == null || updateFunctions == null) {
            return Promise.reject(new Error(i18n.t('Greška prilikom dohvaćanja podataka proizvoda') as string));
        }

        const activeProductFormIds = [];

        for await (const i of [...Array(n).keys()]) {
            const activeProductFormId = await addActiveProductForm(Object.assign({}, initialProductFormValue));
            if (existingProductValues != null) {
                await mapOfferItemsFormData(existingProductValues, activeProductFormId as number, clientId);
            }
            activeProductFormIds.push(activeProductFormId);
        }

        const filteredEditableFields = editableFields.filter((checklistField: ChecklistField) => {
            return checklistField.fieldType !== 'abort' && checklistField.fieldType !== 'warning';
        });
        return Promise.resolve(
            activeProductFormIds.map((activeProductFormId) => {
                return {
                    activeProductFormId,
                    updateFunctions,
                    editableFields: filteredEditableFields,
                    availableSegments,
                    productFormId: offerItem ? offerItem.offerItemProductSystem.product.productForm.id : productFormId,
                    productName: offerItem ? offerItem.offerItemProductSystem.product.name : null,
                    productId: offerItem ? offerItem.offerItemProductSystem.product.id : null,
                    offerItem: offerItem && shouldSetOfferItem ? offerItem : null,
                    isUpdatingData: true,
                    connection: offerItem ? offerItem.connection : null,
                    production: offerItem ? offerItem.production : isCustom,
                } as ProductRowItem;
            })
        );
    }

    /**
     * Retrieves all segment names and locked status from checklists' segments
     * @param activeProductForm - The active product from containing all the checklist segments
     * @return - An array of objects with label/value pairs of checklist segment names and locked status
     */
    public static getSegmentNames(activeProductForm: ProductForm | null) {
        if (activeProductForm == null) {
            return [];
        }

        return activeProductForm.checklists.checklistSegments.map((checklistSegment) => {
            return {
                label: checklistSegment.name,
                value: checklistSegment.name,
                isLocked: checklistSegment?.isLocked,
            };
        });
    }

    /**
     * Determines the visibility for a product slot row
     * A column should be hidden if the visibility state is true or if all fields in a column are disabled
     * @param firstRow - An array of booleans containing all visibility states determined by the selected segment
     * @param allDisabledStates - An array of arrays of booleans containing all the current disabled states
     * for each field and product slot row
     * @return - An array of booleans determining the visibility of a column
     */
    public static determineColumnVisibility(firstRow: boolean[], allDisabledStates: boolean[][]) {
        return firstRow.map((value, index) => {
            if (value) {
                return true;
            }
            return allDisabledStates
                .map((disabledState) => disabledState[index])
                .every((disabledState) => disabledState);
        });
    }

    /**
     * Extracts isDisabledFieldArray from each product slot row
     * The isDisabledFieldArray is an array of booleans determining whether a field is disabled or not based
     * on the fields' visibility function
     * @param productSlotRows - All active product slot rows
     * @return - An array of arrays with booleans determining the disabled state for a field
     */
    // @ts-ignore
    public static getAllDisabledStatesFromRow(productSlotRows: ProductSlotRow[]) {
        // @ts-ignore
        return productSlotRows.map((productSlotRow) => productSlotRow.isDisabledFieldArray);
    }

    /**
     * Determines the visibility of editable fields based on the selected segment
     * Returns false for a field if it's visible (because it is not hidden)
     * @param firstActiveItemRow - The first active item row which contains the editable fields
     * (not necessary to calculate for each field because it's the same for each row)
     * @param selectedSegment - The segment that is selected from the tab selector
     * @return - An array of booleans determining the visibility of a field
     */
    public static getInitialColumnVisibilityBasedOnSelectedSegment(
        firstActiveItemRow: ProductRowItem,
        selectedSegment: string
    ) {
        return firstActiveItemRow.editableFields.map((checklistField) => {
            return (
                !COMMON_FIELD_PIDS.includes(checklistField.pId) &&
                selectedSegment !== 'All' &&
                checklistField.checklistSegmentName !== selectedSegment
            );
        });
    }

    /**
     * Updates the active product form value for pricelist to the one that is selected on the client. If it
     * is not selected on the client, it sets the default
     * @param clientId - The id of the current client
     * @param pId - The pId of the pricelist field (usually p7407 - GlobalOptions.PRICELIST_PID)
     * @param activeProductFormId - The id of the active product form that needs to be updated
     * @return - A promise
     */
    public static async updatePricelistValueToClientPricelistValue(
        clientId: string,
        pId: string,
        activeProductFormId: number
    ) {
        const pricelistValue = ConfiguratorService.determinePricelistValue(clientId);
        await store.dispatch('configurator/updateActiveProductFormValue', {
            pId,
            value: pricelistValue,
            productFormId: activeProductFormId,
        });

        return Promise.resolve();
    }

    /**
     * Determine the priceListRegion value from the client. Returns the default if it does not exist on the client
     * @param clientId - The id of the current client
     * @return - The priceListRegion name
     */
    public static determinePricelistValue(clientId: string) {
        const client = ClientRepository.getById(clientId);

        if (client == null) {
            return Promise.reject(new Error('Selected client does not exist'));
        }

        return client.clientPriceListRegion == null
            ? GlobalOptions.DEFAULT_PRICELIST_VERSION
            : client.clientPriceListRegion.name;
    }
}
