import { Model } from '@vuex-orm/core';
import ProductAPI from '@/api/ProductAPI';
import ProductSystem from '@/models/ProductSystem';
import Image from '@/models/Image';
import ProductForm from '@/models/ProductForm';

export default class Product extends Model {
    public get productDefaultFields() {
        // @ts-ignore
        return this.defaultFields;
    }

    public static entity = 'product';

    public static fields() {
        return {
            id: this.attr(''),
            type: this.string('products'),
            category: this.attr(''),
            defaultFields: this.attr([]),
            name: this.attr(''),
            productForm: this.attr({}),
            subCategory: this.attr(null),
            category_id: this.attr(''),
            productSystem_ids: this.attr([]),
            productSystems: this.hasManyBy(ProductSystem, 'productSystem_ids'),
            icon_id: this.attr(null),
            icon: this.belongsTo(Image, 'icon_id'),
            windowDimensionWizardEnabled: this.attr(false),
        };
    }

    public static async getAll() {
        let products;

        try {
            products = await ProductAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: products.data,
            insertOrUpdate: ['image'],
        });

        return Promise.resolve();
    }

    public static async getById(productId: string) {
        let product;

        try {
            product = await ProductAPI.getById(productId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: product.data,
            insertOrUpdate: ['image', 'uploadedPdf'],
        });

        return Promise.resolve(product);
    }

    public static async searchByNameAndProductSystemName(query: string) {
        let product;

        try {
            product = await ProductAPI.searchByProductNameOrProductSystemName(query);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(product.data);
    }
    public id!: string;
    public type!: string;
    public category!: string;
    public defaultFields!: any;
    public name!: string;
    public productForm!: ProductForm;
    public subCategory!: any;
    public productSystems!: ProductSystem[];
    public icon!: Image;
    public windowDimensionWizardEnabled!: boolean;
}
