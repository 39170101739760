import PriceListRegion from '@/models/PriceListRegion';

export default class PriceListRegionRepository {
    public static getAll() {
        return PriceListRegion.all();
    }

    public static getById(id: string) {
        return PriceListRegion.query().whereId(id).withAll().first();
    }

    public static getByName(name: string) {
        return PriceListRegion.query().where('name', name).withAll().first();
    }
}
