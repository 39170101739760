import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class AwardImageAPI {
    public static async createNew(formData: FormData) {
        let image;

        try {
            image =
                await ApiController.post(ApiRoutes.awardImage.create, formData) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(image);
    }

    public static async updateExisting(file: any) {
        let image;

        const request = {
            data: {
                type: 'award_images',
                id: file.uid,
                attributes: {
                    title: file.title,
                },
            },
        };

        try {
            image =
                await ApiController.patch(ApiRoutes.awardImage.update(file.uid), request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(image);
    }

}
