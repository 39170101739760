var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('label',[_c('span',{staticClass:"required"},[_vm._v("* ")]),_vm._v(_vm._s(_vm.$t('Tip sljedećeg koraka')))]),_c('a-select',{class:{
                    'has-errors': _vm.activeErrors.includes('activityType'),
                },attrs:{"filterOption":"","optionFilterProp":"children","show-search":"","data-test":"next-step-activity-type"},on:{"change":_vm.onInput},model:{value:(_vm.localActivityType),callback:function ($$v) {_vm.localActivityType=$$v},expression:"localActivityType"}},_vm._l((_vm.activityTypes),function(activityType){return _c('a-select-option',{key:activityType.id,attrs:{"value":activityType.id}},[_vm._v(" "+_vm._s(activityType.name)+" ")])}),1)],1),_c('a-col',{attrs:{"span":12}},[_c('label',[_vm._v(_vm._s(_vm.$t('Naslov sljedećeg koraka')))]),_c('a-input',{attrs:{"data-test":"next-step-title"},on:{"change":_vm.onInput},model:{value:(_vm.localName),callback:function ($$v) {_vm.localName=$$v},expression:"localName"}})],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('label',[_c('span',{staticClass:"required"},[_vm._v("* ")]),_vm._v(_vm._s(_vm.$t('Datum od')))]),_c('a-date-picker',{class:{ 'has-errors': _vm.activeErrors.includes('date') },attrs:{"format":_vm.locale.lang.dateTimeFormat,"locale":_vm.locale,"showTime":true,"showToday":false},on:{"change":_vm.onInput},model:{value:(_vm.localDateFrom),callback:function ($$v) {_vm.localDateFrom=$$v},expression:"localDateFrom"}})],1),_c('a-col',{attrs:{"span":12}},[_c('label',[_c('span',{staticClass:"required"},[_vm._v("* ")]),_vm._v(_vm._s(_vm.$t('Datum do')))]),_c('a-date-picker',{class:{ 'has-errors': _vm.activeErrors.includes('date') },attrs:{"format":_vm.locale.lang.dateTimeFormat,"locale":_vm.locale,"showTime":true,"showToday":false},on:{"change":_vm.onInput},model:{value:(_vm.localDateTo),callback:function ($$v) {_vm.localDateTo=$$v},expression:"localDateTo"}})],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('Podsjetnik za sljedeći korak'))+" ")]),_c('a-select',{class:{
                    'has-errors': _vm.activeErrors.includes('reminderOption'),
                },attrs:{"filterOption":"","optionFilterProp":"children","show-search":"","data-test":"next-step-reminder"},on:{"change":_vm.onInput},model:{value:(_vm.localReminderOption),callback:function ($$v) {_vm.localReminderOption=$$v},expression:"localReminderOption"}},_vm._l((_vm.reminderOptions),function(reminderOption){return _c('a-select-option',{key:reminderOption.id,attrs:{"value":reminderOption.id}},[_vm._v(" "+_vm._s(reminderOption.name)+" ")])}),1)],1),_c('a-col',{attrs:{"span":12}},[_c('label',[_c('span',{staticClass:"required"},[_vm._v("* ")]),_vm._v(_vm._s(_vm.$t('Odgovorna osoba'))+" ")]),_c('a-select',{class:{
                    'has-errors': _vm.activeErrors.includes('assignedUser'),
                },attrs:{"filterOption":"","optionFilterProp":"children","show-search":"","data-test":"next-step-assigned-person"},on:{"change":_vm.onInput},model:{value:(_vm.localAssignedUser),callback:function ($$v) {_vm.localAssignedUser=$$v},expression:"localAssignedUser"}},_vm._l((_vm.activeUsers),function(user){return _c('a-select-option',{key:user.name,class:['u-b1', { 'is-hidden': user.isArchived }],attrs:{"disabled":user.isArchived,"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" ")])}),1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('Poruka sljedećeg koraka'))+" ")]),_c('chat-rich-text-editor',{key:_vm.refreshKey,attrs:{"default-value":_vm.localNote,"should-have-border":true,"shouldShowAttachments":false},on:{"updateContent":_vm.onUpdateContent}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }