
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChecklistSegmentModule from '@/components/views/productView/ChecklistSegmentModule.vue';
import ProductView from '@/views/ProductView.vue';
import ConfiguratorService from '@/services/ConfiguratorService';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { Getter, Action } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { fetchAndRegisterJsonLogicFunctionsIfNecessary } from '@/helpers/JsonLogicHelper';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ProductSystemWizard from '@/models/ProductSystemWizard';
import ProductSystemWizardTranslation from '@/models/ProductSystemWizardTranslation';
import ProductSystemWizardRepository from '@/repositories/ProductSystemWizardRepository';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';

@Component({
    name: 'DimensionsWizard',
    components: {
        ChecklistSegmentModule,
        ProductView,
    },
})
export default class DimensionsWizard extends Vue {
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Prop({ required: true }) private wizardData!: any | null;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private productSystemWizardData: ProductSystemWizardTranslation | null = null;
    private quantity: number = 1;
    private activeProductFormId: number | null = null;
    private shouldOpenDimensionsWizardConfigurator = false;

    private get productFormValues() {
        if (this.activeProductFormId == null) {
            return null;
        }

        return this.getProductFormValues(this.activeProductFormId);
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }
        return this.$i18n.locale;
    }
    private get combinationsCustomFields() {
        return [{ pId: 'p740593', value: 'Dimenzije oken', type: 'dropdown' }];
    }
    private setActiveProductFormId(id: number | null) {
        this.activeProductFormId = id;
    }
    private async navigateToProduct(offerItemId: string) {
        const params = {
            productFormId: this.wizardData.productFormId,
            productSystemId: this.wizardData.productSystemId,
            productId: this.wizardData.productId,
            offerItemId,
            alreadyInStore:
                this.activeProductFormId !== undefined && this.activeProductFormId !== null
                    ? this.activeProductFormId.toString()
                    : null,
        };
        this.resetDimensionsDropdown();
        this.$store.dispatch('configurator/setCustomValuesOverride', this.combinationsCustomFields);

        this.$router.push({
            name: RouteNames.configurator,
            // @ts-ignore
            params,
            query: {
                projectId: this.wizardData.projectId,
                offerId: this.wizardData.offerId,
                clientId: this.wizardData.clientId,
                productSystemName: this.wizardData.productSystemName,
            },
        });
    }

    private async openMultipositionPopup() {
        this.loadingOverlay.start();

        const payload = {
            productFormId: this.wizardData.productFormId,
            productId: this.wizardData.productId,
            productSystemName: this.wizardData.productSystemName,
            productName: this.wizardData.productName,
            productSystemId: this.wizardData.productSystemId,
            multipositionType: 'detailed',
            timesToMultiply: this.quantity,
            clientId: this.wizardData.clientId,
            clientsPaymentTypeId: this.wizardData.clientsPaymentTypeId,
            offerId: this.wizardData.offerId,
            projectId: this.wizardData.projectId,
            alreadyInStore:
                this.activeProductFormId !== undefined && this.activeProductFormId !== null
                    ? this.activeProductFormId.toString()
                    : null,
            customDimensions: this.combinationsCustomFields,
        };
        this.closePopup();
        this.resetDimensionsDropdown();

        await fetchAndRegisterJsonLogicFunctionsIfNecessary();

        EventBus.$emit(EventBusEvents.openAddProductsPopup, {
            popupEvent: PopupEvents.openAddProducts,
            data: payload,
        });
        this.loadingOverlay.stop();
    }

    private resetDimensionsDropdown() {
        this.updateActiveProductFormValue({
            pId: 'p740593',
            value: 'Dimenzije senčila',
            productFormId: this.activeProductFormId as number,
        });
    }

    private async onConfirm() {
        const projectId = this.wizardData.projectId;
        const routeOfferId = this.wizardData.offerId;
        let validOfferId;
        this.loadingOverlay.start();
        try {
            validOfferId = await ConfiguratorService.getValidOfferId(
                projectId,
                routeOfferId,
                this.wizardData.clientsPaymentTypeId ?? null
            );
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }
        if (this.quantity === 1) {
            try {
                this.shouldOpenDimensionsWizardConfigurator = true;
                await this.$nextTick();

                this.loadingOverlay.stop();
                this.closePopup();
                this.navigateToProduct(this.wizardData.offerId); // offerItem.data.id
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: e as string,
                });
                this.loadingOverlay.stop();
                return;
            }
        } else if (this.quantity > 1) {
            try {
                this.loadingOverlay.stop();
                await this.openMultipositionPopup();
            } catch (error) {
                if (error && (error as any).message) {
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: (error as Error).message,
                    });
                }
                this.loadingOverlay.stop();
                return;
            }
        }
        this.quantity = 1;
    }

    private closePopup() {
        EventBus.$emit(EventBusEvents.closePopup);
        this.quantity = 1;
    }
    private async fetchProductSystemWizard() {
        const byProductSystemResponse = await ProductSystemWizard.getByProductSystemId(this.wizardData.productSystemId);
        if (!byProductSystemResponse?.data || byProductSystemResponse.data.length === 0) {
            return;
        }
        const productSystemWizardData = ProductSystemWizardRepository.getById(byProductSystemResponse.data[0].id);
        if (!productSystemWizardData) {
            return;
        }
        this.productSystemWizardData =
            (productSystemWizardData as ProductSystemWizard).translations.find(
                (t) => t.locale === this.currentLocale
            ) ?? null;
    }

    private async mounted() {
        this.wizardDataChanged();
    }

    @Watch('wizardData')
    private wizardDataChanged() {
        if (this.wizardData?.productSystemId) {
            this.fetchProductSystemWizard();
        }
    }
}
