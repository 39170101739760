var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"context-menu",style:(_vm.position)},[_c('ul',{staticClass:"menu"},[_c('li',{staticClass:"context-menu__open-tab"},[(_vm.entity.type === 'project')?_c('router-link',{attrs:{"to":{
                    name: _vm.routeNames.project,
                    params: {
                        id: _vm.entity.id,
                    },
                    query: {
                        initialTab: _vm.projectTabValues.Basic,
                    },
                },"target":"_blank"}},[_c('a-icon',{staticClass:"field-icon",attrs:{"type":"export"}}),_vm._v(_vm._s(_vm.$t('Open link in new tab'))+" ")],1):_vm._e(),(_vm.entity.type === 'client')?_c('router-link',{attrs:{"to":{
                    name: _vm.routeNames.editClient,
                    params: {
                        clientId: _vm.entity.id,
                    },
                    query: {
                        initialTab: _vm.clientTabs.CUSTOMER,
                    },
                },"target":"_blank"}},[_c('a-icon',{staticClass:"field-icon",attrs:{"type":"export"}}),_vm._v(_vm._s(_vm.$t('Open link in new tab'))+" ")],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }