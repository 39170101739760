import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import UserGroupConfig from '@/models/interfaces/UserGroup';

export default class UserGroupAPI {
    public static async getAll() {
        let userGroups;

        try {
            userGroups = await ApiController.get(ApiRoutes.userGroup.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroups);
    }

    public static async getById(userGroupId: string) {
        let userGroup;

        try {
            userGroup = await ApiController.get(ApiRoutes.userGroup.getById(userGroupId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroup);
    }

    public static async update(userGroupId: string, userGroupInfo: UserGroupConfig) {
        let userGroup;
        const request = {
            data: {
                type: 'user_groups',
                id: userGroupId,
                attributes: {
                    name: userGroupInfo.name,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            // @ts-ignore
                            id: userGroupInfo.locale.id,
                        },
                    },
                },
            },
        };

        if (userGroupInfo.client) {
            // @ts-ignore
            request.data.relationships.client = {
                data: {
                    type: 'clients',
                    // @ts-ignore
                    id: userGroupInfo.client,
                },
            };
        }

        try {
            userGroup =
                await ApiController.patch(
                    ApiRoutes.userGroup.update(userGroupId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroup);
    }

    public static async create(userGroupInfo: UserGroupConfig) {
        let userGroup;
        const request = {
            data: {
                type: 'user_groups',
                attributes: {
                    name: userGroupInfo.name,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            // @ts-ignore
                            id: userGroupInfo.locale.id,
                        },
                    },
                },
            },
        };

        if (userGroupInfo.client) {
            // @ts-ignore
            request.data.relationships.client = {
                data: {
                    type: 'clients',
                    // @ts-ignore
                    id: userGroupInfo.client,
                },
            };
        }

        try {
            userGroup =
                await ApiController.post(
                    ApiRoutes.userGroup.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userGroup);
    }
}
