import { Model } from '@vuex-orm/core';
import MessageAPI from '@/api/MessageAPI';
import PlainMessage from '@/models/PlainMessage';
import CMSUser from '@/models/CMSUser';
import NextStep from '@/models/NextStep';

export default class ChatMessage extends Model {
    public static entity = 'chatMessage';

    public static fields() {
        return {
            id: this.string(null),
            createdAt: this.string(''),
            content_id: this.attr(null),
            content: this.belongsTo(PlainMessage, 'content_id'),
            madeByUser_id: this.attr(null),
            madeByUser: this.belongsTo(CMSUser, 'madeByUser_id'),
            nextStep_id: this.attr(null),
            nextStep: this.belongsTo(NextStep, 'nextStep_id'),
        };
    }

    public static async getAll() {
        let messages;
        try {
            messages = await MessageAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: messages.data,
        });
        return Promise.resolve();
    }

    public static async getById(messageId: string) {
        let message;
        try {
            message = await MessageAPI.getById(messageId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: message.data,
        });
        return Promise.resolve(message);
    }

    public static async updateExisting(messageId: string, messageInfo: any) {
        let message;
        try {
            message = await MessageAPI.update(messageId, messageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: message.data,
        });
        return Promise.resolve(message);
    }

    public static async removeNextStep(messageId: string) {
        let message;
        try {
            message = await MessageAPI.removeNextStep(messageId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: message.data,
        });
        return Promise.resolve(message);
    }

    public static async createNew(messageInfo: any) {
        let message;
        try {
            message = await MessageAPI.create(messageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: message.data,
        });
        return Promise.resolve(message);
    }

    public static async deleteExisting(messageId: string) {
        try {
            await MessageAPI.delete(messageId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ChatMessage.delete(messageId);
        return Promise.resolve();
    }
    public id!: string;
    public createdAt!: string;
    public content!: PlainMessage;
    public madeByUser!: CMSUser;
    public nextStep!: NextStep | null;
}
