import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';
import { JwtData } from '@/interfaces/general/JwtData';

const getters: GetterTree<ILocalState, {}> = {
    allowedUserGroups(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return [];
        }
        const jwtData = state.jwtData as JwtData;
        return jwtData.userGroups;
    },
    userRights(state) {
        if (Object.keys(state.jwtData).length <= 0 || state.currentUserGroup == null) {
            return [];
        }
        const jwtData = state.jwtData as JwtData;

        return Object.keys(jwtData.userRights![state.currentUserGroup]);
    },
    userRoles(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return [];
        }

        const jwtData = state.jwtData as JwtData;
        return jwtData.roles;
    },

    userId(state) {
        if (Object.keys(state.jwtData).length <= 0) {
            return null;
        }

        const jwtData = state.jwtData as JwtData;
        return jwtData.id;
    },
    currentUserGroup(state) {
        return state.currentUserGroup;
    },

    jwtData(state) {
        return state.jwtData;
    },
};

export default getters;
