import Tax from '@/models/Tax';

export default class TaxRepository {
    public static getAll() {
        return Tax.query().withAll().all();
    }

    public static getById(id: string) {
        return Tax.query().whereId(id).withAll().first();
    }
}
