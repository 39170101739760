import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableProductSystemDetailsFields } from '@/interfaces/components/productSystemDetails/AvailableProductSystemDetailsFields';
import { TJsonApiBody, TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';
import { UploadFile } from 'ant-design-vue/types/upload';
import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';

export default class ProductSystemDetailsAPI {
    public static async getAll() {
        let productSystemDetails;
        try {
            productSystemDetails = await ApiController.get(ApiRoutes.productSystemDetails.getAll) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(productSystemDetails);
    }

    public static async getById(productSystemDetailsId: string, locale?: string | null) {
        let productSystemDetails;

        try {
            productSystemDetails =
                await ApiController.get(
                    ApiRoutes.productSystemDetails.getSingle(productSystemDetailsId), locale) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystemDetails);
    }

    public static async updateExisting(
        productSystemDetailsId: string,
        productSystemDetailsInfo: AvailableProductSystemDetailsFields,
        productSystemId: string,
        locale?: string | null) {
        let productSystemDetails;

        const request: TJsonApiBody = {
            data: {
                type: 'product_system_details',
                id: productSystemDetailsId,
                attributes: {
                    title: productSystemDetailsInfo.title,
                    description: productSystemDetailsInfo.description,
                },
            },
        };
        const relationships: TJsonApiRelationships = {
            productSystem: {
                data: {
                    type: 'product_systems',
                    id: productSystemId,
                },
            },
        };

        if (productSystemDetailsInfo.catalogueImage.length > 0) {
            relationships.catalogueImage = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.catalogueImage[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.catalogueImage = {data: null};
        }

        if (productSystemDetailsInfo.icon.length > 0) {
            relationships.icon = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.icon[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.icon = {data: null};
        }

        if (productSystemDetailsInfo.pdfOfferImage.length > 0) {
            relationships.pdfOfferImage = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.pdfOfferImage[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.pdfOfferImage = {data: null};
        }

        if (productSystemDetailsInfo.documents) {
            const documentData = productSystemDetailsInfo.documents.map((document: UploadFile) => {
                return {
                    type: 'uploaded_pdfs',
                    id: document.uid,
                };
            });
            relationships.documents = {
                data: documentData,
            };
        }

        if (productSystemDetailsInfo.gallery) {
            const galleryData = productSystemDetailsInfo.gallery.map((image: UploadFile) => {
                return {
                    type: 'images',
                    id: image.uid,
                };
            });
            relationships.gallery = {
                data: galleryData,
            };
        }

        if (productSystemDetailsInfo.configurations) {
            const configurationData = productSystemDetailsInfo.configurations.map(
                (configuration: ProductSystemDetailsConfiguration) => {
                    return {
                        type: 'product_system_details_configurations',
                        id: configuration.id,
                    };
                });
            relationships.configurations = {
                data: configurationData,
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            productSystemDetails =
                await ApiController.patch(
                    ApiRoutes.productSystemDetails.updateExisting(productSystemDetailsId),
                    request,
                    locale,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystemDetails);
    }

    public static async createNew(
        productSystemDetailsInfo: AvailableProductSystemDetailsFields,
        productSystemId: string,
        locale?: string | null) {
        let newProductDetails;

        const request: TJsonApiBody = {
            data: {
                type: 'product_system_details',
                attributes: {
                    title: productSystemDetailsInfo.title,
                    description: productSystemDetailsInfo.description,
                },
            },
        };

        const relationships: TJsonApiRelationships = {
            productSystem: {
                data: {
                    type: 'product_systems',
                    id: productSystemId,
                },
            },
        };


        if (productSystemDetailsInfo.catalogueImage.length > 0) {
            relationships.catalogueImage = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.catalogueImage[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.catalogueImage = {data: null};
        }

        if (productSystemDetailsInfo.icon.length > 0) {
            relationships.icon = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.icon[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.icon = {data: null};
        }

        if (productSystemDetailsInfo.pdfOfferImage.length > 0) {
            relationships.pdfOfferImage = {
                data: {
                    type: 'images',
                    id: productSystemDetailsInfo.pdfOfferImage[0].uid,
                },
            };
        } else {
            // @ts-ignore
            relationships.pdfOfferImage = {data: null};
        }

        if (productSystemDetailsInfo.documents) {
            const documentData = productSystemDetailsInfo.documents.map((document: UploadFile) => {
                return {
                    type: 'uploaded_pdfs',
                    id: document.uid,
                };
            });
            relationships.documents = {
                data: documentData,
            };
        }

        if (productSystemDetailsInfo.gallery) {
            const galleryData = productSystemDetailsInfo.gallery.map((image: UploadFile) => {
                return {
                    type: 'images',
                    id: image.uid,
                };
            });
            relationships.gallery = {
                data: galleryData,
            };
        }

        if (productSystemDetailsInfo.configurations) {
            const configurationData = productSystemDetailsInfo.configurations.map(
                (configuration: ProductSystemDetailsConfiguration) => {
                    return {
                        type: 'product_system_details_configurations',
                        id: configuration.id,
                    };
                });
            relationships.configurations = {
                data: configurationData,
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            newProductDetails =
                await ApiController.post(ApiRoutes.productSystemDetails.create, request, locale) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProductDetails);
    }

}
