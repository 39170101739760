import FlaggedPdfValue from '@/models/FlaggedPdfValue';

export default class FlaggedPdfValueRepository {
    public static getAll() {
        return FlaggedPdfValue.all();
    }

    public static getById(flaggedPdfValueId: string) {
        return FlaggedPdfValue.query()
            .whereId(flaggedPdfValueId)
            .withAll()
            .first();
    }
}
