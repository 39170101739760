import { Model } from '@vuex-orm/core';
import PriceManipulationTypeAPI from '@/api/PriceManipulationTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class PriceManipulationType extends Model {
    public static entity = 'priceManipulationType';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
        };
    }

    public static async getAll() {
        let priceManipulationTypes;

        if (ss.has('priceManipulationTypes')) {
            priceManipulationTypes = ss.getAndParse('priceManipulationTypes');
        } else {
            try {
                const response = await PriceManipulationTypeAPI.getAll();
                priceManipulationTypes = response.data;

                ss.stringifyAndSave('priceManipulationTypes', priceManipulationTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: priceManipulationTypes,
        });

        return Promise.resolve();
    }

    public static async getById(priceManipulationTypeId: string) {
        let priceManipulationType;
        try {
            priceManipulationType = await PriceManipulationTypeAPI.getById(priceManipulationTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulationType.data,
        });
        return Promise.resolve(priceManipulationType);
    }

    public id!: string;
    public name!: string | null;
}
