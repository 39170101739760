import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import TaxConfig from '@/models/interfaces/Tax';

export default class TaxAPI {
    public static async getAll() {
        let taxes;

        try {
            taxes = await ApiController.get(ApiRoutes.taxes.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(taxes);
    }

    public static async getById(taxId: string) {
        let tax;

        try {
            tax = await ApiController.get(ApiRoutes.taxes.getById(taxId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(tax);
    }

    public static async update(taxId: string, taxConfig: TaxConfig) {
        let tax;
        const request = {
            data: {
                type: 'taxes',
                id: taxId,
                attributes: {
                    montage: taxConfig.montage,
                    noMontage: taxConfig.noMontage,
                    montageNonTax: taxConfig.montageNonTax,
                    noMontageNonTax: taxConfig.noMontageNonTax,
                    company: taxConfig.company,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: taxConfig.country.id,
                        },
                    },
                },
            },
        };

        try {
            tax =
                await ApiController.patch(
                    ApiRoutes.taxes.update(taxId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(tax);
    }

    public static async create(taxConfig: TaxConfig) {
        let tax;
        const request = {
            data: {
                type: 'taxes',
                attributes: {
                    montage: taxConfig.montage,
                    noMontage: taxConfig.noMontage,
                    montageNonTax: taxConfig.montageNonTax,
                    noMontageNonTax: taxConfig.noMontageNonTax,
                    company: taxConfig.company,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: taxConfig.country.id,
                        },
                    },
                },
            },
        };

        try {
            tax =
                await ApiController.post(
                    ApiRoutes.taxes.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(tax);
    }
}
