import DropdownOptionAPI from '@/api/DropdownOptionAPI';
import { Model } from '@vuex-orm/core';

export default class DropdownOption extends Model {
    public static entity = 'dropdownOption';

    public static fields() {
        return {
            id: this.attr(null),
            value: this.string(''),
        };
    }

    public static async getAll() {
        let dropdownOptions;
        try {
            dropdownOptions = await DropdownOptionAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: dropdownOptions.data,
        });
        return Promise.resolve();
    }

    public id!: string;
    public value!: string;
}
