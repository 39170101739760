
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { UserRepository } from '@/repositories/UserRepository';
import User from '@/models/User';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
    name: 'UserSettingsSelect',
    components: {
        // RLogoIcon,
    },
})
export default class UserSettingsSelect extends Vue {
    @Prop({ required: true }) private collapsed!: boolean;
    private userName: string = '';

    private get settingsRoute() {
        return { name: RouteNames.userSettings };
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private preventLeavingNavOnSubMenuHover(value: boolean) {
        this.$emit('isSubMenuHovering', value);
    }

    private async logout() {
        await User.logout();
        this.$router.push({ name: RouteNames.login });
    }

    private mounted() {
        if (this.currentUser) {
            this.userName = this.currentUser.name;
        }
    }
}
