
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ChecklistFieldModule from '@/components/views/productView/ChecklistFieldModule.vue';
import ChecklistSegment from '@/models/ChecklistSegment';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import Offer from '@/models/Offer';
import { filterProductsFromOfferItems } from '@/helpers/OfferItemTableHelper';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';

@Component({
    name: 'ChecklistSegmentModule',
    components: { ChecklistFieldModule },
})
export default class ChecklistSegmentModule extends Vue {
    @Prop({ required: true })
    private productFormValues!: ActiveProductFormValueObject | null;
    @Prop({ required: true }) private checklistSegment!: ChecklistSegment;
    @Prop({ required: true }) private activeProductFormId!: number | null;
    @Prop({ default: [] }) private pIdFieldErrors!: string[];
    @Prop({ default: 0 }) private levelOverride!: number;
    @Prop({ default: null }) private offer!: Offer;
    @Prop({ default: null }) private offerItem!: OfferItem;
    @Prop({ default: null }) private currentProductForm!: any;
    @Prop({ default: false }) private isConfigurator!: boolean;
    @Prop({ default: false }) private noSegments!: boolean;

    private connection: number | null = null;
    private level: number = 0;
    private production = true;
    private customProductAdditionalFields = {
        production: true,
        connection: null,
    };
    private isAtLeastOneVisibleProperty = true;

    public switchLevel(level: number) {
        this.level = level;
    }

    @Watch('levelOverride', {
        immediate: true,
    })
    private overrideLevel(newVal: number, oldVal: number): void {
        this.level = newVal;
    }

    private get filteredChecklistFields() {
        return this.checklistSegment.checklistFields.filter((checklistField) => {
            return checklistField.fieldType !== 'abort' && checklistField.fieldType !== 'warning';
        });
    }

    private checklistFieldHasVisibilityFunction(checklistField: any) {
        return checklistField.checklistVisibleFunction != null;
    }

    private filterProductsFromOfferItems(offerItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>) {
        return filterProductsFromOfferItems(offerItems, false);
    }

    private created() {
        if (this.offerItem) {
            this.connection = this.offerItem.connection;
            this.production = this.offerItem.production;
        }
    }

    private mounted() {
        setTimeout(() => {
            this.onLevelChange();
        }, 500);
    }

    @Watch('level')
    private async onLevelChange() {
        let isVisible = false;

        await this.$nextTick();
        const segmentFields = this.$refs.checklistField as any;

        if (!segmentFields) {
            return;
        }

        for (let i = 0; i < segmentFields.length; i++) {
            if (window.getComputedStyle(segmentFields[i].$el).display !== 'none') {
                isVisible = true;
                break;
            }
        }

        this.isAtLeastOneVisibleProperty = isVisible;
    }
}
