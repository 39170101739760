import Attachment from '@/models/Attachment';

export default class AttachmentRepository {
    public static getByLeadId(leadId: string) {
        return Attachment.query().where('lead_id', leadId).get();
    }

    public static getAll() {
        return Attachment.query().where('displayName', (name: string) => name !== '').get();
    }

    public static getById(id: string) {
        return Attachment.query().whereId(id).first();
    }
}
