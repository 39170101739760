
import { Component, Prop, Vue } from 'vue-property-decorator';
import PriceListRegionRepository from '@/repositories/PriceListRegionRepository';
import PriceTypeRepository from '@/repositories/PriceTypeRepository';
import DiscountInformationRow from '@/components/global/client/DiscountInformationRow.vue';
import { DiscountTableEntry } from '@/interfaces/components/clients/DiscountTableEntry';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { EventBus } from '@/helpers/EventBusHelper';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
import { fetchAndTransformMaterials } from '@/helpers/Discount/DiscountHelper';

@Component({
    name: 'DiscuntInformation',
    components: {
        DiscountInformationRow,
    },
})
export default class DiscuntInformation extends Vue {
    @Prop({ required: true }) private value!: any;
    @Prop({ required: false }) private deletedDiscountIds!: string[];

    private discounts: DiscountTableEntry[] = [...JSON.parse(JSON.stringify(this.value.discounts))];
    private loading: boolean = false;

    private get priceTypes() {
        return PriceTypeRepository.getAll();
    }

    private get priceListRegions() {
        return PriceListRegionRepository.getAll();
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    public canUserSeeDiscounts() {
        return this.userRights.includes(UserRightsEnum.VIEW_DISCOUNTS);
    }

    public canUserEditDiscounts() {
        return this.userRights.includes(UserRightsEnum.EDIT_DISCOUNTS);
    }

    public canUserSeePriceTypeAndPricelist() {
        return this.userRights.includes(UserRightsEnum.VIEW_PRICETYPE_AND_PRICELIST);
    }

    public canUserEditPriceTypeAndPricelist() {
        return this.userRights.includes(UserRightsEnum.EDIT_PRICETYPE_AND_PRICELIST);
    }

    private async beforeMount() {
        EventBus.$on('discountsUpdated', this.updateDiscounts);

        this.prepareAndCacheDefaultMaterials();
    }

    private async prepareAndCacheDefaultMaterials() {
        if (!ss.has('materials')) {
            this.loading = true;
            const materials = await fetchAndTransformMaterials('');

            ss.stringifyAndSave('materials', materials);
            this.loading = false;
        }
    }

    private beforeDestroy() {
        EventBus.$off('discountsUpdated', this.updateDiscounts);
    }

    private updateDiscounts() {
        this.discounts = [...JSON.parse(JSON.stringify(this.value.discounts))];
    }

    private emptyDiscount(): DiscountTableEntry {
        return {
            isEditing: true,
            isPercentage: true,
        };
    }

    private onAddDiscount() {
        this.discounts.push(this.emptyDiscount());
        this.updateIsEditingDiscounts();
    }

    private cancelChanges(i: number) {
        if (!this.value.discounts[i]) {
            this.discounts.splice(i, 1);
        }
    }

    private onDeleteDiscount(i: number) {
        if (this.deletedDiscountIds && this.discounts[i].id) {
            this.$emit('updateDeletedDiscountIds', this.discounts[i].id);
        }

        this.discounts.splice(i, 1);
    }

    private onUpdateDiscount(i: number) {
        this.value.discounts[i] = { ...this.discounts[i] };
        this.$emit('input', this.value);
    }

    private updateIsEditingDiscounts() {
        this.$emit('updateIsEditingDiscounts', this.discounts.findIndex((d: DiscountTableEntry) => d.isEditing) >= 0);
    }
}
