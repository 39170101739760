import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import LocaleAPI from '@/api/LocaleAPI';

export default class Locale extends Model {

    public static entity = 'locale';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            code: this.string(''),
        };
    }

    public static async getAll() {
        let locales;
        try {
            locales = await LocaleAPI.getAll() as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: locales.data,
        });
        return Promise.resolve(locales);
    }

    public name!: string;
    public code!: string;
    public id!: string;

}
