import { Model } from '@vuex-orm/core';
import Product from '@/models/Product';
import ProductCategoryAPI from '@/api/ProductCategoryAPI';

export default class ProductCategory extends Model {

    public get productCategoryProductIds() {
        // @ts-ignore
        return this.product_ids;
    }

    public static entity = 'productCategory';

    public static fields() {
        return {
            id: this.attr(''),
            type: this.string('product_categories'),
            code: this.string(''),
            name: this.string(''),
            product_ids: this.attr([]),
            products: this.hasManyBy(Product, 'product_ids'),
            position: this.number(0),
        };
    }

    public static async getAll() {
        let allProductCategories;

        try {
            allProductCategories = await ProductCategoryAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({data: allProductCategories.data, insertOrUpdate: ['product', 'productSystem']});

        return Promise.resolve(allProductCategories);
    }

    public static async getAllWithoutIcons() {
        let allProductCategories;

        try {
            allProductCategories = await ProductCategoryAPI.getAllWithoutIcons();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({data: allProductCategories.data, insertOrUpdate: ['product', 'productSystem']});

        return Promise.resolve(allProductCategories);
    }

    public static async getBasic() {
        let allProductCategories;

        try {
            allProductCategories = await ProductCategoryAPI.getBasic();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({data: allProductCategories.data});

        return Promise.resolve(allProductCategories);

    }
    public id!: string;
    public code!: string;
    public name!: string;
    public products!: Product[];
}
