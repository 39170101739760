import ChatMessage from '@/models/ChatMessage';

export default class MessageRepository {
    public static getAll() {
        return ChatMessage.all();
    }

    public static getById(id: string) {
        return ChatMessage.query().whereId(id).withAll().first();
    }
}
