import Offer from '@/models/Offer';

export default class OfferRepository {
    public static getProjectOffers(projectId: string): Offer[] {
        return Offer.query()
            .where('project_id', projectId)
            // could be changed to fetch specific relationships to increase performance
            .withAllRecursive(2)
            .orderBy((offer) => new Date((offer as Offer).createdAt).getTime(), 'desc')
            .get();
    }

    public static getBasicProjectOffers(projectId: string): Offer[] {
        return Offer.query()
            .where('project_id', projectId)
            .orderBy((offer) => new Date((offer as Offer).createdAt).getTime(), 'desc')
            .get();
    }

    public static getProjectLatestOffer(projectId: string) {
        return Offer.query()
            .where('project_id', projectId)
            .withAllRecursive()
            .orderBy((offer) => new Date((offer as Offer).createdAt).getTime(), 'desc')
            .first();
    }

    public static isAnyOfferCurrentlySelected(projectId: string) {
        const offers = Offer.query()
            .where('project_id', projectId)
            .get();

        return offers.some((offer) => offer.isSelected);

    }

    public static getSelectedOffer(projectId: string) {
        return Offer.query()
            .where((offer: Offer) => {
                return offer.project_id === projectId && offer.isSelected;
            })
            .withAllRecursive()
            .first();
    }

    public static getAllSelectedOffers() {
        return Offer.query()
            .where((offer: Offer) => {
                return offer.isSelected;
            })
            .get();
    }

    public static getOfferById(offerId: string | null): Offer | null {
        if (offerId == null) {
            return null;
        }
        return Offer.query().whereId(offerId).withAllRecursive().first();
    }
}
