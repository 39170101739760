export enum PopupEvents {
    openAddProducts = 'openAddProducts',
    openAddMaterials = 'openAddMaterials',
    openMultiposition = 'openMultiposition',
    openDocumentAndPdfType = 'openDocumentAndPdfType',
    priceListOutOfRange = 'priceListOutOfRange',
    contact = 'contact',
    openDuplicateClient = 'openDuplicateClient',
    deleteContactPerson = 'deleteContactPerson',
    deleteClientPerson = 'deleteClientPerson',
    offerItemPriceManipulation = 'offerItemPriceManipulation',
    openPdfPreviewOfferPopup = 'openPdfPreviewOfferPopup',
    openPdfPreviewOrderPopup = 'openPdfPreviewOrderPopup',
    openPreviewFilePopup = 'openPreviewFilePopup',
    openCreateNewEventPopup = 'openCreateNewEventPopup',
    openProjectsModal = 'openProjectsModal',
    openActivitiesProjectsModal = 'openActivitiesProjectsModal',
    openDimensionsWizard = 'openDimensionsWizard',
    openCombinationsWizard = 'openCombinationsWizard',
}
