
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { NewClientModalTabs } from '@/enums/components/EditClient/NewClientModalTabs';
import CustomerInformation from '@/components/global/client/CustomerInformation.vue';
import ContactInformation from '@/components/global/client/ContactInformation.vue';
import AdditionalInformation from '@/components/global/client/AdditionalInformation.vue';
import DiscountInformation from '@/components/global/client/DiscountInformation.vue';
import Currency from '@/models/Currency';
import PaymentType from '@/models/PaymentType';
import BankAccount from '@/models/BankAccount';
import Country from '@/models/Country';
import PriceType from '@/models/PriceType';
import PriceListRegion from '@/models/PriceListRegion';
import ContactPerson from '@/models/ContactPerson';
import ContactConfig from '@/models/interfaces/Contact';
import AddressConfig from '@/models/interfaces/Address';
import Address from '@/models/Address';
import Discount from '@/models/Discount';
import Client from '@/models/Client';
import { RouteNames } from '@/enums/routes/RouteNames';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';
import ClientType from '@/models/ClientType';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import ProductGroup from '@/models/ProductGroup';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ClientRepository from '@/repositories/ClientRepository';
import ContactPersonRepository from '@/repositories/ContactPersonRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import CMSUser from '@/models/CMSUser';
import { ClientAdditionalInformation } from '@/interfaces/components/clients/ClientAdditionalInformation';
import { ErrorOptions } from '@/interfaces/ErrorOptions';
import { DiscountTableEntry } from '@/interfaces/components/clients/DiscountTableEntry';
import { webpageValidationRegex, emailValidationRegex } from '@/regex/formRegex';

@Component({
    name: 'NewClientModal',
    components: { CustomerInformation, ContactInformation, AdditionalInformation, DiscountInformation },
})
export default class NewClientModal extends Vue {
    @Prop({ default: false }) private isNewClientModalVisible!: boolean;

    private emailValidationOverride: boolean = false;
    private emailValidationOverrideValue: boolean = false;
    private formData: any = { ...this.emptyFormData() };

    private mailFormat = emailValidationRegex;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private validationErrors: string[] = [];
    private refErrors: string[] = [];
    private activeTab = 'Customer';
    private contactErrors = [];
    private isLoading = true;

    @Watch('isNewClientModalVisible', { immediate: true })
    private handleModalOpen(newVal: boolean, oldVal: boolean) {
        if (newVal && !oldVal) {
            this.loadingOverlay.start();
            let defaultCountry;
            const currentUserGroup = UserGroupRepository.getById(
                String(this.$store.getters['jwtData/currentUserGroup'])
            );

            if (currentUserGroup?.client) {
                defaultCountry = currentUserGroup.client?.address?.country.id;
            }

            defaultCountry = currentUserGroup?.clientCountryId?.id;

            if (defaultCountry) {
                // @ts-ignore
                this.formData.addressForm.country = defaultCountry;
            } else {
                this.formData.addressForm.country = '23';
            }
            this.loadingOverlay.stop();
        }
    }

    @Watch('formData.isCompany', {
        immediate: false,
    })
    private onUserTypeChange(isCompany: boolean) {
        if (!isCompany) {
            this.formData.webpage = '';
        }

        if (!isCompany && this.formData.contacts.length) {
            const first = this.formData.contacts[0] as any;

            if (!this.formData.email && first.email) {
                this.formData.email = first.email;
            }

            if (!this.formData.phone && first.phone) {
                this.formData.phone = first.phone;
            }
        } else if (isCompany && this.formData.contacts.length) {
            // @ts-ignore
            this.formData.contacts[0].email = this.formData.email;
        }
    }

    private get tabs() {
        const allTabs = Object.values(NewClientModalTabs);
        if (this.formData.isCompany) {
            return allTabs;
        }
        return allTabs.filter((tab) => tab !== NewClientModalTabs.CONTACT);
    }

    private get defaultCountry() {
        const currentlySelectedCountry = this.formData.addressForm.country;

        if (currentlySelectedCountry) {
            return currentlySelectedCountry;
        }

        return '';
    }

    private emptyFormData() {
        return {
            isCompany: false,
            name: null,
            email: '',
            phone: '',
            addressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            address: null,
            deliveryAddressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            deliveryAddress: null,
            invoiceAddressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            invoiceAddress: null,
            useDifferentAddressForDelivery: false,
            useDifferentAddressForInvoice: false,
            taxId: null,
            registerId: null,
            isTaxPayer: null,
            bankCode: null,
            currency: null,
            paymentType: null,
            priceType: null,
            priceListRegion: null,
            assignedUser: null,
            salesman: null,
            tags: [],
            additionalNotes: null,
            contacts: [],
            discounts: [],
            contactPersons: [''],
            discount_ids: [''],
            clientTypes: [],
            webpage: '',
            bigLogo: null,
            squareLogo: null,
            roltekAppPartner: false,
            backofficeUser: null,
            leadOrigin: null,
        };
    }

    private openDuplicateClientPopup(duplicate: Client) {
        EventBus.$emit(EventBusEvents.openDuplicateClientPopup, {
            popupEvent: PopupEvents.openDuplicateClient,
            data: {
                client: duplicate,
            },
        });
    }

    private setFieldsBorder(arr: string[], clear: boolean = false) {
        let customerInfo = this.$refs.customerinfo as any;

        if (Array.isArray(customerInfo)) {
            customerInfo = customerInfo[0];
        }

        arr.forEach((item: string) => {
            if (item === 'phone') {
                document.getElementById('phone-number-wrapper')!.style.border = clear
                    ? '1px solid #d9d9d9'
                    : '1px solid red';

                return;
            }

            const el = customerInfo.$refs[item] as any;

            if (el && el instanceof Vue) {
                el.$el.style.border = clear ? '1px solid #d9d9d9' : '1px solid red';
            }
        });
    }

    private confirmFieldsAreValid() {
        this.validationErrors = [];
        this.setFieldsBorder(this.refErrors, true);
        this.refErrors = [];

        let flag = true;
        let customerIsValid = true;
        let contactInfoIsValid = true;

        if (!this.formData.name) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje ime je obavezno'));
            this.refErrors.push('name');
        }

        if (!this.formData.addressForm.streetNameAndNumber) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje naziv ulice i broj je obavezno'));
            this.refErrors.push('address');
        }

        if (!this.formData.addressForm.poNumber) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje poštanski broj je obavezno'));
            this.refErrors.push('ponumber');
        }

        if (!this.formData.addressForm.city) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje grad je obavezno'));
            this.refErrors.push('city');
        }

        if (!this.formData.addressForm.country) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje država je obavezno'));
            this.refErrors.push('country');
        }

        if (this.formData.isCompany) {
            let missingContactInfo = false;
            let missingAddressInfo = false;

            const contactErrors: any = [];

            const webpageValid = this.validateWebpage();

            if (!webpageValid) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Invalid webpage!'));
                this.refErrors.push('webpage');
            }

            this.formData.contacts.forEach((contact: any, i: number) => {
                const isContactValid = !!contact.name && !!contact.email && !!contact.phone;

                const isAddressValid =
                    !!contact.address.streetNameAndNumber &&
                    !!contact.address.poNumber &&
                    !!contact.address.city &&
                    !!contact.address.country;

                if (!isContactValid) {
                    missingContactInfo = true;
                }

                if (!isAddressValid && !contact.useCompanyAddress) {
                    missingAddressInfo = true;
                }

                contactErrors.push({ index: i, errors: this.checkContactFieldsForErrors(contact) });
            });

            this.contactErrors = contactErrors;

            if (missingContactInfo) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Polja imena, emaila i telefona su obavezna'));
                this.refErrors.push('contacts');
            }

            if (missingAddressInfo) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Polja adrese kontakt osobe su obavezna'));
            }

            // @todo check every contact
            if (!this.formData.contacts.length) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Nedostaje kontakt osoba'));
                this.refErrors.push('contacts');
            }

            if (!this.formData.taxId) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje porezni id broj je obavezno'));
                this.refErrors.push('tax');
            }
        } else {
            if (!this.formData.phone) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje telefon je obavezno'));
                this.refErrors.push('phone');
            }

            if (!this.formData.email) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje email je obavezno'));
                this.emailValidationOverride = true;
                this.emailValidationOverrideValue = false;
            } else {
                this.emailValidationOverride = false;
                this.emailValidationOverrideValue = true;
            }
        }

        this.switchToCorrectTab(customerIsValid, contactInfoIsValid);
        this.setFieldsBorder(this.refErrors);

        return flag;
    }

    private validateWebpage() {
        const input = this.formData.webpage;

        if (!input) {
            return true;
        }

        if (!webpageValidationRegex.test(input)) {
            return false;
        }

        return true;
    }

    private checkContactFieldsForErrors(contact: any) {
        const errorState: any = {};

        if (!contact.email) {
            errorState.email = true;
        }

        if (!contact.phone) {
            errorState.phone = true;
        }

        if (!contact.name) {
            errorState.name = true;
        }

        if (contact.useCompanyAddress) {
            return errorState;
        }

        if (!contact.address.city) {
            errorState.city = true;
        }

        if (!contact.address.country) {
            errorState.country = true;
        }

        if (!contact.address.poNumber) {
            errorState.poNumber = true;
        }

        if (!contact.address.streetNameAndNumber) {
            errorState.streetNameAndNumber = true;
        }

        return errorState;
    }

    private switchToCorrectTab(customerIsValid: boolean, contactInfoIsValid: boolean) {
        switch (this.activeTab) {
            case 'Customer':
                if (!customerIsValid) {
                    return;
                }

                if (!contactInfoIsValid) {
                    this.activeTab = 'Contact';
                }
                break;
            case 'Contact':
                if (!contactInfoIsValid) {
                    return;
                }

                if (!customerIsValid) {
                    this.activeTab = 'Customer';
                }
                break;
            default:
                if (!customerIsValid) {
                    this.activeTab = 'Customer';
                }

                if (!contactInfoIsValid) {
                    this.activeTab = 'Contact';
                }
        }
    }

    private async confirmEmailsAreValid() {
        const customerEmail = this.formData.email;
        const contactEmails = this.formData.contacts.map((contact: any) => {
            return contact.email;
        });

        const emails = [customerEmail, ...contactEmails];
        const clients: Client[] = [];

        for await (const email of emails) {
            if (email && !email.match(this.mailFormat)) {
                this.$notification.error({
                    message: this.$t('Invalid email'),
                    description: '',
                });
                this.loadingOverlay.stop();

                return false;
            }

            const duplicate = await this.checkDuplicateEmails(email);
            if (duplicate) {
                clients.push(duplicate);

                this.openDuplicateClientPopup(duplicate);
                this.loadingOverlay.stop();

                return false;
            }
        }

        if (clients.length === 0) {
            return Promise.resolve(true);
        }
    }

    private async checkDuplicateEmails(input: string) {
        const customerName = this.formData.name;
        const customerAddress = this.formData.addressForm.streetNameAndNumber;
        const customerTaxId = this.formData.taxId;
        let clients = null;

        try {
            if (this.formData.isCompany && Array.isArray(this.formData.contacts)) {
                for (const contact of this.formData.contacts) {
                    const result = await Client.validateClient(
                        contact.email,
                        contact.phone,
                        customerName,
                        customerAddress,
                        customerTaxId
                    );
                    if (result && result.data.length > 0) {
                        return result.data[0];
                    }
                }
            } else {
                clients = await Client.validateClient(
                    input,
                    this.formData.phone,
                    customerName,
                    customerAddress,
                    customerTaxId
                );
                return clients.data.length <= 0 ? null : clients.data[0];
            }
        } catch (e) {
            console.error('Error checking duplicates', e);
            return null;
        }

        return null;
    }

    private isCustomerInfoShown(tab: string) {
        return tab === NewClientModalTabs.CUSTOMER;
    }
    private isContactInfoShown(tab: string) {
        return tab === NewClientModalTabs.CONTACT;
    }
    private isAdditionalInfoShown(tab: string) {
        return tab === NewClientModalTabs.ADDITIONAL;
    }
    private isDiscountsShown(tab: string) {
        return tab === NewClientModalTabs.DISCOUNTS;
    }

    private async createNewClientAndDepenencies() {
        this.loadingOverlay.start();

        const data = this.formData as AvailableClientFields;
        this.formatDataForUpdate(data);
        let newClientId;

        if (!this.confirmFieldsAreValid()) {
            this.$notification.error({
                message: this.validationErrors.join('!\n'),
                description: '',
            });

            this.loadingOverlay.stop();

            return;
        }

        const emailsAreValid = await this.confirmEmailsAreValid();

        if (!emailsAreValid) {
            this.loadingOverlay.stop();
            return;
        }

        try {
            await this.createClientAddress(data);
            data.contactPersons = await this.createContacts();
            await this.handleAlternativeAddresses(data);
            newClientId = await this.createClient(data);
            await this.createDiscounts(newClientId);

            // Update client at the end to make sure data is sent to Roltek in case we created
            // it with discounts
            if (this.formData.discounts.length > 0) {
                await Client.updateExisting(newClientId, data);
            }
        } catch (e) {
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        const isProjectCreation = this.$store.getters['generic/projectClientCreation'];

        if (isProjectCreation) {
            const client = ClientRepository.getById(newClientId);

            this.$store.commit('generic/setCreatedClient', client);
            this.$store.commit('generic/emptyCreatedContacts');

            data.contactPersons.forEach((id: string) => {
                const contact = ContactPersonRepository.getById(id);
                this.$store.commit('generic/addCreatedContact', contact);
            });
        } else {
            this.$router.push({
                name: RouteNames.editClient,
                params: { clientId: newClientId },
            });
        }

        this.closePopup();

        this.$notification.success({
            message: this.$t('Client has been created!') as string,
            description: '',
            class: 'client-create-success',
        });

        this.loadingOverlay.stop();
    }

    private async handleAlternativeAddresses(data: AvailableClientFields) {
        await Promise.all([
            this.handleAddressAPI(data.deliveryAddressForm, 'deliveryAddress', data.useDifferentAddressForDelivery),
            this.handleAddressAPI(data.invoiceAddressForm, 'invoiceAddress', data.useDifferentAddressForInvoice),
        ]);
    }

    private async handleAddressAPI(addressForm: AddressConfig, idFieldForUpdate: string, shouldRun: boolean) {
        if (!shouldRun) {
            this.formData[idFieldForUpdate] = this.formData.address;
            return Promise.resolve();
        }

        let addressPromise;

        try {
            addressPromise = await Address.createNew(addressForm);
            this.formData[idFieldForUpdate] = addressPromise.data.id;
        } catch (e) {
            this.loadingOverlay.stop();
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async createClientAddress(data: AvailableClientFields) {
        if (this.hasAddressData(data.addressForm)) {
            // tslint:disable: no-shadowed-variable
            const resp = await Address.createNew(data.addressForm);
            data.address = resp.data.id;
        }
    }

    private async updateClientWithDuplicatedValues() {
        this.loadingOverlay.start();

        const data = this.formData as AvailableClientFields;
        this.formatDataForUpdate(data);
        let newClientId;

        try {
            await this.createClientAddress(data);

            data.contactPersons = await this.createContacts();

            await this.handleAlternativeAddresses(data);

            newClientId = await this.createClient(data);

            await this.createDiscounts(newClientId);

            // Update client at the end to make sure data is sent to Roltek in case we created
            // it with discounts
            if (this.formData.discounts.length > 0) {
                await Client.updateExisting(newClientId, data);
            }
        } catch (e) {
            console.error('Error in updateClientWithDuplicatedValues', e);
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        const isProjectCreation = this.$store.getters['generic/projectClientCreation'];

        if (isProjectCreation) {
            const client = ClientRepository.getById(newClientId);

            this.$store.commit('generic/setCreatedClient', client);
            this.$store.commit('generic/emptyCreatedContacts');

            data.contactPersons.forEach((id: string) => {
                const contact = ContactPersonRepository.getById(id);
                this.$store.commit('generic/addCreatedContact', contact);
            });
        } else {
            this.$router.push({
                name: RouteNames.editClient,
                params: { clientId: newClientId },
            });
        }

        this.closePopup();

        this.$notification.success({
            message: this.$t('Client has been created!') as string,
            description: '',
            duration: 3,
            class: 'client-create-success',
        });

        this.loadingOverlay.stop();
    }

    private formatDataForUpdate(data: AvailableClientFields) {
        if (data.addressForm && !data.addressForm.country) {
            data.addressForm.country = this.defaultCountry;
        }

        if (typeof data.tags[0] !== 'string') {
            data.tags = data.tags.map((tag: any) => tag.name);
        }

        if (!data.isCompany) {
            data.contacts = [
                {
                    address: {
                        streetNameAndNumber: data.addressForm.streetNameAndNumber,
                        poNumber: data.addressForm.poNumber,
                        city: data.addressForm.city,
                        building: data.addressForm.building,
                        country: data.addressForm.country,
                    },
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    job: undefined,
                    useCompanyAddress: true,
                },
            ];
        }
    }

    private async createClient(data: AvailableClientFields) {
        if (data.tags.length > 0) {
            data.clientTypes = data.tags;
        } else {
            data.clientTypes = this.findClientTypeId(data);
        }
        data.paymentType = await this.findPaymentTypeId(data);

        const resp = await Client.createNew(data);
        return resp.data.id;
    }

    private async createContacts() {
        const contacts = [];

        for (const contact of this.formData.contacts as ContactConfig[]) {
            if (contact.address && !contact.useCompanyAddress) {
                // tslint:disable: no-shadowed-variable
                if (contact.address.country) {
                    contact.address.country = contact.address.country.split('|')[0];
                }

                const newAddress = await Address.createNew(contact.address);
                contact.address_id = newAddress.data.id;
            } else if (contact.useCompanyAddress) {
                contact.address_id = this.formData.address;
            }

            const newContact = await ContactPerson.createNew(contact);
            contacts.push(newContact.data.id);
        }

        return contacts;
    }

    private async createDiscounts(clientId: string) {
        const discountIds = [];
        for (const discount of this.formData.discounts as DiscountTableEntry[]) {
            // tslint:disable: no-shadowed-variable
            discount.clientId = clientId;
            const resp = await Discount.createNew(discount);
            discountIds.push(resp.data.id);
        }
        return discountIds;
    }

    private hasAddressData(address?: AddressConfig) {
        return (
            !!address?.streetNameAndNumber ||
            !!address?.poNumber ||
            !!address?.city ||
            !!address?.building ||
            !!address?.country
        );
    }

    private findClientTypeId(data: AvailableClientFields) {
        const code = data.isCompany ? 'FIRM' : 'CUSTOMER';
        const typeId = this.clientTypes.find((type) => type.code === code)?.id;
        return [typeId];
    }

    private async findPaymentTypeId(data: AvailableClientFields) {
        if (!!data.paymentType) {
            return data.paymentType;
        }
        if (this.paymentTypes.find((type) => type.isDefault)) {
            return this.paymentTypes.find((type) => type.isDefault)?.id;
        } else {
            const fetchedDefaultTypes = await PaymentType.fetchDefaultPaymentType();

            return fetchedDefaultTypes.data.find((type: any) => type.isDefault)?.id;
        }
    }

    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }

    private get paymentTypes() {
        return PaymentTypeRepository.getAllWithArchived();
    }

    private closePopup() {
        if (this.$store.getters['generic/projectClientCreation']) {
            EventBus.$emit(EventBusEvents.toggleNewProjectModal, true);
        }

        this.$store.commit('generic/setProjectClientCreation', false);
        this.formData = this.emptyFormData();

        this.$emit('update:isNewClientModalVisible', false);
    }

    private async fetchInitialClientData() {
        try {
            await Promise.allSettled([
                Currency.getAll(),
                PaymentType.getAll(),
                ClientType.getAll(),
                BankAccount.getAll(),
                Country.getAll(),
                PriceType.getAll(),
                PriceListRegion.getAll(),
                ProductGroup.getAll(),
                CMSUser.getAllOnlyUsers(),
            ]);
        } catch (e) {
            Promise.reject(e);
        }
    }

    private updateClientData(data: ClientAdditionalInformation) {
        // loop through values and update the form data with the new values if there are fields to update
        for (const key in data.values) {
            if (data.fields.includes(key)) {
                switch (key) {
                    case 'city':
                        this.formData.addressForm!.city = data.values[key];
                        break;
                    case 'country':
                        this.formData.addressForm!.country = String(data.values[key]);
                        break;
                    case 'fullName':
                    case 'shortName':
                        this.formData.name = data.values[key];
                        break;
                    case 'postalCode':
                        this.formData.addressForm!.poNumber = data.values[key];
                        break;
                    case 'street':
                        this.formData.addressForm!.streetNameAndNumber = data.values[key];
                        break;
                    case 'registerID':
                        this.formData.registerId = data.values[key];
                        break;
                    case 'taxNumber':
                        this.formData.taxId = data.values[key];
                        break;
                    case 'taxPayer':
                        this.formData.isTaxPayer = data.values[key] ? true : false;
                        break;
                }
            }
        }
    }

    private logActiveTab(activeTab: any) {
        this.activeTab = activeTab;
    }

    private async created() {
        this.loadingOverlay.start();
        await this.fetchInitialClientData();
        this.isLoading = false;
        this.loadingOverlay.stop();

        EventBus.$on(EventBusEvents.updateClientData, this.updateClientData);
        EventBus.$on(EventBusEvents.saveClientWithDuplicates, this.updateClientWithDuplicatedValues);
        EventBus.$on(EventBusEvents.closeNewClientPopup, this.closePopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientData, this.updateClientData);
        EventBus.$off(EventBusEvents.saveClientWithDuplicates, this.updateClientWithDuplicatedValues);
        EventBus.$off(EventBusEvents.closeNewClientPopup, this.closePopup);
    }

    @Watch('formData.email')
    private onEmailChange(newVal: string, oldVal: string) {
        if (this.formData.contacts.length) {
            // @ts-ignore
            this.formData.contacts[0].email = newVal;
        }
    }

    @Watch('formData.contacts.length')
    private onFirstContactCreate(newVal: number) {
        if (newVal !== 1) {
            return;
        }

        if (this.formData.isCompany && this.formData.email) {
            // @ts-ignore
            this.formData.contacts[0].email = this.formData.email;
        }
    }
}
