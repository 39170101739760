import WarrantyWarning from '@/models/WarrantyWarning';

export default class WarrantyWarningRepository {
    public static getAll() {
        return WarrantyWarning.all();
    }

    public static getById(WarrantyWarningId: string) {
        return WarrantyWarning.query()
            .whereId(WarrantyWarningId)
            .withAll()
            .first();
    }
}
