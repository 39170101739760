import { Model } from '@vuex-orm/core';
import CampaignTypeAPI from '@/api/CampaignTypeAPI';

export default class CampaignType extends Model {

    public static entity = 'campaignType';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
        };
    }

    public static async getAll() {
        let campaignTypes;
        try {
            campaignTypes = await CampaignTypeAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaignTypes.data,
        });
        return Promise.resolve(campaignTypes);
    }

    public static async getById(campaignTypeId: string) {
        let campaignType;
        try {
            campaignType = await CampaignTypeAPI.getById(campaignTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaignType.data,
        });
        return Promise.resolve(campaignType);
    }

    public static async updateExisting(campaignTypeId: string, campaignTypeInfo: any) {
        let campaignType;
        try {
            campaignType = await CampaignTypeAPI.update(campaignTypeId, campaignTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaignType.data,
        });
        return Promise.resolve(campaignType);
    }

    public static async createNew(campaignTypeInfo: any) {
        let campaignType;
        try {
            campaignType = await CampaignTypeAPI.create(campaignTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaignType.data,
        });
        return Promise.resolve(campaignType);
    }

    public static async deleteExisting(campaignTypeId: string) {
        try {
            await CampaignTypeAPI.delete(campaignTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await CampaignType.delete(campaignTypeId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;

}
