import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class MessageAPI {
    public static async getAll() {
        let messages;

        try {
            messages = await ApiController.get(ApiRoutes.message.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(messages);
    }

    public static async getById(messageId: string) {
        let message;

        try {
            message = await ApiController.get(ApiRoutes.message.getById(messageId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(message);
    }

    public static async delete(messageId: string) {

        try {
            await ApiController.delete(ApiRoutes.message.delete(messageId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(messageId: string, messageInfo: AvailableLabelFields) {
        let message;
        const request = {
            data: {
                type: 'lead_origins',
                id: messageId,
                attributes: {
                    name: messageInfo.name,
                },
            },
        };

        try {
            message =
                await ApiController.patch(
                    ApiRoutes.message.update(messageId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(message);
    }

    public static async removeNextStep(messageId: string) {
        let message;
        const request = {
            data: {
                type: 'chat_messages',
                id: messageId,
                attributes: {},
                relationships: {
                    nextStep: {
                        data: null,
                    },
                },
            },
        };

        try {
            message =
                await ApiController.patch(
                    ApiRoutes.message.update(messageId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(message);
    }

    public static async create(messageInfo: AvailableLabelFields) {
        let message;
        const request = {
            data: {
                type: 'chat_messages',
                attributes: {},
                relationships: {
                    chat: {
                        data: {
                            type: 'chats',
                            id: messageInfo.chatId,
                        },
                    },
                },
            },
        };

        if (messageInfo.nextStepId) {
            // @ts-ignore
            request.data.relationships.nextStep = {
                data: {
                    type: 'next_steps',
                    id: messageInfo.nextStepId,
                },
            };
        }

        try {
            message =
                await ApiController.post(
                    ApiRoutes.message.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(message);
    }
}
