import ProductSystemDetails from '@/models/ProductSystemDetails';

export default class ProductSystemDetailsRepository {
    public static getAll() {
        return ProductSystemDetails.query().withAll().get();
    }

    public static getById(productSystemDetailsId: string) {
        return ProductSystemDetails.query().whereId(productSystemDetailsId).withAllRecursive().first();
    }
}
