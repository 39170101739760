import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import ProductCatalogueAPI from '@/api/ProductCatalogueAPI';
import Image from '@/models/Image';

export default class ProductCatalogue extends Model {

    public get getMatchcode() {
        // @ts-ignore
        return this.matchcode;
    }

    public get getProductType() {
        // @ts-ignore
        return this.productType;
    }

    public static entity = 'productCatalogue';

    public static fields() {
        return {
            id: this.string(null),
            oid: this.string(''),
            matchcode: this.string(''),
            articleNumber: this.string(''),
            description: this.string(''),
            commodityGroup: this.string(''),
            productGroup: this.string(''),
            examplePriceVK1: this.number(null),
            examplePriceVK2: this.number(null),
            productType: this.number(null),
            perUnit: this.number(null),
            isPurchase: this.boolean(false),
            disabledProduct: this.boolean(false),
            gallery: this.attr([]),
            images: this.hasManyBy(Image, 'gallery'),
        };
    }

    public static async search(query: string) {
        let materials;
        try {
            materials = await ProductCatalogueAPI.search(query) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: materials.data,
            insertOrUpdate: ['gallery'],
        });
        return Promise.resolve(materials.data);
    }
    public id!: string;
    public description!: string;
    public oid!: string;
}
