import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { CalendarPDF } from '@/interfaces/components/calendar/CalendarPDF';
export default class PdfAPI {
    public static async addPDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
        let pdf;
        const request = this.generatePayload(offerId, documentTypeId, pdfTypeId);
        try {
            pdf = await ApiController.post(ApiRoutes.pdf.add, request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async updateExisting(offerId: string, documentTypeId: string, pdfTypeId: string, pdfId: string) {
        let pdf;
        const request = this.generatePayload(offerId, documentTypeId, pdfTypeId, pdfId);
        try {
            pdf = await ApiController.patch(ApiRoutes.pdf.update(pdfId), request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async previewPDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
        let pdf;

        const request = this.generatePayload(offerId, documentTypeId, pdfTypeId);
        try {
            pdf = await ApiController.post(ApiRoutes.pdf.preview, request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async downloadPDF(offerId: string, documentTypeId: string, pdfTypeId: string) {
        let pdf;

        const request = this.generatePayload(offerId, documentTypeId, pdfTypeId);
        try {
            pdf = await ApiController.post(ApiRoutes.pdf.download, request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async downloadCalendarPDF(payload: CalendarPDF[]) {
        let pdf;

        const request = {
            data: payload,
        };

        try {
            pdf = await ApiController.post(ApiRoutes.pdf.calendarPDF, request, null, true);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    private static generatePayload(offerId: string, documentTypeId: string, pdfTypeId: string, pdfId?: string) {
        const payload = {
            data: {
                type: 'pdfs',
                attributes: {
                    type: 'default',
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },
                    documentName: {
                        data: {
                            type: 'document_names',
                            id: documentTypeId,
                        },
                    },
                    offerConfiguration: {
                        data: {
                            type: 'offer_configurations',
                            id: pdfTypeId,
                        },
                    },
                },
            },
        };

        if (pdfId) {
            // @ts-ignore
            payload.data.id = pdfId;
        }

        return payload;
    }
}
