import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductVersionAPI {
    public static async getAll() {
        let ProductVersion;

        try {
            ProductVersion = (await ApiController.get(ApiRoutes.productVersion.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(ProductVersion);
    }
}
