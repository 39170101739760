import ClientType from '@/models/ClientType';

export default class ClientTypeRepository {
    public static getAll() {
        return ClientType.all();
    }

    public static getById(id: string) {
        return ClientType.query().whereId(id).withAll().first();
    }
}
