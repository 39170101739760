var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.userGroups.length > 0)?_c('a-dropdown',{attrs:{"data-test":"userGroupPicker","position":"bottomCenter","visible":_vm.isDropdownVisible,"trigger":['click'],"getPopupContainer":(triggerNode) => triggerNode.parentElement},on:{"visibleChange":_vm.dropdownVisibleChange}},[_c('div',{staticClass:"user-group-select__current",style:(_vm.collapsed ? 'padding:0px;' : 'padding: 0 10px;'),on:{"click":_vm.triggerDropdownVisibility}},[(_vm.currentUserGroup.id == 1)?_c('RLogoIconSLO',{staticClass:"user-group-select__icon",attrs:{"height":25}}):_vm._e(),(_vm.currentUserGroup.id == 2)?_c('RLogoIconAT',{staticClass:"user-group-select__icon",attrs:{"height":25}}):_vm._e(),(_vm.currentUserGroup.id == 3)?_c('RLogoIconHR',{staticClass:"user-group-select__icon",attrs:{"height":25}}):_vm._e(),(!_vm.collapsed)?_c('span',{staticClass:"current-user-group"},[_vm._v(_vm._s(_vm.currentUserGroup.name))]):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"user-group-select__icon-wrap"},[_c('a-icon',{attrs:{"type":"caret-up"}}),_c('a-icon',{attrs:{"type":"caret-down"}})],1):_vm._e()],1),_c('a-menu',{ref:"userGroupSelectMenu",staticStyle:{"padding":"2px 0px"},attrs:{"slot":"overlay"},on:{"mouseleave":(e) => {
                _vm.$emit('collapseMenu', e);
            }},slot:"overlay",model:{value:(_vm.selectedKeys),callback:function ($$v) {_vm.selectedKeys=$$v},expression:"selectedKeys"}},[_c('a-menu-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userGroups.length > 2),expression:"userGroups.length > 2"}],attrs:{"disabled":""}},[_c('a-input',{ref:"userGroupSelectSearch",attrs:{"placeholder":_vm.$t('Upišite barem 3 znaka kako biste počeli pretraživati')},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.scrollDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.scrollUp.apply(null, arguments)}],"pressEnter":function($event){$event.stopPropagation();return _vm.changeUserGroup.apply(null, arguments)},"click":(event) => {
                        event.stopPropagation();
                    }},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_vm._l((_vm.filteredUserGroups),function(userGroup,index){return _c('a-menu-item',{key:index,ref:"userGroupOptions",refInFor:true,attrs:{"data-test":`userGroup-${userGroup.name}`},on:{"click":() => {
                    _vm.onChangeUserGroup(userGroup.id);
                },"mouseover":_vm.onItemHover}},[_vm._v(_vm._s(userGroup.name))])})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }