import { Model } from '@vuex-orm/core';
import PaymentTypeAPI from '@/api/PaymentTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class PaymentType extends Model {
    public static entity = 'paymentType';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            ingeniousId: this.string(''),
            isDefault: this.boolean(false),
            templateName: this.string(''),
            advance: this.number(0),
            discount: this.number(0),
            expirationDate: this.attr(null),
            isArchived: this.boolean(false),
        };
    }

    public static async getAll() {
        let paymentTypes;

        if (ss.has('paymentTypes')) {
            paymentTypes = ss.getAndParse('paymentTypes');
        } else {
            try {
                const fetchedPaymentTypes = await PaymentTypeAPI.getAll();
                paymentTypes = fetchedPaymentTypes.data;

                ss.stringifyAndSave('paymentTypes', paymentTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: paymentTypes,
        });

        return Promise.resolve(paymentTypes);
    }

    public static async getById(paymentTypeId: string) {
        let paymentType;
        try {
            paymentType = await PaymentTypeAPI.getById(paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentType.data,
        });
        return Promise.resolve(paymentType);
    }

    public static async fetchDefaultPaymentType() {
        let paymentType;
        try {
            paymentType = await PaymentTypeAPI.fetchDefaultPaymentType();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: paymentType.data,
        });
        return Promise.resolve(paymentType);
    }

    public static async updateExisting(paymentTypeId: string, paymentTypeInfo: any) {
        let paymentType;
        try {
            paymentType = await PaymentTypeAPI.update(paymentTypeId, paymentTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentType.data,
        });
        return Promise.resolve(paymentType);
    }

    public static async createNew(paymentTypeInfo: any) {
        let paymentType;
        try {
            paymentType = await PaymentTypeAPI.create(paymentTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentType.data,
        });
        return Promise.resolve(paymentType);
    }

    public static async deleteExisting(paymentTypeId: string) {
        try {
            await PaymentTypeAPI.delete(paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await PaymentType.delete(paymentTypeId);
        return Promise.resolve();
    }

    public static async archiveExisting(paymentTypeId: string) {
        let paymentType;
        try {
            paymentType = await PaymentTypeAPI.archive(paymentTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentType.data,
        });
        return Promise.resolve(paymentType);
    }

    public static checkIfPaymentTypeIsExpired(timeDateString: string | null) {
        if (timeDateString == null) {
            return false;
        }
        const currentDateWithOneHourIncrement = new Date();
        const expirationDate = new Date(timeDateString);

        currentDateWithOneHourIncrement.setTime(currentDateWithOneHourIncrement.getTime() + 3600000);

        return +currentDateWithOneHourIncrement > +expirationDate;
    }

    public id!: string;
    public name!: string;
    public isDefault!: boolean;
    public isArchived!: boolean;
    public templateName!: string;
    public advance!: number;
    public discount!: number;
    public expirationDate!: string;
}
