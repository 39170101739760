import { Model } from '@vuex-orm/core';
import LeadProductAPI from '@/api/LeadProductAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
export default class LeadProduct extends Model {
    public static entity = 'LeadProduct';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
        };
    }

    public static async getAll() {
        let products;

        if (ss.has('leadProducts')) {
            products = ss.getAndParse('leadProducts');
        } else {
            try {
                const fetchedProducts = await LeadProductAPI.getAll();
                products = fetchedProducts.data;

                ss.stringifyAndSave('leadProducts', products);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.insertOrUpdate({
            data: products,
        });
        return Promise.resolve();
    }

    public static async getById(labelId: string) {
        let product;
        try {
            product = await LeadProductAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: product.data,
        });
        return Promise.resolve(product);
    }

    public id!: string;
    public name!: string;
}
