import { render, staticRenderFns } from "./ProductCombination.vue?vue&type=template&id=e5ec5fe6&scoped=true"
import script from "./ProductCombination.vue?vue&type=script&lang=ts"
export * from "./ProductCombination.vue?vue&type=script&lang=ts"
import style0 from "./ProductCombination.vue?vue&type=style&index=0&id=e5ec5fe6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5ec5fe6",
  null
  
)

export default component.exports