import Locale from '@/models/Locale';

export default class LocaleRepository {
    public static getAll() {
        return Locale.query().get();
    }

    public static getLocaleByCode(code: string) {
        return Locale.query().where('code', code).first();
    }

    public static getLocaleById(id: string) {
        return Locale.query().whereId(id).first();
    }
}
