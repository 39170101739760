import { Model } from '@vuex-orm/core';
import ContactPersonAPI from '@/api/ContactPersonAPI';
import contactConfig from '@/models/interfaces/Contact';
import UserGroupConnection from '@/models/UserGroupConnection';
import Project from '@/models/Project';
import Address from '@/models/Address';
import AddressConfig from '@/models/interfaces/Address';

export default class ContactPerson extends Model {
    public static entity = 'contact';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            phone: this.attr(''),
            email: this.attr(''),
            job: this.attr(''),
            projects: this.hasManyBy(Project, 'project_ids'),
            project_ids: this.attr([]),
            address: this.belongsTo(Address, 'address_id'),
            address_id: this.attr(null),
            birthday: this.attr(null),
            isDefault: this.attr(false),
        };
    }

    public static async getAll() {
        let contacts;
        try {
            contacts = await ContactPersonAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: contacts.data,
        });
        return Promise.resolve();
    }

    public static async getById(contactId: string) {
        let contact;
        try {
            contact = await ContactPersonAPI.getById(contactId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: contact.data,
        });
        return Promise.resolve(contact);
    }

    public static async getByIdWithProjects(contactId: string) {
        let contact;
        try {
            contact = await ContactPersonAPI.getByIdWithProjects(contactId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: contact.data,
            insertOrUpdate: ['address', 'projects'],
        });
        return Promise.resolve(contact);
    }

    public static async updateExisting(contactId: string, contactsInfo: contactConfig) {
        let contact;

        try {
            contact = await ContactPersonAPI.update(contactId, contactsInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(contact);
    }

    public static async createNew(contactInfo: contactConfig) {
        let contact;
        try {
            contact = await ContactPersonAPI.create(contactInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(contact);
    }

    public static async deleteExisting(contactId: string) {
        try {
            await ContactPersonAPI.delete(contactId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ContactPerson.delete(contactId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public phone!: string | null;
    public email!: string | null;
    public fax!: string | null;
    public projects!: Project[];
    public address!: AddressConfig | null;
    public birthday!: string;
    public isDefault!: boolean;
}
