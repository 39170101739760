import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class NextStepTypeAPI {
    public static async getAll() {
        let nextStepTypes;

        try {
            nextStepTypes = await ApiController.get(ApiRoutes.nextStepType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStepTypes);
    }

    public static async getById(nextStepTypeId: string) {
        let nextStepType;

        try {
            nextStepType = await ApiController.get(ApiRoutes.nextStepType.getById(nextStepTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStepType);
    }

    public static async update(nextStepTypeId: string, nextStepTypeInfo: AvailableLabelFields) {
        let nextStepType;

        const request = {
            data: {
                type: 'next_step_types',
                id: nextStepTypeId,
                attributes: {
                    name: nextStepTypeInfo.name,
                    color: nextStepTypeInfo.color.hex,
                },
            },
        };

        try {
            nextStepType =
                await ApiController.patch(
                    ApiRoutes.nextStepType.update(nextStepTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStepType);
    }

    public static async create(nextStepTypeInfo: AvailableLabelFields) {
        let nextStepType;
        const request = {
            data: {
                type: 'next_step_types',
                attributes: {
                    name: nextStepTypeInfo.name,
                    color: nextStepTypeInfo.color.hex,
                },
            },
        };

        try {
            nextStepType =
                await ApiController.post(
                    ApiRoutes.nextStepType.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStepType);
    }

    public static async delete(nextStepTypeId: string) {
        try {
             await ApiController.delete(ApiRoutes.nextStepType.delete(nextStepTypeId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
