import { Model } from '@vuex-orm/core';
import ProductVersionAPI from '@/api/ProductVersionAPI';

export default class ProductVersion extends Model {
    public static entity = 'productVersion';

    public static fields() {
        return {
            name: this.string(''),
            productForm: this.attr(null),
        };
    }

    public static async getAll() {
        let allProductVersions;

        try {
            allProductVersions = await ProductVersionAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({ data: allProductVersions.data });

        return Promise.resolve();
    }

    public name!: string;
    public productForm!: any;
}
