import CMSUser from '@/models/CMSUser';
import UserGroupConnection from '@/models/UserGroupConnection';

export default class CMSUserRepository {
    public static getAll() {
        return CMSUser.query()
            .withAllRecursive(1)
            .get();
    }

    public static getAllSalesmen() {
        return CMSUser.query()
            .where((cmsUser: CMSUser) => cmsUser.isSalesman && !cmsUser.isArchived)
            .withAllRecursive(1)
            .get();
    }

    public static getSalesmanById(id: string) {
        return CMSUser.query()
            .where((cmsUser: CMSUser) => cmsUser.isSalesman && !cmsUser.isArchived && cmsUser.id === id)
            .withAllRecursive(1)
            .first();
    }

    public static getAllExceptArchivedUsers() {
        return CMSUser.query()
            .where((cmsUser: CMSUser) => !cmsUser.isArchived)
            .withAllRecursive(1)
            .get();
    }

    public static getAllSalesmenIncludingArchived() {
        return CMSUser.query()
            .where((cmsUser: CMSUser) => cmsUser.isSalesman)
            .withAllRecursive(1)
            .get();
    }

    public static getById(id: string) {
        const cmsUser = CMSUser.query()
            .whereId(id)
            .withAllRecursive()
            .first();
        if (cmsUser == null) {
            return null;
        }

        cmsUser.userGroupConnections.forEach((userGroupConnection: UserGroupConnection) => {
            userGroupConnection.userRights = Object.keys(userGroupConnection.userRights);
        });

        return cmsUser;
    }

    public static filterByName(query: string) {
        return CMSUser.query()
            .where((cmsUser: CMSUser) => {
                const cmsUserName = cmsUser.getName.toUpperCase();
                return cmsUserName.includes(query.toLocaleUpperCase());
            })
            .get();
    }

    public static getNameById(id: string) {
        const cmsUser = CMSUser.query()
            .whereId(id)
            .first();
        if (cmsUser == null) {
            return '';
        }
        return cmsUser.name;
    }

    public static getUsersForMentions() {
        const filteredUsers = CMSUser.query()
            .where((cmsUser: CMSUser) => !cmsUser.isArchived)
            .withAllRecursive(1)
            .get();

        return Promise.resolve(
            filteredUsers.map((user) => {
                return {
                    // adds a space on the end of single words because tiptap can't work without it
                    name: user.name.includes(' ') ? user.name : `${user.name} `,
                    id: user.id,
                };
            })
        );
    }

    public static getUsersUserGroupConnection(id: string, userGroupId: string) {
        const cmsUser = CMSUser.query()
            .whereId(id)
            .withAllRecursive()
            .first();
        if (cmsUser == null) {
            return null;
        }

        return cmsUser.userGroupConnections.find(
            (userGroupConnection) => userGroupConnection.userGroup.id === userGroupId
        );
    }

    public static getUsersUserGroups(id: string) {
        const cmsUser = CMSUser.query()
            .whereId(id)
            .withAllRecursive()
            .first();
        if (cmsUser == null) {
            return null;
        }

        return cmsUser.userGroupConnections.map((userGroupConnection) => userGroupConnection.userGroup);
    }
}
