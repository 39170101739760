export class SessionStorageService {
    public static save(name: string, value: string) {
        sessionStorage.setItem(name, value);
    }

    public static stringifyAndSave(name: string, value: any) {
        sessionStorage.setItem(name, JSON.stringify(value));
    }

    public static updateItem(name: string, value: any) {
        let item = this.getAndParse(name);
        item = { ...item, ...value };

        this.stringifyAndSave(name, item);
    }

    public static updateUniqueArray(name: string, value: any) {
        let item = this.getAndParse(name);

        const filteredArray = [...item, ...value].filter((currentObj, index, originalArray) => {
            return index === originalArray.findIndex((obj: any) => obj.value === currentObj.value);
        });

        this.stringifyAndSave(name, filteredArray);
    }

    public static get(name: string) {
        return sessionStorage.getItem(name);
    }

    public static getAndParse(name: string) {
        return JSON.parse(sessionStorage.getItem(name)!);
    }

    public static has(name: string) {
        const value = this.get(name);

        if (!value || value === '[]') {
            return false;
        }

        return true;
    }

    public static remove(name: string) {
        return sessionStorage.removeItem(name);
    }

    public static cache(name: string, value: string) {
        if (!this.has(name)) {
            this.stringifyAndSave(name, value);
        }
    }
}
