import i18n from '@/i18n';
import { RouteNames } from '@/enums/routes/RouteNames';
import { NavigationKeys } from '@/enums/global/NavigationKeys';
import { NavigationItemOptions } from '@/interfaces/components/navigation/NavigationItemOptions';
import { UserRepository } from '@/repositories/UserRepository';
import UserGroup from '@/models/UserGroup';
import { Item } from '@vuex-orm/core';

export function generateNavigationItemsSetup(
    canSeeChat: boolean,
    canSeeCalendar: boolean,
    currentUserGroup: Item<UserGroup>
): NavigationItemOptions[] {
    return [
        {
            label: null,
            icon: null,
            route: null,
            key: NavigationKeys.USERGROUP,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: ['o-section__user-group-picker'],
        },
        {
            label: null,
            icon: null,
            route: {
                name: RouteNames.statisticsDashboard,
            },
            key: NavigationKeys.INTRO,
            isHidden: false,
            navigationItemClasses: ['o-section__logo'],
            menuItemClasses: ['logo'],
        },
        {
            label: i18n.t('Clients'),
            icon: 'user',
            route: {
                name: RouteNames.client,
            },
            key: NavigationKeys.CLIENTS,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: ['clients'],
        },
        {
            label: i18n.t('Comments'),
            icon: 'message',
            route: {
                name: RouteNames.chat,
            },
            key: NavigationKeys.CHAT,
            isHidden: !canSeeChat,
            navigationItemClasses: [],
            menuItemClasses: [],
        },
        {
            label: i18n.t('Calculator'),
            icon: 'calculator',
            route: {
                name: RouteNames.calculator,
            },
            key: NavigationKeys.CALCULATOR,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: [],
        },
        {
            label: i18n.t('Technical Documentation'),
            icon: 'book',
            route: {
                name: RouteNames.technicalDocumentation,
            },
            key: NavigationKeys.DOCUMENTS,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: [],
        },
        {
            label: i18n.t('Calendar'),
            icon: 'calendar',
            route: {
                name: RouteNames.calendar,
            },
            key: NavigationKeys.CALENDAR,
            isHidden: !canSeeCalendar,
            navigationItemClasses: [],
            menuItemClasses: [],
        },
        {
            label: i18n.t('Novi upit'),
            icon: 'plus-square',
            route: {
                name: RouteNames.clientNewInquiry,
            },
            key: NavigationKeys.NEWCLIENTINQUIRY,
            isHidden: (currentUserGroup ? parseInt(currentUserGroup.id, 10) : 0) < 3 ? false : true,
            navigationItemClasses: [],
            menuItemClasses: [],
        },
        {
            label: i18n.t('Notifications'),
            icon: 'bell',
            route: null,
            key: NavigationKeys.NOTIFICATIONS,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: ['notifications'],
        },
        {
            label: UserRepository?.getCurrentUser()?.name ?? 'User settings',
            icon: 'user',
            route: {
                name: RouteNames.settings,
            },
            key: NavigationKeys.SETTINGS,
            isHidden: false,
            navigationItemClasses: [],
            menuItemClasses: ['settings'],
        },
    ];
}
