
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import DocumentName from '@/models/DocumentName';
import DocumentNameRepository from '@/repositories/DocumentNameRepository';
import OfferConfigurationRepository from '@/repositories/OfferConfigurationRepository';
import OfferConfiguration from '@/models/OfferConfiguration';
import Offer from '@/models/Offer';
import AdditionalPDFOptions from '@/components/global/popup/AdditionalPDFOptions.vue';
import {
    validateOfferAndGeneratePDF,
    validateOfferAndPreviewPDF,
    generateAdminPDFPreview,
} from '@/helpers/OfferHelper';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';

@Component({
    name: 'DocumentAndPdfTypeSlot',
    components: {
        AdditionalPDFOptions,
    },
})
export default class DocumentAndPdfTypeSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private selectedDocumentTypeId: any = '';
    private selectedPdfTypeId: any = '';
    private isPendingRequest = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private loading = false;
    private comment =
        this.popupData.selectedOffer && this.popupData.selectedOffer.comment
            ? this.popupData.selectedOffer.comment
            : '';

    private get documentNames(): DocumentName[] {
        return DocumentNameRepository.getAll();
    }

    private get pdfTypes() {
        return OfferConfigurationRepository.getAll();
    }

    private get originalOfferOfferConfiguration() {
        if (this.popupData.selectedOfferPdfTypeId != null) {
            return this.popupData.selectedOfferPdfTypeId;
        }
        if (!Offer.hasPdfRelationship(this.popupData.originalOffer)) {
            return '';
        }

        return this.popupData.originalOffer.pdf.offerConfiguration.id;
    }

    private get originalOfferDocumentName() {
        if (this.popupData.selectedOfferDocumentNameId != null) {
            return this.popupData.selectedOfferDocumentNameId;
        }
        if (!Offer.hasPdfRelationship(this.popupData.originalOffer)) {
            return '';
        }

        return this.popupData.originalOffer.pdf.documentName.id;
    }

    private get areOptionsSelected() {
        return this.selectedDocumentTypeId !== '' && this.selectedPdfTypeId !== '';
    }

    private onClose() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private async onSubmitForm() {
        const configurationOverride = (
            this.$refs.additionalPdfOptions as AdditionalPDFOptions
        ).getAdditionalPDFOptions();

        if (configurationOverride.showProductImageOnRight && configurationOverride.showImages) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: this.$t('Morate odabrati samo jednu opciju za prikaz slike') as string,
            });
            return;
        }

        this.isPendingRequest = true;

        EventBus.$emit(EventBusEvents.updateProject);

        if (this.popupData.isAdminPDF) {
            await generateAdminPDFPreview({
                documentTypeId: this.selectedDocumentTypeId,
                pdfTypeId: this.selectedPdfTypeId,
                comment: this.comment,
                configurationOverride,
                offerId: this.popupData.selectedOffer.id,
                assignedUser: this.popupData.assignedUser,
                client: this.popupData.client,
                expirationDate: this.popupData.selectedOffer.expirationDate,
                isAdminPDF: this.popupData.isAdminPDF,
                isProjectLocked: this.popupData.isProjectLocked,
            });

            this.isPendingRequest = false;
            return;
        }

        if (this.popupData.shouldPreviewPDF) {
            await validateOfferAndPreviewPDF({
                documentTypeId: this.selectedDocumentTypeId,
                pdfTypeId: this.selectedPdfTypeId,
                comment: this.comment,
                configurationOverride,
                offerId: this.popupData.selectedOffer.id,
                assignedUser: this.popupData.assignedUser,
                client: this.popupData.client,
                expirationDate: this.popupData.selectedOffer.expirationDate,
                isProjectLocked: this.popupData.isProjectLocked,
            });
        } else {
            await validateOfferAndGeneratePDF({
                documentTypeId: this.selectedDocumentTypeId,
                pdfTypeId: this.selectedPdfTypeId,
                comment: this.comment,
                configurationOverride,
                offerId: this.popupData.selectedOffer.id,
                assignedUser: this.popupData.assignedUser,
                client: this.popupData.client,
                expirationDate: this.popupData.selectedOffer.expirationDate,
                isProjectLocked: this.popupData.isProjectLocked,
            });
        }

        this.isPendingRequest = false;
    }

    private async created() {
        await this.$nextTick();
        this.loadingOverlay.start();
        try {
            this.loading = true;
            await Promise.all([DocumentName.getAll(), OfferConfiguration.getAll(true)]);

            if (this.documentNames && this.documentNames.length > 0 && this.selectedDocumentTypeId === '') {
                this.selectedDocumentTypeId = this.documentNames[0]?.id;
            }
            if (this.pdfTypes && this.pdfTypes.length === 1 && this.selectedPdfTypeId === '') {
                this.selectedPdfTypeId = this.pdfTypes[0].id;
            }
            this.loading = false;
            this.loadingOverlay.stop();
        } catch (e) {
            this.loading = false;
            this.loadingOverlay.stop();
            return;
        }
    }

    @Watch(`originalOfferDocumentName`, { immediate: true })
    private onUpdateOriginalDocumentName() {
        this.selectedPdfTypeId = this.originalOfferOfferConfiguration;
    }

    @Watch(`originalOfferOfferConfiguration`, { immediate: true })
    private onUpdateOriginalOfferConfiguration() {
        this.selectedDocumentTypeId = this.originalOfferDocumentName;
    }
}
