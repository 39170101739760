import LeadType from '@/models/LeadType';

export default class LeadTypeRepository {
    public static getAll() {
        return LeadType.all();
    }

    public static getById(id: string) {
        return LeadType.query().whereId(id).withAll().first();
    }
}
