
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { RouteNames } from '@/enums/routes/RouteNames';
import { LocalStorageService } from '@/services/LocalStorageService';

@Component({
    name: 'RadioButtonGroup',
})
export default class RadioButtonGroup extends Vue {
    @Prop({ default: false }) private readonly isSolid!: boolean;
    @Prop({ default: '' }) private readonly defaultValue!: string;
    @Prop({ default: false }) private hasGridLayout!: boolean;
    @Prop({ default: null }) private topButtonColSpan!: number | null;
    @Prop({ required: true }) private readonly options!: RadioButtonGroupOptions[];
    @Prop({ required: true }) private readonly showTabs!: boolean;
    @Prop({ required: true }) private readonly selectedNavbarKey!: boolean;
    @Prop({ required: true }) private readonly collapsed!: boolean;
    @Prop({ required: true }) private readonly navWidth!: string;

    private radioValue: string = '';
    private iconTypes: string[] = ['database', 'message', 'tag', 'check-circle', 'eye', 'eye'];
    private radioWidth: string = '';

    private get areProjectTabsInactive() {
        return (
            this.$route.name !== RouteNames.home &&
            this.$route.name !== RouteNames.editClient &&
            this.$route.query.initialTab !== '2'
        );
    }

    private setDefaultValue(): void {
        if (!this.areProjectTabsInactive) {
            this.radioValue = this.defaultValue;
        }
    }

    private redirectHome(value: string) {
        if (
            this.$route.name !== RouteNames.home &&
            (this.$route.query.initialTab !== '2' || this.$route.name !== RouteNames.editClient)
        ) {
            this.$router.push({ name: RouteNames.home, query: { initialTab: value } });
        }
    }

    private onChange(e: Event) {
        const eventTarget = e.target as HTMLInputElement | null;
        if (eventTarget === null) {
            return;
        }

        if (this.$route.name === RouteNames.home) {
            this.$router.replace({ name: RouteNames.home, query: { value: eventTarget.value } });
        }
    }

    private get innerPixelWidth(): string {
        return 'width: ' + this.radioWidth + 'px';
    }

    private get routeQueryValue() {
        return this.$route.query.value;
    }

    private mounted() {
        this.setDefaultValue();
    }

    @Watch('selectedNavbarKey')
    private onMenuItemIsSelectedChange(selectedItem: string[]) {
        if (selectedItem.length && this.areProjectTabsInactive) {
            this.radioValue = '';
        }
    }

    @Watch('navWidth', { immediate: true })
    private setCurrentNavWidth(width: string) {
        this.radioWidth = width;
    }

    @Watch('routeQueryValue', { immediate: true })
    private onRouteQueryChange(val: string) {
        if (val) {
            this.$nextTick(() => {
                this.radioValue = val;
                this.$emit('radioButtonChange', val);

                LocalStorageService.save('lastSelectedTab', val);
            });
        }
    }
}
