
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'ChecklistStringFieldModule',
})
export default class ChecklistStringFieldModule extends Vue {
    @Prop({ required: true }) private pId!: string;
    @Prop() private activeProductFormId!: number | null;
    @Prop({ default: false }) private isDisabled!: boolean;
    @Prop({ default: null }) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Getter('configurator/activeProductFormValue') private productFormValue!: (
        pId: string,
        productFormId: number
    ) => string | null;

    private value = '';
    private disabledPIDs = ['p5541', 'p110207', 'p55590', 'p90591', 'p111592', 'p111600', 'p10622'];

    private get disabledField() {
        return this.disabledPIDs.includes(this.pId);
    }

    private get fieldValue() {
        if (this.activeProductFormId == null) {
            return '';
        }

        return this.productFormValue(this.pId, this.activeProductFormId);
    }

    private onChange(newValue: string) {
        EventBus.$emit(EventBusEvents.changesInDataMade, { state: true });

        this.$emit(EventBusEvents.updateStoreFieldValue, {
            pId: this.pId,
            value: String(newValue),
            productFormId: this.activeProductFormId,
        });
    }

    private onBlur() {
        this.debounceToggleIsActive && this.debounceToggleIsActive(false);
    }

    private onFocus() {
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    @Watch(`fieldValue`, { immediate: true, deep: true })
    private onNewFieldValue(newValue: string | null) {
        if (newValue == null) {
            this.value = '';
            return;
        }

        this.value = newValue;
    }
}
