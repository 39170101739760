import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class ClientTypeAPI {
    public static async getAll() {
        let clientTypes;

        try {
            clientTypes = await ApiController.get(ApiRoutes.clientType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientTypes);
    }

    public static async getById(clientTypeId: string) {
        let clientType;

        try {
            clientType = await ApiController.get(ApiRoutes.clientType.getById(clientTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientType);
    }

    public static async update(clientTypeId: string, clientTypeInfo: AvailableLabelFields) {
        let clientType;

        const request = {
            data: {
                type: 'client_types',
                id: clientTypeId,
                attributes: {
                    name: clientTypeInfo.name,
                    color: clientTypeInfo.color.hex,
                    description: clientTypeInfo.description,
                },
            },
        };

        try {
            clientType =
                await ApiController.patch(
                    ApiRoutes.clientType.update(clientTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientType);
    }

    public static async create(clientTypeInfo: AvailableLabelFields) {
        let clientType;
        const request = {
            data: {
                type: 'client_types',
                attributes: {
                    name: clientTypeInfo.name,
                    color: clientTypeInfo.color.hex,
                    description: clientTypeInfo.description,
                },
            },
        };

        try {
            clientType =
                await ApiController.post(
                    ApiRoutes.clientType.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientType);
    }

    public static async delete(clientTypeId: string) {

        try {
            await ApiController.delete(ApiRoutes.clientType.delete(clientTypeId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
