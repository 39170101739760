import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';

const getters: GetterTree<ILocalState, {}> = {
    areOffersLoading(state) {
        return state.areOffersLoading;
    },
    isProjectLoading(state) {
        return state.isProjectLoading;
    },
    isLeadLoading(state) {
        return state.isLeadLoading;
    },
    areNextStepsLoading(state) {
        return state.areNextStepsLoading;
    },
};

export default getters;
