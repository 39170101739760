import Vue from 'vue';
import Component from 'vue-class-component';
import { UserRightsEnum } from '@/enums/global/UserRights';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { CommonUserGroups } from '@/enums/global/CommonUserGroups';

@Component
export class UserRightsMixin extends Vue {
    public get canCreateClients() {
        return this.userRights.includes(UserRightsEnum.CREATE_CLIENT);
    }

    public get canEditClients() {
        return this.userRights.includes(UserRightsEnum.EDIT_CLIENT);
    }

    public get canDeleteClients() {
        return this.userRights.includes(UserRightsEnum.DELETE_CLIENT);
    }

    public get canSeeInquiries() {
        return this.userRights.includes(UserRightsEnum.SEE_INQUIRIES);
    }

    public get canEditDataSegmentClient() {
        return this.userRights.includes(UserRightsEnum.EDIT_CLIENT_DETAILED);
    }

    public get canViewUsersTab() {
        return this.userRights.includes(UserRightsEnum.VIEW_USER);
    }

    public get canCreateUser() {
        return this.userRights.includes(UserRightsEnum.CREATE_USER);
    }

    public get canEditUser() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER);
    }

    public get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }

    public get canSeeTranslationsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_TRANSLATIONS);
    }

    public get canSeeCMSItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CONTENT);
    }

    public get canSeeInfoButtonsItem() {
        // return this.userRights.includes(UserRightsEnum.CMS_INFO_BUTTONS);
        return true;
    }

    public get canSeeOrderPdfTemplatesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_INFO_BUTTONS);
    }

    public get canSeeStatusesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_STATUSES);
    }

    public get canSeeCampaignsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CAMPAIGNS);
    }

    public get canSeeCampaignTypesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CAMPAIGN_TYPES);
    }

    public get canSeeNextStepTypesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_NEXT_STEP_TYPES);
    }

    public get canSeeClientTypesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CLIENT_TYPES);
    }

    public get canSeeCatalogueItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CATALOG);
    }

    public get canSeePostCodeConnectionsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_POST_CODE_CONNECTIONS);
    }

    public get canSeeDocumentNamesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_DOCUMENT_NAMES);
    }

    public get canSeeOfferConfigurationsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_OFFER_CONFIGURATIONS);
    }

    public get canSeeTaxesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_TAXES);
    }

    public get canSeeAutomaticPriceManipulations() {
        return this.userRights.includes(UserRightsEnum.CMS_AUTOMATIC_PRICE_MANIPULATIONS);
    }

    public get canSeePdfFlaggedValues() {
        return this.userRights.includes(UserRightsEnum.CMS_FLAGGED_FLAGGED_PDF_VALUES);
    }

    public get canSeeAdminTab() {
        return this.userRights.includes(UserRightsEnum.VIEW_LEAD_ASSIGN);
    }

    public get canSeeAllNextSteps() {
        return this.userRights.includes(UserRightsEnum.VIEW_ALL_NEXT_STEPS);
    }
    public get canEditNextSteps() {
        return this.userRights.includes(UserRightsEnum.EDIT_NEXT_STEPS);
    }

    public get canDeleteNextSteps() {
        return this.userRights.includes(UserRightsEnum.DELETE_NEXT_STEPS);
    }

    public get canSeeChat() {
        return this.userRights.includes(UserRightsEnum.VIEW_CHAT);
    }

    public get canUserDeleteOffers() {
        return this.userRights.includes(UserRightsEnum.DELETE_OFFER);
    }

    public get isSuperAdmin() {
        return this.userRoles.includes('ROLE_SUPER_ADMIN');
    }

    public get canSeeUserGroupsItem() {
        return this.isSuperAdmin;
    }

    public get canSeeCurrenciesItem() {
        return this.isSuperAdmin && this.userRights.includes(UserRightsEnum.CMS_CURRENCIES);
    }

    public get canSeeLeadTypesItem() {
        return this.userRights.includes(UserRightsEnum.CMS_LEAD_TYPES);
    }

    public get canSeeEmailTemplateProperties() {
        return this.userRights.includes(UserRightsEnum.CMS_EMAIL_TEMPLATE_PROPERTIES);
    }

    public get canSeeOrderStatusItem() {
        return this.userRights.includes(UserRightsEnum.CMS_ORDER_STATUSES);
    }

    public get canSeeLeadOriginsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_LEAD_ORIGINS);
    }

    public get canSeeProductGroups() {
        return this.userRights.includes(UserRightsEnum.CMS_PRODUCT_GROUPS);
    }

    public get canUserSendOrders() {
        return this.userRights.includes(UserRightsEnum.SEND_ORDER);
    }

    public get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    public get isCurrentUserGroupRoltek() {
        return this.currentUserGroup && (<any>Object).values(CommonUserGroups).includes(this.currentUserGroup.name);
    }

    public get canUserEditPurchasePriceManipulations() {
        return this.userRights.includes(UserRightsEnum.EDIT_PURCHASE_PRICE_MANIPULATIONS);
    }

    public get canUserSeeInquiries() {
        return this.userRights.includes(UserRightsEnum.SEE_INQUIRIES);
    }

    public get canSeeB2BControlPanel() {
        if (this.currentUserGroup == null || this.currentUserGroup.name !== 'Roltek') {
            return false;
        }
        return this.userRights.includes(UserRightsEnum.B2B_CONTROL_PANEL);
    }

    public get canSeeCalendar() {
        return this.canSeeChat && this.canSeeAllNextSteps;
    }

    public get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    public get canAllowErrors() {
        return this.userRights.includes(UserRightsEnum.ALLOW_OFFER_ERRORS);
    }

    public get userRoles() {
        return this.$store.getters['jwtData/userRoles'];
    }

    public get canPrintOfferSpecification() {
        return this.userRights.includes(UserRightsEnum.PRINT_OFFER_SPECIFICATION);
    }

    public get canSeeWarrantyCMS() {
        return this.userRights.includes(UserRightsEnum.CMS_VALID_WARRANTY);
    }

    public get canSeeRoltekMontage() {
        return this.userRights.includes(UserRightsEnum.VIEW_ROLTEK_MONTAGE);
    }

    public get canSeeProductVersions() {
        return this.userRights.includes(UserRightsEnum.VIEW_PRODUCT_VERSIONS);
    }
}
