import { Model } from '@vuex-orm/core';
import LabelAPI from '@/api/LabelAPI';

export default class Label extends Model {
    public static entity = 'label';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            color: this.string(null),
            isDefault: this.boolean(false),
            projectState: this.string(null),
            shouldBeAvailable: this.boolean(false),
        };
    }

    public static async getAll() {
        let labels;
        try {
            labels = await LabelAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        await this.create({
            data: labels.data,
        });
        return Promise.resolve();
    }

    public static async getAllFromAllUserGroups() {
        let labels;
        try {
            labels = await LabelAPI.getAllFromAllUserGroups();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: labels.data,
        });
        return Promise.resolve();
    }

    public static async getByProjectState(projectState: string) {
        let labels;
        try {
            labels = await LabelAPI.getByProjectState(projectState);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: labels.data,
        });
        return Promise.resolve();
    }

    public static async getById(labelId: string) {
        let label;
        try {
            label = await LabelAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: label.data,
            insertOrUpdate: ['projectState'],
        });
        return Promise.resolve(label);
    }

    public static async updateExisting(labelId: string, labelInfo: any) {
        let label;
        try {
            label = await LabelAPI.update(labelId, labelInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: label.data,
            insertOrUpdate: ['projectState'],
        });
        return Promise.resolve(label);
    }

    public static async createNew(labelInfo: any) {
        let label;
        try {
            label = await LabelAPI.create(labelInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: label.data,
            insertOrUpdate: ['projectState'],
        });
        return Promise.resolve(label);
    }

    public static async deleteExisting(labelId: string) {
        try {
            await LabelAPI.delete(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Label.delete(labelId);
        return Promise.resolve();
    }

    public static async fetchAvailableLabels(projectId: string) {
        let labels;
        try {
            labels = await LabelAPI.fetchAvailableLabels(projectId);
        } catch (e) {
            return Promise.reject(e);
        }

        for (const label of labels.data) {
            label.shouldBeAvailable = true;
        }

        await this.insertOrUpdate({
            data: labels.data,
        });

        return Promise.resolve(labels);
    }
    public id!: string;
    public name!: string;
    public color!: string;
    public shouldBeAvailable!: boolean;
}
