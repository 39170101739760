import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class IntroScreenItemAPI {
    public static async getAll() {
        let introScreenItems;

        try {
            introScreenItems = await ApiController.get(ApiRoutes.introScreenItem.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreenItems);
    }

    public static async getById(introScreenItemId: string) {
        let introScreenItem;

        try {
            introScreenItem =
                await ApiController.get(ApiRoutes.introScreenItem.getById(introScreenItemId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreenItem);
    }

    public static async update(introScreenItemId: string, introScreenItemInfo: AvailableLabelFields) {
        let introScreenItem;
        const payload = this.generateRequestPayload({...introScreenItemInfo, id: introScreenItemId});

        try {
            introScreenItem =
                await ApiController.patch(
                    ApiRoutes.introScreenItem.update(introScreenItemId),
                    payload,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreenItem);
    }

    public static async create(introScreenItemInfo: AvailableLabelFields) {
        let introScreenItem;
        const payload = this.generateRequestPayload(introScreenItemInfo);

        try {
            introScreenItem =
                await ApiController.post(
                    ApiRoutes.introScreenItem.create,
                    payload,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(introScreenItem);
    }

    public static async delete(introScreenItemId: string) {
        try {
            await ApiController.delete(ApiRoutes.introScreenItem.delete(introScreenItemId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private static generateRequestPayload(introScreenItemInfo: AvailableLabelFields) {
        const payload = {
            data: {
                type: 'intro_screen_items',
                attributes: {
                    name: introScreenItemInfo.name,
                    color: introScreenItemInfo.color.hex,
                    rowNumber: introScreenItemInfo.rowNumber,
                    state: introScreenItemInfo.projectState === 'request'
                        ? introScreenItemInfo.projectState : undefined,
                },
                relationships: {},
            },
        };

        if (introScreenItemInfo.id) {
            // @ts-ignore
            payload.data.id = introScreenItemInfo.id;
        }

        switch (introScreenItemInfo.projectState) {
            case 'order':
                if (introScreenItemInfo.status) {
                    // @ts-ignore
                    payload.data.relationships.orderStatus = {
                        data: {
                            type: 'order_statuses',
                            id: introScreenItemInfo.status,
                        },
                    };
                }

                // @ts-ignore
                payload.data.relationships.projectLabel = {
                    data: null,
                };
                break;
            case 'request':
                // @ts-ignore
                payload.data.relationships.orderStatus = {
                    data: null,
                };
                // @ts-ignore
                payload.data.relationships.projectLabel = {
                    data: null,
                };
                break;
            default:
                if (introScreenItemInfo.status) {
                    // @ts-ignore
                    payload.data.relationships.projectLabel = {
                        data: {
                            type: 'project_labels',
                            id: introScreenItemInfo.status,
                        },
                    };
                }
                // @ts-ignore
                payload.data.relationships.orderStatus = {
                    data: null,
                };

        }

        if (introScreenItemInfo.introScreen) {
            // @ts-ignore
            payload.data.relationships.introScreen = {
                data: {
                    type: 'intro_screens',
                    id: introScreenItemInfo.introScreen,
                },
            };
        }
        return payload;
    }
}
