import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import { OfferItemRowNumber } from '@/interfaces/components/ProductsInOffer/OfferItemRowNumber';

export default class OfferTitleAPI {
    public static async getAll() {
        let offerTitles;

        try {
            offerTitles = (await ApiController.get(ApiRoutes.offerTitles.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitles);
    }

    public static async getById(offerTitleId: string) {
        let offerTitle;

        try {
            offerTitle = (await ApiController.get(
                ApiRoutes.offerTitles.getById(offerTitleId),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitle);
    }

    public static async delete(offerTitleId: string) {
        try {
            await ApiController.delete(ApiRoutes.offerTitles.delete(offerTitleId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(offerTitleId: string, offerTitleInfo: AvailableLabelFields) {
        let offerTitle;
        const request = {
            data: {
                type: 'offer_titles',
                id: offerTitleId,
                attributes: {
                    description: offerTitleInfo.description,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerTitleInfo.offerId,
                        },
                    },
                },
            },
        };
        try {
            offerTitle = (await ApiController.patch(
                ApiRoutes.offerTitles.update(offerTitleId),
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitle);
    }

    public static async create(offerTitleInfo: AvailableLabelFields) {
        let offerTitle;
        const request = {
            data: {
                type: 'offer_titles',
                attributes: {
                    description: offerTitleInfo.description,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerTitleInfo.offerId,
                        },
                    },
                },
            },
        };

        try {
            offerTitle = (await ApiController.post(ApiRoutes.offerTitles.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitle);
    }

    public static async updateRowNumbers(rowNumbers: OfferItemRowNumber[]) {
        const request = {
            data: {
                attributes: rowNumbers,
            },
        };

        let offerTitle;
        try {
            offerTitle = (await ApiController.patch(
                ApiRoutes.offerTitles.updateRowNumbers(),
                request,
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitle);
    }

    public static async duplicateExisting(offerTitleId: string) {
        let offerTitle;
        try {
            offerTitle = (await ApiController.post(
                ApiRoutes.offerTitles.duplicate(offerTitleId),
                {},
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerTitle);
    }
}
