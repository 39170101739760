import Label from '@/models/Label';

export default class LabelReporistory {
    public static getAll() {
        return Label.query().withAll().get();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return Label.query().whereId(id).withAll().first();
    }

    public static getAllDistinct() {
        const allLabels = Label.query().withAll().get();
        return [...new Map(allLabels.map((v) => [v.name, v])).values()];
    }

    public static delete(id: string) {
        return Label.delete(id);
    }

    public static getLabelsByProjectState(projectState: string) {
        return Label.query().where('projectState', projectState).get();
    }

    public static getAllAvailableLabels() {
        return Label.query().where('shouldBeAvailable', true).withAll().get();
    }

    public static getFirstThreeAvailableLabels() {
        return Label.query().where('shouldBeAvailable', true).withAll().limit(3).get();
    }

    public static getAvailableLabelsAfterFirstThree() {
        return Label.query().where('shouldBeAvailable', true).withAll().offset(3).get();
    }
}
