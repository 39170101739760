import User from '@/models/User';
import { GlobalOptions } from '@/enums/global/GlobalOptions';

export class LocalStorageService {

    public static save(name: string, value: string) {
        localStorage.setItem(name, value);
    }

    public static get(name: string) {
        return localStorage.getItem(name);
    }

    public static has(name: string) {
        return localStorage.getItem(name) !== null;
    }

    public static remove(name: string) {
        return localStorage.removeItem(name);
    }

    public static checkIfLocalStorageParametersShouldBeApplied(user: User | null, entity: string) {
        if (user == null || !LocalStorageService.has(`${entity}-${user.id}`)) {
            return false;
        }
        const localStorageParameters =
            // @ts-ignore
            JSON.parse(LocalStorageService.get(`${entity}-${user.id}`));

        const localStorageParametersVersion = localStorageParameters.v;

        if (localStorageParametersVersion !== GlobalOptions.FILTERS_VERSION) {
            return false;
        }

        return user.id === localStorageParameters.userId;
    }

}
