import { Model } from '@vuex-orm/core';
import UserAliasAPI from '@/api/UserAliasAPI';
import Country from '@/models/Country';

export default class UserAlias extends Model {

    public static entity = 'userAlias';

    public static fields() {
        return {
            id: this.string(null),
            country: this.belongsTo(Country, 'country_id'),
            country_id: this.attr(null),
            phoneNumber: this.string(''),
            email: this.string(''),
        };
    }

    public static async getById(labelId: string) {
        let userAlias;
        try {
            userAlias = await UserAliasAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: userAlias.data,
        });
        return Promise.resolve(userAlias);
    }

    public static async updateExisting(userAliasId: string, userAliasInfo: any) {
        let userAlias;
        try {
            userAlias = await UserAliasAPI.update(userAliasId, userAliasInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: userAlias.data,
        });
        return Promise.resolve(userAlias);
    }

    public static async createNew(userAliasInfo: any) {
        let userAlias;
        try {
            userAlias = await UserAliasAPI.create(userAliasInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: userAlias.data,
        });
        return Promise.resolve(userAlias);
    }

    public static async deleteExisting(userAliasId: string) {
        const userAliasExistsOnlyLocally = userAliasId.includes('uid');

        try {
            if (!userAliasExistsOnlyLocally) {
                await UserAliasAPI.delete(userAliasId);
            }
        } catch (e) {
            return Promise.reject(e);
        }
        await UserAlias.delete(userAliasId);
        return Promise.resolve();
    }

    public static async getAll() {
        let userAliases;

        try {
            userAliases = await UserAliasAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: userAliases.data,
        });

        return Promise.resolve(userAliases);
    }
    public id!: string;
    public country!: Country;
    public phoneNumber!: string;
    public email!: string;
}
