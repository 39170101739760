import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class PriceManipulationAPI {
    public static async getAll() {
        let priceManipulationTypes;

        try {
            priceManipulationTypes = await ApiController.get(ApiRoutes.priceManipulationType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulationTypes);
    }

    public static async getById(priceManipulationTypeId: string) {
        let priceManipulationType;

        try {
            priceManipulationType = await
                ApiController.get(ApiRoutes.priceManipulationType.getById(priceManipulationTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(priceManipulationType);
    }
}
