
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import CMSUser from '@/models/CMSUser';
import LeadOrigin from '@/models/LeadOrigin';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';

@Component({
    name: 'AdditionalInformation',
})
export default class AdditionalInformation extends Vue {
    @Prop({ required: true }) private value!: any;

    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }

    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }

    private get leadOrigins() {
        return LeadOriginRepository.getAll();
    }

    private async mounted() {
        try {
            await Promise.allSettled([CMSUser.getAll(), LeadOrigin.getAll()]);
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error fetching client data') as string,
                description: typeof (error as any) === 'string' ? (error as string) : '',
            });
        }
    }

    @Watch('value', {
        immediate: true,
        deep: true,
    })
    private onValueChange() {
        this.$emit('input', this.value);
    }
}
