import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';
import { JwtData } from '@/interfaces/general/JwtData';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import User from '@/models/User';
import { DynamicObject } from '@/interfaces/general/DynamicObject';

const actions: ActionTree<ILocalState, {}> = {
    async updateJwtData({ commit, getters }, jwtData: JwtData) {
        // todo - remove when this is removed from backend
        delete jwtData.userRights;
        commit('setJwtData', jwtData);

        return Promise.resolve();
    },

    async updateUserGroup({ commit }, userGroupId: number | null) {
        commit('setCurrentUserGroup', userGroupId);
        if (userGroupId != null) {
            LocalStorageService.save(LocalStorageKeyNames.userGroup, String(userGroupId));
        }
        EventBus.$emit(EventBusEvents.updateUserGroup, userGroupId);

        return Promise.resolve();
    },

    async updateUserRights({ commit }, user: User) {
        const userRights = user.userGroupConnections.reduce((previousValue: DynamicObject, currentValue) => {
            previousValue[currentValue.userGroup.id] = currentValue.userRights;

            return previousValue;
        }, {});

        commit('setUserRights', userRights);

        return Promise.resolve();
    },
};

export default actions;
