import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { TranslationRequest } from '@/interfaces/components/translations/TranslationRequest';
import { SearchOptions } from '@/interfaces/components/translations/SearchOptions';

export default class TranslationalObjectAPI {
    public static async getByRoute(searchOptions: SearchOptions) {
        let translationalObjects;

        try {
            translationalObjects =
                await ApiController.get(
                    ApiRoutes.translations.getByRoute(searchOptions));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(translationalObjects);
    }

    public static async updateExisting(
        {
            translatableProperties,
            entityName,
            locale,
            attribute,
            baseLocale,
        }: TranslationRequest) {
        let translationalObject;
        const request = {
            data: {
                type: 'translations',
                attributes: {
                    translatableProperties,
                    entityName,
                    locale,
                    attribute,
                },
            },
        };

        try {
            translationalObject = await ApiController.post(
                ApiRoutes.translations.update(baseLocale), request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(translationalObject);
    }
}
