import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class UserAliasAPI {
    public static async getAll() {
        let userAliases;

        try {
            userAliases = await ApiController.get(ApiRoutes.userAlias.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userAliases);
    }

    public static async getById(userAliasId: string) {
        let userAlias;

        try {
            userAlias = await ApiController.get(ApiRoutes.userAlias.getById(userAliasId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userAlias);
    }

    public static async delete(userAliasId: string) {

        try {
            await ApiController.delete(ApiRoutes.userAlias.delete(userAliasId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(userAliasId: string, userAliasInfo: AvailableLabelFields) {
        let userAlias;
        const request = {
            data: {
                type: 'user_aliases',
                id: userAliasId,
                attributes: {
                    phoneNumber: userAliasInfo.phoneNumber,
                    email: userAliasInfo.email,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: userAliasInfo.country.id,
                        },
                    },
                    user: {
                        data: {
                            type: 'users',
                            id: userAliasInfo.user,
                        },
                    },
                },
            },
        };


        try {
            userAlias =
                await ApiController.patch(
                    ApiRoutes.userAlias.update(userAliasId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userAlias);
    }

    public static async create(userAliasInfo: AvailableLabelFields) {
        let userAlias;
        const request = {
            data: {
                type: 'user_aliases',
                attributes: {
                    phoneNumber: userAliasInfo.phoneNumber,
                    email: userAliasInfo.email,
                },
                relationships: {
                    country: {
                        data: {
                            type: 'countries',
                            id: userAliasInfo.country.id,
                        },
                    },
                    user: {
                        data: {
                            type: 'users',
                            id: userAliasInfo.user,
                        },
                    },
                },
            },
        };

        try {
            userAlias =
                await ApiController.post(
                    ApiRoutes.userAlias.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(userAlias);
    }
}
