import OfferConfiguration from '@/models/OfferConfiguration';

export default class AddressRepository {
    public static getAll() {
        return OfferConfiguration.query().where('isArchived', false).get();
    }

    public static getById(id: string) {
        return OfferConfiguration.query().whereId(id).withAllRecursive().first();
    }
}
