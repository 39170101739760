import i18n from '@/i18n';
import { ElementFilterType } from '@/enums/global/ElementFilterType';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import OrderStatusRepository from '@/repositories/OrderStatusRepository';
import LabelReporistory from '@/repositories/LabelReporistory';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import OrderStatus from '@/models/OrderStatus';
import Label from '@/models/Label';
import { FilterOptionDefaultValues } from '@/interfaces/general/FilterOptionDefaultValues';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';
import LeadTypeRepository from '@/repositories/LeadTypeRepository';
import CampaignRepository from '@/repositories/CampaignReporistory';
import LeadProductRepository from '@/repositories/LeadProductRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { StateSwitchActions } from '@/enums/global/StateSwitchActions';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import PriceListRegionRepository from '@/repositories/PriceListRegionRepository';
import PriceTypeRepository from '@/repositories/PriceTypeRepository';

const detailedClientOptionalFilterOptions = () => {
    return [
        {
            label: i18n.t('Ingenious Id klijenta') as string,
            filter: 'client.visibleId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-visible-id-filter',
        },
        {
            label: i18n.t('Id klijenta') as string,
            filter: 'client.id',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-id-filter',
        },
        {
            label: i18n.t('Ime klijenta') as string,
            filter: 'client.name',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-name-filter',
        },
        {
            label: i18n.t('Ime kontakt osobe') as string,
            filter: 'contactPerson.name',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-name-filter',
        },
        {
            label: i18n.t('Email kontakt osobe') as string,
            filter: 'contactPerson.email',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-email-filter',
        },
        {
            label: i18n.t('Telefon kontakt osobe') as string,
            filter: 'contactPerson.phone',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-phone-filter',
        },
    ];
};
const detailedClientMandatoryFilterOptions = () => {
    return [
        {
            label: i18n.t('Tip klijenta') as string,
            filter: 'clientTypes',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: ClientTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'client-type-filter',
        },
        {
            label: i18n.t('Ulica i ku\u0107ni broj *') as string,
            filter: 'client.address.streetNameAndNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-street-name-and-number-filter',
        },
        {
            label: i18n.t('Po\u0161tanski broj *') as string,
            filter: 'client.address.poNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-po-number-filter',
        },
        {
            label: i18n.t('Grad *') as string,
            filter: 'client.address.city',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-city-filter',
        },
        {
            label: i18n.t('Dr\u017eava *') as string,
            filter: 'client.address.country',
            value: '',
            defaultValue: '',
            type: ElementFilterType.CountryPicker,
            isMandatory: true,
            dataTestId: 'client-address-country-filter',
        },
        {
            label: i18n.t('Odgovorna osoba za klijenta') as string,
            filter: 'client.assignedUser.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'client-assigned-user-filter',
        },
    ];
};

const clientOptionalFilterOptions = () => {
    return [
        {
            label: i18n.t('Ingenious Id klijenta') as string,
            filter: 'clientVisibleId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-visible-id-filter',
        },
        {
            label: i18n.t('Id klijenta') as string,
            filter: 'clientId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-id-filter',
        },
        {
            label: i18n.t('Ime klijenta') as string,
            filter: 'clientName',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-name-filter',
        },
        {
            label: i18n.t('Ime kontakt osobe') as string,
            filter: 'contactPersonName',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-name-filter',
        },
        {
            label: i18n.t('Email kontakt osobe') as string,
            filter: 'contactPersonEmail',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-email-filter',
        },
        {
            label: i18n.t('Telefon kontakt osobe') as string,
            filter: 'contactPersonPhone',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-phone-filter',
        },
    ];
};
const clientMandatoryFilterOptions = () => {
    return [
        {
            label: i18n.t('Tip klijenta') as string,
            filter: 'clientTypes',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: ClientTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'client-type-filter',
        },
        {
            label: i18n.t('Ulica i ku\u0107ni broj *') as string,
            filter: 'clientStreetNameAndNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-street-name-and-number-filter',
        },
        {
            label: i18n.t('Po\u0161tanski broj *') as string,
            filter: 'clientPoNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-po-number-filter',
        },
        {
            label: i18n.t('Grad *') as string,
            filter: 'clientCity',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-city-filter',
        },
        {
            label: i18n.t('Dr\u017eava *') as string,
            filter: 'clientCountryId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.CountryPicker,
            isMandatory: true,
            dataTestId: 'client-address-country-filter',
        },
    ];
};

const clientCmsFilterOptions = () => {
    return [
        {
            label: i18n.t('Ingenious Id klijenta') as string,
            filter: 'visibleId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-visible-id-filter',
        },
        {
            label: i18n.t('Id klijenta') as string,
            filter: 'id',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-id-filter',
        },
        {
            label: i18n.t('Ime klijenta') as string,
            filter: 'name',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-name-filter',
        },
        {
            label: i18n.t('Ime kontakt osobe') as string,
            filter: 'contactPersons.name',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-name-filter',
        },
        {
            label: i18n.t('Email kontakt osobe') as string,
            filter: 'contactPersons.email',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-email-filter',
        },
        {
            label: i18n.t('Telefon kontakt osobe') as string,
            filter: 'contactPersons.phone',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-contact-person-phone-filter',
        },
        {
            label: i18n.t('Tax ID') as string,
            filter: 'taxId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'client-tax-id-filter',
        },
        {
            label: i18n.t('Tip klijenta') as string,
            filter: 'clientTypes',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: ClientTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'client-type-filter',
        },
        {
            label: i18n.t('Ulica i ku\u0107ni broj *') as string,
            filter: 'address.streetNameAndNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-street-name-and-number-filter',
        },
        {
            label: i18n.t('Po\u0161tanski broj *') as string,
            filter: 'address.poNumber',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-po-number-filter',
        },
        {
            label: i18n.t('Grad *') as string,
            filter: 'address.city',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Input,
            isMandatory: true,
            dataTestId: 'client-address-city-filter',
        },
        {
            label: i18n.t('Dr\u017eava *') as string,
            filter: 'address.country',
            value: '',
            defaultValue: '',
            type: ElementFilterType.CountryPicker,
            isMandatory: true,
            dataTestId: 'client-address-country-filter',
        },
        {
            label: i18n.t('Odgovorna osoba za klijenta') as string,
            filter: 'assignedUser.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'client-assigned-user-filter',
        },
        {
            label: i18n.t('Izvor upita') as string,
            filter: 'leadOrigin.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadOriginRepository.getAll,
            isMandatory: true,
            isStandard: true,
            dataTestId: 'lead-origin-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUser.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            isStandard: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
    ];
};

const offerFilterOptions = (userId: string, label?: Label | null) => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'connection-switch',
        },
        {
            label: i18n.t('Id') as string,
            filter: 'typeSpecificId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'id-switch',
        },

        ...clientOptionalFilterOptions(),
        ...clientMandatoryFilterOptions(),
        {
            label: i18n.t('Status') as string,
            filter: 'projectLabelId',
            value: label ? [`label-${label.id}`] : [],
            defaultValue: [],
            type: ElementFilterType.Dropdown,
            dropdownSource: LabelReporistory.getAll,
            isMandatory: true,
            dataTestId: 'status-dropdown',
        },
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Komercijalist') as string,
            filter: 'assignedUserId',
            value: userId,
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'assigned-user-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUserId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
    ];
};
const requestFilterOptions = (userId: string, label?: Label | null) => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
        },
        {
            label: i18n.t('Id') as string,
            filter: 'offers.name',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
        },

        ...detailedClientOptionalFilterOptions(),
        ...detailedClientMandatoryFilterOptions(),
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUser.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
        },
        {
            label: i18n.t('Korisnička grupa') as string,
            filter: 'userGroupId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: UserGroupRepository.getAll,
            isMandatory: true,
        },
        {
            label: i18n.t('Lista cijene') as string,
            filter: 'client.priceListRegion.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: PriceListRegionRepository.getAll,
            isMandatory: true,
        },
        {
            label: i18n.t('Tip cijene') as string,
            filter: 'client.priceType.id',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: PriceTypeRepository.getAll,
            isMandatory: true,
        },
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
        },
        {
            label: i18n.t('Status') as string,
            filter: 'label.name',
            value: label ? label.name : '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownKey: 'name',
            isMandatory: true,
            dropdownSource: LabelReporistory.getAllDistinct,
        },
        {
            label: i18n.t('Komercijalist') as string,
            filter: 'assignedUser.id',
            value: userId,
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
        },
    ];
};
const orderFilterOptions = (userId: string, orderStatus?: OrderStatus | null) => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'connection-switch',
        },
        {
            label: i18n.t('Id') as string,
            filter: 'typeSpecificId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'id-switch',
        },
        {
            label: i18n.t('Project internal order ID') as string,
            filter: 'projectInternalOrderOrderNumber',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'project-internal-order-id',
        },
        ...clientOptionalFilterOptions(),
        ...clientMandatoryFilterOptions(),
        {
            label: i18n.t('Status') as string,
            filter: 'orderStatusId',
            value: orderStatus ? orderStatus.id : '',
            defaultValue: [],
            type: ElementFilterType.Dropdown,
            dropdownSource: OrderStatusRepository.getAll,
            isMandatory: true,
            dataTestId: 'status-dropdown',
        },
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Komercijalist') as string,
            filter: 'assignedUserId',
            value: userId,
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'assigned-user-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUserId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
        {
            label: i18n.t('Datum dostave') as string,
            filter: 'manufactureDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'delivery-date-range',
        },
    ];
};
const leadFilterOptions = (userId: string, label?: Label | null) => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'connection-switch',
        },
        {
            label: i18n.t('Id') as string,
            filter: 'id',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'id-switch',
        },
        ...clientOptionalFilterOptions(),
        ...clientMandatoryFilterOptions(),
        {
            label: i18n.t('Status') as string,
            filter: 'projectLabelId',
            value: label ? [`label-${label.id}`] : [],
            defaultValue: [],
            type: ElementFilterType.Dropdown,
            dropdownSource: LabelReporistory.getAll,
            isMandatory: true,
            dataTestId: 'status-dropdown',
        },
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Komercijalist') as string,
            filter: 'assignedUserId',
            value: userId,
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'assigned-user-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUserId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
        {
            label: i18n.t('Izvor upita') as string,
            filter: 'leadOriginId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadOriginRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-origin-dropdown',
        },
        {
            label: i18n.t('Vrsta upita') as string,
            filter: 'leadTypeId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-type-dropdown',
        },
        {
            label: i18n.t('Klijent se zanima za') as string,
            filter: 'leadProducts',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: LeadProductRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-products-dropdown',
        },
        {
            label: i18n.t('Kampanje') as string,
            filter: 'leadCampaignId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: CampaignRepository.getAll,
            isMandatory: true,
            dataTestId: 'campaign-dropdown',
        },
    ];
};
const allFilterOptions = (userId: string, label?: Label | null) => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'connection-switch',
        },
        {
            label: i18n.t('Id') as string,
            filter: 'typeSpecificId',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'id-switch',
        },
        ...clientOptionalFilterOptions(),
        ...clientMandatoryFilterOptions(),
        {
            label: i18n.t('Datum dostave') as string,
            filter: 'manufactureDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'delivery-date-range',
        },
        {
            label: i18n.t('Status') as string,
            filter: 'projectLabelId',
            value: label ? [`label-${label.id}`] : [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            multipleSelectType: 'or',
            dropdownSource: { label: LabelReporistory.getAll },
            isMandatory: true,
            shouldIncludeKeyInId: true,
            dataTestId: 'status-dropdown',
        },
        {
            label: i18n.t('Order status') as string,
            filter: 'orderStatusId',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            multipleSelectType: 'or',
            dropdownSource: { orderStatus: OrderStatusRepository.getAll },
            isMandatory: true,
            shouldIncludeKeyInId: true,
            dataTestId: 'order-status-dropdown',
        },
        {
            label: i18n.t('Uključi i Roltek produkciju?') as string,
            filter: 'request',
            value: true,
            defaultValue: false,
            type: ElementFilterType.StateSwitch,
            stateSwitchAction: StateSwitchActions.ADD,
            isMandatory: true,
            dataTestId: 'state-switch',
        },
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Datum izrade') as string,
            filter: 'createdAt',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Komercijalist') as string,
            filter: 'assignedUserId',
            value: userId,
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'assigned-user-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUserId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
        {
            label: i18n.t('Izvor upita') as string,
            filter: 'leadOriginId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadOriginRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-origin-dropdown',
        },
        {
            label: i18n.t('Vrsta upita') as string,
            filter: 'leadTypeId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-type-dropdown',
        },
        {
            label: i18n.t('Klijent se zanima za') as string,
            filter: 'leadProducts',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: LeadProductRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-products-dropdown',
        },
        {
            label: i18n.t('Kampanje') as string,
            filter: 'leadCampaignId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: CampaignRepository.getAll,
            isMandatory: true,
            dataTestId: 'campaign-dropdown',
        },
    ];
};
const adminFilterOptions = () => {
    return [
        {
            label: i18n.t('Projekt-veza') as string,
            filter: 'connection',
            value: true,
            defaultValue: true,
            type: ElementFilterType.Switch,
            dataTestId: 'connection-switch',
        },
        ...clientOptionalFilterOptions(),
        ...clientMandatoryFilterOptions(),
        {
            label: i18n.t('Datum') as string,
            filter: 'sortDate',
            value: [],
            defaultValue: [],
            type: ElementFilterType.DateRange,
            isOnlyDateOnDateRange: true,
            isMandatory: true,
            dataTestId: 'date-range',
        },
        {
            label: i18n.t('Izvor upita') as string,
            filter: 'leadOriginId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadOriginRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-origin-dropdown',
        },
        {
            label: i18n.t('Vrsta upita') as string,
            filter: 'leadTypeId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: LeadTypeRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-type-dropdown',
        },
        {
            label: i18n.t('Klijent se zanima za') as string,
            filter: 'leadProducts',
            value: [],
            defaultValue: [],
            type: ElementFilterType.MultipleSelect,
            dropdownSource: LeadProductRepository.getAll,
            isMandatory: true,
            dataTestId: 'lead-products-dropdown',
        },
        {
            label: i18n.t('Kampanje') as string,
            filter: 'leadCampaignId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            dropdownSource: CampaignRepository.getAll,
            isMandatory: true,
            dataTestId: 'campaign-dropdown',
        },
        {
            label: i18n.t('Napravio') as string,
            filter: 'madeByUserId',
            value: '',
            defaultValue: '',
            type: ElementFilterType.Dropdown,
            isMandatory: true,
            dropdownSource: CMSUserRepository.getAllExceptArchivedUsers,
            dataTestId: 'made-by-user-dropdown',
        },
    ];
};

export function getAppropriateFilterOptions({
    entityType,
    currentUser,
    label,
    orderStatus,
    shouldSetDefaultUser = true,
}: FilterOptionDefaultValues) {
    let currentUserAndSalesmanId = '';
    if (currentUser && shouldSetDefaultUser) {
        if (currentUser.isSalesman) {
            currentUserAndSalesmanId = currentUser.id;
        }
    }

    switch (entityType) {
        case ProjectTypes.Lead:
            return leadFilterOptions(currentUserAndSalesmanId, label);
        case ProjectTypes.Offer:
            return offerFilterOptions(currentUserAndSalesmanId, label);
        case ProjectTypes.Request:
            return requestFilterOptions(currentUserAndSalesmanId, label);
        case ProjectTypes.Order:
            return orderFilterOptions(currentUserAndSalesmanId, orderStatus);
        case ProjectTypes.Admin:
            return adminFilterOptions();
        case ProjectTypes.All:
            return allFilterOptions(currentUserAndSalesmanId, label);
        default:
            return clientCmsFilterOptions();
    }
}
