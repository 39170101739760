import { Model } from '@vuex-orm/core';
import TranslationAPI from '@/api/TranslationAPI';

export default class Translation extends Model {

    public static entity = 'translation';

    public get getTranslatableObjects() {
        // @ts-ignore
        return this.translatableObjects;
    }


    public static fields() {
        return {
            id: this.attr(''),
            translatableObjects: this.attr([]),
        };
    }

    public static async getAll() {
        let translations;
        try {
            translations = await TranslationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: translations.data,
        });
        return Promise.resolve();
    }
}
