import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { ApiController } from '@/services/ApiController';
import Rule from '@/models/Rule';
import { formatDateToISOString } from '@/helpers/LocaleHelper';
export default class ruleAPI {
    public static async getAll() {
        let rules;

        try {
            rules = (await ApiController.get(ApiRoutes.rule.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(rules);
    }

    public static async getById(id: string) {
        let rule;

        try {
            rule = (await ApiController.get(ApiRoutes.rule.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(rule);
    }

    public static async create(rule: any) {
        let newrule;

        const payload = {
            data: {
                type: 'rule',
                attributes: {
                    endDateTime: formatDateToISOString(rule.endDateTime),
                    startDateTime: formatDateToISOString(rule.startDateTime),
                    frequency: rule.frequency,
                    interval: rule.interval,
                    month: rule.month,
                    untilNum: rule.untilNum ?? '',
                    daily: rule.daily,
                    endDate: rule.endDate ?? '',
                    endTime: rule.endTime,
                    startDate: formatDateToISOString(rule.startDate),
                    untilDate: formatDateToISOString(rule.untilDate),
                },
            },
        };

        try {
            newrule = (await ApiController.post(ApiRoutes.rule.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newrule);
    }

    public static async update(ruleId: string, rule: any) {
        let updatedrule;

        const payload = {
            data: {
                type: 'rule',
                attributes: {
                    endDateTime: formatDateToISOString(rule.endDateTime),
                    startDateTime: formatDateToISOString(rule.startDateTime),
                    frequency: rule.frequency,
                    interval: rule.interval,
                    month: rule.month,
                    untilNum: rule.untilNum,
                    daily: rule.daily,
                    endDate: rule.endDate,
                    endTime: rule.endTime,
                    startDate: formatDateToISOString(rule.startDate),
                    untilDate: formatDateToISOString(rule.untilDate),
                },
                id: ruleId,
            },
        };

        try {
            updatedrule = (await ApiController.patch(ApiRoutes.rule.update(ruleId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedrule);
    }

    public static async delete(ruleId: string) {
        try {
            await ApiController.delete(ApiRoutes.rule.delete(ruleId));
        } catch (e) {
            return Promise.reject(e);
        }

        await Rule.delete(ruleId);

        return Promise.resolve();
    }
}
