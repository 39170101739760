export const PdfViewConfig = {
    sidebar: {
      viewThumbnail: true,
      viewOutline: false,
      viewAttachments: false,
    },
    secondaryToolbar: {
      secondaryPresentationMode: false,
      secondaryOpenFile: false,
      secondaryPrint: false,
      secondaryDownload: false,
      secondaryViewBookmark: false,
      firstPage: false,
      lastPage: false,
      pageRotateCw: true,
      pageRotateCcw: true,
      cursorSelectTool: false,
      cursorHandTool: false,
      scrollVertical: false,
      scrollHorizontal: false,
      scrollWrapped: false,
      spreadNone: false,
      spreadOdd: false,
      spreadEven: false,
      documentProperties: false,
    },
    toolbar: {
      toolbarViewerLeft: {
        findbar: true,
        previous: true,
        next: true,
        pageNumber: true,
      },
      toolbarViewerRight: {
        presentationMode: true,
        openFile: false,
        print: true,
        download: true,
        viewBookmark: false,
      },
      toolbarViewerMiddle: {
        zoomOut: true,
        zoomIn: true,
        scaleSelectContainer: true,
      },
    },
    errorWrapper: false,
  };
  
  export default PdfViewConfig;