import '@/router/class-component-hooks';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
    AutoComplete,
    Avatar,
    Badge,
    Button,
    Carousel,
    Checkbox,
    ConfigProvider,
    DatePicker,
    Dropdown,
    Empty,
    Form,
    Icon,
    Input,
    InputNumber,
    Layout,
    List,
    Menu,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Radio,
    Pagination,
    Select,
    Skeleton,
    Spin,
    Switch,
    Table,
    Tabs,
    Upload,
    Tooltip,
    Divider,
    Card,
    Row,
    Col,
    Progress,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/filters/index';
import i18n from './i18n';
/* tslint:disable */
const Loading = require('vue-loading-overlay');
import 'vue-loading-overlay/dist/vue-loading.css';
/* tslint:enable */
import vueDebounce from 'vue-debounce';

import VueLazyload from 'vue-lazyload';
// @ts-ignore
// import VueHtmlToPaper from 'vue-html-to-paper';
import VueHtmlToPaper from '@/mixins/HtmlToPaper';

import VueKonva from 'vue-konva';

const options = {
    specs: ['fullscreen=yes'],
    styles: ['/printTableStyle.css', '/printDashboardStyle.css'],
};

Vue.use(VueHtmlToPaper, options);

Vue.use(VueLazyload);

Vue.config.productionTip = false;

const components = [
    AutoComplete,
    Avatar,
    Badge,
    Button,
    Carousel,
    Checkbox,
    DatePicker,
    Dropdown,
    Form,
    Icon,
    Input,
    InputNumber,
    Layout,
    List,
    Menu,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Radio,
    Select,
    Spin,
    Switch,
    Table,
    Tabs,
    Upload,
    Skeleton,
    ConfigProvider,
    Empty,
    Tooltip,
    Divider,
    Card,
    Row,
    Col,
    Progress,
];

const install = () => {
    components.map((component) => {
        Vue.use(component);
    });

    Vue.prototype.$notification = notification;
    Vue.prototype.$info = Modal.info;
    Vue.prototype.$success = Modal.success;
    Vue.prototype.$error = Modal.error;
    Vue.prototype.$warning = Modal.warning;
    Vue.prototype.$confirm = Modal.confirm;
    Vue.prototype.$destroyAll = Modal.destroyAll;
};
install();

Vue.use(Loading);
Vue.use(vueDebounce, {
    listenTo: ['keyup', 'click'],
    defaultTime: '200ms',
});
Vue.use(VueKonva);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
