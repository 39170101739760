import RuleAPI from '@/api/RuleAPI';
import { Model } from '@vuex-orm/core';
import { AxiosError, AxiosResponse } from 'axios';
import Rrule from '@/models/interfaces/Rrule';

export default class rule extends Model {
    public static entity = 'rule';

    public static fields() {
        return {
            id: this.string(''),
            daily: this.string(''),
            endDate: this.string(''),
            endDateTime: this.string(''),
            endTime: this.string(''),
            frequency: this.string(''),
            interval: this.string(''),
            month: this.string(''),
            startDate: this.string(''),
            startDateTime: this.string(''),
            startTime: this.string(''),
            untilDate: this.string(''),
            untilNum: this.string(''),
        };
    }

    public static async getAll() {
        let rule;

        try {
            rule = await RuleAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: rule.data,
            insertOrUpdate: ['rule'],
        });

        return Promise.resolve(rule);
    }

    public static async getById(id: string) {
        let rule;
        try {
            rule = await RuleAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: rule.data,
            insertOrUpdate: ['rule'],
        });

        return Promise.resolve(rule);
    }

    public static async createNew(rule: Rrule): Promise<AxiosResponse | AxiosError> {
        let newrule;

        try {
            newrule = await RuleAPI.create(rule);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: newrule.data,
            insertOrUpdate: ['rule'],
        });

        return Promise.resolve(newrule);
    }

    public static async deleteExisting(ruleId: string) {
        try {
            await RuleAPI.delete(ruleId);
        } catch (e) {
            return Promise.reject(e);
        }

        await rule.delete(ruleId);

        return Promise.resolve();
    }

    public static async updateExisting(ruleId: string, ruleData: Rrule) {
        let newrule;

        try {
            newrule = await RuleAPI.update(ruleId, ruleData);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: newrule.data,
            insertOrUpdate: ['rule'],
        });

        return Promise.resolve();
    }

    public id!: string;
    public daily!: string;
    public endDate!: string;
    public endDateTime!: string;
    public endTime!: string;
    public frequency!: string;
    public interval!: string;
    public month!: string;
    public startDate!: string;
    public startDateTime!: string;
    public startTime!: string;
    public untilDate!: string;
    public untilNum!: string;
}
