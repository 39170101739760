import ProductSystemWizard from '@/models/ProductSystemWizard';

export default class ProductSystemWizardRepository {
    public static getAll() {
        return ProductSystemWizard.query().withAllRecursive().get();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return ProductSystemWizard.query().whereId(id).withAllRecursive().first();
    }
}
