import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    beforeRouteLeave(to: any, from: any, next: any) {
        // hasUnsavedData must be declared in the component
        // this mixin is used in
        // @ts-ignore
        if (!this.hasUnsavedData) {
            next();
            return;
        }
        // @ts-ignore
        const answer = this.confirmLeave();
        if (answer) {
            next();
        } else {
            next(false);
        }
    },
})
export class CheckUnsavedDataMixin extends Vue {

    public confirmLeave() {
        return window.confirm(this.$t('Imate nespremljenih promjena. Želite li stvarno odustati od njih?') as string);
    }

    private beforeWindowUnload(e: any) {
        if (this.confirmStayInDirtyForm()) {
            e.preventDefault();
            e.returnValue = '';
        }
    }

    private confirmStayInDirtyForm() {
        // hasUnsavedData must be declared in the component
        // this mixin is used in
        // @ts-ignore
        return this.hasUnsavedData && !this.confirmLeave();
    }

    private created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
    }

    private beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    }
}
