import { Model } from '@vuex-orm/core';
import ProductForm from '@/models/ProductForm';
import ChecklistSegment from '@/models/ChecklistSegment';

export default class Checklist extends Model {

    public static entity = 'checklist';

    public static fields() {
        return {
            id: this.attr(null),
            version: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            productForm_id: this.attr(null),
            productForm: this.belongsTo(ProductForm, 'productForm_id'),
            checklistSegments_ids: this.attr([]),
            checklistSegments: this.hasManyBy(ChecklistSegment, 'checklistSegments_ids'),
        };
    }

    public version!: string;
    public createdAt!: string;
    public updatedAt!: string;
}
