import ReminderOption from '../models/ReminderOption';

export default class ReminderOptionRepository {
    public static getAll() {
        return ReminderOption.all();
    }

    public static getById(id: string) {
        return ReminderOption.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return ReminderOption.delete(id);
    }
}
