import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import UserGroupConnection from '@/models/UserGroupConnection';

const mutations: MutationTree<ILocalState> = {
    setCmsUser(state, payload) {
        state.cmsUser = payload;
    },
    updateExistingUserGroupConnection(state, {userGroupConnection, userGroupConnectionKey}) {
        if (state.cmsUser === null) {
            return;
        }

        const userRights = Object.assign({}, userGroupConnection);
        delete userRights.userGroup;
        delete userRights.user;
        delete userRights.userRights;
        delete userRights.$id;
        delete userRights.id;
        delete userRights.key;
        delete userRights.userGroup_id;
        delete userRights.user_id;

        // @ts-ignore
        const userGroupConnectionIndex = state.cmsUser.userGroupConnections
            .findIndex((searchedUserGroupConnection: UserGroupConnection) =>
                searchedUserGroupConnection.key === userGroupConnectionKey);

        if (userGroupConnectionIndex === -1) {
            return;
        }


        // @ts-ignore
        state.cmsUser.userGroupConnections[userGroupConnectionIndex].userRights = Object.entries(userRights)
            .filter((entry) => entry[1])
            .map((entry) => entry[0]);
        // @ts-ignore
        state.cmsUser.userGroupConnections[userGroupConnectionIndex].userGroup = userGroupConnection.userGroup;
    },

    addUserGroupConnection(state, {userGroupConnection, userId}) {
        if (state.cmsUser === null) {
            return;
        }

        const userRights = Object.assign({}, userGroupConnection);
        delete userRights.userGroup;
        delete userRights.user;
        delete userRights.userRights;
        delete userRights.$id;
        delete userRights.id;
        delete userRights.key;
        delete userRights.userGroup_id;
        delete userRights.user_id;

        const formattedUserRights = Object.entries(userRights)
            .filter((entry) => entry[1])
            .map((entry) => entry[0]);

        // @ts-ignore
        state.cmsUser.userGroupConnections.push({
            // @ts-ignore
            key: String(state.cmsUser.userGroupConnections.length),
            user: {
                id: userId,
            },
            userGroup: userGroupConnection.userGroup,
            userRights: formattedUserRights,
        });
    },

    deleteUserGroupConnection(state, userGroupConnectionKey) {
        // @ts-ignore
         state.cmsUser.userGroupConnections = state.cmsUser.userGroupConnections
            .filter((searchedUserGroupConnection: UserGroupConnection) => {
                return searchedUserGroupConnection.key !== userGroupConnectionKey;
            });

    },

    setEntityUserGroup(state, {cmsEntity, currentUserGroup}) {
        state.fetchedEntityUserGroup[cmsEntity] =  currentUserGroup;
    },

};

export default mutations;
