
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ClientRepository from '@/repositories/ClientRepository';
import ContactConfig from '@/models/interfaces/Contact';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'DeleteContactPerson',
})
export default class DeleteContactPerson extends Vue {
    @Prop({ required: true }) private popupData!: any;

    private newContactPersonId: string | null = null;

    private get contactHasConnectedProjects() {
        return this.popupData.contact.projects != null ? this.popupData.contact.projects.length > 0 : false;
    }

    private get isSubmitButtonDisabled() {
        if (this.popupData.contact == null || !this.contactHasConnectedProjects) {
            return false;
        }

        if (this.filteredContactPersons.length > 1 && this.newContactPersonId == null) {
            return true;
        }

        return false;
    }

    private get client() {
        if (this.popupData.clientId == null) {
            return null;
        }

        return ClientRepository.getById(this.popupData.clientId);
    }

    private get clientHasOnlyOneRemainingContactPerson() {
        if (this.filteredContactPersons.length <= 0) {
            return false;
        }

        return this.filteredContactPersons.length === 1;
    }

    private get numberOfConnectedProjects() {
        if (!this.contactHasConnectedProjects) {
            return 0;
        }
        return this.popupData.contact.projects.length;
    }

    private get filteredContactPersons() {
        if (this.popupData.contactPersons.length <= 1) {
            return [];
        }

        return this.popupData.contactPersons.filter((pendingContact: ContactConfig) => {
            return pendingContact?.id !== this.popupData.contact?.id;
        });
    }

    private get projectNames() {
        if (!this.contactHasConnectedProjects) {
            return [];
        }

        return this.popupData.contact.projects.map((project: Project, index: number) => {
            return `${this.$t('Projekt')} ${index + 1}: ${
                project.connection != null ? project.connection : this.$t('Nema definiranu projekt-vezu')
            }`;
        });
    }

    private get deleteMessage() {
        if (this.numberOfConnectedProjects === 1) {
            return this.$t('Kontakt osoba koju želite obrisati ima dodijeljen jedan projekt');
        }
        return (this.$t(
            'Kontakt osoba koju želite obrisati ima __N__ dodijeljenih projekata',
        ) as string).replace('__N__', String(this.numberOfConnectedProjects));
    }

    private get oneRemainingContactPersonMessage() {
        return (this.$t(
            `Kontakt osoba __NAME__ će automatski biti dodijeljena ovim projektima umjesto trenutne`,
        ) as string).replace('__NAME__', this.filteredContactPersons[0].name);
    }

    private onDelete() {
        let newContactPersonId = this.newContactPersonId != null ? this.newContactPersonId : null;
        if (this.clientHasOnlyOneRemainingContactPerson) {
            newContactPersonId = this.filteredContactPersons[0].id;
        }
        this.popupData.deleteContact({
            contact: this.popupData.contact,
            pendingContactIndex: this.popupData.pendingContactIndex,
            newContactPersonId,
        });
    }

    private onContactPersonSelect(id: string) {
        this.newContactPersonId = id;
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }
}
