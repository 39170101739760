export enum InstallationAndDeliveryFormFields {
    ASSEMBLY = 'assembly',
    DELIVERY = 'delivery',
    PRICE = 'price',
    NUMBER_OF_WORKERS = 'numberOfWorkers',
    EXPECTED_TIME = 'expectedTime',
    ELECTRICIAN = 'electrician',
    DECOUNSTRUCTION = 'deconstruction',
    REMOVAL = 'removal',
}
