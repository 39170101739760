export enum CmsEntityTypes {
    ChecklistFields = 'checklist-fields',
    ProductDetails = 'product-details',
    Labels = 'labels',
    CampaignTypes = 'campaign-types',
    Campaigns = 'campaigns',
    NextStepTypes = 'next-step-types',
    Users = 'users',
    ClientTypes = 'client-types',
    Taxes = 'taxes',
    DocumentNames = 'document-names',
    UserGroups = 'user-groups',
    PdfOfferOptions = 'pdf-offer-options',
    PostCodeConnections = 'post-code-connections',
    UserGroupConnections = 'user-group-connections',
    LeadTypes = 'lead-types',
    LeadOrigins = 'lead-origins',
    Currency = 'currency',
    OrderStatus = 'order-status',
    IntroScreenItem = 'intro-screen-item',
    PaymentTypes = 'payment-types',
    TaxDetails = 'tax-details',
    Clients = 'clients',
    BankAccount = 'bank-accounts',
    ProjectLabelTemplates = 'project-label-templates',
    AutomaticPriceManipulations = 'automatic-price-manipulations',
    EmailTemplateProperties = 'email-template-properties',
    ProductGroups = 'product-groups',
    PdfOrderTemplatesOptions = 'order-pdf-templates',
    Statistics = 'statistics',
    News = 'news',
    FlaggedPdfValues = 'flagged-pdf-values',
    ProductImage = 'productImage',
    DimensionsWizard = 'dimensions-wizard',
    WarrantyWarnings = 'warranty-warnings',
}
