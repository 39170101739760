export const validParameters = [
    'id',
    'connection',
    'orderNumber',
    'offerName',
    'clientVisibleId',
    'projectLabelId',
    'clientId',
    'clientName',
    'contactPersonName',
    'contactPersonEmail',
    'contactPersonPhone',
    'madeByUserName',
    'madeByUserId',
    'assignedUserName',
    'assignedUserId',
    'countryId',
    'price',
    'sortDate',
    'createdAt',
    'updatedAt',
    'clientPhone',
    'clientStreetNameAndNumber',
    'clientPoNumber',
    'clientCity',
    'clientCountryId',
    'leadOriginId',
    'leadTypeId',
    'leadCampaignId',
    'projectInternalOrderOrderNumber',
    'offerDeliveryDateFrom',
    'typeSpecificId',
    'clientTypes',
    'manufactureDate',
    'leadProducts',
    'visibleId',
    'name',
    'contactPersons.name',
    'contactPersons.email',
    'contactPersons.phone',
    'taxId',
    'clientTypes',
    'address.streetNameAndNumber',
    'address.poNumber',
    'address.city',
    'address.country',
    'assignedUser.id',
    'orderStatusId',
    'madeByUser.id',
    'leadOrigin.id',
];
