import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class CurrencyAPI {
    public static async getAll() {
        let currencies;

        try {
            currencies = await ApiController.get(ApiRoutes.currency.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(currencies);
    }

    public static async getById(currencyId: string) {
        let currency;

        try {
            currency = await ApiController.get(ApiRoutes.currency.getById(currencyId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(currency);
    }

    public static async delete(currencyId: string) {

        try {
            await ApiController.delete(ApiRoutes.currency.delete(currencyId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(currencyId: string, currencyInfo: AvailableLabelFields) {
        let currency;
        const request = {
            data: {
                type: 'currencies',
                id: currencyId,
                attributes: {
                    name: currencyInfo.name,
                    symbol: currencyInfo.symbol,
                    exchangeRate: Number(currencyInfo.exchangeRate),
                },
            },
        };

        try {
            currency =
                await ApiController.patch(
                    ApiRoutes.currency.update(currencyId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(currency);
    }

    public static async create(currencyInfo: AvailableLabelFields) {
        let currency;
        const request = {
            data: {
                type: 'currencies',
                attributes: {
                    name: currencyInfo.name,
                    symbol: currencyInfo.symbol,
                    exchangeRate: Number(currencyInfo.exchangeRate),
                },
            },
        };

        try {
            currency =
                await ApiController.post(
                    ApiRoutes.currency.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(currency);
    }
}
