import UserGroupConnection from '@/models/UserGroupConnection';

export default class UserGroupConnectionRepository {
    public static getAll() {
        return UserGroupConnection.all();
    }

    public static getById(id: string) {
        return UserGroupConnection.query().whereId(id).withAll().first();
    }
}
