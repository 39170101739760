
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Client from '@/models/Client';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { generateGroupedList } from '@/helpers/GroupedListHelper';
import ClientRepository from '@/repositories/ClientRepository';
import { debounce } from 'vue-debounce';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CMSUser from '@/models/CMSUser';

@Component({
    name: 'AutocompleteGroupInput',
})
export default class AutocompleteGroupInput extends Vue {
    @Prop({ required: true }) private type!: string;
    @Prop({ default: false }) private isDisabled!: boolean;
    @Prop({ default: false }) private emitIfEmpty!: boolean;
    @Prop({ default: false }) private showDetailedClientInfo!: boolean;
    @Prop({ default: '' }) private defaultValue!: string;

    private debouncedClientNameChange = debounce(() => {
        this.onClientNameChange();
    }, 500);

    private clientName = '';
    private isLoading = false;
    private value = '';

    public clearInput() {
        this.value = '';
    }
    private get searchableClients() {
        if (this.clientName.length < 3) {
            return [];
        }

        const filteredClients = this.filterActiveRepository();
        if (this.isLoading) {
            return [
                {
                    group: 'LOADING',
                    children: [
                        {
                            name: 'loading',
                        },
                    ],
                },
            ];
        }
        if (!this.isLoading && (filteredClients == null || !filteredClients.length)) {
            return [
                {
                    group: 'EMPTY',
                    children: [
                        {
                            name: 'empty',
                        },
                    ],
                },
            ];
        }
        return generateGroupedList(filteredClients);
    }

    private detailedClientInfo(singleList: Client) {
        if (singleList.address == null || singleList.name === 'loading' || singleList.name === 'empty') {
            return '';
        }

        const clientInfoArray = [
            singleList.name,
            singleList.address.streetNameAndNumber,
            singleList.address.poNumber,
            singleList.address.city,
            singleList.address.country.name,
        ];

        if (singleList.contactPersons.length > 0 && singleList.contactPersons[0].phone) {
            clientInfoArray.push(singleList.contactPersons[0].phone);
        }
        return clientInfoArray.join(', ');
    }

    private onSelect(singleList: any) {
        if (singleList === undefined) {
            return;
        }
        this.value = singleList.name;
        this.$emit(EventBusEvents.dropDownValueSelect, singleList.id);
    }

    private filterActiveRepository() {
        switch (this.type) {
            case 'clients':
                return ClientRepository.getAll();
            case 'user':
                return CMSUserRepository.filterByName(this.clientName);
        }
    }

    private async searchOnActiveEntity() {
        switch (this.type) {
            case 'clients':
                return await Client.search(this.clientName);
            case 'user':
                return await CMSUser.search(this.clientName);
        }
    }

    private async onClientNameChange() {
        if (this.clientName.trim().length < 3) {
            return;
        }
        try {
            await this.searchOnActiveEntity();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.isLoading = false;
        }
        this.isLoading = false;
    }

    private async searchById(id: string) {
        switch (this.type) {
            case 'clients':
                return ClientRepository.getById(id);
            case 'user':
                const user = CMSUserRepository.getById(id);
                if (user != null && user.name !== '') {
                    return user;
                }
                return await CMSUser.getById(id);
        }
        return null;
    }

    private async onSearch() {
        if (this.clientName === '' && this.emitIfEmpty) {
            this.$emit(EventBusEvents.dropDownValueSelect, '');
        }
    }

    @Watch('defaultValue', {
        immediate: true,
    })
    private async onDefaultValueChange() {
        if (this.defaultValue === '') {
            this.value = '';
            return;
        }
        const entity = await this.searchById(this.defaultValue);
        if (entity == null) {
            return;
        }
        this.value = entity.name;
    }
}
