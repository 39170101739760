import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import NewsAttachmentAPI from '@/api/NewsAttachmentAPI';

export default class NewsAttachment extends Model {
    public static entity = 'newsAttachment';

    public static fields() {
        return {
            id: this.string(null),
            attachment: this.string(''),
            title: this.string(''),
            fileType: this.string(''),
            fileSize: this.string(''),
            displayName: this.string(''),
        };
    }

    public static async createNew(formData: FormData) {
        let attachment;
        try {
            attachment = (await NewsAttachmentAPI.createNew(formData)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(attachment);
    }

    public static async getBlob(resourceUrl: string) {
        return await NewsAttachmentAPI.getBlob(resourceUrl);
    }

    public static async getNewsAttachmentByNewsId(newsId: string) {
        let attachments;
        try {
            attachments = (await NewsAttachmentAPI.getAttachmentsByNewsId(newsId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: attachments.data,
        });

        return Promise.resolve(attachments);
    }

    public static async deleteExisting(attachmentId: string) {
        try {
            await NewsAttachmentAPI.delete(attachmentId);
        } catch (e) {
            return Promise.reject(e);
        }
        await NewsAttachment.delete(attachmentId);
        return Promise.resolve();
    }
    public id!: string;
    public uid!: string;
    public attachment!: string;
    public title!: string;
    public type!: string;
    public displayName!: string;
}
