export function getRandomElementFromObj<T>(obj: Record<any, T>): T {
    const values = Object.values(obj);
    const randomColor = values[Math.floor(Math.random() * values.length)];

    return randomColor;
}

export function deletePropertyByValue<T>(obj: Record<any, T>, targetValue: T): void {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === targetValue) {
            delete obj[key];
            break; // Assuming you want to delete only the first occurrence
        }
    }
}

export function findKeyByValue<T>(obj: Record<any, T>, targetValue: T): null | string | number {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === targetValue) {
            return key;
        }
    }

    return null;
}
