import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { WorkflowPayload } from '@/interfaces/components/Workflow/WorkflowPayload';
import Locale from '@/models/Locale';

export default class WorkflowTemplateAPI {
    public static async getAll() {
        let workflowTemplates;

        try {
            workflowTemplates = await ApiController.get(ApiRoutes.workflowTemplate.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflowTemplates);
    }

    public static async getById(workflowTemplateId: string) {
        let workflowTemplate;

        try {
            workflowTemplate = await
                ApiController.get(ApiRoutes.workflowTemplate.getById(workflowTemplateId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflowTemplate);
    }

    public static async getByLocale(localeId: string) {
        let workflowTemplate;

        try {
            workflowTemplate = await
                ApiController.get(ApiRoutes.workflowTemplate.getByLocale(localeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflowTemplate);
    }

    public static async delete(workflowTemplateId: string) {

        try {
            await ApiController.delete(ApiRoutes.workflowTemplate.delete(workflowTemplateId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(workflowTemplateId: string, workflowTemplatePayload: WorkflowPayload) {
        let workflowTemplate;
        const request = {
            data: {
                type: 'workflow_templates',
                id: workflowTemplateId,
                attributes: {
                    ...workflowTemplatePayload,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: (workflowTemplatePayload.locale as Locale).id,
                        },
                    },
                },
            },
        };

        try {
            workflowTemplate =
                await ApiController.patch(
                    ApiRoutes.workflowTemplate.update(workflowTemplateId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflowTemplate);
    }

    public static async create(workflowTemplatePayload: WorkflowPayload) {
        let workflowTemplate;
        const request = {
            data: {
                type: 'workflow_templates',
                attributes: {
                    name: (workflowTemplatePayload.locale as Locale).name,
                    ...workflowTemplatePayload,
                },
                relationships: {
                    locale: {
                        data: {
                            type: 'locales',
                            id: (workflowTemplatePayload.locale as Locale).id,
                        },
                    },
                },
            },
        };

        try {
            workflowTemplate =
                await ApiController.post(
                    ApiRoutes.workflowTemplate.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(workflowTemplate);
    }
}
