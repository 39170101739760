export enum DiscountFormFields {
    NAME = 'name',
    PC1 = 'pc1',
    PC2 = 'pc2',
    DISCOUNT_CATEGORY = 'discountCategory',
    DISCOUNTED_ENTITY_OID = 'discountedEntityOid',
    IS_PERCENTAGE = 'isPercentage',
    ACTIONS = 'actions',
    PRODUCT_GROUP = 'productGroup',
}
