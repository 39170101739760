import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import { vuexORMDatabase } from '@/services/ORMDatabase';
import configurator from './modules/configurator';
import jwtData from './modules/jwtData';
import temporaryCmsData from './modules/temporaryCmsData';
import projectLoadingState from './modules/projectLoadingState';
import tableData from './modules/tableData';
import generic from './modules/generic';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        VuexORM.install(vuexORMDatabase),
        createPersistedState({
            paths: ['tableData.projectNavigationSettings'],
        }),
    ],
    modules: {
        generic,
        configurator,
        jwtData,
        temporaryCmsData,
        projectLoadingState,
        tableData,
    },
});
