import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import ILocalState from './stateInterface';

const initState: ILocalState = {
    projectClientCreation: false,
    createdClient: null,
    createdContacts: [],
    calendarPopup: false,
    unreadNotifications: 0,
    sidebarCollapsed: true,
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
