import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';

export default class ProjectSearchAPI {
    public static async getAll(query: string) {
        let Projects;

        try {
            Projects = await ApiController.get(ApiRoutes.projects.getForTable(query));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(Projects);
    }
}
