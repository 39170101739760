import LeadOrigin from '@/models/LeadOrigin';

export default class LeadOriginRepository {
    public static getAll() {
        return LeadOrigin.all();
    }

    public static getById(id: string) {
        return LeadOrigin.query().whereId(id).withAll().first();
    }
}
