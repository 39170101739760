
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Label from '@/models/Label';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { Collection } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import Project from '@/models/Project';
import ActivityNewModal from '@/components/global/ActivityNewModal.vue';
import ProjectRepository from '@/repositories/ProjectRepository';
import NextStepTemplate from '@/models/NextStepTemplate';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProjectStatusSwitcher',
    components: {
        ActivityNewModal,
    },
})
export default class ProjectStatusSwitcher extends Vue {
    @Prop({ required: true }) private projectId!: null | string;
    @Prop({ required: true }) private currentLabel!: null | string;
    @Prop({ required: true }) private isProjectInRequestState!: boolean;
    @Prop({ required: true }) private projectHasActiveOrderStatus!: boolean;
    @Prop({ required: false }) private label!: null | string;
    @Prop({ required: false }) private color!: null | string;

    private isActivityNewModalVisible = false;
    private labels: Collection<Label> = [];
    private loading = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get shouldLabelsBeDisabled() {
        return this.isProjectInRequestState || this.projectHasActiveOrderStatus;
    }

    private get labelColor() {
        if (this.color) {
            return this.color;
        }

        // return
    }

    private get project(): Project | null {
        if (!this.projectId) {
            return null;
        }

        return ProjectRepository.getProjectById(this.projectId);
    }

    private get projectLead() {
        if (this.project) {
            return this.project.projectLead;
        }
    }

    private startLoading() {
        this.$emit('update:isLoading', true);
    }

    private stopLoading() {
        this.$emit('update:isLoading', false);
    }

    private async onChangeLabel(label: Label) {
        if (!this.projectId) {
            return;
        }

        EventBus.$emit(EventBusEvents.updateProject, { projectId: this.projectId, labelId: label.id });
        this.fetchNextStepTemplatesAndOpenPopup(label.id);
    }

    // LOGIC FOR OPENING MODAL ON STATUS CHANGE
    private async fetchNextStepTemplatesAndOpenPopup(labelId: string) {
        let templates: AxiosResponse<NextStepTemplate[]> | undefined;
        this.startLoading();

        try {
            await Promise.all([
                Project.getById(this.projectId as string),
                this.fetchNextStepTemplates(labelId).then((res) => (templates = res)),
            ]);
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error occured') as string,
                description: this.$t(typeof error === 'string' ? error : '') as string,
            });
            return;
        } finally {
            this.stopLoading();
        }

        if (!templates || templates.data.length === 0) {
            return;
        }

        const userId = this.getNextStepTemplateUserId(templates.data[0].responsiblePersonType);
        this.isActivityNewModalVisible = true;

        this.$nextTick(() => {
            EventBus.$emit(EventBusEvents.openActivityNewModalOnStatusChange, {
                templatesData: templates?.data,
                assignedUser: userId,
            });
        });
    }

    private async fetchNextStepTemplates(labelId: string) {
        let templates: AxiosResponse<NextStepTemplate[]> | null = null;

        try {
            templates = (await NextStepTemplate.getByLabelId(labelId)) as AxiosResponse<NextStepTemplate[]>;
        } catch (e) {
            Promise.reject(e);
        }

        if (templates) {
            return Promise.resolve(templates);
        }
    }

    private getNextStepTemplateUserId(responsiblePerson: string) {
        if (responsiblePerson === 'CREATOR') {
            return this.project?.projectMadeByUser?.id;
        }

        if (responsiblePerson === 'COMMERCIALIST') {
            return this.project?.projectAssignedUser?.id;
        }

        if (!isNaN(Number(responsiblePerson))) {
            return responsiblePerson;
        }
    }

    private async fetchAllowedStatuses() {
        if (this.projectId == null || this.shouldLabelsBeDisabled) {
            return;
        }
        const response = (await Label.fetchAvailableLabels(this.projectId)) as AxiosResponse<Label[]>;
        this.labels = response.data;
    }

    private async onClick() {
        if (!this.labels || this.labels.length <= 0) {
            this.loading = true;
            await this.fetchAllowedStatuses();
            this.loading = false;
        }
    }

    private openActivityNewModalAndFillData(data: any) {
        this.isActivityNewModalVisible = true;

        this.$nextTick(() => {
            EventBus.$emit(EventBusEvents.openActivityNewModalOnStatusChange, {
                templatesData: data.templatesData,
                assignedUser: data.assignedUser,
            });
        });
    }

    private async created() {
        EventBus.$on(EventBusEvents.fetchedNextStepTemplate, this.openActivityNewModalAndFillData);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.fetchedNextStepTemplate, this.openActivityNewModalAndFillData);
    }

    @Watch('currentLabel')
    private onLabelChange() {
        this.fetchAllowedStatuses();
    }
}
