var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.label)?_c('h5',{staticClass:"u-b3"},[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e(),(!_vm.shouldLabelsBeDisabled && _vm.currentLabel)?_c('a-dropdown',{staticClass:"status-switcher",attrs:{"trigger":['click']}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.labels),function(label){return _c('a-menu-item',{key:label.id,on:{"click":() => {
                        _vm.onChangeLabel(label);
                    }}},[_vm._v(" "+_vm._s(label.name)+" ")])}),1),_c('a-button',{staticClass:"u-b3",style:(_vm.labelColor
                    ? {
                          'background-color': _vm.labelColor + 20,
                          color: _vm.labelColor,
                          'border-color': _vm.labelColor,
                      }
                    : {}),attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.onClick()}}},[_vm._v(" "+_vm._s(_vm.currentLabel)+" "),_c('a-icon',{attrs:{"type":"down"}})],1)],1):_vm._e(),(_vm.shouldLabelsBeDisabled && _vm.currentLabel)?_c('a-button',{staticClass:"u-b3 status-switcher no-dropdown",style:(_vm.labelColor
                ? {
                      'background-color': _vm.labelColor + 20,
                      color: _vm.labelColor,
                      'border-color': _vm.labelColor,
                  }
                : {}),attrs:{"type":"text"}},[_vm._v(" "+_vm._s(_vm.currentLabel)+" ")]):_vm._e(),(!_vm.currentLabel)?_c('span',[_vm._v(" - ")]):_vm._e(),(_vm.isActivityNewModalVisible)?_c('ActivityNewModal',{attrs:{"isActivityNewModalVisible":_vm.isActivityNewModalVisible,"project":_vm.project,"lead":_vm.projectLead},on:{"update:isActivityNewModalVisible":function($event){_vm.isActivityNewModalVisible=$event},"update:is-activity-new-modal-visible":function($event){_vm.isActivityNewModalVisible=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }