import { Model } from '@vuex-orm/core';
import orderStatusAPI from '@/api/OrderStatusAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class OrderStatus extends Model {
    public static entity = 'orderStatus';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            code: this.string(null),
        };
    }

    public static async getAll() {
        let orderStatuses;

        if (ss.has('orderStatuses')) {
            orderStatuses = ss.getAndParse('orderStatuses');
        } else {
            try {
                const fetchedStatuses = await orderStatusAPI.getAll();
                orderStatuses = fetchedStatuses.data;

                ss.stringifyAndSave('orderStatuses', orderStatuses);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: orderStatuses,
        });

        return Promise.resolve(orderStatuses);
    }

    public static async getById(orderStatusId: string) {
        let orderStatus;
        try {
            orderStatus = await orderStatusAPI.getById(orderStatusId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: orderStatus.data,
        });
        return Promise.resolve(orderStatus);
    }

    public static async updateExisting(orderStatusId: string, orderStatusInfo: any) {
        let orderStatus;
        try {
            orderStatus = await orderStatusAPI.update(orderStatusId, orderStatusInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: orderStatus.data,
        });
        return Promise.resolve(orderStatus);
    }

    public static async createNew(orderStatusInfo: any) {
        let orderStatus;
        try {
            orderStatus = await orderStatusAPI.create(orderStatusInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: orderStatus.data,
        });
        return Promise.resolve(orderStatus);
    }

    public static async deleteExisting(orderStatusId: string) {
        try {
            await orderStatusAPI.delete(orderStatusId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OrderStatus.delete(orderStatusId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public code!: string;
}
