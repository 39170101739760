import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ClientInformationAPI {
    public static async getById(taxID: string) {
        let clientInformation;

        try {
            clientInformation = (await ApiController.get(
                ApiRoutes.clientInformation.getById(taxID),
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(clientInformation);
    }
}
