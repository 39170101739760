import BankAccount from '@/models/BankAccount';

export default class BankAccountRepository {
    public static getAllWithFormattedName() {
        return BankAccount.all().map((element) => {
            const name = `${element.bankName}: ${element.accountNumber}`;
            return {...element, name };
        });
    }

    public static getAll() {
        return BankAccount.all();
    }

    public static getById(id: string) {
        return BankAccount.query().whereId(id).withAll().first();
    }
}
