import ChecklistField from '@/models/ChecklistField';

export default class ChecklistFieldRepository {
    public static getAll() {
        return ChecklistField.query().withAllRecursive().get();
    }

    public static getValidChecklistFields() {
        return ChecklistField.query().where((checklistField: ChecklistField) => {
            return checklistField.fieldType !== 'warning' && checklistField.fieldType !== 'abort';
        }).withAllRecursive().get();
    }

    public static getById(id: string) {
        return ChecklistField.query().whereId(id).withAll().first();
    }
}
