import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import { formatDate } from '@/helpers/CmsIndexHelper';

export default class PaymentTypeAPI {
    public static async getAll() {
        let paymentTypes;

        try {
            paymentTypes = await ApiController.get(ApiRoutes.paymentType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentTypes);
    }

    public static async getById(paymentTypeId: string) {
        let paymentType;

        try {
            paymentType = await ApiController.get(ApiRoutes.paymentType.getById(paymentTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentType);
    }

    public static async fetchDefaultPaymentType() {
        let paymentType;

        try {
            paymentType = await ApiController.get(ApiRoutes.paymentType.fetchDefaultPaymentType) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentType);
    }

    public static async delete(paymentTypeId: string) {

        try {
            await ApiController.delete(ApiRoutes.paymentType.delete(paymentTypeId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(paymentTypeId: string, paymentTypeInfo: AvailableLabelFields) {
        let paymentType;
        const request = {
            data: {
                type: 'payment_types',
                id: paymentTypeId,
                attributes: {
                    templateName: paymentTypeInfo.templateName,
                    advance: Number(paymentTypeInfo.advance),
                    discount: Number(paymentTypeInfo.discount),
                    expirationDate: paymentTypeInfo.expirationDate ?
                        formatDate(paymentTypeInfo.expirationDate) : null,
                    isDefault: paymentTypeInfo.isDefault,
                },
            },
        };

        try {
            paymentType =
                await ApiController.patch(
                    ApiRoutes.paymentType.update(paymentTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentType);
    }

    public static async archive(paymentTypeId: string) {
        let paymentType;
        const request = {
            data: {
                type: 'payment_types',
                id: paymentTypeId,
                attributes: {
                    isArchived: true,
                },
            },
        };

        try {
            paymentType =
                await ApiController.patch(
                    ApiRoutes.paymentType.update(paymentTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentType);
    }

    public static async create(paymentTypeInfo: AvailableLabelFields) {
        let paymentType;
        const request = {
            data: {
                type: 'payment_types',
                attributes: {
                    templateName: paymentTypeInfo.templateName,
                    advance: Number(paymentTypeInfo.advance),
                    discount: Number(paymentTypeInfo.discount),
                    expirationDate: paymentTypeInfo.expirationDate ?
                        formatDate(paymentTypeInfo.expirationDate) : undefined,
                    isDefault: paymentTypeInfo.isDefault,
                },
            },
        };

        try {
            paymentType =
                await ApiController.post(
                    ApiRoutes.paymentType.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentType);
    }
}
