import { Model } from '@vuex-orm/core';
import DashboardAPI from '@/api/DashboardAPI';
import DashboardReport from '@/models/DashboardReport';

export default class Dashboard extends Model {
    public static entity = 'dashboard';

    public static fields() {
        return {
            id: this.string(''),
            dashboardReports: this.attr(null),
        };
    }

    public static async getById(dashboardId: string): Promise<any> {
        let dashboard;

        try {
            dashboard = await DashboardAPI.getById(dashboardId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: dashboard.data,
        });
        return Promise.resolve(dashboard);
    }

    public static async getAll(): Promise<any> {
        let dashboards;

        try {
            dashboards = await DashboardAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: dashboards.data,
        });
        return Promise.resolve(dashboards);
    }

    public static async getCurrent(): Promise<any> {
        let dashboard;

        try {
            dashboard = await DashboardAPI.getCurrent();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboard);
    }

    public static async getByUserId(userId: string, dashboardId: string | null): Promise<any> {
        let dashboard;

        try {
            dashboard = await DashboardAPI.getByUserId(userId, dashboardId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(dashboard);
    }

    public id!: string;
    public dashboardReports!: any[];
}
