import { Model } from '@vuex-orm/core';
import PostCodeConnectionConfig from '@/models/interfaces/PostCodeConnection';
import CMSUser from '@/models/CMSUser';
import PostCodeConnectionAPI from '@/api/PostCodeConnectionAPI';
import Country from '@/models/Country';

export default class PostCodeConnection extends Model {

    public static entity = 'postCodeConnection';

    public static fields() {
        return {
            id: this.attr(null),
            fromNumber: this.attr(null),
            toNumber: this.attr(null),
            user_id: this.attr(null),
            user: this.belongsTo(CMSUser, 'user_id'),
            country: this.belongsTo(Country, 'country_id'),
            country_id: this.attr(null),
        };
    }

    public static async getAll() {
        let postCodeConnections;
        try {
            postCodeConnections = await PostCodeConnectionAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: postCodeConnections.data,
        });
        return Promise.resolve();
    }

    public static async getById(postCodeConnectionId: string) {
        let postCodeConnection;
        try {
            postCodeConnection = await PostCodeConnectionAPI.getById(postCodeConnectionId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: postCodeConnection.data,
        });
        return Promise.resolve(postCodeConnection);
    }

    public static async updateExisting(postCodeConnectionId: string,
                                       postCodeConnectionInfo: PostCodeConnectionConfig) {
        let postCodeConnection;
        try {
            postCodeConnection = await PostCodeConnectionAPI.update(postCodeConnectionId, postCodeConnectionInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(postCodeConnection);
    }

    public static async createNew(postCodeConnectionInfo: PostCodeConnectionConfig) {
        let postCodeConnection;
        try {
            postCodeConnection = await PostCodeConnectionAPI.create(postCodeConnectionInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(postCodeConnection);
    }

    public static async deleteExisting(postCodeConnectionId: string) {
        try {
            await PostCodeConnectionAPI.delete(postCodeConnectionId);
        } catch (e) {
            return Promise.reject(e);
        }
        await PostCodeConnection.delete(postCodeConnectionId);
        return Promise.resolve();
    }

    public id!: string;
    public toNumber!: number;
    public fromNumber!: number;
    public user!: CMSUser;
    public country!: Country;

}
