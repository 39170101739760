// @ts-ignore
import { Node, Plugin } from 'tiptap';
// @ts-ignore
import { nodeInputRule } from 'tiptap-commands';

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export default class Image extends Node {
    private readonly uploadFunc: null | ((file: File) => Promise<void>);

    constructor(name: null, parent: null, uploadFunc: null | ((file: File) => Promise<void>) = null) {
        super(name, parent);
        this.uploadFunc = uploadFunc;
    }

    get name() {
        return 'image';
    }

    get schema() {
        return {
            inline: true,
            attrs: {
                src: {},
                alt: {
                    default: null,
                },
                title: {
                    default: null,
                },
            },
            group: 'inline',
            draggable: true,
            parseDOM: [
                {
                    tag: 'img[src]',
                    getAttrs: (dom: HTMLElement) => ({
                        src: dom.getAttribute('src'),
                        title: dom.getAttribute('title'),
                        alt: dom.getAttribute('alt'),
                    }),
                },
            ],
            toDOM: (node: Node) => ['img', node.attrs],
        };
    }

    get plugins() {
        const upload = this.uploadFunc;
        return [
            new Plugin({
                props: {
                    handlePaste(view: any, event: any) {
                        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
                        for (const item of items) {
                            if (item.type.indexOf('image') === 0) {
                                event.preventDefault();
                                const {schema} = view.state;

                                const image = item.getAsFile();

                                if (upload) {
                                    upload(image).then((src) => {
                                        const node = schema.nodes.image.create({
                                            src,
                                        });
                                        const transaction = view.state.tr.replaceSelectionWith(node);
                                        view.dispatch(transaction);
                                    });

                                } else {
                                    const reader = new FileReader();
                                    reader.onload = (readerEvent) => {
                                        const node = schema.nodes.image.create({
                                            src: (readerEvent.target as FileReader).result,
                                        });
                                        const transaction = view.state.tr.replaceSelectionWith(node);
                                        view.dispatch(transaction);
                                    };
                                    reader.readAsDataURL(image);
                                }

                            }
                        }
                        return false;
                    },
                    handleDOMEvents: {
                        drop(view: any, event: any) {
                            const hasFiles = event.dataTransfer
                                && event.dataTransfer.files
                                && event.dataTransfer.files.length;

                            if (!hasFiles) {
                                return;
                            }

                            const images = Array
                                .from(event.dataTransfer.files)
                                .filter((file: any) => (/image/i).test(file.type));

                            if (images.length === 0) {
                                return;
                            }

                            event.preventDefault();

                            const {schema} = view.state;
                            const coordinates = view.posAtCoords({left: event.clientX, top: event.clientY});

                            images.forEach(async (image: any) => {
                                const reader = new FileReader();

                                if (upload) {
                                    const node = schema.nodes.image.create({
                                        src: await upload(image),
                                    });
                                    const transaction = view.state.tr.insert(coordinates.pos, node);
                                    view.dispatch(transaction);
                                } else {
                                    reader.onload = (readerEvent) => {
                                        const node = schema.nodes.image.create({
                                            src: (readerEvent.target as FileReader).result,
                                        });
                                        const transaction = view.state.tr.insert(coordinates.pos, node);
                                        view.dispatch(transaction);
                                    };
                                    reader.readAsDataURL(image);
                                }
                            });
                        },
                    },
                },
            }),
        ];
    }

    public commands({type}: any) {
        return (attrs: any) => (state: any, dispatch: any) => {
            const {selection} = state;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
        };
    }

    public inputRules({type}: any) {
        return [
            nodeInputRule(IMAGE_INPUT_REGEX, type, (match: any) => {
                const [, alt, src, title] = match;
                return {
                    src,
                    alt,
                    title,
                };
            }),
        ];
    }

}
