<template>
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.583984 0.666504V3.4165H5.62565V14.4165H8.37565V3.4165H13.4173V0.666504H0.583984Z" fill="#8C8C8C"/>
    </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'RTETitleIcon',
})
export default class RTETitleIcon extends Vue {
}
</script>

<style lang="scss" scoped>

</style>
