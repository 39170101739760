import { Model } from '@vuex-orm/core';
import Label from '@/models/Label';

export default class ProjectLabelWorkflowNode extends Model {
    public static entity = 'projectLabelWorkflowNode';

    public static fields() {
        return {
            id: this.string(null),
            x: this.number(0),
            y: this.number(0),
            projectLabel: this.belongsTo(Label, 'projectLabel_id'),
            projectLabel_id: this.attr(null),
        };
    }

    public id!: string;
    public x!: number;
    public y!: number;
    public projectLabel!: Label;
}
