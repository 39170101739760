import { Model } from '@vuex-orm/core';
import CurrencyAPI from '@/api/CurrencyAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class Currency extends Model {
    public static entity = 'currency';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            symbol: this.string(null),
            exchangeRate: this.number(0),
        };
    }

    public static async getAll() {
        let currencies;

        if (ss.has('currencies')) {
            currencies = ss.getAndParse('currencies');
        } else {
            try {
                const fetchedCurrencies = await CurrencyAPI.getAll();
                currencies = fetchedCurrencies.data;

                ss.stringifyAndSave('currencies', currencies);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: currencies,
        });
        return Promise.resolve();
    }

    public static async getById(currencyId: string) {
        let currency;
        try {
            currency = await CurrencyAPI.getById(currencyId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: currency.data,
        });
        return Promise.resolve(currency);
    }

    public static async updateExisting(currencyId: string, currencyInfo: any) {
        let currency;
        try {
            currency = await CurrencyAPI.update(currencyId, currencyInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: currency.data,
        });
        return Promise.resolve(currency);
    }

    public static async createNew(currencyInfo: any) {
        let currency;
        try {
            currency = await CurrencyAPI.create(currencyInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: currency.data,
        });
        return Promise.resolve(currency);
    }

    public static async deleteExisting(currencyId: string) {
        try {
            await CurrencyAPI.delete(currencyId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Currency.delete(currencyId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public symbol!: string;
    public exchangeRate!: number;
}
