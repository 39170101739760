import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import Schedule from '@/models/Schedule';
import { ApiController } from '@/services/ApiController';

export default class ScheduleAPI {
    // TEMP: login request

    public static async getAll() {
        let getAllSchedules;

        try {
            getAllSchedules = (await ApiController.get(ApiRoutes.schedule.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(getAllSchedules);
    }

    public static async getById(id: string) {
        let schedule;
        try {
            schedule = (await ApiController.get(ApiRoutes.schedule.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(schedule);
    }

    public static async getByUserId(id: string) {
        let schedules;
        try {
            schedules = (await ApiController.get(ApiRoutes.schedule.getByUser(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(schedules);
    }

    public static async getByResourceId(id: string) {
        let schedules;
        try {
            schedules = (await ApiController.get(ApiRoutes.schedule.getByResource(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(schedules);
    }

    public static async create(schedule: Schedule) {
        let newSchedule;

        const payload: any = {
            data: {
                type: 'schedule',
                attributes: {
                    ...schedule,
                    recurrenceExpression: schedule.recurrenceExpression ?? '',
                },
                relationships: {},
            },
        };

        if (schedule.ruleId && schedule.ruleId !== 'null') {
            payload.data.relationships.rule = {
                data: {
                    type: 'rules',
                    id: schedule.ruleId,
                },
            };
        }

        delete payload.data.attributes.ruleId;

        try {
            newSchedule = (await ApiController.post(ApiRoutes.schedule.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newSchedule);
    }

    public static async update(scheduleId: string, schedule: Schedule) {
        let updatedSchedule;

        const payload: any = {
            data: {
                type: 'schedule',
                attributes: {
                    dateTimeFrom: schedule.dateTimeFrom,
                    dateTimeTo: schedule.dateTimeTo,
                    duration: schedule.duration,
                    recurrenceExpression: schedule.recurrenceExpression ?? '',
                    title: schedule.title,
                    userChanged: String(schedule.userChanged) ?? '',
                    userInserted: String(schedule.userInserted) ?? '',
                },
                id: scheduleId,
                relationships: {},
            },
        };

        if (schedule.resourceId && schedule.resourceId !== 'null') {
            payload.data.attributes.resourceId = schedule.resourceId;
        }

        if (schedule.userId && schedule.userId !== 'null') {
            payload.data.attributes.userId = schedule.userId;
        }

        if (schedule.ruleId && schedule.ruleId !== 'null') {
            payload.data.relationships.rule = {
                data: {
                    type: 'rules',
                    id: schedule.ruleId,
                },
            };
        }

        try {
            updatedSchedule = (await ApiController.patch(
                ApiRoutes.schedule.update(scheduleId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedSchedule);
    }

    public static async delete(scheduleId: string) {
        try {
            (await ApiController.delete(ApiRoutes.schedule.delete(scheduleId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private static token: string | null = null;
}
