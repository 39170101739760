import { Model } from '@vuex-orm/core';
import NextStepTypeAPI from '@/api/NextStepTypeAPI';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
export default class NextStepType extends Model {
    public static entity = 'nextStepType';

    public static fields() {
        return {
            id: this.string(null),
            color: this.string(null),
            name: this.attr(null),
        };
    }

    public static async getAll() {
        let nextStepTypes;

        if (ss.has('nextStepTypes')) {
            nextStepTypes = ss.getAndParse('nextStepTypes');
        } else {
            try {
                const fetchedTypes = await NextStepTypeAPI.getAll();
                nextStepTypes = fetchedTypes.data;

                ss.stringifyAndSave('nextStepTypes', nextStepTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: nextStepTypes,
        });

        return Promise.resolve();
    }

    public static async getById(nextStepTypeId: string) {
        let nextStepType;
        try {
            nextStepType = await NextStepTypeAPI.getById(nextStepTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextStepType.data,
        });
        return Promise.resolve(nextStepType);
    }

    public static async updateExisting(nextStepTypeId: string, nextStepTypeInfo: any) {
        let nextStepType;
        try {
            nextStepType = await NextStepTypeAPI.update(nextStepTypeId, nextStepTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextStepType.data,
        });
        return Promise.resolve(nextStepType);
    }

    public static async createNew(nextStepTypeInfo: any) {
        let nextStepType;
        try {
            nextStepType = await NextStepTypeAPI.create(nextStepTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextStepType.data,
        });
        return Promise.resolve(nextStepType);
    }

    public static async deleteExising(nextStepTypeId: string) {
        try {
            await NextStepTypeAPI.delete(nextStepTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await NextStepTypeRepository.delete(nextStepTypeId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public color!: string;
}
