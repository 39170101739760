import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';

export default class ProjectLabelTemplateRepository {
    public static getAll() {
        return ProjectLabelTemplate.query().withAll().get();
    }

    public static getAllByLocale(localeId: string) {
        return ProjectLabelTemplate
            .query()
            .withAll()
            .where('locale_id', (projectLabelTemplateLocaleId: string | null) => {
                return projectLabelTemplateLocaleId == null || localeId === projectLabelTemplateLocaleId;
            }).get();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return ProjectLabelTemplate.query().whereId(id).withAll().first();
    }

    public static delete(id: string) {
        return ProjectLabelTemplate.delete(id);
    }
}
