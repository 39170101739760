
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { Getter } from 'vuex-class';
import { toggleClassBasedOnOverlapFromTargetElement } from '@/helpers/ScrollHelper';
import { debounce } from 'vue-debounce';
import { UserRepository } from '@/repositories/UserRepository';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'PriceModule',
})
export default class PriceModule extends Vue {
    @Getter('projectLoadingState/areOffersLoading')
    private areOffersLoading!: boolean;
    @Prop({ required: true }) private sellingPriceList!: PriceItem[];
    @Prop({ required: true }) private purchasePriceList!: PriceItem[];
    @Prop({ required: true }) private roltekPriceList!: PriceItem[];
    @Prop({ default: false }) private shouldHandleIfOffersAreLoaded!: boolean;
    @Prop({ default: false }) private shouldRecalculatePosition!: boolean;
    @Prop({ default: undefined }) private overlappingElement!: Vue | undefined;

    // false = SellingPrice; true = PurchasePrice
    private togglePrice: boolean = false;

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get canUserViewPurchasePrice() {
        return this.userRights.includes(UserRightsEnum.VIEW_PURCHASE_PRICE);
    }

    private get currentUserGroup() {
        return String(this.$store.getters['jwtData/currentUserGroup']);
    }

    private get toggledPriceList(): PriceItem[] {
        if (this.togglePrice && this.purchasePriceList) {
            if (this.currentUserGroup == '1' || this.currentUserGroup == '2') {
                return this.roltekPriceList;
            } else {
                return this.purchasePriceList;
            }
        } else {
            if (this.currentUserGroup == '1' || this.currentUserGroup == '2') {
                return this.purchasePriceList;
            } else {
                return this.sellingPriceList;
            }
        }
    }

    private get finalPurchasePrice() {
        if (this.currentUserGroup == '1' || this.currentUserGroup == '2') {
            return this.roltekPriceList[this.roltekPriceList.length - 1];
        } else {
            if (!this.purchasePriceList || this.purchasePriceList.length === 0) {
                return null;
            }
            return this.purchasePriceList[this.purchasePriceList.length - 1];
        }
    }

    private get finalSellingPrice() {
        if (this.sellingPriceList.length === 0) {
            return null;
        }

        return this.sellingPriceList[this.sellingPriceList.length - 1];
    }

    private get shouldShowLoading() {
        if (!this.shouldHandleIfOffersAreLoaded) {
            return false;
        }

        return this.areOffersLoading;
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private debouncedHandleScroll = debounce(() => {
        this.handleScroll();
    }, 40);

    private handleScroll() {
        if (this.overlappingElement == null) {
            return;
        }

        const priceModuleElement = this.$refs.priceModule as HTMLElement;
        const overlappingElement = this.overlappingElement.$el as HTMLElement;

        toggleClassBasedOnOverlapFromTargetElement(priceModuleElement, overlappingElement, 'isOverlappingButton');
    }

    private created() {
        if (this.shouldRecalculatePosition) {
            window.addEventListener('scroll', this.debouncedHandleScroll);
        }
    }

    private beforeDestroy() {
        if (this.shouldRecalculatePosition) {
            window.removeEventListener('scroll', this.debouncedHandleScroll);
        }
    }

    @Watch('sellingPriceList', { deep: true })
    private onPriceListChange() {
        if (this.sellingPriceList && this.sellingPriceList.length > 0) {
            this.$nextTick(() => {
                this.handleScroll();
            });
        }
    }
}
