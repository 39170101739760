import Offer from '@/models/Offer';

export class OfferService {
    public static hasPdfRelationship(offer: Offer | null) {
        if (offer == null || offer.pdf == null) {
            return false;
        }

        return true;
    }

    public static hasOriginalOffer(offer: Offer | null) {
        if (offer == null || offer.originalOfferId == null) {
            return false;
        }

        return true;
    }
}
