import PriceType from '@/models/PriceType';

export default class PriceTypeRepository {
    public static getAll() {
        return PriceType.all();
    }

    public static getById(id: string) {
        return PriceType.query().whereId(id).withAll().first();
    }
}
