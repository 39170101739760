import OrderConfiguration from '@/models/OrderConfiguration';

export default class OrderConfigurationRepository {
    public static getAll() {
        return OrderConfiguration.query().get();
    }

    public static getById(id: string) {
        return OrderConfiguration.query().whereId(id).withAllRecursive().first();
    }
}
