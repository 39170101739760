import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';

const getters: GetterTree<ILocalState, {}> = {
    cmsUser(state) {
        return state.cmsUser;
    },
    cmsUserId(state) {
        if (state.cmsUser == null) {
            return null;
        }

        // @ts-ignore
        return state.cmsUser.id;
    },
    cmsEntityUserGroup: (state) => (cmsEntity: string) => {
    return state.fetchedEntityUserGroup[cmsEntity];
},

};

export default getters;
