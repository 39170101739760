
import { Vue, Component } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import ContactSlot from '@/components/global/popup/ContactSlot.vue';
import { ModalWidth } from '@/enums/global/ModalWidth';

@Component({
    name: 'ContactPopup',
    components: {ContactSlot},
})
export default class ContactPopup extends Vue {
    public popupEvents: any = PopupEvents;
    public popupData: any = null;
    private title: string = '';
    private visible: boolean = false;
    private popupEvent: string = '';
    private modalWidth = ModalWidth;

    private eventBusListeners() {
        EventBus.$on(EventBusEvents.openContactPopup, (eventData: PopupEventData) => {
            this.openPopup(eventData.popupEvent);

            if (eventData.data === undefined) {
                return;
            }

            this.popupData = eventData.data;
        });

        EventBus.$on(EventBusEvents.closeContactPopup, () => {
            this.closePopup();
        });
    }

    private openPopup(popupEvent: string) {
        this.popupEvent = popupEvent;
        this.visible = true;
    }

    private closePopup() {
        this.visible = false;
        this.popupEvent = '';
        this.title = '';
    }

    private mounted() {
        this.eventBusListeners();
    }
}
