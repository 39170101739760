import LeadProduct from '@/models/LeadProduct';

export default class LeadProductRepository {
    public static getAll() {
        return LeadProduct.all();
    }

    public static getById(id: string) {
        return LeadProduct.query().whereId(id).withAll().first();
    }
}
