
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import ChecklistBoolFieldModule from '@/components/views/productView/ChecklistBoolFieldModule.vue';
import ChecklistDropdownFieldModule from '@/components/views/productView/ChecklistDropdownFieldModule.vue';
import ChecklistFloatFieldModule from '@/components/views/productView/ChecklistFloatFieldModule.vue';
import ChecklistStringFieldModule from '@/components/views/productView/ChecklistStringFieldModule.vue';
import ChecklistIntegerFieldModule from '@/components/views/productView/ChecklistIntegerFieldModule.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { determineFieldVisibility } from '@/helpers/FieldFunctionHelper';
import { Action } from 'vuex-class';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
// @ts-ignore
import is from 'is_js';
import RichTextEditor from '@/components/views/CmsIndex/RichTextEditor.vue';
import { GlobalOptions } from '@/enums/global/GlobalOptions';

enum fieldType {
    float = 'ChecklistFloatFieldModule',
    string = 'ChecklistStringFieldModule',
    boolean = 'ChecklistBoolFieldModule',
    integer = 'ChecklistIntegerFieldModule',
    dropdown = 'ChecklistDropdownFieldModule',
}

@Component({
    name: 'ChecklistFieldModule',
    components: {
        ChecklistBoolFieldModule,
        ChecklistDropdownFieldModule,
        ChecklistFloatFieldModule,
        ChecklistIntegerFieldModule,
        ChecklistStringFieldModule,
        RichTextEditor,
    },
})
export default class ChecklistFieldModule extends Vue {
    @Prop({ required: true }) private checklistField!: ChecklistField;
    @Prop({ required: true }) private activeProductFormId!: number | null;
    @Prop({ default: false }) private isAlternativeStyle!: boolean;
    @Prop({ default: false }) private isFirst!: boolean;
    @Prop({ default: false }) private itemRowIndex!: number;
    @Prop({ default: false }) private isLast!: boolean;
    @Prop({ default: 0 }) private level!: number;
    @Prop({ default: false }) private isUpdating!: boolean;
    @Prop({ default: false }) private isVisibleInMultiposition!: boolean;
    @Prop({ default: false }) private isDisabled!: boolean | undefined;
    @Prop({ default: [] }) private pIdFieldErrors!: string[];
    @Prop({ default: null }) private addNewRow!: () => void | null;
    @Prop({ default: null }) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Prop({ default: false }) private isConfigurator!: boolean;

    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({
        pId,
        value,
        productFormId,
    }: {
        pId: string;
        value: ConfiguratorValue;
        productFormId: number;
    }) => void;

    private isFieldVisible = true;
    private isModalVisible = false;
    private selectedChecklist = null;
    private showFullScreenImage = false;
    private hasError = false;

    private get determineIsFieldVisible() {
        if (this.isAlternativeStyle) {
            return this.isVisibleInMultiposition;
        }

        return this.isFieldVisible;
    }

    private get labelFontClass() {
        return this.isAlternativeStyle ? 'u-b4' : 'u-b2-';
    }

    private get labelClasses() {
        let classes = '';
        if (this.descriptionExists()) {
            classes = 'is-clickable';
        }

        classes += ` ${this.labelFontClass}`;

        return classes;
    }

    private get className() {
        const classes = ['c-checklist__row', `c-checklist__row--${this.checklistField.fieldType}`];
        if (this.isAlternativeStyle) {
            classes.push('is-alternative');
        }
        if (this.isFirst) {
            classes.push('is-first');
        }

        if (this.isUpdating) {
            classes.push('is-multiposition');
        }

        return classes.join(' ');
    }

    private toggleImageFullScreen() {
        this.showFullScreenImage = !this.showFullScreenImage;
    }

    private checkVisibilityLevel(fieldLevel: number) {
        return fieldLevel <= this.level;
    }
    private descriptionExists() {
        return this.checklistField.description !== null;
    }

    private get activeFieldModule() {
        return fieldType[this.checklistField.fieldType as keyof typeof fieldType];
    }

    private toggleInfoDetailsModal() {
        if (this.descriptionExists()) {
            this.isModalVisible = !this.isModalVisible;
            this.showFullScreenImage = false;
            if (this.isModalVisible && is.safari()) {
                this.$nextTick(() => {
                    const imageWrapperElement = this.$refs.imageWrapper as HTMLElement;
                    // @ts-ignore
                    const text = this.$refs.text.$el as HTMLElement;
                    const modalInner = document.querySelector('.ant-modal-body') as HTMLElement;
                    const textHeight = text.getBoundingClientRect().height;
                    const modalInnerHeight = modalInner.getBoundingClientRect().height;

                    imageWrapperElement.style.height = modalInnerHeight - textHeight - 110 + 'px';
                });
            }
        }
    }

    private async triggerVisibilityFunctions({
        activeProductFormValues,
    }: {
        activeProductFormValues: ActiveProductFormValueObject;
    }) {
        this.$notification.destroy();
        if (!this.checklistFieldHasVisibilityFunction()) {
            return;
        }
        this.isFieldVisible = determineFieldVisibility(
            this.checklistField.checklistVisibleFunction,
            activeProductFormValues
        );
    }

    private checklistFieldHasVisibilityFunction() {
        return this.checklistField.checklistVisibleFunction != null;
    }

    private onUpdateStoreFieldValue({
        pId,
        value,
        productFormId,
    }: {
        pId: string;
        value: ConfiguratorValue;
        productFormId: number;
    }) {
        if (this.activeProductFormId == null) {
            return;
        }
        this.updateActiveProductFormValue({
            pId,
            value,
            productFormId: this.activeProductFormId,
        });
    }

    private isDropdownElementVisible() {
        const element = document.querySelector(
            `.ant-select-dropdown--single.c-dropdown-${this.checklistField.pId}-${this.itemRowIndex}`
        ) as HTMLElement;

        if (element == null) {
            return false;
        }

        return element.style.display !== 'none';
    }

    private focusNextRowElement(nextRowElement: HTMLElement) {
        if (nextRowElement == null) {
            return;
        }
        const elementToBeFocused = nextRowElement.querySelector('input');

        if (
            this.isDropdown(nextRowElement) ||
            nextRowElement instanceof HTMLInputElement ||
            elementToBeFocused == null
        ) {
            nextRowElement.focus();
        } else {
            elementToBeFocused.focus();
        }
    }

    private isDropdown(element: Element | null): boolean {
        if (element == null) {
            return false;
        }

        return element.classList.contains('c-dropdown');
    }

    private async onPressEnter(event: Event) {
        if (this.isDropdown(event.target as Element) && this.isDropdownElementVisible()) {
            return;
        }

        if (this.isLast) {
            if (this.addNewRow == null) {
                return;
            }
            await this.addNewRow();
        }
        let pid: string = this.checklistField.pId;
        if (pid === GlobalOptions.HEIGHT_PID) {
            pid = GlobalOptions.QUANTITY_PID;
        }
        const nextRowElement = document.querySelector(
            `.c-checklist__row-value[pid="${pid}"][itemrowindex="${this.itemRowIndex + 1}"]`
        );
        this.$nextTick(() => {
            this.focusNextRowElement(nextRowElement as HTMLElement);
        });
    }
    private async onPressDown(event: Event) {
        if (this.isLast) {
            return;
        }
        const nextRowElement = document.querySelector(
            `.c-checklist__row-value[pid="${this.checklistField.pId}"][itemrowindex="${this.itemRowIndex + 1}"]`
        );
        this.$nextTick(() => {
            this.focusNextRowElement(nextRowElement as HTMLElement);
        });
    }
    private async onPressUp(event: Event) {
        if (this.isFirst) {
            return;
        }
        const nextRowElement = document.querySelector(
            `.c-checklist__row-value[pid="${this.checklistField.pId}"][itemrowindex="${this.itemRowIndex - 1}"]`
        );
        this.$nextTick(() => {
            this.focusNextRowElement(nextRowElement as HTMLElement);
        });
    }

    private setFieldErrorState(data: { rowKey: number; pid: string; errorInField: boolean }) {
        const rowIndexUnavailable = !this.itemRowIndex && this.itemRowIndex !== 0;
        const isCorrectField =
            (rowIndexUnavailable || data.rowKey === this.itemRowIndex) && data.pid === this.checklistField.pId;

        if (!isCorrectField) {
            return;
        }

        if (data.errorInField) {
            this.hasError = true;
        } else {
            this.hasError = false;
        }
    }

    private created() {
        EventBus.$on(EventBusEvents.triggerVisibilityFunctions, this.triggerVisibilityFunctions);
        EventBus.$on(EventBusEvents.setProductFieldErrorState, this.setFieldErrorState);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.triggerVisibilityFunctions, this.triggerVisibilityFunctions);
        EventBus.$off(EventBusEvents.setProductFieldErrorState, this.setFieldErrorState);
    }
}
