import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';

const mutations: MutationTree<ILocalState> = {
    setAreOffersLoading(state, payload) {
        state.areOffersLoading = payload;
    },
    setIsProjectLoading(state, payload) {
        state.isProjectLoading = payload;
    },
    setIsLeadLoading(state, payload) {
        state.isLeadLoading = payload;
    },
    setAreNextStepsLoading(state, payload) {
        state.areNextStepsLoading = payload;
    },
    setAllLoadingState(state, payload) {
        state.areOffersLoading = payload;
        state.isProjectLoading = payload;
        state.isLeadLoading = payload;
        state.areNextStepsLoading = payload;
    },
};

export default mutations;
