
import { Component, Model, Watch, Ref, Vue, Prop } from 'vue-property-decorator';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

@Component({
    name: 'PhoneNumberInput',
    components: {
        VuePhoneNumberInput,
    },
})
export default class PhoneNumberInput extends Vue {
    @Model('change', { type: String, default: '' }) private value!: string;
    @Prop({ type: String, default: '' }) private selectedCountry!: string;

    @Ref('phoneInput') private readonly phoneInput!: any;

    private input: string = '';

    private handlePhoneChange(val: string | number) {
        this.$emit('change', '' + val);
        this.$emit('formatted', this.phoneInput.results.formattedNumber);
    }

    @Watch('value', {
        immediate: true,
    })
    private handleValueChange(newVal: string, oldVal: string) {
        if (newVal !== oldVal) {
            this.input = this.value;
        }
    }
}
