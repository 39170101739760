import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class TaxDetailsAPI {
    public static async getAll() {
        let taxDetails;

        try {
            taxDetails = await ApiController.get(ApiRoutes.taxDetails.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(taxDetails);
    }

    public static async getById(taxDetailsId: string) {
        let taxDetails;

        try {
            taxDetails = await ApiController.get(ApiRoutes.taxDetails.getById(taxDetailsId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(taxDetails);
    }

    public static async update(taxDetailsId: string, taxDetailsInfo: AvailableLabelFields) {
        let taxDetails;

        const request = {
            data: {
                type: 'tax_details',
                id: taxDetailsId,
                attributes: {
                    amount: taxDetailsInfo.amount,
                    description: taxDetailsInfo.description,
                },
            },
        };

        try {
            taxDetails =
                await ApiController.patch(
                    ApiRoutes.taxDetails.update(taxDetailsId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(taxDetails);
    }

    public static async create(taxDetailsInfo: AvailableLabelFields) {
        let taxDetails;
        const request = {
            data: {
                type: 'tax_details',
                attributes: {
                    amount: taxDetailsInfo.amount,
                    description: taxDetailsInfo.description,
                },
            },
        };
        try {
            taxDetails =
                await ApiController.post(
                    ApiRoutes.taxDetails.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(taxDetails);
    }

    public static async delete(taxDetailsId: string) {

        try {
            await ApiController.delete(ApiRoutes.taxDetails.delete(taxDetailsId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
