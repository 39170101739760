import { Model } from '@vuex-orm/core';

export default class SystemWorkflowNode extends Model {
    public static entity = 'systemWorkflowNode';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(''),
            type: this.string(''),
            x: this.number(0),
            y: this.number(0),
        };
    }

    public id!: string;
    public name!: string;
    public type!: string;
    public x!: number;
    public y!: number;
}
