import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { ApiController } from '@/services/ApiController';
import { AxiosResponse } from 'axios';
import { AvailableChecklistFields } from '@/interfaces/components/cms/checklistFields/AvailableChecklistFields';
import { TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';

export default class ChecklistFieldAPI {
    public static async getAll() {
        let checklistFields;
        try {
            checklistFields = await ApiController.get(ApiRoutes.cms.checklistFields.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(checklistFields);
    }

    public static async getById(checklistFieldId: string) {
        let checklistField;

        try {
            checklistField =
                await ApiController.get(ApiRoutes.cms.checklistFields.single(checklistFieldId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(checklistField);
    }

    public static async update(checklistFieldId: string, checklistFieldInfo: AvailableChecklistFields) {
        let checklistField;
        const request = {
            data: {
                type: 'checklist_fields',
                id: checklistFieldId,
                attributes: {
                    description: checklistFieldInfo.description,
                },
                relationships: {},
            },
        };

        const relationships: TJsonApiRelationships = {
            image: {
                // @ts-ignore
                data: null,
            },
        };

        if (checklistFieldInfo.image.length > 0) {
            relationships.image = {
                data: {
                    type: 'images',
                    id: checklistFieldInfo.image[0].uid,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            checklistField =
                await ApiController.patch(
                    ApiRoutes.cms.checklistFields.update(checklistFieldId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(checklistField);
    }
}
