import { Model } from '@vuex-orm/core';
import { AxiosError, AxiosResponse } from 'axios';
import TokenAPI from '@/api/TokenAPI';

export default class Token extends Model {
    public static entity: string = 'tokens';

    public static fields() {
        return {
            id: this.attr(null),
            access_token: this.attr(''),
            token_type: this.attr(''),
            expires_at: this.attr(''),
        };
    }

    public static async refreshToken(): Promise<AxiosResponse | AxiosError> {
        return await TokenAPI.refreshToken();
    }

    public static setToken(token: any) {
        this.create({
            data: token,
        });
    }
}
