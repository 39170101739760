import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class CustomerHistoryAPI {
    public static async getAll() {
        let customerHistory;

        try {
            customerHistory = (await ApiController.get(ApiRoutes.customerHistory.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(customerHistory);
    }
}
