import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class PriceTypeAPI {
    public static async getAll() {
        let priceTypes;
        try {
            priceTypes = await ApiController.get(ApiRoutes.priceType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return priceTypes;
    }
}
