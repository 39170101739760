import Project from '@/models/Project';
import { TabKeys } from '@/enums/components/CmsIndex/TabKeys';
import { ChatProjectsSearchParameters } from '@/interfaces/components/chat/ChatProjectsSearchParameters';
import Offer from '@/models/Offer';
import { sortDates, sortStrings } from '@/helpers/SortingHelper';
import { TabItemInterface } from '@/interfaces/components/chat/TabItemInterface';
import { ProjectShortInfo } from '@/interfaces/components/chat/ProjectShortInfo';
import NextStep from '@/models/NextStep';
import moment from 'moment';
import User from '@/models/User';
import ChatMessage from '@/models/ChatMessage';
import i18n from '@/i18n';
import { Modal, notification } from 'ant-design-vue';
import { ChatActionConfiguration } from '@/interfaces/components/chat/ChatActionConfiguration';
import Chat from '@/models/Chat';
import PlainMessage from '@/models/PlainMessage';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ChatMessageSaveOptions } from '@/interfaces/components/chat/ChatMessageSaveOptions';
import MessageRepository from '@/repositories/MessageRepository';
import Attachment from '@/models/Attachment';
import SingleNextStepForm from '@/components/global/SingleNextStepForm.vue';
import { stripHTMLTagsFromString } from '@/helpers/TextHelper';

export async function fetchChatProjects(chatProjectSearchParameters: ChatProjectsSearchParameters) {
    try {
        await Project.getChatProjects(generateQueryString(chatProjectSearchParameters));
    } catch (error) {
        throw error;
    }

    return Promise.resolve();
}

function generateQueryString(chatProjectsSearchParameters: ChatProjectsSearchParameters) {
    let queryString =
        chatProjectsSearchParameters.userId != null && chatProjectsSearchParameters.activeTabKey === TabKeys.MyProjects
            ? `(assignedUser.id:${chatProjectsSearchParameters.userId}|madeByUser.id:${chatProjectsSearchParameters.userId}|chat.messages.content.users.id:${chatProjectsSearchParameters.userId})`
            : '';
    const searchableFilters = ['connection', 'client.name', 'orderNumber', 'offers.name'];
    const urlParams = chatProjectsSearchParameters.activeTabKey === TabKeys.MyProjects ? '&onlyMy=1' : '';

    if (chatProjectsSearchParameters.searchOptions.query !== '') {
        const searchableFilterQuery = searchableFilters
            .map((filter) => {
                return `${filter}:${encodeURI(`%${chatProjectsSearchParameters.searchOptions.query}%`)}`;
            })
            .join('|');

        if (queryString.length > 0) {
            queryString = `${queryString}^(${searchableFilterQuery})`;
        } else {
            queryString = searchableFilterQuery;
        }
    }

    if (queryString.length > 0) {
        queryString = `&q=${queryString}`;
    }

    return `${queryString}&page[size]=${chatProjectsSearchParameters.searchOptions.size}&page[number]=${chatProjectsSearchParameters.searchOptions.page}${urlParams}`;
}

export function convertProjectsToProjectInfo(projects: Project | Project[]) {
    if (Array.isArray(projects)) {
        return projects.map((project) => {
            return {
                projectInfo: convertProjectToProjectInfo(project),
            };
        }) as TabItemInterface[];
    }

    return convertProjectToProjectInfo(projects) as ProjectShortInfo;
}

function convertProjectToProjectInfo(project: Project) {
    const latestOffer = getLatestOfferFromProject(project);
    const latestOfferName = latestOffer != null && latestOffer.name !== '-' ? latestOffer.name : null;
    const offerNameOrOrderNumber = project.projectState === 'order' ? project.orderNumber || '-' : latestOfferName;

    return generateProjectInfo(project, offerNameOrOrderNumber);
}

function getLatestOfferFromProject(project: Project) {
    return project.projectOfferRevisions.sort((a: Offer, b: Offer) => sortStrings(a.createdAt, b.createdAt, false))[0];
}

function generateProjectInfo(project: Project, offerNameOrOrderNumber: string | null) {
    const messages = project.chat && project.chat.messages;

    return {
        projectId: project.projectId,
        clientName: project.projectClient ? project.projectClient.clientName : i18n.t('KLIJENT NIJE DODIJELJEN'),
        offerId: offerNameOrOrderNumber,
        connection: project.connection,
        latestMessage: getLatestMessageString(messages),
        createdAt: getLatestMessageDate(messages),
        createdBy: getLatestMessageUser(messages),
    };
}

/** Extract the latest message date
 * @param messages - All of the messages in a chat
 * @return The date string of the latest message
 */

export function getLatestMessageDate(messages: null | ChatMessage[]) {
    if (messages == null || messages.length <= 0) {
        return '';
    }

    const sortedMessages = sortMessages(messages);

    return sortedMessages[0].createdAt;
}

/** Extract the latest message user name
 * @param messages - All of the messages in a chat
 * @return The date string of the latest user
 */

export function getLatestMessageUser(messages: null | ChatMessage[]) {
    if (messages == null || messages.length <= 0) {
        return '';
    }

    const sortedMessages = sortMessages(messages);

    return sortedMessages[0].madeByUser.name;
}

/**
 * Extracts the latest message and converts its content or next step note as a string
 * @param messages - All of the messages in a chat
 * @return The string of the next step note or content if there is a latest message, otherwise an empty string
 */
export function getLatestMessageString(messages: null | ChatMessage[]) {
    if (messages == null || messages.length <= 0) {
        return '';
    }

    const sortedMessages = sortMessages(messages);
    const latestMessage = sortedMessages[0];

    return constructMessageString(latestMessage);
}

// *Sort messages by date
export function sortMessages(messages: ChatMessage[]) {
    return messages.slice().sort((messageA, messageB) => sortDates(messageA.createdAt, messageB.createdAt, false));
}

/**
 * Constructs the message from the next step note or type name if next step exists, otherwise from the content text
 * It will also strip all HTML tags
 * @param message - The message that needs to be converted to string
 * @return The string of the next step note/type name or content text
 */
export function constructMessageString(message: ChatMessage) {
    if (message.nextStep != null) {
        let textToBeRendered =
            stripHTMLTagsFromString(message.nextStep.note) !== '' ? message.nextStep.note : message.nextStep.name;

        if (message.nextStep.type != null) {
            textToBeRendered = message.nextStep.type.name;
        }

        return stripHTMLTagsFromString(`${i18n.t('Aktivnost')}: ${textToBeRendered}`);
    }

    return stripHTMLTagsFromString(`${message.content.text}`);
}

export function getProjectsConnectionAndOfferIdString(offerId: string | null, connection: string | null) {
    const filteredBottomRowData = [connection, offerId].filter((projectData) => projectData != null);
    return filteredBottomRowData.join(' • ');
}

export function generateMessageHeaderString(message: ChatMessage, currentUser: User, type: string) {
    const isCurrentUserSameAsPoster = message.madeByUser.id === currentUser.id;
    const userName = type === 'activity' ? (message.nextStep as NextStep).madeByUser.name : message.madeByUser.name;
    const nameString = `${userName}${isCurrentUserSameAsPoster ? ` (${i18n.t('ja')})` : ''}`;

    const dateString = moment(
        type === 'activity' ? (message.nextStep as NextStep).createdAt : message.createdAt
    ).format('DD.MM.YYYY HH:mm');

    return `${nameString} • ${dateString}`;
}

export function getActionsForType(chatActionConfiguration: ChatActionConfiguration) {
    if (chatActionConfiguration.type === 'activity') {
        return [
            {
                label: i18n.t('Obriši'),
                color: 'red',
                callback: () => {
                    const onDeleteNextStep = async () => {
                        try {
                            if (chatActionConfiguration.plainMessageId == null) {
                                await ChatMessage.deleteExisting(chatActionConfiguration.messageId as string);
                            } else {
                                await ChatMessage.removeNextStep(chatActionConfiguration.messageId as string);
                                await NextStep.deleteExisting(chatActionConfiguration.nextStepId as string);
                            }

                            if (chatActionConfiguration.nextStepId) {
                                NextStep.delete(chatActionConfiguration.nextStepId);
                            }
                            // await Project.getById(chatActionConfiguration.projectId);
                        } catch (error) {
                            notification.error({
                                message: i18n.t('Dogodila se greška') as string,
                                description: (error as Error).message,
                            });
                            return;
                        }

                        EventBus.$emit(EventBusEvents.updateProject, chatActionConfiguration.projectId);

                        notification.success({
                            message: i18n.t('Aktivnost je uspješno obrisana') as string,
                            description: ``,
                        });
                    };

                    Modal.confirm({
                        title: i18n.t('Jeste li sigurni da želite obrisati ovu aktivnost?') as string,
                        content: '',
                        onOk: onDeleteNextStep,
                    });
                },
                isDisabled: false,
                isVisible: chatActionConfiguration.isMadeByUserSameAsCurrentUser,
            },
        ];
    }

    return [
        {
            label: i18n.t('Obriši'),
            color: 'red',
            callback: () => {
                const onDeleteChatMessage = async () => {
                    try {
                        await ChatMessage.deleteExisting(chatActionConfiguration.messageId as string);
                        if (chatActionConfiguration.nextStepId) {
                            NextStep.delete(chatActionConfiguration.nextStepId);
                        }
                    } catch (error) {
                        notification.error({
                            message: i18n.t('Dogodila se greška') as string,
                            description: (error as Error).message,
                        });
                        return;
                    }

                    EventBus.$emit(EventBusEvents.updateProject, chatActionConfiguration.projectId);

                    notification.success({
                        message: i18n.t('Poruka je uspješno obrisana') as string,
                        description: ``,
                    });
                };

                Modal.confirm({
                    title: i18n.t('Jeste li sigurni da želite obrisati ovu poruku?') as string,
                    content: '',
                    onOk: onDeleteChatMessage,
                });
            },
            isDisabled: false,
            isVisible: chatActionConfiguration.isMadeByUserSameAsCurrentUser,
        },
        {
            label: i18n.t('Stvori aktivnost'),
            callback: () => {
                const message = MessageRepository.getById(chatActionConfiguration.messageId);
                if (message == null) {
                    return;
                }
                const regexResult = message.content.text.match(/data-mention-id="(\d+)"/);
                let selectedUserId = null;

                if (regexResult != null) {
                    selectedUserId = regexResult[1];
                }
                EventBus.$emit(EventBusEvents.showCreateActivityForm, {
                    content: message.content.text,
                    messageId: chatActionConfiguration.messageId,
                    selectedUserId,
                });
            },
            isVisible: true,
            isDisabled: chatActionConfiguration.nextStepId != null,
        },
    ];
}

export async function saveChatMessage(chatMessageSaveOptions: ChatMessageSaveOptions) {
    let createdChatId: string | null = null;
    let chatMessageResponseId: string | null = null;

    try {
        if (chatMessageSaveOptions.chatId == null) {
            createdChatId = await createNewChat(chatMessageSaveOptions.selectedProjectId);
        }
        if (chatMessageSaveOptions.chatMessageId == null) {
            chatMessageResponseId = await createChatMessage(createdChatId || chatMessageSaveOptions.chatId);
            await createPlainMessage(
                chatMessageResponseId || chatMessageSaveOptions.chatMessageId,
                chatMessageSaveOptions.content,
                chatMessageSaveOptions.attachments
            );
        }
        if (chatMessageSaveOptions.shouldCreateNextStep) {
            await createNextStep(
                chatMessageSaveOptions.singleNextStepFormReference,
                chatMessageSaveOptions.chatMessageId != null
                    ? chatMessageSaveOptions.chatMessageId
                    : (chatMessageResponseId as string)
            );
        }
        if (chatMessageSaveOptions.chatId == null && createdChatId == null) {
            return Promise.reject(new Error('Chat id does not exist'));
        }
        await Chat.getById((chatMessageSaveOptions.chatId || createdChatId) as string);
        EventBus.$emit(EventBusEvents.updateProject, chatMessageSaveOptions.selectedProjectId);
    } catch (e) {
        throw e;
    }
    EventBus.$emit(EventBusEvents.clearContent);

    return Promise.resolve();
}

export async function createNewChat(selectedProjectId: string) {
    let chatResponse;
    try {
        chatResponse = await Chat.createNew({
            projectId: selectedProjectId,
        });
    } catch (e) {
        throw e;
    }

    return Promise.resolve(chatResponse.data.id);
}

export async function createChatMessage(chatId: string | null) {
    if (chatId == null) {
        return Promise.reject(new Error('Chat ID is not defined'));
    }
    let chatMessageResponse;
    try {
        chatMessageResponse = await ChatMessage.createNew({
            chatId,
        });
    } catch (e) {
        throw e;
    }

    return Promise.resolve(chatMessageResponse.data.id);
}

async function createPlainMessage(
    chatMessageId: string | undefined | null,
    content: string,
    attachments: Attachment[]
) {
    if (chatMessageId == null) {
        return Promise.reject(new Error('Chat message id is not defined'));
    }
    try {
        await PlainMessage.createNew({
            chatMessageId,
            text: content,
            attachments,
        });
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

async function createNextStep(
    // @ts-ignore
    singleNextStepFormReference: SingleNextStepForm,
    chatMessageId: string | null | undefined
) {
    if (chatMessageId == null) {
        return Promise.reject(new Error('Chat message id is not defined'));
    }
    try {
        // @ts-ignore
        await singleNextStepFormReference.onSaveNextStep(false, chatMessageId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}

/**
 * Creates a new chat (if necessary) and a new chat messaged based on the shouldCreateChatMessage argument
 * @param shouldCreateChatMessage - Determines whether the chat/chat message should be created
 * @param projectId - The id of the project where the chat should be created
 * @param chatId - The id of the chat (if it exists)
 * @param nextStepObjectId - The id of the next step if it exists (in case of editing)
 * @return - The id of the newly created chat message
 */
export async function createNewChatMessageAndChat(
    shouldCreateChatMessage: boolean,
    projectId: string | null,
    chatId: string | null,
    nextStepObjectId: string | null | undefined
) {
    let createdChatId: string | null = null;
    let chatMessageResponseId: string | null = null;

    if (shouldCreateChatMessage && projectId) {
        if (chatId == null) {
            createdChatId = await createNewChat(projectId);
        }
        if (!nextStepObjectId) {
            chatMessageResponseId = await createChatMessage(createdChatId || chatId);
        }
    }

    return Promise.resolve(chatMessageResponseId);
}
