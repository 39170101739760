import { Model } from '@vuex-orm/core';
import TranslationalObjectAPI from '@/api/TranslationalObjectAPI';
import { TranslationRequest } from '@/interfaces/components/translations/TranslationRequest';
import { SearchOptions } from '@/interfaces/components/translations/SearchOptions';

export default class TranslationalObject extends Model {
    public static entity = 'translationalObject';

    public get getTranslatableProperties() {
        // @ts-ignore
        return this.translatableProperties;
    }

    public get getName() {
        // @ts-ignore
        return this.name;
    }

    public get getLocale() {
        // @ts-ignore
        return this.locale;
    }

    public static fields() {
        return {
            id: this.attr(''),
            entityName: this.string(''),
            locale: this.string(''),
            attribute: this.string(''),
            translatableProperties: this.attr([]),
        };
    }

    public static async getByRoute(searchOptions: SearchOptions) {
        let translationalObjects;
        try {
            translationalObjects = await TranslationalObjectAPI.getByRoute(searchOptions);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: translationalObjects.data,
        });
        return Promise.resolve();
    }

    public static async updateExisting(
        {
            translatableProperties,
            entityName,
            locale,
            attribute,
            baseLocale,
        }: TranslationRequest) {
        try {
            await TranslationalObjectAPI.updateExisting({
                translatableProperties,
                entityName,
                locale,
                attribute,
                baseLocale,
            });
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
