
import { filterAndMapImagesWithPosition } from '@/helpers/CmsIndexHelper';
import { OrderPdfPropertiesRequest } from '@/interfaces/components/cms/OrderPdfProperties/OrderPdfPropertiesRequest';

export function constructOrderPdfPropertiesRequest(
    {
        orderPdfPropertiesId,
        orderConfigurationId,
        orderPdfPropertiesConfig,
    }: OrderPdfPropertiesRequest) {
    const request = {
        data: {
            type: 'order_pdf_properties',
            attributes: {
                companyInfo: orderPdfPropertiesConfig.companyInfo,
                introText: orderPdfPropertiesConfig.introText,
                offerInformation: orderPdfPropertiesConfig.offerInformation,
                paymentAndDelivery: orderPdfPropertiesConfig.paymentAndDelivery,
                richtext: orderPdfPropertiesConfig.richtext,
                imageSegment: orderPdfPropertiesConfig.imageSegment,
                signature: orderPdfPropertiesConfig.signature,
                footerColumn1: orderPdfPropertiesConfig.footerColumn1,
                footerColumn2: orderPdfPropertiesConfig.footerColumn2,
                footerColumn3: orderPdfPropertiesConfig.footerColumn3,
                footerColumn4: orderPdfPropertiesConfig.footerColumn4,
            },
            relationships: {
                orderConfiguration: {
                    data: {
                        type: 'order_configurations',
                        id: orderConfigurationId,
                    },
                },
            },
        },
    };
    if (orderPdfPropertiesId) {
        // @ts-ignore
        request.data.id = orderPdfPropertiesId;
    }

    if (orderPdfPropertiesConfig.locale) {
        // @ts-ignore
        request.data.relationships.locale = {
            data: {
                type: 'locales',
                id: orderPdfPropertiesConfig.locale.id,
            },
        };
    }

    if (orderPdfPropertiesConfig.logo.length > 0) {
        // @ts-ignore
        request.data.relationships.logo = {
            data: {
                type: 'images',
                id: orderPdfPropertiesConfig.logo[0].uid,
            },
        };
    } else {
        // @ts-ignore
        request.data.relationships.logo = {data: null};
    }

    if (orderPdfPropertiesConfig.awardImages) {
        const awardImageArray = filterAndMapImagesWithPosition(
            orderPdfPropertiesConfig.awardImages,
            'award_images');

        // @ts-ignore
        request.data.relationships.awardImages = {
            data: awardImageArray,
        };
    }

    if (orderPdfPropertiesConfig.headerImages) {
        const headerImageArray = filterAndMapImagesWithPosition(
            orderPdfPropertiesConfig.headerImages,
            'header_images');

        // @ts-ignore
        request.data.relationships.headerImages = {
            data: headerImageArray,
        };
    }
    return request;
}
