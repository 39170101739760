import { Model } from '@vuex-orm/core';
import Image from '@/models/Image';
import { AvailableProductSystemDetailsFields } from '@/interfaces/components/productSystemDetails/AvailableProductSystemDetailsFields';
import ProductSystemDetailsConfigurationAPI from '@/api/ProductSystemDetailsConfigurationAPI';

export default class ProductSystemDetailsConfiguration extends Model {

    public static entity = 'productSystemDetailsConfiguration';

    public static fields() {
        return {
            id: this.attr(null),
            title: this.attr(null),
            image_id: this.attr(null),
            image: this.belongsTo(Image, 'image_id'),
        };
    }

    // public static async getAll() {
    //     let productDetails;
    //     try {
    //         productDetails = await ProductDetailsAPI.getAll();
    //     } catch (e) {
    //         return Promise.reject(e);
    //     }
    //     this.create({
    //         data: productDetails.data,
    //         insertOrUpdate: ['image', 'product', 'uploadedPdf'],
    //     });
    //     return Promise.resolve();
    // }
    //
    // public static async getById(productDetailsId: string) {
    //     let productDetails;
    //     try {
    //         productDetails = await ProductDetailsAPI.getById(productDetailsId);
    //     } catch (e) {
    //         return Promise.reject(e);
    //     }
    //     this.insertOrUpdate({
    //         data: productDetails.data,
    //         insertOrUpdate: ['image', 'product', 'uploadedPdf'],
    //     });
    //     return Promise.resolve(productDetails);
    // }

    public static async updateExisting(
        productSystemDetailsConfigurationId: string,
        productSystemDetailsConfigurationInfo: AvailableProductSystemDetailsFields,
        parentEntityId?: string,
        locale?: string | null) {
        let productSystemDetailsConfiguration;

        try {
            productSystemDetailsConfiguration =
                await ProductSystemDetailsConfigurationAPI.updateExisting(
                    productSystemDetailsConfigurationId,
                    productSystemDetailsConfigurationInfo,
                    locale);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productSystemDetailsConfiguration.data,
            insertOrUpdate: ['image'],
        });
        return Promise.resolve(productSystemDetailsConfiguration);
    }

    public static async createNew(
        productSystemDetailsConfigurationInfo: AvailableProductSystemDetailsFields,
        parentEntityId?: string,
        locale?: string | null) {
        let productSystemDetailsConfiguration;

        try {
            productSystemDetailsConfiguration =
                await ProductSystemDetailsConfigurationAPI.createNew(
                    productSystemDetailsConfigurationInfo,
                    locale);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: productSystemDetailsConfiguration.data,
            insertOrUpdate: ['image'],
        });
        return Promise.resolve(productSystemDetailsConfiguration);
    }

    public id!: string;
    public title!: string;
    public image!: Image | null;
}
