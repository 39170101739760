import { Model } from '@vuex-orm/core';
import OfferConfigurationConfig from '@/models/interfaces/OfferConfiguration';
import OfferConfigurationAPI from '@/api/OfferConfigurationAPI';
import OfferPdfProperties from '@/models/OfferPdfProperties';
import BankAccount from '@/models/BankAccount';
import Currency from '@/models/Currency';
import { OfferPriceType } from '@/enums/global/OfferPriceType';

export default class OfferConfiguration extends Model {

    public static entity = 'offerConfiguration';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            isArchived: this.boolean(false),
            showName: this.boolean(false),
            showProductDetails: this.boolean(false),
            showPricelists: this.boolean(false),
            showImages: this.boolean(false),
            showDifferences: this.boolean(false),
            showProductImageOnRight: this.boolean(false),
            showImageOnce: this.boolean(false),
            showUpn: this.boolean(false),
            showDiscounts: this.string(''),
            showPrice: this.string(''),
            offerPdfProperties_id: this.attr(null),
            offerPdfProperties: this.belongsTo(OfferPdfProperties, 'offerPdfProperties_id'),
            bankAccount: this.belongsTo(BankAccount, 'bankAccount_id'),
            bankAccount_id: this.attr(null),
            currency: this.belongsTo(Currency, 'currency_id'),
            currency_id: this.attr(null),
            showPurchasePrice: this.boolean(false),
            showRecommendedPrice: this.boolean(false),
        };
    }

    public static async getAll(getOnlyUnarchived = false) {
        let offerConfigurations;
        try {
            offerConfigurations = await OfferConfigurationAPI.getAll(getOnlyUnarchived);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: offerConfigurations.data,
            insertOrUpdate: ['currency'],
        });
        return Promise.resolve();
    }

    public static async getById(offerConfigurationId: string) {
        let offerConfiguration;
        try {
            offerConfiguration = await OfferConfigurationAPI.getById(offerConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: offerConfiguration.data,
        });
        return Promise.resolve(offerConfiguration);
    }

    public static async updateExisting(offerConfigurationId: string, offerConfigurationInfo: OfferConfigurationConfig) {
        let offerConfiguration;
        try {
            offerConfiguration = await OfferConfigurationAPI.update(offerConfigurationId, offerConfigurationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(offerConfiguration);
    }

    public static async createNew(offerConfigurationInfo: OfferConfigurationConfig) {
        let offerConfiguration;
        try {
            offerConfiguration = await OfferConfigurationAPI.create(offerConfigurationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(offerConfiguration);
    }

    public static async duplicateExisting(offerConfigurationId: string) {
        try {
            await OfferConfigurationAPI.duplicate(offerConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }

    public static async deleteExisting(offerConfigurationId: string) {
        try {
            await OfferConfigurationAPI.delete(offerConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OfferConfiguration.delete(offerConfigurationId);
        return Promise.resolve();
    }

    public static async archive(offerConfigurationId: string) {
        let offerConfiguration;
        try {
            offerConfiguration = await OfferConfigurationAPI.archive(offerConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(offerConfiguration);
    }

    public static determineDefaultPrintPrice(showPurchasePrice: boolean, showRecommendedPrice: boolean) {
        if (showPurchasePrice) {
            return OfferPriceType.PURCHASE_PRICE;
        }

        if (showRecommendedPrice) {
            return OfferPriceType.RECOMMENDED_PRICE;
        }

        return OfferPriceType.SELLING_PRICE;
    }
    public id!: string;
    public name!: string;
    public showName!: boolean;
    public showProductDetails!: boolean;
    public showPricelists!: boolean;
    public showImages!: boolean;
    public showDifferences!: boolean;
    public showProductImageOnRight!: boolean;
    public showImageOnce!: boolean;
    public isArchived!: boolean;
    public showUpn!: boolean;
    public showDiscounts!: string;
    public showPrice!: string;
    public currency!: Currency;
    public showPurchasePrice!: boolean;
    public showRecommendedPrice!: boolean;
    public printPrice = OfferConfiguration.determineDefaultPrintPrice(
        this.showPurchasePrice,
        this.showRecommendedPrice,
    );
}
