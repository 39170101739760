import { Model } from '@vuex-orm/core';
import ReportTypesAPI from '@/api/ReportTypesAPI';
import { AxiosError, AxiosResponse } from 'axios';

export default class ReportTypes extends Model {
    public static entity = 'reportType';

    public static fields() {
        return {
            id: this.string(null),
            key: this.string(null),
            label: this.string(null),
        };
    }

    public static async getAll(): Promise<any> {
        let reportTypes;

        try {
            reportTypes = await ReportTypesAPI.getTypes();
        } catch (e) {
            return Promise.reject(e);
        }

        await this.create({
            data: reportTypes.data,
        });

        return Promise.resolve(reportTypes);
    }

    public id!: string;
    public key!: string;
    public label!: string;
}
