import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class LabelAPI {
    public static async getAll() {
        let locales;

        try {
            locales = await ApiController.get(ApiRoutes.locale.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(locales);
    }
}
