
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import PriceManipulationTypeRepository from '@/repositories/PriceManipulationTypeRepository';
import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';
import OfferItemPriceManipulationRepository from '@/repositories/OfferItemPriceManipulationRepository';

@Component({
    name: 'OfferItemPriceManipulationForm',
})
export default class OfferItemPriceManipulationForm extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private isLoading = false;

    private get form() {
        return this.$form.createForm(this, { name: 'offerItemPriceManipulation' } as IformCreateOption);
    }

    private get priceId() {
        return this.popupData.offerItemPriceManipulationId;
    }

    private get offerItemPriceManipulationItem() {
        if (this.priceId) {
            return OfferItemPriceManipulationRepository.getById(this.priceId);
        }

        return null;
    }

    private get priceManipulationTypes() {
        return PriceManipulationTypeRepository.getAll();
    }

    private get initialPriceManipulationType() {
        return PriceManipulationTypeRepository.getById('1');
    }
    private get userId() {
        return this.$store.getters['jwtData/userId'];
    }

    private get initialPriceType() {
        return this.offerItemPriceManipulationItem
            ? this.offerItemPriceManipulationItem.priceManipulationType?.id
            : this.initialPriceManipulationType?.id;
    }

    private handleSubmit(event: Event) {
        event.preventDefault();

        // @ts-ignore
        this.form.validateFieldsAndScroll(async (err: Error, values: any) => {
            if (err) {
                return Promise.reject();
            }
            this.isLoading = true;

            values['offerItemPriceType'] = this.isRoltek ? '2' : '3';
            values['updatedBy'] = this.userId;

            try {
                if (this.priceId) {
                    await OfferItemPriceManipulation.updateExisting(this.priceId, values);
                    this.$notification.success({
                        message: this.$t('Promjena cijene je uspješno ažurirana!') as string,
                        description: '',
                    });
                } else {
                    await Promise.all(
                        this.popupData.selectedOfferItems.map((selectedOfferItemId: string) => {
                            return OfferItemPriceManipulation.createNew({ ...values, offerItem: selectedOfferItemId });
                        })
                    );

                    this.$notification.success({
                        message: this.$t('Promjena cijene je uspješno stvorena!') as string,
                        description: '',
                    });
                }
            } catch (error) {
                this.$notification.error({
                    message: this.$t('Greška prilikom stvaranja promjene cijene!') as string,
                    description: '',
                });
            } finally {
                EventBus.$emit(EventBusEvents.updateProject, { finalizePriceManipulations: true });
                EventBus.$emit(EventBusEvents.closePopup);
            }

            this.isLoading = false;

            return Promise.resolve();
        });
    }

    private get isRoltek(): boolean {
        const userGroup = String(this.$store.getters['jwtData/currentUserGroup']);

        if (userGroup === '1' || userGroup === '2') {
            return true;
        }

        return false;
    }

    private async mounted() {
        this.isLoading = true;

        if (this.priceId) {
            await OfferItemPriceManipulation.getById(this.priceId);
        }

        this.isLoading = false;
    }
}
