
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ClientRepository from '@/repositories/ClientRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProjectRepository from '@/repositories/ProjectRepository';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';

@Component({
    name: 'DeleteClientPerson',
    components: {
        AutocompleteGroupInput,
    },
})
export default class DeleteClientPerson extends Vue {
    @Prop({ required: true }) private popupData!: any;

    private newContactPersonId: string | null = null;
    private projects: Project[] = [];
    private replaceClientId: string | null = null;
    private replaceContactPersonId: string | null = null;
    private currentClientContactPersons: any[] = [];

    private get clientHasConnectedProjects() {
        return this.projects.length;
    }

    private get isSubmitButtonDisabled() {
        if (this.clientHasConnectedProjects === 0) {
            return false;
        } else {
            if (this.replaceClientId === null) {
                return true;
            }
        }
    }

    private async created() {
        await Project.getByClient(this.popupData.clientId);
        this.projects = ProjectRepository.filterProjectsByClient(this.popupData.clientId);
    }

    private get client() {
        if (this.popupData.clientId == null) {
            return null;
        }

        return ClientRepository.getById(this.popupData.clientId);
    }

    private get numberOfConnectedProjects() {
        if (!this.clientHasConnectedProjects) {
            return 0;
        }
        return this.popupData.client.projects.length;
    }

    private get projectNames() {
        if (!this.clientHasConnectedProjects) {
            return [];
        }

        return this.projects.map((project: Project, index: number) => {
            return `${this.$t('Projekt')} ${index + 1}: ${
                project.connection != null ? project.connection : this.$t('Nema definiranu projekt-vezu')
            }`;
        });
    }

    private get deleteMessage() {
        if (this.numberOfConnectedProjects === 1) {
            return this.$t('Kontakt osoba koju želite obrisati ima dodijeljen jedan projekt');
        }
        return (this.$t('Klijent kojeg želite obrisati ima __N__ dodijeljenih projekata') as string).replace(
            '__N__',
            String(this.clientHasConnectedProjects)
        );
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private onContactPersonSelect(id: string) {
        this.newContactPersonId = id;
    }

    private onClientSelect(e: string) {
        this.replaceClientId = e;

        const client = ClientRepository.getById(this.replaceClientId);

        if (client) {
            this.currentClientContactPersons = client.contactPersons;
        }
    }

    private selectNewContactPersons(e: any) {
        this.replaceContactPersonId = e;
    }

    private onDelete() {
        this.popupData.deleteClient({
            clientId: this.popupData.clientId,
            projects: this.projects,
            newClientId: this.replaceClientId,
            newContactPersonId: this.replaceContactPersonId,
        });
    }
}
