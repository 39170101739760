import { Model } from '@vuex-orm/core';
import NotificationAPI from '@/api/NotificationAPI';

export default class Notification extends Model {
    public static entity = 'notification';

    public static fields() {
        return {
            id: this.string(null),
            type: this.string(''),
            readAt: this.attr(null),
            createdAt: this.string(''),
            message: this.string(''),
            navigation: this.attr(null),
        };
    }

    public static async getAll() {
        let notifications;

        try {
            notifications = await NotificationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: notifications.data,
        });
        return Promise.resolve();
    }

    public static async getUnread() {
        let notification;
        try {
            notification = await NotificationAPI.getUnread();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: notification.data,
        });
        return Promise.resolve();
    }

    public static async getUnreadCount() {
        let unreadCount;
        try {
            unreadCount = await NotificationAPI.getUnreadCount();
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(unreadCount.data);
    }

    public static async updateAllAsRead() {
        let notification;
        try {
            notification = await NotificationAPI.updateAllAsRead();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: notification.data,
        });
        return Promise.resolve(notification);
    }

    public id!: string;
    public type!: string;
    public readAt!: string;
    public createdAt!: string;
    public message!: string;
    public navigation!: any;
}
