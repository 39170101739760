export enum GlobalOptions {
    MAX_VISIBLE_MATERIAL_DROPDOWN_ITEMS = 4,
    FILTERS_VERSION = '15',
    ALL = 'all',
    DEFAULT_PRICELIST_VERSION = '-22',
    HEIGHT_PID = 'p7403',
    FINAL_HEIGHT_PID = 'p740650',
    WIDTH_PID = 'p7402',
    FINAL_WIDTH_PID = 'p740649',
    QUANTITY_PID = 'p7404',
    POSITION_PID = 'p7408',
    PRICELIST_PID = 'p7407',
    SISTEM_PID = 'p7405',
}

export const MANDATORY_SIMPLE_PRODUCT_CATEGORIES = ['INSECT_SCREENS'];
export const COMMON_FIELD_PIDS = [
    GlobalOptions.QUANTITY_PID,
    GlobalOptions.WIDTH_PID,
    GlobalOptions.HEIGHT_PID,
    GlobalOptions.POSITION_PID,
    GlobalOptions.SISTEM_PID,
] as string[];

export const PASTED_FIELD_PIDS = [
    GlobalOptions.QUANTITY_PID,
    GlobalOptions.WIDTH_PID,
    GlobalOptions.HEIGHT_PID,
    GlobalOptions.POSITION_PID,
] as string[];