import { Model } from '@vuex-orm/core';
import EmailTemplatePropertyAPI from '@/api/EmailTemplatePropertyAPI';
import Locale from '@/models/Locale';

export default class EmailTemplateProperty extends Model {
    public static entity = 'emailTemplateProperty';

    public static fields() {
        return {
            id: this.string(null),
            headerRichtext: this.string(''),
            footerRichtext: this.string(''),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
        };
    }

    public static async getAll() {
        let emailTemplateProperties;
        try {
            emailTemplateProperties = await EmailTemplatePropertyAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: emailTemplateProperties.data,
        });
        return Promise.resolve();
    }

    public static async getById(emailTemplatePropertyId: string) {
        let emailTemplateProperty;
        try {
            emailTemplateProperty = await EmailTemplatePropertyAPI.getById(emailTemplatePropertyId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: emailTemplateProperty.data,
        });
        return Promise.resolve(emailTemplateProperty);
    }

    public static async updateExisting(emailTemplatePropertyId: string, emailTemplatePropertyInfo: any) {
        let emailTemplateProperty;
        try {
            emailTemplateProperty =
                await EmailTemplatePropertyAPI.update(emailTemplatePropertyId, emailTemplatePropertyInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: emailTemplateProperty.data,
        });
        return Promise.resolve(emailTemplateProperty);
    }

    public static async createNew(emailTemplatePropertyInfo: any) {
        let emailTemplateProperty;
        try {
            emailTemplateProperty = await EmailTemplatePropertyAPI.create(emailTemplatePropertyInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: emailTemplateProperty.data,
        });
        return Promise.resolve(emailTemplateProperty);
    }

    public static async deleteExisting(emailTemplatePropertyId: string) {
        try {
            await EmailTemplatePropertyAPI.delete(emailTemplatePropertyId);
        } catch (e) {
            return Promise.reject(e);
        }
        await EmailTemplateProperty.delete(emailTemplatePropertyId);
        return Promise.resolve();
    }
    public id!: string;
    public headerRichtext!: string;
    public footerRichtext!: string;
    public locale!: Locale;
}
