import EmailTemplateProperty from '@/models/EmailTemplateProperty';

export default class EmailTemplatePropertyRepository {
    public static getAll() {
        return EmailTemplateProperty.query().withAll().get();
    }

    public static getById(id: string) {
        return EmailTemplateProperty.query().whereId(id).withAll().first();
    }
}
