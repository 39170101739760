import { Model } from '@vuex-orm/core';
import LeadTypeAPI from '@/api/LeadTypeAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class LeadType extends Model {
    public static entity = 'leadType';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
        };
    }

    public static async getAll() {
        let leadTypes;

        if (ss.has('leadTypes')) {
            leadTypes = ss.getAndParse('leadTypes');
        } else {
            try {
                const fetchedTypes = await LeadTypeAPI.getAll();
                leadTypes = fetchedTypes.data;

                ss.stringifyAndSave('leadTypes', leadTypes);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: leadTypes,
        });

        return Promise.resolve();
    }

    public static async getById(labelId: string) {
        let leadType;
        try {
            leadType = await LeadTypeAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: leadType.data,
        });
        return Promise.resolve(leadType);
    }

    public static async updateExisting(leadTypeId: string, leadTypeInfo: any) {
        let leadType;
        try {
            leadType = await LeadTypeAPI.update(leadTypeId, leadTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: leadType.data,
        });
        return Promise.resolve(leadType);
    }

    public static async createNew(leadTypeInfo: any) {
        let leadType;
        try {
            leadType = await LeadTypeAPI.create(leadTypeInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: leadType.data,
        });
        return Promise.resolve(leadType);
    }

    public static async deleteExisting(leadTypeId: string) {
        try {
            await LeadTypeAPI.delete(leadTypeId);
        } catch (e) {
            return Promise.reject(e);
        }
        await LeadType.delete(leadTypeId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
}
