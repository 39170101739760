
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import ConfiguratorService from '@/services/ConfiguratorService';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import OfferItem from '@/models/OfferItem';
import { formatFormData } from '@/helpers/FormDataHelper';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import OfferTitle from '@/models/OfferTitle';
import { generateArraysForUpdatingRowNumbers } from '@/helpers/OfferItemTableHelper';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { checklistFieldsWithErrors } from '@/helpers/OfferHelper';
import { ProductTypes } from '@/enums/global/ProductTypes';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { MultipositionProduct } from '@/interfaces/components/projectNew/MultipositionProduct';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';

@Component({
    name: 'AddOfferItems',
})
export default class AddOfferItems extends Vue {
    @Prop({ required: true }) public activeItemRows!: ProductRowItem[];
    @Prop({ required: true }) public projectId!: string;
    @Prop({ required: true }) public offerId!: string | null;
    @Prop({ default: null }) public clientsPaymentTypeId!: string | null;
    @Prop({ default: () => [] }) public offerItemIdsToBeDeleted!: string[];
    @Prop({ default: () => [] }) public offerItemIdsForUpdatingRowNumbers!: any[];
    @Prop({ required: true }) public isUpdating!: boolean;
    @Prop({ required: false }) public productSlotRows!: () => any[];
    @Prop({ required: true }) private formFieldErrorsExist!: boolean;
    @Prop({ required: false }) private configuration!: { isGlobalButton: boolean };
    @Prop({ required: false }) private productFormId!: string;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    private updatedOfferId = this.offerId;
    private eventFinishedCallback: (() => void) | null = null;
    private eventFinished: boolean = false;

    // In case we need progress bar again
    // private productsCompleted = 0;
    // private progressStatus = 'active';
    // private isLoading = false;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get itemsCount() {
        return this.activeItemRows ? this.activeItemRows.length : 0;
    }

    // private get progressBarPercentage() {
    //     return Math.round((this.productsCompleted / this.itemsCount) * 100);
    // }

    private errorsHaveValidWarranty(checklistErrors: ChecklistField[]) {
        if (!checklistErrors?.length) {
            return true;
        }

        const invalidWarranty = checklistErrors.some((checklist: ChecklistField) => {
            if (checklist.checklistStringField) {
                return !checklist.checklistStringField.validWarranty;
            }
        });

        return !invalidWarranty;
    }

    private async updateOrAddItemsToOffer() {
        this.loadingOverlay.start();
        this.eventFinishedCallback = null;
        this.$emit(EventBusEvents.validateFormFields, this.activeItemRows);

        await new Promise<void>((resolve) => {
            this.eventFinishedCallback = resolve;

            if (this.eventFinished) {
                this.eventFinished = false;
                resolve();
            }
        });

        if (this.formFieldErrorsExist) {
            this.loadingOverlay.stop();
            return;
        }

        // In case we need progress bar again
        // this.productsCompleted = 0;
        // this.progressStatus = 'active';

        try {
            await this.deletePendingOfferItems();
            await this.updateOrCreateOfferItems();
        } catch (error) {
            if (error && (error as any).message) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: (error as Error).message,
                });
            }
            this.loadingOverlay.stop();
            return;
        }

        if (this.isUpdating) {
            try {
                await this.updateOfferItemRowNumbers();
            } catch (error) {
                this.loadingOverlay.stop();
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: this.$t('Error loading product items'),
                });
                return;
            }
        }

        // In case we need progress bar again
        // this.progressStatus = 'success';

        EventBus.$emit(EventBusEvents.updateProject, { newOfferId: null, redirectTo: null, shouldUpdateOffer: true });

        if (!this.configuration?.isGlobalButton) {
            EventBus.$emit(EventBusEvents.removeSavedMultipositionSegment, this.productFormId);

            this.$notification.success({
                message: this.isUpdating
                    ? (this.$t('Segment changed successfully!') as string)
                    : (this.$t('Proizvodi uspješno dodani!') as string),
                description: '',
            });
        } else {
            this.$notification.success({
                message: this.isUpdating
                    ? (this.$t('Proizvodi uspješno promijenjeni!') as string)
                    : (this.$t('Proizvodi uspješno dodani!') as string),
                description: '',
            });
            EventBus.$emit(EventBusEvents.toggleEditingForProductsInOffer);
            EventBus.$emit(EventBusEvents.closePopup);
        }

        this.loadingOverlay.stop();
    }

    // private async onUpdateItemsInOffer(activeItemRow: ProductRowItem) {
    //     if (activeItemRow.offerItem == null) {
    //         return Promise.reject();
    //     }

    //     const allowErrors = activeItemRow.offerItem?.hasErrors ? false : null;

    //     const formData: ChecklistFieldEntry[] = formatFormData(
    //         this.getProductFormValues(activeItemRow.activeProductFormId) as ActiveProductFormValueObject
    //     );

    //     const checklistErrors = checklistFieldsWithErrors(activeItemRow);
    //     const errorsHaveValidWarranty = this.errorsHaveValidWarranty(checklistErrors);

    //     let productFormId = null;
    //     if (activeItemRow.productFormId != null) {
    //         productFormId = parseInt(activeItemRow.productFormId);
    //     }

    //     try {
    //         await OfferItem.updateExisting(
    //             formData,
    //             activeItemRow.offerItem.offerItemId,
    //             ProductTypes.PRODUCT,
    //             checklistErrors.length > 0,
    //             productFormId,
    //             activeItemRow.connection,
    //             activeItemRow.production,
    //             allowErrors,
    //             errorsHaveValidWarranty
    //         );
    //     } catch (e) {
    //         this.loadingOverlay.stop();
    //         this.$notification.error({
    //             message: this.$t('Dogodila se greška') as string,
    //             description: e as string,
    //         });
    //         return Promise.reject();
    //     }

    //     return Promise.resolve();
    // }

    private async updateOfferItemRowNumbers() {
        const { offerItemRowNumbers, offerTitleRowNumbers } = generateArraysForUpdatingRowNumbers(
            this.offerItemIdsForUpdatingRowNumbers
        );

        try {
            await Promise.all([
                OfferItem.updateRowNumbers(offerItemRowNumbers),
                OfferTitle.updateRowNumbers(offerTitleRowNumbers),
            ]);
        } catch (error) {
            throw error;
        }

        return Promise.resolve();
    }

    private async preparePayloadForOfferItems(activeItemRow: ProductRowItem) {
        const activeProductFormId = activeItemRow.activeProductFormId;
        let productFormValues = this.getProductFormValues(activeProductFormId);
        const hasErrors = checklistFieldsWithErrors(activeItemRow).length > 0;

        // @ts-ignore
        productFormValues = Object.keys(productFormValues).map((key) => {
            // @ts-ignore
            const value = productFormValues[key];
            if (typeof value === 'object' && value !== null) {
                return value; // Pass the object as it is
            }
            return { id: key, value }; // Convert primitive values to { id, value } objects
        });

        if (productFormValues == null) {
            return Promise.reject();
        }

        const checklistErrors = checklistFieldsWithErrors(activeItemRow);
        const errorsHaveValidWarranty = this.errorsHaveValidWarranty(checklistErrors);
        const offerItemId = activeItemRow.offerItem != null ? activeItemRow.offerItem.id : null;

        const payload: any = {
            formData: productFormValues,
            hasErrors,
            production: activeItemRow.production,
            offerItemType: activeItemRow?.offerItem?.offerItemType ?? 'product',
            validWarranty: errorsHaveValidWarranty,
            activeProductFormId: activeItemRow.productFormId,
            connection: activeItemRow.connection,
        };

        if (offerItemId) {
            payload.id = offerItemId;
        }

        return Promise.resolve(payload);
    }

    private async onAddItemsToOffer(payloads: MultipositionProduct[]) {
        try {
            await ConfiguratorService.addBulkItemsToOffer(payloads, this.updatedOfferId!);
        } catch (e) {
            this.loadingOverlay.stop();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });

            return Promise.reject();
        }

        return Promise.resolve();
    }

    private async onUpdateItemsInOffer(payloads: MultipositionProduct[]) {
        try {
            await ConfiguratorService.updateBulkItemsInOffer(payloads, this.updatedOfferId!);
        } catch (e) {
            this.loadingOverlay.stop();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });

            return Promise.reject();
        }

        return Promise.resolve();
    }

    private async deletePendingOfferItems() {
        const promises = [];

        for (const offerItemId of this.offerItemIdsToBeDeleted) {
            promises.push(OfferItem.deleteExisting(offerItemId));
        }

        try {
            await Promise.all(promises);
        } catch {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    private async getOfferId() {
        let validOfferId;

        try {
            validOfferId = await ConfiguratorService.getValidOfferId(
                this.projectId,
                this.updatedOfferId,
                this.clientsPaymentTypeId
            );
            this.updatedOfferId = validOfferId;
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: this.$t('Error getting offer ID'),
            });
            this.loadingOverlay.stop();
            return Promise.reject();
        }
    }

    private async updateOrCreateOfferItems() {
        await this.getOfferId();

        const payloads: MultipositionProduct[] = [];
        const promises = [];
        // Get payloads for all products we gonna add

        try {
            for (const activeItemRow of this.activeItemRows) {
                let promise;

                // Send call for payload preparation and update payloads
                promise = this.preparePayloadForOfferItems(activeItemRow);
                promises.push(
                    promise.then((payload: any) => {
                        payloads.push(payload);
                    })
                );
            }

            if (promises.length) {
                await Promise.all(promises);
            }

            await Promise.all([
                this.onAddItemsToOffer(payloads.filter((payload: MultipositionProduct) => !payload.id)),
                this.onUpdateItemsInOffer(payloads.filter((payload: MultipositionProduct) => payload.id)),
            ]);
        } catch {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    private continueExecution() {
        if (this.eventFinishedCallback) {
            this.eventFinishedCallback();
        } else {
            this.eventFinished = true;
        }

        this.eventFinishedCallback = null;
    }

    private created() {
        EventBus.$on(EventBusEvents.formValidationFinished, this.continueExecution);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.formValidationFinished, this.continueExecution);
    }
}
