import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProductAPI {
    public static async getAll() {
        let products;

        try {
            products = await ApiController.get(ApiRoutes.product.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(products);
    }

    public static async getById(productId: string) {
        let product;

        try {
            product = await ApiController.get(ApiRoutes.product.getSingle(productId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(product);
    }

    public static async searchByProductNameOrProductSystemName(query: string) {
        let product;

        try {
            product = await ApiController.get(ApiRoutes.product.searchByProductNameOrProductSystemName(query));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(product);
    }
}
