import ProductCategory from '@/models/ProductCategory';

export default class ProductCategoriesRepository {
    public static getAll() {
        return ProductCategory.query().withAllRecursive().orderBy('position').get();
    }

    public static getOnlyNamesAndIds() {
        return ProductCategory.query().orderBy('position').get();
    }

    public static getById(productCategoryId: string) {
        return ProductCategory.query()
            .whereId(productCategoryId).withAllRecursive().first();
    }

    public static getByCode(productCategoryCode: string) {
        return ProductCategory.query().where('code', productCategoryCode).withAllRecursive().first();
    }
}
