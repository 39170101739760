import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class BankAccountAPI {
    public static async getAll() {
        let bankAccounts;

        try {
            bankAccounts = await ApiController.get(ApiRoutes.bankAccount.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(bankAccounts);
    }

    public static async getById(bankAccountId: string) {
        let bankAccount;

        try {
            bankAccount = await ApiController.get(ApiRoutes.bankAccount.getById(bankAccountId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(bankAccount);
    }

    public static async delete(bankAccountId: string) {

        try {
            await ApiController.delete(ApiRoutes.bankAccount.delete(bankAccountId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(bankAccountId: string, bankAccountInfo: AvailableLabelFields) {
        let bankAccount;
        const request = {
            data: {
                type: 'bank_accounts',
                id: bankAccountId,
                attributes: {
                    bankName: bankAccountInfo.bankName,
                    accountNumber: bankAccountInfo.accountNumber,
                },
            },
        };

        try {
            bankAccount =
                await ApiController.patch(
                    ApiRoutes.bankAccount.update(bankAccountId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(bankAccount);
    }

    public static async create(bankAccountInfo: AvailableLabelFields) {
        let bankAccount;
        const request = {
            data: {
                type: 'bank_accounts',
                attributes: {
                    bankName: bankAccountInfo.bankName,
                    accountNumber: bankAccountInfo.accountNumber,
                },
            },
        };

        try {
            bankAccount =
                await ApiController.post(
                    ApiRoutes.bankAccount.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(bankAccount);
    }
}
