import { Model } from '@vuex-orm/core';
import StatusEventAPI from '@/api/StatusEventAPI';
import { AxiosError, AxiosResponse } from 'axios';
import StatusEventConfiguration from '@/models/interfaces/StatusEventConfiguration';

export default class StatusEvent extends Model {
    public static entity = 'StatusEvent';

    public static fields() {
        return {
            id: this.string(null),
            conditions: this.attr(null),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            updatedBy: this.string(''),
            executionType: this.string(''),
            nextStepTemplate: this.attr(''),
            madeByUser: this.string(''),
            name: this.string(''),
            projectLabel: this.string(''),
            receptionUsers: this.attr(''),
            status: this.string(''),
            statusChange: this.string(''),
            type: this.string(''),
        };
    }

    public static async getById(StatusEventId: string): Promise<AxiosResponse | AxiosError> {
        let statusEvent;

        try {
            statusEvent = await StatusEventAPI.getById(StatusEventId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: statusEvent.data,
        });
        return Promise.resolve(statusEvent);
    }

    public static async getAll(): Promise<AxiosResponse | AxiosError> {
        let statusEvents;

        try {
            statusEvents = await StatusEventAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: statusEvents.data,
        });
        return Promise.resolve(statusEvents);
    }

    public static async createNew(EventData: StatusEventConfiguration): Promise<any> {
        let statusEvent;

        try {
            statusEvent = await StatusEventAPI.create(EventData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: statusEvent.data,
        });
        return Promise.resolve(statusEvent);
    }

    public static async updateExisting(
        StatusEventId: string,
        EventData: StatusEventConfiguration,
    ): Promise<void | AxiosError> {
        let statusEvent;

        try {
            statusEvent = await StatusEventAPI.update(StatusEventId, EventData);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: statusEvent.data,
        });

        return Promise.resolve();
    }

    public static async deleteExisting(StatusEventId: string): Promise<void | AxiosError> {
        try {
            await StatusEventAPI.delete(StatusEventId);
        } catch (e) {
            return Promise.reject(e);
        }

        await StatusEvent.delete(StatusEventId);

        return Promise.resolve();
    }

    public id!: string;
    public conditions!: object;
    public createdAt!: string;
    public updatedAt!: string;
    public updatedBy!: string;
    public executionType!: string;
    public nextStepTemplate!: number;
    public madeByUser!: string;
    public name!: string;
    public projectLabel!: string;
    public receptionUsers!: object;
    public status!: string;
    public statusChange!: string;
    public type!: string;
}
