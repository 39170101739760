import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { MontageInterface } from '@/models/interfaces/Montage';
import { MontageDetailsOptions } from '@/interfaces/components/projectNew/MontageDetailsOptions';

export default class MontageAPI {
    public static async create(offerId: string, montageInfo: MontageInterface | MontageDetailsOptions) {
        let montage;

        const request = {
            data: {
                type: 'montages',
                attributes: {
                    delivery: montageInfo.delivery,
                    assembly: montageInfo.assembly,
                    price: montageInfo.price,
                    numberOfWorkers: montageInfo.numberOfWorkers,
                    electrician: montageInfo.electrician,
                    expectedTime: montageInfo.expectedTime,
                    deconstruction: montageInfo.deconstruction,
                    removal: montageInfo.removal,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },
                },
            },
        };

        try {
            montage =
                await ApiController.post(ApiRoutes.montage.create, request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(montage);
    }

    public static async update(montageId: string, montageInfo: MontageInterface | MontageDetailsOptions) {
        let montage;
        const request = {
            data: {
                type: 'montages',
                id: montageId,
                attributes: {
                    delivery: montageInfo.delivery,
                    assembly: montageInfo.assembly,
                    price: montageInfo.price,
                    numberOfWorkers: montageInfo.numberOfWorkers,
                    electrician: montageInfo.electrician,
                    expectedTime: montageInfo.expectedTime,
                    deconstruction: montageInfo.deconstruction,
                    removal: montageInfo.removal,
                },
            },
        };

        try {
            montage =
                await ApiController.patch(ApiRoutes.montage.update(montageId), request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(montage);
    }

    public static async delete(montageId: string) {
        try {
            await ApiController.delete(ApiRoutes.montage.delete(montageId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
