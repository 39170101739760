import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class JsFunctionAPI {
    public static async getAll() {
        let functions;

        try {
            functions = await ApiController.get(ApiRoutes.functions.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(functions);
    }
}
