import OrderStatus from '@/models/OrderStatus';

export default class OrderStatusRepository {
    public static getAll() {
        return OrderStatus.all();
    }

    public static getById(id: string) {
        return OrderStatus.query().whereId(id).withAll().first();
    }
}
