import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import { Moment } from 'moment';
import moment from 'moment';

export default class NextStepAPI {
    public static async getAll() {
        let nextSteps;

        try {
            nextSteps = (await ApiController.get(ApiRoutes.nextStep.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextSteps);
    }

    public static async getById(nextStepId: string) {
        let nextStep;

        try {
            nextStep = (await ApiController.get(ApiRoutes.nextStep.getById(nextStepId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async update(nextStepId: string, nextStepInfo: AvailableLabelFields) {
        let nextStep;

        const payload = {
            data: {
                type: 'next_steps',
                id: nextStepId,
                attributes: {
                    name: nextStepInfo.name,
                    dateFrom: this.formatDateString(nextStepInfo?.dateFrom ?? nextStepInfo?.date),
                    dateTo: this.formatDateString(nextStepInfo?.dateTo ?? nextStepInfo?.date),
                    note: nextStepInfo.note,
                    completed: nextStepInfo.completed,
                    reminderDate: nextStepInfo.reminderDate,
                },
                relationships: {},
            },
        };

        if (nextStepInfo.reminderOption) {
            // @ts-ignore
            payload.data.relationships.reminderOption = {
                data: {
                    type: 'reminder_options',
                    id: nextStepInfo.reminderOption,
                },
            };
        }

        if (nextStepInfo.nextStepType) {
            // @ts-ignore
            payload.data.relationships.type = {
                data: {
                    type: 'next_step_types',
                    id: nextStepInfo.nextStepType,
                },
            };
        }

        if (nextStepInfo.assignedUser) {
            // @ts-ignore
            payload.data.relationships.assignedUser = {
                data: {
                    type: 'users',
                    id: nextStepInfo.assignedUser,
                },
            };
        }

        if (nextStepInfo.chatMessage) {
            // @ts-ignore
            payload.data.relationships.chatMessage = {
                data: {
                    type: 'chat_messages',
                    id: nextStepInfo.chatMessage,
                },
            };
        }

        if (nextStepInfo.projectId) {
            // @ts-ignore
            payload.data.relationships.project = {
                data: {
                    type: 'projects',
                    id: nextStepInfo.projectId,
                },
            };
        }

        try {
            nextStep = (await ApiController.patch(ApiRoutes.nextStep.update(nextStepId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async create(nextStepInfo: AvailableLabelFields) {
        let nextStep;
        const payload = {
            data: {
                type: 'next_steps',
                attributes: {
                    name: nextStepInfo.name,
                    date: nextStepInfo.date,
                    dateFrom: this.formatDateString(nextStepInfo?.dateFrom ?? nextStepInfo?.date),
                    dateTo: this.formatDateString(nextStepInfo?.dateTo ?? nextStepInfo?.date),
                    note: nextStepInfo.note,
                    completed: nextStepInfo.completed,
                    reminderDate: nextStepInfo.reminderDate,
                },
                relationships: {},
            },
        };

        if (nextStepInfo.reminderOption) {
            // @ts-ignore
            payload.data.relationships.reminderOption = {
                data: {
                    type: 'reminder_options',
                    id: nextStepInfo.reminderOption,
                },
            };
        }

        if (nextStepInfo.nextStepType) {
            // @ts-ignore
            payload.data.relationships.type = {
                data: {
                    type: 'next_step_types',
                    id: nextStepInfo.nextStepType,
                },
            };
        }

        if (nextStepInfo.assignedUser) {
            // @ts-ignore
            payload.data.relationships.assignedUser = {
                data: {
                    type: 'users',
                    id: nextStepInfo.assignedUser,
                },
            };
        }

        if (nextStepInfo.projectId) {
            // @ts-ignore
            payload.data.relationships.project = {
                data: {
                    type: 'projects',
                    id: nextStepInfo.projectId,
                },
            };
        }

        if (nextStepInfo.chatMessage) {
            // @ts-ignore
            payload.data.relationships.chatMessage = {
                data: {
                    type: 'chat_messages',
                    id: nextStepInfo.chatMessage,
                },
            };
        }

        try {
            nextStep = (await ApiController.post(ApiRoutes.nextStep.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static formatDateString(date: string | Moment) {
        const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSZ'; // ISO format with local time zone offset

        if (typeof date === 'string') {
            return moment(date).format(formatString);
        }

        return date.format(formatString);
    }

    public static async updateCompletedState(nextStepId: string, completed: boolean) {
        let nextStep;

        const payload = {
            data: {
                type: 'next_steps',
                id: nextStepId,
                attributes: {
                    completed,
                },
            },
        };

        try {
            nextStep = (await ApiController.patch(ApiRoutes.nextStep.update(nextStepId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(nextStep);
    }

    public static async delete(nextStepId: string) {
        try {
            await ApiController.delete(ApiRoutes.nextStep.delete(nextStepId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getFilteredProjects(query: string) {
        let filteredProjects;

        try {
            filteredProjects = (await ApiController.get(
                ApiRoutes.nextStep.filterNextStepProjects(query)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(filteredProjects);
    }
}
