import { Model } from '@vuex-orm/core';
import PriceManipulationType from '@/models/PriceManipulationType';
import ProductCategory from '@/models/ProductCategory';
import AutomaticPriceManipulationAPI from '@/api/AutomaticPriceManipulationAPI';

export default class AutomaticPriceManipulation extends Model {

    public static entity = 'automaticPriceManipulation';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            value: this.number(0),
            priceManipulationType: this.belongsTo(PriceManipulationType, 'priceManipulationType_id'),
            priceManipulationType_id: this.attr(null),
            productCategory_id: this.attr(null),
            productCategory: this.belongsTo(ProductCategory, 'productCategory_id'),
        };
    }

    public static async getAll() {
        let priceManipulations;
        try {
            priceManipulations = await AutomaticPriceManipulationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulations.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve();
    }

    public static async getById(priceManipulationId: string) {
        let priceManipulation;
        try {
            priceManipulation = await AutomaticPriceManipulationAPI.getById(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve(priceManipulation);
    }

    public static async updateExisting(priceManipulationId: string, priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await AutomaticPriceManipulationAPI.update(priceManipulationId, priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await AutomaticPriceManipulation.delete(priceManipulationId);
        return Promise.resolve(priceManipulation);
    }

    public static async createNew(priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await AutomaticPriceManipulationAPI.create(priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(priceManipulation);
    }

    public static async deleteExisting(priceManipulationId: string) {
        try {
            await AutomaticPriceManipulationAPI.delete(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await AutomaticPriceManipulation.delete(priceManipulationId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string | null;
    public value!: number;
    public priceManipulationType!: PriceManipulationType;
    public productCategory!: ProductCategory;

}
