import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class AttachmentAPI {
    public static async createNew(formData: FormData, shouldBePublic: boolean) {
        const apiRoute = shouldBePublic ? ApiRoutes.attachments.createPublic : ApiRoutes.attachments.create;
        let image;

        try {
            image = (await ApiController.post(apiRoute, formData)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(image);
    }

    public static async getBlob(resourceUrl: string) {
        return (await ApiController.get(resourceUrl, null, true)) as AxiosResponse;
    }

    public static async getAttachmentsByProjectId(projectId: string) {
        let attachments;

        try {
            attachments = (await ApiController.get(
                ApiRoutes.attachments.getAttachmentsByProjectId(projectId)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(attachments);
    }

    public static async delete(attachmentId: string) {
        try {
            await ApiController.delete(ApiRoutes.attachments.delete(attachmentId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
