import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { ApiController } from '@/services/ApiController';
import { AxiosResponse } from 'axios';
import ProductSystemWizardTranslation from '@/models/ProductSystemWizardTranslation';
import { TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';

export default class ProductSystemWizardAPI {

    public static async getAll() {
        let getAllProductSystemWizard;

        try {
            getAllProductSystemWizard = await ApiController.get(ApiRoutes.productSystemWizard.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(getAllProductSystemWizard);
    }

    public static async getById(id: string) {
        let productSystemWizardData;
        try {
            productSystemWizardData = await 
                ApiController.get(ApiRoutes.productSystemWizard.getById(id)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(productSystemWizardData);
    }

    public static async getByProductSystemId(id: string) {
        let productSystemWizardData;
        try {
            productSystemWizardData = await 
                ApiController.get(ApiRoutes.productSystemWizard.getByProductSystemId(id)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(productSystemWizardData);
    }

    public static async createNew(data?: any) {

        const request = {
            data: {
                type: 'product_system_window_wizard',
                attributes: {
                    content: data?.translations.filter((t: any) => !!t.description && t.description !== '')
                                                .map((el: ProductSystemWizardTranslation) => {
                        return {
                            ...el,
                            image: el.image ? el.image.uuid : null,
                            locale: el.locale, // el.locale?.code,
                        };
                    }),
                },
                relationships: {},
            },
        };
        const relationships: TJsonApiRelationships = {
            productSystem: {
                // @ts-ignore
                data: null,
            },
        };
        if (data.productSystem) {
            relationships.productSystem = {
                data: {
                    type: 'product_systems',
                    id: data.productSystem.id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }
        let newProductSystemWizard;
        try {
            newProductSystemWizard = await 
                ApiController.post(ApiRoutes.productSystemWizard.create, request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProductSystemWizard);
    }

    public static async createNewImage(image?: any) {
        let newProductSystemWizardImage;
        try {
            newProductSystemWizardImage = await 
                ApiController.post(ApiRoutes.productSystemWizard.createImage, image) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProductSystemWizardImage);
    }
    public static async getImage(id: string) {
        let image;
        try {
            image = await ApiController.get(ApiRoutes.productSystemWizard.getImage(id)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(image);
    }
    public static async updateExisting(id: string, data: any) {

        const request = {
            data: {
                type: 'product_system_window_wizard',
                id: String(id),
                attributes: {
                    content: 
                        data?.translations.filter((t: any) => !!t.description && t.description !== '')
                                                .map((el: ProductSystemWizardTranslation) => {
                        return {
                            // ...el,
                            description: el.description ?? '',
                            image: el.image ? (el.image.uuid !== '' ? el.image.uuid : el.image.id) : null,
                            locale: el.locale, // el.locale?.code,
                        };
                    }),
                },
                relationships: {},
            },
        };
        const relationships: TJsonApiRelationships = {
            productSystem: {
                // @ts-ignore
                data: null,
            },
        };
        if (data.productSystem) {
            relationships.productSystem = {
                data: {
                    type: 'product_systems',
                    id: data.productSystem.id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        let updatedProductSystemWizard;
        try {
            updatedProductSystemWizard = await
            ApiController.patch(ApiRoutes.productSystemWizard.update(id), request) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedProductSystemWizard);
    }

    public static async deleteExisting(id: string) {
        try {
            await ApiController.delete(ApiRoutes.productSystemWizard.update(id));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
