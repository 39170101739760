
import { Component, Prop, Vue } from 'vue-property-decorator';
import { defaultValueWarnings } from '@/interfaces/components/projectNew/DefaultValueWarnings';

@Component({
    name: 'DefaultValueWarnings',
    components: {},
})
export default class DefaultValueWarnings extends Vue {
    @Prop({ required: true }) private warnings!: defaultValueWarnings[];
    @Prop({ required: false, default: false }) private isVisible!: boolean;

    private get hasWarnings() {
        if (!this.warnings) {
            return false;
        }

        return this.warnings.some((warningItem: defaultValueWarnings) => {
            return !!warningItem?.warnings.length;
        });
    }

    private handleOk() {
        this.$emit('closeModal');
    }
}
