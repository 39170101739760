import NextStep from '@/models/NextStep';

export default class NextStepRepository {
    public static getAll() {
        return NextStep.query().withAllRecursive().all();
    }

    public static getById(id: string) {
        return NextStep.query().whereId(id).withAllRecursive().first();
    }

    public static delete(id: string) {
        return NextStep.delete(id);
    }

    public static getLeadsNextSteps(leadId: string): NextStep[] {
        return NextStep.query().where('lead_id', leadId).withAllRecursive().get();
    }

    public static getNextStepsWithProjects() {
        return NextStep.query().withAllRecursive().get();
    }
}
