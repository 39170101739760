import Campaign from '@/models/Campaign';

export default class CampaignRepository {
    public static getAll() {
        return Campaign.all();
    }

    public static getById(id: string) {
        return Campaign.query().whereId(id).withAll().first();
    }
}
