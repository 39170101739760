import OfferRepository from '@/repositories/OfferRepository';
import OrderPdf from '@/models/OrderPdf';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Offer from '@/models/Offer';
import Client from '@/models/Client';
import { AxiosError } from 'axios';
// @ts-ignore
import downloadFile from 'downloadjs';
import i18n from '@/i18n';

/**
 * Create the order PDF on the order
 * @param offerId - The offer id that will create order
 * @param orderConfigurationId - The  id order_configurations
 * @return An empty promise
 */
export async function updateOrCreateOrderPDF(offerId: string, orderConfigurationId: number) {
    await Offer.getById(offerId);
    const offer = OfferRepository.getOfferById(offerId);
    if (offer == null) {
        return Promise.reject();
    }

    try {
        if (!offer.orderPdf) {
            await OrderPdf.addToOffer(offerId, orderConfigurationId);
        } else {
            await OrderPdf.updateExisting(offer.id, offer.orderPdf.id, orderConfigurationId);
        }
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve();
}

export function getOrderConfigurationIdByLocale(locale: string) {
    switch (locale) {
        case 'sl':
            return 2;
        case 'hr':
            return 3;
        case 'it':
            return 5;
        case 'de':
            return 4;
        case 'en':
            return 1;
        case 'hu':
            return 6;
        default:
            return 1;
    }
}

/**
 * Validate the given data for creating the PDF and download the PDF preview
 * @param offerId - id of offer
 * @param locale - presents current user language
 * @return An empty promise
 */
export async function getOrderPdfForPreview(offerId: string, locale: string) {
    try {
        const orderConfigurationId = getOrderConfigurationIdByLocale(locale);
        // await updateOrCreateOrderPDF(offerId, orderConfigurationId);
        const pdfData = await preparedOrderPDFForPreviewBasedOnGivenOptions(offerId, orderConfigurationId);

        return Promise.resolve(pdfData);
    } catch (e) {
        EventBus.$emit(EventBusEvents.closePopup);
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject();
    }
}

/**
 * Preapre pdf for previewing the PDF based on the given options
 * @param offerId - id of offer
 * @param documentTypeId - id of document pdf properties type
 * @return pdfDataPath empty promise
 */
export async function preparedOrderPDFForPreviewBasedOnGivenOptions(offerId: string, documentTypeId: number) {
    let pdf;
    let file;
    let data = null;

    try {
        pdf = await OrderPdf.previewOrderPDF(offerId, documentTypeId);
        file = await Offer.getOfferPDF('files/order-pdf/' + pdf.data.filename);

        data = {
            file,
            pdf,
        };
    } catch (e) {
        // tslint:disable-next-line:max-line-length
        if((e as any).response.status==422){
            throw new Error(i18n.t('One or more positions have old product form version. Positions need to be recalculated.'));
        }
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    return Promise.resolve(data);
}

/**
 * Download the PDF of the given order, if it exists
 * @param offer - The offer from which the PDF should be downloaded
 * @param client - The project client whose name will be used in the pdf name, if it exists
 */
export async function downloadOrderPDF(offer: Offer | null, client: Client | null) {
    if (offer == null || offer.orderPdf == null) {
        throw new Error('Order PDF does not exist');
    }

    let pdf;

    try {
        pdf = await OrderPdf.getOrderPDF(offer.orderPdf.filename);
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        } else {
            throw new Error((e as { response: { data: { meta: { message: string } } } }).response.data.meta.message);
        }
    }

    downloadFile(pdf.data, `${(offer.offerName=='-') ? i18n.t('OrderPDF') : offer.offerName}${client ? ' '+client.name : '_'+offer.project_id}.pdf`, pdf.data.type);

    return Promise.resolve();
}
