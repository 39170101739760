import { Model } from '@vuex-orm/core';
import ResourceAPI from '@/api/ResourceAPI';
import { AxiosResponse, AxiosError } from 'axios';

export default class Resource extends Model {
    public static entity = 'resource';

    public static fields() {
        return {
            id: this.string(null),
            userChanged: this.string(null),
            userInserted: this.string(null),
            title: this.string(''),
            description: this.string(''),
            notes: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
        };
    }

    public static async getAll() {
        let resources;

        try {
            resources = await ResourceAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: resources.data,
            insertOrUpdate: ['resources'],
        });

        return Promise.resolve(resources);
    }

    public static async getById(id: string) {
        let resource;
        try {
            resource = await ResourceAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: resource.data,
            insertOrUpdate: ['resources'],
        });

        return Promise.resolve(resource);
    }

    public static async createNew(resource: Resource): Promise<AxiosResponse | AxiosError> {
        let newResource;
        try {
            newResource = await ResourceAPI.create(resource);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: newResource.data,
            insertOrUpdate: ['resources'],
        });

        return Promise.resolve(newResource);
    }

    public static async updateExisting(resourceId: string, resourceData: Resource) {
        let resource;
        try {
            resource = await ResourceAPI.update(resourceId, resourceData);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: resource.data,
            insertOrUpdate: ['resources'],
        });

        return Promise.resolve(resource);
    }

    public static async deleteExisting(resourceId: string) {
        try {
            await ResourceAPI.delete(resourceId);
        } catch (e) {
            return Promise.reject(e);
        }

        await Resource.delete(resourceId);

        return Promise.resolve();
    }

    public id!: string;
    public userChanged!: string;
    public userInserted!: string;
    public title!: string;
    public description!: string;
    public notes!: string;
    public createdAt!: string;
    public updatedAt!: string;
}
