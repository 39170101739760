import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import LeadInterface from '@/models/interfaces/Lead';
import { formatDate } from '@/helpers/CmsIndexHelper';
import { UploadFile } from 'ant-design-vue/types/upload';
import { LeadDetailsOptions } from '@/interfaces/components/projectNew/LeadDetailsOptions';

export default class LeadsAPI {
    public static async createNew(leadData: LeadInterface, id: string) {
        const formattedPriority = leadData.priority ? formatDate(leadData.priority) : null;

        const payload = {
            data: {
                type: 'leads',
                attributes: {
                    description: leadData.description,
                    numberOfProducts: leadData.numberOfProducts,
                    windowsBuiltIn: leadData.windowsBuiltIn,
                },
                relationships: {
                    project: {
                        data: {
                            type: 'projects',
                            id,
                        },
                    },
                },
            },
        };

        if (formattedPriority) {
            // @ts-ignore
            payload.data.attributes.priority = formattedPriority;
        }

        if (leadData.origin) {
            // @ts-ignore
            payload.data.relationships.origin = {
                data: {
                    type: 'lead_origins',
                    id: leadData.origin,
                },
            };
        }

        if (leadData.objectAddress) {
            // @ts-ignore
            payload.data.relationships.objectAddress = {
                data: {
                    type: 'addresses',
                    id: leadData.objectAddress.id,
                },
            };
        } else {
            // @ts-ignore
            payload.data.relationships.objectAddress = {
                data: null,
            };
        }

        if (leadData.products) {
            // @ts-ignore
            payload.data.relationships.products = {
                data: leadData.products.map((productId: string) => {
                    return {
                        type: 'lead_products',
                        id: productId,
                    };
                }),
            };
        }

        if (leadData.campaign) {
            // @ts-ignore
            payload.data.relationships.campaign = {
                data: {
                    type: 'campaigns',
                    id: leadData.campaign,
                },
            };
        }

        if (leadData.leadType) {
            // @ts-ignore
            payload.data.relationships.leadType = {
                data: {
                    type: 'lead_types',
                    id: leadData.leadType,
                },
            };
        }

        return await ApiController.post(ApiRoutes.createNewLead, payload) as AxiosResponse;
    }

    public static async updateExisting(leadData: LeadInterface, leadId: string) {
        const formattedPriority = leadData.priority ? formatDate(leadData.priority) : null;

        const payload = {
            data: {
                type: 'leads',
                id: leadId,
                attributes: {
                    description: leadData.description,
                    numberOfProducts: leadData.numberOfProducts,
                    windowsBuiltIn: leadData.windowsBuiltIn,
                },
                relationships: {},
            },
        };
        if (formattedPriority) {
            // @ts-ignore
            payload.data.attributes.priority = formattedPriority;
        }

        if (leadData.origin) {
            // @ts-ignore
            payload.data.relationships.origin = {
                data: {
                    type: 'lead_origins',
                    id: leadData.origin,
                },
            };
        }

        if (leadData.objectAddress) {
            // @ts-ignore
            payload.data.relationships.objectAddress = {
                data: {
                    type: 'addresses',
                    id: leadData.objectAddress.id,
                },
            };
        } else {
            // @ts-ignore
            payload.data.relationships.objectAddress = {
                data: null,
            };
        }

        if (leadData.campaign) {
            // @ts-ignore
            payload.data.relationships.campaign = {
                data: {
                    type: 'campaigns',
                    id: leadData.campaign,
                },
            };
        }

        if (leadData.products) {
            // @ts-ignore
            payload.data.relationships.products = {
                data: leadData.products.map((productId: string) => {
                    return {
                        type: 'lead_products',
                        id: productId,
                    };
                }),
            };
        }

        if (leadData.leadType) {
            // @ts-ignore
            payload.data.relationships.leadType = {
                data: {
                    type: 'lead_types',
                    id: leadData.leadType,
                },
            };
        }


        return await ApiController.patch(ApiRoutes.updateLead(leadId), payload) as AxiosResponse;
    }

    public static async updateLeadDetails(values: LeadDetailsOptions, leadId: string) {

        const payload = {
            data: {
                type: 'leads',
                id: leadId,
                attributes: {
                    description: values.description,
                    numberOfProducts: values.numberOfProducts,
                    windowsBuiltIn: values.windowsBuiltIn,
                },
                relationships: {},
            },
        };


        if (values.leadOrigin) {
            // @ts-ignore
            payload.data.relationships.origin = {
                data: {
                    type: 'lead_origins',
                    id: values.leadOrigin,
                },
            };
        }

        if (values.campaign) {
            // @ts-ignore
            payload.data.relationships.campaign = {
                data: {
                    type: 'campaigns',
                    id: values.campaign,
                },
            };
        }

        if (values.products) {
            // @ts-ignore
            payload.data.relationships.products = {
                data: values.products.map((productId: string) => {
                    return {
                        type: 'lead_products',
                        id: productId,
                    };
                }),
            };
        }

        if (values.leadType) {
            // @ts-ignore
            payload.data.relationships.leadType = {
                data: {
                    type: 'lead_types',
                    id: values.leadType,
                },
            };
        }


        return await ApiController.patch(ApiRoutes.lead.updateExisting(leadId), payload);
    }

    public static async deleteObjectAddress(leadId: string) {
        const payload = {
            data: {
                type: 'leads',
                id: leadId,
                attributes: {
                },
                relationships: {
                    objectAddress: {
                        data: null,
                    },
                },
            },
        };

        return await ApiController.patch(ApiRoutes.lead.deleteObjectAddress(leadId), payload);
    }

    public static async updateAttachment(attachments: UploadFile[], leadId: string) {
        const payload = {
            data: {
                type: 'leads',
                id: leadId,
                attributes: {},
                relationships: {
                    attachments: {
                        data: [],
                    },
                },
            },
        };
        if (attachments.length > 0) {
            const attachmentData = attachments.map((attachment: UploadFile) => {
                return {
                    type: 'attachments',
                    id: attachment.uid,
                };
            });
            payload.data.relationships.attachments = {
                // @ts-ignore
                data: attachmentData,
            };
        }


        return await ApiController.patch(ApiRoutes.updateLead(leadId), payload) as AxiosResponse;
    }

    public static async getAdditionalData(leadId: string) {
        return await ApiController.get(ApiRoutes.lead.leadAdditionalData(leadId)) as AxiosResponse;
    }


    public static async getPdf(leadId: string) {
        return await ApiController.get(ApiRoutes.lead.pdf(leadId), null, true) as AxiosResponse;
    }
}
