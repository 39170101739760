
import { Vue, Component, Prop } from 'vue-property-decorator';
import { convertMultipleDropdownSourcesToArray } from '@/helpers/SearchHelper';

@Component({
    name: 'MultipleSelect',
})
export default class MultipleSelect extends Vue {
    @Prop({ required: true }) public value!: any[];
    @Prop({ default: false }) public isDisabled!: boolean;
    @Prop({ default: false }) public shouldIncludeKeyInId!: boolean;
    @Prop({ required: true }) private list!: object | (() => any[]);
    @Prop({ default: '' }) private label!: string;

    private formatValues(itemId: any) {
        this.$emit('update:value', itemId);
    }

    private get source() {
        if (this.shouldIncludeKeyInId || typeof this.list === 'object') {
            return convertMultipleDropdownSourcesToArray(this.list);
        }

        return this.list();
    }
}
