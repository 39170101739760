import PriceManipulationType from '@/models/PriceManipulationType';

export default class PriceManipulationTypeRepository {
    public static getAll() {
        return PriceManipulationType.query().withAll().get();
    }

    public static getById(id: string) {
        return PriceManipulationType.query().whereId(id).withAll().first();
    }
}
