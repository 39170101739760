
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AppTable from '@/components/global/app-table/AppTable.vue';
import { UserRepository } from '@/repositories/UserRepository';
import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';
import { getAppropriateFilterOptions } from '@/helpers/NewProjectSearchBarHelper';
import { ElementFilterType } from '@/enums/global/ElementFilterType';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import CountriesDropdown from '@/components/global/CountriesDropdown.vue';
import MultipleSelect from '@/components/global/MultipleSelect.vue';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ColumnSorterInterface } from '@/interfaces/ColumnSorterInterface';
import { LocalStorageService } from '@/services/LocalStorageService';
import { checkIfLocalStorageParametersExist, clearSearchParameters } from '@/helpers/SearchHelper';
import { ProjectTypes } from '@/enums/models/ProjectTypes';

@Component({
    name: 'TableFilters',
    components: {
        AppTable,
        CountriesDropdown,
        MultipleSelect,
        AutocompleteGroupInput,
    },
})
export default class TableFilters extends Vue {
    @Prop({ required: true }) private entityType!: string;
    @Prop({ required: true }) private readonly sortedInfo!: ColumnSorterInterface | null;
    @Prop({ required: true }) private isLoading!: boolean;
    @Prop({ required: true }) private toggleFilters!: () => {};
    @Prop({ default: false }) private ignoreLocalFilters!: boolean;

    private activeFilterOptions: SearchEntityFilter[] = [];
    private elementFilterType = ElementFilterType;

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private get users() {
        return CMSUserRepository.getAll();
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private startLoading() {
        this.$emit('startLoading');
    }

    private closeFiltersWindow() {
        this.toggleFilters();
    }

    private get isRequestTab() {
        return this.entityType === ProjectTypes.Request;
    }

    private onSearch() {
        this.startLoading();

        EventBus.$emit(EventBusEvents.runEntitySearchWithParameters, {
            isSearchActive: true,
            entityType: this.entityType,
            availableFilters: this.activeFilterOptions,
            page: 1,
        });
    }

    private onClearSearchParameters() {
        if (this.user == null) {
            return;
        }

        this.startLoading();

        clearSearchParameters(this.entityType, this.user.id);
        this.activeFilterOptions.forEach((filter: SearchEntityFilter) => {
            filter.value = filter.defaultValue;

            if (filter.filter === 'assignedUser.id') {
                filter.value = '';
            }
        });

        EventBus.$emit(EventBusEvents.runEntitySearch, {
            sortField: null,
            sortOrder: null,
            filters: [],
            shouldSetDefaultUser: false,
        });
        EventBus.$emit(EventBusEvents.updateSortedInfo, { columnKey: null, order: null });

        this.toggleFilters();
    }

    private onDropdownChange(filter: SearchEntityFilter, choiceId?: string) {
        filter.value = choiceId == null ? '' : choiceId;
        this.onSearch();
    }

    private setFilterInUI(availableFilter: SearchEntityFilter) {
        const activeOptions = this.activeFilterOptions;

        const selectedFilterOption = activeOptions.find((filterOption: SearchEntityFilter) => {
            return filterOption.filter === availableFilter.filter;
        });

        // @ts-ignore
        if (selectedFilterOption == null) {
            return;
        }

        selectedFilterOption.value = availableFilter.value;
    }
    private updateFiltersAfterSearch(args: { availableFilters: SearchEntityFilter[] }) {
        if (args.availableFilters && args.availableFilters.length > 0) {
            this.activeFilterOptions = args.availableFilters;
        }
    }
    private created() {
        EventBus.$on(EventBusEvents.runEntitySearchWithParameters, this.updateFiltersAfterSearch);
    }
    private beforeDestroy() {
        EventBus.$on(EventBusEvents.runEntitySearchWithParameters, this.updateFiltersAfterSearch);
    }

    @Watch('entityType', { immediate: true })
    private async onEntityTypeChange() {
        let localStorageParameters = null;

        this.activeFilterOptions = getAppropriateFilterOptions({
            entityType: this.entityType,
            currentUser: this.user,
            shouldSetDefaultUser: !this.ignoreLocalFilters,
        });
        if (checkIfLocalStorageParametersExist(this.user, this.entityType) && !this.ignoreLocalFilters) {
            localStorageParameters = JSON.parse(
                LocalStorageService.get(
                    // @ts-ignore
                    `${this.entityType}-${this.user.id}`
                ) as string
            );
            localStorageParameters.availableFilters.forEach((filter: SearchEntityFilter) => {
                this.setFilterInUI(filter);
            });
        }
    }
}
