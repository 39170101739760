import i18n from '@/i18n';
import OfferItem from '@/models/OfferItem';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import OfferTitle from '@/models/OfferTitle';
import { OfferItemIdAndType } from '@/interfaces/components/ProductsInOffer/OfferItemIdAndType';
import { OfferItemRowNumber } from '@/interfaces/components/ProductsInOffer/OfferItemRowNumber';
import OfferItemRepository from '@/repositories/OfferItemRepository';
import { ProductAndProductFormId } from '@/interfaces/general/ProductAndProductFormId';
import { ProductTypes } from '@/enums/global/ProductTypes';
import { sortNumbers } from '@/helpers/SortingHelper';
import Offer from '@/models/Offer';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import ProjectRepository from '@/repositories/ProjectRepository';
import { formatCurrency } from '@/helpers/NumberFormatter';
import store from '@/store';
import { nextTick } from 'vue/types/umd';
import { has } from 'cypress/types/lodash';

function getColumnsForEditMode(shouldAllowMoveAndReorder: boolean, currencySymbol: string) {
    return [
        {
            rowReorder: shouldAllowMoveAndReorder,
            headerStyle: {
                width: '40px',
            },
        },
        {
            expander: true,
            field: 'expand',
            bodyClass: 'text-center',
            headerStyle: {
                width: '40px',
            },
        },
        {
            field: 'displayRowNumber',
            header: i18n.t('Br'),
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'rowNumber',
            headerStyle: {
                width: '55px',
            },
        },
        {
            field: 'position',
            header: i18n.t('Pos'),
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-left',
            headerStyle: {
                width: '80px',
            },
        },
        {
            field: 'name',
            header: i18n.t('Naziv') as string,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-left',
            headerClass: 'text-left',
        },
        {
            field: 'width',
            header: i18n.t('\u0160irina (CM)') as string,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '105px',
            },
        },
        {
            field: 'height',
            header: i18n.t('Visina (CM)') as string,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '105px',
            },
        },
        {
            field: 'quantity',
            header: i18n.t('Koli\u010dina') as string,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '90px',
            },
        },
        {
            field: 'finalPurchasePrice',
            header: `${i18n.t('Kupovna cijena')} (${currencySymbol})`,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-right',
            headerClass: 'text-right',
        },
        {
            field: 'finalSellingPrice',
            header: `${i18n.t('Prodajna cijena')} (${currencySymbol})`,
            sortable: shouldAllowMoveAndReorder,
            bodyClass: 'text-right',
            headerClass: 'text-right get-width',
        },
        {
            field: 'actions',
            headerStyle: {
                width: '40px',
            },
        },
        {
            field: 'selection',
            headerClass: 'text-right',
            bodyClass: 'text-right',
            headerStyle: {
                width: '40px',
            },
        },
    ];
}

function getColumnsForViewMode(currencySymbol: string, canUserViewPurchasePrice: boolean) {
    const columns = [
        {
            expander: true,
            field: 'expand',
            bodyClass: 'text-center',
            headerStyle: {
                width: '40px',
            },
        },
        {
            field: 'displayRowNumber',
            header: i18n.t('Br'),
            bodyClass: 'rowNumber',
            headerStyle: {
                width: '40px',
            },
        },
        {
            field: 'position',
            header: i18n.t('Pos'),
            bodyClass: 'text-left',
            headerStyle: {
                width: '80px',
            },
        },
        {
            field: 'name',
            header: i18n.t('Naziv') as string,
            bodyClass: 'text-left',
            headerClass: 'text-left',
        },
        {
            field: 'width',
            header: i18n.t('\u0160irina (CM)') as string,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '105px',
            },
        },
        {
            field: 'height',
            header: i18n.t('Visina (CM)') as string,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '105px',
            },
        },
        {
            field: 'quantity',
            header: i18n.t('Koli\u010dina') as string,
            bodyClass: 'text-center',
            headerClass: 'text-center',
            headerStyle: {
                width: '95px',
            },
        },
        {
            field: 'finalPurchasePrice',
            header: `${i18n.t('Kupovna cijena')} (${currencySymbol})`,
            bodyClass: 'text-right',
            headerClass: 'text-right',
        },
        {
            field: 'finalSellingPrice',
            header: `${i18n.t('Prodajna cijena')} (${currencySymbol})`,
            bodyClass: 'text-right',
            headerClass: 'text-right get-width',
        },
    ];

    if (!canUserViewPurchasePrice) {
        const priceIndex = columns.findIndex((item) => item.field === 'finalPurchasePrice');

        if (priceIndex !== -1) {
            columns.splice(priceIndex, 1);
        }
    }

    return columns;
}

export function getAppropriateColumnSettings({
    shouldBeAbleToMoveAndReorder,
    isEditMode,
    currencySymbol,
    canUserViewPurchasePrice = true,
    isNewProject = false,
}: any) {
    if (isEditMode) {
        return getColumnsForEditMode(shouldBeAbleToMoveAndReorder, currencySymbol);
    }

    if (isNewProject) {
        return getColumnsForViewMode(currencySymbol, canUserViewPurchasePrice);
    }
}

export function transformNumberToLocalFormat(number: number | string) {
    return formatCurrency(number);
}

export function transformTableOfferItems(
    offerItems: Array<OfferItem | OfferTitle>,
    projectId: string
): TransformedTableOfferItem[] {
    let currentUserGroup = Number(LocalStorageService.get(LocalStorageKeyNames.userGroup));
    let projectUserGroup = ProjectRepository.getProjectById(projectId)?.userGroupId;
    return offerItems.map((offerItem: OfferItem | OfferTitle) => {
        let offerItemWidth;
        let offerItemHeight;
        let offerItemQuantity;
        let offerItemName;
        let offerItemType;
        let offerItemPosition = '-';
        if (offerItem instanceof OfferItem) {
            if (offerItem.offerItemOfferItemType === ProductTypes.MATERIAL) {
                offerItemWidth = offerItem.offerItemFormData.find(
                    (entry: ChecklistFieldEntry) => entry.id === 'x'
                ).value;
                offerItemHeight = offerItem.offerItemFormData.find(
                    (entry: ChecklistFieldEntry) => entry.id === 'y'
                ).value;
                offerItemQuantity = offerItem.offerItemFormData.find(
                    (entry: ChecklistFieldEntry) => entry.id === 'q'
                ).value;
                offerItemName = offerItem.offerItemMaterial.description;
                offerItemType = offerItem.offerItemOfferItemType;
            } else {
                // tslint:disable-next-line:max-line-length
                const offerItemPositionObject = offerItem.offerItemFormData.find(
                    (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.POSITION_PID
                );
                // tslint:disable-next-line:max-line-length
                offerItemWidth =
                    offerItem.offerItemFormData.find(
                        (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.FINAL_WIDTH_PID
                    )?.value ??
                    offerItem.offerItemFormData.find(
                        (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.WIDTH_PID
                    ).value;
                // tslint:disable-next-line:max-line-length
                offerItemHeight =
                    offerItem.offerItemFormData.find(
                        (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.FINAL_HEIGHT_PID
                    )?.value ??
                    offerItem.offerItemFormData.find(
                        (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.HEIGHT_PID
                    ).value;
                // tslint:disable-next-line:max-line-length
                offerItemQuantity = offerItem.offerItemFormData.find(
                    (entry: ChecklistFieldEntry) => entry.id === GlobalOptions.QUANTITY_PID
                ).value;
                offerItemPosition = offerItemPositionObject != null ? offerItemPositionObject.value : '';
                offerItemName = offerItem.name;
                offerItemType = offerItem.offerItemOfferItemType;
            }
        } else {
            offerItemWidth = '';
            offerItemHeight = '';
            offerItemQuantity = '';
            offerItemName = offerItem.description;
            offerItemPosition = '';
            offerItemType = 'offerTitle';
        }
        const offerItemData = (offerItem as OfferItem).offerItemPrice;
        const offerItemInstanceOfOfferTitle = isOfferItemInstanceOfOfferTitle(offerItem);
        const finalSellingPrice = offerItemInstanceOfOfferTitle ? '' : offerItemData.finalSellingPrice;
        const finalPurchasePrice = offerItemInstanceOfOfferTitle ? '' : offerItemData.finalPurchasePrice;
        const finalRoltekPrice = offerItemInstanceOfOfferTitle ? '' : offerItemData.finalRoltekPrice;

        if (currentUserGroup == 1 || currentUserGroup == 2) {
            //ROLTEK
            return {
                id: offerItem.id,
                key: `${offerItem.id}-${offerItemType}`,
                name: offerItemName,
                finalSellingPrice: transformNumberToLocalFormat(finalPurchasePrice),
                finalPurchasePrice: transformNumberToLocalFormat(finalRoltekPrice),
                width: offerItemWidth == null ? '-' : offerItemWidth,
                height: offerItemHeight == null ? '-' : offerItemHeight,
                quantity: offerItemQuantity == null ? '-' : offerItemQuantity,
                productDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemProductDetails,
                displayRowNumber: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).displayRowNumber,
                purchasePriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceDetails,
                sellingPriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceDetails,
                rowNumber: offerItem.rowNumber,
                position: offerItemPosition,
                hasErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).hasErrors,
                allowErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).allowErrors,
                type: offerItemType,
                sellingPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceManipulationDetails || [],
                purchasePriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceManipulationDetails || [],
                roltekPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceManipulationDetails || [],
                validWarranty: (offerItem as OfferItem).validWarranty,
                productFormId:
                    offerItemType == 'product' ? parseInt((offerItem as OfferItem).productSystem.product.id) : 0,
            };
        } else if (currentUserGroup == projectUserGroup) {
            // B2B gleda svoje recorde
            return {
                id: offerItem.id,
                key: `${offerItem.id}-${offerItemType}`,
                name: offerItemName,
                finalSellingPrice: transformNumberToLocalFormat(finalSellingPrice),
                finalPurchasePrice: transformNumberToLocalFormat(finalPurchasePrice),
                width: offerItemWidth == null ? '-' : offerItemWidth,
                height: offerItemHeight == null ? '-' : offerItemHeight,
                quantity: offerItemQuantity == null ? '-' : offerItemQuantity,
                productDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemProductDetails,
                displayRowNumber: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).displayRowNumber,
                sellingPriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.sellingPriceDetails,
                purchasePriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceDetails,
                rowNumber: offerItem.rowNumber,
                position: offerItemPosition,
                hasErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).hasErrors,
                allowErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).allowErrors,
                type: offerItemType,
                sellingPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.sellingPriceManipulationDetails || [],
                purchasePriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceManipulationDetails || [],
                roltekPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceManipulationDetails || [],
                validWarranty: (offerItem as OfferItem).validWarranty,
                productFormId:
                    offerItemType == 'product' ? parseInt((offerItem as OfferItem).productSystem.product.id) : 0,
            };
        } else {
            return {
                id: offerItem.id,
                key: `${offerItem.id}-${offerItemType}`,
                name: offerItemName,
                finalSellingPrice: transformNumberToLocalFormat(finalRoltekPrice),
                finalPurchasePrice: transformNumberToLocalFormat(finalPurchasePrice),
                width: offerItemWidth == null ? '-' : offerItemWidth,
                height: offerItemHeight == null ? '-' : offerItemHeight,
                quantity: offerItemQuantity == null ? '-' : offerItemQuantity,
                productDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemProductDetails,
                displayRowNumber: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).displayRowNumber,
                purchasePriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceDetails,
                sellingPriceDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? null
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceDetails,
                rowNumber: offerItem.rowNumber,
                position: offerItemPosition,
                hasErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).hasErrors,
                allowErrors: isOfferItemInstanceOfOfferTitle(offerItem) ? false : (offerItem as OfferItem).allowErrors,
                type: offerItemType,
                sellingPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceManipulationDetails || [],
                purchasePriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.purchasePriceManipulationDetails || [],
                roltekPriceManipulationDetails: isOfferItemInstanceOfOfferTitle(offerItem)
                    ? []
                    : (offerItem as OfferItem).offerItemPrice.roltekPriceManipulationDetails || [],
                validWarranty: (offerItem as OfferItem).validWarranty,
                productFormId:
                    offerItemType == 'product' ? parseInt((offerItem as OfferItem).productSystem.product.id) : 0,
            };
        }
    });
}

export async function checkErrorStateForOfferItems(offerItems: Array<OfferItem>) {
    if (!offerItems.length) {
        return;
    }

    checkIfOfferItemsHasInvalidPrice(offerItems);

    const existingError = offerItems.some((item: OfferItem) => {
        return item.hasErrors && !item.allowErrors;
    });

    const existingWarning = offerItems.some((item: OfferItem) => {
        return (item.hasErrors && item.allowErrors && !item.validWarranty) || (!item.hasErrors && !item.validWarranty);
    });

    return { existingError, existingWarning };
}

function checkIfOfferItemsHasInvalidPrice(offerItems: OfferItem[]) {
    let checkIfOfferItemsHasInvalidPrice = false;

    offerItems.forEach((offerItem) => {
        const hasErrors = checkIfOfferItemHasInvalidPrice(offerItem);

        if (hasErrors) {
            checkIfOfferItemsHasInvalidPrice = hasErrors;
        }
    });

    store.dispatch('configurator/updateOfferPriceValidState', checkIfOfferItemsHasInvalidPrice);
}

function checkIfOfferItemHasInvalidPrice(offerItem: OfferItem) {
    let finalPurchasePrice = parseInt(offerItem?.offerItemPrice?.finalPurchasePrice);
    let finalRoltekPrice = parseInt(offerItem?.offerItemPrice?.finalRoltekPrice);
    let finalSellingPrice = parseInt(offerItem?.offerItemPrice?.finalSellingPrice);
    let pricePerUnitPurchase = parseInt(offerItem?.offerItemPrice?.pricePerUnitPurchase);
    let pricePerUnitRoltek = parseInt(offerItem?.offerItemPrice?.pricePerUnitRoltek);
    let pricePerUnitSelling = parseInt(offerItem?.offerItemPrice?.pricePerUnitSelling);
    let originalPurchasePrice = parseInt(offerItem?.offerItemPrice?.purchasePriceDetails[0]?.priceDetailAmount);
    let originalSellingPrice = parseInt(offerItem?.offerItemPrice?.sellingPriceDetails[0]?.priceDetailAmount);
    let originalRoltekPrice = parseInt(offerItem?.offerItemPrice?.roltekPriceDetails[0]?.priceDetailAmount);

    const hasErrors =
        finalPurchasePrice === 0 ||
        finalRoltekPrice === 0 ||
        finalSellingPrice === 0 ||
        pricePerUnitPurchase === 0 ||
        pricePerUnitRoltek === 0 ||
        pricePerUnitSelling === 0 ||
        originalPurchasePrice === 0 ||
        originalSellingPrice === 0 ||
        originalRoltekPrice === 0;

    if (hasErrors) {
        offerItem.hasErrors = hasErrors;
    }

    return hasErrors;
}

function isOfferItemInstanceOfOfferTitle(offerItem: OfferItem | OfferTitle | TransformedTableOfferItem) {
    return offerItem instanceof OfferTitle;
}

export function extractOfferItemIds(allOfferItems: Array<OfferItem | OfferTitle>): string[] {
    return filterProductsFromOfferItems(allOfferItems).map((offerItem) => {
        const fetchedOfferItem = extractOfferItem(offerItem);

        return (fetchedOfferItem as OfferItem).offerItemId;
    });
}

export function mapAllOfferItemsForUpdatingRowNumbers(
    allOfferItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>
): OfferItemIdAndType[] {
    return allOfferItems.map((offerItem: OfferItem | OfferTitle | TransformedTableOfferItem) => {
        let type = (offerItem as TransformedTableOfferItem).type;
        if (offerItem instanceof OfferItem) {
            type = offerItem.offerItemOfferItemType;
        } else if (offerItem instanceof OfferTitle) {
            type = 'offerTitle';
        }
        return {
            id: offerItem.id,
            type,
        };
    });
}

export function extractAndFetchProductsAndProductFormIds(allOfferItems: Array<OfferItem | OfferTitle>) {
    const productAndProductFormIds: ProductAndProductFormId[] = [];

    filterProductsFromOfferItems(allOfferItems).forEach((offerItem) => {
        const fetchedOfferItem = extractOfferItem(offerItem);

        productAndProductFormIds.push({
            productFormId: (fetchedOfferItem as OfferItem).offerItemProductSystem.product.productForm.id,
            productId: (fetchedOfferItem as OfferItem).offerItemProductSystem.product.id,
        });
    });

    return productAndProductFormIds;
}

export function filterProductsFromOfferItems(
    allOfferItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>,
    shouldIncludeMaterials = false
) {
    return allOfferItems.filter((offerItem) => {
        if (
            (offerItem as TransformedTableOfferItem).type &&
            ((offerItem as TransformedTableOfferItem).type === ProductTypes.PRODUCT ||
                (shouldIncludeMaterials && (offerItem as TransformedTableOfferItem).type === ProductTypes.MATERIAL))
        ) {
            return true;
        }

        const isOfferItemProductSystem = offerItem instanceof OfferItem && offerItem.offerItemProductSystem != null;
        const isOfferItemMaterial = offerItem instanceof OfferItem && offerItem.offerItemMaterial != null;

        return isOfferItemProductSystem || (shouldIncludeMaterials && isOfferItemMaterial);
    });
}

function extractOfferItem(offerItem: TransformedTableOfferItem | OfferItem | OfferTitle) {
    if (offerItem instanceof OfferItem) {
        return offerItem;
    }
    return OfferItemRepository.getById(offerItem.id);
}

export function convertRowNumberAndIdToKeyValuePairs(allOfferItems: TransformedTableOfferItem[]) {
    const offerItemIdsAndRowNumbers: { [key: string]: string } = {};

    allOfferItems.forEach((pendingActionOfferItem) => {
        offerItemIdsAndRowNumbers[pendingActionOfferItem.rowNumber] = pendingActionOfferItem.id;
    });

    return offerItemIdsAndRowNumbers;
}

export function filterOutCorrectOfferItemFromPendingAction(
    pendingAction: TransformedTableOfferItem[],
    offerItem: TransformedTableOfferItem
) {
    return pendingAction.filter((pendingActionOfferItem) => {
        return !(
            pendingActionOfferItem.id === offerItem.id && pendingActionOfferItem.rowNumber === offerItem.rowNumber
        );
    });
}

export async function duplicateTheCorrectEntity(offerItemType: string, offerItemId: string) {
    if (offerItemType !== 'offerTitle') {
        await OfferItem.duplicateExisting(offerItemId);
    } else {
        await OfferTitle.duplicateExisting(offerItemId);
    }
}

export async function deleteTheCorrectEntity(offerItemType: string, offerItemId: string) {
    if (offerItemType === 'offerTitle') {
        await OfferTitle.deleteExisting(offerItemId);
    } else {
        await OfferItem.deleteExisting(offerItemId);
    }
}

export function generateArraysForUpdatingRowNumbers(
    allOfferItems: Array<TransformedTableOfferItem | OfferItemIdAndType>
) {
    const offerItemRowNumbers: OfferItemRowNumber[] = [];
    const offerTitleRowNumbers: OfferItemRowNumber[] = [];

    allOfferItems.slice().forEach((offerItem, index) => {
        if (offerItem.type !== 'offerTitle') {
            offerItemRowNumbers.push({
                rowNumber: index + 1,
                id: offerItem.id,
            });
        } else {
            offerTitleRowNumbers.push({
                rowNumber: index + 1,
                id: offerItem.id,
            });
        }
    });

    return {
        offerItemRowNumbers,
        offerTitleRowNumbers,
    };
}

/**
 * Filters offer titles from all offer items
 * @param allOfferItems - All offer items on an offer
 * @return All offer titles if there are any
 */
export function filterOfferTitlesFromOfferItems(
    allOfferItems: Array<OfferItem | OfferTitle | TransformedTableOfferItem>
) {
    return allOfferItems.filter((offerItem) => {
        return isOfferItemInstanceOfOfferTitle(offerItem);
    });
}

/**
 * Sort all offer items and titles by row number in the offer
 * @param offer - The offer entity
 * @return - The sorted offer items and titles by row number
 */
export function sortOfferItemsAndTitlesByRowNumber(offer: null | Offer) {
    if (offer == null) {
        return [];
    }

    return [...offer.offerItems, ...offer.offerTitles].sort((a, b) => {
        return sortNumbers(a.rowNumber, b.rowNumber);
    });
}
