import { Model } from '@vuex-orm/core';

export default class OfferItemPrice extends Model {
    public static entity = 'offerItemPrice';

    public static fields() {
        return {
            id: this.attr(null),
            finalSellingPrice: this.number(''),
            finalPurchasePrice: this.number(''),
            finalRoltekPrice: this.number(''),
            pricePerUnitSelling: this.number(''),
            pricePerUnitPurchase: this.number(''),
            pricePerUnitRoltek: this.number(''),
            discountPercentage: this.number(''),          
            sellingPriceDetails: this.attr([]),
            purchasePriceDetails: this.attr([]),
            roltekPriceDetails: this.attr([]),
            priceDate: this.string(''),
            sellingPriceManipulationDetails: this.attr([]),
            purchasePriceManipulationDetails: this.attr([]),
            roltekPriceManipulationDetails: this.attr([]),
        };
    }

    public id!: string;
    public finalSellingPrice!: string;
    public finalPurchasePrice!: string;
    public finalRoltekPrice!: string;
    public pricePerUnitSelling!: string;
    public pricePerUnitPurchase!: string;
    public pricePerUnitRoltek!: string;
    public discountPercentage!: string;
    public priceDate!: string;
    public sellingPriceDetails!: any[];
    public purchasePriceDetails!: any[];
    public roltekPriceDetails!: any[];
    public sellingPriceManipulationDetails!: any[];
    public purchasePriceManipulationDetails!: any[];
    public roltekPriceManipulationDetails!: any[];
    
}

