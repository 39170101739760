import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';

export default class ClientAPI {
    public static async getAll() {
        let clients;
        try {
            clients = (await ApiController.get(ApiRoutes.client.getAll)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async getById(clientId: string) {
        let client;

        try {
            client = (await ApiController.get(ApiRoutes.client.getSingle(clientId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(client);
    }

    public static async getByIdForConfigurator(clientId: string) {
        let client;

        try {
            client = (await ApiController.get(ApiRoutes.client.getSingleForConfigurator(clientId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(client);
    }

    public static async getSingleForClientScreen(clientId: string) {
        let client;

        try {
            client = (await ApiController.get(ApiRoutes.client.getSingleForClientScreen(clientId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(client);
    }

    public static async delete(clientId: string) {
        try {
            await ApiController.delete(ApiRoutes.client.delete(clientId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async updateExisting(clientId: string, clientInfo: AvailableClientFields) {
        let updatedClient;

        const payload = {
            data: {
                type: 'clients',
                id: clientId,
                attributes: {
                    name: clientInfo.name,
                    fax: clientInfo.fax,
                    additionalNotes: clientInfo.additionalNotes,
                    currency: clientInfo.currency,
                    bankCode: clientInfo.bankCode,
                    taxId: clientInfo.taxId,
                    isTaxPayer: clientInfo.isTaxPayer,
                    registerId: clientInfo.registerId,
                    isCompany: clientInfo.isCompany,
                    webpage: clientInfo.isCompany ? clientInfo.webpage : '',
                    bigLogo: clientInfo.bigLogo,
                    squareLogo: clientInfo.squareLogo,
                },
                relationships: {},
            },
        };

        if (clientInfo.contactPersons && clientInfo.contactPersons.length > 0) {
            const contactIdObjects = clientInfo.contactPersons.map((contactPersonId: string) => {
                return {
                    type: 'contact_persons',
                    id: contactPersonId,
                };
            });
            // @ts-ignore
            payload.data.relationships.contactPersons = {
                data: contactIdObjects,
            };
        }

        if (clientInfo.currency) {
            // @ts-ignore
            payload.data.relationships.currency = {
                data: {
                    type: 'currencies',
                    id: clientInfo.currency,
                },
            };
        }

        if (clientInfo.backofficeUser) {
            // @ts-ignore
            payload.data.relationships.backofficeUser = {
                data: {
                    type: 'users',
                    id: clientInfo.backofficeUser,
                },
            };
        }

        if (clientInfo.priceListRegion) {
            // @ts-ignore
            payload.data.relationships.priceListRegion = {
                data: {
                    type: 'price_list_regions',
                    id: clientInfo.priceListRegion,
                },
            };
        }

        if (clientInfo.priceType) {
            // @ts-ignore
            payload.data.relationships.priceType = {
                data: {
                    type: 'price_types',
                    id: clientInfo.priceType,
                },
            };
        }

        if (clientInfo.address) {
            // @ts-ignore
            payload.data.relationships.address = {
                data: {
                    type: 'addresses',
                    id: clientInfo.address,
                },
            };
        }

        if (clientInfo.deliveryAddress) {
            // @ts-ignore
            payload.data.relationships.deliveryAddress = {
                data: {
                    type: 'addresses',
                    id: clientInfo.deliveryAddress,
                },
            };
        }

        if (clientInfo.invoiceAddress) {
            // @ts-ignore
            payload.data.relationships.invoiceAddress = {
                data: {
                    type: 'addresses',
                    id: clientInfo.invoiceAddress,
                },
            };
        }

        if (clientInfo.clientTypes && clientInfo.clientTypes.length > 0) {
            const clientTypeObjectArray = clientInfo.clientTypes.map((clientTypeId: string) => {
                return {
                    type: 'client_types',
                    id: clientTypeId,
                };
            });
            // @ts-ignore
            payload.data.relationships.clientTypes = {
                data: clientTypeObjectArray,
            };
        } else {
            if (clientInfo.clientTypes) {
                // @ts-ignore
                payload.data.relationships.clientTypes = {
                    data: [],
                };
            }
        }

        if (clientInfo.assignedUser) {
            // @ts-ignore
            payload.data.relationships.assignedUser = {
                data: {
                    type: 'users',
                    id: clientInfo.assignedUser,
                },
            };
        }

        if (clientInfo.paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: clientInfo.paymentType,
                },
            };
        }

        if (clientInfo.leadOrigin) {
            // @ts-ignore
            payload.data.relationships.origin = {
                data: {
                    type: 'lead_origins',
                    id: clientInfo.leadOrigin,
                },
            };
        }

        try {
            updatedClient = (await ApiController.patch(
                ApiRoutes.client.updateExisting(clientId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedClient);
    }

    public static async updatePaymentType(clientId: string, clientInfo: AvailableClientFields) {
        let updatedClient;

        const payload = {
            data: {
                type: 'clients',
                id: clientId,
                attributes: {},
                relationships: {},
            },
        };

        if (clientInfo.paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: clientInfo.paymentType,
                },
            };
        }

        try {
            updatedClient = (await ApiController.patch(
                ApiRoutes.client.updateExisting(clientId),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedClient);
    }

    public static async createNew(clientInfo: AvailableClientFields) {
        let newClient;

        const payload = {
            data: {
                type: 'clients',
                attributes: {
                    name: clientInfo.name,
                    fax: clientInfo.fax,
                    additionalNotes: clientInfo.additionalNotes,
                    currency: clientInfo.currency == null ? 1 : clientInfo.currency,
                    bankCode: clientInfo.bankCode,
                    taxId: clientInfo.taxId,
                    isTaxPayer: clientInfo.isTaxPayer,
                    registerId: clientInfo.registerId,
                    isCompany: clientInfo.isCompany,
                    backofficeUser: clientInfo.backofficeUser,
                    webpage: clientInfo.isCompany ? clientInfo.webpage : '',
                    bigLogo: null,
                    squareLogo: null,
                },
                relationships: {},
            },
        };

        if (clientInfo.contactPersons.length > 0) {
            const contactIdObjects = clientInfo.contactPersons.map((contactPersonId: string) => {
                return {
                    type: 'contact_persons',
                    id: contactPersonId,
                };
            });
            // @ts-ignore
            payload.data.relationships.contactPersons = {
                data: contactIdObjects,
            };
        }

        if (clientInfo.currency) {
            // @ts-ignore
            payload.data.relationships.currency = {
                data: {
                    type: 'currencies',
                    id: clientInfo.currency,
                },
            };
        }

        if (clientInfo.priceListRegion) {
            // @ts-ignore
            payload.data.relationships.priceListRegion = {
                data: {
                    type: 'price_list_regions',
                    id: clientInfo.priceListRegion,
                },
            };
        }

        if (clientInfo.priceType) {
            // @ts-ignore
            payload.data.relationships.priceType = {
                data: {
                    type: 'price_types',
                    id: clientInfo.priceType,
                },
            };
        }

        if (clientInfo.clientTypes.length > 0) {
            const clientTypeObjectArray = clientInfo.clientTypes.map((clientTypeId: string) => {
                return {
                    type: 'client_types',
                    id: clientTypeId,
                };
            });
            // @ts-ignore
            payload.data.relationships.clientTypes = {
                data: clientTypeObjectArray,
            };
        }

        if (clientInfo.address) {
            // @ts-ignore
            payload.data.relationships.address = {
                data: {
                    type: 'addresses',
                    id: clientInfo.address,
                },
            };
        }

        if (clientInfo.deliveryAddress) {
            // @ts-ignore
            payload.data.relationships.deliveryAddress = {
                data: {
                    type: 'addresses',
                    id: clientInfo.deliveryAddress,
                },
            };
        }

        if (clientInfo.invoiceAddress) {
            // @ts-ignore
            payload.data.relationships.invoiceAddress = {
                data: {
                    type: 'addresses',
                    id: clientInfo.invoiceAddress,
                },
            };
        }

        if (clientInfo.assignedUser) {
            // @ts-ignore
            payload.data.relationships.assignedUser = {
                data: {
                    type: 'users',
                    id: clientInfo.assignedUser,
                },
            };
        }

        if (clientInfo.paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: clientInfo.paymentType,
                },
            };
        }

        if (clientInfo.leadOrigin) {
            // @ts-ignore
            payload.data.relationships.origin = {
                data: {
                    type: 'lead_origins',
                    id: clientInfo.leadOrigin,
                },
            };
        }

        try {
            newClient = (await ApiController.post(ApiRoutes.client.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newClient);
    }

    public static async search(query: string) {
        let clients;

        try {
            clients = await ApiController.get(ApiRoutes.client.search(query));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async searchNew(query: string) {
        let clients;

        try {
            clients = await ApiController.get(ApiRoutes.client.searchNew(query));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async validateEmail(input: string) {
        let clients;

        try {
            clients = await ApiController.get(ApiRoutes.client.validateEmail(input));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async validateClient(
        inputEmail: string,
        inputPhone: string,
        inputName: string,
        inputAddress: string,
        inputTaxId: string
    ) {
        let clients;
        try {
            let input;
            if (inputTaxId === null) {
                input =
                    '(contactPersons.email:' +
                    inputEmail +
                    '|contactPersons.phone:' +
                    inputPhone +
                    '|(name:' +
                    inputName +
                    '^address.streetNameAndNumber:' +
                    inputAddress +
                    '))';
            } else {
                input =
                    '(contactPersons.email:' +
                    inputEmail +
                    '|contactPersons.phone:' +
                    inputPhone +
                    '|(name:' +
                    inputName +
                    '^address.streetNameAndNumber:' +
                    inputAddress +
                    ')|taxId:' +
                    inputTaxId +
                    ')';
            }
            clients = await ApiController.get(ApiRoutes.client.validateClient(input));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async fetchByPaymentType(paymentTypeId: string) {
        let clients;
        try {
            clients = (await ApiController.get(ApiRoutes.client.fetchByPaymentType(paymentTypeId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async filterClients(query: string) {
        let clients;
        try {
            clients = (await ApiController.get(ApiRoutes.client.filterClients(query))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(clients);
    }

    public static async sendToIngenious(clientId: string) {
        try {
            await ApiController.post(ApiRoutes.client.sendToIngenious(clientId), {});
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
