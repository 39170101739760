
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NotificationNavigation } from '@/interfaces/general/NotificationNavigation';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';
import { GlobalEntities } from '@/enums/global/GlobalEntities';

@Component({
    name: 'CustomNavLink',
})
export default class CustomNavLink extends Vue {
    @Prop({ required: true }) private routingData!: NotificationNavigation;

    private routeNames = RouteNames;
    private clientTabs = ClientTabs;
    private globalEntities = GlobalEntities;

    private get nav() {
        return this.routingData;
    }

    private get navTab() {
        if (!this.nav.tab) {
            return '';
        }

        switch (this.nav.entity) {
            case 'projects':
                return this.findProjectTab(this.nav.tab);
            case 'clients':
                return this.findClientTab(this.nav.tab);
            default:
                return '';
        }
    }

    private get navigationText() {
        if (!this.nav) {
            return '';
        }

        switch (this.nav.entity) {
            case 'projects':
                return `Project ${this.nav.id}`;
            case 'clients':
                return `Client ${this.nav.id}`;
            default:
                return 'Check';
        }
    }

    private findProjectTab(tab: string) {
        switch (tab) {
            case 'basic':
                return '0';
            case 'lead':
                return '1';
            case 'products':
                return '2';
            case 'orders':
                return '3';
            case 'payments':
                return '4';
            default:
                return tab;
        }
    }

    private findClientTab(tab: string) {
        return tab;
    }
}
