export enum EventBusEvents {
    openAddProductsPopup = 'openAddProductsPopup',
    openMaterialsPopup = 'openMaterialsPopup',
    openMultipositionPopup = 'openMultipositionPopup',
    openDuplicateClientPopup = 'openDuplicateClientPopup',
    openAddOfferTitlePopup = 'openAddOfferTitlePopup',
    closePopup = 'closePopup',
    updateLeadInquiry = 'leadDescriptionUpdate',
    updateProject = 'updateProject',
    onProductsChangesMade = 'onProductsChangesMade',
    updateStoreFieldValue = 'updateStoreFieldValue',
    triggerVisibilityFunctions = 'triggerVisibilityFunctions',
    triggerOptionsFunctions = 'triggerOptionsFunctions',
    editConfiguratorItem = 'editConfiguratorItem',
    updateClientFormItem = 'updateClientFormItem',
    updateClientInfo = 'updateClientInfo',
    saveClientInfo = 'saveClientInfo',
    deleteDiscounts = 'deleteDiscounts',
    dropDownValueSelect = 'dropdownValueSelect',
    emitProjectPagination = 'emitProjectPagination',
    emitClientPagination = 'emitClientPagination',
    onUpdateProject = 'onUpdateProject',
    updateSaveButtonState = 'updateSaveButtonState',
    openEntityIndex = 'openEntityIndex',
    runEntitySearch = 'runEntitySearch',
    runEntitySearchWithParameters = 'runEntitySearchWithParameters',
    cmsError = 'cmsError',
    toggleViewMode = 'toggleViewMode',
    updateSortedInfo = 'updateSortedInfo',
    onPrintLeadPDF = 'onPrintLeadPDF',
    openPriceListOutOfRangePopup = 'openPriceListOutOfRangePopup',
    openContactPopup = 'openPopupSlot',
    closeContactPopup = 'closePopupSlot',
    toggleProjectDeleteMode = 'toggleProjectDeleteMode',
    deleteSelectedEntities = 'deleteSelectedEntities',
    updateIsDeleteMode = 'updateIsDeleteMode',
    openDocumentAndPdfTypeSlot = 'openDocumentAndPdfTypeSlot',
    createOfferFromLead = 'createOfferFromLead',
    refetchHome = 'refetchHome',
    duplicateValueDetected = 'duplicateValueDetected',
    updateUserGroup = 'updateUserGroup',
    changesInDataMade = 'changesInDataMade',
    deselectSelectedProductSystem = 'deselectSelectedProductSystem',
    onDeleteContactPerson = 'onDeleteContactPerson',
    onDeleteClientPerson = 'onDeleteClientPerson',
    checkAndSetDuplicateContactEmails = 'checkAndSetDuplicateContactEmails',
    resetOfferItemsState = 'resetOfferItemsState',
    onUpdateNextStepData = 'onUpdateNextStepData',
    onUpdateSelectedOffer = 'onUpdateSelectedOffer',
    runClientSearch = 'runClientSearch',
    toggleFilters = 'toggleFilters',
    searchFinished = 'searchFinished',
    openChatProject = 'openChatProject',
    showCreateActivityForm = 'showCreateActivityForm',
    clearContent = 'clearContent',
    onOfferItemPriceManipulation = 'onOfferItemPriceManipulation',
    updateProjectTabActiveKey = 'updateProjectTabActiveKey',
    emitOpenPopup = 'emitOpenPopup',
    emitTranslationPagination = 'emitTranslationPagination',
    openRecalculationPopup = 'openRecalculationPopup',
    closeRecalculationPopup = 'closeRecalculationPopup',
    updateWorkflowReadOnlyModalVisibility = 'updateWorkflowReadOnlyModalVisibility',
    updateProjectLog = 'updateProjectLog',
    updateOfferData = 'updateOfferData',
    addressFormValueChange = 'addressFormValueChange',
    finishCreatingOfferItemPriceManipulations = 'finishCreatingOfferItemPriceManipulations',
    fetchProjectFromRepository = 'fetchProjectFromRepository',
    fetchSelectedOfferFromRepository = 'fetchSelectedOfferFromRepository',
    onOpenPdfPreviewOfferPopup = 'onOpenPdfPreviewOfferPopup',
    onOpenPdfPreviewOrderPopup = 'onOpenPdfPreviewOrderPopup',
    onOpenPreviewFilePopup = 'onOpenPreviewFilePopup',
    changeTabValue = 'changeTabValue',
    updateClientProjectTab = 'updateClientProjectTab',
    createNewUserButton = 'createNewUserButton',
    saveAllTranslations = 'saveAllTranslations',
    saveAllUserSettings = 'saveAllUserSettings',
    printActivities = 'printActivities',
    calculatorPurchasePrice = 'calculatorPurchasePrice',
    calculatorClientChosen = 'calculatorClientChosen',
    calculatorCreateProject = 'calculatorCreateProject',
    calculatorProductData = 'calculatorProductData',
    toggleNewProjectModal = 'toggleNewProjectModal',
    openProjectsModal = 'openProjectsModal',
    toggleNewClientModal = 'toggleNewClientModal',
    updateClient = 'updateClient',
    closeNewClientPopup = 'closeNewClientPopup',
    showNotification = 'showNotification',
    clearNotifications = 'clearNotifications',
    clearCalculator = 'clearCalculator',
    formatDataForNewStatus = 'formatDataForNewStatus',
    fetchNewStatusEvent = 'fetchNewStatusEvent',
    openActivityModal = 'openActivityModal',
    createNewActivity = 'createNewActivity',
    openActivityNewModalOnStatusChange = 'openActivityNewModalOnStatusChange',
    fetchedNextStepTemplate = 'fetchedNextStepTemplate',
    openCreateNewEventPopup = 'openCreateNewEventPopup',
    openActivitiesProjectsModal = 'openActivitiesProjectsModal',
    deleteChart = 'deleteChart',
    createNewsReport = 'createNewsReport',
    createStatisticsReport = 'createStatisticsReport',
    downloadReport = 'downloadReport',
    uploadNewsAttachment = 'uploadNewsAttachment',
    searchParametersChanged = 'searchParametersChanged',
    changeCurrentDashboardUser = 'changeCurrentDashboardUser',
    projectsFromFilterChanged = 'projectsFromFilterChanged',
    showOrderTab = 'showOrderTab',
    formatContactsAddress = 'formatContactsAddress',
    print = 'print',
    getClientInformation = 'getClientInformation',
    clientInformationConfirmed = 'clientInformationConfirmed',
    clientInformationCanceled = 'clientInformationCanceled',
    updateClientData = 'updateClientData',
    checkStringFieldsErrorStatus = 'checkStringFieldsErrorStatus',
    setStringFieldErrorState = 'setStringFieldErrorState',
    setNewProjectInCalendar = 'setNewProjectInCalendar',
    removeDeletedClientFromTable = 'removeDeletedClientFromTable',
    cancelDeletingClients = 'cancelDeletingClients',
    setProductFieldErrorState = 'setProductFieldErrorState',
    validateFormFields = 'validateFormFields',
    formValidationFinished = 'formValidationFinished',
    toggleEditingForProductsInOffer = 'toggleEditingForProductsInOffer',
    closeCreateOfferPopup = 'closeCreateOfferPopup',
    resetChatInputForm = 'resetChatInputForm',
    removeSavedMultipositionSegment = 'removeSavedMultipositionSegment',
    toggleCustomMenu = 'toggleCustomMenu',
    showCelebrationModal = 'showCelebrationModal',
    openDimensionsWizard = 'openDimensionsWizard',
    setDashboardPrintOption = 'setDashboardPrintOption',
    prepareTablesForPrinting = 'prepareTablesForPrinting',
    openCombinationsSelectorPopup = 'openCombinationsSelectorPopup',
    openCombinationsEditPopup = 'openCombinationsEditPopup',
    updateWidthOfProductSlotWrappers = 'updateWidthOfProductSlotWrappers',
    resetCombinationProductSelectionStatus = 'resetCombinationProductSelectionStatus',
    openCombinationsWizard = 'openCombinationsWizard',
    initializeClientAttachments = 'initializeClientAttachments',
    updateClientCachedData = 'updateClientCachedData',
    saveClientWithDuplicates = 'saveClientWithDuplicates',
}
