import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProjectStateAPI {
    public static async getAll() {
        let projectStates;

        try {
            projectStates = await ApiController.get(ApiRoutes.projectStates.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectStates);
    }
}
