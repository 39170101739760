import IntroScreen from '@/models/IntroScreen';

export default class IntroScreenRepository {
    public static getAll() {
        return IntroScreen.all();
    }

    public static getById(id: string) {
        return IntroScreen.query().whereId(id).withAllRecursive().first();
    }

    public static delete(id: string) {
        return IntroScreen.delete(id);
    }

    public static getFirst() {
        return IntroScreen.query().withAllRecursive().first();
    }
}
