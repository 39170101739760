import Country from '../models/Country';

export default class CountryRepository {
    public static getAll() {
        return Country.all();
    }

    public static getById(id: string) {
        return Country.query()
            .whereId(id)
            .withAll()
            .first();
    }

    public static getByCode(code: string) {
        return Country.query()
            .where('code', code)
            .withAll()
            .first();
    }
}
