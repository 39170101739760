
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { NotificationField } from '@/interfaces/general/Notification';
import CustomNavLink from './CustomNavLink.vue';

@Component({
    name: 'Notification',
    components: { CustomNavLink },
})
export default class SingleNotification extends Vue {
    @Prop({ required: true }) private notificationData!: NotificationField;

    private get locale() {
        return getDatepickerLocale();
    }

    private get routingData() {
        return this.notificationData.navigation;
    }

    private get notificationDate() {
        return moment(new Date(this.notificationData.createdAt)).format(this.locale.lang.dateTimeFormat);
    }
}
