import DocumentName from '@/models/DocumentName';

export default class DocumentNameRepository {
    public static getAll() {
        return DocumentName.all();
    }

    public static getById(id: string) {
        return DocumentName.query().whereId(id).withAll().first();
    }
}
