import { Model } from '@vuex-orm/core';
import OrderPdfAPI from '@/api/OrderPdfAPI';
import DocumentName from '@/models/DocumentName';
import OrderConfiguration from '@/models/OrderConfiguration';
import OfferRepository from '@/repositories/OfferRepository';

export default class OrderPdf extends Model {
    public static entity = 'orderPdf';

    public static fields() {
        return {
            id: this.attr(''),
            path: this.string(''),
            filename: this.string(''),
            documentName_id: this.attr(null),
            orderConfiguration_id: this.attr(null),
            documentName: this.belongsTo(DocumentName, 'documentName_id'),
            orderConfiguration: this.belongsTo(OrderConfiguration, 'orderConfiguration_id'),
        };
    }

    public static async addToOffer(offerId: string, orderConfigurationId: number) {
        let pdf;
        try {
            pdf = await OrderPdfAPI.addPDF(offerId, orderConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: pdf.data,
        });
        return Promise.resolve(pdf.data);
    }

    public static async updateExisting(offerId: string, pdfId: string, orderConfigurationId: number) {
        let pdf;
        try {
            pdf = await OrderPdfAPI.updateExisting(offerId, pdfId, orderConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: pdf.data,
        });
        return Promise.resolve();
    }

    public static async previewOrderPDF(offerId: string, documentTypeId: number) {
        return await OrderPdfAPI.previewPDF(offerId, documentTypeId);
    }

    public static async getOrderPDF(fileURL: string) {
        let pdf;

        try {
            pdf = await OrderPdfAPI.getOrderPDF('files/order-pdf/' + fileURL);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(pdf);
    }

    public static async getInternalOrderPDF(fileURL: string) {
        let pdf;

        try {
            pdf = await OrderPdfAPI.getOrderPDF(fileURL);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(pdf);
    }

    public id!: string;
    public path!: string;
    public filename!: string;
    public documentName!: DocumentName;
    public orderConfiguration!: OrderConfiguration;
}
