var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDropzone),expression:"!showDropzone"}],on:{"dragover":function($event){return _vm.toggleDropzone(true)}}},[_c('a-row',[(_vm.attachment)?_c('div',[_c('a-row',{staticClass:"img-container"},[_c('img',{staticClass:"attachment-image",attrs:{"src":_vm.blob,"alt":""}})]),_c('a-col',{staticStyle:{"overflow":"hidden"},attrs:{"span":22}},[_c('a-button',{staticStyle:{"padding":"0"},attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.onPreview(_vm.attachment)}}},[_c('a-icon',{attrs:{"type":"file"}}),_vm._v(" "+_vm._s(_vm.attachment?.title ?? _vm.attachment?.name ?? '')+" ")],1)],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachment.id === _vm.loading),expression:"attachment.id === loading"}],attrs:{"span":2}},[_c('a-spin',{staticClass:"client-edit-tabs__loading"},[_c('a-icon',{attrs:{"slot":"indicator","type":"loading"},slot:"indicator"})],1)],1),_c('a-col',{attrs:{"span":2}},[_c('a-icon',{staticStyle:{"float":"right","margin-top":"3px"},attrs:{"type":"delete"},on:{"click":function($event){return _vm.onOpenRemoveModal(_vm.attachment)}}})],1)],1):_c('div',[_c('a-upload',{staticStyle:{"cursor":"pointer"},attrs:{"file-list":[],"customRequest":(data) => {
                            _vm.manualUpload(data.file);
                        },"list-type":"picture","multiple":false}},[_c('a-icon',{attrs:{"type":"plus"}})],1)],1)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropzone),expression:"showDropzone"}],staticClass:"wrapper",on:{"dragover":function($event){return _vm.toggleDropzone(true)},"dragleave":function($event){return _vm.toggleDropzone(false)},"drop":function($event){_vm.showDropzone = false}}},[_c('UploadDragger',{attrs:{"customRequest":(data) => {
                    _vm.manualUpload(data.file);
                },"fileList":[],"showUploadList":{
                showPreviewIcon: false,
                showDownloadIcon: false,
            },"listType":"picture","multiple":false,"name":"file"}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('Dovucite datoteke koje želite postaviti'))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }