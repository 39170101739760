import { render, staticRenderFns } from "./FloatingDeleteActions.vue?vue&type=template&id=cc262fa2&scoped=true"
import script from "./FloatingDeleteActions.vue?vue&type=script&lang=ts"
export * from "./FloatingDeleteActions.vue?vue&type=script&lang=ts"
import style0 from "./FloatingDeleteActions.vue?vue&type=style&index=0&id=cc262fa2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc262fa2",
  null
  
)

export default component.exports