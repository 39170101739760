import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';

export default class OrderPdfAPI {
    public static async addPDF(offerId: string, orderConfigurationId: number) {
        let pdf;
        const request = this.generatePayload(offerId, orderConfigurationId);
        try {
            pdf = await ApiController.post(ApiRoutes.orderPdf.add, request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async updateExisting(offerId: string, pdfId: string, orderConfigurationId: number) {
        let pdf;
        const request = this.generatePayload(offerId, orderConfigurationId, pdfId);
        try {
            pdf = await ApiController.patch(ApiRoutes.orderPdf.update(pdfId), request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async previewPDF(offerId: string, documentTypeId: number) {
        let pdf;

        const request = this.generatePayload(offerId, documentTypeId);
        try {
            pdf = await ApiController.post(ApiRoutes.orderPdf.preview, request);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(pdf);
    }

    public static async getOrderPDF(pdfURL: string) {
        let pdf;

        try {
            pdf = await ApiController.get(pdfURL, null, true);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(pdf);
    }

    private static generatePayload(offerId: string, orderConfigurationId?: number, pdfId?: string) {
        const payload = {
            data: {
                type: 'order_pdfs',
                attributes: {
                    type: 'default',
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: String(offerId),
                        },
                    },
                    orderConfiguration: {
                        data: {
                            type: 'order_configurations',
                            id: String(orderConfigurationId),
                        },
                    },
                },
            },
        };
        if (pdfId) {
            // @ts-ignore
            payload.data.id = pdfId;
        }
        return payload;
    }
}
