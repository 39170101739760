export enum ElementFilterType {
    GroupedDropdown = 'GroupedDropdown',
    Switch = 'Switch',
    StateSwitch = 'StateSwitch',
    DateRange = 'DateRange',
    NumberInput = 'NumberInput',
    CountryPicker= 'CountryPicker',
    Dropdown = 'Dropdown',
    Input = 'Input',
    MultipleSelect = 'MultipleSelect',
}
