import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class ChatAPI {
    public static async getAll() {
        let chats;

        try {
            chats = await ApiController.get(ApiRoutes.chat.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(chats);
    }
    public static async getById(chatId: string) {
        let chat;

        try {
            chat = await ApiController.get(ApiRoutes.chat.getById(chatId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(chat);
    }

    public static async delete(chatId: string) {

        try {
            await ApiController.delete(ApiRoutes.chat.delete(chatId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(chatId: string, chatInfo: AvailableLabelFields) {
        let chat;
        const request = {
            data: {
                type: 'lead_origins',
                id: chatId,
                attributes: {
                    name: chatInfo.name,
                },
            },
        };

        try {
            chat =
                await ApiController.patch(
                    ApiRoutes.chat.update(chatId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(chat);
    }

    public static async create(chatInfo: AvailableLabelFields) {
        let chat;
        const request = {
            data: {
                type: 'chats',
                attributes: {
                },
                relationships: {
                    project: {
                        data: {
                            type: 'projects',
                            id: chatInfo.projectId,
                        },
                    },
                },
            },
        };

        try {
            chat =
                await ApiController.post(
                    ApiRoutes.chat.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(chat);
    }
}
