import { Model } from '@vuex-orm/core';
import CampaignAPI from '@/api/CampaignAPI';
import CampaignType from '@/models/CampaignType';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class Campaign extends Model {
    public static entity = 'campaign';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            startDate: this.attr(null),
            endDate: this.attr(null),
            campaignType_id: this.attr(null),
            campaignType: this.belongsTo(CampaignType, 'campaignType_id'),
        };
    }

    public static async getAll() {
        let campaigns;

        if (ss.has('campaigns')) {
            campaigns = ss.getAndParse('campaigns');
        }

        try {
            const fetchedCampaigns = await CampaignAPI.getAll();
            campaigns = fetchedCampaigns.data;

            ss.stringifyAndSave('campaigns', campaigns);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: campaigns,
        });
        return Promise.resolve();
    }

    public static async getById(campaignId: string) {
        let campaign;
        try {
            campaign = await CampaignAPI.getById(campaignId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaign.data,
            insertOrUpdate: ['campaignType'],
        });
        return Promise.resolve(campaign);
    }

    public static async updateExisting(campaignId: string, campaignInfo: any) {
        let campaign;
        try {
            campaign = await CampaignAPI.update(campaignId, campaignInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaign.data,
            insertOrUpdate: ['campaignType'],
        });
        return Promise.resolve(campaign);
    }

    public static async createNew(campaignInfo: any) {
        let campaign;
        try {
            campaign = await CampaignAPI.create(campaignInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: campaign.data,
            insertOrUpdate: ['campaignType'],
        });
        return Promise.resolve(campaign);
    }

    public static async deleteExisting(campaignId: string) {
        try {
            await CampaignAPI.delete(campaignId);
        } catch (e) {
            return Promise.reject(e);
        }
        await Campaign.delete(campaignId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public startDate!: string;
    public endDate!: string;
}
