import TranslationalObject from '@/models/TranslationalObject';
import { TranslatableProperty } from '@/interfaces/components/translations/TranslatableProperty';
import { LocaleValues } from '@/interfaces/components/translations/LocaleValues';
import { SearchOptions } from '@/interfaces/components/translations/SearchOptions';
import TranslationalObjectRepository from '@/repositories/TranslationalObjectRepository';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import i18n from '@/i18n';
import { notification } from 'ant-design-vue';
import { AxiosError } from 'axios';

export function groupTranslationalObjects(translationalObjects: TranslationalObject[]) {
    const groupedClientLists = translationalObjects.reduce((accumulator: any, element: any) => {
        // @ts-ignore
        const group = element.translatable.id;
        // @ts-ignore
        if (!accumulator[group]) {
            // @ts-ignore
            accumulator[group] = { group, children: [element] };
        } else {
            // @ts-ignore
            accumulator[group].children.push(element);
        }
        return accumulator;
    }, {});
    return groupedClientLists;
}

export function mapTranslationByKey(key: string) {
    return translationMap[key];
}

/**
 * Capitalizes first letters of words in a string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export function capitalizeWord(str: string, lower = false) {
    const normalizedString = (lower ? str.toLowerCase() : str).replace(/_/g, ' ');
    return normalizedString.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

const translationMap: LocaleValues = {
    address: 'Adresa',
    bankCode: 'Bank code',
    additionalNotes: 'Dodatne napomene',
    country: 'Dr\u017eava *',
    email: 'E-mail',
    electrician: 'Elektri\u010dar *',
    fax: 'Fax',
    city: 'Grad *',
    name: 'Naziv',
    priceManipulationType: 'Tip',
    salesman: 'Komercijalist',
    assignedUser: 'Komercijalist',
    contactInfo: 'Kontakt informacije',
    generalInfo: 'Osnovne informacije',
    additionalInfo: 'Ostale informacije',
    companyInfo: 'Podaci',
    postalCode: 'Po\u0161tanski broj *',
    taxId: 'Tax ID',
    isClientTaxPayer: 'U sustavu poreza',
    isClientTaxPayerUpdatedAt: 'Zadnja provjera statusa poreza',
    phone: 'Telefon',
    phoneNumber: 'Telefon',
    currency: 'Valuta',
    streetNameAndNumber: 'Ulica i kućni broj *',
    visibleId: 'Ingenious Id klijenta',
    id: 'Id klijenta',
    priceType: 'Tip cijene',
    technologist: 'Napravio',
    description: 'Opis',
    title: 'Naziv',
    image: 'Slika',
    documents: 'Dokumenti',
    gallery: 'Galerija',
    isDefault: 'Postavljen kao početna vrijednost?',
    projectState: 'Status projekta',
    configurations: 'Konfiguracije',
    startDate: 'Datum početka',
    endDate: 'Datum završetka',
    campaignType: 'Tip kampanje',
    clientType: 'Tip klijenta',
    locale: 'Jezik',
    contactPerson: 'Kontakt osoba',
    color: 'Boja',
    montage: 'Montaža',
    noMontage: 'Bez montaže',
    montageNonTax: 'Montaža bez taxe',
    noMontageNonTax: 'Bez montaže i taxe',
    company: 'Firma',
    countryName: 'Ime države',
    showPrice: 'Odaberi cijene',
    showDiscounts: 'Prikaži popuste',
    offer: 'Ponuda',
    request: 'Roltek produkcija',
    order: 'Narudžba',
    lead: 'Upit',
    archived: 'Arhivirano',
    draft: 'Nacrt',
    priceListRegion: 'Lista cijene',
    showClientOffers: 'Prikaži ponude klijenta',
    showClientOrders: 'Prikaži narudžbe klijenta',
    actions: 'Akcije',
    history: 'Povijest',
    paymentType: 'Vrsta plaćanja',
    createdAt: 'Povijest',
};

/**
 * Generates the payload appropriate for backend based on the given translated strings
 * It will filter out null and unchanged values
 * @param translatedStrings - The key/value pairs containing the current translation values
 * @return - Key/value pairs of the current translation values
 */
export function generateTranslatablePropertiesPayload(translatedStrings: LocaleValues) {
    const translatableObject = TranslationalObjectRepository.getFirst();

    if (translatableObject == null || translatableObject.getTranslatableProperties == null) {
        return {};
    }
    const translationsWithChanges = filterTranslationsWithUnchangedValues(translatableObject, translatedStrings);

    return translationsWithChanges.reduce((acc, translatableArray) => {
        const [key, value] = translatableArray;
        return { ...acc, [key]: value };
    }, {});
}

/**
 * Filters out all translatable properties that did not change in respect to the current strings
 * @param translatableObject - The current translatable object that contains the translatable properties
 * @param translatedStrings - The key/value pairs containing the current translation values
 * @return - All translatable properties that have changes in them
 */
export function filterTranslationsWithUnchangedValues(
    translatableObject: TranslationalObject,
    translatedStrings: LocaleValues
) {
    const oldTranslatableProperties: TranslatableProperty[] = translatableObject.getTranslatableProperties;

    return Object.entries(translatedStrings).filter(([key, value]) => {
        const oldTranslatableProperty = oldTranslatableProperties.find((translatableProperty) => {
            return translatableProperty.baseLocaleString === key;
        });

        return oldTranslatableProperty && oldTranslatableProperty.localeString !== value;
    });
}

/**
 * Fetches the translation object from backend based on the given search options
 * @param searchOptions - All options necessary for preforming a search
 * @return - All translatable properties converted to key/value pairs for the current page
 */
export async function updateTranslationData(searchOptions: SearchOptions) {
    try {
        await TranslationalObject.getByRoute(searchOptions);
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return Promise.reject(e);
    }

    return Promise.resolve(convertTranslatablePropertiesToObject());
}

/**
 * Converts the translatable properties on a translational object to key/value pairs
 * @return - An object with key value pairs if it exists, otherwise an empty object
 */
export function convertTranslatablePropertiesToObject() {
    const translatableObject = TranslationalObjectRepository.getFirst();
    if (translatableObject == null) {
        return {};
    }
    const translatableProperties: TranslatableProperty[] = translatableObject.getTranslatableProperties;

    return translatableProperties.reduce((previousValue, translatableProperty) => {
        const translatablePropertyKey = translatableProperty.baseLocaleString as string;
        return {
            ...previousValue,
            [translatablePropertyKey]: translatableProperty.localeString,
        };
    }, {});
}

/**
 * Converts the given translated key/value pairs to array of LocaleValues and sends to backend
 * @param translatedStrings - The key/value pairs containing the current translation values
 * @param entityName - The name of the entity that is being updated
 * @param locale - The locale that is being updated
 * @param attribute - The name of the attribute that is being updated
 * @param baseLocale - The base locale of the current translation
 */
export async function handleSave(
    translatedStrings: LocaleValues,
    entityName: string,
    locale: string,
    attribute: string,
    baseLocale: string
) {
    try {
        await TranslationalObject.updateExisting({
            translatableProperties: generateTranslatablePropertiesPayload(translatedStrings),
            entityName,
            locale,
            attribute,
            baseLocale,
        });
    } catch (e) {
        showErrorNotifications(e as AxiosError<any>);
        return;
    }
    notification.success({
        message: i18n.t('Prijevodi uspješno spremljeni!') as string,
        description: '',
    });
}

/**
 * Checks if translated strings have any differences with the original translatable properties
 * @param translatedStrings - The key/value pairs containing the current translation values
 * @return A boolean whether the translations have changes or not
 */
export function translationsHaveChanges(translatedStrings: LocaleValues) {
    return Object.entries(generateTranslatablePropertiesPayload(translatedStrings)).length > 0;
}
