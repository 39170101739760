import CMSUserAPI from '@/api/CMSUserAPI';
import { CMSUserInterface } from '@/models/interfaces/CMSUser';
import { Model } from '@vuex-orm/core';
import { AxiosError, AxiosResponse } from 'axios';
import Locale from '@/models/Locale';
import PostCodeConnection from '@/models/PostCodeConnection';
import UserGroupConnection from '@/models/UserGroupConnection';
import Currency from '@/models/Currency';
import UserAlias from '@/models/UserAlias';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class CMSUser extends Model {
    public get getId() {
        // @ts-ignore
        return this.id;
    }

    public get getType() {
        // @ts-ignore
        return this.type;
    }

    public get getUsername() {
        // @ts-ignore
        return this.username;
    }

    public get getEmail() {
        // @ts-ignore
        return this.email;
    }

    public get getRoles() {
        // @ts-ignore
        return this.roles;
    }

    public get getName() {
        // @ts-ignore
        return this.name;
    }

    public get getUserType() {
        // @ts-ignore
        return this.userType;
    }

    public static entity = 'cmsUser';

    public static fields() {
        return {
            id: this.attr(''),
            username: this.attr(''),
            email: this.attr(''),
            roles: this.attr([]),
            name: this.attr(''),
            phoneNumber: this.attr(''),
            userType: this.attr(''),
            isSalesman: this.boolean(false),
            isArchived: this.boolean(false),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
            postCodeConnections: this.hasManyBy(PostCodeConnection, 'postCodeConnection_ids'),
            postCodeConnection_ids: this.attr([]),
            userGroupConnections: this.hasManyBy(UserGroupConnection, 'userGroupConnection_ids'),
            userGroupConnection_ids: this.attr([]),
            //  discounts: this.relation(Discount),
            currency: this.belongsTo(Currency, 'currency_id'),
            currency_id: this.attr(null),
            aliases: this.hasManyBy(UserAlias, 'aliases_ids'),
            aliases_ids: this.attr([]),
        };
    }

    public static async getAll() {
        let cmsUsers;

        if (ss.has('cmsUsers')) {
            cmsUsers = ss.getAndParse('cmsUsers');
        } else {
            try {
                const users = await CMSUserAPI.getAll();
                cmsUsers = users.data;

                ss.stringifyAndSave('cmsUsers', cmsUsers);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: cmsUsers,
            insertOrUpdate: ['postCodeConnection', 'currency', 'country'],
        });

        return Promise.resolve(cmsUsers);
    }

    public static async getAllOnlyUsers() {
        let cmsUsers;

        if (ss.has('cmsOnlyUsers')) {
            cmsUsers = ss.getAndParse('cmsOnlyUsers');
        } else {
            try {
                const users = await CMSUserAPI.getAllOnlyUsers();
                cmsUsers = users.data;

                ss.stringifyAndSave('cmsOnlyUsers', cmsUsers);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: cmsUsers,
        });

        return Promise.resolve(cmsUsers);
    }

    public static async getAllForUserRights() {
        let cmsUsers;

        try {
            cmsUsers = await CMSUserAPI.getAllForUserRights();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: cmsUsers.data,
            insertOrUpdate: ['userGroupConnections', 'userGroup'],
        });
        return Promise.resolve(cmsUsers);
    }

    public static async getAllFromAllUserGroups() {
        let cmsUsers;

        try {
            cmsUsers = await CMSUserAPI.getAllFromAllUserGroups();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: cmsUsers.data,
            insertOrUpdate: ['postCodeConnection', 'currency', 'country'],
        });
        return Promise.resolve(cmsUsers);
    }

    public static async getById(id: string) {
        let cmsUser;

        try {
            cmsUser = await CMSUserAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: cmsUser.data,
            insertOrUpdate: ['locale', 'postCodeConnection', 'userGroupConnection', 'userGroup', 'currency'],
        });

        return Promise.resolve(cmsUser.data);
    }

    public static async createNew(cmsUser: CMSUserInterface): Promise<AxiosResponse | AxiosError> {
        let newCmsUser;
        try {
            newCmsUser = await CMSUserAPI.createNew(cmsUser);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: newCmsUser.data,
            insertOrUpdate: ['locale', 'userGroupConnection', 'userGroup', 'currency'],
        });

        return Promise.resolve(newCmsUser);
    }

    public static async updateExisting(id: string, cmsUser: CMSUserInterface): Promise<AxiosResponse | AxiosError> {
        let updatedCmsUser;
        try {
            updatedCmsUser = await CMSUserAPI.updateExisting(id, cmsUser);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: updatedCmsUser.data,
            insertOrUpdate: ['locale', 'userGroupConnection', 'userGroup', 'currency'],
        });

        return Promise.resolve(updatedCmsUser);
    }

    public static async updateCurrency(id: string, currencyId: string): Promise<AxiosResponse | AxiosError> {
        let updatedCmsUser;
        try {
            updatedCmsUser = await CMSUserAPI.updateCurrency(id, currencyId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: updatedCmsUser.data,
            insertOrUpdate: ['locale', 'userGroupConnection', 'userGroup', 'currency'],
        });

        return Promise.resolve(updatedCmsUser);
    }

    public static async search(query: string) {
        let clients;
        try {
            clients = (await CMSUserAPI.search(query)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: clients.data,
        });
        return Promise.resolve(clients);
    }

    public static async onDelete(id: string) {
        try {
            await CMSUserAPI.delete(id);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }
    public name!: string;
    public id!: string;
    public userGroupConnections!: UserGroupConnection[];
    public aliases!: UserAlias[];
    public isSalesman!: boolean;
    public isArchived!: boolean;
    public currency!: Currency;
}
