import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import ContactConfig from '@/models/interfaces/Contact';
import { TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';
import { formatDateToDateString } from '@/helpers/LocaleHelper';

export default class ContactPersonAPI {
    public static async getAll() {
        let contactPersons;

        try {
            contactPersons = (await ApiController.get(ApiRoutes.contact.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(contactPersons);
    }

    public static async getById(contactId: string) {
        let contact;

        try {
            contact = (await ApiController.get(ApiRoutes.contact.getById(contactId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(contact);
    }

    public static async getByIdWithProjects(contactId: string) {
        let contact;

        try {
            contact = (await ApiController.get(ApiRoutes.contact.getByIdWithProjects(contactId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(contact);
    }

    public static async update(contactId: string, contactInfo: ContactConfig) {
        let contact;
        const request = {
            data: {
                type: 'contact_persons',
                id: contactId,
                attributes: {
                    name: contactInfo.name,
                    phone: contactInfo.phone,
                    email: contactInfo.email,
                    job: contactInfo.job,
                    isDefault: contactInfo.isDefault,
                    birthday: formatDateToDateString(contactInfo.birthday),
                },
                relationships: {},
            },
        };

        const relationships: TJsonApiRelationships = {
            address: {
                // @ts-ignore
                data: null,
            },
        };

        if (contactInfo.address_id) {
            relationships.address = {
                data: {
                    type: 'addresses',
                    id: contactInfo.address_id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            contact = (await ApiController.patch(ApiRoutes.contact.update(contactId), request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(contact);
    }

    public static async create(contactInfo: ContactConfig) {
        let contact;
        const request = {
            data: {
                type: 'contact_persons',
                attributes: {
                    name: contactInfo.name,
                    phone: contactInfo.phone,
                    email: contactInfo.email,
                    job: contactInfo.job,
                    isDefault: contactInfo.isDefault,
                    birthday: formatDateToDateString(contactInfo.birthday),
                },
                relationships: {},
            },
        };

        const relationships: TJsonApiRelationships = {
            address: {
                // @ts-ignore
                data: null,
            },
        };

        if (contactInfo.address_id) {
            relationships.address = {
                data: {
                    type: 'addresses',
                    id: contactInfo.address_id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            contact = (await ApiController.post(ApiRoutes.contact.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(contact);
    }

    public static async delete(contactId: string) {
        try {
            await ApiController.delete(ApiRoutes.contact.delete(contactId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
