
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OfferConfigurationRepository from '@/repositories/OfferConfigurationRepository';
import OfferConfiguration from '@/models/OfferConfiguration';
import {
    generateDiscountPDFOptions,
    generatePricePDFOptions,
    generateProductPDFOptions,
    generateSelectedPriceOptions,
} from '@/helpers/CmsIndexHelper';
import { Checkbox, Icon, Radio, Tooltip } from 'ant-design-vue';
import { extractAdditionalPDFOptionsFromOfferConfiguration } from '@/helpers/project/ProjectHelper';
import Offer from '@/models/Offer';
import { ConfigurationOverride } from '@/interfaces/general/ConfigurationOverride';
import { OfferPriceType } from '@/enums/global/OfferPriceType';
import { UserRightsEnum } from '@/enums/global/UserRights';

@Component({
    name: 'AdditionalPDFOptions',
    components: {
        Radio,
        RadioGroup: Radio.Group,
        Checkbox,
        CheckBoxGroup: Checkbox.Group,
        Tooltip,
        Icon,
    },
})

export default class AdditionalPDFOptions extends Vue {
    @Prop({required: true}) private selectedPdfTypeId!: string;
    @Prop({required: true}) private selectedOffer!: Offer;

    private indeterminate = true;
    private checkAll = false;
    private discountOption = '';
    private priceOption = '';
    private printPriceOption = OfferPriceType.SELLING_PRICE;
    private checkedProductOptions: string[] = [];
    private areSelectedOptionsAndOfferConfigurationSame = true;

    private get selectedPdfType() {
        if (this.selectedPdfTypeId === '') {
            return null;
        }

        return OfferConfigurationRepository.getById(this.selectedPdfTypeId) as OfferConfiguration;
    }

    private get productOptions() {
        return generateProductPDFOptions().map((productPDFOption) => {
            return {
                ...productPDFOption,
                label: this.$t(productPDFOption.label),
                value: productPDFOption.name,
            };
        });
    }

    private get discountOptions() {
        return generateDiscountPDFOptions();
    }

    private get priceOptions() {
        return generatePricePDFOptions();
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get printPriceOptions() {
        const priceOptions = generateSelectedPriceOptions();

        if(!this.canUserSeeRecommendedPrice()) {
            const index = priceOptions.findIndex((item) => item.label === 'Preporučena cijena');

            priceOptions.splice(index, 1);
        }
        return priceOptions;
    }

    public canUserSeeRecommendedPrice() {
        return this.userRights.includes(UserRightsEnum.VIEW_RECOMMENDED_PRICE);
    }

    public getAdditionalPDFOptions() {
        const configurationOverride: ConfigurationOverride = {
            showPrice: this.priceOption,
            showDiscounts: this.discountOption,
            showPurchasePrice: this.printPriceOption === OfferPriceType.PURCHASE_PRICE,
            showRecommendedPrice: this.printPriceOption === OfferPriceType.RECOMMENDED_PRICE,
        } as ConfigurationOverride;

        this.productOptions.forEach((productOption) => {
            configurationOverride[productOption.value] = this.checkedProductOptions.includes(productOption.value);
        });
        return configurationOverride;
    }

    private compareSelectedOptionsWithOfferConfiguration() {
        if (this.selectedPdfType == null) {
            return true;
        }
        const {
            checkedProductOptions,
            discountOption,
            priceOption,
            printPrice,
        } = extractAdditionalPDFOptionsFromOfferConfiguration(this.selectedPdfType, this.productOptions);

        const areCheckedProductOptionsSame = checkedProductOptions.length === this.checkedProductOptions.length &&
            this.checkedProductOptions.every((value) => checkedProductOptions.includes(value));
        const areDiscountOptionsSame = this.discountOption === discountOption;
        const arePriceOptionsSame = this.priceOption === priceOption;
        const arePrintPriceOptionsSame = this.printPriceOption === printPrice;

        this.areSelectedOptionsAndOfferConfigurationSame = areCheckedProductOptionsSame &&
            areDiscountOptionsSame &&
            arePriceOptionsSame &&
            arePrintPriceOptionsSame;
    }

    private onChange(checkedList: any) {
        this.indeterminate = !!checkedList.length && checkedList.length < this.productOptions.length;
        this.checkAll = checkedList.length === this.productOptions.length;

        this.compareSelectedOptionsWithOfferConfiguration();
    }

    private onCheckAllChange(e: Event) {
        if (e.target == null) {
            return;
        }
        const isChecked = (e.target as HTMLInputElement).checked;

        this.checkedProductOptions = isChecked ? this.productOptions.map((option) => option.value) : [];
        this.indeterminate = false;
        this.checkAll = isChecked;

        this.compareSelectedOptionsWithOfferConfiguration();
    }

    private mounted() {
        if (this.selectedOffer.configurationOverride == null) {
            this.onSelectedPdfTypeIdChange();
            return;
        }

        this.discountOption = this.selectedOffer.configurationOverride.showDiscounts;
        this.priceOption = this.selectedOffer.configurationOverride.showPrice;
        this.printPriceOption = OfferConfiguration.determineDefaultPrintPrice(
            this.selectedOffer.configurationOverride.showPurchasePrice,
            this.selectedOffer.configurationOverride.showRecommendedPrice,
        );
        this.checkedProductOptions = Object.entries(this.selectedOffer.configurationOverride)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => key);
        this.checkAll = this.checkedProductOptions.length === this.productOptions.length;

        this.compareSelectedOptionsWithOfferConfiguration();
    }

    @Watch('selectedPdfTypeId')
    private onSelectedPdfTypeIdChange() {
        if (this.selectedPdfType == null) {
            return;
        }

        const {
            checkedProductOptions,
            discountOption,
            priceOption,
            printPrice,
        } = extractAdditionalPDFOptionsFromOfferConfiguration(this.selectedPdfType, this.productOptions);

        this.checkAll = checkedProductOptions.length === this.productOptions.length;
        this.checkedProductOptions = checkedProductOptions;
        this.discountOption = discountOption;
        this.priceOption = priceOption;
        this.printPriceOption = printPrice;

        this.compareSelectedOptionsWithOfferConfiguration();
    }
}
