import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class OfferItemPriceTypeAPI {
    public static async getAll() {
        let offerItemPriceTypes;
        try {
            offerItemPriceTypes = await ApiController.get(ApiRoutes.offerItemPriceType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return offerItemPriceTypes;
    }

    public static async getById(offerItemPriceTypeId: string) {
        let offerItemPriceTypes;

        try {
            offerItemPriceTypes = (await ApiController.get(ApiRoutes.offerItemPriceType.getById(offerItemPriceTypeId))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItemPriceTypes);
    }
}
