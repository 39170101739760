
import { Component, Prop, Vue } from 'vue-property-decorator';
import ActivityModalForm, { ActivityFormData } from '@/components/global/ActivityModalForm.vue';
import NextStep from '@/models/NextStep';
import { transformObjectToBeBackendCompliant } from '@/helpers/NextStepHelper';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import Lead from '@/models/interfaces/Lead';
import Project from '@/models/Project';
import { createNewChatMessageAndChat } from '@/helpers/chat/ChatHelper';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import moment from 'moment';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ActivityTemplateResponse from '@/models/interfaces/ActivityTemplateResponse';
import StatusEventConditions from '@/models/interfaces/StatusEventConditions';

@Component({
    name: 'ActivityNewModal',
    components: {
        ActivityModalForm,
    },
})
export default class ActivityNewModal extends Vue {
    @Prop({ required: true }) private lead!: Lead;
    @Prop({ required: true }) private project!: Project;
    @Prop({ default: false }) private isActivityNewModalVisible!: boolean;
    private formData = {} as ActivityFormData;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private closeActivityNewPopup() {
        this.$emit('update:isActivityNewModalVisible', false);
    }

    private updateFormData(data: ActivityFormData) {
        this.formData = {
            ...data,
        };
    }

    private get activityTypes() {
        return NextStepTypeRepository.getAll();
    }

    private async saveNewActivity() {
        this.loadingOverlay.start();

        try {
            // if name field is empty, copy the next step type name
            const data: UpgradedNextStep = {
                ...this.formData,
                nextStepType: this.formData.activityType,
                projectId: this.project.id,
            };

            if (!this.formData.name) {
                data.name = this.activityTypes.find((a) => a.id === this.formData.activityType)?.name;
            }

            const chatId = await createNewChatMessageAndChat(true, this.project?.id, this.project?.chat?.id, undefined);
            const backendCompliantObject = transformObjectToBeBackendCompliant(data, chatId);
            await NextStep.createNew(backendCompliantObject);
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: '',
            });

            this.loadingOverlay.stop();
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Activity successfully added') as string,
            description: '',
        });

        this.closeActivityNewPopup();
        EventBus.$emit(EventBusEvents.updateProject);
    }

    private async onStatusChange(data: { templatesData: ActivityTemplateResponse[]; assignedUser: string }) {
        const { templatesData, assignedUser } = data;
        const serializedActivityData = this.serializeFetchedTemplateData(templatesData, assignedUser);

        this.updateFormData(serializedActivityData);
    }

    private serializeFetchedTemplateData(activityForm: ActivityTemplateResponse[], assignedUser: string) {
        const date = this.formatActivityDate(activityForm[0].statusEventActivityConfig.conditions) as Date;

        const serializedActivityData = {
            activityType: activityForm[0].type.id,
            name: activityForm[0].name,
            dateFrom: date as Date,
            dateTo: this.increaseDateByHours(date, 1),
            reminderOption: '',
            assignedUser,
            note: activityForm[0].note,
        };
        return serializedActivityData;
    }

    private formatActivityDate(conditions: StatusEventConditions) {
        const date = this.getNextSunday();

        if (conditions.selectedTimeStep === 0 || conditions.selectedTimeStep === null) {
            return date;
        }

        // calculate current time + selectedTimeStep in selectedTimeUnit
        const { timeUnit, selectedTimeStep } = conditions;

        if (timeUnit === 'seconds') {
            date.setSeconds(date.getSeconds() + selectedTimeStep);
        } else if (timeUnit === 'minutes') {
            date.setMinutes(date.getMinutes() + selectedTimeStep);
        } else if (timeUnit === 'hours') {
            date.setHours(date.getHours() + selectedTimeStep);
        } else if (timeUnit === 'days') {
            date.setDate(date.getDate() + selectedTimeStep);
        }

        return moment(date);
    }

    private getNextSunday() {
        const currentDate = new Date();
        const dayOfWeek = currentDate.getDay();
        const daysUntilNextSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;
        const nextSunday = new Date(currentDate);

        nextSunday.setDate(currentDate.getDate() + daysUntilNextSunday);
        nextSunday.setHours(12);
        nextSunday.setMinutes(0);
        nextSunday.setSeconds(0);
        nextSunday.setMilliseconds(0);

        return nextSunday;
    }

    private increaseDateByHours(date: Date, hour: number) {
        const dateCopy = new Date(date);
        dateCopy.setHours(date.getHours() + hour);

        return dateCopy;
    }

    private mounted() {
        EventBus.$on(EventBusEvents.openActivityNewModalOnStatusChange, this.onStatusChange);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openActivityNewModalOnStatusChange, this.onStatusChange);
    }
}
