import { Model } from '@vuex-orm/core';
import { AxiosError, AxiosResponse } from 'axios';
import LeadsAPI from '@/api/LeadsAPI';
import LeadOrigin from '@/models/LeadOrigin';
import LeadInterface from '@/models/interfaces/Lead';
import LeadProduct from '@/models/LeadProduct';
import Campaign from '@/models/Campaign';
import LeadType from '@/models/LeadType';
import Attachment from '@/models/Attachment';
import Address from '@/models/Address';
import ReminderOption from '@/models/ReminderOption';
import { UploadFile } from 'ant-design-vue/types/upload';
import Project from '@/models/Project';
import { LeadDetailsOptions } from '@/interfaces/components/projectNew/LeadDetailsOptions';

export default class Lead extends Model {
    public get leadId() {
        // @ts-ignore
        return this.id;
    }

    public get leadDescription() {
        // @ts-ignore
        return this.description;
    }

    public static entity = 'lead';

    public static fields() {
        return {
            id: this.attr(''),
            type: this.string('leads'),
            description: this.attr(''),
            createdAt: this.string(''),
            dateInserted: this.string(''),
            updatedAt: this.string(''),
            importText: this.attr(''),
            priority: this.attr(null),
            numberOfProducts: this.number(0),
            windowsBuiltIn: this.boolean(false),
            project_id: this.attr(null),
            origin_id: this.attr(null),
            leadType_id: this.attr(null),
            campaign_id: this.attr(null),
            objectAddress_id: this.attr(null),
            product_ids: this.attr([]),
            attachments_ids: this.attr([]),
            origin: this.belongsTo(LeadOrigin, 'origin_id'),
            leadType: this.belongsTo(LeadType, 'leadType_id'),
            products: this.hasManyBy(LeadProduct, 'product_ids'),
            campaign: this.belongsTo(Campaign, 'campaign_id'),
            attachments: this.hasManyBy(Attachment, 'attachments_ids'),
            objectAddress: this.belongsTo(Address, 'objectAddress_id'),
            project: this.belongsTo(Project, 'project_id'),
        };
    }

    public static async createNew(leadData: LeadInterface, id: string): Promise<AxiosResponse | AxiosError> {
        const newLead = await LeadsAPI.createNew(leadData, id);

        this.insertOrUpdate({ data: newLead.data });

        return newLead;
    }

    public static async fetchAdditionalData(id: string): Promise<AxiosResponse | AxiosError> {
        const newLead = await LeadsAPI.getAdditionalData(id);

        await this.insertOrUpdate({
            data: newLead.data,
            insertOrUpdate: ['origin', 'leadProduct', 'campaign', 'address', 'leadType', 'attachment'],
        });

        return newLead;
    }

    public static async updateExisting(leadData: LeadInterface, leadId: string): Promise<AxiosResponse | AxiosError> {
        const updatedLead = await LeadsAPI.updateExisting(leadData, leadId);

        this.insertOrUpdate({
            data: updatedLead.data,
            insertOrUpdate: ['address', 'type', 'reminderOption', 'country'],
        });

        return updatedLead;
    }

    public static async updateAttachments(
        attachments: UploadFile[],
        leadId: string
    ): Promise<AxiosResponse | AxiosError> {
        const updatedLead = await LeadsAPI.updateAttachment(attachments, leadId);

        this.insertOrUpdate({
            data: updatedLead.data,
            insertOrUpdate: ['address', 'type', 'reminderOption', 'country'],
        });

        return updatedLead;
    }

    public static async updateLeadDetails(values: LeadDetailsOptions, leadId: string) {
        const updatedLead = await LeadsAPI.updateLeadDetails(values, leadId);

        await this.insertOrUpdate({
            data: updatedLead.data,
        });

        return Promise.resolve(updatedLead);
    }

    public static async deleteObjectAddress(leadId: string): Promise<void> {
        let updatedLead;

        try {
            updatedLead = await LeadsAPI.deleteObjectAddress(leadId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: updatedLead.data,
            insertOrUpdate: ['address', 'country'],
        });

        return Promise.resolve();
    }

    public static async getPdf(leadId: string) {
        return await LeadsAPI.getPdf(leadId);
    }
    public id!: string;
    public description!: string;
    public dateInserted!: string;
    public importText!: string | null;
    public priority!: null | string;
    public numberOfProducts!: number;
    public windowsBuiltIn!: boolean;
    public reminderOption!: ReminderOption | null;
    public origin!: LeadOrigin;
    public leadType!: LeadType;
    public campaign!: Campaign;
    public products!: LeadProduct[];
    public project!: Project;
    public objectAddress!: Address | null;
    public attachments!: Attachment[];
}
