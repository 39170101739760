import { Model } from '@vuex-orm/core';
import Locale from '@/models/Locale';
import Image from '@/models/Image';
import AwardImage from '@/models/AwardImage';
import OrderPdfPropertiesAPI from '@/api/OrderPdfPropertiesAPI';
import OrderPdfPropertiesConfiguration from '@/models/interfaces/OrderPdfPropertiesConfiguration';
import HeaderImage from '@/models/HeaderImage';

export default class OrderPdfProperties extends Model {

    public static entity = 'orderPdfProperties';

    public static fields() {
        return {
            id: this.attr(null),
            companyInfo: this.string(''),
            introText: this.string(''),
            offerInformation: this.attr(''),
            paymentAndDelivery: this.attr(''),
            richtext: this.attr(''),
            imageSegment: this.attr(''),
            signature: this.string(''),
            footerColumn1: this.attr(''),
            footerColumn2: this.attr(''),
            footerColumn3: this.attr(''),
            footerColumn4: this.attr(''),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
            logo: this.belongsTo(Image, 'logo_id'),
            logo_id: this.attr(null),
            headerImages_id: this.attr([]),
            headerImages: this.hasManyBy(HeaderImage, 'headerImages_id'),
            awardImages_id: this.attr([]),
            awardImages: this.hasManyBy(AwardImage, 'awardImages_id'),
        };
    }

    public static async getAll() {
        let orderPdfProperties;
        try {
            orderPdfProperties = await OrderPdfPropertiesAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: orderPdfProperties.data,
        });
        return Promise.resolve();
    }

    public static async getById(orderPdfPropertiesId: string) {
        let orderPdfProperties;
        try {
            orderPdfProperties = await OrderPdfPropertiesAPI.getById(orderPdfPropertiesId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: orderPdfProperties.data,
        });
        return Promise.resolve(orderPdfProperties);
    }

    public static async updateExisting(
        orderPdfPropertiesId: string,
        orderPdfPropertiesInfo: OrderPdfPropertiesConfiguration,
        orderConfigurationId?: string) {
        let orderPdfProperties;
        try {
            orderPdfProperties = await OrderPdfPropertiesAPI.update(
                orderPdfPropertiesId,
                orderPdfPropertiesInfo,
                orderConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(orderPdfProperties);
    }

    public static async createNew(
        orderPdfPropertiesInfo: OrderPdfPropertiesConfiguration,
        orderConfigurationId?: string) {
        let orderPdfProperties;
        try {
            orderPdfProperties = await OrderPdfPropertiesAPI.create(orderPdfPropertiesInfo, orderConfigurationId);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(orderPdfProperties);
    }

    public static async deleteExisting(orderPdfPropertiesId: string) {
        try {
            await OrderPdfPropertiesAPI.delete(orderPdfPropertiesId);
        } catch (e) {
            return Promise.reject(e);
        }
        await OrderPdfProperties.delete(orderPdfPropertiesId);
        return Promise.resolve();
    }

    public id!: string;
    public companyInfo!: string;
    public introText!: string;
    public offerInformation!: string;
    public paymentAndDelivery!: string;
    public richtext!: string;
    public imageSegment!: string;
    public signature!: string;
    public footerColumn1!: string;
    public footerColumn2!: string;
    public footerColumn3!: string;
    public footerColumn4!: string;
    public locale!: Locale;
    public logo!: Image;
    public headerImages!: HeaderImage[];
    public awardImages!: AwardImage[];
}
