import { VueConstructor } from 'vue';

interface HtmlToPaperOptions {
    name?: string;
    specs?: string | string[];
    styles?: string[];
    windowTitle?: string;
}

function addStyles(win: Window | null, styles: string[]) {
    styles.forEach((style) => {
        if (win) {
            let link = win.document.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('type', 'text/css');
            link.setAttribute('href', style);
            win.document.getElementsByTagName('head')[0]?.appendChild(link);
        }
    });
}

function openWindow(url: string, name: string, props: string) {
    let windowRef = null;
    windowRef = window.open(url, name, props);

    if (windowRef && !windowRef.opener) {
        windowRef.opener = self;
    }

    windowRef?.focus();
    return windowRef;
}

const VueHtmlToPaper = {
    install(Vue: VueConstructor, options: HtmlToPaperOptions = {}) {
        Vue.prototype.$htmlToPaper = (el: string, localOptions: HtmlToPaperOptions, cb: () => boolean = () => true) => {
            let defaultName = '_blank',
                defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
                defaultStyles: string[] = [],
                defaultWindowTitle = window.document.title;
            let {
                name = defaultName,
                specs = defaultSpecs,
                styles = defaultStyles,
                windowTitle = defaultWindowTitle,
            } = options;
            if (localOptions) {
                if (localOptions.name) name = localOptions.name;
                if (localOptions.specs) specs = localOptions.specs;
                if (localOptions.styles) styles = localOptions.styles;
                if (localOptions.windowTitle) windowTitle = localOptions.windowTitle;
            }

            specs = !!specs?.length ? (specs as []).join(',') : '';

            const element = window.document.getElementById(el);

            if (!element) {
                alert(`Element to print #${el} not found!`);
                return;
            }

            const url = '';
            const win = openWindow(url, name, specs as string);

            if (win) {
                win.document.write(`
                <html>
                    <head>
                        <title>${windowTitle || window.document.title}</title>
                    </head>
                    <body>
                        ${element.innerHTML}
                    </body>
                </html>
            `);

                addStyles(win, styles);

                setTimeout(() => {
                    win.document?.close();
                    win.focus();
                    win.print();
                    win.close();

                    setTimeout(function () {
                        if (win) {
                            win.close();
                        }
                    }, 1);
                    cb();
                }, 1000);
            }

            return true;
        };
    },
};

export default VueHtmlToPaper;
