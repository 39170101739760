import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import DocumentNameConfig from '@/models/interfaces/DocumentName';

export default class DocumentNameAPI {
    public static async getAll() {
        let documentNames;

        try {
            documentNames = await ApiController.get(ApiRoutes.documentName.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(documentNames);
    }

    public static async getById(addressId: string) {
        let documentName;

        try {
            documentName = await ApiController.get(ApiRoutes.documentName.getById(addressId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(documentName);
    }

    public static async update(documentNameId: string, documentNameInfo: DocumentNameConfig) {
        let documentName;
        const request = {
            data: {
                type: 'document_names',
                id: documentNameId,
                attributes: {
                },
                relationships: {
                    projects: {
                        data: {
                            type: 'projects',
                            id: documentNameInfo.projectId,
                        },
                    },
                },
            },
        };

        if (documentNameInfo.name) {
            // @ts-ignore
            request.data.attributes.name = documentNameInfo.name;
        }

        if (documentNameInfo.prefix) {
            // @ts-ignore
            request.data.attributes.prefix = documentNameInfo.prefix;
        }

        try {
            documentName =
                await ApiController.patch(
                    ApiRoutes.documentName.update(documentNameId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(documentName);
    }

    public static async delete(documentNameId: string) {

        try {
            await ApiController.delete(ApiRoutes.documentName.delete(documentNameId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async create(documentNameInfo: DocumentNameConfig) {
        let documentName;
        const request = {
            data: {
                type: 'document_names',
                attributes: {
                    name: documentNameInfo.name,
                    prefix: documentNameInfo.prefix,
                },
            },
        };

        try {
            documentName =
                await ApiController.post(
                    ApiRoutes.documentName.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(documentName);
    }
}
