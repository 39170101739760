import { Model } from '@vuex-orm/core';
import Currency from '@/models/Currency';
import PaymentRecordAPI from '@/api/PaymentRecordAPI';
import Project from '@/models/Project';

export default class PaymentRecord extends Model {

    public static entity = 'paymentRecord';

    public static fields() {
        return {
            id: this.string(null),
            amount: this.number(0),
            currency_id: this.attr(null),
            currency: this.belongsTo(Currency, 'currency_id'),
            paymentDate: this.attr(null),
            project_id: this.attr(null),
            project: this.belongsTo(Project, 'project_id'),
        };
    }

    public static async getAll() {
        let paymentRecords;
        try {
            paymentRecords = await PaymentRecordAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentRecords.data,
        });
        return Promise.resolve(paymentRecords);
    }

    public static async getById(paymentRecordId: string) {
        let paymentRecord;
        try {
            paymentRecord = await PaymentRecordAPI.getById(paymentRecordId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: paymentRecord.data,
        });
        return Promise.resolve(paymentRecord);
    }

    public static async updateExisting(paymentRecordId: string, paymentRecordInfo: any) {
        let paymentRecord;
        try {
            paymentRecord = await PaymentRecordAPI.update(paymentRecordId, paymentRecordInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: paymentRecord.data,
        });
        return Promise.resolve(paymentRecord);
    }

    public static async createNew(paymentRecordInfo: any) {
        let paymentRecord;
        try {
            paymentRecord = await PaymentRecordAPI.create(paymentRecordInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: paymentRecord.data,
        });
        return Promise.resolve(paymentRecord);
    }

    public static async deleteExisting(paymentRecordId: string) {
        try {
            await PaymentRecordAPI.delete(paymentRecordId);
        } catch (e) {
            return Promise.reject(e);
        }
        await PaymentRecord.delete(paymentRecordId);
        return Promise.resolve();
    }
    public id!: string;
    public amount!: number;
    public currency!: Currency;
    public paymentDate!: string;

}
