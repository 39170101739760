
import { Component, Vue } from 'vue-property-decorator';
import NavigationTemplate from '@/components/global/NavigationTemplate.vue';
import Popup from '@/components/global/popup/Popup.vue';
import ContactPopup from '@/components/global/popup/ContactPopup.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NewProjectModal from '@/components/global/NewProjectModal.vue';
import NewClientModal from '@/components/global/client/NewClientModal.vue';

@Component({
    name: 'App',
    components: { ContactPopup, Popup, NavigationTemplate, NewProjectModal, NewClientModal },
})
export default class App extends Vue {
    private counter = 0;
    private isNewProjectModalVisible = false;
    private isNewClientModalVisible = false;
    private isCalendarPopup = false;

    private toggleNewProjectModal(visible: boolean, isCalendarPopup: boolean) {
        this.isNewProjectModalVisible = visible;
        this.isCalendarPopup = isCalendarPopup;
    }

    private async created() {
        EventBus.$on(EventBusEvents.refetchHome, () => this.counter++);
        EventBus.$on(EventBusEvents.toggleNewProjectModal, this.toggleNewProjectModal);
        EventBus.$on(EventBusEvents.toggleNewClientModal, (visible: boolean) => {
            this.isNewClientModalVisible = visible;
        });
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.refetchHome, () => this.counter++);
        EventBus.$off(EventBusEvents.toggleNewProjectModal, this.toggleNewProjectModal);
        EventBus.$off(EventBusEvents.toggleNewClientModal, (visible: boolean) => {
            this.isNewClientModalVisible = visible;
        });
    }
}
