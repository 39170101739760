import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { CMSUserInterface } from '@/models/interfaces/CMSUser';
import { ApiController } from '@/services/ApiController';
import { AxiosResponse } from 'axios';
import { TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';
import PostCodeConnection from '@/models/PostCodeConnection';

export default class CMSUserAPI {
    public static async getAll() {
        let cmsUsers;
        try {
            cmsUsers = (await ApiController.get(ApiRoutes.cmsUser.getAll)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return cmsUsers;
    }
    public static async getAllOnlyUsers() {
        let cmsUsers;
        try {
            cmsUsers = (await ApiController.get(ApiRoutes.cmsUser.getAllOnlyUsers)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return cmsUsers;
    }
    public static async getAllForUserRights() {
        let cmsUsers;
        try {
            cmsUsers = (await ApiController.get(ApiRoutes.cmsUser.getAllForUserRights)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return cmsUsers;
    }

    public static async getAllFromAllUserGroups() {
        let cmsUsers;
        try {
            cmsUsers = (await ApiController.get(ApiRoutes.cmsUser.getAllFromAllGroups)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return cmsUsers;
    }

    public static async getById(id: string) {
        let cmsUser;

        try {
            cmsUser = (await ApiController.get(ApiRoutes.cmsUser.getSingle(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(cmsUser);
    }

    public static async updateExisting(id: string, cmsUser: CMSUserInterface) {
        let updatedCmsUser;

        const payload = {
            data: {
                type: 'users',
                id,
                attributes: {
                    email: cmsUser.email,
                    roles: cmsUser.roles,
                    isSalesman: cmsUser.isSalesman,
                    isArchived: cmsUser.isArchived,
                },
            },
        };

        if (cmsUser.name !== '') {
            // @ts-ignore
            payload.data.attributes.name = cmsUser.name;
        }
        if (cmsUser.phoneNumber !== '') {
            // @ts-ignore
            payload.data.attributes.phoneNumber = cmsUser.phoneNumber;
        }

        if (cmsUser.password !== '') {
            // @ts-ignore
            payload.data.attributes.password = cmsUser.password;
        }

        const relationships: TJsonApiRelationships = {};

        if (cmsUser.locale) {
            relationships.locale = {
                data: {
                    type: 'locales',
                    id: cmsUser.locale.id,
                },
            };
        }

        if (cmsUser.currency) {
            relationships.currency = {
                data: {
                    type: 'currencies',
                    id: cmsUser.currency.id,
                },
            };
        }

        // @ts-ignore
        if (cmsUser.postCodeConnections) {
            // @ts-ignore
            const postCodeConnectionData = cmsUser.postCodeConnections.map((postCodeConnection: PostCodeConnection) => {
                return {
                    type: 'post_code_connections',
                    id: postCodeConnection.id,
                };
            });
            relationships.configurations = {
                data: postCodeConnectionData,
            };
        }

        if (payload.data) {
            (payload.data as TJsonApiData).relationships = relationships;
        }

        try {
            updatedCmsUser = (await ApiController.patch(
                ApiRoutes.cmsUser.updateExisting(id),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedCmsUser);
    }

    public static async updateCurrency(id: string, currencyId: string) {
        let updatedCmsUser;

        const payload = {
            data: {
                type: 'users',
                id,
                attributes: {},
                relationships: {
                    currency: {
                        data: {
                            type: 'currencies',
                            id: currencyId,
                        },
                    },
                },
            },
        };

        try {
            updatedCmsUser = (await ApiController.patch(
                ApiRoutes.cmsUser.updateExisting(id),
                payload
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(updatedCmsUser);
    }

    public static async createNew(cmsUser: CMSUserInterface) {
        let newClient;

        const request = {
            data: {
                type: 'users',
                attributes: {
                    email: cmsUser.email,
                    roles: cmsUser.roles,
                    isSalesman: cmsUser.isSalesman,
                    isArchived: cmsUser.isArchived,
                },
            },
        };

        if (cmsUser.name !== '') {
            // @ts-ignore
            request.data.attributes.name = cmsUser.name;
        }
        if (cmsUser.phoneNumber !== '') {
            // @ts-ignore
            request.data.attributes.phoneNumber = cmsUser.phoneNumber;
        }
        if (cmsUser.password !== '') {
            // @ts-ignore
            request.data.attributes.password = cmsUser.password;
        }

        const relationships: TJsonApiRelationships = {};

        if (cmsUser.locale) {
            relationships.locale = {
                data: {
                    type: 'locales',
                    id: cmsUser.locale.id,
                },
            };
        }

        if (cmsUser.currency) {
            relationships.currency = {
                data: {
                    type: 'currencies',
                    id: cmsUser.currency.id,
                },
            };
        }

        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            newClient = (await ApiController.post(ApiRoutes.cmsUser.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newClient);
    }

    public static async search(query: string) {
        let users;

        try {
            users = await ApiController.get(ApiRoutes.cmsUser.search(query));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(users);
    }

    public static async delete(id: string) {
        try {
            await ApiController.delete(ApiRoutes.cmsUser.delete(id));
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }
}
