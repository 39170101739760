import ProductSystem from '@/models/ProductSystem';

export class ProductSystemRepository {
    public static getAll() {
        return ProductSystem.query().withAllRecursive().get();
    }

    public static getById(productSystemId: string) {
        return ProductSystem.query().withAllRecursive().whereId(productSystemId).first();
    }
}
