
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClientLogoAttachment from '@/components/global/client/ClientLogoAttachment.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ClientLogoAttachments',
    components: { ClientLogoAttachment },
})
export default class ClientLogoAttachments extends Vue {
    @Prop({ default: false }) private value!: any;
    @Prop({ default: null }) private clientId!: string;

    private updateBigAttachment(id: string | null) {
        this.value.bigLogo = id;
        this.onValueChange();
    }

    private updateSquareAttachment(id: string | null) {
        this.value.squareLogo = id;
        this.onValueChange();
    }

    private onValueChange() {
        this.$emit('input', this.value);
        EventBus.$emit(EventBusEvents.updateClient);
    }
}
