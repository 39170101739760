import { Model } from '@vuex-orm/core';
import IntroScreenAPI from '@/api/IntroScreenAPI';
import IntroScreenRepository from '@/repositories/IntroScreenRepository';
import IntroScreenItem from '@/models/IntroScreenItem';

export default class IntroScreen extends Model {
    public static entity = 'IntroScreen';

    public static fields() {
        return {
            id: this.attr(null),
            userGroupId: this.attr(null),
            introScreenItem_ids: this.attr([]),
            introScreenItems: this.hasManyBy(IntroScreenItem, 'introScreenItem_ids'),
        };
    }

    public static async getAll() {
        let introScreens;
        try {
            introScreens = await IntroScreenAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: introScreens.data,
        });
        return Promise.resolve();
    }

    public static async getById(introScreenId: string) {
        let introScreen;
        try {
            introScreen = await IntroScreenAPI.getById(introScreenId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: introScreen.data,
        });
        return Promise.resolve(introScreen);
    }

    public static async updateExisting(introScreenId: string, introScreenInfo: any) {
        let introScreen;
        try {
            introScreen = await IntroScreenAPI.update(introScreenId, introScreenInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: introScreen.data,
        });
        return Promise.resolve(introScreen);
    }

    public static async createNew(introScreenInfo: any) {
        let introScreen;
        try {
            introScreen = await IntroScreenAPI.create(introScreenInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insert({
            data: introScreen.data,
        });
        return Promise.resolve(introScreen);
    }

    public static async deleteExisting(introScreenId: string) {
        try {
            await IntroScreenAPI.delete(introScreenId);
        } catch (e) {
            return Promise.reject(e);
        }
        await IntroScreenRepository.delete(introScreenId);
        return Promise.resolve();
    }

    public id!: string;
    public userGroupId!: string;
    public introScreenItems!: IntroScreenItem[];
}
