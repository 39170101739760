
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';

@Component({
    name: 'CustomContextMenu',
})
export default class CustomContextMenu extends Vue {
    @Prop({ required: true }) private entity!: { id: string; coordinates: { x: number; y: number }; type: string };

    private projectTabValues = ProjectTabValues;
    private routeNames = RouteNames;
    private clientTabs = ClientTabs;

    private get position() {
        return {
            left: String(this.entity.coordinates.x) + 'px',
            top: String(this.entity.coordinates.y) + 'px',
        };
    }
}
