export enum CustomColors {
    Blue1 = '#deecf9',
    Blue2 = '#97c6ea',
    Blue3 = '#458dcb',
    Blue4 = '#0f5692',
    Blue5 = '#133d66',
    Blue6 = '#085a6e',
    Blue7 = '#03708e',
    Blue8 = '#009aaf',
    Blue9 = '#60bed3',
    Blue10 = '#a1dced',
}
