import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import HeaderImageAPI from '@/api/HeaderImageAPI';

export default class HeaderImage extends Model {

    public static entity = 'headerImage';

    public static fields() {
        return {
            id: this.string(null),
            position: this.attr(null),
            image: this.string(''),
            uuid: this.string(''),
            title: this.string(''),
            fileSize: this.string(''),
            type: this.string(''),
        };
    }

    public static async createNew(formData: FormData) {
        let headerImage;

        try {
            headerImage = await HeaderImageAPI.createNew(formData) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }


        this.insertOrUpdate({
            data: headerImage.data,
        });

        return Promise.resolve(headerImage);
    }

    public static async updateExisting(file: any) {
        let headerImage;

        try {
            headerImage = await HeaderImageAPI.updateExisting(file) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: headerImage.data,
        });

        return Promise.resolve(headerImage);
    }

    public id!: string;
    public position!: number;
    public image!: string;
    public uuid!: string;
    public fileSize!: string;
    public title!: string;
    public type!: string;
}
