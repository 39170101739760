
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WindowCanvasConfig } from '@/interfaces/components/combinations/WindowCanvasConfig';

@Component({
    name: 'CanvasWindow',
    components: {},
})
export default class CanvasWindow extends Vue {
    @Prop({ required: true }) private windowConfig!: WindowCanvasConfig;
    @Prop({ required: true }) private metricUnit!: string;
    private leftTextWidth = 0;
    private bottomTextWidth = 0;

    private get groupX() {
        return this.windowConfig.initialPositionX;
    }

    private get groupY() {
        return this.windowConfig.initialPositionY;
    }

    private get groupScaleX() {
        // return (this.stageConfig.width / this.windowConfig.width) * 0.2;
        return 0.42;
    }

    private get groupScaleY() {
        // return (this.stageConfig.height / this.windowConfig.height) * 0.5;
        return 0.42;
    }

    private get transformedDimensions(): { height: number; width: number } {
        if (this.metricUnit === 'cm') {
            return {
                height: parseFloat(this.windowConfig.height as any) * 10,
                width: parseFloat(this.windowConfig.width as any) * 10,
            };
        }

        return {
            height: parseFloat(this.windowConfig.height as any),
            width: parseFloat(this.windowConfig.width as any),
        };
    }

    private get frameOnScreenWidth() {
        return this.transformedDimensions?.width * this.groupScaleX;
    }

    private get frameOnScreenHeight() {
        return this.transformedDimensions?.height * this.groupScaleY;
    }

    private frameConfig(type: string) {
        const padding = 20;
        const frameWidth = this.transformedDimensions?.width * this.groupScaleX;
        const frameHeight = this.transformedDimensions?.height * this.groupScaleY;

        switch (type) {
            case 'top':
                return {
                    points: [0, 0, frameWidth, 0, frameWidth - padding, padding, padding, padding],
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                    closed: true,
                };
            case 'left':
                return {
                    points: [0, 0, padding, padding, padding, frameHeight - padding, 0, frameHeight],
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                    closed: true,
                };
            case 'bottom':
                return {
                    points: [
                        0,
                        frameHeight,
                        padding,
                        frameHeight - padding,
                        frameWidth - padding,
                        frameHeight - padding,
                        frameWidth,
                        frameHeight,
                    ],
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                    closed: true,
                };
            case 'right':
                return {
                    points: [
                        frameWidth,
                        0,
                        frameWidth,
                        frameHeight,
                        frameWidth - padding,
                        frameHeight - padding,
                        frameWidth - padding,
                        padding,
                    ],
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 1,
                    closed: true,
                };
            case 'glass':
                return {
                    x: padding,
                    y: padding,
                    width: frameWidth - padding * 2,
                    height: frameHeight - padding * 2,
                    fill: 'lightblue',
                };
            default:
                return {};
        }
    }

    private labelConfig(metric: string) {
        const offset = 20;

        switch (metric) {
            case 'width':
                return {
                    x: this.frameOnScreenWidth / 2 - this.bottomTextWidth / 2,
                    y: this.frameOnScreenHeight + offset,
                    fill: 'white',
                    stroke: 'grey',
                };
            case 'height':
                return {
                    x: -offset - this.leftTextWidth,
                    y: this.frameOnScreenHeight / 2 - offset / 2,
                    fill: 'white',
                    stroke: 'grey',
                };
            default:
                return {};
        }
    }

    private textConfig(metric: string) {
        const labelConfig = {
            fill: 'white',
            stroke: 'rgba(0, 0, 0, 0.65)',
            fontSize: 18,
            letterSpacing: 1,
        };

        switch (metric) {
            case 'height':
                return {
                    ...labelConfig,
                    text: this.dimensionsLabel?.height ?? '',
                };
            case 'width':
                return {
                    ...labelConfig,
                    text: this.dimensionsLabel?.width ?? '',
                };
            default:
                return {};
        }
    }

    private get dimensionsLabel() {
        if (this.metricUnit === 'mm') {
            return {
                height: `${this.windowConfig.height}mm`,
                width: `${this.windowConfig.width}mm`,
            };
        } else if (this.metricUnit === 'cm') {
            return {
                height: `${parseFloat(this.windowConfig.height as any)}cm`,
                width: `${parseFloat(this.windowConfig.width as any)}cm`,
            };
        }
    }

    private get arrowLineConfig() {
        const offset = 20;
        const frameHeight = this.transformedDimensions?.height * this.groupScaleY;
        const frameWidth = this.transformedDimensions?.width * this.groupScaleX;

        return {
            sceneFunc: function (context: any, shape: any) {
                context.beginPath();
                context.fillStyle = 'grey';
                context.lineWidth = 0.5;

                context.moveTo(0, 0);
                context.lineTo(-offset, 0);

                context.moveTo(0, frameHeight);
                context.lineTo(-offset, frameHeight);

                context.moveTo(0, frameHeight);
                context.lineTo(0, frameHeight + offset);

                context.moveTo(frameWidth, frameHeight);
                context.lineTo(frameWidth, frameHeight + offset);

                context.stroke();
                context.closePath();
                shape.getLayer().batchDraw(); // Update the layer to reflect changes
            },
        };
    }

    private get arrowConfig() {
        return (metric: string) => {
            const offset = 20;
            const arrowOffset = offset / 2;
            const arrowSize = 8;
            const frameHeight = this.transformedDimensions?.height * this.groupScaleY;
            const frameWidth = this.transformedDimensions?.width * this.groupScaleX;

            switch (metric) {
                case 'left':
                    return {
                        sceneFunc: function (context: any, shape: any) {
                            context.beginPath();
                            context.moveTo(-arrowOffset - arrowSize, arrowSize);
                            context.lineTo(-arrowOffset, 0);
                            context.lineTo(-arrowOffset + arrowSize, arrowSize);

                            // line
                            context.moveTo(-arrowOffset, 0);
                            context.lineTo(-arrowOffset, frameHeight);

                            // bottom pointer
                            context.moveTo(-arrowOffset - arrowSize, frameHeight - arrowSize);
                            context.lineTo(-arrowOffset, frameHeight);
                            context.lineTo(-arrowOffset + arrowSize, frameHeight - arrowSize);

                            context.strokeStyle = 'grey';
                            context.lineWidth = 1;
                            context.stroke();
                            context.closePath();
                            shape.getLayer().batchDraw(); // Update the layer to reflect changes
                        },
                    };
                case 'bottom':
                    return {
                        sceneFunc: function (context: any, shape: any) {
                            context.beginPath();
                            context.translate(0, frameHeight + arrowOffset);
                            context.moveTo(arrowSize, -arrowSize);
                            context.lineTo(0, 0);
                            context.lineTo(arrowSize, arrowSize);

                            // line
                            context.moveTo(0, 0);
                            context.lineTo(frameWidth, 0);

                            // bottom pointer
                            context.moveTo(frameWidth - arrowSize, -arrowSize);
                            context.lineTo(frameWidth, 0);
                            context.lineTo(frameWidth - arrowSize, arrowSize);

                            context.strokeStyle = 'grey';
                            context.lineWidth = 1;
                            context.stroke();
                            context.closePath();
                            shape.getLayer().batchDraw(); // Update the layer to reflect changes
                        },
                    };
            }
        };
    }

    private async mounted() {
        await this.$nextTick();

        this.leftTextWidth = (this.$refs?.leftText as any)._konvaNode.textWidth;
        this.bottomTextWidth = (this.$refs?.bottomText as any)._konvaNode.textWidth;
    }
}
