import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class OfferItemPriceManipulationAPI {
    public static async getAll() {
        let offerItemPriceManipulations;

        try {
            offerItemPriceManipulations = (await ApiController.get(
                ApiRoutes.offerItemPriceManipulation.get
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItemPriceManipulations);
    }

    public static async getById(offerItemPriceManipulationId: string) {
        let offerItemPriceManipulation;

        try {
            offerItemPriceManipulation = (await ApiController.get(
                ApiRoutes.offerItemPriceManipulation.getById(offerItemPriceManipulationId)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async update(
        offerItemPriceManipulationId: string,
        offerItemPriceManipulationInfo: AvailableLabelFields
    ) {
        let offerItemPriceManipulation;

        const request = {
            data: {
                type: 'offer_item_price_manipulations',
                id: offerItemPriceManipulationId,
                attributes: {
                    name: offerItemPriceManipulationInfo.name ? offerItemPriceManipulationInfo.name : null,
                    value: offerItemPriceManipulationInfo.value,
                },
                relationships: {
                    priceManipulationType: {
                        data: {
                            type: 'price_manipulation_types',
                            id: offerItemPriceManipulationInfo.priceManipulationType,
                        },
                    },
                },
            },
        };

        try {
            offerItemPriceManipulation = (await ApiController.patch(
                ApiRoutes.offerItemPriceManipulation.update(offerItemPriceManipulationId),
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async create(offerItemPriceManipulationInfo: AvailableLabelFields) {
        let offerItemPriceManipulation;

        const request = {
            data: {
                type: 'offer_item_price_manipulations',
                attributes: {
                    name: offerItemPriceManipulationInfo.name ? offerItemPriceManipulationInfo.name : null,
                    value: offerItemPriceManipulationInfo.value,
                },
                relationships: {
                    priceManipulationType: {
                        data: {
                            type: 'price_manipulation_types',
                            id: offerItemPriceManipulationInfo.priceManipulationType,
                        },
                    },
                    offerItem: {
                        data: {
                            type: 'offer_items',
                            id: offerItemPriceManipulationInfo.offerItem,
                        },
                    },
                    offerItemPriceType: {
                        data: {
                            type: 'offer_item_price_type',
                            id: offerItemPriceManipulationInfo.offerItemPriceType,
                        },
                    },
                    updatedBy: {
                        data: {
                            type: 'users',
                            id: offerItemPriceManipulationInfo.updatedBy,
                        },
                    },
                },
            },
        };

        try {
            offerItemPriceManipulation = (await ApiController.post(
                ApiRoutes.offerItemPriceManipulation.create,
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offerItemPriceManipulation);
    }

    public static async delete(offerItemPriceManipulationId: string) {
        try {
            await ApiController.delete(ApiRoutes.offerItemPriceManipulation.delete(offerItemPriceManipulationId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async deleteAll(offerItemId: string) {
        try {
            await ApiController.delete(ApiRoutes.offerItemPriceManipulation.deleteAll(offerItemId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
