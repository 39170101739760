
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import RoltekHeaderLogo from '@/icons/RoltekHeaderLogo.vue';
import RLogoIcon from '@/icons/RLogoIcon.vue';
import UserGroupSelect from '@/components/global/UserGroupSelect.vue';
import UserSettingsSelect from '@/components/global/UserSettingsSelect.vue';
import { NavigationKeys } from '@/enums/global/NavigationKeys';
import { Icon, Menu } from 'ant-design-vue';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { generateNavigationItemsSetup } from '@/helpers/navigation/NewNavigationHelper';
import NewRadioButtonGroup from '@/components/global/NewRadioButtonGroup.vue';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { LocalStorageService } from '@/services/LocalStorageService';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { getLastSelectedTab } from '@/helpers/project/ProjectHelper';
import NotificationsPopup from '@/components/global/popup/NotificationsPopup.vue';

@Component({
    name: 'NewNavigationItems',
    components: {
        UserRightsMixin,
        RoltekHeaderLogo,
        RLogoIcon,
        UserGroupSelect,
        UserSettingsSelect,
        NewRadioButtonGroup,
        Menu,
        MenuItem: Menu.Item,
        Icon,
        NotificationsPopup,
    },
})
export default class NewNavigationItems extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Getter('generic/unreadNotificationsCount') private unreadCount!: number;
    @Prop({ default: () => [''] }) private selectedNavbarKey!: string[];
    @Prop({ required: true }) private navWidth!: number;
    @Prop({ required: true }) private collapsed!: boolean;

    private navigationKeys = NavigationKeys;
    private notificationsVisible = false;
    private rerenderKey = 0;

    protected get projectTypeOptions(): RadioButtonGroupOptions[] {
        const options: RadioButtonGroupOptions[] = [
            {
                value: ProjectTypes.All as string,
                label: this.$t('All Projects') as string,
            },
            {
                value: ProjectTypes.Offer as string,
                label: this.$t('Offers') as string,
            },
            {
                value: ProjectTypes.Order as string,
                label: this.$t('Orders') as string,
            },
        ];

        if (this.canSeeAdminTab && !this.showClientProjectOptions) {
            options.push({
                value: ProjectTypes.Admin as string,
                label: this.$t('Control panel') as string,
            });
        }

        if (this.canSeeB2BControlPanel) {
            options.push({
                value: ProjectTypes.Request as string,
                label: this.$t('B2B control panel') as string,
            });
        }
        if (this.canSeeInquiries) {
            const inquiries = {
                value: ProjectTypes.Lead as string,
                label: this.$t('Inquiries') as string,
            };

            options.splice(1, 0, inquiries);
        }

        return options;
    }

    private get isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    private get showClientProjectOptions(): boolean {
        if (this.$route.name!.toLowerCase().includes('client')) {
            return true;
        }

        return false;
    }

    private get showProjectTabs() {
        return this.$route.query.initialTab === '2' || this.$route.name! === 'home';
    }

    private get items() {
        // tslint:disable-next-line:max-line-length
        return generateNavigationItemsSetup(this.canSeeChat, this.canSeeCalendar, this.currentUserGroup);
    }

    private get defaultValue() {
        if (this.$route.name === RouteNames.editClient) {
            return this.projectTypeOptions[1].value;
        }
        const fromLocalStorage = getLastSelectedTab();
        const lastSelectedTab = fromLocalStorage
            ? this.projectTypeOptions.find(
                  (projectTypeOption: RadioButtonGroupOptions) => projectTypeOption.value === fromLocalStorage
              )
            : null;
        if (lastSelectedTab == null) {
            return this.projectTypeOptions[1].value;
        }
        return lastSelectedTab.value;
    }

    private triggerRerender() {
        // this.rerenderKey++;
        this.notificationsVisible = !this.notificationsVisible;
        this.notificationsVisible = !this.notificationsVisible;
    }

    private onUpdateEntityType(entityType: string) {
        // update lastSlecetedTab in local storage every time user changes tab in sidebar nav
        LocalStorageService.save('lastSelectedTab', entityType);
        this.$emit('selectedNavbarKey', []);
        EventBus.$emit(EventBusEvents.updateClientProjectTab, entityType);
    }

    private collapseMenu(e: any) {
        if (this.isTouchDevice || this.notificationsVisible === true) {
            return;
        }

        const mouseX = e.clientX;
        const menuElement = this.$refs.navMenu;
        // @ts-ignore
        const isMouseOverMenu = mouseX < menuElement.$el.clientWidth;

        if (isMouseOverMenu) {
            return;
        }

        this.$emit('collapseNav', true);
    }
}
