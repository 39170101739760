import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ProjectLogAPI {
    public static async getAll() {
        let projectLogs;

        try {
            projectLogs = await ApiController.get(ApiRoutes.logs.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLogs);
    }

    public static async getById(projectId: string) {
        let projectLog;

        try {
            projectLog = await ApiController.get(ApiRoutes.logs.getById(projectId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(projectLog);
    }
}
