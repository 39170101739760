import { Model } from '@vuex-orm/core';

export default class OfferPrice extends Model {

    public static entity = 'offerPrice';

    public static fields() {
        return {
            id: this.attr(''),
            price: this.attr(0),
            priceWithTax: this.attr(0),
            priceDetails: this.attr([]),
        };
    }

    public price!: number;
    public priceWithTax!: number;
    public priceDetails!: any[];

}
