<template>
    <div class="editor" :class="{ 'is-disabled': isDisabled, 'has-border': shouldHaveBorder }" v-if="editor">
        <input @change="fileChange" type="file" ref="file" style="display: none" />
        <editor-menu-bubble
            class="menububble"
            :editor="editor"
            @hide="hideLinkMenu"
            v-slot="{ commands, isActive, getMarkAttrs, menu }"
        >
            <div
                class="menububble"
                :class="{ 'is-active': menu.isActive }"
                :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >
                <form
                    class="menububble__form"
                    v-if="linkMenuIsActive"
                    @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                >
                    <input
                        class="menububble__input"
                        type="text"
                        v-model="linkUrl"
                        placeholder="https://"
                        ref="linkInput"
                        @keydown.esc="hideLinkMenu"
                    />
                    <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                        <a-icon type="close-circle" />
                    </button>
                </form>

                <template v-else>
                    <button
                        class="menububble__button"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                    >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
                        <a-icon type="link" />
                    </button>
                </template>
            </div>
        </editor-menu-bubble>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div class="menubar">
                <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                    <a-icon type="bold" />
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.bullet_list() }"
                    @click="commands.bullet_list"
                >
                    <a-icon type="unordered-list" />
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.ordered_list() }"
                    @click="commands.ordered_list"
                >
                    <a-icon type="ordered-list" />
                </button>

                <button class="menubar__button" v-if="shouldShowAttachments" @click="openFileDialog">
                    <a-icon type="paper-clip" />
                </button>

                <button class="menubar__button" @click="commands.undo">
                    <a-icon type="undo" />
                </button>

                <button class="menubar__button" @click="commands.redo">
                    <a-icon type="redo" />
                </button>
            </div>
        </editor-menu-bar>
        <editor-content class="editor__content" :editor="editor"></editor-content>
        <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
            <template v-if="hasResults">
                <div
                    v-for="(user, index) in filteredUsers"
                    :key="user.id"
                    class="suggestion-list__item"
                    :class="{ 'is-selected': navigatedUserIndex === index }"
                    @click="selectUser(user)"
                >
                    {{ user.name }}
                </div>
            </template>
            <div v-else class="suggestion-list__item is-empty">
                No users found
            </div>
        </div>
    </div>
</template>

<script>
import Fuse from 'fuse.js';
import tippy, { sticky } from 'tippy.js';
import RTETitleIcon from '@/icons/RTETitleIcon.vue';
import RTEAddImageIcon from '@/icons/RTEAddImageIcon.vue';
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import { OrderedList, BulletList, ListItem, Bold, Link, History, Mention, Placeholder } from 'tiptap-extensions';
import Attachment from '@/models/Attachment';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import AttachmentRepository from '@/repositories/AttachmentRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import CMSUser from '@/models/CMSUser';

export default {
    components: {
        EditorContent,
        EditorMenuBar,
        RTETitleIcon,
        RTEAddImageIcon,
        EditorMenuBubble,
    },

    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        shouldShowAttachments: {
            type: Boolean,
            default: true,
        },
        shouldHaveBorder: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            haveUsersBeenFetched: false,
            editor: new Editor({
                extensions: [
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new Bold(),
                    new Link(),
                    new History(),
                    new Placeholder({
                        emptyNodeText: this.$t('Upišite poruku ovdje'),
                    }),
                    new Mention({
                        // a list of all suggested items
                        items: async () => {
                            if (!this.haveUsersBeenFetched) {
                                this.haveUsersBeenFetched = true;
                                await CMSUser.getAllOnlyUsers();
                            }
                            return CMSUserRepository.getUsersForMentions();
                        },
                        // is called when a suggestion starts
                        onEnter: ({ items, query, range, command, virtualNode }) => {
                            this.query = query;
                            this.filteredUsers = items;
                            this.suggestionRange = range;
                            this.renderPopup(virtualNode);
                            // we save the command for inserting a selected mention
                            // this allows us to call it inside of our custom popup
                            // via keyboard navigation and on click
                            this.insertMention = command;
                        },
                        // is called when a suggestion has changed
                        onChange: ({ items, query, range, virtualNode }) => {
                            this.query = query;
                            this.filteredUsers = items;
                            this.suggestionRange = range;
                            this.navigatedUserIndex = 0;
                            this.renderPopup(virtualNode);
                        },
                        // is called when a suggestion is cancelled
                        onExit: () => {
                            // reset all saved values
                            this.query = null;
                            this.filteredUsers = [];
                            this.suggestionRange = null;
                            this.navigatedUserIndex = 0;
                            this.destroyPopup();
                        },
                        // is called on every keyDown event while a suggestion is active
                        onKeyDown: ({ event }) => {
                            if (event.key === 'ArrowUp') {
                                this.upHandler();
                                return true;
                            }
                            if (event.key === 'ArrowDown') {
                                this.downHandler();
                                return true;
                            }
                            if (event.key === 'Enter') {
                                this.enterHandler();
                                return true;
                            }
                            return false;
                        },
                        // is called when a suggestion has changed
                        // this function is optional because there is basic filtering built-in
                        // you can overwrite it if you prefer your own filtering
                        // in this example we use fuse.js with support for fuzzy search
                        onFilter: async (items, query) => {
                            if (!query) {
                                return items;
                            }
                            await new Promise((resolve) => {
                                setTimeout(resolve, 500);
                            });
                            const fuse = new Fuse(items, {
                                threshold: 0.2,
                                keys: ['name'],
                            });
                            return fuse.search(query).map((item) => item.item);
                        },
                    }),
                ],
                content: this.value,
                onUpdate: this.onUpdate,
            }),
            query: null,
            suggestionRange: null,
            filteredUsers: [],
            navigatedUserIndex: 0,
            // tslint:disable no-empty
            insertMention: () => {},
            linkMenuIsActive: false,
            linkUrl: null,
        };
    },

    computed: {
        hasResults() {
            return this.filteredUsers.length;
        },

        showSuggestions() {
            return this.query || this.hasResults;
        },
    },
    watch: {
        value() {
            this.editor.setContent(this.value);
        },
    },

    methods: {
        // navigate to the previous item
        // if it's the first item, navigate to the last one
        upHandler() {
            this.navigatedUserIndex =
                (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length;
        },

        // navigate to the next item
        // if it's the last item, navigate to the first one
        downHandler() {
            this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
        },

        enterHandler() {
            const user = this.filteredUsers[this.navigatedUserIndex];

            if (user) {
                this.selectUser(user);
            }
        },

        // we have to replace our suggestion text with a mention
        // so it's important to pass also the position of your suggestion text
        selectUser(user) {
            this.insertMention({
                range: this.suggestionRange,
                attrs: {
                    id: user.id,
                    label: user.name,
                },
            });
            this.$emit('onSelectUser', user.id);
            this.editor.focus();
        },

        // renders a popup with suggestions
        // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
        renderPopup(node) {
            const { x, y } = node.getBoundingClientRect();

            if (x === 0 && y === 0) {
                return;
            }

            if (this.popup) {
                return;
            }

            // ref: https://atomiks.github.io/tippyjs/v6/all-props/
            this.popup = tippy('#app', {
                getReferenceClientRect: () => node.getBoundingClientRect(),
                appendTo: () => document.body,
                interactive: true,
                sticky: true, // make sure position of tippy is updated when content changes
                plugins: [sticky],
                content: this.$refs.suggestions,
                trigger: 'mouseenter', // manual
                showOnCreate: true,
                theme: 'dark',
                placement: 'top-start',
                inertia: true,
                duration: [400, 200],
            });
        },

        destroyPopup() {
            if (this.popup) {
                this.popup[0].destroy();
                this.popup = null;
            }
        },
        onUpdate({ getHTML }) {
            const html = this.fixEmptyNewlines(getHTML());
            this.$emit('updateContent', html);
        },
        fixEmptyNewlines(html) {
            return html.replace(/<p><\/p>/gim, '<p>&nbsp;</p>');
        },
        openFileDialog(command) {
            const fileInput = this.$refs.file;
            fileInput.click();
        },

        async fileChange() {
            const fileInput = this.$refs.file;
            const file = fileInput.files ? fileInput.files[0] : null;
            if (file == null) {
                return;
            }

            const formData = new FormData();
            let uploadedFile;

            formData.append('attachment', file);

            try {
                uploadedFile = await Attachment.createNew(formData);
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška'),
                    description: e.message,
                });
                return;
            }

            this.$emit('onFileUpload', AttachmentRepository.getById(uploadedFile.data.id));
        },

        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },

        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },

        setLinkUrl(command, url) {
            command({ href: url, target: '_blank' });
            this.hideLinkMenu();
        },

        clearContent() {
            this.editor.clearContent(true);
        },
    },

    beforeDestroy() {
        EventBus.$off(EventBusEvents.clearContent, this.clearContent);
        this.destroyPopup();

        if (this.editor == null) {
            return;
        }
        this.editor.destroy();
    },
    created() {
        EventBus.$on(EventBusEvents.clearContent, this.clearContent);
    },
};
</script>

<style scoped lang="scss">
.editor {
    position: relative;
    width: 100%;

    &__title {
        margin-right: 12px;
        color: $black;
    }

    &__content {
        border-radius: 4px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        max-height: 150px;
        overflow-y: auto;

        &::v-deep {
            .ProseMirror {
                min-height: 60px;
                padding: 12px;

                &-focused {
                    outline: none;
                }
            }

            p.is-editor-empty:first-child::before {
                content: attr(data-empty-text);
                float: left;
                color: #aaa;
                pointer-events: none;
                height: 0;
                font-style: italic;
            }

            p {
                margin: 0;
                color: $black;
            }

            pre {
                padding: 0.7rem 1rem;
                border-radius: 5px;
                background: $black;
                color: $white;
                font-size: 0.8rem;
                overflow-x: auto;

                code {
                    display: block;
                }
            }

            p code {
                padding: 0.2rem 0.4rem;
                border-radius: 5px;
                font-size: 0.8rem;
                font-weight: bold;
                background: rgba($black, 0.1);
                color: rgba($black, 0.8);
            }

            ul,
            ol {
                padding-left: 1rem;
            }

            li > p,
            li > ol,
            li > ul {
                margin: 0;
            }

            a {
                color: $blue-6;
                text-decoration: underline;
            }

            blockquote {
                border-left: 3px solid rgba($black, 0.1);
                color: rgba($black, 0.8);
                padding-left: 0.8rem;
                font-style: italic;

                p {
                    margin: 0;
                }
            }

            img {
                max-width: 100%;
                border-radius: 3px;
            }

            table {
                border-collapse: collapse;
                table-layout: fixed;
                width: 100%;
                margin: 0;
                overflow: hidden;

                td,
                th {
                    min-width: 1em;
                    border: 2px solid $border-gray;
                    padding: 3px 5px;
                    vertical-align: top;
                    box-sizing: border-box;
                    position: relative;

                    > * {
                        margin-bottom: 0;
                    }
                }

                th {
                    font-weight: bold;
                    text-align: left;
                }

                .selectedCell:after {
                    z-index: 2;
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(200, 200, 255, 0.4);
                    pointer-events: none;
                }

                .column-resize-handle {
                    position: absolute;
                    right: -2px;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    z-index: 20;
                    background-color: #adf;
                    pointer-events: none;
                }
            }

            .tableWrapper {
                margin: 1em 0;
                overflow-x: auto;
            }

            .resize-cursor {
                cursor: ew-resize;
                cursor: col-resize;
            }
        }

        * {
            caret-color: currentColor;
        }
    }
}

.menubar {
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    display: flex;
    align-items: center;

    &::v-deep {
        svg {
            path {
                fill: #8c8c8c;
            }
        }
    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.is-focused {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
        font-weight: bold;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $black;
        height: 22px;
        width: 30px;
        justify-content: center;
        align-items: center;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        .anticon {
            font-size: 18px;
        }

        &:hover {
            background-color: $grey-4;
        }

        &.is-active {
            background-color: #e2edff;

            &::v-deep {
                svg {
                    path {
                        fill: $roltek-blue;
                    }
                }
            }
        }
    }

    span#{&}__button {
        font-size: 13.3333px;
    }
}

.menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: $black;
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $white;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        span {
            margin-right: 12px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba($white, 0.1);
        }

        &.is-active {
            background-color: rgba($white, 0.2);
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        font: inherit;
        border: none;
        background: transparent;
        color: $white;
    }
}

.is-disabled {
    opacity: 0.6;
    pointer-events: none;
}

.has-border {
    border: 1px solid $grey-1;
    padding-top: 6px;
}
</style>
