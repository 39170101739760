import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class CampaignTypeAPI {
    public static async getAll() {
        let campaignTypes;

        try {
            campaignTypes = await ApiController.get(ApiRoutes.campaignTypes.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaignTypes);
    }

    public static async getById(campaignTypeId: string) {
        let campaignType;

        try {
            campaignType = await ApiController.get(ApiRoutes.campaignTypes.getById(campaignTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaignType);
    }

    public static async delete(campaignTypeId: string) {

        try {
            await ApiController.delete(ApiRoutes.campaignTypes.delete(campaignTypeId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(campaignTypeId: string, campaignTypeInfo: AvailableLabelFields) {
        let campaignType;
        const request = {
            data: {
                type: 'campaign_types',
                id: campaignTypeId,
                attributes: {
                    name: campaignTypeInfo.name,
                },
            },
        };

        try {
            campaignType =
                await ApiController.patch(
                    ApiRoutes.campaignTypes.update(campaignTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaignType);
    }

    public static async create(campaignTypeInfo: AvailableLabelFields) {
        let campaignType;
        const request = {
            data: {
                type: 'campaign_types',
                attributes: {
                    name: campaignTypeInfo.name,
                },
            },
        };

        try {
            campaignType =
                await ApiController.post(
                    ApiRoutes.campaignTypes.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(campaignType);
    }
}
