import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { stringValidationRegex } from '@/regex/formRegex';
import { COMMON_FIELD_PIDS } from '@/enums/global/GlobalOptions';
import { determineFieldVisibility } from './FieldFunctionHelper';
import { FormValidationPayload } from '@/interfaces/general/FormValidationPayload';
import ChecklistField from '@/models/ChecklistField';

let productFieldErrors: any = {};

function checkForErrorsInFieldsAndReturnState(formValues: FormValidationPayload[]) {
    let hasErrors = false;

    formValues.forEach((rowValues) => {
        const errorState = rowHasErrors(rowValues);

        if (errorState) {
            hasErrors = errorState;
        }
    });

    let errorDescription = '';

    const keys = Object.keys(productFieldErrors);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (!productFieldErrors.hasOwnProperty(key)) {
            continue;
        }

        const field = productFieldErrors[key];
        const fieldDescription = field.description;

        if (field.errorInField) {
            errorDescription += fieldDescription;

            // Check if it's the last item
            if (i !== keys.length - 2) {
                errorDescription += ', ';
            }
        }
    }

    return { hasErrors, errorDescription };
}

function rowHasErrors(row: FormValidationPayload) {
    let hasErrors = false;

    for (const key in row.values) {
        if (!row.values.hasOwnProperty(key)) {
            continue;
        }

        const value = row.values[key];
        const uniqueKey = key + row.rowKey;

        if (typeof value === 'object') {
            continue;
        }

        let errorInField;

        // Find segment of particual field
        const fieldSegment = row.fieldSegments.find((segment: any) => {
            return segment.pId === key;
        });

        const fieldType = determineFieldType(fieldSegment!);
        const isFieldVisible = determineIsFieldVisible(row.values, fieldSegment!);

        if ((!COMMON_FIELD_PIDS.includes(key) && fieldType !== 'string') || !isFieldVisible) {
            continue;
        }

        // Check if field is valid
        if (fieldType === 'string') {
            row.values[key] = value.trim();
            errorInField = stringFieldHasError(row.values[key]);
        } else if (['float', 'integer'].includes(fieldType)) {
            errorInField = !isAValidNumber(value);
        }

        if (errorInField) {
            // Set field error status so we can track it and emit error state for fieldsfieldSegment
            hasErrors = errorInField;
            EventBus.$emit(EventBusEvents.setProductFieldErrorState, { pid: key, rowKey: row.rowKey, errorInField });
        } else if (productFieldErrors[uniqueKey]?.errorInField === true) {
            EventBus.$emit(EventBusEvents.setProductFieldErrorState, {
                pid: key,
                rowKey: row.rowKey,
                errorInField,
            });
        }

        // In case form with errors gets resubmitted we check
        // if there were errors before and remove error state from field
        productFieldErrors[uniqueKey] = {
            errorInField,
            description: setFieldDescription(fieldSegment!, row.rowKey),
        };
    }

    return hasErrors;
}

function determineFieldType(fieldSegment: ChecklistField) {
    if (fieldSegment) {
        return fieldSegment.fieldType;
    }

    return '';
}

function determineIsFieldVisible(values: any, fieldSegment: ChecklistField) {
    if (!fieldSegment?.checklistVisibleFunction) {
        return true;
    }

    const isFieldVisible = determineFieldVisibility(fieldSegment.checklistVisibleFunction, values);

    return isFieldVisible;
}

function stringFieldHasError(value: string) {
    return stringValidationRegex.test(value);
}

function isAValidNumber(value: number) {
    return Number.isFinite(value) && value !== 0;
}

function setFieldDescription(fieldSegment: ChecklistField, rowKey: number | null) {
    let description = '';

    const fieldName = fieldSegment?.name ?? 'Unknown';

    if (rowKey !== null) {
        // description = `<span>${fieldName} - at row: ${rowKey}</span><br>`;
        description = `${fieldName} - at row: ${rowKey}`;
    } else {
        // description = `<span>${fieldName}</span><br>`;
        description = `${fieldName}`;
    }

    return description;
}

function resetHelperState() {
    productFieldErrors = {};
}

export { checkForErrorsInFieldsAndReturnState, rowHasErrors, resetHelperState };
