import { AxiosError, AxiosResponse } from 'axios';
import Lead from '@/models/Lead';
import Offer from '@/models/Offer';
import { Model } from '@vuex-orm/core';
import Fields from '@vuex-orm/core/lib/model/contracts/Fields';
import ProjectAPI from '@/api/ProjectAPI';
import Client from '@/models/Client';
import { UpdateProjectRelationship } from '@/interfaces/components/UpdateProjectRelationship';
import Label from '@/models/Label';
import CMSUser from '@/models/CMSUser';
import OrderStatus from '@/models/OrderStatus';
import ContactPerson from '@/models/ContactPerson';
import Chat from '@/models/Chat';
import PaymentRecord from '@/models/PaymentRecord';
import ProjectRepository from '@/repositories/ProjectRepository';
import BankAccount from '@/models/BankAccount';
import NextStep from '@/models/NextStep';
import { UpdateClientAndContactPersonOptions } from '@/interfaces/components/projectNew/UpdateClientAndContactPersonOptions';
import OfferRepository from '@/repositories/OfferRepository';

export default class Project extends Model {
    public get projectId() {
        // @ts-ignore
        return this.id;
    }

    public get projectState() {
        // @ts-ignore
        return this.state;
    }

    public get projectLead() {
        // @ts-ignore
        return this.lead;
    }

    public get projectClient() {
        // @ts-ignore
        return this.client;
    }

    public get projectOfferRevisions() {
        // @ts-ignore
        return this.offerRevisions;
    }

    public get projectAssignedUser() {
        // @ts-ignore
        return this.assignedUser;
    }

    public get projectAssignedUserId() {
        // @ts-ignore
        return this.assignedUser_id;
    }

    public get projectMadeByUser() {
        // @ts-ignore
        return this.madeByUser;
    }

    public get projectUpdatedAt() {
        // @ts-ignore
        return this.updatedAt;
    }

    public get projectOrderedBy() {
        // @ts-ignore
        return this.orderedBy;
    }
    public get projectRoltekHelp() {
        // @ts-ignore
        return this.roltekHelp;
    }

    public static entity = 'project';

    /*public static async fetchDataForOverviewTab(id: string) {
        let project;
        try {
            project = await ProjectAPI.fetchDataForOverviewTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: project.data,
            insertOrUpdate: [
                'offer',
                'cmsUser',
                'client',
                'clientType',
                'label',
                'paymentType',
                'address',
                'country',
                'orderStatus',
                'contactPerson',
                'chat',
                'chatMessage',
            ],
        });

        const projectOffers = OfferRepository.getProjectOffers(id);
        await Offer.getForOverviewTab(projectOffers[0].id);

        return Promise.resolve(project);
    }

    public static async fetchForLeadTab(id: string) {
        let project;
        try {
            project = await ProjectAPI.fetchForLeadTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: project.data,
        });

        return Promise.resolve(project);
    }

    public static async fetchForOfferTab(id: string) {
        let project;
        try {
            project = await ProjectAPI.fetchForOfferTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: project.data,
        });

        return Promise.resolve(project);
    }

    public static async fetchForPaymentsTab(id: string) {
        let project;
        try {
            project = await ProjectAPI.fetchForPaymentsTab(id);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: project.data,
            insertOrUpdate: [
                'currency',
                'paymentRecord',
            ],
        });

        return Promise.resolve(project);
    }*/
    public static async fetchDataForProjectView(id: string) {
        let project;
        try {
            project = await ProjectAPI.fetchDataForProjectView(id);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: project.data,
            insertOrUpdate: [
                'lead',
                'offerRevisions',
                'client',
                'label',
                'lead.products',
                'lead.productSystems',
                'lead.nextStepType',
                'attachments',
                'madeBySalesman',
                'lead.objectAddress',
                'client.address',
                'client.address.country',
                'nextSteps',
                'currency',
                'campaign',
                'attachments',
                'offerItemPriceManipulations',
                'offerPrice',
                'paymentRecord',
                'offer',
                'cmsUser',
                'clientType',
                'paymentType',
                'address',
                'country',
                'orderStatus',
                'contactPerson',
                'chat',
                'chatMessage',
                'projectInternalOrders',
            ],
        });

        return Promise.resolve(project);
    }
    public static fields(): Fields {
        return {
            id: this.attr(null),
            state: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            lead: this.hasOne(Lead, 'project_id'),
            offerRevisions: this.hasMany(Offer, 'project_id'),
            client_id: this.attr(null),
            sortDate: this.string(''),
            orderNumber: this.attr(null),
            client: this.belongsTo(Client, 'client_id'),
            readOnly: this.boolean(false),
            assignedUser_id: this.attr(null),
            madeByUser_id: this.attr(null),
            orderStatus_id: this.attr(null),
            orderStatus: this.belongsTo(OrderStatus, 'orderStatus_id'),
            contactPerson_id: this.attr(null),
            contactPerson: this.belongsTo(ContactPerson, 'contactPerson_id'),
            assignedUser: this.belongsTo(CMSUser, 'assignedUser_id'),
            madeByUser: this.belongsTo(CMSUser, 'madeByUser_id'),
            label_id: this.attr(null),
            label: this.belongsTo(Label, 'label_id'),
            connection: this.attr(null),
            deliveryDateFrom: this.attr(null),
            deliveryDateTo: this.attr(null),
            chat_id: this.attr(null),
            chat: this.belongsTo(Chat, 'chat_id'),
            paymentRecords: this.hasMany(PaymentRecord, 'project_id'),
            userGroupId: this.number(null),
            bankAccount_id: this.attr(null),
            bankAccount: this.belongsTo(BankAccount, 'bankAccount_id'),
            orderedBy: this.attr(null),
            productsInfo: this.attr(null),
            projectInternalOrders: this.attr(null),
            deliveryDate: this.string(''),
            manufactureDate: this.string(''),
            roltekHelp: this.attr(null),
            nextSteps_ids: this.attr([]),
            nextSteps: this.hasManyBy(NextStep, 'nextSteps_ids'),
        };
    }

    public static async getAll(): Promise<AxiosResponse | AxiosError> {
        const getAllProjects = await ProjectAPI.getAll();

        this.create({
            data: getAllProjects.data,
            insertOrUpdate: ['lead, offerRevisions', 'orderStatus'],
        });

        return getAllProjects;
    }

    public static async getLeadProjects() {
        const leadProjects = await ProjectAPI.getLeadProjects();
        this.create({
            data: leadProjects.data,
            insertOrUpdate: ['lead', 'offerRevisions', 'label', 'orderStatus', 'currency'],
        });

        return Promise.resolve();
    }

    public static async getAdminProjects() {
        const adminProjects = await ProjectAPI.getAdminProjects();
        this.create({
            data: adminProjects.data,
            insertOrUpdate: [
                'lead',
                'offerRevisions',
                'label',
                'client.address',
                'lead.products',
                'orderStatus',
                'currency',
            ],
        });

        return Promise.resolve();
    }

    public static async getOfferProjects() {
        const offerProjects = await ProjectAPI.getOfferProjects();

        this.create({
            data: offerProjects.data,
            insertOrUpdate: ['lead', 'offerRevisions', 'label', 'orderStatus', 'currency'],
        });

        return Promise.resolve();
    }

    public static async getOfferAndRequestProjects() {
        const offerProjects = await ProjectAPI.getOfferAndRequestProjects();

        this.create({
            data: offerProjects.data,
            insertOrUpdate: ['lead', 'offerRevisions', 'label', 'orderStatus', 'currency'],
        });

        return Promise.resolve();
    }

    public static async getOrderProjects() {
        const orderProjects = await ProjectAPI.getOrderProjects();
        try {
            this.create({
                data: orderProjects.data,
                insertOrUpdate: ['lead, offerRevisions', 'label', 'orderStatus', 'currency'],
            });
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getActivitiesProjects() {
        const activitiesProjects = await ProjectAPI.getActivitiesProjects();

        try {
            this.create({
                data: activitiesProjects.data,
                insertOrUpdate: ['lead, offerRevisions', 'label', 'lead.nextStepType', 'orderStatus', 'currency'],
            });
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getChatProjects(query: string) {
        let chatProjects;

        try {
            chatProjects = await ProjectAPI.getChatProjects(query);
        } catch (error) {
            return Promise.reject(error);
        }

        this.create({
            data: chatProjects.data,
            insertOrUpdate: [
                'lead',
                'offerRevisions',
                'chat',
                'chatMessage',
                'cmsUser',
                'plainMessage',
                'nextStep',
                'attachment',
                'nextStepType',
            ],
        });

        return Promise.resolve();
    }

    public static async getFilteredProjects(query: string, projectType: string) {
        let projects;
        try {
            projects = await ProjectAPI.getFilteredProjects(query, projectType);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: projects.data,
            insertOrUpdate: [
                'lead',
                'offerRevisions',
                'label',
                'offerItems',
                'client.address',
                'country',
                'orderStatus',
                'clientType',
                'nextStepType',
                'leadOrigin',
                'leadType',
                'campaign',
                'LeadProduct',
                'cmsUser',
                'currency',
                'priceListRegion',
                'paymentType',
                'priceType',
                'productsInfo',
            ],
        });

        await this.store().dispatch('tableData/updateProjects', {
            sortedProjectIds: projects.data.map((project: Project) => project.id),
            projects: ProjectRepository.getAllProjects(),
        });

        await this.store().dispatch('tableData/updateProjectNavigationSettings', {
            hasNext: projects.request.response.links.next !== null,
            hasPrev: projects.request.response.links.prev !== null,
        });

        return Promise.resolve();
    }

    public static async getById(id: string, fetchData = 'all') {
        let project;
        let insertOrUpdate: string[] = [];

        try {
            project = await ProjectAPI.getById(id, fetchData);
        } catch (e) {
            return Promise.reject(e);
        }

        switch (fetchData) {
            case 'basic':
                insertOrUpdate = [
                    'orderStatus',
                    'label',
                    'chat',
                    'chat.messages',
                    'contactPerson',
                    'client',
                    'client.clientTypes',
                    'client.address',
                    'client.address.country',
                ];
                break;
            case 'leads':
                insertOrUpdate = [
                    'lead',
                    'lead.objectAddress',
                    'lead.products',
                    'lead.productSystems',
                    'lead.nextStepType',
                ];
                break;
            case 'orders':
                insertOrUpdate = ['projectInternalOrders', 'attachments'];
                break;
            case 'payments':
                insertOrUpdate = ['paymentRecord', 'currency'];
        }
        // getSingleWithGeneralInfo(id: string) {
        //     return `/projects/${id}?include=orderStatus,label,chat,chat.messages,contactPerson,client,client.clientTypes,client.address,client.address.country`;
        // },
        // getSingleWithLeads(id: string) {
        //     return `/projects/${id}?include=lead,lead.campaign,lead.leadType,lead.objectAddress,lead.origin,lead.products,lead.attachments,lead.attachments.lead`;
        // },
        // getSingleWithOrders(id: string) {
        //     return `/projects/${id}?include=projectInternalOrders,projectInternalOrders.orderStatus,projectInternalOrders.attachments`;
        // },
        // getSingleWithPaymentRecords(id: string) {
        //     return `/projects/${id}?include=paymentRecords,paymentRecords.currency`;
        // },

        this.insertOrUpdate({
            data: project.data,
            insertOrUpdate: insertOrUpdate ?? [
                'lead',
                'offerRevisions',
                'offerItems',
                'client',
                'label',
                'lead.products',
                'lead.productSystems',
                'lead.nextStepType',
                'attachments',
                'madeBySalesman',
                'lead.objectAddress',
                'client.address',
                'chat',
                'client.address.country',
                'nextSteps',
                'currency',
                'campaign',
                'offerItemPriceManipulations',
                'offerPrice',
                'paymentRecord',
            ],
        });

        return Promise.resolve(project);
    }

    public static async getForSpecificTab(id: string) {
        return Project.find(id);
    }

    public static async getByClient(clientId: string) {
        const clientProjects = await ProjectAPI.getByClient(clientId);

        this.insertOrUpdate({
            data: clientProjects.data,
        });

        return clientProjects;
    }

    public static async createNew(defaultClientId?: string | null): Promise<AxiosResponse | AxiosError> {
        let newProject;
        try {
            newProject = await ProjectAPI.createNew(defaultClientId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insert({
            data: newProject.data,
            insertOrUpdate: ['label', 'lead'],
        });

        return Promise.resolve(newProject);
    }

    public static async updateExisting({
        projectId,
        clientId,
        assignedUserId,
        labelId,
        madeByUserId,
        connection,
        documentNameId,
        orderStatusId,
        contactPersonId,
        orderedBy,
    }: UpdateProjectRelationship): Promise<AxiosResponse | AxiosError> {
        let updatedProject;
        try {
            updatedProject = await ProjectAPI.updateExisting({
                projectId,
                clientId,
                assignedUserId,
                labelId,
                madeByUserId,
                connection,
                documentNameId,
                orderStatusId,
                contactPersonId,
                orderedBy,
            });
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: updatedProject.data,
        });

        return Promise.resolve(updatedProject);
    }

    public static async updateBankAccount({ projectId, bankAccountId }: any): Promise<AxiosResponse | AxiosError> {
        let updatedProject;
        try {
            updatedProject = await ProjectAPI.updateBankAccount({
                projectId,
                bankAccountId,
            });
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: updatedProject.data,
        });

        return Promise.resolve(updatedProject);
    }

    public static async updateProjectLabel(projectId: string, labelId: string): Promise<AxiosResponse | AxiosError> {
        let updatedProject;
        try {
            updatedProject = await ProjectAPI.updateProjectLabel(projectId, labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: updatedProject.data,
        });
        await this.store().dispatch('tableData/updateProject', {
            project: ProjectRepository.getProjectById(projectId),
        });
        return Promise.resolve(updatedProject);
    }

    public static async deleteExisting(projectId: string): Promise<void | AxiosError> {
        try {
            await ProjectAPI.deleteExisting(projectId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async fetchFilteredProjectsForNavigation(query: string) {
        let projects;
        try {
            projects = await ProjectAPI.getFilteredProjects(query, '', true);
        } catch (e) {
            return Promise.reject(e);
        }

        const payload = {
            projectIds: projects.data.map((project: Project) => project.id),
            hasNext: projects.request.response.links.next !== null,
            hasPrev: projects.request.response.links.prev !== null,
        };

        return Promise.resolve(payload);
    }

    public static async fetchForTotalProjectNumber(query: string) {
        let projects;
        try {
            projects = await ProjectAPI.getFilteredProjects(query, '', true);
        } catch (e) {
            return Promise.reject(e);
        }

        if (projects.request.response.meta == null) {
            return null;
        }

        return Promise.resolve(projects.request.response.meta.totalCount);
    }

    public static async updateClientAndContactPerson(requestOptions: UpdateClientAndContactPersonOptions) {
        let updatedProject;
        try {
            updatedProject = await ProjectAPI.updateClientAndContactPerson(requestOptions);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: updatedProject.data,
            insertOrUpdate: ['client', 'clientType', 'paymentType', 'address', 'country', 'contactPerson'],
        });

        return Promise.resolve(updatedProject);
    }
    public id!: string;
    public label!: Label;
    public lead!: Lead;
    public state!: string;
    public connection!: string;
    public deliveryDateFrom!: string | null;
    public deliveryDateTo!: string | null;
    public createdAt!: string;
    public readOnly!: boolean;
    public offerRevisions!: Offer[];
    public orderStatus!: OrderStatus;
    public contactPerson!: ContactPerson;
    // tslint:disable-next-line:variable-name
    public client_id!: string | null;
    public orderNumber!: string | null;
    public sortDate!: string;
    public chat!: Chat;
    public bankAccount!: null | BankAccount;
    public paymentRecords!: PaymentRecord[] | null;
    public userGroupId!: number;
    public client!: Client | null;
    public orderedBy!: number | null;
    public projectInternalOrders!: any;
    public deliveryDate!: string;
    public manufactureDate!: string;
    public roltekHelp!: boolean;
    public nextSteps!: NextStep[];
}
