import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { WarrantyConfig } from '@/interfaces/components/cms/WarrantyWarning';

export default class warrantyWarningAPI {
    public static async getAll(data: WarrantyConfig) {
        let warrantyWarnings;

        const filterQuery = data?.filterQuery ? `name=${data.filterQuery}` : '';

        try {
            warrantyWarnings = (await ApiController.get(
                ApiRoutes.warrantyWarning.get(data.validWarranty ? '1' : '0', filterQuery)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(warrantyWarnings);
    }

    public static async getById(warrantyWarningId: string) {
        let warrantyWarning;

        try {
            warrantyWarning = (await ApiController.get(
                ApiRoutes.warrantyWarning.getById(warrantyWarningId)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(warrantyWarning);
    }

    public static async update(id: string, shouldRemoveValidWarranty: boolean) {
        let warrantyWarning;

        const request = {
            data: {
                type: 'checklist_values',
                id: id,
                attributes: {
                    validWarranty: shouldRemoveValidWarranty ? false : true,
                },
            },
        };

        try {
            warrantyWarning = await ApiController.patch(ApiRoutes.warrantyWarning.update(id), request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(warrantyWarning);
    }
}
