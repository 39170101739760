import { Model } from '@vuex-orm/core';
import ProjectLabelTemplateAPI from '@/api/ProjectLabelTemplateAPI';
import Locale from '@/models/Locale';

export default class ProjectLabelTemplate extends Model {

    public static entity = 'projectLabelTemplate';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
        };
    }

    public static async getAll() {
        let projectLabelTemplates;
        try {
            projectLabelTemplates = await ProjectLabelTemplateAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        await this.create({
            data: projectLabelTemplates.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve();
    }

    public static async getById(projectLabelTemplateId: string) {
        let projectLabelTemplate;
        try {
            projectLabelTemplate = await ProjectLabelTemplateAPI.getById(projectLabelTemplateId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: projectLabelTemplate.data,
            insertOrUpdate: [],
        });
        return Promise.resolve(projectLabelTemplate);
    }

    public static async updateExisting(projectLabelTemplateId: string, projectLabelTemplateInfo: any) {
        let projectLabelTemplate;
        try {
            projectLabelTemplate =
                await ProjectLabelTemplateAPI.update(projectLabelTemplateId, projectLabelTemplateInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: projectLabelTemplate.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve(projectLabelTemplate);
    }

    public static async createNew(projectLabelTemplateInfo: any) {
        let projectLabelTemplate;
        try {
            projectLabelTemplate = await ProjectLabelTemplateAPI.create(projectLabelTemplateInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: projectLabelTemplate.data,
            insertOrUpdate: ['locale'],
        });
        return Promise.resolve(projectLabelTemplate);
    }

    public static async deleteExisting(projectLabelTemplateId: string) {
        try {
            await ProjectLabelTemplateAPI.delete(projectLabelTemplateId);
        } catch (e) {
            return Promise.reject(e);
        }
        await ProjectLabelTemplate.delete(projectLabelTemplateId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string;
    public locale!: Locale;

}
