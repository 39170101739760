import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class LeadProductAPI {
    public static async getAll() {
        let leadProducts;

        try {
            leadProducts = await ApiController.get(ApiRoutes.leadProduct.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadProducts);
    }
    public static async getById(leadProductId: string) {
        let leadProduct;

        try {
            leadProduct = await ApiController.get(ApiRoutes.leadProduct.getById(leadProductId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadProduct);
    }
}
