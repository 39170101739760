
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { fetchAndRegisterJsonLogicFunctionsIfNecessary } from '@/helpers/JsonLogicHelper';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import CanvasWindow from '../combinations/CanvasWindow.vue';
import { WindowTemplate } from '@/interfaces/components/combinations/WindowTemplate';
import { WindowElement } from '@/interfaces/components/combinations/WindowElement';
import { CombinationsPayload } from '@/interfaces/components/combinations/CombinationsWizardMultipositionPayload';

@Component({
    name: 'CombinationsWizard',
    components: { CanvasWindow },
})
export default class CombinationsWizard extends Vue {
    @Prop({ required: true }) private wizardData!: any | null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private configLoaded = false;
    private activeProductFormId: number | null = null;
    private extraSpaceForLabels = 200;
    private percentageIncreasement = 2.5;
    private dividerForScaleAdjustment = 0.208;
    private isTooltipVisible = false;
    private metricUnit = 'mm';
    private selectedTemplate: any = null;
    private productDimensions: CombinationsPayload | null = null;

    private windowTemplates: WindowTemplate[] = [
        {
            id: 'template-1',
            stageConfig: { height: 0, width: 0 },
            windowElements: [
                {
                    id: 'window-1',
                    canvasConfig: {
                        width: 1100,
                        height: 1200,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
            ],
        },
        {
            id: 'template-2',
            stageConfig: { height: 0, width: 0 },
            windowElements: [
                {
                    id: 'window-1',
                    canvasConfig: {
                        width: 1100,
                        height: 1200,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
                {
                    id: 'window-2',
                    canvasConfig: {
                        width: 1100,
                        height: 800,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
            ],
        },
        {
            id: 'template-3',
            stageConfig: { height: 0, width: 0 },
            windowElements: [
                {
                    id: 'window-1',
                    canvasConfig: {
                        width: 1100,
                        height: 1200,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
                {
                    id: 'window-2',
                    canvasConfig: {
                        width: 1000,
                        height: 1200,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
                {
                    id: 'window-3',
                    canvasConfig: {
                        width: 800,
                        height: 1200,
                        positionX: null,
                        positionY: null,
                        initialPositionX: null,
                        initialPositionY: 35,
                    },
                },
            ],
        },
    ];

    private onMetricUnitChange(event: any) {
        switch (event?.target?.value as string) {
            case 'mm':
                this.updateTemplatesMetricUnit('mm');
                if (this.selectedTemplate) {
                    this.updateTemplateMetricUnit(this.selectedTemplate, 'mm');
                }
                break;
            case 'cm':
                this.updateTemplatesMetricUnit('cm');
                if (this.selectedTemplate) {
                    this.updateTemplateMetricUnit(this.selectedTemplate, 'cm');
                }
        }
    }

    private updateTemplatesMetricUnit(unit: 'cm' | 'mm') {
        this.windowTemplates.forEach((template: any) => this.updateTemplateMetricUnit(template, unit));
    }

    private updateTemplateMetricUnit(template: any, unit: 'cm' | 'mm') {
        template.windowElements.forEach((window: any) => {
            if (unit === 'cm') {
                window.canvasConfig.width = parseFloat(window.canvasConfig.width) / 10;
                window.canvasConfig.height = parseFloat(window.canvasConfig.height) / 10;
            } else if (unit === 'mm') {
                window.canvasConfig.width = parseFloat(window.canvasConfig.width) * 10;
                window.canvasConfig.height = parseFloat(window.canvasConfig.height) * 10;
            }
        });
    }

    private tooltipVisibilityChange(active: boolean) {
        if (!this.selectedTemplate) {
            this.isTooltipVisible = active;
        } else {
            this.isTooltipVisible = false;
        }
    }

    private async redrawTemplateCanvas() {
        await this.$nextTick();
        this.setTemplatePositionsAndStageConfig(this.selectedTemplate, true);
    }

    private setTemplatesPositionsAndStageConfig() {
        this.windowTemplates.forEach(async (windowTemplate: WindowTemplate) => {
            this.setTemplatePositionsAndStageConfig(windowTemplate);
        });
    }

    private async setTemplatePositionsAndStageConfig(windowTemplate: WindowTemplate, setStage: boolean = true) {
        const dividerForStageWidth = 4.5 + (windowTemplate.windowElements.length * this.percentageIncreasement) / 10;
        await this.$nextTick();

        const { width, height } = windowTemplate.windowElements.reduce(
            (total: any, currentElement: any) => {
                total.width += parseFloat(this.transformDimension(currentElement.canvasConfig.width) as any);
                total.height += parseFloat(this.transformDimension(currentElement.canvasConfig.height) as any);

                return total;
            },
            { width: 0, height: 0 }
        );

        const stageConfig = {
            height: this.calculateStageConfigHeight(windowTemplate),
            // lower total width for stage since we scaled it down in canvas
            width: (width + this.extraSpaceForLabels) / dividerForStageWidth,
        };

        if (setStage) {
            // Set state config
            this.$set(windowTemplate, 'stageConfig', { ...stageConfig });
        }

        // Set initial positions in a template
        this.setElementsInitialPositions(windowTemplate.windowElements);
    }

    private setElementsInitialPositions(windowElements: WindowElement[]) {
        // We set initial position coordinates based on the following logic:
        // Each element has its position equal to the previous element position plus
        // The previous element width/height / divisor (higher number = smaller gap between windows)

        windowElements.forEach((element: any, i: number) => {
            if (i === 0) {
                this.$set(element.canvasConfig, 'initialPositionX', 50);
            } else {
                const transformedWidth = this.transformDimension(windowElements[i - 1]?.canvasConfig.width) as any;
                const transformedXPosition = windowElements[i - 1]?.canvasConfig.initialPositionX || 0;
                this.$set(
                    element.canvasConfig,
                    'initialPositionX',
                    (transformedWidth - this.increasementForWiderWidths(transformedWidth)) *
                        this.dividerForScaleAdjustment +
                        transformedXPosition
                );
            }
        });
    }

    private increasementForWiderWidths(width: string) {
        const medianValue = 250;
        const dividerForUpscaling = 6.76;

        if (+width <= medianValue) {
            return 0;
        }

        const baseDifference = parseFloat(width) - medianValue;

        return Math.round(baseDifference / dividerForUpscaling);
    }

    private calculateStageConfigHeight(template: WindowTemplate) {
        const height = Math.max(
            ...template.windowElements.map((element) => this.transformDimension(element.canvasConfig.height) as number)
        );

        if (height < 600) {
            return (height + this.extraSpaceForLabels) / 4;
        }

        return height / 4;
    }

    private transformDimension(value: string | number) {
        if (this.metricUnit === 'mm') {
            return parseFloat(value as any);
        } else if (this.metricUnit === 'cm') {
            return parseFloat(value as any) * 10;
        }
    }

    private selectWindowTemplate(templateId: string) {
        if (templateId === '') {
            this.selectedTemplate = null;
            return;
        }

        this.selectedTemplate = JSON.parse(
            JSON.stringify(this.windowTemplates.find((template: any) => template.id === templateId))
        );
    }

    private get combinationsCustomFields() {
        return [
            { pId: 'p7402', type: 'width' },
            { pId: 'p7403', type: 'height' },
            { pId: 'p740593', value: 'Dimenzije oken', type: 'dropdown' },
        ];
    }

    private prepareCombinationsPayload() {
        const rows: any = [];

        this.selectedTemplate?.windowElements.forEach((element: WindowElement) => {
            let fields: any = [];

            this.combinationsCustomFields.forEach((field: any) => {
                const payload = {
                    pId: field.pId,
                    value: this.formatDimensionForProduct(field, element),
                };

                fields.push(payload);
            });

            rows.push({ fields });
        });

        this.productDimensions = rows;
    }

    private formatDimensionForProduct(field: any, element: WindowElement) {
        let value: any;

        switch (field.type) {
            case 'width':
                value = element.canvasConfig.width;
                break;
            case 'height':
                value = element.canvasConfig.height;
                break;
            default:
                value = field.value;
        }

        const isDimension = field.type === 'height' || field.type === 'width';

        if (this.metricUnit === 'mm' && isDimension) {
            value = String(parseFloat(value) / 10);
        }

        if (isDimension) {
            value = parseFloat(value);
        }

        return value;
    }

    private async openMultipositionPopup() {
        await fetchAndRegisterJsonLogicFunctionsIfNecessary();
        this.prepareCombinationsPayload();

        EventBus.$emit(EventBusEvents.openAddProductsPopup, {
            popupEvent: PopupEvents.openAddProducts,
            data: {
                productFormId: this.wizardData.productFormId,
                productId: this.wizardData.productId,
                productSystemName: this.wizardData.productSystemName,
                productName: this.wizardData.productName,
                productSystemId: this.wizardData.productSystemId,
                multipositionType: 'detailed',
                timesToMultiply: this.selectedTemplate?.windowElements.length,
                clientId: this.wizardData.clientId,
                clientsPaymentTypeId: this.wizardData.clientsPaymentTypeId,
                offerId: this.wizardData.offerId,
                projectId: this.wizardData.projectId,
                alreadyInStore:
                    this.activeProductFormId !== undefined && this.activeProductFormId !== null
                        ? this.activeProductFormId.toString()
                        : null,
                customDimensions: this.productDimensions,
            },
        });
    }

    private async mounted() {
        await this.$nextTick();

        this.setTemplatesPositionsAndStageConfig();

        await this.$nextTick();
        this.configLoaded = true;
    }

    private async onConfirm() {
        this.loadingOverlay.start();
        try {
            this.loadingOverlay.stop();
            this.closePopup();
            await this.openMultipositionPopup();
        } catch (error) {
            if (error && (error as any).message) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: (error as Error).message,
                });
            }
            return;
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private closePopup() {
        EventBus.$emit(EventBusEvents.closePopup);
    }
}
