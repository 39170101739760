
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProductCombination from '@/components/views/productView/ProductCombination.vue';

@Component({
    name: 'CombinationsSelectorPopup',
    components: {
        ProductCombination,
    },
})
export default class CombinationsSelectorPopup extends Vue {
    @Prop({ required: true }) private productData!: any;
    private visible: boolean = false;
    private productsSelectedForCombination: number[] = [];
    private isTooltipVisible = false;

    private openPopup() {
        this.visible = true;
        this.updateHiddenIndices();
    }

    private closePopup() {
        this.productsSelectedForCombination = [];
        this.visible = false;
        this.$emit('combinationsSelectorClosed');
    }

    private updateHiddenIndices() {
        this.$emit('updateHiddenIndices');
    }

    private tooltipVisibilityChange(active: boolean) {
        if (this.isCombinationModalAdditionDisabled) {
            this.isTooltipVisible = active;
        } else {
            this.isTooltipVisible = false;
        }
    }

    private async addNewCombination() {
        this.$emit('addNewCombination', this.productsSelectedForCombination);

        await this.$nextTick();

        this.productsSelectedForCombination = [];
        EventBus.$emit(EventBusEvents.resetCombinationProductSelectionStatus);
        this.closePopup();
    }

    private get isCombinationModalAdditionDisabled() {
        return !(this.productsSelectedForCombination.length > 1 && this.productsSelectedForCombination.length < 5);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.openCombinationsSelectorPopup, this.openPopup);
        EventBus.$on(EventBusEvents.closeContactPopup, this.closePopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openCombinationsSelectorPopup, this.openPopup);
        EventBus.$off(EventBusEvents.closeContactPopup, this.closePopup);
    }
}
