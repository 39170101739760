
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProductCombination from '@/components/views/productView/ProductCombination.vue';

@Component({
    name: 'CombinationsEditPopup',
    components: {
        ProductCombination,
    },
})
export default class CombinationsEditPopup extends Vue {
    @Prop({ required: true }) private productData!: any;
    private visible: boolean = false;

    private openPopup() {
        this.visible = true;
    }

    private closePopup() {
        this.visible = false;
    }

    private mounted() {
        EventBus.$on(EventBusEvents.openCombinationsEditPopup, this.openPopup);
        EventBus.$on(EventBusEvents.closeContactPopup, this.closePopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openCombinationsEditPopup, this.openPopup);
        EventBus.$off(EventBusEvents.closeContactPopup, this.closePopup);
    }
}
