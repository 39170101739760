import i18n from '@/i18n';

export const ProjectTypes = {
    Offer: i18n.t('Ponude'),
    Request: i18n.t('RoltekRequest'),
    RoltekRequest: i18n.t('RoltekRequest'),
    Lead: i18n.t('Upiti'),
    Order: i18n.t('Narud\u017ebe'),
    Admin: i18n.t('Admin'),
    All: i18n.t('AllProjects'),
    AllProjects: i18n.t('Projekti'),
};
