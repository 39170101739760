import { Model } from '@vuex-orm/core';
import LeadOriginAPI from '@/api/LeadOriginAPI';
import { SessionStorageService as ss } from '@/services/SessionStorageService';

export default class LeadOrigin extends Model {
    public static entity = 'leadOrigin';

    public static fields() {
        return {
            id: this.string(null),
            name: this.string(null),
        };
    }

    public static async getAll() {
        let labels;

        if (ss.has('leadOrigins')) {
            labels = ss.getAndParse('leadOrigins');
        } else {
            try {
                const fetchedLabels = await LeadOriginAPI.getAll();

                labels = fetchedLabels.data;
                ss.stringifyAndSave('leadOrigins', labels);
            } catch (e) {
                return Promise.reject(e);
            }
        }

        this.create({
            data: labels,
        });

        return Promise.resolve();
    }

    public static async getById(labelId: string) {
        let label;
        try {
            label = await LeadOriginAPI.getById(labelId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: label.data,
        });
        return Promise.resolve(label);
    }

    public static async updateExisting(leadOriginId: string, leadOriginInfo: any) {
        let leadOrigin;
        try {
            leadOrigin = await LeadOriginAPI.update(leadOriginId, leadOriginInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: leadOrigin.data,
        });
        return Promise.resolve(leadOrigin);
    }

    public static async createNew(leadOriginInfo: any) {
        let leadOrigin;
        try {
            leadOrigin = await LeadOriginAPI.create(leadOriginInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: leadOrigin.data,
        });
        return Promise.resolve(leadOrigin);
    }

    public static async deleteExisting(leadOriginId: string) {
        try {
            await LeadOriginAPI.delete(leadOriginId);
        } catch (e) {
            return Promise.reject(e);
        }
        await LeadOrigin.delete(leadOriginId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
}
