
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ProductCombinationsHeader from '@/components/global/popup/ProductCombinationsHeader.vue';
import CombinationsProductSlotRow from '@/components/global/popup/CombinationsProductSlotRow.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';

@Component({
    name: 'ProductCombination',
    components: {
        ProductCombinationsHeader,
        CombinationsProductSlotRow,
    },
})
export default class ProductCombination extends Vue {
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Prop({ required: true }) private productData!: any;
    @Prop({ required: false }) private productsSelectedForCombination!: number[];
    @Prop({ required: false, default: false }) private isAddingCombinations!: boolean;

    private customFieldsSettingsForCombinations = [{ pId: 'p10301', value: true }];

    private updateCombinationProductSelection(productFormId: number, selected: boolean) {
        if (selected) {
            this.productsSelectedForCombination.push(productFormId);
        } else {
            const index = this.productsSelectedForCombination.findIndex((id: number) => id === productFormId);
            this.productsSelectedForCombination.splice(index, 1);
        }
    }

    private updateHiddenIndices() {
        this.updateWidthOfProductSlotWrappers();
        this.$emit('updateHiddenIndices');
    }

    private updateWidthOfProductSlotWrappers() {
        this.$nextTick(async () => {
            let wrapperElements;

            wrapperElements = [
                ...(this.$refs.productSlotRow as any).map((productSlotRow: any) => productSlotRow.$refs.wrapper),
                (this.$refs.multipositionHeader as any).$refs.wrapper,
            ] as HTMLElement[];

            for await (const wrapperElement of wrapperElements) {
                wrapperElement.style.width = 'auto';
                await this.$nextTick();
                wrapperElement.style.width = `${wrapperElement.clientWidth}px`;
            }
        });
    }

    private updateCustomFieldValues() {
        this.productData.itemRows.forEach((itemRow: any) => {
            this.customFieldsSettingsForCombinations.forEach((field: any) => {
                this.updateActiveProductFormValue({
                    pId: field.pId,
                    value: field.value,
                    productFormId: itemRow.activeProductFormId,
                });
            });
        });
    }

    private mounted() {
        EventBus.$on(EventBusEvents.updateWidthOfProductSlotWrappers, this.updateWidthOfProductSlotWrappers);
        if (this.isAddingCombinations) {
            this.updateCustomFieldValues();
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateWidthOfProductSlotWrappers, this.updateWidthOfProductSlotWrappers);
    }
}
