
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CountryRepository from '@/repositories/CountryRepository';
import Country from '@/models/Country';

@Component({
    name: 'CountriesDropdown',
})
export default class CountriesDropdown extends Vue {
    @Prop({default: ''}) public newValue!: string;
    @Prop({default: false}) public isDisabled!: boolean;
    @Prop({default: false}) public allowClear!: boolean;

    private value = '';

    private get countries() {
        return CountryRepository.getAll() || [];
    }

    private async mounted() {
        try {
            await Country.getAll();
        } catch (e) {
            return;
        }
    }

    @Watch('newValue', {immediate: true})
    private onNewValueInjection() {
        this.value = this.newValue;
    }
}
