import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableProductSystemDetailsFields } from '@/interfaces/components/productSystemDetails/AvailableProductSystemDetailsFields';
import { TJsonApiBody, TJsonApiData, TJsonApiRelationships } from 'jsona/lib/JsonaTypes';

export default class ProductSystemDetailsConfigurationAPI {
    // public static async getAll() {
    //     let productDetails;
    //     try {
    //         productDetails = await ApiController.get(ApiRoutes.productDetails.getAll) as AxiosResponse;
    //     } catch (e) {
    //         return Promise.reject(e);
    //     }
    //     return Promise.resolve(productDetails);
    // }
    //
    // public static async getById(productDetailsId: string) {
    //     let productDetails;
    //
    //     try {
    //         productDetails =
    //             await ApiController.get(ApiRoutes.productDetails.getSingle(productDetailsId)) as AxiosResponse;
    //     } catch (e) {
    //         return Promise.reject(e);
    //     }
    //
    //     return Promise.resolve(productDetails);
    // }

    public static async updateExisting(
        productSystemDetailsConfigurationId: string,
        productSystemDetailsConfigurationInfo: AvailableProductSystemDetailsFields,
        locale?: string | null) {
        let productSystemDetailsConfiguration;

        const request: TJsonApiBody = {
            data: {
                type: 'product_system_details_configurations',
                id: productSystemDetailsConfigurationId,
                attributes: {
                    title: productSystemDetailsConfigurationInfo.title,
                },
            },
        };
        const relationships: TJsonApiRelationships = {};

        relationships.image = {
            data: {
                type: 'images',
                id: productSystemDetailsConfigurationInfo.image[0].uid,
            },
        };


        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            productSystemDetailsConfiguration =
                await ApiController.patch(
                    ApiRoutes.cms.productSystemDetailsConfiguration.update(productSystemDetailsConfigurationId),
                    request,
                    locale,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystemDetailsConfiguration);
    }

    public static async createNew(
        productSystemDetailsConfigurationInfo: AvailableProductSystemDetailsFields,
        locale?: string | null) {
        let productSystemDetailsConfiguration;

        const request: TJsonApiBody = {
            data: {
                type: 'product_system_details_configurations',
                attributes: {
                    title: productSystemDetailsConfigurationInfo.title,
                },
            },
        };
        const relationships: TJsonApiRelationships = {};

        relationships.image = {
            data: {
                type: 'images',
                id: productSystemDetailsConfigurationInfo.image[0].uid,
            },
        };


        if (request.data) {
            (request.data as TJsonApiData).relationships = relationships;
        }

        try {
            productSystemDetailsConfiguration =
                await ApiController.post(
                    ApiRoutes.cms.productSystemDetailsConfiguration.create,
                    request,
                    locale,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(productSystemDetailsConfiguration);
    }

}
