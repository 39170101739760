import { Model } from '@vuex-orm/core';
import WarrantyWarningAPI from '@/api/WarrantyWarningAPI';
import { WarrantyOptions, WarrantyConfig } from '@/interfaces/components/cms/WarrantyWarning';

export default class WarrantyWarning extends Model {
    public static entity = 'WarrantyWarning';

    public static fields() {
        return {
            id: this.attr(null),
            defaultValue: this.string(''),
            value: this.string(''),
            validWarranty: this.attr(null),
        };
    }

    public static async getAll(data: WarrantyConfig) {
        let WarrantyWarnings: any;

        try {
            WarrantyWarnings = await WarrantyWarningAPI.getAll(data);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: WarrantyWarnings.data,
        });

        return Promise.resolve();
    }

    public static async getById(id: string) {
        let WarrantyWarning;

        try {
            WarrantyWarning = await WarrantyWarningAPI.getById(id);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: WarrantyWarning.data,
        });
        return Promise.resolve(WarrantyWarning);
    }

    public static async updateExisting(warningId: string, data: { value: WarrantyOptions }) {
        let WarrantyWarning;

        try {
            WarrantyWarning = await WarrantyWarningAPI.update(warningId, false);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: WarrantyWarning.data,
        });
        return Promise.resolve(WarrantyWarning);
    }

    public static async createNew(data: { value: WarrantyOptions }) {
        let WarrantyWarning;

        try {
            WarrantyWarning = await WarrantyWarningAPI.update(data.value.id, true);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: WarrantyWarning.data,
        });
        return Promise.resolve(WarrantyWarning);
    }

    public id!: string;
    public defaultValue!: string;
    public value!: string;
    public validWarranty!: boolean;
}
