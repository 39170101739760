import { Model } from '@vuex-orm/core';

export default class WorkflowNodeConnection extends Model {
    public static entity = 'workflowNodeConnection';

    public static fields() {
        return {
            id: this.string(null),
            sourcePosition: this.string(''),
            destinationPosition: this.string(''),
            sourceNode: this.string(''),
            destinationNode: this.string(''),
        };
    }

    public id!: string;
    public sourcePosition!: string;
    public destinationPosition!: string;
    public sourceNode!: string;
    public destinationNode!: string;
}
