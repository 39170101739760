
import CountryRepository from '@/repositories/CountryRepository';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ContactEmailPhoneForm from '@/components/global/ContactEmailPhoneForm.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { ContactDelete } from '@/interfaces/ContactDelete';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import ContactPerson from '@/models/ContactPerson';
import Project from '@/models/Project';
import ContactConfig from '@/models/interfaces/Contact';
import ClientRepository from '@/repositories/ClientRepository';
import ContactPersonRepository from '@/repositories/ContactPersonRepository';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';

@Component({
    name: 'ContactInformation',
    components: { ContactEmailPhoneForm },
})
export default class ContactInformation extends Vue {
    @Prop({ required: true }) private value!: any;
    @Prop({ required: false }) private defaultCountryId!: string;
    @Prop({ default: false }) private emailValidationOverride!: boolean;
    @Prop({ default: false }) private emailValidationOverrideValue!: boolean;
    @Prop({ default: null }) private clientId!: string;
    @Prop({ default: () => [] }) private contactErrors!: [];

    private contacts = [...this.value.contacts];
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private contactEmailAndPhoneForm = {
        emails: [''],
        phones: [''],
        primaryIndex: 0,
    };

    private emptyContact() {
        return {
            name: undefined,
            email: '',
            phone: '',
            job: undefined,
            useCompanyAddress: true,
            address: { ...this.value.addressForm, country: this.formattedDefaultCountry },
            birthday: '',
            isDefault: !this.value.contacts.length ? true : false,
        };
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private emptyAddress() {
        return {
            streetNameAndNumber: undefined,
            poNumber: undefined,
            city: undefined,
            building: undefined,
            country: this.formattedDefaultCountry,
        };
    }

    private onAddContact() {
        this.contacts.push(this.emptyContact());
    }

    private get flagUrl() {
        // return a function for getter
        return (countryCode: string | undefined) =>
            countryCode ? `url(https://flagsapi.com/${countryCode}/flat/48.png)` : '';
    }

    private get countries() {
        return CountryRepository.getAll();
    }

    private get defaultCountry() {
        return CountryRepository.getById(this.defaultCountryId);
    }

    private get formattedDefaultCountry() {
        return this.defaultCountry ? this.defaultCountry.id + '|' + this.defaultCountry.code : undefined;
    }

    private onUseCompanyAddressChange(i: number, checked: boolean) {
        if (!checked) {
            let id: string;

            if (this.contacts[i].address?.id === this.value.addressForm?.id) {
                id = '';
            } else {
                id = this.contacts[i].address?.id;
            }

            this.contacts[i].address = {
                id,
                streetNameAndNumber: this.value.addressForm.streetNameAndNumber,
                poNumber: this.value.addressForm.poNumber,
                city: this.value.addressForm.city,
                building: this.value.addressForm.building,
                country: this.value.addressForm.country,
            };

            this.contacts[i].address_id = '';
        } else {
            this.contacts[i].address = this.emptyAddress();
            this.contacts[i].address_id = this.value.addressForm.id;
        }
    }

    private onDefaultContactPersonChange(i: number, checked: boolean) {
        if (checked) {
            this.contacts[i].isDefault = checked;

            this.contacts.forEach((contact: ContactConfig, index: number) => {
                if (i !== index && contact.isDefault) {
                    contact.isDefault = false;
                }
            });
        } else {
            this.$notification.warning({
                message: this.$t('Warning') as string,
                description: this.$t('You need to have one default contact person!'),
                duration: 2,
            });
        }
    }

    private deleteContact({ pendingContactIndex, contact }: ContactDelete) {
        let filteredContacts = this.contacts.filter((pendingContact) => pendingContact.name !== '');

        contact = ContactPersonRepository.getById(contact.id) || contact;
        filteredContacts = filteredContacts.map((item: ContactConfig) => ContactPersonRepository.getById(item.id));
        const popupData: PopupEventData = {
            popupEvent: PopupEvents.deleteContactPerson,
            data: {
                pendingContactIndex,
                contact,
                clientId: this.value.id,
                deleteContact: this.deleteContactAndClosePopup.bind(this),
                contactPersons: filteredContacts,
            },
        };

        EventBus.$emit(EventBusEvents.onDeleteContactPerson, popupData);
    }

    private async deleteContactAndClosePopup(contactDeleteSettings: ContactDelete) {
        try {
            await this.deleteContactRemoteAndLocal(contactDeleteSettings);
        } catch (error) {
            this.loadingOverlay.stop();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (error as Error).message,
            });
        }
        EventBus.$emit(EventBusEvents.closePopup);
        this.$notification.success({
            message: this.$t('Kontakt osoba uspješno obrisana!') as string,
            description: '',
        });
    }

    private async deleteContactRemoteAndLocal({ pendingContactIndex, contact, newContactPersonId }: ContactDelete) {
        this.removeContactFromArray(pendingContactIndex);
        this.loadingOverlay.start();
        if (contact.id == null || newContactPersonId == null) {
            this.loadingOverlay.stop();
            return;
        }

        try {
            for await (const project of contact.projects) {
                await Project.updateExisting({
                    projectId: project.projectId,
                    contactPersonId: newContactPersonId,
                });
            }
        } catch (error) {
            return Promise.reject(error);
        }

        if (contact.id != null) {
            await ContactPerson.deleteExisting(contact.id);
        }

        this.loadingOverlay.stop();
        return Promise.resolve();
    }

    private removeContactFromArray(pendingContactKey: number) {
        this.contacts.splice(pendingContactKey, 1);
    }

    private formatCountryValue(id: string) {
        const country = this.countries.find((countryVal) => countryVal.id === id);
        if (country) {
            return country.id + '|' + country.code;
        }
    }

    private formatContactsAddress() {
        if (this.contacts) {
            this.contacts.forEach((contact: ContactConfig) => {
                if (!contact.address || !contact.address.country) {
                    return;
                }

                if (typeof contact.address.country !== 'string') {
                    contact.address.country = this.formatCountryValue(contact.address.country.id);
                    return;
                }

                if (!contact.address.country.includes('|')) {
                    contact.address.country = this.formatCountryValue(contact.address.country);
                }
            });
        }
    }

    private addDefaultContactBasedOnCustomerData() {
        const contactData = {
            name: this.value.name || undefined,
            email: this.value.email || '',
            phone: this.value.phone || '',
            job: undefined,
            useCompanyAddress: true,
            address: { ...this.value.addressForm, country: this.formattedDefaultCountry },
            birthday: '',
            isDefault: false,
        };

        this.contacts.push(contactData);
    }

    private mounted() {
        const client = ClientRepository.getById(this.clientId);

        client?.contactPersons.forEach(async (contact: ContactPerson) => {
            await ContactPerson.getByIdWithProjects(contact.id);
        });

        if ((this.value.email || this.value.phone) && !this.value.contacts.length) {
            this.addDefaultContactBasedOnCustomerData();
        }
    }

    @Watch('contacts', {
        immediate: true,
        deep: true,
    })
    private onContactsChange() {
        this.formatContactsAddress();

        this.value.contacts = this.contacts;
        this.$emit('input', this.value);
    }
}
