import { Model } from '@vuex-orm/core';
import DocumentNameConfig from '@/models/interfaces/DocumentName';
import DocumentNameAPI from '@/api/DocumentNameAPI';

export default class DocumentName extends Model {

    public static entity = 'documentName';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            ingeniousId: this.string(''),
            prefix: this.string(''),
        };
    }

    public static async getAll() {
        let documentNames;
        try {
            documentNames = await DocumentNameAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: documentNames.data,
        });
        return Promise.resolve();
    }

    public static async getById(documentNameId: string) {
        let documentName;
        try {
            documentName = await DocumentNameAPI.getById(documentNameId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: documentName.data,
        });
        return Promise.resolve(documentName);
    }

    public static async updateExisting(documentNameId: string, documentNameInfo: DocumentNameConfig) {
        let documentName;
        try {
            documentName = await DocumentNameAPI.update(documentNameId, documentNameInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(documentName);
    }

    public static async createNew(documentNameInfo: DocumentNameConfig) {
        let documentName;
        try {
            documentName = await DocumentNameAPI.create(documentNameInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(documentName);
    }

    public static async deleteExisting(documentNameId: string) {
        try {
            await DocumentNameAPI.delete(documentNameId);
        } catch (e) {
            return Promise.reject(e);
        }
        await DocumentName.delete(documentNameId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public ingeniousId!: string;
    public prefix!: string;

}
