import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class ReminderOptionAPI {
    public static async getAll() {
        let reminderOptions;

        try {
            reminderOptions = await ApiController.get(ApiRoutes.reminderOption.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(reminderOptions);
    }

    public static async getById(reminderOptionId: string) {
        let reminderOption;

        try {
            reminderOption =
                await ApiController.get(ApiRoutes.reminderOption.getById(reminderOptionId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(reminderOption);
    }

    public static async update(reminderOptionId: string, reminderOptionInfo: AvailableLabelFields) {
        let reminderOption;
        const request = {
            data: {
                type: 'reminder_options',
                id: reminderOptionId,
                attributes: {
                    name: reminderOptionInfo.name,
                    timeSeconds: reminderOptionInfo.timeSeconds,
                },
            },
        };

        try {
            reminderOption =
                await ApiController.patch(
                    ApiRoutes.reminderOption.update(reminderOptionId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(reminderOption);
    }

    public static async create(reminderOptionInfo: AvailableLabelFields) {
        let reminderOption;
        const request = {
            data: {
                type: 'reminder_options',
                attributes: {
                    name: reminderOptionInfo.name,
                    timeSeconds: reminderOptionInfo.timeSeconds,
                },
            },
        };

        try {
            reminderOption =
                await ApiController.post(
                    ApiRoutes.reminderOption.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(reminderOption);
    }

    public static async delete(reminderOptionId: string) {

        try {
            await ApiController.delete(ApiRoutes.reminderOption.delete(reminderOptionId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
