import { Model } from '@vuex-orm/core';
import Client from '@/models/Client';
import Locale from '@/models/Locale';
import UserGroupAPI from '@/api/UserGroupAPI';
import UserGroupConfig from '@/models/interfaces/UserGroup';
import Country from './Country';
import PriceListRegion from './PriceListRegion';
import PriceType from './PriceType';

export default class UserGroup extends Model {

    public static entity = 'userGroup';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            client: this.belongsTo(Client, 'client_id'),
            client_id: this.attr(null),
            locale: this.belongsTo(Locale, 'locale_id'),
            locale_id: this.attr(null),
            clientCountryId: this.belongsTo(Country, 'country_id'),
            country_id: this.attr(null),
            priceTypeId: this.attr(null),
            priceListId: this.attr(null),
            clientPriceTypeId: this.attr(null),

        };
    }
    public static async getAll() {
        let userGroups;
        try {
            userGroups = await UserGroupAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: userGroups.data,
            insertOrUpdate: ['locale', 'client'],
        });
        return Promise.resolve(userGroups);
    }

    public static async getById(userGroupId: string) {
        let userGroup;
        try {
            userGroup = await UserGroupAPI.getById(userGroupId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: userGroup.data,
            insertOrUpdate: ['locale', 'client'],
        });
        return Promise.resolve(userGroup);
    }

    public static async updateExisting(userGroupId: string, userGroupInfo: UserGroupConfig) {
        let userGroup;
        try {
            userGroup = await UserGroupAPI.update(userGroupId, userGroupInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(userGroup);
    }

    public static async createNew(userGroupInfo: UserGroupConfig) {
        let userGroup;
        try {
            userGroup = await UserGroupAPI.create(userGroupInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(userGroup);
    }

    public id!: string;
    public name!: string;
    public client!: Client;
    public locale!: Locale;
    public clientId!: string;
    public clientCountryId!: Country;
    public priceTypeId!: string;
    public priceListId!: string;
    public clientPriceTypeId!: string;
}
