import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class LeadTypeAPI {
    public static async getAll() {
        let leadTypes;

        try {
            leadTypes = await ApiController.get(ApiRoutes.leadType.get) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadTypes);
    }

    public static async getById(leadTypeId: string) {
        let leadType;

        try {
            leadType = await ApiController.get(ApiRoutes.leadType.getById(leadTypeId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadType);
    }

    public static async delete(leadTypeId: string) {

        try {
            await ApiController.delete(ApiRoutes.leadType.delete(leadTypeId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(leadTypeId: string, leadTypeInfo: AvailableLabelFields) {
        let leadType;
        const request = {
            data: {
                type: 'lead_types',
                id: leadTypeId,
                attributes: {
                    name: leadTypeInfo.name,
                },
            },
        };

        try {
            leadType =
                await ApiController.patch(
                    ApiRoutes.leadType.update(leadTypeId),
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadType);
    }

    public static async create(leadTypeInfo: AvailableLabelFields) {
        let leadType;
        const request = {
            data: {
                type: 'lead_types',
                attributes: {
                    name: leadTypeInfo.name,
                },
            },
        };

        try {
            leadType =
                await ApiController.post(
                    ApiRoutes.leadType.create,
                    request,
                ) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(leadType);
    }
}
