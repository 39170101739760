import { OfferPdfPropertiesRequest } from '@/interfaces/components/cms/OfferPdfProperties/OfferPdfPropertiesRequest';
import { filterAndMapImagesWithPosition } from '@/helpers/CmsIndexHelper';

export function constructOfferPdfPropertiesRequest(
    {
        offerPdfPropertiesId,
        offerConfigurationId,
        offerPdfPropertiesConfig,
    }: OfferPdfPropertiesRequest) {
    const request = {
        data: {
            type: 'offer_pdf_properties',
            attributes: {
                companyInfo: offerPdfPropertiesConfig.companyInfo,
                introText: offerPdfPropertiesConfig.introText,
                offerInformation: offerPdfPropertiesConfig.offerInformation,
                paymentAndDelivery: offerPdfPropertiesConfig.paymentAndDelivery,
                richtext: offerPdfPropertiesConfig.richtext,
                imageSegment: offerPdfPropertiesConfig.imageSegment,
                signature: offerPdfPropertiesConfig.signature,
                footerColumn1: offerPdfPropertiesConfig.footerColumn1,
                footerColumn2: offerPdfPropertiesConfig.footerColumn2,
                footerColumn3: offerPdfPropertiesConfig.footerColumn3,
                footerColumn4: offerPdfPropertiesConfig.footerColumn4,
            },
            relationships: {
                offerConfiguration: {
                    data: {
                        type: 'offer_configurations',
                        id: offerConfigurationId,
                    },
                },
            },
        },
    };
    if (offerPdfPropertiesId) {
        // @ts-ignore
        request.data.id = offerPdfPropertiesId;
    }

    if (offerPdfPropertiesConfig.locale) {
        // @ts-ignore
        request.data.relationships.locale = {
            data: {
                type: 'locales',
                id: offerPdfPropertiesConfig.locale.id,
            },
        };
    }

    if (offerPdfPropertiesConfig.logo.length > 0) {
        // @ts-ignore
        request.data.relationships.logo = {
            data: {
                type: 'images',
                id: offerPdfPropertiesConfig.logo[0].uid,
            },
        };
    } else {
        // @ts-ignore
        request.data.relationships.logo = {data: null};
    }

    if (offerPdfPropertiesConfig.awardImages) {
        const awardImageArray = filterAndMapImagesWithPosition(
            offerPdfPropertiesConfig.awardImages,
            'award_images');

        // @ts-ignore
        request.data.relationships.awardImages = {
            data: awardImageArray,
        };
    }

    if (offerPdfPropertiesConfig.headerImages) {
        const headerImageArray = filterAndMapImagesWithPosition(
            offerPdfPropertiesConfig.headerImages,
            'header_images');

        // @ts-ignore
        request.data.relationships.headerImages = {
            data: headerImageArray,
        };
    }
    return request;
}
