export const AppTableColumn = {
    title: 'Id',
    client: 'Klijent',
    city: 'Grad *',
    createdAt: 'Datum izrade',
    updatedAt: 'Datum ažuriranja',
    state: 'Status',
    nextStep: 'Status sljedećeg koraka',
    description: 'Opis',
    price: 'Cijena',
    nextStepDate: 'Datum naslednje aktivnosti',
    nextStepDateFrom: 'Datum od',
    nextStepDateTo: 'Datum do',
    phone: 'Telefon',
    nextStepTime: 'Vrijeme',
    type: 'Tip',
    connection: 'Projekt-veza',
    numberOfProducts: 'Broj proizvoda',
    assignedUser: 'Komercijalist',
    products: 'Klijent se zanima za',
    completed: 'Završeno?',
    address: 'Adresa',
    postalCode: 'Po\u0161tanski broj *',
    taxId: 'Tax ID',
    country: 'Dr\u017eava *',
    madeBy: 'Napravio',
    priceListRegion: 'Lista cijene',
    priceType: 'Tip cijene',
    userGroup: 'Korisnička grupa',
    // deliveryDateFrom: 'Datum dostave',
    manufactureDate: 'Manufacture date',
    deliveryDate: 'Delivery date',
};
