import { Model } from '@vuex-orm/core';
import Checklist from '@/models/Checklist';
import ChecklistField from '@/models/ChecklistField';

export default class ChecklistSegment extends Model {

    public static entity = 'checklistSegment';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            isLocked: this.attr(null),
            checklist_id: this.attr(null),
            checklist: this.belongsTo(Checklist, 'checklist_id'),
            checklistFields_ids: this.attr([]),
            checklistFields: this.hasManyBy(ChecklistField, 'checklistFields_ids'),
        };
    }

    public name!: string;
    public checklistFields!: ChecklistField[];
    public isLocked!: boolean;
}
