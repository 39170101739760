var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"ant-modal-header"},[_c('div',{staticClass:"ant-modal-title title-header-container"},[_c('h5',{staticClass:"u-a1--bold"},[_vm._v(" "+_vm._s(_vm.$t('Preview file'))+" ")])])]),_c('div',{staticClass:"ant-modal-body pdf-preview-body"},[(_vm.popupData.data.fileType === 'pdf')?_c('VuePdfApp',{ref:"printPdf",attrs:{"pdf":_vm.src,"file-name":_vm.fileName,"theme":"light","config":_vm.pdfConfig}}):_vm._e(),(
                _vm.popupData.data.fileType === 'jpg' ||
                    _vm.popupData.data.fileType === 'jpeg' ||
                    _vm.popupData.data.fileType === 'png'
            )?_c('img',{staticClass:"preview-file__img",attrs:{"src":_vm.src}}):_vm._e(),(
                _vm.popupData.data.fileType === 'mov' ||
                    _vm.popupData.data.fileType === 'mp4' ||
                    _vm.popupData.data.fileType === 'ogg'
            )?_c('video',{attrs:{"width":"400","controls":""}},[_c('source',{attrs:{"src":_vm.src,"type":'video/' + _vm.popupData.data.fileType}})]):_vm._e()],1),_c('div',{staticClass:"ant-modal-footer"},[_c('AButton',{staticStyle:{"margin-right":"10px"},attrs:{"type":"default"},on:{"click":_vm.onCancel}},[_vm._v(_vm._s(_vm.$t('Close')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }